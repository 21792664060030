import { ConfigProvider, Form, Input, Row, Select, Switch } from "antd";
import { Content } from "antd/es/layout/layout";

export default function CreateNewVenueMapping({
  assetOptions,
  networkOptions,
  venueOptions,
  newAVM,
  setNewAVM,
}: any) {
  return (
    <Content id="create-new-venue-mapping">
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "white",
            },
          },
        }}
      >
        <Row justify="center">
          <h2 style={{ color: "white" }}>Create new specific venue mapping</h2>
        </Row>
        <Form.Item label={<b>Asset</b>} required>
          <Select
            // allowClear
            showSearch
            className="dcl-asset-select"
            placeholder="Asset"
            onChange={(e) => {
              newAVM.assetId = e;
              setNewAVM({ ...newAVM });
            }}
            value={newAVM?.assetId}
            options={assetOptions}
            popupMatchSelectWidth={false}
            filterOption={(input, option: any) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Form.Item>
        <Form.Item label={<b>Venue</b>} required>
          <Select
            // allowClear
            showSearch
            className="dcl-venue-select"
            placeholder="Venue"
            onChange={(e) => {
              newAVM.venueId = e;
              setNewAVM({ ...newAVM });
            }}
            value={newAVM?.venueId}
            options={venueOptions}
            popupMatchSelectWidth={false}
            filterOption={(input, option: any) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Form.Item>
        <Form.Item label={<b>Asset Network</b>} required>
          <Select
            // allowClear
            className="dcl-asset-select"
            placeholder="Asset Network"
            onChange={(e) => {
              newAVM.assetNetworkId = e;
              setNewAVM({ ...newAVM });
            }}
            value={newAVM?.assetNetworkId}
            options={networkOptions}
            popupMatchSelectWidth={false}
            showSearch
            filterOption={(input, option: any) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Form.Item>
        <Form.Item label={<b>Network value</b>} required>
          <Input
            value={newAVM?.value}
            onChange={(e) => {
              newAVM.value = e.target.value;
              setNewAVM({ ...newAVM });
            }}
          />
        </Form.Item>
        <Form.Item label={<b>Is Active</b>}>
          <Switch
            checked={newAVM?.isActive}
            onChange={(e) => {
              newAVM.isActive = e;
              setNewAVM({ ...newAVM });
            }}
          />
        </Form.Item>
      </ConfigProvider>
    </Content>
  );
}
