import { ConfigProvider, Form } from "antd";

export default function DclQuoteConfirmationModal({
  isBase,
  date,
  orderType,
  client,
  venue,
  symbol,
  transactionWay,
  qty,
  price,
  venueFee,
  dclFee,
  note,
  msg,
  summary,
  status = "",
  inCard = false,
}: any) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            labelColor: inCard ? undefined : "white",
            itemMarginBottom: 6,
          },
        },
        token: {
          colorText: inCard ? undefined : "white",
        },
      }}
    >
      <Form.Item label={<b>Date</b>}>{date}</Form.Item>
      <Form.Item label={<b>Order Type</b>}>{orderType}</Form.Item>
      {inCard ? undefined : (
        <Form.Item label={<b>Client</b>}>{client}</Form.Item>
      )}
      <Form.Item label={<b>Venue</b>}>{venue}</Form.Item>
      <Form.Item label={<b>Symbol</b>}>{symbol}</Form.Item>
      <Form.Item label={<b>Transaction Way</b>}>{transactionWay}</Form.Item>
      <Form.Item label={<b>is Base?</b>} colon={false}>
        {isBase ? "Base" : "Quote"}
      </Form.Item>

      <Form.Item label={<b>{isBase ? "Base" : "Quote"} Amount</b>}>
        {qty}
      </Form.Item>
      <Form.Item label={<b>Venue Price</b>}>{price}</Form.Item>
      <Form.Item label={<b>Venue Fee (BPS)</b>}>{venueFee}</Form.Item>
      <Form.Item label={<b>Delchain Fee (BPS)</b>}>{dclFee}</Form.Item>
      <Form.Item label={<b>Note</b>}>{note}</Form.Item>
      <Form.Item label={<b>Message</b>}>{msg}</Form.Item>
      <Form.Item label={<b>Summary</b>}>{summary}</Form.Item>
      {inCard ? (
        <Form.Item label={<b>Status</b>}>{status}</Form.Item>
      ) : undefined}
    </ConfigProvider>
  );
}
