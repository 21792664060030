import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, DatePicker, Row, Table } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useCallback, useEffect, useState } from "react";
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

export default function ViewTradingReportCurrencyBreakdown() {
  const columns = [
    {
      title: "Asset Ticker",
      dataIndex: "ticker",
      width: "25%",
      fixed: "left" as const,
      render: (ticker: any) => <strong>{ticker}</strong>,
    },
    {
      title: "# of Orders",
      dataIndex: "count",
      render: (count: any) => <strong>{count}</strong>,
      sorter: (a: any, b: any) => a.count - b.count, // Custom sorter for number of orders
      width: "20%",
      align: "right" as any,
    },
    {
      title: "USD Notional",
      dataIndex: "sum",
      render: (sum: any) => <strong>{sum}</strong>,
      defaultSortOrder: "descend" as const,
      sorter: (a: any, b: any) =>
        Number(a.sum.replaceAll(",", "")) - Number(b.sum.replaceAll(",", "")), // Custom sorter for number of orders
      align: "right" as any,
      width: "55%",
    },
  ];
  const [initialFetch, setInitialFetch] = useState<boolean>(true);

  const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
    // Can not select days after today and today
    return current > dayjs().endOf("d");
  };
  const [datePeriod, setDatePeriod] = useState<any>([
    dayjs().subtract(7, "d"),
    dayjs().subtract(1, "d"),
  ]);
  const [selectedDatePeriod, setSeletedDatePeriod] = useState<any[]>([
    undefined,
    undefined,
  ]);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [dataPrepopulated, setDataPrepopulated] = useState<boolean>(false);
  const [breakdownData, setBreakdownData] = useState<any[]>();
  const [totalRow, setTotalRow] = useState<any>();

  const fetchData = useCallback(() => {
    const startDate = datePeriod[0]
      .tz("America/New_York", true)
      .startOf("d")
      .toISOString();
    const endDate = datePeriod[1]
      .tz("America/New_York", true)
      .endOf("d")
      .toISOString();
    setSeletedDatePeriod([startDate, endDate]);
    setIsFetchingData(true);
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/trading-report/currency-breakdown",
      withCredentials: true,
      data: {
        startDate,
        endDate,
      },
    })
      .then((res) => {
        const filteredData = res.data.filter(
          (item: any) => item.ticker !== "Total",
        );
        const total = res.data.find((item: any) => item.ticker === "Total");
        setTotalRow(total);
        setBreakdownData(filteredData);
      })
      .finally(() => {
        setIsFetchingData(false);
        setDataPrepopulated(true);
      });
  }, [datePeriod]);

  useEffect(() => {
    if (initialFetch) {
      fetchData();
      setInitialFetch(false);
    }
  }, [initialFetch, fetchData]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const exportCSV = useCallback(() => {
    const startDate = selectedDatePeriod[0];
    const endDate = selectedDatePeriod[1];
    setIsDownloading(true);
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/trading-report/currency-breakdown/export-csv",
      data: {
        startDate,
        endDate,
      },
      withCredentials: true,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(
            2,
            "0",
          )}_trading_report_venue_breakdown.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, [selectedDatePeriod]);
  return (
    <Content id="venue-trading-report-currency-breakdown">
      <Row className="dcl-filter-row" align={"middle"}>
        <Col
          className="dcl-filter-item"
          xl={{ span: 5 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <RangePicker
            className="dcl-daterange-select"
            value={datePeriod}
            disabledDate={disabledDate}
            onChange={(e) => {
              setDatePeriod(e);
            }}
          />
        </Col>
        <Col
          xl={{ span: 5 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Button
            onClick={fetchData}
            disabled={!datePeriod}
            type="primary"
            loading={isFetchingData}
          >
            Apply
          </Button>
        </Col>
        {dataPrepopulated ? (
          <Col
            xl={{ span: 14 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row justify="end" className="pagination-row">
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                disabled={isDownloading}
                loading={isDownloading}
                onClick={exportCSV}
              >
                <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
                CSV Export
              </Button>
            </Row>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      {dataPrepopulated && totalRow ? (
        <Row>
          <Col xxl={12} lg={14} md={18} sm={24} xs={24}>
            <Table
              id="venue-trading-report-table"
              columns={columns}
              loading={isFetchingData}
              dataSource={breakdownData}
              scroll={{ x: 400 }}
              pagination={{ showSizeChanger: true, defaultPageSize: 20 }}
              summary={() => (
                <Table.Summary fixed={"bottom"}>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <b>{totalRow.ticker}</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align="right">
                      <b>{totalRow.count}</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align="right">
                      <b>{totalRow.sum}</b>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Content>
  );
}
