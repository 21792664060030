import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { DisplayContext } from "../../../Context/displayContext";
export default function ExchangeViewer() {
  const [, copy] = useCopyToClipboard();

  const copyMessageToClipboard = (messageToCopy: string) => {
    copy(messageToCopy);
    toast.success(`${messageToCopy} has been copied to clipboard`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const [selectedVenueId, setSelecteVenueId] = useState<number>();
  const [venueList, setVenueList] = useState<any[]>([]);
  const [venueTransactionFetched, setVenueTranscationFetched] =
    useState<boolean>(false);
  const fetchVenueList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueList(res.data.list);
        const vId = res.data.defaultVenueId;
        setSelecteVenueId(vId);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);
  const [venueAccountList, setVenueAccountList] = useState<any>();

  const getVenueAccountList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-accounts",
      withCredentials: true,
    })
      .then((res) => {
        const data = res.data.venueAccountList.map((row: any) => ({
          key: row.id,
          venueId: row.venueId,
          label: `${row.label}${
            row.accountNumber ? ` (${row.accountNumber})` : ""
          }`,
          value: row.id,
        }));
        setVenueAccountList(data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      });
  }, [navigate]);

  useEffect(() => {
    fetchVenueList();
    getVenueAccountList();
  }, [fetchVenueList, getVenueAccountList]);

  const [fetchDate, setFetchDate] = useState<any>();
  const [isInstantFetchLoading, setIsInstantFetchLoading] =
    useState<boolean>(false);
  const [txnFetchDuration, setTxnFetchDuration] = useState<number>(0);
  const [balanceFetchDuration, setBalanceFetchDuration] = useState<number>(0);

  const orderColumns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      hidden: false,
      sorter: (a: any, b: any) =>
        new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf(),
      defaultSortOrder: "descend" as const,
      editable: false,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: displayContext?.displayContext.timezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
      width: "200px",
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { orderId, info }: any) => (
        <React.Fragment>
          <Button
            onClick={() => copyMessageToClipboard(orderId)}
            size={"small"}
            className={"icon-button"}
          >
            <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
          </Button>

          <Tooltip title={info ? info.replaceAll(/\\/g, "") : ""}>
            <i className="dcl-info">i</i>
          </Tooltip>

          <Link
            to={"/bo/venues/delchain_transactions/trades"}
            state={{
              from: window.location.pathname,
              orderId: orderId,
            }}
          >
            {orderId}
          </Link>
        </React.Fragment>
      ),
      width: "220px",
    },
    {
      title: "Venue",
      dataIndex: "venueName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "75px",
    },
    {
      title: "Venue Account",
      dataIndex: "venueAccountLabel",
      hidden: false,
      sorter: false,
      editable: false,
      width: "200px",
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { orderStatus }: any) =>
        orderStatus ? orderStatus.name : "",
      width: "90px",
    },
    {
      title: "Venue Order Type",
      dataIndex: "venueOrderTypeId",
      hidden: false,
      sorter: false,
      render: (_: any, { venueOrderTypeId }: any) => (
        <Space>{venueOrderTypeId}</Space>
      ),
      width: "125px",
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { orderType, id }: any) =>
        orderType ? (
          orderType.name === "Virtual X-Trade" ? (
            <React.Fragment>
              {orderType.name}
              <Button
                style={{
                  padding: "1px",
                  margin: "1px",
                  border: "none",
                  background: "none",
                  boxShadow: "none",
                }}
              >
                <FontAwesomeIcon icon={"fa-regular fa-trash-can" as IconProp} />
              </Button>
            </React.Fragment>
          ) : (
            orderType.name
          )
        ) : (
          ""
        ),
      width: "150px",
    },
    {
      title: "Transaction Way",
      dataIndex: "transactionWayName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "125px",
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionTypeName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "125px",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      hidden: false,
      sorter: false,
      editable: false,
      width: "100px",
    },
    {
      title: "Price",
      dataIndex: "price",
      align: "right" as const,
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { price }: any) =>
        price ? (
          <Space>
            {price.toLocaleString("en-us", {
              minimumFractionDigits: 4,
              maximumFractionDigits: 9,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
      width: "150px",
    },
    {
      title: "Base Currency Amount",
      dataIndex: "baseCurrencyAmount",
      align: "right" as const,
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { baseCurrencyAmount }: any) =>
        baseCurrencyAmount ? (
          <Space>
            {baseCurrencyAmount.toLocaleString("en-us", {
              minimumFractionDigits: 2,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
      width: "150px",
    },
    {
      title: "Base Fill Amount",
      dataIndex: "baseFilledAmount",
      align: "right" as const,
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { baseFilledAmount }: any) =>
        baseFilledAmount ? (
          <Space>
            {baseFilledAmount.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 9,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
      width: "150px",
    },
  ];
  const [orderList, setOrderList] = useState<any[]>();
  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(false);
  const fetchVenueOrder = useCallback((venueId: number) => {
    setIsOrderLoading(true);
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-orders",
      withCredentials: true,
      params: {
        offset: 0,
        pageSize: 100,
        venues: JSON.stringify([venueId]),
        startDate: dayjs().subtract(1, "day").toISOString(),
        endDate: dayjs()
          .tz("America/New_York", true)
          .endOf("day")
          .toISOString(),
        sortField: "timestamp",
        sortOrder: "DESC",
      },
    })
      .then((res) => {
        setOrderList(res.data.orders);
      })
      .finally(() => {
        setIsOrderLoading(false);
      });
  }, []);

  const ledgerColumns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      hidden: false,
      sorter: (a: any, b: any) =>
        new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf(),
      editable: false,
      defaultSortOrder: "descend" as const,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: displayContext?.displayContext.timezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
    {
      title: "Ledger ID",
      dataIndex: "ledgerId",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { ledgerId, info }: any) => (
        <React.Fragment>
          <Button
            onClick={() => copyMessageToClipboard(ledgerId)}
            size={"small"}
            className={"icon-button"}
          >
            <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
          </Button>
          <Tooltip title={info ? info.replaceAll(/\\/g, "") : ""}>
            <i className="dcl-info">i</i>
          </Tooltip>
          {ledgerId}
        </React.Fragment>
      ),
    },
    {
      title: "Trade ID",
      dataIndex: "tradeId",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { tradeId, venueTradeId }: any) => (
        <React.Fragment>
          {venueTradeId ? (
            <React.Fragment>
              <Button
                onClick={() => copyMessageToClipboard(tradeId)}
                size={"small"}
                className={"icon-button"}
              >
                <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
              </Button>
              <Link
                to={"/bo/venues/delchain_transactions/trades"}
                state={{
                  from: window.location.pathname,
                  tradeId: tradeId,
                }}
              >
                {tradeId}
              </Link>
            </React.Fragment>
          ) : (
            tradeId
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Venue",
      dataIndex: "venueName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "75px",
    },
    {
      title: "Venue Account",
      dataIndex: "venueAccountLabel",
      hidden: false,
      sorter: false,
      editable: false,
      width: "200px",
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { orderStatus }: any) =>
        orderStatus ? orderStatus.name : "",
      width: "100px",
    },
    {
      title: "Is OTC",
      dataIndex: "isOTC",
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { isOTC }: any) => (
        <Checkbox disabled defaultChecked={isOTC} />
      ),
      width: "75px",
    },
    {
      title: "Transaction Way",
      dataIndex: "transactionWayName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "135px",
      render: (_: any, { id, transactionWayName }: any) => (
        <React.Fragment>
          {transactionWayName?.startsWith("Virtual") ? (
            <React.Fragment>
              {transactionWayName} &nbsp;
              <Button
                style={{
                  padding: "1px",
                  margin: "1px",
                  border: "none",
                  background: "none",
                  boxShadow: "none",
                }}
              >
                <FontAwesomeIcon icon={"fa-regular fa-trash-can" as IconProp} />
              </Button>
            </React.Fragment>
          ) : (
            transactionWayName
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Transaction Type",
      dataIndex: "transactionTypeName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "125px",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right" as const,
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { amount }: any) =>
        !isNaN(amount) ? (
          <Space>
            {amount.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 9,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
      width: "150px",
    },
    {
      title: "Asset",
      dataIndex: "baseCurrencyName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "100px",
    },
    {
      title: "Fee Currency Amount",
      dataIndex: "feeAmount",
      align: "right" as const,
      hidden: false,
      sorter: false,
      editable: false,
      render: (_: any, { feeAmount }: any) =>
        !isNaN(feeAmount) ? (
          <Space>
            {Number(feeAmount).toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 9,
            })}
          </Space>
        ) : (
          <Space>{feeAmount}</Space>
        ),
      width: "150px",
    },
    {
      title: "Fee Currency",
      dataIndex: "feeCurrencyName",
      hidden: false,
      sorter: false,
      editable: false,
      width: "100px",
    },
  ];
  const [ledgerList, setLedgerList] = useState<any[]>();
  const [isLedgerLoading, setIsLedgerLoading] = useState<boolean>(false);
  const fetchVenueLedger = useCallback((venueId: number) => {
    setIsLedgerLoading(true);
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-ledgers",
      withCredentials: true,
      params: {
        offset: 0,
        pageSize: 100,
        venues: JSON.stringify([venueId]),
        startDate: dayjs().subtract(1, "day").toISOString(),
        endDate: dayjs()
          .tz("America/New_York", true)
          .endOf("day")
          .toISOString(),
        sortField: "timestamp",
        sortOrder: "DESC",
      },
    })
      .then((res) => {
        setLedgerList(res.data.ledgers);
      })
      .finally(() => {
        setIsLedgerLoading(false);
      });
  }, []);

  const balanceColumns = [
    {
      title: "Venue Account",
      dataIndex: "venueAccountId",
      hidden: false,
      sorter: false,
      editable: false,
      width: "200px",
      render: (_: any, { venueAccountId }: any) => (
        <Space>
          {
            venueAccountList?.find((row: any) => row.value === venueAccountId)
              ?.label
          }
        </Space>
      ),
    },
    {
      title: "Asset",
      dataIndex: "asset",
      sorter: (a: any, b: any) => a.asset.localeCompare(b.asset),
      width: 80,
    },
    {
      title: "Free Amount",
      dataIndex: "free",
      width: 120,
      align: "right" as const,
      render: (_: any, { free }: any) =>
        !isNaN(free) ? (
          <Space>
            {free.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: "Hold Amount",
      dataIndex: "holdAmount",
      width: 120,
      align: "right" as const,
      render: (_: any, { hold }: any) =>
        !isNaN(hold) ? (
          <Space>
            {hold.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      width: 120,
      hidden: false,
      sorter: (a: any, b: any) => a.total - b.total,
      align: "right" as const,
      render: (_: any, { total }: any) =>
        !isNaN(total) ? (
          <Space>
            {total.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
  ];
  const [balanceList, setBalanceList] = useState<any[]>();
  const [isBalanceLoading, setIsBalanceLoading] = useState<boolean>(false);
  const fetchVenueBalance = useCallback((venueId: number) => {
    setIsBalanceLoading(true);
    setBalanceFetchDuration(0);
    const startTime = Date.now();
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/instant-balance",
      withCredentials: true,
      params: {
        venueId: venueId,
      },
    })
      .then((res) => {
        let key = 0;
        for (const row of res.data.data) {
          row.key = key++;
        }
        setBalanceList(res.data.data);
      })
      .finally(() => {
        setIsBalanceLoading(false);
        const endTime = Date.now();
        setBalanceFetchDuration(
          Number(((endTime - startTime) / 1000).toFixed(3)),
        );
      });
  }, []);

  const instantFetch = useCallback(
    (selectedVenueId: number) => {
      setIsInstantFetchLoading(true);
      const startTime = Date.now();

      fetchVenueBalance(selectedVenueId);
      axios({
        method: "POST",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/venue/venue-transaction/instant",
        data: { venueId: Number(selectedVenueId) },
        withCredentials: true,
      })
        .then((res) => {
          setVenueTranscationFetched(true);
          setFetchDate(new Date(res.data.lastUpdated));
          const endTime = Date.now();
          setTxnFetchDuration(
            Number(((endTime - startTime) / 1000).toFixed(3)),
          );
          fetchVenueOrder(selectedVenueId);
          fetchVenueLedger(selectedVenueId);
        })
        .finally(() => {
          setIsInstantFetchLoading(false);
        });
    },
    [fetchVenueBalance, fetchVenueOrder, fetchVenueLedger],
  );

  useEffect(() => {
    if (selectedVenueId) {
      setVenueTranscationFetched(false);
      fetchVenueOrder(selectedVenueId);
      fetchVenueLedger(selectedVenueId);
      setVenueTranscationFetched(true);
    }
  }, [selectedVenueId, fetchVenueOrder, fetchVenueLedger]);

  return (
    <Content id="order-builder-exchange-viewer">
      <Row style={{ margin: "5px" }} align="middle">
        <Col xs={12} sm={12} md={6} lg={5} xl={4} xxl={4}>
          <Select
            className="dcl-venue-select"
            allowClear
            placeholder="Venues"
            options={venueList}
            onChange={(e) => {
              setSelecteVenueId(e);
            }}
            disabled={isInstantFetchLoading}
            style={{ width: "100%" }}
            value={selectedVenueId}
            popupMatchSelectWidth={false}
            filterOption={(input, option: any) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={18} lg={19} xl={20} xxl={20}>
          <Button
            type="primary"
            onClick={() => {
              if (selectedVenueId) instantFetch(selectedVenueId);
            }}
            loading={isInstantFetchLoading}
          >
            Instant Fetch
          </Button>
          {fetchDate || isInstantFetchLoading ? (
            <Space className="dcl-filter-row-space">
              <b>Last updated: </b>
              {fetchDate
                ? fetchDate.toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    timeZoneName: "short",
                  })
                : "Pending..."}
              {isInstantFetchLoading ? (
                <Spin />
              ) : (
                <Row style={{ margin: "1em" }}>
                  <b>Last Transaction Fetch Took: </b>&nbsp; {txnFetchDuration}{" "}
                  seconds
                </Row>
              )}
            </Space>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      {venueTransactionFetched ? (
        <React.Fragment>
          <Divider />
          <Tabs
            type="card"
            items={[
              {
                label: <Space>Orders{isOrderLoading ? <Spin /> : <></>}</Space>,
                key: "orders",
                children: (
                  <Table
                    sticky
                    size="small"
                    scroll={{
                      x: 1500,
                    }}
                    rowKey="id"
                    loading={isOrderLoading}
                    columns={orderColumns}
                    dataSource={orderList}
                    pagination={{
                      showSizeChanger: true,
                      pageSizeOptions: [10, 20, 50],
                      hideOnSinglePage: true,
                    }}
                  />
                ),
              },
              {
                label: (
                  <Space>Ledgers{isLedgerLoading ? <Spin /> : <></>}</Space>
                ),
                key: "ledgers",
                children: (
                  <Table
                    sticky
                    size="small"
                    scroll={{
                      x: 1800,
                    }}
                    rowKey="id"
                    loading={isLedgerLoading}
                    columns={ledgerColumns}
                    dataSource={ledgerList}
                    pagination={{
                      showSizeChanger: true,
                      pageSizeOptions: [10, 20, 50],
                      hideOnSinglePage: true,
                    }}
                  />
                ),
              },
              {
                label: (
                  <Space>
                    Balances
                    {isBalanceLoading ? (
                      <Spin />
                    ) : (
                      <Tooltip title="Refresh Balance Data">
                        <Button
                          style={{
                            border: "none",
                            background: "none",
                            boxShadow: "none",
                            padding: "1px 2px",
                            margin: "0 0px",
                          }}
                          onClick={() => {
                            if (selectedVenueId)
                              fetchVenueBalance(selectedVenueId);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={"fa-solid fa-arrow-rotate-right" as IconProp}
                          />
                        </Button>
                      </Tooltip>
                    )}
                  </Space>
                ),
                key: "balances",
                children: (
                  <React.Fragment>
                    {balanceList?.length ? (
                      <Row style={{ margin: "1em" }}>
                        <b>Last Balance Fetch Took: </b> &nbsp;
                        {isBalanceLoading ? (
                          <Spin />
                        ) : (
                          <React.Fragment>
                            {balanceFetchDuration} seconds
                          </React.Fragment>
                        )}
                      </Row>
                    ) : (
                      <></>
                    )}

                    <Table
                      sticky
                      size="small"
                      scroll={{
                        x: 1000,
                      }}
                      rowKey="key"
                      pagination={{
                        showSizeChanger: true,
                        pageSizeOptions: [10, 20, 50],
                        hideOnSinglePage: true,
                      }}
                      loading={isBalanceLoading}
                      columns={balanceColumns}
                      dataSource={balanceList}
                    />
                  </React.Fragment>
                ),
              },
            ]}
          />
        </React.Fragment>
      ) : (
        <></>
      )}
    </Content>
  );
}
