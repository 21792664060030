import { Button, Card, Col, Row, Select, Spin, Switch } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function VenueListToFetch(props: { setIsFinishLoaded: any }) {
  const navigate = useNavigate();
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [txnListToFetch, setTxnListToFetch] = useState<any>([]);
  const [balanceListToFetch, setBalanceListToFetch] = useState<any>([]);
  const [onFinishLoaded, setOnFinishLoaded] = useState<boolean>(false);
  const saveData = useCallback(() => {
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/list-to-fetch",
      withCredentials: true,
      data: {
        listToFetch: balanceListToFetch,
        listType: "balance",
      },
    }).catch((err) => {
      if (err.response.status === 403) {
        navigate("/login");
      }
    });
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/list-to-fetch",
      withCredentials: true,
      data: {
        listToFetch: txnListToFetch,
        listType: "txn",
      },
    })
      .then(() => {
        toast.success("List to Fetch Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setDataFetched(true);
      });
  }, [txnListToFetch, balanceListToFetch, navigate]);
  const callLambdaFetchLastDay = (ccxtKey: string, row: any) => {
    const daysBack = row.daysBack ? row.daysBack : 3;
    props.setIsFinishLoaded(false);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/fetch-missing-transactions",
      withCredentials: true,
      data: {
        coingeckioId: ccxtKey,
        daysBack: daysBack,
      },
    })
      .then(() => {
        toast.success("Lambda function successfully invoked", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        props.setIsFinishLoaded(true);
      });
  };
  const fetchData = useCallback(() => {
    setDataFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/list-to-fetch",
      withCredentials: true,
      params: {
        listType: "txn",
      },
    })
      .then((res) => setTxnListToFetch(res.data.listToFetch))
      .finally(() => {
        setDataFetched(true);
      });
  }, []);
  const fetchBalanceData = useCallback(() => {
    setDataFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/list-to-fetch",
      withCredentials: true,
      params: {
        listType: "balance",
      },
    })
      .then((res) => setBalanceListToFetch(res.data.listToFetch))
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setDataFetched(true);
      });
  }, [navigate]);
  useEffect(() => {
    fetchData();
    fetchBalanceData();
  }, [fetchBalanceData, fetchData]);
  useEffect(() => {
    setOnFinishLoaded(balanceListToFetch.length && txnListToFetch.length);
  }, [balanceListToFetch, txnListToFetch]);
  return (
    <Card
      title={
        <Row>
          <Col span={8}> Venue List To Fetch</Col>
        </Row>
      }
      bordered={false}
      loading={!dataFetched}
      style={{
        margin: "10px",
        borderRadius: "10px",
      }}
      id="venue-list-to-fetch"
    >
      {onFinishLoaded ? (
        <React.Fragment>
          <Card.Grid
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            <Row>
              <Col span={4}>
                <b>Venue Name</b>
              </Col>
              <Col span={4}>
                <b>Fetch Txn From Lambda</b>
              </Col>
              <Col span={4}>
                <b>Days Back</b>
              </Col>
              <Col span={6}>
                <b>Force Fetch</b>
              </Col>
              <Col span={4} offset={2}>
                <b>Fetch Balance From Lambda</b>
              </Col>
            </Row>
          </Card.Grid>
          {txnListToFetch.map((venue: any, index: number) => {
            const balanceVenue = balanceListToFetch[index];
            return (
              <Card.Grid
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
                key={index}
              >
                <Row align={"middle"}>
                  <Col span={4}>{venue.name}</Col>
                  <Col span={4}>
                    <Switch
                      defaultChecked={venue.value}
                      onChange={(value) => {
                        txnListToFetch[index].value = value;
                        setTxnListToFetch([...txnListToFetch]);
                      }}
                    />
                  </Col>
                  <Col span={4}>
                    <Select
                      defaultValue={venue.daysBack ? venue.daysBack : 3}
                      onChange={(value) => {
                        txnListToFetch[index].daysBack = value;
                        setTxnListToFetch([...txnListToFetch]);
                      }}
                      options={[
                        { label: "1 Day", value: 1, key: 1 },
                        { label: "3 Days", value: 3, key: 3 },
                        { label: "7 Days", value: 7, key: 7 },
                        { label: "10 Days", value: 10, key: 10 },
                      ]}
                    />
                  </Col>
                  <Col span={6}>
                    <Button
                      onClick={() => {
                        callLambdaFetchLastDay(
                          venue.ccxtKey,
                          txnListToFetch[index],
                        );
                      }}
                    >
                      Fetch {venue.name} data
                    </Button>
                  </Col>
                  <Col span={4} offset={2}>
                    <Switch
                      defaultChecked={balanceVenue && balanceVenue.value}
                      onChange={(value) => {
                        balanceListToFetch[index].value = value;
                        setBalanceListToFetch([...balanceListToFetch]);
                      }}
                    />
                  </Col>
                </Row>
              </Card.Grid>
            );
          })}
          <Card.Grid
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            <Row justify="end">
              <Button
                type="primary"
                onClick={() => {
                  saveData();
                }}
              >
                Save
              </Button>
            </Row>
          </Card.Grid>
        </React.Fragment>
      ) : (
        <Row justify={"space-around"}>
          <Spin />
        </Row>
      )}
    </Card>
  );
}
