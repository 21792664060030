import React, { useCallback, useContext, useEffect, useState } from "react";
// import "antd/dist/antd.min.css";
import "../../assets/scss/dashboard.scss";
import { Card, Col, Layout, Row, Space, Tooltip } from "antd";
import { UserContext } from "../Context/userContext";
import axios from "axios";
import { DisplayContext } from "../Context/displayContext";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ActiveClientBarChart from "./ActiveClientBarChart";
import DashboardTreeChart from "./TreeChart";

const { Content } = Layout;

function Dashboard({ pageType }: any) {
  const context = useContext(UserContext);
  const displayContext = useContext(DisplayContext);

  const [dailyBalanceLatestTimestamp, setDailyBalanceLatestTimestamp] =
    useState<Date>();
  const [dailyBalanceState, setDailyBalanceState] = useState<string>("");

  const [instantBalanceLastFetched, setInstantBalanceLastFetched] =
    useState<Date>();
  const [fetchInstantBalanceState, setFetchInstantBalanceState] =
    useState<string>("");

  const [instantPriceLastFetched, setInstantPriceLastFetched] =
    useState<Date>();
  const [fetchInstantPriceState, setFetchInstantPriceState] =
    useState<string>("");

  const [transactionLastFetched, setTansactionLastFetched] = useState<Date>();
  const [fetchTransactionState, setFetchTransactionState] =
    useState<string>("");

  const [ebankingLastFetched, setEbankingLastFetched] = useState<Date>();
  const [fetchEbankingState, setFetchEbankingtate] = useState<string>("");

  const [priceCalculationFetched, setPriceCalculationFetched] =
    useState<Date>();
  const [priceCalculationState, setPriceCalculationState] =
    useState<string>("");

  const [displayLastCall, setDisplayLastCall] = useState<boolean>(false);
  const [displayGlobalTimes, setDisplayGlobalTimes] = useState<boolean>(false);
  const [displayUsersData, setDisplayUsersData] = useState<boolean>(false);
  const [displayLatestVenues, setDisplayLatestVenues] =
    useState<boolean>(false);
  const [displayAssetData, setDisplayAssetsData] = useState<boolean>(false);
  const [displayTelegramData, setDisplayTelegramData] =
    useState<boolean>(false);
  const [displayWalletAuc, setDisplayWalletAuc] = useState<boolean>(false);
  const [displayWalletAus, setDisplayWalletAus] = useState<boolean>(false);

  const [lastCallFetched, setLastCallFetched] = useState<boolean>(false);
  const gridStyleLeft: React.CSSProperties = {
    width: "45%",
    textAlign: "left",
  };
  const gridStyleRight: React.CSSProperties = {
    width: "55%",
    textAlign: "left",
  };

  const [displayTimezoneOptions, setDisplayTimezoneOption] = useState<any[]>();
  const [timezoneFetched, setTimezoneFetched] = useState<boolean>(false);

  const [activeUserCountFetched, setActiveUserCountFetched] =
    useState<boolean>(false);
  const [activeUserCount, setActiveUserCount] = useState<number>();
  const [inactiveUserCount, setInactiveUserCount] = useState<number>();
  const [activeClientCount, setActiveClientCount] = useState<number>();
  const [inactiveClientCount, setInactiveClientCount] = useState<number>();

  const [assetDataFetched, setAssetDataFetched] = useState<boolean>(false);
  const [assetDataLastFetched, setAssetDataLastFetched] = useState<Date>();
  const [assetState, setAssetState] = useState<string>();

  const [cryptoCount, setCryptoCount] = useState<number>();
  const [fiatCount, setFiatCount] = useState<number>();
  const [toFetchAssetCount, setToFetchAssetCount] = useState<number>();
  const [insertedAssetCount, setInertedAssetCount] = useState<number>();

  const [telegramDataFetched, setTelegramDataFetched] =
    useState<boolean>(false);
  const [telegramResponse, setTelegramResponse] = useState<any>();
  const [telegramDate, setTelegramDate] = useState<Date>();
  const [telegramRunningState, setTelegramRunningState] = useState<string>("");

  const [venueDataFetched, setVenueDataFetched] = useState<boolean>(false);
  const [newOrdersCount, setNewOrdersCount] = useState<number>();
  const [newTradesCount, setNewTradesCount] = useState<number>();
  const [newLedgersCount, setNewLedgersCount] = useState<number>();

  const [displayVenueExposureData, setDisplayVenueExposureData] =
    useState<boolean>(false);
  const [venueExposureDataFetched, setVenueExposureDataFetched] =
    useState<boolean>(false);
  const [venueExposureData, setVenueExposureData] = useState<any[]>();
  const [balanceLatestDate, setBalanceLatestDate] = useState<Date>();

  const [walletTxnLastFetch, setWalletTxnLastFetched] = useState<Date>();
  const [walletAumDataFetched, setWalletAumDataFetched] =
    useState<boolean>(false);
  const [activeCsdyWalletCount, setActiveCsdyWalletCount] = useState<any>();
  const [activeStkgWalletCount, setActiveStkgWalletCount] = useState<any>();
  const [activeCsdySubWalletCount, setActiveCsdySubWalletCount] =
    useState<any>();
  const [activeStkgSubWalletCount, setActiveStkgSubWalletCount] =
    useState<any>();
  const [activeCsdyClientCount, setActiveCsdyClientCount] = useState<any>();
  const [activeStkgClientCount, setActiveStkgClientCount] = useState<any>();
  const [acitveClientObj, setActiveClientObj] = useState<any>();
  const [walletAuc, setWalletAuc] = useState<any>();
  const [walletAus, setWalletAus] = useState<any>();
  const [walletAucChartData, setWalletAucChartData] = useState<any>();

  const [dailyBalanceData, setDailyBalanceData] = useState<any>();
  const [isDisplayDailyBalanceChart, setIsDisplayDailyBalanceChart] =
    useState<boolean>(false);
  const [isDailyBalanceDataLoaded, setIsDailyBalanceDataLoaded] =
    useState<boolean>(false);

  const [displayClientLoans, setDisplayClientLoans] = useState<boolean>(false);
  const [clientLoans, setClientLoans] = useState<any[]>([]);
  const [isClientLoanLoaded, setIsClientLoanLoaded] = useState<boolean>(false);

  const fetchClientLoans = useCallback(() => {
    setIsClientLoanLoaded(false);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/bo/financial-services/loans",
      params: {
        dashboard: true,
      },
      withCredentials: true,
    })
      .then((res) => {
        setClientLoans(res.data);
        setDisplayClientLoans(res.data?.length ? true : false);
      })
      .finally(() => {
        setIsClientLoanLoaded(true);
      });
  }, []);

  useEffect(() => {
    fetchClientLoans();
  }, [fetchClientLoans]);

  const fetchDailyBalanceData = useCallback(() => {
    setLastCallFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/balance/dashboard",
      withCredentials: true,
    })
      .then((res) => {
        setDailyBalanceData(res.data);
        setIsDisplayDailyBalanceChart(true);
      })
      .catch(() => {
        setIsDisplayDailyBalanceChart(false);
      })
      .finally(() => {
        setIsDailyBalanceDataLoaded(true);
      });
  }, []);

  const fetchWidgetDisplay = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/dashboard-widget-display",
      withCredentials: true,
    }).then((res) => {
      setDisplayLastCall(res.data.widgetDataFetchTimes);
      setDisplayGlobalTimes(res.data.widgetGlobalTimes);
      setDisplayUsersData(res.data.widgetUsersData);
      setDisplayLatestVenues(res.data.widgetLatestVenuesData);
      setDisplayAssetsData(res.data.widgetAssetsData);
      setDisplayTelegramData(res.data.widgetTelegramData);
      setDisplayWalletAuc(res.data.widgetAmountUnderCustody);
      setDisplayWalletAus(res.data.widgetAmountUnderStaking);
      if (res.data.widgetVenueExposureData) {
        setDisplayVenueExposureData(res.data.widgetLatestVenuesData);
      }
    });
  }, []);

  const fetchLastCallData = useCallback(() => {
    setLastCallFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/lastCalls",
      withCredentials: true,
    })
      .then((res) => {
        setDailyBalanceLatestTimestamp(new Date(res.data.dailyBalance));
        setInstantBalanceLastFetched(new Date(res.data.instantBalance));
        setInstantPriceLastFetched(new Date(res.data.instantPrice));
        setTansactionLastFetched(new Date(res.data.transaction));
        setEbankingLastFetched(new Date(res.data.ebanking));
        setPriceCalculationFetched(new Date(res.data.priceCalculation));
        setDailyBalanceState(res.data.dailyBalanceState);
        setFetchInstantBalanceState(res.data.instantBalanceState);
        setFetchInstantPriceState(res.data.instantPriceState);
        setFetchEbankingtate(res.data.ebankingState);
        setFetchTransactionState(res.data.transactionState);
        setPriceCalculationState(res.data.priceCalculationState);
      })
      .finally(() => {
        setLastCallFetched(true);
      });
  }, []);

  const getDisplayTimezone = useCallback(() => {
    setTimezoneFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/displayTimezone",
      withCredentials: true,
    })
      .then((res) => {
        setDisplayTimezoneOption(res.data);
      })
      .finally(() => {
        setTimezoneFetched(true);
      });
  }, []);

  const getUserClientCount = useCallback(() => {
    setActiveUserCountFetched(false);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/active-count",
      withCredentials: true,
    })
      .then((res) => {
        setActiveUserCount(res.data.activeUser);
        setInactiveUserCount(res.data.inactiveUser);
        setActiveClientCount(res.data.activeClient);
        setInactiveClientCount(res.data.inactiveClient);
        setActiveClientObj(res.data.monthlyCounts);
      })
      .finally(() => {
        setActiveUserCountFetched(true);
      });
  }, []);

  const getAssetInfo = useCallback(() => {
    setAssetDataFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/dashboard",
      withCredentials: true,
    })
      .then((res) => {
        setCryptoCount(res.data.cryptoCount);
        setFiatCount(res.data.fiatCount);
        setToFetchAssetCount(res.data.toFetchCount);
        setInertedAssetCount(res.data.insertedCount);
        setAssetDataLastFetched(new Date(res.data.lastDate));
        setAssetState(res.data.assetState);
      })
      .finally(() => {
        setAssetDataFetched(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getTelegramBackupInfo = useCallback(() => {
    setTelegramDataFetched(false);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/telegram-backup-result",
      withCredentials: true,
    })
      .then((res) => {
        setTelegramDate(new Date(res.data.createdAt));
        setTelegramResponse(res.data.JSON);
        setTelegramRunningState(res.data.runningState);
      })
      .finally(() => {
        setTelegramDataFetched(true);
      });
  }, []);

  const getVenueData = useCallback(() => {
    setVenueDataFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/last-venue-data",
      withCredentials: true,
    })
      .then((res) => {
        setNewOrdersCount(res.data.orderCount);
        setNewTradesCount(res.data.tradeCount);
        setNewLedgersCount(res.data.ledgerCount);
      })
      .finally(() => {
        setVenueDataFetched(true);
      });
  }, []);

  const getVenueExposureData = useCallback(() => {
    setVenueExposureDataFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-exposure",
      withCredentials: true,
    })
      .then((res) => {
        setVenueExposureData(res.data.res);
        setBalanceLatestDate(new Date(res.data.lastCall));
      })
      .finally(() => {
        setVenueExposureDataFetched(true);
      });
  }, []);

  const getWalletAssetUnderManagement = useCallback(() => {
    setWalletAumDataFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallet/aum",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.lastTxnCall)
          setWalletTxnLastFetched(new Date(res.data.lastTxnCall));
        setWalletAucChartData(res.data.chartData);
        setWalletAuc(res.data.walletAuc);
        setWalletAus(res.data.walletAus);
        setActiveCsdyWalletCount(res.data.activeCustodyWalletCount);
        setActiveCsdySubWalletCount(res.data.activeCustodySubsalletsCount);
        setActiveStkgWalletCount(res.data.activeStakingWalletCount);
        setActiveStkgSubWalletCount(res.data.activeStakingSubwalletsCount);
        setActiveCsdyClientCount(res.data.activeCustodyClientCount);
        setActiveStkgClientCount(res.data.activeStakingClientCount);
      })
      .finally(() => {
        setWalletAumDataFetched(true);
      });
  }, []);

  useEffect(() => {
    fetchDailyBalanceData();
    fetchWidgetDisplay();
    fetchLastCallData();
    getDisplayTimezone();
    getUserClientCount();
    getAssetInfo();
    getTelegramBackupInfo();
    getVenueData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (displayVenueExposureData) getVenueExposureData();
  }, [displayVenueExposureData, getVenueExposureData]);

  useEffect(() => {
    if (displayWalletAuc || displayWalletAus) getWalletAssetUnderManagement();
  }, [displayWalletAuc, displayWalletAus, getWalletAssetUnderManagement]);

  return (
    <React.Fragment>
      <Layout>
        <Content id="bo-dashboard">
          <Row justify="center" align="middle">
            <h4 className="dashboard-welcome">Hi {context?.user.fullname}</h4>
          </Row>
          <Row>
            {isDisplayDailyBalanceChart ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>
                        <Tooltip
                          title={balanceLatestDate?.toLocaleString("en-US", {
                            timeZone: displayContext?.displayContext.timezone,
                            timeZoneName: "short",
                          })}
                        >
                          Venue Balances
                        </Tooltip>
                      </Col>
                    </Row>
                  }
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-dollar-sign" as IconProp}
                      size={"lg"}
                    />
                  }
                  loading={!isDailyBalanceDataLoaded}
                  bordered={false}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Grid style={{ width: "100%" }}>
                    <DashboardTreeChart data={dailyBalanceData} />
                  </Card.Grid>
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayWalletAuc ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>Asset Under Custody</Col>
                    </Row>
                  }
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-vault" as IconProp}
                      size={"lg"}
                    />
                  }
                  loading={!walletAumDataFetched}
                  bordered={false}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Grid style={{ width: "100%" }}>
                    <DashboardTreeChart data={walletAucChartData} />
                  </Card.Grid>
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayUsersData ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>Active Clients</Col>
                    </Row>
                  }
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-users" as IconProp}
                      size={"lg"}
                    />
                  }
                  bordered={false}
                  loading={!activeUserCountFetched}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Grid style={{ width: "100%" }}>
                    {acitveClientObj ? (
                      <ActiveClientBarChart data={acitveClientObj} />
                    ) : (
                      <></>
                    )}
                  </Card.Grid>
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayLastCall ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>Latest Data Fetch Time</Col>
                    </Row>
                  }
                  bordered={false}
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-cloud-arrow-down" as IconProp}
                      size={"lg"}
                    />
                  }
                  loading={!lastCallFetched}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Grid style={{ width: "50%" }} key="ebanking-ledger">
                    <Tooltip
                      title={
                        <Row>
                          {ebankingLastFetched
                            ? ebankingLastFetched.toLocaleString("en-US", {
                                timeZone:
                                  displayContext?.displayContext.timezone,
                                timeZoneName: "short",
                              })
                            : "Pending..."}
                        </Row>
                      }
                    >
                      {fetchEbankingState ? (
                        <i className={`dcl_state ${fetchEbankingState}`}></i>
                      ) : (
                        <></>
                      )}
                      <b>Ebanking Ledger</b>
                    </Tooltip>
                  </Card.Grid>
                  <Card.Grid style={{ width: "50%" }} key="venue-daily-balance">
                    <Tooltip
                      title={
                        <Row>
                          {dailyBalanceLatestTimestamp
                            ? dailyBalanceLatestTimestamp.toLocaleString(
                                "en-US",
                                {
                                  timeZone:
                                    displayContext?.displayContext.timezone,
                                  timeZoneName: "short",
                                },
                              )
                            : "Pending..."}
                        </Row>
                      }
                    >
                      {dailyBalanceState ? (
                        <i className={`dcl_state ${dailyBalanceState}`}></i>
                      ) : (
                        <></>
                      )}
                      <b>Venue Daily Balance</b>
                    </Tooltip>
                  </Card.Grid>
                  <Card.Grid
                    style={{ width: "50%" }}
                    key="venue-instant-balance"
                  >
                    <Tooltip
                      title={
                        <Row>
                          {instantBalanceLastFetched
                            ? instantBalanceLastFetched.toLocaleString(
                                "en-US",
                                {
                                  timeZone:
                                    displayContext?.displayContext.timezone,
                                  timeZoneName: "short",
                                },
                              )
                            : "Pending..."}
                        </Row>
                      }
                    >
                      {fetchInstantBalanceState ? (
                        <i
                          className={`dcl_state ${fetchInstantBalanceState}`}
                        ></i>
                      ) : (
                        <></>
                      )}
                      <b>Venue Instant Balance</b>
                    </Tooltip>
                  </Card.Grid>

                  <Card.Grid style={{ width: "50%" }} key="venue-instant-price">
                    <Tooltip
                      title={
                        <Row>
                          {instantPriceLastFetched
                            ? instantPriceLastFetched.toLocaleString("en-US", {
                                timeZone:
                                  displayContext?.displayContext.timezone,
                                timeZoneName: "short",
                              })
                            : "Pending..."}
                        </Row>
                      }
                    >
                      {fetchInstantPriceState ? (
                        <i
                          className={`dcl_state ${fetchInstantPriceState}`}
                        ></i>
                      ) : (
                        <></>
                      )}
                      <b>Venue Instant Price</b>
                    </Tooltip>
                  </Card.Grid>
                  <Card.Grid style={{ width: "50%" }} key="venue-transaction">
                    <Tooltip
                      title={
                        <Row>
                          {transactionLastFetched
                            ? transactionLastFetched.toLocaleString("en-US", {
                                timeZone:
                                  displayContext?.displayContext.timezone,
                                timeZoneName: "short",
                              })
                            : "Pending..."}
                        </Row>
                      }
                    >
                      {fetchTransactionState ? (
                        <i className={`dcl_state ${fetchTransactionState}`}></i>
                      ) : (
                        <></>
                      )}
                      <b>Venue Transaction</b>
                    </Tooltip>
                  </Card.Grid>
                  <Card.Grid style={{ width: "50%" }} key="asset-price">
                    <Tooltip
                      title={
                        <Row>
                          {assetDataLastFetched
                            ? assetDataLastFetched.toLocaleString("en-US", {
                                timeZone:
                                  displayContext?.displayContext.timezone,
                                timeZoneName: "short",
                              })
                            : "Pending..."}
                        </Row>
                      }
                    >
                      {assetState ? (
                        <i className={`dcl_state ${assetState}`}></i>
                      ) : (
                        <></>
                      )}
                      <b>Asset Prices</b>
                    </Tooltip>
                  </Card.Grid>
                  <Card.Grid style={{ width: "50%" }} key="telegram-groups">
                    <Tooltip
                      title={
                        <Row>
                          {telegramDate
                            ? telegramDate.toLocaleString("en-US", {
                                timeZone:
                                  displayContext?.displayContext.timezone,
                                timeZoneName: "short",
                              })
                            : "Pending..."}
                        </Row>
                      }
                    >
                      {telegramRunningState ? (
                        <i className={`dcl_state ${telegramRunningState}`}></i>
                      ) : (
                        <></>
                      )}
                      <b>Telegram Groups</b>
                    </Tooltip>
                  </Card.Grid>
                  <Card.Grid
                    style={{ width: "50%" }}
                    key="market-trade-price-calc"
                  >
                    <Tooltip
                      title={
                        <Row>
                          {priceCalculationFetched
                            ? priceCalculationFetched.toLocaleString("en-US", {
                                timeZone:
                                  displayContext?.displayContext.timezone,
                                timeZoneName: "short",
                              })
                            : "Pending..."}
                        </Row>
                      }
                    >
                      {priceCalculationState ? (
                        <i className={`dcl_state ${priceCalculationState}`}></i>
                      ) : (
                        <></>
                      )}
                      <b>Market Trade Price Calc</b>
                    </Tooltip>
                  </Card.Grid>
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayVenueExposureData ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>Venue Exposure</Col>
                    </Row>
                  }
                  bordered={false}
                  loading={!venueExposureDataFetched}
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-arrow-up-right-dots" as IconProp}
                      size={"lg"}
                    />
                  }
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  {venueExposureData ? (
                    venueExposureData.map((e: any, i: number) => (
                      <Card.Grid style={{ width: "25%" }} key={i}>
                        <Tooltip
                          title={
                            <React.Fragment>
                              <Row>
                                <Space>
                                  <b>Crypto Exposure:</b>
                                  {e.cryptoExposure.toLocaleString("en-us", {
                                    type: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Space>
                              </Row>
                              <Row>
                                <Space>
                                  <b>Fiat Exposure:</b>
                                  {e.fiatExposure.toLocaleString("en-us", {
                                    type: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Space>
                              </Row>
                              <Row>
                                <Space>
                                  <b>Total Exposure:</b>
                                  {e.totalExposure.toLocaleString("en-us", {
                                    type: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Space>
                              </Row>
                              <br />
                              <Row>
                                <Space>
                                  <b>Exposure Limit:</b>
                                  {e.limit.toLocaleString("en-us", {
                                    type: "currency",
                                    currency: "USD",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </Space>
                              </Row>
                            </React.Fragment>
                          }
                        >
                          <Link to="/bo/venues/delchain_balances/venueExposures">
                            <Space>
                              <b>
                                {e.key}
                                {fetchEbankingState ? (
                                  <i className={`dcl_state ${e.flag}`}></i>
                                ) : (
                                  <></>
                                )}
                              </b>
                            </Space>
                          </Link>
                        </Tooltip>
                      </Card.Grid>
                    ))
                  ) : (
                    <></>
                  )}
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayClientLoans ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>Client Loans</Col>
                    </Row>
                  }
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-hand-holding-dollar" as IconProp}
                      size={"lg"}
                    />
                  }
                  bordered={false}
                  loading={!isClientLoanLoaded}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                  styles={{
                    body: {
                      padding: 0,
                    },
                  }}
                >
                  {clientLoans.map((clientLoan, i) => (
                    <Card.Grid style={{ width: "100%" }} key={i}>
                      <Link to="/bo/financial-services/loans">
                        <i className={`dcl_state ${clientLoan.status}`}></i>
                        <b>{clientLoan.clientName}</b>
                      </Link>
                    </Card.Grid>
                  ))}
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayAssetData ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>Assets</Col>
                    </Row>
                  }
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-dollar-sign" as IconProp}
                      size={"lg"}
                    />
                  }
                  bordered={false}
                  loading={!assetDataFetched}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Grid style={gridStyleLeft}>
                    <b>Crypto Assets:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>{cryptoCount}</Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b>Fiat Assets:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>{fiatCount}</Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b>Prices to Retrieve:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {toFetchAssetCount}
                  </Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b>Prices Inserted:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {insertedAssetCount}
                  </Card.Grid>
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayWalletAuc ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>
                        {walletTxnLastFetch ? (
                          <Tooltip
                            title={walletTxnLastFetch.toLocaleString("en-US", {
                              timeZone: displayContext?.displayContext.timezone,
                            })}
                          >
                            Custody
                          </Tooltip>
                        ) : (
                          "Custody"
                        )}
                      </Col>
                    </Row>
                  }
                  bordered={false}
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-vault" as IconProp}
                      size={"lg"}
                    />
                  }
                  loading={!walletAumDataFetched}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Grid style={gridStyleLeft}>
                    <b>Total AUC:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>{walletAuc}</Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b># Client with Active Custody Wallet:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {activeCsdyClientCount}
                  </Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b># Active Wallets:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {activeCsdyWalletCount}
                  </Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b># Active Subwallets:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {activeCsdySubWalletCount}
                  </Card.Grid>
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayWalletAus ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>Staking</Col>
                    </Row>
                  }
                  bordered={false}
                  loading={!walletAumDataFetched}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-circle-dollar-to-slot" as IconProp}
                      size={"lg"}
                    />
                  }
                >
                  <Card.Grid style={gridStyleLeft}>
                    <b>Total AUS:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>{walletAus}</Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b># Client with Active Staking Wallet:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {activeStkgClientCount}
                  </Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b># Active Wallets:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {activeStkgWalletCount}
                  </Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b># Active Subwallets:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {activeStkgSubWalletCount}
                  </Card.Grid>
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayLatestVenues ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>Last Venue Data</Col>
                    </Row>
                  }
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-file-arrow-down" as IconProp}
                      size={"lg"}
                    />
                  }
                  bordered={false}
                  loading={!venueDataFetched}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Grid style={gridStyleLeft}>
                    <b>New Orders:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>{newOrdersCount}</Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b>New Trades:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>{newTradesCount}</Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b>New Ledgers:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {newLedgersCount}
                  </Card.Grid>
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayUsersData ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>Users</Col>
                    </Row>
                  }
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-solid fa-users" as IconProp}
                      size={"lg"}
                    />
                  }
                  bordered={false}
                  loading={!activeUserCountFetched}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Grid style={gridStyleLeft}>
                    <b>Active Clients:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {activeClientCount}
                  </Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b>Inactive Clients:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {inactiveClientCount}
                  </Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b>Active Users:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {activeUserCount}
                  </Card.Grid>
                  <Card.Grid style={gridStyleLeft}>
                    <b>Inactive Users:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {inactiveUserCount}
                  </Card.Grid>
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayGlobalTimes ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}> Global Time</Col>
                    </Row>
                  }
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-regular fa-clock" as IconProp}
                      size={"lg"}
                    />
                  }
                  bordered={false}
                  loading={!timezoneFetched}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                  styles={{
                    body: {
                      padding: 0,
                    },
                  }}
                >
                  {displayTimezoneOptions?.map((timezone, i) => {
                    return (
                      <Row key={i} style={{ padding: 0 }}>
                        <Card.Grid style={gridStyleLeft}>
                          <b>{timezone.key}</b>
                        </Card.Grid>
                        <Card.Grid style={gridStyleRight}>
                          {timezone
                            ? new Date().toLocaleString("en-US", {
                                timeZone: timezone.value,
                              })
                            : "Pending..."}
                        </Card.Grid>
                      </Row>
                    );
                  })}
                </Card>
              </Col>
            ) : (
              <></>
            )}
            {displayTelegramData ? (
              <Col lg={8} md={24} sm={24} xs={24}>
                <Card
                  title={
                    <Row>
                      <Col span={8}>Telegram</Col>
                    </Row>
                  }
                  extra={
                    <FontAwesomeIcon
                      icon={"fa-brands fa-telegram" as IconProp}
                      size={"lg"}
                    />
                  }
                  bordered={false}
                  loading={!telegramDataFetched}
                  style={{
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <Card.Grid style={gridStyleLeft}>
                    <b>Groups to Backup:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {telegramResponse
                      ? telegramResponse.groupsToBackup
                      : "Pending..."}
                  </Card.Grid>

                  <Card.Grid style={gridStyleLeft}>
                    <b>Groups:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {telegramResponse
                      ? telegramResponse.nbGroups
                      : "Pending..."}
                  </Card.Grid>

                  <Card.Grid style={gridStyleLeft}>
                    <b>Channels:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {telegramResponse
                      ? telegramResponse.nbChannels
                      : "Pending..."}
                  </Card.Grid>

                  <Card.Grid style={gridStyleLeft}>
                    <b>Messages Files OK:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {telegramResponse
                      ? telegramResponse.nbTotalMsgFilesRetrieved
                      : "Pending..."}
                  </Card.Grid>

                  <Card.Grid style={gridStyleLeft}>
                    <b>Messages Files Not OK:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {telegramResponse
                      ? telegramResponse.nbTotalMsgFilesNotRetrieved
                      : "Pending..."}
                  </Card.Grid>

                  <Card.Grid style={gridStyleLeft}>
                    <b>New Media Files:</b>
                  </Card.Grid>
                  <Card.Grid style={gridStyleRight}>
                    {telegramResponse
                      ? telegramResponse.nbMediaFilesRetrieved
                      : "Pending..."}
                  </Card.Grid>
                  {telegramResponse &&
                  telegramResponse.groupdNotRetrieved &&
                  telegramResponse.groupdNotRetrieved.length > 0 ? (
                    <React.Fragment>
                      <Card.Grid style={gridStyleLeft}>
                        <b>Groups not Retrieved:</b>
                      </Card.Grid>
                      <Card.Grid style={gridStyleRight}>
                        {telegramResponse
                          ? telegramResponse.groupdNotRetrieved
                          : "Pending..."}
                      </Card.Grid>
                    </React.Fragment>
                  ) : (
                    <></>
                  )}
                </Card>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Content>
      </Layout>
    </React.Fragment>
  );
}

export default Dashboard;
