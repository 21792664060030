import { Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OperationReport from "./OperationReport";

export default function OperationReportContainer() {
  const { tabName = "create" } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!["create"].includes(tabName)) {
      navigate("/bo/operation/report");
    }
  }, [navigate, tabName]);

  const [canCreate, setCanCreate] = useState<boolean>(false);

  const fetchCanCreate = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/can-create",
      withCredentials: true,
    }).then((res) => {
      if (!res.data.canCreate) {
        navigate("/");
      }
      setCanCreate(res.data.canCreate);
    });
  }, [navigate]);

  useEffect(() => {
    fetchCanCreate();
  }, [fetchCanCreate]);

  return (
    <Content id="operation-report-container">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/operation/report/" + key);
        }}
        activeKey={tabName}
        items={[
          {
            label: "Create Report",
            key: "create",
            disabled: !canCreate,
            children: (
              <React.Fragment>
                <OperationReport />
              </React.Fragment>
            ),
          },
        ]}
      />
    </Content>
  );
}
