import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import ViweOperationXTradeModal from "./ViewOperationXTradeModal";
import { toast } from "react-toastify";

export default function ViewXTrades() {
  const navigate = useNavigate();
  const [xTradeList, setXTradeList] = useState<any[]>([]);
  const [selectedXTrade, setSelectedXTrade] = useState<any>();
  const [showEditXTradeModal, setShowEditXTradeModal] =
    useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [updatingXTrade, setUpdatingXTrade] = useState<boolean>(false);
  const [selecetXTradeId, setSelectedXTradeId] = useState<any>();
  const [xTradesSelectedDetails, setXTradesSelectedDetails] = useState<any>();
  const [showOperationXTradeModal, setShowOperationXTradeModal] =
    useState<boolean>(false);
  const columns = [
    {
      title: "xTradeId",
      dataIndex: "xTradeId",
      width: "150px",
    },
    {
      title: "Label",
      dataIndex: "label",
      width: "150px",
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "300px",
      render: (_: any, { description }: any) => (
        <p style={{ whiteSpace: "pre-line", color: "black" }}>{description}</p>
      ),
    },
    {
      title: "Status",
      dataIndex: "operationStatus",
      width: "100px",
      align: "center" as const,
      render: (_: any, { operationStatus }: any) =>
        operationStatus?.name ? (
          <FontAwesomeIcon
            className={
              operationStatus?.name.toLocaleLowerCase() === "open"
                ? "all-open"
                : operationStatus?.name.toLocaleLowerCase() === "closed"
                ? "closed"
                : "some-open"
            }
            icon={
              (operationStatus?.name.toLocaleLowerCase() === "open" ||
              operationStatus?.name.toLocaleLowerCase() === "partially open"
                ? "fa-solid fa-lock-open"
                : "fa-solid fa-lock") as IconProp
            }
          />
        ) : (
          <></>
        ),
    },
    {
      title: "# of Operations",
      dataIndex: "links",
      width: "90px",
      align: "right" as const,
      render: (_: any, { links }: any) => links?.length,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "150px",
      align: "center" as const,
      render: (_: any, xTrade: any) => (
        <Row justify={"space-evenly"}>
          <Tooltip title="Detail">
            <Button
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
              onClick={() => {
                setSelectedXTradeId(xTrade.id);
                setShowOperationXTradeModal(true);
              }}
            >
              <FontAwesomeIcon icon={"fa-regular fa-eye" as IconProp} />
            </Button>
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              onClick={() => {
                setSelectedXTrade(xTrade);
                setShowEditXTradeModal(true);
              }}
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
            >
              <FontAwesomeIcon
                icon={"fa-regular fa-pen-to-square" as IconProp}
              />
            </Button>
          </Tooltip>
        </Row>
      ),
    },
  ];
  const fetchXTradeDetails = useCallback(() => {
    if (!selecetXTradeId) return;
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/item/x-trade",
      params: {
        xTradeIds: JSON.stringify([selecetXTradeId]),
      },
      withCredentials: true,
    }).then((res) => {
      setXTradesSelectedDetails(res.data);
    });
  }, [selecetXTradeId]);

  useEffect(() => {
    fetchXTradeDetails();
  }, [fetchXTradeDetails]);

  const fetchXTradeList = useCallback(() => {
    setTableLoading(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/x-trades",
      withCredentials: true,
    })
      .then((res) => {
        setXTradeList(res.data.xTrades);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setTableLoading(false);
      });
  }, [navigate]);

  const updateXTrade = useCallback(() => {
    if (!selectedXTrade) return;
    setUpdatingXTrade(true);
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/x-trade",
      withCredentials: true,
      data: {
        id: selectedXTrade.id,
        xTradeId: selectedXTrade.xTradeId,
        label: selectedXTrade.label,
        description: selectedXTrade.description,
      },
    })
      .then(() => {
        toast.success("Updated Successfully");
        setUpdatingXTrade(false);
        setShowEditXTradeModal(false);
        setSelectedXTrade(undefined);
      })
      .catch(() => {
        toast.error("Failed to Update");
      })
      .finally(() => {
        fetchXTradeList();
      });
  }, [selectedXTrade, fetchXTradeList]);

  useEffect(() => {
    fetchXTradeList();
  }, [fetchXTradeList]);
  return (
    <Content id="view-x-trades-list">
      <Table
        columns={columns}
        dataSource={xTradeList}
        rowKey={"xTradeId"}
        sticky
        scroll={{ x: 1500 }}
        loading={tableLoading}
      />
      <Modal
        open={showEditXTradeModal}
        onCancel={() => {
          setShowEditXTradeModal(false);
          setSelectedXTrade(undefined);
        }}
        footer={[]}
      >
        <ConfigProvider
          theme={{
            components: {
              Form: {
                labelColor: "white",
                labelFontSize: 16,
              },
            },
          }}
        >
          <Row justify={"center"}>
            <h2 style={{ color: "white" }}>Cross-Trade</h2>
          </Row>
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{
              marginTop: "40px",
            }}
          >
            <Form.Item label={<b>X-Trade Id</b>} required>
              <Input
                value={selectedXTrade?.xTradeId}
                onChange={(e) => {
                  selectedXTrade.xTradeId = e.target.value;
                  setSelectedXTrade({ ...selectedXTrade });
                }}
              />
            </Form.Item>
            <Form.Item label={<b>Label</b>} required>
              <Input
                value={selectedXTrade?.label}
                onChange={(e) => {
                  selectedXTrade.label = e.target.value;
                  setSelectedXTrade({ ...selectedXTrade });
                }}
              />
            </Form.Item>
            <Form.Item label={<b>Description</b>}>
              <TextArea
                rows={3}
                value={selectedXTrade?.description}
                onChange={(e) => {
                  selectedXTrade.description = e.target.value;
                  setSelectedXTrade({ ...selectedXTrade });
                }}
              />
            </Form.Item>
            <Row justify={"center"}>
              <Button
                onClick={() => {
                  setShowEditXTradeModal(false);
                  setSelectedXTrade(undefined);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  updateXTrade();
                }}
                loading={updatingXTrade}
              >
                Update
              </Button>
            </Row>
          </Form>
        </ConfigProvider>
      </Modal>

      <Modal
        open={showOperationXTradeModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setXTradesSelectedDetails(undefined);
          setShowOperationXTradeModal(false);
          setSelectedXTradeId(undefined);
        }}
      >
        <ViweOperationXTradeModal xtradeDetails={xTradesSelectedDetails} />
      </Modal>
    </Content>
  );
}
