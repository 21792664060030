import { Button, ConfigProvider, Form, Row, Select } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function QuoteAssignOperation({
  clientId,
  dclQuoteId,
  setSelectedClientId,
  setSelectedDclQuote,
  setShowAssignOperationModal,
  fetchdclDisplayedOrders,
  selectedOpId,
  setSelectedOpId,
}: any) {
  const [operationList, setOperationList] = useState<any[]>([]);
  const [selectedOperation, setSelectedOperation] = useState<any>();
  const [loadingOpList, setLoadingOpList] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const fetchOperationWithClientId = useCallback(() => {
    if (!clientId) return;
    setLoadingOpList(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations",
      params: {
        clients: JSON.stringify([clientId]),
        operationStatus: "Open",
      },
      withCredentials: true,
    })
      .then((res) => {
        setOperationList(
          res.data.operations.map((row: any) => ({
            value: row.id,
            key: row.id,
            label: row.label,
          })),
        );
      })
      .finally(() => {
        setLoadingOpList(false);
      });
  }, [clientId]);
  const submit = useCallback(
    (isDelete = false) => {
      setUpdating(true);
      axios({
        method: "Put",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/venue/dcl-order-data/operation",
        data: {
          dclQuoteId: String(dclQuoteId),
          operationId: String(selectedOperation),
          isDelete: isDelete,
        },
        withCredentials: true,
      })
        .then(() => {
          toast.success("Assigned Successfully");
        })
        .finally(() => {
          setSelectedOperation(undefined);
          setOperationList([]);
          setUpdating(false);
          setSelectedOpId(undefined);
          setSelectedClientId(undefined);
          setSelectedDclQuote(undefined);
          setShowAssignOperationModal(false);
          fetchdclDisplayedOrders({ initialFetch: false });
        });
    },
    [
      dclQuoteId,
      fetchdclDisplayedOrders,
      selectedOperation,
      setSelectedClientId,
      setSelectedDclQuote,
      setShowAssignOperationModal,
      setSelectedOpId,
    ],
  );
  useEffect(() => {
    if (selectedOpId) setSelectedOperation(selectedOpId);
  }, [selectedOpId]);
  useEffect(() => {
    fetchOperationWithClientId();
  }, [fetchOperationWithClientId]);
  return (
    <Content id="quote-assign-operation" style={{ marginTop: "2em" }}>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "white",
              itemMarginBottom: 6,
            },
          },
        }}
      >
        <Form.Item label={<b>Operation</b>}>
          <Select
            loading={loadingOpList}
            dropdownStyle={{
              backgroundColor: "white",
            }}
            options={operationList}
            value={selectedOperation}
            onChange={(e) => {
              setSelectedOperation(e);
            }}
            showSearch
            filterOption={(input, option: any) =>
              option.label
                ?.toLocaleLowerCase()
                .includes(input.toLocaleLowerCase())
            }
          />
        </Form.Item>
      </ConfigProvider>
      <Row justify={"end"} style={{ marginTop: "2em" }}>
        {selectedOpId ? (
          <Button
            onClick={() => {
              submit(true);
            }}
            danger
            loading={updating}
          >
            Unlink
          </Button>
        ) : (
          <></>
        )}
        <Button
          onClick={() => {
            submit();
          }}
          loading={updating}
          type="primary"
        >
          Sumbit
        </Button>
      </Row>
    </Content>
  );
}
