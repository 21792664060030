import { Button, Col, Row, Space, Spin, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DisplayContext } from "../../Context/displayContext";
import ViewEbankingBalance from "./ViewEbankingBalance";
import ViewEbankingBalanceDetails from "./ViewEbankingBalanceDetails";

export default function ViewEbankingBalanceContainer() {
  const { tabName = "overview" } = useParams();
  const navigate = useNavigate();

  const [isInstantFetching, setIsInstantFetching] = useState<boolean>(false);
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  const balanceFetchRef = useRef<any>(null);
  const [fetchDate, setFetchDate] = useState<Date>(new Date());
  const instantFetch = useCallback(() => {
    setIsInstantFetching(true);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking",
      withCredentials: true,
      data: {
        instantFetch: true,
      },
    })
      .then((res) => {
        setFetchDate(new Date(res.data.lastUpdated));
      })
      .finally(() => {
        setIsInstantFetching(false);
        if (balanceFetchRef.current) {
          balanceFetchRef.current();
        }
      });
  }, []);

  return (
    <Content id="view-ebanking-container">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Row className="dcl-filter-row" align="middle">
            <Button className="ant-btn-primary" onClick={instantFetch}>
              Instant Fetch
            </Button>
            <Space className="dcl-filter-row-space">
              <b>Last updated: </b>
              {fetchDate.toLocaleString("en-US", {
                timeZone: displayTimezone,
                timeZoneName: "short",
              })}
              {isInstantFetching ? <Spin /> : <></>}
            </Space>
          </Row>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/ebanking/balance/" + key);
        }}
        items={[
          {
            label: "Overview",
            key: "overview",
            children: (
              <ViewEbankingBalance setBalanceFetchRef={balanceFetchRef} />
            ),
          },
          {
            label: "Details",
            key: "detail",
            children: <ViewEbankingBalanceDetails />,
          },
        ]}
      />
    </Content>
  );
}
