import {
  Button,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export default function CreateNewLoan({
  loanDetail,
  createNewLoan,
  setOpenCreateModal,
}: any) {
  const [clientOptions, setClientOptions] = useState<any[]>();
  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);
  const [assetOptions, setAssetOptions] = useState<any>();
  const fetchAssetOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/",
      withCredentials: true,
    }).then((res) => {
      const options = [];
      for (const asset of res.data.assetList) {
        options.push({
          label: `${asset.name} (${asset.ticker})`,
          value: asset.id,
        });
      }
      setAssetOptions(options);
    });
  }, []);
  useEffect(() => {
    getClientOptions();
    fetchAssetOptions();
  }, [fetchAssetOptions, getClientOptions]);
  return (
    <Content id="create-new-loan-modal">
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "white",
            },
          },
        }}
      >
        <Row
          justify={"center"}
          style={{
            marginTop: "20px",
          }}
        >
          <h2 style={{ color: "white" }}>Loan</h2>
        </Row>
        <Form
          labelCol={{ span: 6 }}
          labelWrap
          wrapperCol={{ span: 18 }}
          style={{
            marginTop: "40px",
          }}
        >
          <Form.Item label={<b>Client</b>} required>
            <Select
              placeholder={"Client"}
              options={clientOptions}
              onChange={(e) => {
                loanDetail.clientId = e;
              }}
              showSearch
              style={{
                width: "60%",
                maxWidth: "100%",
              }}
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Date of Loan</b>} required>
            <DatePicker
              placeholder={"Date of Loan"}
              onChange={(e) => {
                loanDetail.dateOfLoan = e?.toISOString();
              }}
            />
          </Form.Item>
         
          <Form.Item label={<b>Loan Amount</b>} required>
            <InputNumber
              placeholder={"Loan Amount"}
              style={{
                width: "60%",
                maxWidth: "100%",
              }}
              controls={false}
              formatter={(value) => {
                const parts = `${value}`.split(".");
                // Apply the original regex only to the first part (integer part) to add commas.
                const integerPartWithCommas = parts[0].replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ",",
                );
                // Reassemble the parts, including the decimal part if it was present.
                return parts.length > 1
                  ? `${integerPartWithCommas}.${parts[1]}`
                  : integerPartWithCommas;
              }}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              onChange={(e) => (loanDetail.loanAmount = e)}
            />
          </Form.Item>

          <Form.Item label={<b>Loan Asset</b>} required>
            <Select
              placeholder={"Loan Asset"}
              onChange={(e) => {
                loanDetail.loanAssetId = e;
              }}
              style={{
                width: "60%",
                maxWidth: "100%",
              }}
              options={assetOptions}
              allowClear
              popupMatchSelectWidth={false}
              showSearch
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Interest Rate</b>} required>
            <InputNumber
              placeholder={"Interest Rate"}
              style={{
                width: "50%",
                maxWidth: "100%",
              }}
              addonAfter={<b style={{ color: "white" }}>%</b>}
              onChange={(e) => (loanDetail.interestRate = e)}
            />
          </Form.Item>
          <Form.Item
            label={<b>Loan Term</b>}
            required
            help={<p color="white">9M, 12M, 1Y, 24M... </p>}
          >
            <Input
              placeholder={"Loan Term"}
              style={{
                width: "50%",
                maxWidth: "100%",
              }}
              onChange={(e) => (loanDetail.loanTerm = e.target.value)}
            />
          </Form.Item>
          <Form.Item label={<b>Collateral Wallet Address</b>} required>
            <Input
              placeholder={"Collateral Wallet Address"}
              onChange={(e) => {
                loanDetail.custodyWalletAddress = e.target.value.trim();
              }}
              style={{
                width: "80%",
                maxWidth: "100%",
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Collateral Asset</b>} required>
            <Select
              placeholder={"Collateral Asset"}
              onChange={(e) => {
                loanDetail.collateralAssetId = e;
              }}
              style={{
                width: "60%",
                maxWidth: "100%",
              }}
              options={assetOptions?.filter((row: any) =>
                row.label.startsWith("Chainlink"),
              )}
              allowClear
              popupMatchSelectWidth={false}
              showSearch
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Initial Margion Ratio</b>} required>
            <InputNumber
              placeholder={"Initial Margion Ratio"}
              style={{
                width: "45%",
                maxWidth: "100%",
              }}
              addonAfter={<b style={{ color: "white" }}>%</b>}
              onChange={(e) => (loanDetail.initialMarginRatio = e)}
            />
          </Form.Item>
          <Form.Item label={<b>Margin Ratio Threshold</b>} required>
            <InputNumber
              placeholder={"Margin Ratio Threshold"}
              style={{
                width: "45%",
                maxWidth: "100%",
              }}
              addonAfter={<b style={{ color: "white" }}>%</b>}
              onChange={(e) => (loanDetail.marginRatioThreshold = e)}
            />
          </Form.Item>
          <Form.Item label={<b>Display Asset Price Url</b>} required>
            <Input
              placeholder={"Asset Price Url"}
              style={{
                width: "80%",
                maxWidth: "100%",
              }}
              onChange={(e) => (loanDetail.assetPriceUrl = e.target.value)}
            />
          </Form.Item>
        </Form>
        <Row justify={"center"}>
          <Button
            onClick={() => {
              setOpenCreateModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              createNewLoan();
            }}
          >
            Create
          </Button>
        </Row>
      </ConfigProvider>
    </Content>
  );
}
