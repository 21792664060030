import { Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CreateSubaccountTransfer from "./CreateSubaccountsTransfer";
import SubaccountTransferList from "./SubaccountTransferList";

function SubaccountTransferContainer() {
  const { tabName = "list" } = useParams();
  const navigate = useNavigate();
  const [canCreateSubaccountTransfer, setCanCreateSubaccountTransfer] =
    useState<boolean>(false);
  return (
    <Content id="subaccount-transfer-tabs">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/venues/subaccount_transfer/" + key);
        }}
        items={[
          {
            label: "Historical Transfers",
            key: "list",
            children: (
              <SubaccountTransferList
                canCreateSubaccountTransfer={canCreateSubaccountTransfer}
                setCanCreateSubaccountTransfer={setCanCreateSubaccountTransfer}
              />
            ),
          },
          {
            label: "Create Transfer",
            key: "create-transfer",
            disabled: !canCreateSubaccountTransfer,
            children: (
              <React.Fragment>
                <CreateSubaccountTransfer
                canCreateSubaccountTransfer={canCreateSubaccountTransfer}
                setCanCreateSubaccountTransfer={setCanCreateSubaccountTransfer}
                />
              </React.Fragment>
            ),
          },
        ]}
      />
    </Content>
  );
}
export default SubaccountTransferContainer;
