import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { tailFormItemLayout } from "../UserPages/CreateUser";

function CreateAsset() {
  const location = useLocation();
  const navigate = useNavigate();
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [assetTypeList, setAssetTypeList] = useState<any[]>();
  const [assetApiUrlTypeList, setAssetApiUrlTypeList] = useState<any[]>();
  const [assetApiUrlEnvironmentList, setAssetApiUrlEnvironmentList] =
    useState<any[]>();
  const [assetNetworkList, setApiNetworkList] = useState<any>();
  const [newAPIUrls, setNewAPIUrls] = useState<any[]>([]);
  const [form] = useForm();

  const [venuePairs, setVenuePairs] = useState<any[]>([]);
  const [venueOptions, setVenueOptions] = useState<any[]>();
  const [assetOptions, setAssetOptions] = useState<any>();

  const [assetTickerVenuePairList, setAssetTickerVenuePairList] = useState<
    any[]
  >([]);

  const [selectedNetworks, setSelectedNetworks] = useState<any[]>([]);
  const [selectedNetworkOptions, setSelectedNetworkOptions] = useState<any[]>(
    [],
  );

  const onNetworkChange = useCallback(
    (e: any) => {
      const networkIdsInUse = [
        ...new Set(
          [
            ...newAPIUrls.map((row) => Number(row.assetNetworkId)),
            ...newAPIUrls.map(
              (row) => row.assetAssetNetworkMap?.assetNetworkId,
            ),
          ].filter((n) => !isNaN(n)),
        ),
      ];
      const temp = [...new Set([...e, ...networkIdsInUse])];
      if (e.length < temp.length) {
        toast.warn(
          "Cannot Remove this network. It is used by at leasat one api url. Please remove the url first.",
        );
      }
      form.setFieldValue("assetNetworks", temp);
      setSelectedNetworks(temp);
    },
    [newAPIUrls, form],
  );

  const fetchAssetOptions = useCallback(() => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);
  const fetchVenueOptions = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueOptions(res.data.list);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);
  useEffect(() => {
    fetchAssetOptions();
    fetchVenueOptions();
  }, [fetchAssetOptions, fetchVenueOptions]);

  const addNewPair = useCallback(() => {
    venuePairs.push({
      venueId: undefined,
      counterCurrencyId: undefined,
      pair: "",
      type: "tickerPrice",
    });
    setVenuePairs([...venuePairs]);
  }, [venuePairs]);

  const updateATVPairValue = useCallback(
    (
      venuePair: any,
      field: string,
      value: number | string | { toFetch: boolean; ticker: string },
    ) => {
      const index = assetTickerVenuePairList.indexOf(venuePair);
      assetTickerVenuePairList[index][field] = value;
      setAssetTickerVenuePairList([...assetTickerVenuePairList]);
    },
    [assetTickerVenuePairList],
  );
  const deleteAtvPair = useCallback(
    (pair: any) => {
      const newPairs = [...assetTickerVenuePairList];
      const index = newPairs.indexOf(pair);
      newPairs.splice(index, 1);
      setAssetTickerVenuePairList(newPairs);
    },
    [assetTickerVenuePairList],
  );

  const fetchAssetTypeList = useCallback(() => {
    setOnFinishLoad(false);

    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/asset-type",
      withCredentials: true,
    })
      .then((res) => {
        setAssetTypeList(res.data);
      })
      .catch((err) => {
        console.error("Failed to fetch asset type");
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);

  const fetchAssetApiUrlUtils = useCallback(() => {
    setOnFinishLoad(false);

    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/asset-url-utils",
      withCredentials: true,
    })
      .then((res) => {
        setAssetApiUrlTypeList(res.data.apiTypes);
        setAssetApiUrlEnvironmentList(res.data.environments);
        setApiNetworkList(res.data.networks);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);

  const onFinish = (form: any) => {
    setOnFinishLoad(false);
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/create",
      withCredentials: true,
      data: {
        name: form.name,
        ticker: form.ticker,
        assetType: form.assetType,
        isActive: form.isActive,
        isStablecoin: form.isStablecoin,
        // isTrading: form.isTrading,
        // isCustody: form.isCustody,
        // isStaking: form.isStaking,
        coingeckoId: form.coingeckoId,
        assetNetworks: form.assetNetworks,
        venuePairs: venuePairs,
        apiUrls: newAPIUrls,
        atvPairs: assetTickerVenuePairList,
      },
    })
      .then((res) => {
        navigate("/bo/confirmation", {
          state: {
            pageType: "Asset",
            createOrUpdate: "Create",
            displayName: form.name,
            createUrl: "/bo/asset/create",
            editUrl: "/bo/asset/update/" + res.data.id,
          },
        });
        toast.success("Asset Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    toast.error("Please fill required fields ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    fetchAssetTypeList();
    fetchAssetApiUrlUtils();
  }, [fetchAssetTypeList, fetchAssetApiUrlUtils]);

  useEffect(() => {
    if (assetNetworkList && assetNetworkList.length) {
      const filteredNetworkOptions = assetNetworkList
        .filter((row: any) => selectedNetworks.includes(row.id))
        .map((row: any) => ({
          label: row.name,
          value: row.id,
          name: row.name,
        }));
      setSelectedNetworkOptions(filteredNetworkOptions);
    }
  }, [assetNetworkList, selectedNetworks]);

  return onFinshiLoad ? (
    <React.Fragment>
      <Content id="create-asset-page">
        <Row>
          <Button
            onClick={() => {
              if (location.state) {
                navigate(location.state.from);
              } else {
                navigate("/bo/users");
              }
            }}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-chevron-left" as IconProp}
            />
          </Button>
        </Row>
        <Form
          form={form}
          labelWrap
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="form-body"
          labelCol={{
            sm: {
              span: 8,
              offset: 0,
            },
          }}
          wrapperCol={{
            sm: {
              span: 16,
              offset: 0,
            },
          }}
          style={{
            padding: "1em",
            margin: "auto",
          }}
        >
          <Row justify="center" align="middle">
            <h2>Create Asset</h2>
          </Row>
          <Row>
            <Col sm={{ span: 9, order: 1 }} xs={{ span: 24, order: 1 }}>
              <Form.Item
                label="Asset Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input asset name",
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
              <Form.Item
                label="Asset Ticker"
                name="ticker"
                rules={[
                  {
                    required: true,
                    message: "Please input asset ticker",
                  },
                ]}
              >
                <Input placeholder="Ticker" />
              </Form.Item>
              <Form.Item label="Asset Type" name="assetType">
                <Select placeholder="Asset Type" popupMatchSelectWidth={false}>
                  {assetTypeList?.map((assetType) => (
                    <Select.Option
                      value={assetType["id"]}
                      key={assetType["id"]}
                      id={assetType["id"]}
                    >
                      {assetType["name"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="isStablecoin"
                label="Is Stablecoin"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col sm={{ span: 8, order: 2 }} xs={{ span: 24, order: 2 }}>
              <Form.Item name="assetNetworks" label="Network">
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={assetNetworkList}
                  placeholder="Network"
                  value={selectedNetworks}
                  onChange={(e) => onNetworkChange(e)}
                />
              </Form.Item>
              <Form.Item label="Coingecko ID" name="coingeckoId">
                <Input placeholder="Coingecko ID" />
              </Form.Item>
            </Col>
            <Col sm={{ span: 7, order: 3 }} xs={{ span: 24, order: 3 }}>
              <Form.Item
                name="isActive"
                label="Is Active"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              {/* <Form.Item
                name="isTrading"
                label="Is Trading"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="isCustody"
                label="Is Custody"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                name="isStaking"
                label="Is Staking"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item> */}
            </Col>
          </Row>
          <Divider orientation="left">Venue Tickers</Divider>
          {assetTickerVenuePairList?.map((atvPair: any, i: number) => (
            <Row
              key={`atvp_${i}`}
              justify={"space-evenly"}
              style={{ marginBottom: "1em" }}
            >
              <Col lg={6} md={24} sm={24} xs={24}>
                <Select
                  value={atvPair.venueId}
                  onChange={(e) => {
                    updateATVPairValue(atvPair, "venueId", e);
                  }}
                  className="dcl-venue-select"
                  style={{ width: "100%" }}
                  options={venueOptions}
                  allowClear
                  placeholder="Venue"
                  showSearch
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Col>
              <Col lg={6} md={24} sm={24} xs={24}>
                <Input
                  placeholder="Pair Name"
                  value={atvPair.tickerValue}
                  onChange={(e) =>
                    updateATVPairValue(atvPair, "tickerValue", e.target.value)
                  }
                />
              </Col>
              <Col lg={6} md={24} sm={24} xs={24}>
                <Switch
                  title="Fetch on venue"
                  defaultChecked={true}
                  value={atvPair.json?.toFetch}
                  onChange={(e) =>
                    updateATVPairValue(atvPair, "json", {
                      ticker: atvPair.tickerValue,
                      toFetch: e,
                    })
                  }
                />
              </Col>
              <Col lg={1} md={24} sm={24} xs={24}>
                <Button
                  danger
                  style={{ marginTop: 0 }}
                  onClick={() => deleteAtvPair(atvPair)}
                >
                  <MinusCircleOutlined style={{ color: "red" }} />
                </Button>
              </Col>
            </Row>
          ))}
          <Row justify={"space-evenly"} align="middle">
            <Button
              type="dashed"
              block
              icon={<PlusOutlined />}
              onClick={() => {
                const newList = [...assetTickerVenuePairList];
                newList.push({
                  id: new Date().getUTCMilliseconds(),
                  assetId: undefined,
                  venueId: undefined,
                  tickerValue: "",
                  json: {
                    ticker: "",
                    toFetch: true,
                  },
                });
                setAssetTickerVenuePairList(newList);
              }}
            >
              Add a ticker on venue
            </Button>
          </Row>
          <Divider orientation="left">API URLs</Divider>

          {newAPIUrls.map((newApiUrl: any) => (
            <React.Fragment key={newApiUrl.id}>
              <Card style={{ margin: "5px" }}>
                <Row align="middle" justify={"space-between"}>
                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Type"
                      popupMatchSelectWidth={false}
                      defaultValue={newApiUrl.apiTypeId}
                      onChange={(e: any) => (newApiUrl.apiTypeId = e)}
                    >
                      {assetApiUrlTypeList?.map((apiUrlType) => (
                        <Select.Option
                          key={apiUrlType.name}
                          value={apiUrlType.id}
                        >
                          {apiUrlType.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Envrionment"
                      popupMatchSelectWidth={false}
                      defaultValue={newApiUrl.environmentId}
                      onChange={(e: any) => (newApiUrl.environmentId = e)}
                    >
                      {assetApiUrlEnvironmentList?.map((nework) => (
                        <Select.Option key={nework.name} value={nework.id}>
                          {nework.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Asset URL Network"
                      options={selectedNetworkOptions}
                      onChange={(e: any) => {
                        newApiUrl.assetNetworkId = e;
                      }}
                    />
                  </Col>
                  <Col lg={5} md={5} sm={12} xs={12}>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="Method"
                      popupMatchSelectWidth={false}
                      defaultValue={newApiUrl.method}
                      onChange={(e: any) => (newApiUrl.method = e)}
                    >
                      <Select.Option key={"POST"} value={true}>
                        POST
                      </Select.Option>
                      <Select.Option key={"GET"} value={false}>
                        GET
                      </Select.Option>
                    </Select>
                  </Col>
                </Row>
                <Row
                  align="middle"
                  justify={"space-around"}
                  style={{ marginTop: "5px" }}
                >
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <TextArea
                      placeholder="Url"
                      onChange={(e) => {
                        newApiUrl.url = e.target.value;
                      }}
                    />
                  </Col>
                </Row>
                <Row
                  align="middle"
                  justify={"space-around"}
                  style={{ marginTop: "5px" }}
                >
                  <Col lg={18} md={18} sm={24} xs={24}>
                    {newApiUrl.dictionaries?.map((param: any) => (
                      <Row key={param.id}>
                        <Space className="create-asset-dictionary-space">
                          <Select
                            style={{ width: "150px" }}
                            defaultValue={param.key}
                            options={[
                              { key: "Input", value: "Input", label: "Input" },
                              {
                                key: "Output",
                                value: "Output",
                                label: "Output",
                              },
                            ]}
                            onChange={(e) => {
                              param.key = e;
                            }}
                          />
                        </Space>
                        <Space className="create-asset-dictionary-space">
                          <TextArea
                            style={{ minWidth: "400px" }}
                            defaultValue={param.value}
                            placeholder="Dictionary Value"
                            onChange={(e) => {
                              param.value = e.target.value;
                            }}
                          />
                        </Space>
                        <Space className="create-asset-dictionary-space">
                          <MinusCircleOutlined
                            onClick={() => {
                              newApiUrl.dictionaries.splice(
                                newApiUrl.dictionaries.indexOf(param),
                                1,
                              );
                              setNewAPIUrls([...newAPIUrls]);
                            }}
                          />
                        </Space>
                      </Row>
                    ))}
                  </Col>
                  <Col lg={4} md={4} sm={24} xs={24}>
                    <Button
                      onClick={() => {
                        newApiUrl.dictionaries.push({
                          id: new Date().getUTCMilliseconds(),
                          key: "",
                          value: "",
                          newlyCreated: true,
                        });
                        setNewAPIUrls([...newAPIUrls]);
                      }}
                    >
                      Add Dictionary
                    </Button>
                    <Button
                      danger
                      onClick={() => {
                        newAPIUrls.splice(newAPIUrls.indexOf(newApiUrl), 1);
                        setNewAPIUrls([...newAPIUrls]);
                      }}
                    >
                      Delete URL
                    </Button>
                  </Col>
                </Row>
              </Card>
            </React.Fragment>
          ))}
          <Row justify="center" align="middle">
            <Button
              type="dashed"
              onClick={() => {
                const newList = [...newAPIUrls];
                newList.push({
                  id: new Date().getUTCMilliseconds(),
                  apiTypeId: 1,
                  environmentId: 1,
                  assetNetworkId: undefined,
                  method: "GET",
                  dictionaries: [{ id: 0, key: "", value: "" }],
                });
                setNewAPIUrls(newList);
              }}
              block
              icon={<PlusOutlined />}
            >
              Add a URL
            </Button>
          </Row>
          <Divider orientation="left">Venue Pairs</Divider>
          {venuePairs.map((venuePair: any, i: number) => (
            <Row
              key={`vp_${i}`}
              justify={"space-evenly"}
              style={{ marginBottom: "1em" }}
            >
              <Col span={6}>
                <Select
                  defaultValue={venuePair.venueId}
                  onChange={(e) => {
                    venuePair.venueId = e;
                  }}
                  className="dcl-venue-select"
                  style={{ width: "100%" }}
                  options={venueOptions}
                  allowClear
                  placeholder="Venue"
                  showSearch
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Col>
              <Col span={6}>
                <Select
                  defaultValue={venuePair.counterCurrencyId}
                  onChange={(e) => {
                    venuePair.counterCurrencyId = e;
                  }}
                  className="dcl-asset-select"
                  options={assetOptions}
                  style={{ width: "100%" }}
                  allowClear
                  placeholder="Counter Currency"
                  popupMatchSelectWidth={false}
                  showSearch
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Col>
              <Col span={8}>
                <Input
                  placeholder="Pair Name"
                  defaultValue={venuePair.pair}
                  onChange={(e) => (venuePair.pair = e.target.value)}
                />
              </Col>
            </Row>
          ))}
          <Row justify={"space-evenly"} align="middle">
            <Button
              type="dashed"
              block
              icon={<PlusOutlined />}
              onClick={addNewPair}
            >
              Add a Pair
            </Button>
          </Row>
          <Divider />

          <Form.Item {...tailFormItemLayout}>
            <Button htmlType="submit" className="submit-button primary-button">
              Create Asset
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </React.Fragment>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
export default CreateAsset;
