import { Card, Col, Divider, Row, Space, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";

export default function ViewFeePolicyDetail({ feePolicyJSON }: any) {
  return (
    <Content id="view-fee-policy-detail">
      <Row>
        <Col span={24}>
          <Card title={feePolicyJSON.feePolicyName}>
            <Row>
              <Col span={5} offset={3}>
                <b>Fees Below</b>
                {`: ${feePolicyJSON.feesBelow.toLocaleString("en-us", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </Col>
              <Col span={5} offset={3}>
                <b>DCL Minimum Fee</b>
                {`: ${feePolicyJSON.feesBelowDclFee.toLocaleString("en-us", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </Col>
              <Col span={5} offset={3}>
                <b>Venue Minimum Fee</b>
                {`: ${feePolicyJSON.feesBelowVenueFee.toLocaleString("en-us", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              </Col>
            </Row>
            <Divider />
            {feePolicyJSON.rangeFees && feePolicyJSON.rangeFees.length ? (
              <React.Fragment>
                <Table
                  rowKey={"from"}
                  dataSource={feePolicyJSON.rangeFees}
                  pagination={{ hideOnSinglePage: true }}
                  columns={[
                    {
                      title: "Range",
                      dataIndex: "range",
                      render: (_: any, { from, to }: any) => (
                        <Space>
                          {from}-{to}
                        </Space>
                      ),
                    },
                    {
                      title: "DCL Fees",
                      dataIndex: "dclFees",
                      align: "right" as const,
                      render: (_: any, { dclFees, dclFeeType }: any) => (
                        <Space>
                          {dclFees} {dclFeeType === "dollar" ? "Dollar" : "BPS"}
                        </Space>
                      ),
                    },
                    {
                      title: "Venue Fees",
                      dataIndex: "venueFee",
                      align: "right" as const,
                      render: (_: any, { venueFee, venueFeeType }: any) => (
                        <Space>
                          {venueFee}{" "}
                          {venueFeeType === "dollar" ? "Dollar" : "BPS"}
                        </Space>
                      ),
                    },
                  ]}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Table
                  rowKey={"id"}
                  dataSource={[feePolicyJSON]}
                  pagination={{ hideOnSinglePage: true }}
                  columns={[
                    {
                      title:
                        feePolicyJSON.dclFeeType === "bps"
                          ? "DCL Fees (BPS)"
                          : "DCL Fees ($)",
                      dataIndex: "dclFees",
                      align: "right" as const,
                      render: (_: any, { dclFees, dclFeeType }: any) => (
                        <Space>
                          {dclFees}
                          {dclFeeType === "bps" ? "BPS" : "Dollar"}
                        </Space>
                      ),
                    },
                    {
                      title:
                        feePolicyJSON.venueFeeType === "bps"
                          ? "Venue Fees (BPS)"
                          : "Venue Fees ($)",
                      dataIndex: "venueFee",
                      align: "right" as const,
                      render: (_: any, { venueFee, venueFeeType }: any) => (
                        <Space>
                          {venueFee} {venueFeeType === "bps" ? "BPS" : "Dollar"}
                        </Space>
                      ),
                    },
                  ]}
                />
              </React.Fragment>
            )}
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
