import { Button, Col, DatePicker, Divider, List, Row, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import { Buffer } from "buffer";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { DisplayContext } from "../../../Context/displayContext";
const { RangePicker } = DatePicker;

export default function TelegramBackupDownload() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const [clientOptions, setClientOptions] = useState<any[]>();
  const [selectedClient, setSelectClient] = useState<any>();
  const [dates, setDates] = useState<any>();
  const [selectedDateRange, setSelectedDateRange] = useState<any>();
  const [selectedMediaType, setSelectedMediaType] = useState<String>("csv");
  const [fileList, setFileList] = useState<any>();
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [fileCounts, setFileCounts] = useState<number>(0);
  const [fileDownloading, setFileDownloading] = useState<any>({});

  const handleClientSelect = useCallback((option: any, optionElement: any) => {
    setSelectClient(option);
  }, []);

  const disabledDate = (current: any) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && dates[0].year() !== current.year();
    const tooEarly = dates[1] && dates[1].year() !== current.year();
    return (
      !!tooEarly ||
      !!tooLate ||
      !!(current > dayjs().endOf("day")) ||
      !!(current < dayjs("2022-07-13").startOf("day"))
    );
  };

  const onOpenChange = (open: any) => {
    if (open) {
      setDates(selectedDateRange);
    } else {
      setDates(null);
    }
  };

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }
        const clientsWithTelegramGroup = res.data.clients.filter(
          (t: any) => t.telegramGroup,
        );
        setClientOptions(clientsWithTelegramGroup);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  const downloadBackup = useCallback(
    (fileName: string) => {
      fileDownloading[fileName] = true;
      setFileDownloading({
        ...fileDownloading,
      });
      axios({
        method: "GET",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/self/api/download-telegram-backup",
        withCredentials: true,
        params: {
          fileName,
        },
      })
        .then((res) => {
          const t: number[] = Object.values(res.data);
          const buffer = Buffer.from(t);
          const blob = new Blob([buffer]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            fileName.split("/")[fileName.split("/").length - 1],
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        })
        .finally(() => {
          fileDownloading[fileName] = false;
          setFileDownloading({ ...fileDownloading });
        });
    },
    [fileDownloading],
  );

  const fetchFileList = useCallback(() => {
    if (!selectedDateRange) return;
    const [startDate, endDate] = selectedDateRange;
    const startDateString = startDate.format("YYYY-MM-DD");
    const endDateString = endDate.format("YYYY-MM-DD");
    let result: any[] = [];
    setFetchingData(true);
    setDataFetched(false);
    if (startDateString.split("-")[0] !== endDateString.split("-")[0]) {
      let startYear = Number(startDateString.split("-")[0]);
      while (startYear <= Number(endDateString.split("-")[0])) {
        const startDateString = dayjs(`${startYear}-01-01`)
          .startOf("year")
          .format("YYYY-MM-DD");
        let endDateString = dayjs(`${startYear}-01-01`)
          .endOf("year")
          .format("YYYY-MM-DD");
        if (dayjs(endDateString) > endDate) {
          endDateString = endDate.format("YYYY-MM-DD");
        }
        axios({
          method: "GET",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL +
            "/self/api/telegram-backup-file-list",
          withCredentials: true,
          params: {
            startDate: startDateString,
            endDate: endDateString,
            clientId: selectedClient,
            mediaType: selectedMediaType,
          },
        })
          .then((res) => {
            if (res.data) {
              res.data.forEach((t: any) => {
                result.push(t);
              });
            }
            if (startDateString.split("-")[0] === endDateString.split("-")[0]) {
              result.sort(
                (prev: any, curr: any) =>
                  dayjs(
                    curr.Key.split("/")[curr.Key.split("/").length - 1].split(
                      "-",
                    )[0],
                    "YYYYMMDD",
                  ).valueOf() -
                  dayjs(
                    prev.Key.split("/")[prev.Key.split("/").length - 1].split(
                      "-",
                    )[0],
                    "YYYYMMDD",
                  ).valueOf(),
              );
              setFileList(result);
              setFetchingData(false);
              setDataFetched(true);
              setFileCounts(result.length);
            }
          })
          .catch((e) => {
            toast.error(e.response.data.message);
          });
        if (selectedMediaType === "media") break;
        startYear += 1;
      }
    } else {
      const endDateString = endDate.format("YYYY-MM-DD");
      axios({
        method: "GET",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/self/api/telegram-backup-file-list",
        withCredentials: true,
        params: {
          startDate: startDateString,
          endDate: endDateString,
          clientId: selectedClient,
          mediaType: selectedMediaType,
        },
      })
        .then((res) => {
          if (res.data) {
            res.data.sort(
              (prev: any, curr: any) =>
                dayjs(
                  curr.Key.split("/")[curr.Key.split("/").length - 1].split(
                    "-",
                  )[0],
                  "YYYYMMDD",
                ).valueOf() -
                dayjs(
                  prev.Key.split("/")[prev.Key.split("/").length - 1].split(
                    "-",
                  )[0],
                  "YYYYMMDD",
                ).valueOf(),
            );

            setFileList(res.data);
            setFileCounts(res.data.length);
          } else {
            setFileList([]);
            setFileCounts(0);
          }
          setFetchingData(false);
          setDataFetched(true);
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        });
    }
  }, [selectedDateRange, selectedClient, selectedMediaType]);

  useEffect(() => {
    getClientOptions();
  }, [getClientOptions]);

  return (
    <Content id="download-telegram-backup">
      <Row className="dcl-filter-row" align="middle">
        <Col
          xl={{ span: 4, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <RangePicker
            className="dcl-daterange-select"
            onOpenChange={onOpenChange}
            onCalendarChange={(val) => {
              setDates(val);
            }}
            disabledDate={disabledDate}
            onChange={(e) => {
              setSelectedDateRange(e);
            }}
          />
        </Col>
        <Col
          xl={{ span: 4, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Select
            placeholder={"Client"}
            options={clientOptions}
            onSelect={handleClientSelect}
            value={selectedClient}
            showSearch
            style={{
              width: "100%",
              maxWidth: '100%'
            }}
            filterOption={(input, option: any) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Col>
        <Col
          xl={{ span: 4, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Select
            placeholder={"Media Type"}
            options={[
              { key: "csv", label: "CSV", value: "csv" },
              { key: "media", label: "Media", value: "media" },
            ]}
            onChange={(e) => setSelectedMediaType(e)}
            value={selectedMediaType}
            showSearch
            style={{
              width: "100%",
              maxWidth: '100%'
            }}
            filterOption={(input, option: any) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Col>
        <Col
          xl={{ span: 4, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Button
            disabled={!(selectedClient && selectedDateRange)}
            loading={fetchingData}
            type="primary"
            onClick={fetchFileList}
          >
            Get File List
          </Button>
        </Col>
        <Col
          xl={{ span: 3, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          {dataFetched ? <b>Found {fileCounts} files</b> : <></>}
        </Col>
      </Row>
      <Row>
        {dataFetched ? (
          <React.Fragment>
            <Divider />
            <List
              loading={fetchingData}
              dataSource={fileList}
              style={{ width: "100%" }}
              header={
                <Row>
                  <Col span={10} offset={1}>
                    <b>File Name</b>
                  </Col>
                  <Col span={4}>
                    <b>Size (Bytes)</b>
                  </Col>
                  <Col span={4}>
                    <b>Last Modified</b>
                  </Col>
                </Row>
              }
              renderItem={(item: any) => (
                <List.Item>
                  <Col span={10} offset={1}>
                    {item.Key.split("/")[item.Key.split("/").length - 1]}
                  </Col>
                  <Col span={4}>{item.Size}</Col>
                  <Col span={4}>
                    {new Date(item.LastModified)
                      .toLocaleString("en-US", {
                        timeZone: displayTimezone,
                        timeZoneName: "short",
                      })
                      .split(", ")
                      .join(" ")}
                  </Col>
                  <Col span={5}>
                    <Button
                      size={"small"}
                      onClick={() => downloadBackup(item.Key)}
                      loading={fileDownloading[item.Key] === true}
                    >
                      Download
                    </Button>
                  </Col>
                </List.Item>
              )}
            />
          </React.Fragment>
        ) : (
          <></>
        )}
      </Row>
    </Content>
  );
}
