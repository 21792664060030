import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button, Form, Input, Layout, Modal, Row,
  Space, Spin, Table
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
function EmailSettings() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<any>();
  const [emailSettings, setEmailSettings] = useState<any[]>([]);
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [deletedRows, setDeletedRows] = useState<any[]>([]);

  const openDeleteConfirmModal = (id: any) => {
    setIsModalOpen(true);
    setSelectedRowId(id);
  };

  const deleteRow = (id: any) => {
    setOnFinishLoad(false);
    const newEmailSettings = [...emailSettings];
    const newDeltedRows = [...deletedRows];
    const rowToDelete = newEmailSettings.find((setting) => setting.id === id);
    if (!rowToDelete.newRow) {
      newDeltedRows.push(rowToDelete);
    }
    setDeletedRows(newDeltedRows);
    newEmailSettings.splice(newEmailSettings.indexOf(rowToDelete), 1);
    setEmailSettings(newEmailSettings);
    setOnFinishLoad(true);
  };

  const updateEmailFields = (
    id: number,
    fieldName: string,
    fieldValue: string,
  ) => {
    const newEmailSettings = [...emailSettings];
    const row = newEmailSettings.filter((obj) => obj.id === id)[0];
    row[fieldName] = fieldValue;
    row.edited = true;
    setEmailSettings(emailSettings);
  };

  const submit = () => {
    const editedRows = emailSettings.filter(
      (e) => e.edited === true && !e.newRow,
    );
    const newRows = emailSettings.filter((e) => e.newRow);

    setOnFinishLoad(false);
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/emails",
      withCredentials: true,
      data: {
        editedRows: editedRows,
        newRows: newRows,
        deletedRows: deletedRows,
      },
    })
      .then(() => {
        toast.success("Email settings updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
        fetchData();
      });
  };

  const insertNewRow = () => {
    setOnFinishLoad(false);
    const newEmailSettings = [...emailSettings];
    const newRow = {
      id: `temp_${newEmailSettings.length + 1}`,
      key: `New key ${newEmailSettings.length + 1}`,
      subject: "",
      emailFrom: "",
      emailFromName: "",
      body: "",
      newRow: true,
    };
    newEmailSettings.unshift(newRow);
    setEmailSettings(newEmailSettings);
    setOnFinishLoad(true);
  };

  const fetchData = () => {
    setOnFinishLoad(false);
    setDeletedRows([]);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/emails",
      withCredentials: true,
    })
      .then((res) => {
        res.data.forEach((e: any) => {
          e.key = e.id;
          e.edited = false;
        });
        setEmailSettings(res.data);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      width: "2%",
      editable: false,
      render: (text: any) => <strong>{text}</strong>,
    },
    {
      title: "Email Name",
      dataIndex: "name",
      width: "6%",
      editable: false,
      render: (text: any, { id }: any) => (
        <Input
          defaultValue={text}
          onChange={(e: any) => {
            updateEmailFields(id, "name", e.target.value);
          }}
        />
      ),
    },
    {
      title: "Subject",
      dataIndex: "subject",
      width: "7%",
      editable: false,
      render: (text: any, { id }: any) => (
        <TextArea
          defaultValue={text}
          rows={3}
          onChange={(e: any) => {
            updateEmailFields(id, "subject", e.target.value);
          }}
        />
      ),
    },
    {
      title: "Email From",
      dataIndex: "emailFrom",
      width: "5%",
      editable: false,
      render: (text: any, { id }: any) => (
        <Input
          defaultValue={text}
          onChange={(e: any) => {
            updateEmailFields(id, "emailFrom", e.target.value);
          }}
        />
      ),
    },
    {
      title: "Email From Name",
      dataIndex: "emailFromName",
      width: "5%",
      editable: false,
      render: (text: any, { id }: any) => (
        <Input
          defaultValue={text}
          onChange={(e: any) => {
            updateEmailFields(id, "emailFromName", e.target.value);
          }}
        />
      ),
    },
    {
      title: "Body",
      dataIndex: "body",
      width: "10%",
      editable: false,
      render: (text: any, { id }: any) => (
        <TextArea
          defaultValue={text}
          rows={3}
          onChange={(e: any) => {
            updateEmailFields(id, "body", e.target.value);
          }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      width: "4%",
      editable: false,
      render: (_: any, { id }: any) => (
        <Space>
          <Button
            onClick={() => {
              openDeleteConfirmModal(id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);
  return onFinshiLoad ? (
    <Content>
      <Row justify={"end"} style={{ marginRight: "10px" }}>
        <Button
          type="primary"
          onClick={() => {
            insertNewRow();
          }}
        >
          <FontAwesomeIcon
            icon={"fa-solid fa-plus" as IconProp}
            className="white-plus"
          />
          &nbsp; New Email
        </Button>
      </Row>
      <Form component={false}>
        <Table
          columns={columns}
          //rowKey={(record) => record.id}
          dataSource={emailSettings}
          scroll={{ x: 1200 }}
          sticky
        />
      </Form>
      <Row justify="center">
        <Space>
          <Button
            onClick={() => {
              fetchData();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              submit();
            }}
          >
            Save
          </Button>
        </Space>
      </Row>
      <Modal
        getContainer={false}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onOk={() => {
          deleteRow(selectedRowId);
          setIsModalOpen(false);
        }}
      >
        <p>
          Are you sure you want to delete the email settings's row{" "}
          {selectedRowId}?
        </p>
        <p>
          Click the Ok button and then click the Save button at the end of the
          page to save the updates.
        </p>
      </Modal>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
export default EmailSettings;
