import { Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DisplayContext } from "../../Context/displayContext";
import ViewClientOrders from "./ViewClientOrders";
import ViewMasterLedgerEBankingLedger from "./ViewMasterLedgerEbankingLedger";
import ViewExchangeLedger from "./ViewExchangeLedger";
import ViewOpenOrders from "./ViewOpenOrders";

export default function ViewMasterLedger() {
  const { tabName = "client-orders" } = useParams();
  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayContext, displayTimezone]);

  return (
    <Content id="delchain-master-ledger">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/delchain_master_ledger/" + key);
        }}
        items={[
          {
            label: "Client Closed Orders",
            key: "client-orders",
            children: (
              <React.Fragment>
                <ViewClientOrders />
              </React.Fragment>
            ),
          },
          {
            label: "Exchange Ledger",
            key: "exchange-ledger",
            children: (
              <React.Fragment>
                <ViewExchangeLedger />
              </React.Fragment>
            ),
          },
          {
            label: "Ebanking Ledger",
            key: "ebanking-ledger",
            children: (
              <React.Fragment>
                <ViewMasterLedgerEBankingLedger />
              </React.Fragment>
            ),
          },
          {
            label: "Client Open Orders",
            key: "client-open-orders",
            children: (
              <React.Fragment>
                <ViewOpenOrders />
              </React.Fragment>
            ),
          },
        ]}
      ></Tabs>
    </Content>
  );
}
