import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Divider, Row, Switch } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../Context/displayContext";
function UserInfo() {
  const displayContext = useContext(DisplayContext);

  const navigate = useNavigate();
  const location = useLocation();
  const [canEditUser, setCanEditUser] = useState<boolean>(false);
  const { encodedId } = useParams();
  const [userLogs, setUserLogs] = useState<any[]>();
  // const [clientList, setClientList] = useState<any[]>([]);
  // const [capList, setCapList] = useState<any[]>([
  //   {
  //     key: "",
  //     capName: "",
  //     value: "",
  //   },
  // ]);
  // const [currClientPageSize, setClientCurrPageSize] =
  //   useState(DEFAULT_PAGE_SIZE);

  // const [clientsLength, setClientsLength] = useState(0);
  const [user, setUser] = useState<any>({
    id: "",
    timezoneId: "",
    firstName: "",
    lastName: "",
    role: { roleName: "" },
    mobileNum: "",
  });
  const fetchData = useCallback(
    (params = {}) => {
      axios({
        method: "Get",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/user/" +
          encodeURIComponent(encodedId ? encodedId : ""),
        withCredentials: true,
      })
        .then((res) => {
          setUser(res.data.user);
          res.data.capabilities.forEach((capbility: any) => {
            capbility.key = capbility.id;
            capbility.capName = capbility.name ? capbility.name : "";
          });
          setCanEditUser(res.data.canEdit);
          setUserLogs(res.data.logs);
          // setCapList(res.data.capabilities);
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate("/bo/users");
        });
    },
    [encodedId, navigate],
  );

  const cardGridLeft = {
    width: "35%",
    textAlign: "left",
  } as const;
  const cardGridRight = {
    width: "65%",
    textAlign: "left",
  } as const;

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <React.Fragment>
      <Content>
        <Row>
          <Col span={4}>
            <Button
              onClick={() => {
                if (location.state) {
                  navigate(location.state.from);
                } else {
                  navigate("/bo/users");
                }
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-circle-chevron-left" as IconProp}
              />
            </Button>
          </Col>
          <Col span={16} style={{ textAlign: "center" }}>
            <h2>{user ? `${user.firstName} ${user.lastName}` : ""}</h2>
          </Col>
          <Col span={2} offset={2}>
            <Button
              disabled={!canEditUser}
              onClick={() => {
                encodedId
                  ? navigate(
                      `/bo/user/update/${encodeURIComponent(encodedId)}`,
                      { state: { from: window.location.pathname } },
                    )
                  : console.log("empty");
              }}
            >
              Edit
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}>
          <Col lg={12} md={24}>
            <Card
              title={
                <Row>
                  <Col span={8}>
                    <b>Details</b>
                  </Col>
                </Row>
              }
              bordered={false}
              style={{
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Card.Grid style={cardGridLeft}>
                <b>Email:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>{user.email}</Card.Grid>

              <Card.Grid style={cardGridLeft}>
                <b>Telegram username:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.telegramUsername}
              </Card.Grid>

              <Card.Grid style={cardGridLeft}>
                <b>Registration Date:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.createdAt
                  ? new Date(user.createdAt).toLocaleString("en-US", {
                      timeZone: displayContext?.displayContext.timezone,
                      timeZoneName: "short",
                    })
                  : ""}
              </Card.Grid>

              <Card.Grid style={cardGridLeft}>
                <b>Is active:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.email ? (
                  <Switch defaultChecked={user.isActive} disabled></Switch>
                ) : (
                  ""
                )}
              </Card.Grid>
            </Card>
          </Col>
          <Col lg={12} md={24}>
            <Card
              title={
                <Row>
                  <Col span={8}>
                    <b>Infos</b>
                  </Col>
                </Row>
              }
              bordered={false}
              style={{
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Card.Grid style={cardGridLeft}>
                <b>Role:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.role ? user.role.roleName : ""}
              </Card.Grid>

              <Card.Grid style={cardGridLeft}>
                <b>Company:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.role &&
                user.role.roleName.toLowerCase().startsWith("delchain")
                  ? "Delchain"
                  : user.clients
                  ? user.clients.map((client: any, index: number) => {
                      return (
                        <Row key={index}>
                          {client.client ? (
                            <Link
                              to={
                                "/bo/client/info/" +
                                encodeURIComponent(client.clientId)
                              }
                              state={{ from: window.location.pathname }}
                            >
                              {client.client.clientName}
                            </Link>
                          ) : (
                            ""
                          )}
                        </Row>
                      );
                    })
                  : ""}
              </Card.Grid>
            </Card>
          </Col>

          <Col lg={10} md={24}>
            <Card
              title={
                <Row>
                  <Col span={8}>
                    <b>Address</b>
                  </Col>
                </Row>
              }
              bordered={false}
              style={{
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Card.Grid style={cardGridLeft}>
                <b>Address Line 1:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.address ? user.address.addressLine1 : ""}
              </Card.Grid>
              <Card.Grid style={cardGridLeft}>
                <b>Address Line 2:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.address ? user.address.addressLine2 : ""}
              </Card.Grid>
              <Card.Grid style={cardGridLeft}>
                <b>Postcode:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.address ? user.address.postcode : ""}
              </Card.Grid>
              <Card.Grid style={cardGridLeft}>
                <b>City:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.address ? user.address.city : ""}
              </Card.Grid>
              <Card.Grid style={cardGridLeft}>
                <b>State:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.address ? user.address.state : ""}
              </Card.Grid>
              <Card.Grid style={cardGridLeft}>
                <b>Country:</b>
              </Card.Grid>
              <Card.Grid style={cardGridRight}>
                {user.address && user.address.country
                  ? user.address.country.nicename
                  : ""}
              </Card.Grid>
            </Card>
          </Col>
        </Row>
        <Divider />
        <Row style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}>
          <Col span={24}>
            <Card
              title={
                <Row>
                  <Col span={8}>
                    <b>Activities</b>
                  </Col>
                </Row>
              }
              bordered={false}
              style={{
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Row>
                <Col span={6}>
                  <b>Date</b>
                </Col>
                <Col span={6}>
                  <b>IP Address</b>
                </Col>
                <Col span={6}>
                  <b>Action</b>
                </Col>
                <Col span={6}>
                  <b>JSON</b>
                </Col>
              </Row>
              {userLogs && userLogs.length ? (
                userLogs.map((log: any, index: number) => (
                  <Row key={index}>
                    <Col span={6}>
                      {new Date(log.createdAt).toLocaleString("en-US", {
                        timeZone: displayContext?.displayContext.timezone,
                        timeZoneName: "short",
                      })}
                    </Col>
                    <Col span={6}>{log.ipAddress}</Col>
                    <Col span={6}>
                      {log.targetUserId !== 0
                        ? log.logAction
                          ? log.logAction
                          : ""
                        : `${log.logAction} / ${log.targetUserLastName}`}
                    </Col>
                    <Col span={6} style={{ wordBreak: "break-all" }}>
                      {log.jsonText}
                    </Col>
                  </Row>
                ))
              ) : (
                <React.Fragment>
                  <Divider></Divider>
                  <Row justify="center">
                    <Col span={2}>
                      <b>No Logs</b>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
}
export default UserInfo;
