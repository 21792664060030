import { Button, ConfigProvider, Divider, Form, Row, Select } from "antd";
import { Content } from "antd/es/layout/layout";
import { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "../../Context/displayContext";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Buffer } from "buffer";

export default function ViewLoanDetails({ loanDetail, index }: any) {
  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const [isExportingCSV, setIsExportCSV] = useState<boolean>(false);
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false);
  const [displayDetail, setDisplayDetail] = useState<any>({ ...loanDetail });
  const [selectedDate, setSelectedDate] = useState<any>(
    dayjs().format("YYYY-MM-DD"),
  );
  const [dateOptions, setDateOptions] = useState<any>([]);

  useEffect(() => {
    const options = [
      {
        key: "upToDate",
        label: "Up to Date",
        value: dayjs().format("YYYY-MM-DD"),
      },
    ];
    let currentDate = dayjs(loanDetail.dateOfLoan).tz("America/New_York", true);
    let endDate = dayjs(loanDetail.loanEndDate).tz("America/New_York", true);
    while (currentDate < endDate) {
      const date = currentDate.endOf("month");
      options.push({
        label: date.format("MMMM YYYY"),
        key: date.format("MMMMYYYY"),
        value: date.format("YYYY-MM-DD"),
      });
      currentDate = currentDate.add(1, "month");
    }
    options.push({
      label: endDate.format("MMMM YYYY"),
      key: endDate.format("MMMMYYYY"),
      value: endDate.format("YYYY-MM-DD"),
    });
    setDateOptions(options);
  }, [loanDetail]);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  const refreshModal = useCallback(
    (selectedDate: string) => {
      axios({
        method: "Get",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/bo/financial-services/loans",
        params: { endDate: selectedDate },
        withCredentials: true,
      })
        .then((res) => {
          setDisplayDetail(res.data.clientLoans[index]);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        });
    },
    [index, navigate],
  );
  useEffect(() => {
    refreshModal(selectedDate);
  }, [selectedDate, refreshModal]);
  const exportLoanReportCSV = useCallback(() => {
    setIsExportCSV(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/bo/financial-services/loans/export-csv",
      withCredentials: true,
      data: {
        data: displayDetail,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date(selectedDate);

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_${
            displayDetail?.clientName
          }_${displayDetail.dateOfLoan}_${displayDetail.term}_loan.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsExportCSV(false);
      });
  }, [selectedDate, displayDetail, navigate]);
  const generateReport = useCallback(() => {
    setIsGeneratingReport(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/bo/financial-services/loans/generate-pdf-report",
      withCredentials: true,
      data: {
        data: displayDetail,
        selectedReportDate: selectedDate,
      },
    })
      .then((res) => {
        const buffer = Buffer.from(res.data);
        const blob = new Blob([buffer]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        const date = new Date(selectedDate);
        link.href = url;
        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_${
            displayDetail?.clientName
          }_${displayDetail.dateOfLoan}_${displayDetail.term}_loan.pdf`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response?.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsGeneratingReport(false);
      });
  }, [selectedDate, displayDetail, navigate]);

  return (
    <Content id={"view-loan-details"}>
      {displayDetail ? (
        <ConfigProvider
          theme={{
            components: {
              Form: {
                labelColor: "white",
              },
              InputNumber: {
                /* here is your component tokens */
                addonBg: "lightgrey",
              },
            },
            token: {
              colorBgContainerDisabled: "lightgrey",
              colorSplit: "white",
              colorText: "white",
            },
          }}
        >
          <Row justify={"center"}>
            <h2 style={{ color: "white" }}>Loan Detail</h2>
          </Row>

          <Row justify="end" align={"middle"}>
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainerDisabled: "lightgrey",
                  colorSplit: "white",
                  colorText: "#19bdc2",
                },
              }}
            >
              <Select
                options={dateOptions}
                showSearch
                style={{ width: "150px" }}
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e);
                }}
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            </ConfigProvider>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px", color: "#18bdc2" }}
              onClick={exportLoanReportCSV}
              loading={isExportingCSV}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
            <Button
              onClick={() => generateReport()}
              type="primary"
              loading={isGeneratingReport}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-file-pdf" as IconProp}
                style={{ marginRight: "10px" }}
              />
              PDF Export
            </Button>
          </Row>
          <Form.Item label={<b>Client Name</b>}>
            {displayDetail.clientName?.split("(")[0]}
          </Form.Item>
          <Form.Item label={<b>Client Deltec Account Number</b>}>
            {displayDetail.clientName?.split("(")[1]?.split(")")[0]}
          </Form.Item>
          <Form.Item label={<b>Report Date</b>}>
            {dayjs(selectedDate ? selectedDate : undefined).format(
              "YYYY-MM-DD",
            )}
          </Form.Item>
          <Divider orientation="left">
            <b style={{ fontSize: "18px" }}>Loan Summary</b>
          </Divider>
          <Form.Item label={<b>Loan Balance</b>}>
            {displayDetail.loanAmount?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            {displayDetail.loanAssetTicker}
          </Form.Item>
          <Form.Item label={<b>Interest Rate</b>}>
            {(displayDetail?.interestRate * 100).toFixed(2)}%
          </Form.Item>
          <Form.Item label={<b>Loan Term</b>}>
            {displayDetail.loanLength} {displayDetail.loanUnit}
          </Form.Item>
          <Form.Item label={<b>Loan Start Date</b>}>
            {displayDetail.dateOfLoan}
          </Form.Item>
          <Form.Item label={<b>Loan Payback date</b>}>
            {displayDetail.loanEndDate}
          </Form.Item>
          <Divider orientation="left">
            <b style={{ fontSize: "18px" }}>Collateral Information</b>
          </Divider>
          <Form.Item label={<b>Collateral Asset</b>}>
            {displayDetail.collateralAssetFullName}
          </Form.Item>
          <Form.Item label={<b>Collateral Amount</b>}>
            {displayDetail.collateralAmount?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            {displayDetail.collateralAssetTicker}
          </Form.Item>
          <Form.Item label={<b>Collateral Wallet Address</b>}>
            {displayDetail.custodyWalletAddress}
          </Form.Item>
          <Form.Item label={<b>Value</b>}>
            {displayDetail.collateralUSDValue?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            (based off{" "}
            {dayjs(displayDetail.lastCollateralPriceDatetime).tz(displayTimezone).format(
              "MM/DD/YYYY",
            )}{" "}
            market price)
          </Form.Item>
          <Form.Item label={<b>Loan-to-Value Ratio</b>}>
            {(
              (displayDetail.collateralUSDValue / displayDetail.loanUSDValue) *
              100
            ).toFixed(2)}
            %
          </Form.Item>
          <Divider orientation="left">
            <b style={{ fontSize: "18px" }}>Loan Activity</b>
          </Divider>
          <Form.Item label={<b>Interest Accrual</b>} />
          {Object.entries(displayDetail.monthlyInterest).map(
            ([month, monthData]: any, index) => (
              <Form.Item
                label={
                  <b>
                    {index + 1}.&nbsp;{month}
                    {monthData && monthData.daysCount
                      ? ` (${monthData.daysCount} days)`
                      : ""}
                  </b>
                }
                key={index}
                style={{ marginLeft: "2em" }}
              >
                $
                {monthData?.value?.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                USD
              </Form.Item>
            ),
          )}
          <Form.Item label={<b>Total Accrual</b>}>
            ${" "}
            {displayDetail.totalAccrual?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            USD
          </Form.Item>
        </ConfigProvider>
      ) : (
        <></>
      )}
    </Content>
  );
}
