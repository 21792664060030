import { Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DisplayContext } from "../../../Context/displayContext";
import ViewDclQuote from "./ViewDclQuote";
import ExchangeViewer from "./ExchangeViewer";

export default function DclQuoteContainer() {
  const { tabName = "" } = useParams();
  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayContext, displayTimezone]);

  return (
    <Content id="delchain-venue-order-builder">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/venues/quote_builder/" + key);
        }}
        items={[
          {
            label: "Quote Builder",
            key: "",
            children: (
              <React.Fragment>
                <ViewDclQuote />
              </React.Fragment>
            ),
          },
          {
            label: "Exchange Viewer",
            key: "exchange-viewer",
            children: (
              <React.Fragment>
                <ExchangeViewer />
              </React.Fragment>
            ),
          },
        ]}
      ></Tabs>
    </Content>
  );
}
