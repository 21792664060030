import {
  Button,
  Card,
  Col,
  ConfigProvider,
  List,
  Modal,
  Row,
  Select,
  Spin,
  Steps,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../../Context/displayContext";

export default function TelegramBroadcast() {
  const displayContext = useContext(DisplayContext);
  const navigate = useNavigate();
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [messageToBroadcast, setMessageToBroadcast] = useState<string>("");
  const [broadcastImageUrl, setBroadcastImageUrl] = useState<string>("");
  const [broadcastTestingGroup, setBroadcastTestingGroup] =
    useState<string>("");
  const [lastIsTesting, setLastIsTesting] = useState<boolean>(false);
  const [messageIsApproved, setMessageIsApproved] = useState<boolean>(false);
  const [canSendReal, setCanSendReal] = useState<boolean>(false);
  const [lastTestSenderInfo, setLastTestSenderInfo] = useState<any>();
  const [lastRealSenderInfo, setLastRealSenderInfo] = useState<any>();

  const [lastActivityTimestamp, setLastActivityTimestamp] = useState<string>();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isTesting, setIsTesting] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [approvedUserName, setApprovedUserName] = useState<string>();
  const [aprrovedAt, setAprrovedAt] = useState<string>();
  const [latestSentResultSinceYesterday, setLatestSentResultSinceYesterday] =
    useState<any>();
  const [waitingForApproval, setWaitingForApproval] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [broadcastGroupOptions, setBroadcastGroupOptions] = useState<any>([]);
  const [broadCastGroupToSend, setBroadcastGroupToSend] =
    useState<string>("all");
  const cssLeft = {
    width: "30%",
    textAlign: "left",
  } as const;
  const cssRight = {
    width: "70%",
    textAlign: "left",
  } as const;

  const getTelegramBroadcastGroups = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/telegram-broadcast-group-list",
      withCredentials: true,
    })
      .then((res) => {
        const values = [{ key: "all", label: "All", value: "all" }];
        for (const row of res.data) {
          values.push({
            key: row?.id,
            value: row?.fileName,
            label: row?.fileName,
          });
        }
        setBroadcastGroupOptions(values);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  useEffect(() => {
    getTelegramBroadcastGroups();
  }, [getTelegramBroadcastGroups]);

  const fetchMessage = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/telegram-broadcast-message",
      withCredentials: true,
    })
      .then((res) => {
        setMessageToBroadcast(res.data.messageToBroadcast);
        setBroadcastImageUrl(res.data.broadcastImageUrl);
        setBroadcastTestingGroup(res.data.broadcastTestingGroup);
        setBroadcastGroupToSend(
          res.data.groupToSendFilename ? res.data.groupToSendFilename : "all"
        );
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      });
  }, [navigate]);

  const fetchData = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/telegram-broadcast-sending-info",
      withCredentials: true,
    })
      .then((res) => {
        setIsTesting(res.data.broadcastTesting);
        setIsSending(res.data.broadcastToSend);
        setLastIsTesting(res.data.lastIsTesting);
        setMessageIsApproved(res.data.telegramBroadcastIsApproved);
        setCanSendReal(
          res.data.telegramBroadcastIsApproved && !res.data.approvedBySamePerson
        );
        setLastActivityTimestamp(res.data.lastTelegramTimestamp);
        setLastRealSenderInfo(res.data.realSenderInfo);
        setLastTestSenderInfo(res.data.testSenderInfo);
        setApprovedUserName(res.data.approvedByUserName);
        setAprrovedAt(res.data.telegramBroadcastIsApprovedUpdated);
        setWaitingForApproval(res.data.waitingForApproval);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      })
      .finally(() => {
        setDataFetched(true);
      });
  }, [navigate]);
  const saveData = useCallback(
    (
      broadcastTesting?: boolean,
      broadcastToSend?: boolean,
      message = "Successfully Updated telegram broadcast info"
    ) => {
      axios({
        method: "Put",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/update-telegram-broadcast",
        withCredentials: true,
        data: {
          messageToBroadcast,
          broadCastGroupToSend,
          broadcastImageUrl,
          broadcastTestingGroup,
          broadcastTesting,
          broadcastToSend,
        },
      })
        .then(() => {
          toast.success(message, { autoClose: 10000 });
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
            return;
          }
        });
    },
    [
      broadCastGroupToSend,
      messageToBroadcast,
      navigate,
      broadcastImageUrl,
      broadcastTestingGroup,
    ]
  );

  const TelegramApproveClick = useCallback(() => {
    setMessageIsApproved(!messageIsApproved);
    setWaitingForApproval(false);
    axios({
      method: "Put",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/update-telegram-broadcast-approval",
      data: { newApproval: !messageIsApproved },
      withCredentials: true,
    }).then((res) => {
      setApprovedUserName(res.data.name);
      setAprrovedAt(res.data.updatedAt);
    });
    saveData(
      messageIsApproved,
      false,
      messageIsApproved
        ? ((
            <React.Fragment>
              <p>
                Test broadcast has been disapproved! Please edit the broadcastin
                and test again.
              </p>
              <p>The sending process has been stopped.</p>
            </React.Fragment>
          ) as any)
        : ((
            <React.Fragment>
              <p>Test broadcast has been approved.</p>
              <p>
                Waiting for another operator to allow sending to real clients.
              </p>
            </React.Fragment>
          ) as any)
    );
    setIsTesting(messageIsApproved);
    setIsSending(false);
    if (messageIsApproved) {
      setCanSendReal(false);
    }
  }, [saveData, messageIsApproved]);

  const finishWorkflow = useCallback(
    (
      message = (
        <React.Fragment>
          <p>Telegram broadcast workflow is now finished!</p>
        </React.Fragment>
      )
    ) => {
      setIsTesting(true);
      setIsSending(false);
      setCanSendReal(false);
      saveData(true, false, message as any);
      setMessageIsApproved(false);
      axios({
        method: "Put",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/update-telegram-broadcast-approval",
        data: { newApproval: false, isReset: true },
        withCredentials: true,
      }).catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      });
    },
    [saveData, navigate]
  );

  const fetchLatestSentToClientSinceYesterday = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/telegram-broadcast-latest-sent",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data) {
          setLatestSentResultSinceYesterday(
            res.data.latestBroadcastToClientsSinceYesterday
          );
        }
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      });
  }, [navigate]);

  useEffect(() => {
    fetchMessage();
    fetchLatestSentToClientSinceYesterday();
    fetchData();
  }, [fetchData, fetchLatestSentToClientSinceYesterday, fetchMessage]);

  useEffect(() => {
    let intervalId: any;
    let interval = 5000;

    intervalId = setInterval(() => {
      fetchData();
      fetchLatestSentToClientSinceYesterday();
    }, interval);

    // Cleanup function to clear the interval
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [fetchData, fetchLatestSentToClientSinceYesterday]);

  useEffect(() => {
    // console.log("-------------------------------");
    // console.log("isTESTING", isTesting);
    // console.log("toSEND", isSending);
    // console.log("WAITING FOR APPROVAL", waitingForApproval);
    // console.log("MessageApproved", messageIsApproved);
    // console.log(!isSending && isTesting);
    // console.log(messageIsApproved && !(!isSending && !isTesting));
    // console.log("-------------------------------");

    if (
      (!isSending && !messageIsApproved && !waitingForApproval && isTesting) ||
      (!lastIsTesting && !isSending)
    ) {
      console.log("INITIAL");
      setCurrentStep(0);
    } else if (
      !isSending &&
      !messageIsApproved &&
      waitingForApproval &&
      isTesting
    ) {
      setCurrentStep(1);
      console.log("APPROVE/DISAPPROVE");
    } else if (canSendReal && !isSending) {
      console.log("SEND REAL");
      setCurrentStep(2);
    } else if (isSending && !isTesting) {
      console.log("Finish Stage");
      setCurrentStep(3);
    }
  }, [
    isSending,
    messageIsApproved,
    isTesting,
    canSendReal,
    waitingForApproval,
    lastIsTesting,
  ]);

  const stepItems = [
    {
      title: (
        <React.Fragment>
          <Button
            type="primary"
            className="ant-step-button"
            disabled={(messageIsApproved && !isTesting) || isSending}
            onClick={() => {
              saveData(
                true,
                true,
                (
                  <React.Fragment>
                    <p>Test sending process has been started!</p>
                  </React.Fragment>
                ) as any
              );
              setLastActivityTimestamp(undefined);
              setIsTesting(true);
              setIsSending(true);
              setCanSendReal(false);
              setMessageIsApproved(false);
              setWaitingForApproval(false);
            }}
          >
            Send to test clients
          </Button>
          {isSending && isTesting ? (
            <Button
              danger={true}
              className="ant-step-button"
              onClick={() => {
                saveData(
                  true,
                  false,
                  (
                    <React.Fragment>
                      <p>Test sending process has been stopped!</p>
                    </React.Fragment>
                  ) as any
                );
                setIsTesting(true);
                setIsSending(false);
                setWaitingForApproval(false);
                setMessageIsApproved(false);
              }}
            >
              Stop
            </Button>
          ) : (
            <></>
          )}
        </React.Fragment>
      ),
      description: (
        <React.Fragment>
          Information about test sending will be displayed here.
          {isSending && isTesting ? (
            <Row>
              <Spin /> &nbsp; Waiting for the cron job to be sent (up to 5 mins)
            </Row>
          ) : (
            <Row>
              {lastTestSenderInfo ? (
                <React.Fragment>
                  <b>Last test broadcast sent by: &nbsp;</b>
                  {`${lastTestSenderInfo.senderName} (${
                    lastActivityTimestamp && lastIsTesting
                      ? new Date(lastActivityTimestamp).toLocaleString(
                          "en-us",
                          {
                            timeZone: displayTimezone,
                            timeZoneName: "short",
                          }
                        )
                      : new Date(lastTestSenderInfo.updatedAt).toLocaleString(
                          "en-us",
                          {
                            timeZone: displayTimezone,
                            timeZoneName: "short",
                          }
                        )
                  }
                  )`}
                </React.Fragment>
              ) : (
                ""
              )}
            </Row>
          )}
        </React.Fragment>
      ),
    },
    {
      title: (
        <Row>
          <Button
            className="ant-step-button"
            danger={messageIsApproved}
            onClick={TelegramApproveClick}
            disabled={
              (!lastIsTesting && !messageIsApproved) ||
              (!messageIsApproved && !waitingForApproval) ||
              (isSending && !isTesting) ||
              (!lastIsTesting && !isSending)
            }
          >
            {!messageIsApproved ? "Approve" : "Disapprove"}
          </Button>
        </Row>
      ),

      description: (
        <React.Fragment>
          Approve to enable the real sending or disapprove and come back to
          testing.
          {approvedUserName && !(!lastIsTesting && !isSending) ? (
            <Row>
              <b>Approved by: &nbsp;</b>
              {approvedUserName}&nbsp;(
              {aprrovedAt
                ? new Date(aprrovedAt).toLocaleString("en-us", {
                    timeZone: displayTimezone,
                    timeZoneName: "short",
                  })
                : ""}
              )
            </Row>
          ) : (
            ""
          )}
          {!(approvedUserName && !(!lastIsTesting && !isSending)) &&
          !waitingForApproval ? (
            <Row>
              <b>Last Approval: &nbsp;</b>
              {latestSentResultSinceYesterday?.lastApproveLog?.approvedBy}
              &nbsp;(
              {latestSentResultSinceYesterday?.lastApproveLog?.approvedDate
                ? new Date(
                    latestSentResultSinceYesterday?.lastApproveLog?.approvedDate
                  ).toLocaleString("en-us", {
                    timeZone: displayTimezone,
                    timeZoneName: "short",
                  })
                : ""}
              )
            </Row>
          ) : (
            ""
          )}
        </React.Fragment>
      ),
    },
    {
      title: (
        <React.Fragment>
          <Button
            type="primary"
            className="ant-step-button"
            onClick={() => {
              saveData(
                false,
                true,
                (
                  <React.Fragment>
                    <p>Real Clients sending process has been started!</p>
                  </React.Fragment>
                ) as any
              );
              setIsSending(true);
              setIsTesting(false);
              setCanSendReal(false);
              setLastActivityTimestamp(undefined);
            }}
            disabled={
              !canSendReal ||
              (isSending && !isTesting) ||
              (!lastIsTesting && !isSending)
            }
          >
            Send to real clients
          </Button>
          {isSending && !isTesting ? (
            <Button
              danger={true}
              className="ant-step-button"
              onClick={() =>
                finishWorkflow(
                  <React.Fragment>
                    <p>Real clients sending process has been stopped!</p>
                  </React.Fragment>
                )
              }
            >
              Stop
            </Button>
          ) : (
            <></>
          )}
        </React.Fragment>
      ),
      description: (
        <React.Fragment>
          Information about real sending will be displayed here.
          {isSending && !isTesting ? (
            <Row>
              <Spin /> &nbsp; Waiting for the cron job to be sent (up to 5 mins)
            </Row>
          ) : (
            <Row>
              {lastRealSenderInfo ? (
                <React.Fragment>
                  <b>Last real clients broadcast sent by: &nbsp;</b>
                  {`${lastRealSenderInfo.senderName} (${
                    lastActivityTimestamp && !lastIsTesting
                      ? new Date(lastActivityTimestamp).toLocaleString(
                          "en-us",
                          {
                            timeZone: displayTimezone,
                            timeZoneName: "short",
                          }
                        )
                      : new Date(lastRealSenderInfo.updatedAt).toLocaleString(
                          "en-us",
                          {
                            timeZone: displayTimezone,
                            timeZoneName: "short",
                          }
                        )
                  }
                  )`}
                </React.Fragment>
              ) : (
                ""
              )}
            </Row>
          )}
        </React.Fragment>
      ),
    },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            defaultColor: "#57be57",
            defaultBorderColor: "#57be57",
          },
          Steps: {
            /* here is your component tokens */
            titleLineHeight: 40,
            iconSize: 50,
          },
        },
      }}
    >
      <Card
        title={
          <Row>
            <Col span={8}>Telegram Broadcast</Col>
          </Row>
        }
        bordered={false}
        style={{
          margin: "10px",
          borderRadius: "10px",
        }}
        id="venue-list-to-fetch"
      >
        <Card.Grid style={cssLeft}>Message to Broadcast</Card.Grid>
        <Card.Grid style={cssRight}>
          <TextArea
            disabled={(messageIsApproved && !isTesting) || isSending}
            value={messageToBroadcast}
            onChange={(e) => {
              setMessageToBroadcast(e.target.value);
            }}
          />
          <Row className="telegram-input-detail">
            <b>Text Formatting:</b> &nbsp; this is a &nbsp; <b>**bold**</b>{" "}
            &nbsp; and &nbsp;
            <i>__italic__ </i> &nbsp; text
          </Row>
        </Card.Grid>

        <Card.Grid style={cssLeft}>Image to Broadcast</Card.Grid>
        <Card.Grid style={cssRight}>
          <TextArea
            disabled={(messageIsApproved && !isTesting) || isSending}
            value={broadcastImageUrl}
            onChange={(e) => setBroadcastImageUrl(e.target.value)}
          />
          <p
            style={{
              wordWrap: "break-word",
              color: "black",
            }}
            className="telegram-input-detail"
          >
            <b>Example: </b>
            https://delchain.io/wp-content/uploads/2020/05/DLC_Logo_Primary_CMYK_Preview.png
          </p>
        </Card.Grid>

        <Card.Grid style={cssLeft}>Groups to Broadcast to in Testing</Card.Grid>
        <Card.Grid style={cssRight}>
          <TextArea
            value={broadcastTestingGroup}
            disabled={(messageIsApproved && !isTesting) || isSending}
            onChange={(e) => setBroadcastTestingGroup(e.target.value)}
          />
        </Card.Grid>
        <Card.Grid style={cssLeft}>Broadcast Group</Card.Grid>
        <Card.Grid style={cssRight}>
          <Select
            disabled={(messageIsApproved && !isTesting) || isSending}
            value={broadCastGroupToSend}
            options={broadcastGroupOptions}
            onChange={(e) => {
              setBroadcastGroupToSend(e);
            }}
            style={{ width: "100%" }}
          />
        </Card.Grid>
        <Card.Grid
          style={{
            width: "100%",
            textAlign: "left",
          }}
        >
          <Row justify={"end"}>
            <Button
              type="primary"
              disabled={(messageIsApproved && !isTesting) || isSending}
              onClick={() => {
                saveData(
                  true,
                  false,
                  (
                    <React.Fragment>
                      <p>Broadcast info updated successfully!</p>
                      <p>The sending process has been stopped.</p>
                    </React.Fragment>
                  ) as any
                );
                setIsTesting(true);
                setIsSending(false);
              }}
            >
              {"Save"}
            </Button>
          </Row>
        </Card.Grid>

        <Card.Grid
          style={{
            width: "100%",
            textAlign: "left",
          }}
        >
          <Row justify={"end"}>
            <Button
              onClick={() => {
                setDataFetched(false);
                fetchData();
                fetchLatestSentToClientSinceYesterday();
              }}
            >
              Refresh
            </Button>
          </Row>
          <Row justify={"center"} id="telegram-broadcast-steps">
            {dataFetched ? (
              <Steps
                current={currentStep}
                direction="vertical"
                items={stepItems}
              />
            ) : (
              <Spin size="large" />
            )}
          </Row>
        </Card.Grid>
        {latestSentResultSinceYesterday ? (
          <Card.Grid
            style={{
              width: "100%",
              textAlign: "left",
            }}
          >
            <List
              size="small"
              header={
                <b>
                  Latest result sent to real clients since yesterday (
                  {new Date(
                    latestSentResultSinceYesterday.createdAt
                  ).toLocaleString("en-us", {
                    timeZone: displayTimezone,
                    timeZoneName: "short",
                  })}
                  )
                </b>
              }
              dataSource={Object.entries(
                latestSentResultSinceYesterday.lastJSON
              )}
              renderItem={(item) => (
                <List.Item>
                  <b>{item[0]}: </b>
                  {`${item[1]}`}
                </List.Item>
              )}
            />
          </Card.Grid>
        ) : (
          <></>
        )}
        <Modal
          open={showModal}
          onCancel={() => {
            setShowModal(false);
          }}
          onOk={() => {
            setShowModal(false);
          }}
        >
          <Row>
            <p>
              Once switched on and saved, the message will start to be sent
              within 5 min
            </p>
          </Row>
        </Modal>
      </Card>
    </ConfigProvider>
  );
}
