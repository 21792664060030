import { createContext } from "react"

export interface IDisplayContext {
    timezone: string
}

export interface DisplayontextType {
    displayContext: IDisplayContext;
    setDisplayContext: (user: IDisplayContext) => void;
  }
export const DisplayContext = createContext<DisplayontextType | null>(null)