import { Col, Layout, Row, Spin, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PrcessReset from "./ProcessReset";
import QuoteBuilderDefaultValues from "./QuoteBuilderDefaultValues";
import VenueExposureLimits from "./VenueExposureLimits";
import VenueListToFetch from "./VenueListToFetch";

export default function TechSettings() {
  const { tabName = "process-reset-and-list-to-fetch" } = useParams();
  const [activeKey, setActiveKey] = useState<string>(tabName);
  const [isFinishLoaded, setIsFinishLoaded] = useState<boolean>(true);
  const navigate = useNavigate();

  const [canUpdateQuoteBuilder, setCanUpdateQuoteBuilder] =
    useState<boolean>(false);
  const fetchCanUpdateQuoteBuilder = useCallback(() => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/quote-builder-default-fees/can-edit",
      withCredentials: true,
    }).then((res) => {
      setCanUpdateQuoteBuilder(res.data.canUpdate);
      if (!res.data.canUpdate && tabName === "quote-builder") {
        setActiveKey("process-reset-and-list-to-fetch");
        navigate("/bo/settings/tech-settings/");
      }
    });
  }, [navigate, tabName]);
  useEffect(() => {
    fetchCanUpdateQuoteBuilder();
  }, [fetchCanUpdateQuoteBuilder]);

  return isFinishLoaded ? (
    <Content id="tech-settings">
      <Tabs
        type="card"
        defaultActiveKey={tabName}
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key);
          navigate("/bo/settings/tech-settings/" + key);
        }}
        items={[
          {
            label: "Process Reset & List to Fetch",
            key: "process-reset-and-list-to-fetch",
            children: (
              <React.Fragment>
                <Row justify={"space-around"}>
                  <Col xxl={{ span: 11 }} lg={{ span: 11 }} md={{ span: 24 }}>
                    <PrcessReset />
                  </Col>
                  <Col xxl={{ span: 11 }} lg={{ span: 11 }} md={{ span: 24 }}>
                    <VenueListToFetch setIsFinishLoaded={setIsFinishLoaded} />
                  </Col>
                </Row>
              </React.Fragment>
            ),
          },
          {
            label: "Venue Exposure",
            key: "venue-exposure",
            children: (
              <Row justify="center">
                <Col xxl={{ span: 12 }} lg={{ span: 18 }} md={{ span: 24 }}>
                  <VenueExposureLimits />
                </Col>
              </Row>
            ),
          },
          {
            label: "Quote Builder",
            key: "quote-builder",
            disabled: !canUpdateQuoteBuilder,
            children: (
              <Row justify="center">
                <Col xxl={{ span: 12 }} lg={{ span: 18 }} md={{ span: 24 }}>
                  <QuoteBuilderDefaultValues />
                </Col>
              </Row>
            ),
          },
        ]}
      />
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
