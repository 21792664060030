import { Card, Col, Row, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "../../../Context/displayContext";

export default function ViewVenueBalanceExposures() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const [venueExposureDataFetched, setVenueExposureDataFetched] =
    useState<boolean>(false);
  const [venueExposureData, setVenueExposureData] = useState<any[]>();
  const [lastCall, setLastCall] = useState<Date>();
  const getVenueExposureData = useCallback(() => {
    setVenueExposureDataFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-exposure",
      withCredentials: true,
    })
      .then((res) => {
        setVenueExposureData(res.data.res);
        setLastCall(new Date(res.data.lastCall));
      })
      .finally(() => {
        setVenueExposureDataFetched(true);
      });
  }, []);
  useEffect(() => {
    getVenueExposureData();
  }, [getVenueExposureData]);
  const cardGridStyle = {
    width: "100%",
    padding: "8px 18px",
  };
  const cardLastGridStyle = {
    borderRadius: "0 0 10px 10px",
    backgroundColor: "rgb(245 245 244)",
  };
  return (
    <Content id="view-venue-balance-exposure">
      <Row className="dcl-filter-row">
        <Space className="dcl-filter-row-space" style={{ marginTop: "10px" }}>
          <b>Last updated: </b>
          {lastCall
            ? lastCall.toLocaleString("en-US", {
                timeZone: displayTimezone,
                timeZoneName: "short",
              })
            : "Pending..."}
        </Space>
      </Row>
      <Row>
        <Col xxl={4} xl={6} lg={12} md={24}>
          <Card
            className={"state_green"}
            title={<b>Total</b>}
            loading={!venueExposureDataFetched}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
          >
            <Card.Grid style={{ ...cardGridStyle }}>
              <Row>
                <Col span={14}>
                  <b>Crypto Exposure:</b>
                </Col>
                <Col span={10} style={{ textAlign: "right" }}>
                  $
                  {venueExposureData
                    ?.reduce((prev: number, curr: any) => {
                      return curr.cryptoExposure
                        ? prev + Number(curr.cryptoExposure)
                        : prev;
                    }, 0)
                    .toLocaleString("en-us", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid style={{ ...cardGridStyle }}>
              <Row>
                <Col span={14}>
                  <b>Fiat Exposure:</b>
                </Col>
                <Col span={10} style={{ textAlign: "right" }}>
                  $
                  {venueExposureData
                    ?.reduce((prev: number, curr: any) => {
                      return curr.fiatExposure
                        ? prev + Number(curr.fiatExposure)
                        : prev;
                    }, 0)
                    .toLocaleString("en-us", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid style={{ ...cardGridStyle }}>
              <Row>
                <Col span={14}>
                  <b>Total Exposure:</b>
                </Col>
                <Col span={10} style={{ textAlign: "right" }}>
                  $
                  {venueExposureData
                    ?.reduce((prev: number, curr: any) => {
                      return curr.totalExposure
                        ? prev + Number(curr.totalExposure)
                        : prev;
                    }, 0)
                    .toLocaleString("en-us", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Col>
              </Row>
            </Card.Grid>
            <Card.Grid style={{ ...cardGridStyle, ...cardLastGridStyle }}>
              <Row>
                <Col span={14}> &nbsp;</Col>
                <Col span={10} style={{ textAlign: "right" }}>
                  &nbsp;
                </Col>
              </Row>
            </Card.Grid>
          </Card>
        </Col>
        {venueExposureData ? (
          venueExposureData.map((e: any, i: number) => (
            <Col xxl={4} xl={6} lg={12} md={24} key={i}>
              <Card
                className={e.flag}
                title={<b>{e.key}</b>}
                loading={!venueExposureDataFetched}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={{ ...cardGridStyle }}>
                  <Row>
                    <Col span={14}>
                      <b>Crypto Exposure:</b>
                    </Col>
                    <Col span={10} style={{ textAlign: "right" }}>
                      $
                      {e.cryptoExposure.toLocaleString("en-us", {
                        type: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Col>
                  </Row>
                </Card.Grid>
                <Card.Grid style={{ ...cardGridStyle }}>
                  <Row>
                    <Col span={14}>
                      <b>Fiat Exposure:</b>
                    </Col>
                    <Col span={10} style={{ textAlign: "right" }}>
                      $
                      {e.fiatExposure.toLocaleString("en-us", {
                        type: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Col>
                  </Row>
                </Card.Grid>
                <Card.Grid style={{ ...cardGridStyle }}>
                  <Row>
                    <Col span={14}>
                      <b>Total Exposure:</b>
                    </Col>
                    <Col span={10} style={{ textAlign: "right" }}>
                      $
                      {e.totalExposure.toLocaleString("en-us", {
                        type: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Col>
                  </Row>
                </Card.Grid>
                <Card.Grid style={{ ...cardGridStyle, ...cardLastGridStyle }}>
                  <Row>
                    <Col span={14}>
                      <b>Exposure Limit:</b>{" "}
                    </Col>
                    <Col span={10} style={{ textAlign: "right" }}>
                      $
                      {e.limit.toLocaleString("en-us", {
                        type: "currency",
                        currency: "USD",
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Col>
                  </Row>
                </Card.Grid>
              </Card>
            </Col>
          ))
        ) : (
          <></>
        )}
      </Row>
    </Content>
  );
}
