import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Collapse, Divider, Row, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
const { Panel } = Collapse;

function MyCompanyProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  // const clientAccountsColumns = [
  //   {
  //     title: "ID",
  //     dataIndex: "id",
  //     width: "3%",
  //     editable: false,
  //   },
  //   {
  //     title: "Account Number",
  //     dataIndex: "accountNumber",
  //     width: "10%",
  //     editable: false,
  //   },
  //   {
  //     title: "Client Account Type",
  //     dataIndex: "clientAccountTypeName",
  //     width: "10%",
  //     editable: false,
  //   },
  // ];
  const { clientId } = useParams();
  const [client, setClient] = useState<any>({});
  // const [clientAccounts, setClientAccounts] = useState<any[]>([]);
  const fetchData = useCallback(
    (params = {}) => {
      axios({
        method: "Get",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/client/" +
          encodeURIComponent(clientId ? clientId : ""),
        withCredentials: true,
      })
        .then((res) => {
          // setClientAccounts(res.data.clientAccounts);
          setClient(res.data.client);
        })
        .catch(() => {
          toast.error("Unable to fetch client info", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      // eslint-disable-next-line
    },
    [clientId],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <React.Fragment>
      <Content>
        <Row>
          <Button
            onClick={() => {
              if (location.state) {
                navigate(location.state.from);
              } else {
                navigate("/bo/clients");
              }
            }}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-chevron-left" as IconProp}
            />
          </Button>
        </Row>
        {/* <Row justify="center" align="middle">
          <h2>My Company Profile</h2>
        </Row> */}
        <Row style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Space>
              <strong>Email:</strong> {client.email}
            </Space>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Space>
              <strong>Client Type:</strong>{" "}
              {client.clientType ? client.clientType.name : ""}
            </Space>
          </Col>
        </Row>

        <Row style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Space>
              <strong>Client Name:</strong> {client.clientName}
            </Space>
          </Col>
          {/* <Col md={2}>isActive</Col> */}
          {/* <Col md={10}>{client.isActive ? "Active" : "Inactive"}</Col> */}
        </Row>
        <Row style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Space>
              <strong>Bussiness Activity:</strong>
              {client.businessActivityDspTerm ? client.businessActivityDspTerm.value : ""}
            </Space>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}>
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <Space>
              <strong>Deltec Account:</strong>{" "}
              {client.deltecAccount ? client.deltecAccount : ""}
            </Space>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <Space>
              <strong>Delchain Account:</strong>
              {client.delchainAccount ? client.delchainAccount : ""}
            </Space>
          </Col>
        </Row>
        {/* <Row style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}>
          <Col md={{ span: 6 }}>
            <Card title="Client Accounts">
                <Table
                  columns={clientAccountsColumns}
                  dataSource={clientAccounts}
                  pagination={{
                    pageSize: clientAccounts.length,
                    hideOnSinglePage: true,
                  }}
                ></Table>
            </Card>
          </Col>
        </Row> */}
        <Divider />
        <Collapse defaultActiveKey={[]}>
          <Panel header="Address Info" key="address">
            <Row
              style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}
            >
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <Space>
                  <strong>Address Line 1:</strong>{" "}
                  {client.address ? client.address.addressLine1 : ""}
                </Space>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}
            >
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <Space>
                  <strong>Address Line 2:</strong>{" "}
                  {client.address ? client.address.addressLine2 : ""}
                </Space>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}
            >
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <Space>
                  <strong>City:</strong>{" "}
                  {client.address ? client.address.city : ""}
                </Space>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <Space>
                  <strong>State:</strong>
                  {client.address ? client.address.state : ""}
                </Space>
              </Col>
            </Row>
            <Row
              style={{ marginTop: "10px", marginLeft: "1%", marginRight: "1%" }}
            >
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <Space>
                  <strong>Country:</strong>{" "}
                  {client.address ? client.address.country.nicename : ""}{" "}
                </Space>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <Space>
                  <strong>PostCode:</strong>
                  {client.address ? client.address.postcode : ""}
                </Space>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Content>
    </React.Fragment>
  );
}
export default MyCompanyProfile;
