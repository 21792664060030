import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Row,
  Space,
  Table,
  Tooltip,
  Form,
  Select,
  Input,
  Switch,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DisplayContext } from "../../Context/displayContext";

export default function ViewWallets({ filter }: any) {
  const [filterForm] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const [tableData, setTableData] = useState<any[]>();
  const [onFinishLoaded, setOnFinishLoaded] = useState<boolean>(false);
  const [editDisabled, setEditDisabled] = useState<boolean>(true);
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [tableLength, setTableLength] = useState<number>(0);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
  });
  const [canFetchHistoricalTxns, setCanFetchHistoricalTxns] =
    useState<boolean>(false);
  const [assetPrices, setAssetPrices] = useState<any>();
  const initialColumns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      width: 100,
      render: (_: any, { clientId, clientName }: any) => (
        <Link
          to={"/bo/client/info/" + encodeURIComponent(clientId)}
          state={{
            from: window.location.pathname,
            clientIds: filterForm.getFieldValue("clientIds"),
            walletAddress: filterForm.getFieldValue("address"),
            assetIds: filterForm.getFieldValue("assetIds"),
            walletTypeIds: filterForm.getFieldValue("walletTypeIds"),
            isActive: filterForm.getFieldValue("isActive"),
            pagination: pagination,
          }}
        >
          {clientName}
        </Link>
      ),
    },
    { title: "Subaccount", dataIndex: "clientAccountNumber", width: 80 },
    {
      title: "Assets",
      dataIndex: "assetName",
      width: 100,
      render: (_: any, { subWallets }: any) =>
        subWallets && subWallets.length
          ? subWallets.map((wallet: any) => wallet.asset.ticker).join(", ")
          : "",
    },
    { title: "Label", dataIndex: "label", width: 80 },
    {
      title: "Wallet Address",
      dataIndex: "walletAddress",
      width: 150,
      render: (_: any, { walletAddress, id }: any) => (
        <Link
          to={"/bo/wallet/view/" + encodeURIComponent(id)}
          state={{
            from: window.location.pathname,
            clientIds: filterForm.getFieldValue("clientIds"),
            walletAddress: filterForm.getFieldValue("address"),
            assetIds: filterForm.getFieldValue("assetIds"),
            walletTypeIds: filterForm.getFieldValue("walletTypeIds"),
            isActive: filterForm.getFieldValue("isActive"),
            canFetchHistoricalTxns: canFetchHistoricalTxns,
            pagination: pagination,
          }}
        >
          {walletAddress}
        </Link>
      ),
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      width: 50,
      render: (_: any, { isActive, dateArchived, dateActivated }: any) =>
        isActive ? (
          <Space className="dcl-active">
            <Tooltip
              title={
                "Activated Date: " +
                new Date(dateActivated).toLocaleString("en-US", {
                  timeZone: displayContext?.displayContext.timezone,
                  timeZoneName: "short",
                })
              }
            >
              <Switch defaultChecked disabled />
            </Tooltip>
          </Space>
        ) : (
          <Space className="dcl-inactive">
            <Tooltip
              title={
                "Archived Date: " +
                new Date(dateArchived).toLocaleString("en-US", {
                  timeZone: displayContext?.displayContext.timezone,
                  timeZoneName: "short",
                })
              }
            >
              <Switch defaultChecked={false} disabled />
            </Tooltip>
          </Space>
        ),
    },
    {
      title: "Total Value",
      dataIndex: "totalValue",
      width: 100,
      align: "right" as const,
      render: (_: any, { subWallets }: any) => (
        <Space>
          {subWallets && subWallets.length && assetPrices
            ? subWallets
                .reduce(
                  (prev: any, curr: any) =>
                    assetPrices[curr.assetId]
                      ? prev + curr.displayBalance * assetPrices[curr.assetId]
                      : prev,
                  0,
                )
                .toLocaleString("en-us", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 5,
                })
            : "0.0"}
        </Space>
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      width: 50,
      fixed: "right" as const,
      render: (_: any, { id }: any) => (
        <Button
          disabled={editDisabled}
          onClick={() => {
            const url = "/bo/wallet/update/" + encodeURIComponent(id);
            navigate(url, {
              state: {
                from: window.location.pathname,
                clientIds: filterForm.getFieldValue("clientIds"),
                walletAddress: filterForm.getFieldValue("address"),
                assetIds: filterForm.getFieldValue("assetIds"),
                walletTypeIds: filterForm.getFieldValue("walletTypeIds"),
                isActive: filterForm.getFieldValue("isActive"),
                canFetchHistoricalTxns: canFetchHistoricalTxns,
                pagination: pagination,
              },
            });
          }}
        >
          Edit
        </Button>
      ),
    },
  ];
  const [showFilter, setShowFilter] = useState<boolean>(filter ? true : false);
  const [clientOptions, setClientOptions] = useState<any[]>();
  const [clientAccountOptions, setClientAccountOptions] = useState<any[]>();
  const [venueOptions, setVenueOptions] = useState<any[]>();
  const [isExportingCSV, setIsExportingCSV] = useState<boolean>(false);

  const onReset = () => {
    fetchData();
    filterForm.resetFields();
  };

  const filterWallets = useCallback((form: any, pagination: any) => {
    setPagination(pagination);
    setOnFinishLoaded(false);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallets",
      withCredentials: true,
      data: {
        ...form,
        pagination: pagination,
      },
    })
      .then((res) => {
        setTableData(res.data.wallets);
        setTableLength(res.data.count);
        setCanFetchHistoricalTxns(res.data.canFetchWalletHistoricalTxns);
        if (res.data.canEdit) {
          setEditDisabled(false);
        }
      })
      .finally(() => {
        setOnFinishLoaded(true);
      });
  }, []);

  const getVenueList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueOptions(res.data.list);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  const getClientAccountsList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-accounts",
      withCredentials: true,
    })
      .then((res) => {
        setClientAccountOptions(res.data.clientAccounts);
      })
      .catch(() => {
        console.log("Unable to fetch the wallet type list");
      });
  }, []);

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        res.data.clients.forEach(
          (c: any) => (c.label = `${c.label} (${c.deltecAccount})`),
        );
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  const getAssetOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  const exportCSV = useCallback(() => {
    if (tableData) {
      for (const wallet of tableData) {
        wallet.assets = (
          wallet.subWallets && wallet.subWallets.length
            ? wallet.subWallets.map((sub: any) =>
                sub.asset ? sub.asset.ticker : "",
              )
            : []
        ).join(", ");
        wallet.totalValue =
          wallet.subWallets && wallet.subWallets.length
            ? wallet.subWallets.reduce(
                (prev: any, curr: any) =>
                  assetPrices[curr.assetId]
                    ? prev + curr.displayBalance * assetPrices[curr.assetId]
                    : prev,
                0,
              )
            : 0;
      }
    }
    setIsExportingCSV(true);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallets/export-csv",
      withCredentials: true,
      data: {
        wallets: tableData,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_wallets.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsExportingCSV(false);
      });
  }, [tableData, assetPrices]);

  const fetchSelectOptions = useCallback(() => {
    getClientOptions();
    getAssetOptions();
    getVenueList();
    getClientAccountsList();
  }, [getAssetOptions, getClientOptions, getVenueList, getClientAccountsList]);

  useEffect(() => {
    fetchSelectOptions();
  }, [fetchSelectOptions]);
  const fetchData = useCallback(
    (paginationParams = { current: 1, pageSize: 10 }) => {
      setOnFinishLoaded(false);
      axios({
        method: "Post",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallets",
        data: {
          pagination: paginationParams,
        },
        withCredentials: true,
      })
        .then((res) => {
          setTableData(res.data.wallets);
          setTableLength(res.data.count);
          setAssetPrices(res.data.assetPrices);
          setCanFetchHistoricalTxns(res.data.canFetchWalletHistoricalTxns);
          if (res.data.canEdit) {
            setEditDisabled(false);
          }
        })
        .finally(() => {
          setOnFinishLoaded(true);
        });
    },
    [],
  );

  useEffect(() => {
    if (tableData) return;
    if (location.state) {
      filterForm.setFieldsValue(location.state);
      setShowFilter(true);
      setOnFinishLoaded(false);
      axios({
        method: "Post",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallets",
        withCredentials: true,
        data: {
          ...location.state,
        },
      })
        .then((res) => {
          setTableData(res.data.wallets);
          setTableLength(res.data.count);
          setAssetPrices(res.data.assetPrices);
          setCanFetchHistoricalTxns(res.data.canFetchWalletHistoricalTxns);
          if (res.data.canEdit) {
            setEditDisabled(false);
          }
        })
        .finally(() => {
          if (location.state.pagination)
            setPagination(location.state.pagination);
          setOnFinishLoaded(true);
        });
    } else {
      fetchData();
    }
  }, [tableData, location, filterForm, fetchData]);

  return (
    <Content id="view-wallets">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Space>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
              Filters
              {showFilter ? (
                <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
              ) : (
                <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
              )}
            </Button>
          </Space>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={() => {
              filterWallets(filterForm.getFieldsValue(), {
                ...pagination,
                current: 1,
              });
            }}
            hidden={!showFilter}
            className="dcl-toggled-content dcl-toggled-content-filter"
          >
            <Row>
              <Form.Item name="clientIds" className="dcl-filter-item">
                <Select
                  className="dcl-client-select"
                  placeholder="Client"
                  options={clientOptions}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="clientAccounts" className="dcl-filter-item">
                <Select
                  placeholder="Subaccounts"
                  className="dcl-client-account-select"
                  options={clientAccountOptions}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="venueIds" className="dcl-filter-item">
                <Select
                  placeholder="Venue"
                  className="dcl-venue-select"
                  options={venueOptions}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="address" className="dcl-filter-item">
                <Input placeholder="Wallet Address" />
              </Form.Item>
              <Form.Item name="assetIds" className="dcl-filter-item">
                <Select
                  className="dcl-asset-select"
                  placeholder="Asset Ticker"
                  options={assetOptions}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="isActive" className="dcl-filter-item">
                <Select
                  className="dcl-status-select"
                  placeholder="Status"
                  options={[
                    {
                      label: "Active",
                      value: "active",
                    },
                    { label: "Inactive", value: "inactive" },
                    {
                      label: "All",
                      value: "all",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="isClientPersonal" className="dcl-filter-item">
                <Select
                  className="dcl-status-select"
                  placeholder="Is Client Personal"
                  options={[
                    {
                      label: "True",
                      value: true,
                    },
                    { label: "False", value: false },
                    {
                      label: "All",
                      value: undefined,
                    },
                  ]}
                />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                <b>{tableLength} Wallets</b>
                <Button htmlType="submit" className="ant-btn-primary">
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8 }} sm={{ span: 8 }}>
          <Row justify="end">
            <Button
              className="dcl-btn-toggle"
              disabled={!onFinishLoaded}
              onClick={exportCSV}
              loading={isExportingCSV}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
            <Button
              type="primary"
              disabled={editDisabled}
              className="dcl-btn-toggle"
              onClick={() => {
                navigate("/bo/wallet/create");
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-plus" as IconProp}
                className="white-plus"
              />
              New Wallet
            </Button>
          </Row>
        </Col>
      </Row>

      <Table
        pagination={{
          ...pagination,
          total: tableLength,
          pageSizeOptions: [10, 50, 100],
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            filterWallets(filterForm.getFieldsValue(), {
              current: page,
              pageSize,
            });
          },
        }}
        sticky
        rowKey="id"
        loading={!onFinishLoaded}
        columns={initialColumns}
        scroll={{ x: 1500 }}
        showSorterTooltip={false}
        dataSource={tableData}
      />
    </Content>
  );
}
