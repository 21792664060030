import { DataType } from "@antv/l7-core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { Content } from "antd/lib/layout/layout";
import { ColumnsType } from "antd/lib/table";
import axios from "axios";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";
import AssignOperation from "../OperationPages/AssignOperation";
import ViewOperationSummary from "../OperationPages/ViewOperationSummary";
import CreateVirtualLedger from "../VenuePages/CreateVirtualLedger";
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

const operationStatusOptions = [
  { key: "all", label: "All", value: "all" },
  { key: "assigned", label: "Assigned", value: "not_null" },
  { key: "closed", label: "Closed", value: "closed" },
  { key: "none", label: "Not Assigned", value: "none" },
  { key: "open", label: "Open", value: "open" },
  { key: "partially_open", label: "Partially Open", value: "partially_open" },
];

export default function ViewEBankingLedger() {
  const location = useLocation();
  const { clientAccountId } = useParams();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const displayContext = useContext(DisplayContext);
  const navigate = useNavigate();
  const [filterForm] = Form.useForm();
  const [onFinishLoad, setOnFinishLoad] = useState<boolean>(false);
  const [data, setData] = useState<any[]>();
  const [fetchDate, setFetchDate] = useState<Date>(new Date());
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  const [isRunning, setIsRunning] = useState<boolean>();
  const [displayReversal, setDisplayReversal] = useState<boolean>(false);
  const [reversalData, setReversalData] = useState<any>();

  const [clientOptions, setClientOptions] = useState<any[]>();
  const [currPage, setCurrPage] = useState<any>(1);
  const [currPageSize, setCurrPageSize] = useState<any>(10);
  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      params: {
        withOperationOpen: JSON.stringify(true),
      },
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }

        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  useEffect(() => {
    getClientOptions();
  }, [getClientOptions]);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today and today
    return current > dayjs().endOf("day");
  };

  const [portfolioOptions, setPortfolioOptions] =
    useState<{ label: string; value: number }[]>();
  const [clientPortfolioOptions, setClientPortfolioOptions] =
    useState<{ label: string; value: number }[]>();

  const fetchDelchainPortfolios = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/delchain-portfolio",
      params: {
        key: "filter_venue",
      },
      withCredentials: true,
    }).then((res) => {
      setPortfolioOptions(res.data);
    });
  }, []);

  const fetchLedgerWithBookEntry = useCallback((bookEntryId: string) => {
    if (!bookEntryId) return;
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking/book-entry",
      withCredentials: true,
      params: {
        bookEntryId: bookEntryId,
      },
    }).then((res) => {
      setDisplayReversal(true);
      setReversalData(res.data);
    });
  }, []);

  const fetchClientPortfolios = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-portfolio",
      withCredentials: true,
    }).then((res) => {
      setClientPortfolioOptions(res.data);
    });
  }, []);

  const fetchData = useCallback(
    (
      params = {
        startDate: undefined,
        endDate: undefined,
        delchainPortfolioIds: undefined as any,
        instantFetch: false,
        operationStatus: undefined,
        transactionWay: undefined,
      },
    ) => {
      setOnFinishLoad(false);
      setCurrPage(1);
      setCurrPageSize(10);
      axios({
        method: "Post",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking",
        withCredentials: true,
        data: {
          startDate: params.startDate,
          endDate: params.endDate,
          delchainPortfolioIds: params.delchainPortfolioIds,
          instantFetch: params.instantFetch,
          operationStatus: params.operationStatus,
          transactionWay: params.transactionWay,
        },
      })
        .then((res) => {
          setFetchDate(new Date(res.data.lastUpdated));
          setData(res.data.transactions);
          setIsRunning(res.data.isRunning);
        })
        .finally(() => setOnFinishLoad(true));
    },
    [],
  );

  const refreshPage = (form: any) => {
    let startDate, endDate;
    setOnFinishLoad(false);
    if (form.dateRange) {
      startDate = form.dateRange[0]
        .tz("America/New_York", true)
        .startOf("day")
        .toISOString();
      endDate = form.dateRange[1]
        .tz("America/New_York", true)
        .endOf("day")
        .toISOString();
    }
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking",
      withCredentials: true,
      data: {
        startDate: startDate,
        endDate: endDate,
        delchainPortfolioIds: form.delchainPortfolioIds
          ? form.delchainPortfolioIds
          : undefined,
        ledgerId: form.ledgerId ? form.ledgerId : undefined,
        entryNo: form.entryNo ? form.entryNo : undefined,
        clientPortfolioIds: form.clientPortfolioIds
          ? form.clientPortfolioIds
          : undefined,
        reversal: form.reversal ? form.reversal : undefined,
        operationStatus: form.operationStatus
          ? form.operationStatus
          : undefined,
        transactionWay: form.transactionWay ? form.transactionWay : undefined,
      },
    })
      .then((res) => {
        res.data.transactions.map((t: any) => (t.key = t.ledger_id));
        setData(res.data.transactions);
      })
      .finally(() => setOnFinishLoad(true));
  };

  const filterEbankingledger = useCallback((form: any) => {
    setCurrPage(1);
    setCurrPageSize(10);
    refreshPage(form);
  }, []);

  const onReset = () => {
    setCurrPage(1);
    setCurrPageSize(10);
    filterForm.resetFields();
    fetchData();
  };

  const downloadCSV = () => {
    setOnFinishLoad(false);
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking/export-csv",
      withCredentials: true,
      data: {
        startDate: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[0]
              .tz("America/New_York", true)
              .startOf("day")
              .toISOString()
          : undefined,
        endDate: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[1]
              .tz("America/New_York", true)
              .endOf("day")
              .toISOString()
          : undefined,
        delchainPortfolioIds: filterForm.getFieldValue("delchainPortfolioIds")
          ? filterForm.getFieldValue("delchainPortfolioIds")
          : undefined,
        ledgerId: filterForm.getFieldValue("ledgerId")
          ? filterForm.getFieldValue("ledgerId")
          : undefined,
        entryNo: filterForm.getFieldValue("entryNo")
          ? filterForm.getFieldValue("entryNo")
          : undefined,
        transactionWay: filterForm.getFieldValue("transactionWay")
          ? filterForm.getFieldValue("transactionWay")
          : undefined,
        clientPortfolioIds: filterForm.getFieldValue("clientPortfolioIds")
          ? filterForm.getFieldValue("clientPortfolioIds")
          : undefined,
        reversal: filterForm.getFieldValue("reversal")
          ? filterForm.getFieldValue("reversal")
          : undefined,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_ebanking_ledger.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };

  const [showOperationAssignModal, setShowOperationAssignModal] =
    useState<boolean>(false);
  const [referenceId, setReferenceId] = useState<number>();
  const [referenceType, setReferenceType] = useState<string>();
  const [referenceItem, setReferenceItem] = useState<any>();

  const [showOperationDetailModal, setShowOperationDetailModal] =
    useState<boolean>(false);
  const [canAssignOperation, setCanAssignOperation] = useState<boolean>(false);
  const fetchAssignCapability = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/access",
      withCredentials: true,
    })
      .then((res) => {
        setCanAssignOperation(res.data.canAssign);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  useEffect(() => {
    fetchAssignCapability();
  }, [fetchAssignCapability]);

  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [venueList, setVenueList] = useState<any[]>();
  const [transactionWayList, setTransactionWayList] = useState<any[]>();
  const fetchTxnWayList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/transaction-way",
      withCredentials: true,
      params: {
        name: "ebanking",
      },
    })
      .then((res) => {
        setTransactionWayList(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  useEffect(() => {
    fetchTxnWayList();
  }, [fetchTxnWayList]);
  const fetchUtils = useCallback(
    (params = {}) => {
      setOnFinishLoad(false);
      axios({
        method: "GET",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
        withCredentials: true,
      })
        .then((res) => {
          setVenueList(res.data.list);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        });
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
        withCredentials: true,
      })
        .then((res) => {
          const options = [];
          for (const asset of res.data.assetList) {
            options.push({
              label: `${asset.name} (${asset.ticker})`,
              value: asset.id,
            });
          }
          setAssetOptions(options);
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
    },
    [navigate],
  );
  useEffect(() => {
    fetchUtils();
  }, [fetchUtils]);

  const [canCreateVirtualLedger, setCanCreateVirtualLedger] =
    useState<boolean>(false);
  const [showCreateVirtualLedger, setShowCreateVirtualLedger] =
    useState<boolean>(false);
  const fetchCanCreateVirtualLedger = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/can-create-virtural-ledger",
      withCredentials: true,
    }).then((res) => {
      setCanCreateVirtualLedger(res.data.canCreateVirtualLedger);
    });
  }, []);
  useEffect(() => {
    fetchCanCreateVirtualLedger();
  }, [fetchCanCreateVirtualLedger]);
  const initialColumns: ColumnsType<DataType> = [
    {
      title: <Space>Timestamp</Space>,
      dataIndex: "timestamp",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
      width: 150,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: displayTimezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
    {
      title: "Operation",
      dataIndex: "operationItem",
      sorter: false,
      render: (
        _: any,
        { id, operationItem, amount, assetId, dir, currency }: any,
      ) =>
        operationItem && operationItem.length ? (
          <Row justify={"center"}>
            <Button
              onClick={() => {
                setReferenceId(id);
                setReferenceType("ebanking_ledger");
                setShowOperationDetailModal(true);
              }}
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
            >
              <FontAwesomeIcon
                icon={
                  operationItem?.some(
                    (item: any) =>
                      item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                      "open",
                  )
                    ? ("fa-solid fa-lock-open" as IconProp)
                    : ("fa-solid fa-lock" as IconProp)
                }
                className={
                  operationItem?.some(
                    (item: any) =>
                      item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                      "open",
                  )
                    ? operationItem?.every(
                        (item: any) =>
                          item.operation?.operationStatus?.name?.toLocaleLowerCase() ===
                          "open",
                      )
                      ? "all-open"
                      : "some-open"
                    : "closed"
                }
              />
            </Button>
          </Row>
        ) : (
          <Row justify={"center"}>
            <Button
              onClick={() => {
                setReferenceId(id);
                setReferenceType("ebanking_ledger");
                setShowOperationAssignModal(true);
                setReferenceItem({
                  amount: amount,
                  assetId: assetId,
                  assetName: currency,
                  fee: {
                    amount: 0,
                    assetId: assetId,
                  },
                  transactionWay: dir,
                });
              }}
              disabled={!canAssignOperation}
              style={{
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
            >
              {canAssignOperation ? (
                <FontAwesomeIcon
                  icon={"fa-regular fa-square-plus" as IconProp}
                  style={{ fontSize: "1.2em" }}
                />
              ) : (
                <></>
              )}
            </Button>
          </Row>
        ),
      width: "100px",
    },
    {
      title: "Ledger ID",
      dataIndex: "ledger_id",
      sorter: (a: any, b: any) => a.ledger_id.localeCompare(b.ledger_id),
      width: 200,
      render: (_: any, { ledger_id, id, dir }: any) => (
        <React.Fragment>
          {ledger_id} &nbsp;
          {dir?.startsWith("Virtual") ? (
            <Button
              style={{
                padding: "1px",
                margin: "1px",
                border: "none",
                background: "none",
                boxShadow: "none",
              }}
              onClick={() => {
                setShowDeleteVirtualLedgerModal(true);
                setDeleteVirtualLedgerId(id);
              }}
            >
              <FontAwesomeIcon icon={"fa-regular fa-trash-can" as IconProp} />
            </Button>
          ) : (
            <></>
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Entry No",
      dataIndex: "entry_no",
      sorter: (a: any, b: any) => a.entry_no.localeCompare(b.entry_no),
      width: 200,
      render: (_: any, { entry_no }: any) => <Space>{entry_no}</Space>,
    },
    {
      title: "Portfolio No",
      dataIndex: "portfolio_no",
      sorter: (a: any, b: any) => a.portfolio_no - b.portfolio_no,
      width: 150,
    },
    {
      title: "Client Portfolio No",
      dataIndex: "client_portfolio_no",
      sorter: (a: any, b: any) => a.client_portfolio_no - b.client_portfolio_no,
      width: 200,
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      sorter: (a: any, b: any) => a.client_name.localeCompare(b.client_name),
      width: 200,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
      width: 200,
      render: (_: any, { description }: any) => <Space>{description}</Space>,
    },
    {
      title: "Accounting Text",
      dataIndex: "accounting_text",
      sorter: (a: any, b: any) =>
        a.accounting_text.localeCompare(b.accounting_text),
      width: 150,
    },
    {
      title: "Dir",
      dataIndex: "dir",
      sorter: (a: any, b: any) => a.dir.localeCompare(b.dir),
      width: 100,
      render: (_: any, { dir }: any) => (
        <Space className={`dcl-${dir}`}>
          {dir === "Virtual Deposit"
            ? "Credit"
            : dir === "Virtual Withdrawal"
            ? "Debit"
            : dir}
        </Space>
      ),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      sorter: (a: any, b: any) => a.currency.localeCompare(b.currency),
      width: 100,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a: any, b: any) => a.amount - b.amount,
      align: "right",
      width: 150,
      render: (_: any, { amount, currency }: any) =>
        currency === "USD" || currency === "EUR" ? (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: "currency",
              currency: currency,
            }).format(amount)}
          </Space>
        ) : (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(amount)}
          </Space>
        ),
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount_usd",
      sorter: (a: any, b: any) => a.amount_usd - b.amount_usd,
      align: "right",
      width: 150,
      render: (_: any, { amount_usd }: any) => (
        <Space>
          {Intl.NumberFormat("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "currency",
            currency: "USD",
          }).format(amount_usd)}
        </Space>
      ),
    },
    {
      title: "Reversed",
      dataIndex: "reversed",
      sorter: (a: any, b: any) => a.reversed.localeCompare(b.reversed),
      width: 80,
      render: (_: any, { reversed, reversal_book_entry }: any) => (
        <Space
          className={`dcl-${reversed}`}
          onClick={() => {
            fetchLedgerWithBookEntry(reversal_book_entry);
          }}
        >
          {reversed}
        </Space>
      ),
    },
    {
      title: "Book Entry",
      dataIndex: "book_entry",
      width: 200,
    },
    {
      title: "Reversal Book Entry",
      dataIndex: "reversal_book_entry",
      width: 200,
    },
    {
      title: "Client Portfolio Label",
      dataIndex: "client_portfolio_label",
      sorter: (a: any, b: any) =>
        a.client_portfolio_label.localeCompare(b.client_portfolio_label),
      width: 200,
      render: (_: any, { client_portfolio_label }: any) => (
        <Space>{client_portfolio_label}</Space>
      ),
    },
    {
      title: "Credit Text",
      dataIndex: "creditText",
      width: 200,
    },
    {
      title: "Debit Text",
      dataIndex: "debitText",
      width: 200,
    },
    {
      title: <Space>Accounting Date</Space>,
      dataIndex: "accounting_date",
      sorter: (a: any, b: any) => a.accounting_date - b.accounting_date,
      width: 200,
      render: (_: any, { accounting_date }: any) => (
        <Space>
          {dayjs(String(accounting_date), "YYYYMMDD")
            .tz(displayContext?.displayContext.timezone, true)
            .format("MM/DD/YYYY")}
        </Space>
      ),
    },
    {
      title: "Value Date",
      dataIndex: "value_date",
      sorter: (a: any, b: any) => a.value_date - b.value_date,
      width: 150,
      // fixed: "right",
      render: (_: any, { value_date }: any) => (
        <Space>
          {dayjs(String(value_date), "YYYYMMDD")
            .tz(displayContext?.displayContext.timezone, true)
            .format("MM/DD/YYYY")}
        </Space>
      ),
    },
  ];

  const fetchIsRunning = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking/is-running",
      withCredentials: true,
    }).then((res) => {
      setIsRunning(res.data.isRunning);
    });
  }, []);

  const instantFetch = useCallback(() => {
    let startDate, endDate, delchainPortfolioIds;
    const form = filterForm.getFieldsValue();
    if (form.dateRange) {
      startDate = form.dateRange[0]
        .tz("America/New_York", true)
        .startOf("day")
        .toISOString();
      endDate = form.dateRange[1]
        .tz("America/New_York", true)
        .endOf("day")
        .toISOString();
    }
    delchainPortfolioIds = form.delchainPortfolioIds
      ? form.delchainPortfolioIds
      : undefined;
    fetchData({
      startDate: startDate,
      endDate: endDate,
      delchainPortfolioIds: delchainPortfolioIds,
      instantFetch: true,
      operationStatus: undefined,
      transactionWay: undefined,
    });
    //fetchData({ instantFetch: true }
  }, [filterForm, fetchData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning) {
        fetchIsRunning();
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [isRunning, fetchIsRunning]);

  useEffect(() => {
    if (
      location.state &&
      location.state.timestamp &&
      location.state.delchainPortfolioId &&
      filterForm &&
      !clientAccountId
    ) {
      setShowFilter(true);
      filterForm.setFieldValue("dateRange", [
        dayjs(location.state.timestamp),
        dayjs(location.state.timestamp),
      ]);
      filterForm.setFieldValue("delchainPortfolioIds", [
        location.state.delchainPortfolioId,
      ]);
      filterForm.setFieldValue(
        "operationStatus",
        location?.state?.operationStatus,
      );
      filterForm.setFieldValue(
        "transactionWay",
        location?.state?.transactionWay,
      );
      refreshPage({
        dateRange: [
          dayjs(location.state.timestamp),
          dayjs(location.state.timestamp),
        ],
        delchainPortfolioIds: [location.state.delchainPortfolioId],
        operationStatus: location?.state?.operationStatus,
      });
    } else if (filterForm && clientAccountId) {
      setShowFilter(true);
      filterForm.setFieldValue("clientPortfolioIds", [Number(clientAccountId)]);
      fetchData({
        startDate: undefined,
        endDate: undefined,
        delchainPortfolioIds: [Number(clientAccountId)],
        instantFetch: false,
        operationStatus: location?.state?.operationStatus,
        transactionWay: location?.state?.transactionWay,
      });
    } else {
      fetchData();
    }
    fetchDelchainPortfolios();
    fetchClientPortfolios();
  }, [
    clientAccountId,
    filterForm,
    fetchData,
    fetchDelchainPortfolios,
    fetchClientPortfolios,
    location,
  ]);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const [showDeleteVirtualLedgerModal, setShowDeleteVirtualLedgerModal] =
    useState<boolean>(false);
  const [deleteVirtualLedgerId, setDeleteVirtualLedgerId] = useState<any>();
  const [isDeletingVirtualLedger, setIsDeletingVirtualLedger] =
    useState<boolean>(false);
  const deleteVirtualLedger = useCallback(() => {
    setIsDeletingVirtualLedger(true);
    axios({
      method: "Delete",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/virtual-ledger",
      data: {
        referenceId: deleteVirtualLedgerId,
        reference: "ebanking_ledger",
      },
      withCredentials: true,
    })
      .then(() => {
        toast.success("Deleted Successfully");
        refreshPage(filterForm.getFieldsValue());
      })
      .finally(() => {
        setIsDeletingVirtualLedger(false);
        setShowDeleteVirtualLedgerModal(false);
        setDeleteVirtualLedgerId(undefined);
      });
  }, [filterForm, deleteVirtualLedgerId]);

  return onFinishLoad ? (
    <Content id="ebanking-ledger">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Row className="dcl-filter-row" align="middle">
            <Space>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
                Filters
                {showFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
            </Space>
            <Space>
              <Button className="ant-btn-primary" onClick={instantFetch}>
                Instant Fetch
              </Button>
            </Space>
            <Space className="dcl-filter-row-space">
              <b>Last updated: </b>
              {fetchDate.toLocaleString("en-US", {
                timeZone: displayTimezone,
                timeZoneName: "short",
              })}
              {isRunning ? <Spin /> : <></>}
            </Space>
          </Row>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={filterEbankingledger}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
            labelWrap
          >
            <Row>
              <Form.Item
                name="dateRange"
                className="dcl-filter-item"
                initialValue={[dayjs().subtract(7, "days"), dayjs()]}
              >
                <RangePicker
                  disabledDate={disabledDate}
                  className="dcl-daterange-select"
                />
              </Form.Item>
              <Form.Item
                name={"delchainPortfolioIds"}
                className="dcl-filter-item"
              >
                <Select
                  options={portfolioOptions}
                  mode="multiple"
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  className="dcl-portfolio-select"
                  placeholder="Delchain Portfolio"
                  popupMatchSelectWidth={false}
                  allowClear
                />
              </Form.Item>
              <Form.Item
                name={"clientPortfolioIds"}
                className="dcl-filter-item"
              >
                <Select
                  options={clientPortfolioOptions}
                  mode="multiple"
                  className="dcl-portfolio-select"
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Client Portfolios"
                  popupMatchSelectWidth={false}
                  allowClear
                />
              </Form.Item>
              <Form.Item name={"ledgerId"} className="dcl-filter-item">
                <Input placeholder="Ledger Id" />
              </Form.Item>
              <Form.Item name={"entryNo"} className="dcl-filter-item">
                <Input placeholder="Entry No" />
              </Form.Item>
              <Form.Item name={"reversal"} className="dcl-filter-item">
                <Select
                  className="dcl-status-select"
                  options={[
                    { label: "Yes", value: "Y" },
                    { label: "No", value: "N" },
                  ]}
                  placeholder="Reversal"
                  popupMatchSelectWidth={false}
                  allowClear
                />
              </Form.Item>
              <Form.Item name="transactionWay" className="dcl-filter-item">
                <Select
                  className="dcl-transaction-way-select"
                  allowClear
                  placeholder="Dir"
                  options={transactionWayList}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="operationStatus" className="dcl-filter-item">
                <Select
                  className="dcl-order-type-select"
                  allowClear
                  placeholder="Operation Status"
                  options={operationStatusOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
            </Row>

            <Row justify="end">
              <Space>
                {data?.length}
                <b>Results</b>
              </Space>
              <Space>
                <Button
                  htmlType="submit"
                  className="ant-btn-primary"
                  loading={!onFinishLoad}
                >
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify="end">
            {canCreateVirtualLedger ? (
              <Button
                type="primary"
                disabled={!canCreateVirtualLedger}
                className="dcl-btn-toggle"
                onClick={() => {
                  setShowCreateVirtualLedger(true);
                }}
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-plus" as IconProp}
                  className="white-plus"
                />
                Virtual Ledger
              </Button>
            ) : (
              <></>
            )}

            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={downloadCSV}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>

      <Table
        dataSource={data}
        sticky
        showSorterTooltip={false}
        rowKey="id"
        columns={initialColumns as any}
        pagination={{
          showSizeChanger: true,
          current: currPage,
          pageSize: currPageSize,
          onChange: (curr, pageSize) => {
            setCurrPage(curr);
            setCurrPageSize(pageSize);
          },
          pageSizeOptions: [10, 50, 100],
        }}
        loading={isRunning}
        scroll={{
          x: "max-header",
        }}
      />
      <Modal
        className="ebanking-ledger"
        width={"60%"}
        open={displayReversal}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={() => {
          setDisplayReversal(false);
        }}
        onOk={() => {
          setDisplayReversal(false);
        }}
        title={<b>Reversal Book Entry</b>}
      >
        {reversalData ? (
          <Row>
            <Col span={3}>
              <b className="ebanking-modal-label">Timestmp:</b>
            </Col>
            <Col span={5}>
              {new Date(reversalData.timestamp).toLocaleString("en-us", {
                timeZone: displayTimezone,
                timeZoneName: "short",
              })}
            </Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Ledger ID:</b>
            </Col>
            <Col span={5}>{reversalData.ledgerId}</Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Entry No.:</b>
            </Col>
            <Col span={5}>{reversalData.bookEntry}</Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Portfolio NO.:</b>
            </Col>
            <Col span={5}>{reversalData.delchainPortfolio.accountNumber}</Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Client Portfolio No.:</b>
            </Col>
            <Col span={5}>
              {reversalData.clientAccount
                ? reversalData.clientAccount.accountNumber
                : ""}
            </Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Client Name:</b>
            </Col>
            <Col span={5}>
              {reversalData.client ? reversalData.client.clientName : ""}
            </Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Description:</b>
            </Col>
            <Col span={5}>{reversalData.description}</Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Accounting Text:</b>
            </Col>
            <Col span={5}>{reversalData.accountingText}</Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Dir:</b>
            </Col>
            <Col span={5}>{reversalData.transactionWay.name}</Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Currency:</b>
            </Col>
            <Col span={5}>
              {reversalData.asset ? reversalData.asset.name : ""}
            </Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Amount:</b>
            </Col>
            <Col span={5}>
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(reversalData.amount)}
            </Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Amount USD:</b>
            </Col>
            <Col span={5}>
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                style: "currency",
                currency: "USD",
              }).format(reversalData.amountUSD)}
            </Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Reversed:</b>
            </Col>
            <Col span={5}>{reversalData.reversed}</Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Client Portfolio Label:</b>
            </Col>
            <Col span={5}>
              {reversalData.clientAccount
                ? reversalData.clientAccount.label
                : ""}
            </Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Credit Text:</b>
            </Col>
            <Col span={5}>{reversalData.creditText}</Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Debit Text:</b>
            </Col>
            <Col span={5}>{reversalData.debitText}</Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Accounting Date:</b>
            </Col>
            <Col span={5}>
              {new Date(reversalData.accountingDate).toLocaleString("en-us", {
                timeZone: displayTimezone,
                timeZoneName: "short",
              })}
            </Col>
            <Col span={3}>
              <b className="ebanking-modal-label">Value Date:</b>
            </Col>
            <Col span={5}>
              {new Date(reversalData.valueDate).toLocaleString("en-us", {
                timeZone: displayTimezone,
                timeZoneName: "short",
              })}
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </Modal>
      <Modal
        open={showOperationAssignModal}
        footer={[]}
        onCancel={() => {
          setShowOperationAssignModal(false);
          setClientOptions([...(clientOptions as any)]);
        }}
      >
        <AssignOperation
          clientOptions={clientOptions}
          setShowOperationAssignModal={setShowOperationAssignModal}
          referenceItem={referenceItem}
          referenceId={referenceId}
          referenceType={referenceType}
          fetchData={refreshPage}
          form={filterForm.getFieldsValue()}
        />
      </Modal>
      <Modal
        open={showOperationDetailModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setReferenceType(undefined);
          setReferenceId(undefined);
          setShowOperationDetailModal(false);
        }}
      >
        <ViewOperationSummary
          clientOptions={clientOptions}
          referenceId={referenceId}
          reference={referenceType}
          setShowModal={setShowOperationDetailModal}
          fetchData={refreshPage}
          form={filterForm.getFieldsValue()}
        />
      </Modal>
      <Modal
        open={showCreateVirtualLedger}
        footer={[]}
        closable={true}
        onCancel={() => {
          setShowCreateVirtualLedger(false);
        }}
        destroyOnClose
      >
        <CreateVirtualLedger
          assetList={assetOptions}
          venueList={venueList}
          setShowCreateVirtualLedger={setShowCreateVirtualLedger}
          fetchData={refreshPage}
          form={filterForm.getFieldsValue()}
        />
      </Modal>
      <Modal
        open={showDeleteVirtualLedgerModal}
        onCancel={() => {
          setShowDeleteVirtualLedgerModal(false);
          setDeleteVirtualLedgerId(undefined);
        }}
        onOk={() => {
          deleteVirtualLedger();
        }}
        cancelButtonProps={{ loading: isDeletingVirtualLedger }}
        okButtonProps={{ loading: isDeletingVirtualLedger }}
      >
        <Alert
          style={{
            marginTop: "30px",
          }}
          message={"Are you sure you want to delete this virtual ledger?"}
          type="warning"
          showIcon
        />
      </Modal>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
