import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../Context/userContext";

function ViewUsers({
  filter,
  onFilterChange,
  userDisplay,
  onDisplayChange,
}: any) {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [filterForm] = Form.useForm();
  const [csvForm] = Form.useForm();
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [clientList, setClientList] = useState<any[]>([]);
  const [editDisabled, setEditDisabled] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [currPageSize, setCurrPageSize] = useState(10);
  const [usersLength, setUsersLength] = useState(0);
  const [userList, setUserList] = useState<any[]>([]);
  const [showFilter, setShowFilter] = useState<boolean>(filter ? true : false);
  const [showCSVSetting, setShowCSVSetting] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [displayColumns, setDisplayColumns] = useState<any[]>([]);
  const [tableSorter, setSorter] = useState<any>();

  const userTypeOptions = [
    { label: "Client", key: "client", value: false },
    { label: "Delchain", key: "delchain", value: true },
    { label: "All", key: "all", value: null },
  ];

  const initialColumns = [
    {
      title: "Last Name",
      dataIndex: "lastName",
      sorter: true,
      hidden: false,
      editable: false,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      sorter: true,
      editable: false,
    },

    {
      title: "Email Address",
      dataIndex: "email",
      sorter: true,
      editable: false,
      render: (_: any, { id, email }: any) => (
        <Space size="middle" key={id}>
          <Link
            to={"/bo/user/info/" + encodeURIComponent(id)}
            state={{ from: window.location.pathname }}
          >
            {email}
          </Link>
        </Space>
      ),
    },
    {
      title: "Clients",
      dataIndex: "clients",
      editable: false,
      render: (_: any, { clients }: any) => (
        <>
          {clients.map((client: any) => {
            const link =
              "/bo/client/info/" + encodeURIComponent(client.clientId);
            return client ? (
              <Space size="middle" key={client.clientId}>
                <Link to={link} state={{ from: window.location.pathname }}>
                  {client.client.clientName}
                </Link>
                <br />
              </Space>
            ) : (
              <></>
            );
          })}
        </>
      ),
    },

    {
      title: "Telegram Username",
      dataIndex: "telegramUsername",
      // width: "15%",
      sorter: true,
      editable: false,
      render: (_: any, { telegramUsername, telegramUserlink }: any) => (
        <Link
          to={""}
          onClick={() => {
            console.log(telegramUserlink);
            window.open(telegramUserlink);
          }}
        >
          {telegramUsername}
        </Link>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      sorter: true,
      editable: false,
      render: (_: any, { country }: any) =>
        country ? <>{country.nicename}</> : <></>,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNum",
      sorter: true,
      editable: false,
    },
    {
      title: "Is Active",
      dataIndex: "isActive",

      editable: false,
      render: (_: any, { id, isActive }: any) => (
        <Space size="small" id={id}>
          <Switch defaultChecked={isActive} disabled />
        </Space>
      ),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      hidden: false,
      editable: false,
      render: (_: any, { id }: any) => (
        <Space size="small" id={id}>
          <Popconfirm
            title="Are you sure you want delete this user?"
            onConfirm={() => {
              axios({
                method: "PUT",
                url:
                  process.env.REACT_APP_AWS_BACKEND_URL +
                  "/user/user-delete-check",
                withCredentials: true,
                data: {
                  userId: id,
                },
              })
                .then(() => {
                  axios({
                    method: "DELETE",
                    url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/",
                    withCredentials: true,
                    data: {
                      userId: id,
                    },
                  }).then(() => {
                    toast.success("User deleted successfully", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    fetchData();
                  });
                })
                .catch((err) => {
                  if (err.response.status === 403) {
                    navigate("/login");
                  }
                  toast.error("Cannot delete this user.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
            }}
          >
            <Button disabled={editDisabled} onClick={() => {}}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      fixed: "right",
      editable: false,
      render: (text: any, record: any) => (
        <Button
          disabled={editDisabled}
          onClick={() => {
            const url = "/bo/user/update/" + encodeURIComponent(record.id);
            navigate(url, { state: { from: window.location.pathname } });
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const changeColumnDisplay = (dataIndex: string) => {
    columns.find((e) => e.dataIndex === dataIndex).hidden = !columns.find(
      (e) => e.dataIndex === dataIndex,
    ).hidden;
    //setColumns(columns);
    onDisplayChange(columns);
    setDisplayColumns(columns.filter((item) => !item.hidden));
  };

  const handleTableChange = (_: any, __: any, sorter: any) => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/user",
      params: {
        offset:
          currentPage === 1 || currentPage === 0
            ? 0
            : (currentPage - 1) * currPageSize,
        pageSize: currPageSize,
        roleId: userContext?.user.roleId,
        firstName: filterForm.getFieldValue("firstName"),
        lastName: filterForm.getFieldValue("lastName"),
        email: filterForm.getFieldValue("email"),
        isActive: filterForm.getFieldValue("isActive"),
        isEmployee: filterForm.getFieldValue("isEmployee"),
        clientId: filterForm.getFieldValue("clientId"),
        telegramUsername: filterForm.getFieldValue("telegramUsername"),
        sortField: sorter.field,
        sortOrder: sorter.order,
      },
      withCredentials: true,
    })
      .then((res) => {
        res.data.users?.forEach((user: any) => {
          user.key = user.id;
        });
        setUserList(res.data.users);
      })
      .catch(() => {
        console.error("Unable to fetch user list");
      });
    setSorter({ ...sorter });
  };

  const downloadCSV = () => {
    const displayOptions = {
      lastName: csvForm.getFieldValue("lastName"),
      firstName: csvForm.getFieldValue("firstName"),
      clients: csvForm.getFieldValue("clients"),
      isActive: csvForm.getFieldValue("isActive"),
      email: csvForm.getFieldValue("email"),
      mobileNum: csvForm.getFieldValue("mobileNum"),
      telegram: csvForm.getFieldValue("telegram"),
      country: csvForm.getFieldValue("country"),
    };
    const filterOptions = {
      lastName: filterForm.getFieldValue("lastName"),
      firstName: filterForm.getFieldValue("firstName"),
      email: filterForm.getFieldValue("email"),
      isActive: filterForm.getFieldValue("isActive"),
      clientId: filterForm.getFieldValue("clientId"),
      telegramUsername: filterForm.getFieldValue("telegramUsername"),
      isEmployee: filterForm.getFieldValue("isEmployee"),
    };
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/export-csv",
      withCredentials: true,
      data: {
        filter: filterOptions,
        displayOptions: displayOptions,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_users.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  };

  const filterUsers = (form: any) => {
    setOnFinishLoad(false);
    onFilterChange(form);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/",
      withCredentials: true,
      params: {
        offset: 0,
        pageSize: currPageSize,
        roleId: userContext?.user.roleId,
        firstName: form.firstName ? form.firstName.trim() : "",
        lastName: form.lastName ? form.lastName.trim() : "",
        email: form.email,
        isActive: form.isActive,
        clientId: form.clientId,
        isEmployee: form.isEmployee,
        telegramUsername: form.telegramUsername,
      },
    })
      .then((res) => {
        setCurrentPage(0);
        setUsersLength(res.data.totalUsers);
        for (const row of res.data.users) {
          row.key = row.id;
        }
        setUserList(res.data.users);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };
  const onReset = () => {
    fetchData();
    filterForm.resetFields();
    onFilterChange(undefined);
  };
  const fetchData = useCallback(
    (params = {}) => {
      setOnFinishLoad(false);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/",
        params: {
          offset: 0,
          pageSize: 10,
          roleId: userContext?.user.roleId,
          firstName: filterForm
            ? filterForm.getFieldValue("firstName")
            : undefined,
          lastName: filterForm
            ? filterForm.getFieldValue("lastName")
            : undefined,
          email: filterForm ? filterForm.getFieldValue("email") : undefined,
          isActive: filterForm
            ? filterForm.getFieldValue("isActive")
            : undefined,
          clientId: filterForm
            ? filterForm.getFieldValue("clientId")
            : undefined,
          telegramUsername: filterForm
            ? filterForm.getFieldValue("telegramUsername")
            : undefined,
          isEmployee: filterForm
            ? filterForm.getFieldValue("isEmployee")
            : undefined,
        },
        withCredentials: true,
      })
        .then((res) => {
          //console.log(res.data);
          setCurrentPage(0);
          setCurrPageSize(10);
          setUsersLength(res.data.totalUsers);
          for (const row of res.data.users) {
            row.key = row.id;
          }
          setUserList(res.data.users);
          setEditDisabled(!res.data.canEditUser);
          //console.log(res);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/",
        withCredentials: true,
      }).then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.clientName} ${
            c.deltecAccount ? `(${c.deltecAccount})` : ""
          }`;
        }
        setClientList(res.data.clients);
      });
    },
    [userContext?.user.roleId, filterForm, navigate],
  );

  const onPaginationChange = (current: any, pageSize: any) => {
    //console.log(current, pageSize);
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/user",
      params: {
        offset: current === 1 || current === 0 ? 0 : (current - 1) * pageSize,
        pageSize: pageSize,
        roleId: userContext?.user.roleId,
        firstName: filterForm.getFieldValue("firstName"),
        lastName: filterForm.getFieldValue("lastName"),
        email: filterForm.getFieldValue("email"),
        isActive: filterForm.getFieldValue("isActive"),
        clientId: filterForm.getFieldValue("clientId"),
        isEmployee: filterForm.getFieldValue("isEmployee"),
        telegramUsername: filterForm.getFieldValue("telegramUsername"),
        sortField: tableSorter ? tableSorter.field : undefined,
        sortOrder: tableSorter ? tableSorter.order : undefined,
      },
      withCredentials: true,
    })
      .then((res) => {
        setCurrentPage(current);
        setCurrPageSize(pageSize);
        res.data.users?.forEach((user: any) => {
          user.key = user.id;
        });
        setUserList(res.data.users);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };

  useEffect(() => {
    if (filterForm && filter) {
      filterForm.setFieldsValue(filter);
    }
    fetchData();
    if (userDisplay) {
      setColumns(userDisplay);
      setDisplayColumns(userDisplay.filter((item: any) => !item.hidden));
    } else {
      setColumns(initialColumns);
      setDisplayColumns(initialColumns.filter((item) => !item.hidden));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDisabled, filter, filterForm]);
  return onFinshiLoad ? (
    <React.Fragment>
      <Content id="view-users">
        <Row className="dcl-filter-row">
          <Col md={{ span: 16 }} sm={{ span: 16 }}>
            <Space>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowCSVSetting(false);
                  setShowFilter(!showFilter);
                }}
              >
                <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
                Filters
                {showFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowFilter(false);
                  setShowCSVSetting(!showCSVSetting);
                }}
              >
                Display
                {showCSVSetting ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
            </Space>
            <Form
              title="Filter"
              form={filterForm}
              onFinish={filterUsers}
              className="dcl-toggled-content dcl-toggled-content-filter"
              hidden={!showFilter}
            >
              <Row>
                <Form.Item name="lastName" className="dcl-filter-item">
                  <Input placeholder="Last Name" />
                </Form.Item>
                <Form.Item name="firstName" className="dcl-filter-item">
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  name="email"
                  className="dcl-filter-item"
                  normalize={(value) => value.trim()}
                >
                  <Input placeholder="Email" />
                </Form.Item>
                <Form.Item name="telegramUsername" className="dcl-filter-item">
                  <Input placeholder="Telegram Username" />
                </Form.Item>
                <Form.Item name="isActive" className="dcl-filter-item">
                  <Select
                    placeholder="Status"
                    popupMatchSelectWidth={false}
                    className="dcl-status-select"
                    showSearch
                    filterOption={(input, option: any) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    <Select.Option key={"true"} value={true}>
                      Active
                    </Select.Option>
                    <Select.Option key={"false"} value={false}>
                      Inactive
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="clientId" className="dcl-filter-item">
                  <Select
                    className="dcl-client-select"
                    showSearch
                    placeholder="Client"
                    popupMatchSelectWidth={false}
                    filterOption={(input, option: any) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {clientList?.map((client) => (
                      <Select.Option
                        key={client.id}
                        label={client.label}
                        value={client.id}
                      >
                        {client.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="isEmployee"
                  className="dcl-filter-item"
                  initialValue={false}
                >
                  <Select
                    placeholder="User Type"
                    popupMatchSelectWidth={false}
                    className="dcl-status-select"
                    showSearch
                    filterOption={(input, option: any) => {
                      return option?.label
                        ?.toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    options={userTypeOptions}
                  />
                </Form.Item>
              </Row>
              <Row justify="end">
                <Space>
                  {usersLength}
                  <b>Results</b>
                </Space>
                <Space>
                  <Button htmlType="submit" className="ant-btn-primary">
                    Apply
                  </Button>
                  <Button onClick={onReset}>Reset</Button>
                </Space>
              </Row>
            </Form>

            <Form
              className="dcl-toggled-content dcl-toggled-content-csv"
              form={csvForm}
              hidden={!showCSVSetting}
            >
              <Row>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      userDisplay
                        ? !userDisplay.find(
                            (e: any) => e.dataIndex === "lastName",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("lastName");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      userDisplay
                        ? !userDisplay.find(
                            (e: any) => e.dataIndex === "firstName",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("firstName");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      userDisplay
                        ? !userDisplay.find((e: any) => e.dataIndex === "email")
                            .hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("email");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="clients"
                  label="Clients"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      userDisplay
                        ? !userDisplay.find(
                            (e: any) => e.dataIndex === "clients",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("clients");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="telegram"
                  label="Telegram Username"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      userDisplay
                        ? !userDisplay.find(
                            (e: any) => e.dataIndex === "telegramUsername",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("telegramUsername");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="country"
                  label="Country"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      userDisplay
                        ? !userDisplay.find(
                            (e: any) => e.dataIndex === "country",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("country");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="mobileNum"
                  label="Mobile Number"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      userDisplay
                        ? !userDisplay.find(
                            (e: any) => e.dataIndex === "mobileNum",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("mobileNum");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="isActive"
                  label="Is Active"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      userDisplay
                        ? !userDisplay.find(
                            (e: any) => e.dataIndex === "isActive",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("isActive");
                    }}
                  />
                </Form.Item>
              </Row>
            </Form>
          </Col>
          <Col md={{ span: 8 }} sm={{ span: 8 }}>
            <Row justify="end">
              <Button className="dcl-btn-toggle" onClick={downloadCSV}>
                <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
                CSV Export
              </Button>
              <Button
                type="primary"
                disabled={editDisabled}
                className="dcl-btn-toggle"
                onClick={() => {
                  navigate("/bo/user/create");
                }}
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-plus" as IconProp}
                  className="white-plus"
                />
                New User
              </Button>
            </Row>
          </Col>
        </Row>
        <Table
          columns={displayColumns}
          dataSource={userList}
          onChange={handleTableChange}
          showSorterTooltip={false}
          pagination={{ pageSize: currPageSize, hideOnSinglePage: true }}
        />
        <Row justify="end" className="pagination-row">
          <Pagination
            showSizeChanger
            onChange={onPaginationChange}
            current={currentPage}
            pageSize={currPageSize}
            // pageSizeOptions={[2, 4, 10]}
            total={usersLength}
          />
        </Row>
      </Content>
    </React.Fragment>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}

export default ViewUsers;
