import { Col, Row, Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import TelegramBroadcast from "./TelegramBoardcast";
import CreateTelegramBroadcastGroup from "./TelegramBroadcastGroup";

export default function TelegramBroadcastContainer() {
  const { tabName = "broadcast" } = useParams();
  const navigate = useNavigate();
  return (
    <Content id="telegram-backup-container">
      <Tabs
        type="card"
        defaultActiveKey={tabName}
        onChange={(key) => {
          navigate("/bo/settings/telegram-broadcast/" + key);
        }}
        items={[
          {
            label: "Telegram Broadcast",
            key: "broadcast",
            children: (
              <Row justify="center">
                <Col xxl={{ span: 12 }} lg={{ span: 18 }} md={{ span: 24 }}>
                  <TelegramBroadcast />
                </Col>
              </Row>
            ),
          },
          {
            label: "Telegram Broadcast Groups",
            key: "group",
            children: (
              <Row justify="center">
                <Col xxl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
                  <CreateTelegramBroadcastGroup />
                </Col>
              </Row>
            ),
          },
        ]}
      />
    </Content>
  );
}
