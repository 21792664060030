import { Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DisplayContext } from "../../Context/displayContext";
import ViewVenueLedgers from "./ViewVenueTransactionTabs/ViewVenueLedgers";
import ViewVenueOrders from "./ViewVenueTransactionTabs/ViewVenueOrders";
import ViewVenueTrades from "./ViewVenueTransactionTabs/ViewVenueTrades";

export default function ViewVenueTransactions() {
  const { tabName = "orders" } = useParams();
  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayContext, displayTimezone]);

  useEffect(() => {
    if (!["orders", "trades", "ledgers"].includes(tabName)) {
      navigate("/bo/venues/delchain_transactions/");
    }
  }, [navigate, tabName]);

  // Timezone Display
  return (
    <Content>
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/venues/delchain_transactions/" + key);
        }}
        activeKey={tabName}
        items={[
          {
            label: "Orders",
            key: "orders",
            children: (
              <React.Fragment>
                <ViewVenueOrders tabName={tabName} />
              </React.Fragment>
            ),
          },
          {
            label: "Trades",
            key: "trades",
            children: (
              <React.Fragment>
                <ViewVenueTrades tabName={tabName}/>
              </React.Fragment>
            ),
          },
          {
            label: "Ledgers",
            key: "ledgers",
            children: (
              <React.Fragment>
                <ViewVenueLedgers tabName={tabName}/>
              </React.Fragment>
            ),
          },
        ]}
      />
    </Content>
  );
}
