import { Col, Divider, Row, Select, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import TradeReportChart from "./TradeReportChart";

export default function ViewVenueTradingReportOrders() {
  const STARTYEAR = 2017;
  const dataViewOptions = [
    { label: "Weekly View", key: 0, value: "Weekly View" },
    { label: "Monthly View", key: 1, value: "Monthly View" },
  ];
  const yearOptions = Array.from(
    { length: new Date().getFullYear() - STARTYEAR + 1 },
    (_, index) => {
      return {
        value: String(STARTYEAR + index),
        label: STARTYEAR + index,
        key: index,
      };
    },
  );
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false);
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [venueList, setVenueList] = useState<any[]>([]);
  const [selectedVenues, setSelectedVenues] = useState<any[]>([]);
  const [orderSummaryData, setOrderSummaryData] = useState<any>();
  const [currentOrderSummaryData, setCurrentOrderSummaryData] = useState<any[]>(
    [],
  );
  const [selectedViewType, setSelectedViewType] =
    useState<string>("Weekly View");
  const [selectedYear, setSelectedYear] = useState<string>(
    dayjs().format("YYYY"),
  );
  const fetchListToFetch = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/list-to-fetch",
      withCredentials: true,
    }).then((res) => {
      const venues = res.data.listToFetch.map((v: any) => {
        return { key: v.key, label: v.name, value: v.name };
      });
      setVenueList(venues);
      const values = res.data.listToFetch.map((v: any) => v.name);
      setSelectedVenues(values);
    });
  }, []);
  const fetchOrderSummaryData = useCallback(() => {
    setFetchingData(true);
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/trading-report-order-summary",
      params: {
        year: selectedYear,
      },
      withCredentials: true,
    })
      .then((res) => {
        setOrderSummaryData(res.data);
        const newOrderSummaryData = [];
        // Weekly View
        if (selectedViewType === "Weekly View") {
          for (let key = 1; key <= 52; key++) {
            let tradeCount = 0;
            let tradeVolume = 0;
            const data = res.data.weeklyView[key];
            if (!data) {
              newOrderSummaryData.push({
                name: `W ${key}`,
                tradeCount: tradeCount,
                tradeVolume: tradeVolume,
              });
              continue;
            }
            for (const objectKey of Object.keys(data)) {
              if (selectedVenues.includes(objectKey)) {
                tradeCount += data[objectKey] ? Number(data[objectKey]) : 0;
                tradeVolume += data[objectKey + "Amount"]
                  ? Number(data[objectKey + "Amount"])
                  : 0;
              }
            }
            newOrderSummaryData.push({
              name: `W ${key}`,
              tradeCount: tradeCount,
              tradeVolume: tradeVolume,
            });
          }
        } else {
          for (let key = 0; key < 12; key++) {
            const month = dayjs().month(key).format("MMMM");

            let tradeCount = 0;
            let tradeVolume = 0;

            const data = res.data.monthlyView[key];
            if (!data) {
              newOrderSummaryData.push({
                name: ` ${month}`,
                tradeCount: tradeCount,
                tradeVolume: tradeVolume,
              });
              continue;
            }
            for (const objectKey of Object.keys(data)) {
              if (selectedVenues.includes(objectKey)) {
                tradeCount += data[objectKey] ? Number(data[objectKey]) : 0;
                tradeVolume += data[objectKey + "Amount"]
                  ? Number(data[objectKey + "Amount"])
                  : 0;
              }
            }
            newOrderSummaryData.push({
              name: ` ${month}`,
              tradeCount: tradeCount,
              tradeVolume: tradeVolume,
            });
          }
        }
        setCurrentOrderSummaryData(newOrderSummaryData);
        setOrderSummaryData(res.data);
      })
      .finally(() => {
        setFetchingData(false);
      });
  }, [selectedVenues, selectedYear, selectedViewType]);

  const generateNewDataArray = useCallback(
    (newOrderSummaryData: any, viewType: string, selectedVenues: string[]) => {
      if (viewType === "Weekly View") {
        for (let key = 1; key <= 52; key++) {
          let tradeCount = 0;
          let tradeVolume = 0;
          const data = orderSummaryData.weeklyView[key];
          if (!data) {
            newOrderSummaryData.push({
              name: `W ${key}`,
              tradeCount: tradeCount,
              tradeVolume: tradeVolume,
            });
            continue;
          }
          for (const objectKey of Object.keys(data)) {
            if (selectedVenues.includes(objectKey)) {
              tradeCount += data[objectKey] ? Number(data[objectKey]) : 0;
              tradeVolume += data[objectKey + "Amount"]
                ? Number(data[objectKey + "Amount"])
                : 0;
            }
          }
          newOrderSummaryData.push({
            name: `W ${key}`,
            tradeCount: tradeCount,
            tradeVolume: tradeVolume,
          });
        }
      } else {
        for (let key = 0; key < 12; key++) {
          const month = dayjs().month(key).format("MMMM");

          let tradeCount = 0;
          let tradeVolume = 0;
          const data = orderSummaryData.monthlyView[key];
          if (!data) {
            newOrderSummaryData.push({
              name: ` ${month}`,
              tradeCount: tradeCount,
              tradeVolume: tradeVolume,
            });
            continue;
          }
          for (const objectKey of Object.keys(data)) {
            if (selectedVenues.includes(objectKey)) {
              tradeCount += data[objectKey] ? Number(data[objectKey]) : 0;
              tradeVolume += data[objectKey + "Amount"]
                ? Number(data[objectKey + "Amount"])
                : 0;
            }
          }
          newOrderSummaryData.push({
            name: ` ${month}`,
            tradeCount: tradeCount,
            tradeVolume: tradeVolume,
          });
        }
      }
      return newOrderSummaryData;
    },
    [orderSummaryData],
  );

  const onVenueChange = useCallback(
    (e: any) => {
      setSelectedVenues(e);
      const newOrderSummaryData: any[] = [];
      generateNewDataArray(newOrderSummaryData, selectedViewType, e);

      setCurrentOrderSummaryData(newOrderSummaryData);
    },
    [generateNewDataArray, selectedViewType],
  );

  const onViewTypeChange = useCallback(
    (e: any) => {
      setSelectedViewType(e);
      const newOrderSummaryData: any[] = [];
      generateNewDataArray(newOrderSummaryData, e, selectedVenues);
      setCurrentOrderSummaryData(newOrderSummaryData);
    },
    [generateNewDataArray, selectedVenues],
  );

  useEffect(() => {
    fetchListToFetch();
  }, [fetchListToFetch]);
  useEffect(() => {
    if (initialFetchDone) return;
    if (selectedVenues && selectedVenues.length > 0) {
      fetchOrderSummaryData();
      setInitialFetchDone(true);
    }
  }, [fetchOrderSummaryData, initialFetchDone, selectedVenues]);
  return (
    <Content id="venue-trading-report-order-summary">
      <Row className="dcl-filter-row">
        <Col
          className="dcl-filter-item"
          xl={{ span: 2 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Select
            className="dcl-date-select"
            options={yearOptions}
            defaultValue={selectedYear}
            popupMatchSelectWidth={false}
            onChange={(e) => {
              setSelectedYear(e);
              setInitialFetchDone(false);
            }}
          />
        </Col>
        <Col
          className="dcl-filter-item"
          xl={{ span: 3 }}
          lg={{ span: 3 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Select
            className="dcl-status-select "
            options={dataViewOptions}
            defaultValue={selectedViewType}
            popupMatchSelectWidth={false}
            onChange={(e) => {
              onViewTypeChange(e);
            }}
          />
        </Col>
        <Col
          className="dcl-filter-item"
          xl={{ span: 16 }}
          lg={{ span: 24 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Select
            className="dcl-venue-select"
            placeholder="Venues"
            options={venueList}
            value={selectedVenues}
            popupMatchSelectWidth={false}
            allowClear
            onChange={(e) => {
              onVenueChange(e);
            }}
            showSearch
            filterOption={(input, option: any) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            mode="multiple"
          />
        </Col>
      </Row>

      <Divider />
      {fetchingData ? (
        <Row justify={"center"}>
          <Spin size="large"  />
        </Row>
      ) : (
        <TradeReportChart
          data={currentOrderSummaryData}
          yearNum={selectedYear}
          viewType={selectedViewType}
        />
      )}
    </Content>
  );
}
