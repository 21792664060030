import { Alert, Button, Col, Divider, Row, Space, Table, Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";
import FileUploader from "./UploadComponents/FileUploader";
import LBankUpload from "./UploadComponents/LBankUpload";

export default function ViewVenueUploads() {
  const tradeInputRef = useRef<HTMLInputElement>(null);
  const orderInputRef = useRef<HTMLInputElement>(null);
  const ledgerInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [fileType, setFileType] = useState<string>();
  const [parsedData, setParsedData] = useState<any[]>();
  const [onDataloaded, setOnDataLoaded] = useState<boolean>(true);
  const [errorMessages, setErrorMessages] = useState<any[]>();
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);
  const [duplicateTxs, setDuplicateTxs] = useState<any[]>();

  const handleFileChange = (
    e: ChangeEvent<HTMLInputElement>,
    fileTypeString: string,
  ) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      setFileUploaded(false);
      setParsedData(undefined);
      setErrorMessages(undefined);
      setDuplicateTxs(undefined);
      setFileType(fileTypeString);
    }
  };

  const initialColumnsForOrder = [
    {
      title: "Datetime",
      dataIndex: "datetime",
      render: (_: any, { dateError, datetime }: any) => (
        <Space style={{ color: dateError ? "red" : "black" }}>{datetime}</Space>
      ),
    },
    { title: "Order Id", dataIndex: "orderId" },
    {
      title: "Venue",
      dataIndex: "venue",
      render: (_: any, { venueError, venue }: any) => (
        <Space style={{ color: venueError ? "red" : "black" }}>{venue}</Space>
      ),
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      render: (_: any, { orderStatus, orderStatusError }: any) => (
        <Space style={{ color: orderStatusError ? "red" : "black" }}>
          {orderStatus}
        </Space>
      ),
    },
    {
      title: "Order Type",
      dataIndex: "orderType",
      render: (_: any, { orderType, orderTypeError }: any) => (
        <Space style={{ color: orderTypeError ? "red" : "black" }}>
          {orderType}
        </Space>
      ),
    },
    {
      title: "Transaction Way",
      dataIndex: "transactionWay",
      render: (_: any, { transactionWay, transactionWayError }: any) => (
        <Space style={{ color: transactionWayError ? "red" : "black" }}>
          {transactionWay}
        </Space>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      render: (_: any, { symbolError, symbol }: any) => (
        <Space style={{ color: symbolError ? "red" : "black" }}>{symbol}</Space>
      ),
    },
    { title: "Price", dataIndex: "price", align: "right" as const },
    { title: "Base Amount", dataIndex: "baseAmount", align: "right" as const },
    {
      title: "Base Fill Amount",
      dataIndex: "baseFillAmount",
      align: "right" as const,
    },
    {
      title: "Base Remain Amount",
      dataIndex: "baseRemainAmount",
      align: "right" as const,
    },
    {
      title: "Average Fill Price",
      dataIndex: "avgFillPrice",
      align: "right" as const,
    },
    { title: "Fee Amount", dataIndex: "fee", align: "right" as const },
    {
      title: "Fee Currency",
      dataIndex: "feeCurrency",
      // fixed: "right" as const,
      render: (_: any, { feeCurrency, feeCurrencyError }: any) => (
        <Space style={{ color: feeCurrencyError ? "red" : "black" }}>
          {feeCurrency}
        </Space>
      ),
    },
  ];

  const initialColumnsForTrade = [
    {
      title: "Datetime",
      dataIndex: "datetime",
      render: (_: any, { dateError, datetime }: any) => (
        <Space style={{ color: dateError ? "red" : "black" }}>{datetime}</Space>
      ),
    },
    { title: "Trade Id", dataIndex: "tradeId" },
    { title: "Order Id", dataIndex: "orderId" },
    {
      title: "Venue",
      dataIndex: "venue",
      render: (_: any, { venueError, venue }: any) => (
        <Space style={{ color: venueError ? "red" : "black" }}>{venue}</Space>
      ),
    },
    {
      title: "Transaction Way",
      dataIndex: "transactionWay",
      render: (_: any, { transactionWay, transactionWayError }: any) => (
        <Space style={{ color: transactionWayError ? "red" : "black" }}>
          {transactionWay}
        </Space>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      render: (_: any, { symbolError, symbol }: any) => (
        <Space style={{ color: symbolError ? "red" : "black" }}>{symbol}</Space>
      ),
    },
    { title: "Base Amount", dataIndex: "baseAmount", align: "right" as const },
    { title: "Price", dataIndex: "price", align: "right" as const },
    { title: "Fee Amount", dataIndex: "fee", align: "right" as const },
    {
      title: "Fee Currency",
      dataIndex: "feeCurrency",
      // fixed: "right" as const,
      render: (_: any, { feeCurrency, feeCurrencyError }: any) => (
        <Space style={{ color: feeCurrencyError ? "red" : "black" }}>
          {feeCurrency}
        </Space>
      ),
    },
  ];

  const initialColumnsForLedger = [
    {
      title: "Datetime",
      dataIndex: "datetime",
      render: (_: any, { dateError, datetime }: any) => (
        <Space style={{ color: dateError ? "red" : "black" }}>{datetime}</Space>
      ),
    },
    { title: "Ledger Id", dataIndex: "ledgerId" },
    { title: "Transaction Id", dataIndex: "txId" },
    {
      title: "Venue",
      dataIndex: "venue",
      render: (_: any, { venueError, venue }: any) => (
        <Space style={{ color: venueError ? "red" : "black" }}>{venue}</Space>
      ),
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      render: (_: any, { orderStatus, orderStatusError }: any) => (
        <Space style={{ color: orderStatusError ? "red" : "black" }}>
          {orderStatus}
        </Space>
      ),
    },
    {
      title: "Transaction Way",
      dataIndex: "transactionWay",
      render: (_: any, { transactionWay, transactionWayError }: any) => (
        <Space style={{ color: transactionWayError ? "red" : "black" }}>
          {transactionWay}
        </Space>
      ),
    },
    {
      title: "Asset",
      dataIndex: "asset",
      render: (_: any, { assetError, asset }: any) => (
        <Space style={{ color: assetError ? "red" : "black" }}>{asset}</Space>
      ),
    },
    { title: "Amount", dataIndex: "amount", align: "right" as const },
    { title: "Fee Amount", dataIndex: "fee", align: "right" as const },
    {
      title: "Fee Currency",
      dataIndex: "feeCurrency",
      // fixed: "right" as const,
      render: (_: any, { feeCurrency, feeCurrencyError }: any) => (
        <Space style={{ color: feeCurrencyError ? "red" : "black" }}>
          {feeCurrency}
        </Space>
      ),
    },
  ];

  const handleUploadClick = () => {
    setFileUploaded(false);
    setErrorMessages(undefined);
    if (!file) {
      return;
    }
    // creates a new FormData object and appends the file to it
    const formData = new FormData();
    formData.append("file", file);
    formData.append("txType", String(fileType));
    setOnDataLoaded(false);
    axios({
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/upload-csv",
      method: "POST",
      headers: { "content-typs": "multipart/form-data" },
      withCredentials: true,
      data: formData,
    })
      .then((res) => {
        setParsedData(res.data.records);
        setErrorMessages(res.data.errorMessages);
        setFileUploaded(true);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => {
        setOnDataLoaded(true);
        setFile(undefined);
        if (tradeInputRef.current) {
          tradeInputRef.current.value = "";
        }
        if (orderInputRef.current) {
          orderInputRef.current.value = "";
        }
        if (ledgerInputRef.current) {
          ledgerInputRef.current.value = "";
        }
      });
  };

  const handleSave = useCallback(() => {
    axios({
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/save-manual-uploads",
      method: "POST",
      headers: { "content-typs": "multipart/form-data" },
      withCredentials: true,
      data: {
        fileType: fileType,
        records: parsedData,
      },
    })
      .then((res) => {
        toast.success("Trade Data saved successfully");
        if (!res.data.duplicateTxs.length) {
          setFile(undefined);
          setFileUploaded(false);
          setParsedData(undefined);
          setOnDataLoaded(false);
        } else {
          setDuplicateTxs(res.data.duplicateTxs);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  }, [fileType, parsedData]);

  return (
    <Content id="venue-manual-upload">
      <Tabs
        defaultActiveKey={"manual-upload"}
        type="card"
        items={[
          {
            label: "Manual Upload",
            key: "manual-upload",
            children: (
              <React.Fragment>
                <Row justify="center">
                  <Col xxl={{ span: 6 }} lg={{ span: 7 }} md={{ span: 24 }}>
                    <FileUploader
                      cardId={"card-upload-trades"}
                      title={"Trades"}
                      fileType={"trade"}
                      disabled={
                        file?.type !== "text/csv" || fileType !== "trade"
                      }
                      inputRef={tradeInputRef}
                      handleFileChange={handleFileChange}
                      handleUploadClick={handleUploadClick}
                    />
                  </Col>
                  <Col xxl={{ span: 6 }} lg={{ span: 7 }} md={{ span: 24 }}>
                    <FileUploader
                      cardId={"card-upload-orders"}
                      title={"Orders"}
                      fileType={"order"}
                      disabled={
                        file?.type !== "text/csv" || fileType !== "order"
                      }
                      inputRef={orderInputRef}
                      handleFileChange={handleFileChange}
                      handleUploadClick={handleUploadClick}
                    />
                  </Col>
                  <Col xxl={{ span: 6 }} lg={{ span: 7 }} md={{ span: 24 }}>
                    <FileUploader
                      cardId={"card-upload-ledgers"}
                      title={"Ledgers"}
                      fileType={"ledger"}
                      disabled={
                        file?.type !== "text/csv" || fileType !== "ledger"
                      }
                      inputRef={ledgerInputRef}
                      handleFileChange={handleFileChange}
                      handleUploadClick={handleUploadClick}
                    />
                  </Col>
                </Row>
                <Divider />
                {fileType === "trade" && fileUploaded ? (
                  <Table
                    columns={initialColumnsForTrade}
                    dataSource={parsedData}
                    rowKey="id"
                    loading={!onDataloaded}
                    scroll={{ x: 2000 }}
                  />
                ) : (
                  <></>
                )}
                {fileType === "order" && fileUploaded ? (
                  <Table
                    columns={initialColumnsForOrder}
                    dataSource={parsedData}
                    rowKey="id"
                    loading={!onDataloaded}
                    scroll={{ x: 2000 }}
                  />
                ) : (
                  <></>
                )}
                {fileType === "ledger" && fileUploaded ? (
                  <Table
                    columns={initialColumnsForLedger}
                    dataSource={parsedData}
                    rowKey="id"
                    loading={!onDataloaded}
                    scroll={{ x: 2000 }}
                  />
                ) : (
                  <></>
                )}
                {errorMessages?.map((error, index) => (
                  <Alert
                    className="error-icon"
                    message={error}
                    type="error"
                    key={index}
                    showIcon
                  />
                ))}
                {duplicateTxs?.map((tx, index) => (
                  <Alert
                    className="warning-icon"
                    message={`Skipped to insert ${tx.type} with id ${tx.id} on venue ${tx.venue}. It has already been inserted into database earlier.`}
                    type="warning"
                    key={index}
                    showIcon
                  />
                ))}
                <Row justify="center">
                  {(!errorMessages || errorMessages?.length === 0) &&
                  !duplicateTxs &&
                  fileUploaded &&
                  parsedData ? (
                    <Button type="primary" onClick={handleSave}>
                      Save
                    </Button>
                  ) : (
                    <></>
                  )}
                </Row>
              </React.Fragment>
            ),
          },

          {
            label: "LBank Upload",
            key: "lbank",
            children: <LBankUpload />,
          },
        ]}
      ></Tabs>
    </Content>
  );
}
