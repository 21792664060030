import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Pagination,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "../../../Context/displayContext";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const { RangePicker } = DatePicker;

const DEFAULT_START_DATETIME = dayjs().hour(8).minute(0).second(0),
  DEFAULT_END_DATETIME = dayjs().hour(18).minute(0).second(0);

function ViewVenueMarketTrades() {
  const displayContext = useContext(DisplayContext);
  const navigate = useNavigate();
  const [onFinishLoad, setOnFinishLoad] = useState<boolean>(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [currPageSize, setCurrPageSize] = useState(100);
  const [tradeCount, setTradeCount] = useState();

  // Table
  const [tableData, setTableData] = useState<any[]>([]);

  // Filter
  const [filterForm] = Form.useForm();
  const [showFilter, setShowFilter] = useState<boolean>(false);

  // Flter Options
  const [venueList, setVenueList] = useState<any[]>();
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [transactionWayList, setTransactionWayList] = useState<any[]>([]);

  const [baseAssetIds, setBaseAssetIds] = useState<any[]>([]);
  const [quoteAssetIds, setQuoteAssetIds] = useState<any[]>([]);
  const [baseAssetOptions, setBaseAssetOptions] = useState<any[]>();
  const [quoteAssetOptions, setQuoteAssetOptions] = useState<any[]>();
  const [venueIdsInTable, setVenueIdsInTable] = useState<any[]>([]);
  const [venueOptions, setVenueOptions] = useState<any[]>();

  const fetchUtils = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
      params: { includeAll: true },
    })
      .then((res) => {
        setVenueList(res.data.list);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/transaction-way",
      withCredentials: true,
    }).then((res) => {
      const filteredTransactionWay = res.data.filter(
        (d: any) =>
          d.label.toLowerCase() === "buy" || d.label.toLowerCase() === "sell",
      );
      setTransactionWayList(filteredTransactionWay);
    });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/",
      withCredentials: true,
    }).then((res) => {
      const options = [];
      for (const asset of res.data.assetList) {
        options.push({
          label: `${asset.name} (${asset.ticker})`,
          value: asset.id,
        });
      }
      setAssetOptions(options);
    });
  }, [navigate]);

  const filterData = (form: any) => {
    const startDate = form.dateRange
      ? form.dateRange[0].format("YYYY-MM-DDTHH:00:00+0100")
      : "";
    const endDate = form.dateRange
      ? form.dateRange[1].format("YYYY-MM-DDTHH:00:00+0100")
      : "";
    setCurrentPage(0);
    fetchData({
      offset: 0,
      pageSize: currPageSize,
      startDate: startDate,
      endDate: endDate,
      venueIds:
        form.venueIds && form.venueIds.length
          ? JSON.stringify(form.venueIds)
          : "",
      baseAssetId: form.baseAssetId ? form.baseAssetId : "",
      quoteAssetId: form.quoteAssetId ? form.quoteAssetId : "",
      transactionWayIds:
        form.transactionWayIds && form.transactionWayIds.length
          ? JSON.stringify(form.transactionWayIds)
          : "",
    });
  };

  const initialColumns = [
    {
      title: "Date Time",
      dataIndex: "timestamp",
      width: 80,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: displayContext?.displayContext.timezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
    {
      title: "Venue",
      dataIndex: "venueName",
      width: 40,
    },
    {
      title: "Symbol Pair",
      dataIndex: "symbolPair",
      width: 60,
    },
    {
      title: "Transaction Way",
      dataIndex: "tranasactionWayName",
      width: 40,
      render: (_: any, { transactionWayName }: any) =>
        transactionWayName ? (
          <Space className={"dcl-" + transactionWayName.toLowerCase()}>
            {transactionWayName}
          </Space>
        ) : (
          ""
        ),
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 60,
      align: "right" as const,
      render: (_: any, { price }: any) =>
        price ? (
          <Space>
            {price.toLocaleString("en-us", {
              minimumFractionDigits: 4,
              maximumFractionDigits: 9,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: 60,
      align: "right" as const,
      render: (_: any, { amount }: any) =>
        amount ? (
          <Space>
            {amount.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 6,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: "Cost",
      dataIndex: "cost",
      width: 80,
      align: "right" as const,
      render: (_: any, { cost }: any) =>
        cost ? (
          <Space>
            {cost.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 9,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
  ];

  const fetchData = useCallback(
    (
      params = {
        offset: 0,
        pageSize: 100,
        startDate: "",
        endDate: "",
        venueIds: "",
        baseAssetId: "",
        quoteAssetId: "",
        transactionWayIds: "",
      },
    ) => {
      setOnFinishLoad(false);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/market-trades",
        withCredentials: true,
        params: {
          offset: params.offset,
          pageSize: params.pageSize,
          startDate: params.startDate ? params.startDate : undefined,
          endDate: params.endDate ? params.endDate : undefined,
          venueIds: params.venueIds ? params.venueIds : "",
          transactionWayIds: params.transactionWayIds
            ? params.transactionWayIds
            : "",
          baseAssetId: params.baseAssetId ? params.baseAssetId : undefined,
          quoteAssetId: params.quoteAssetId ? params.quoteAssetId : undefined,
        },
      })
        .then((res) => {
          if (params.offset === 0) {
            setTradeCount(res.data.count);
            setBaseAssetIds(res.data.baseAssets);
            setQuoteAssetIds(res.data.quoteAssets);
            setVenueIdsInTable(res.data.venueIds);
          }
          setTableData(res.data.trades);
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
    },
    [],
  );

  const onPaginationChange = (current: any, pageSize: any) => {
    const offset =
      current === 1 || current === 0 ? 0 : (current - 1) * pageSize;
    const startDate = filterForm.getFieldValue("dateRange")
      ? filterForm
          .getFieldValue("dateRange")[0]
          .format("YYYY-MM-DDTHH:00:00+0100")
      : undefined;
    const endDate = filterForm.getFieldValue("dateRange")
      ? filterForm
          .getFieldValue("dateRange")[1]
          .format("YYYY-MM-DDTHH:00:00+0100")
      : undefined;
    setCurrentPage(current);
    setCurrPageSize(pageSize);
    fetchData({
      offset: offset,
      pageSize: pageSize,
      startDate: startDate,
      endDate: endDate,
      venueIds:
        filterForm.getFieldValue("venueIds") &&
        filterForm.getFieldValue("venueIds").length
          ? JSON.stringify(filterForm.getFieldValue("venueIds"))
          : "",
      baseAssetId: filterForm.getFieldValue("baseAssetId")
        ? filterForm.getFieldValue("baseAssetId")
        : "",
      quoteAssetId: filterForm.getFieldValue("quoteAssetId")
        ? filterForm.getFieldValue("quoteAssetId")
        : "",
      transactionWayIds:
        filterForm.getFieldValue("transactionWayIds") &&
        filterForm.getFieldValue("transactionWayIds").length
          ? JSON.stringify(filterForm.getFieldValue("transactionWayIds"))
          : "",
    });
  };

  const onReset = useCallback(() => {
    filterForm.resetFields();
    setCurrentPage(0);
    fetchData({
      offset: 0,
      pageSize: currPageSize,
      startDate: DEFAULT_START_DATETIME.format("YYYY-MM-DDTHH:00:00+0100"),
      endDate: DEFAULT_END_DATETIME.format("YYYY-MM-DDTHH:00:00+0100"),
      venueIds:
        filterForm.getFieldValue("venueIds") &&
        filterForm.getFieldValue("venueIds").length
          ? JSON.stringify(filterForm.getFieldValue("venueIds"))
          : "",
      baseAssetId: filterForm.getFieldValue("baseAssetId")
        ? filterForm.getFieldValue("baseAssetId")
        : "",
      quoteAssetId: filterForm.getFieldValue("quoteAssetId")
        ? filterForm.getFieldValue("quoteAssetId")
        : "",
      transactionWayIds:
        filterForm.getFieldValue("transactionWayIds") &&
        filterForm.getFieldValue("transactionWayIds").length
          ? JSON.stringify(filterForm.getFieldValue("transactionWayIds"))
          : "",
    });
  }, [filterForm, fetchData, currPageSize]);

  const onRefresh = useCallback(() => {
    // If no date selected
    if (!filterForm.getFieldValue("dateRange")) {
      onReset();
    }
    // If date not modified
    if (
      filterForm.getFieldValue("dateRange")[0].format() ===
        DEFAULT_START_DATETIME.format() &&
      filterForm.getFieldValue("dateRange")[1].format() ===
        DEFAULT_END_DATETIME.format()
    ) {
      onReset();
    } else {
      const startDate = filterForm
        .getFieldValue("dateRange")[0]
        .format("YYYY-MM-DDTHH:00:00+0100");
      const endDate = filterForm
        .getFieldValue("dateRange")[1]
        .format("YYYY-MM-DDTHH:00:00+0100");
      setCurrentPage(0);
      fetchData({
        offset: 0,
        pageSize: currPageSize,
        startDate: startDate,
        endDate: endDate,
        venueIds:
          filterForm.getFieldValue("venueIds") &&
          filterForm.getFieldValue("venueIds").length
            ? JSON.stringify(filterForm.getFieldValue("venueIds"))
            : "",
        baseAssetId: filterForm.getFieldValue("baseAssetId")
          ? filterForm.getFieldValue("baseAssetId")
          : "",
        quoteAssetId: filterForm.getFieldValue("quoteAssetId")
          ? filterForm.getFieldValue("quoteAssetId")
          : "",
        transactionWayIds:
          filterForm.getFieldValue("transactionWayIds") &&
          filterForm.getFieldValue("transactionWayIds").length
            ? JSON.stringify(filterForm.getFieldValue("transactionWayIds"))
            : "",
      });
    }
  }, [filterForm, currPageSize, fetchData, onReset]);

  const exportCSV = useCallback(() => {
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/market-trades/export-csv",
      withCredentials: true,
      data: {
        startDateString: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[0]
              .format("YYYY-MM-DDTHH:00:00+0100")
          : undefined,
        endDateString: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[1]
              .format("YYYY-MM-DDTHH:00:00+0100")
          : undefined,
        venueIds: filterForm.getFieldValue("venueIds")
          ? filterForm.getFieldValue("venueIds")
          : undefined,
        baseAssetId: filterForm.getFieldValue("baseAssetId")
          ? filterForm.getFieldValue("baseAssetId")
          : undefined,
        quoteAssetId: filterForm.getFieldValue("quoteAssetId")
          ? filterForm.getFieldValue("quoteAssetId")
          : undefined,
        transactionWayIds: filterForm.getFieldValue("transactionWayIds")
          ? filterForm.getFieldValue("transactionWayIds")
          : undefined,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_venue_market_trade.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        } else if (err.response.status === 408) {
          toast.error(err.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  }, [filterForm, navigate]);

  useEffect(() => {
    fetchUtils();
    fetchData({
      offset: 0,
      pageSize: currPageSize,
      startDate: DEFAULT_START_DATETIME.format("YYYY-MM-DDTHH:00:00+0100"),
      endDate: DEFAULT_END_DATETIME.format("YYYY-MM-DDTHH:00:00+0100"),
      venueIds: "",
      baseAssetId: "",
      quoteAssetId: "",
      transactionWayIds: "",
    });
  }, [fetchUtils, fetchData, currPageSize]);

  useEffect(() => {
    setBaseAssetOptions(
      assetOptions?.filter((asset: any) => baseAssetIds.includes(asset.value)),
    );
    setQuoteAssetOptions(
      assetOptions?.filter((asset: any) => quoteAssetIds.includes(asset.value)),
    );
  }, [baseAssetIds, quoteAssetIds, assetOptions]);

  useEffect(() => {
    setVenueOptions(
      venueList?.filter((venue: any) => venueIdsInTable.includes(venue.value)),
    );
  }, [venueList, venueIdsInTable]);

  return (
    <Content id="view-venue-market-trades">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Row className="dcl-filter-row" align="middle">
            <Space>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
                Filters
                {showFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
            </Space>
            <Space>
              <Button className="ant-btn-primary" onClick={onRefresh}>
                Refresh
              </Button>
            </Space>
          </Row>
          <Form
            title="Filter"
            form={filterForm}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
            onFinish={filterData}
            labelWrap
          >
            <Row>
              <Form.Item
                name="dateRange"
                className="dcl-filter-item"
                initialValue={[DEFAULT_START_DATETIME, DEFAULT_END_DATETIME]}
              >
                <RangePicker
                  className="dcl-daterange-select"
                  renderExtraFooter={() => (
                    <b>Datetime selected in timezone CET</b>
                  )}
                  showTime={{ format: "HH:00:00+0100" }}
                  format="YYYY-MM-DD HH:00:00+0100"
                />
              </Form.Item>
              <Form.Item name="venueIds" className="dcl-filter-item">
                <Select
                  className="dcl-venue-select"
                  mode="multiple"
                  allowClear
                  placeholder="Venue"
                  options={venueOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="baseAssetId" className="dcl-filter-item">
                <Select
                  className="dcl-asset-select"
                  allowClear
                  placeholder="Base Asset Ticker"
                  options={baseAssetOptions}
                  popupMatchSelectWidth={false}
                  showSearch
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="quoteAssetId" className="dcl-filter-item">
                <Select
                  className="dcl-asset-select"
                  allowClear
                  placeholder="Quote Asset Ticker"
                  options={quoteAssetOptions}
                  popupMatchSelectWidth={false}
                  showSearch
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="transactionWayIds" className="dcl-filter-item">
                <Select
                  className="dcl-transaction-way-select"
                  mode="multiple"
                  allowClear
                  placeholder="Transaction Way"
                  options={transactionWayList}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                {tradeCount}
                <b>Results</b>
              </Space>
              <Space>
                <Button htmlType="submit" className="ant-btn-primary">
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify="end">
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={exportCSV}
              // disabled
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>
      <Table
        sticky
        loading={!onFinishLoad}
        rowKey="id"
        columns={initialColumns}
        dataSource={tableData}
        size={"small"}
        scroll={{
          x: 1600,
        }}
        // onChange={handleTableChange}
        showSorterTooltip={false}
        pagination={{ pageSize: currPageSize, hideOnSinglePage: true }}
      />
      <Row justify="end" className="pagination-row">
        <Pagination
          showSizeChanger
          showQuickJumper
          pageSizeOptions={[100, 200, 300]}
          onChange={onPaginationChange}
          current={currentPage}
          pageSize={currPageSize}
          total={tradeCount}
        />
      </Row>
    </Content>
  );
}
export default ViewVenueMarketTrades;
