import { Card, Col, Row, Table, Tooltip } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "../../Context/displayContext";
import dayjs from "dayjs";

export default function ViewEbankingBalance({ setBalanceFetchRef }: any) {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const [initalBalanceDate, setInitialBalanceDate] = useState<any>();
  const [balanceData, setBalanceData] = useState<any[]>([]);
  const [lastUpadate, setLastUpdate] = useState<any>();
  const fetchData = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking/delchain-balances",
      withCredentials: true,
    }).then((res) => {
      setLastUpdate(new Date(res.data.updatedAt));
      const data: any[] = [];
      Object.entries(res.data.result).forEach(([key, value]: any) => {
        const filtered = value.filter((t: any) => t.err === undefined);
        data.push({ key: key, value: filtered });
      });
      setBalanceData(data);
      setInitialBalanceDate(res.data.initalDate);
    });
  }, []);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  useEffect(() => {
    setBalanceFetchRef.current = fetchData;
  }, [fetchData, setBalanceFetchRef]);
  const balanceColumns = [
    { title: "Asset", dataIndex: "assetTicker", width: "25%" },
    {
      title: <Tooltip title="Calculated Balance">Calculated Balance</Tooltip>,
      dataIndex: "calculatedBalance",
      ellipsis: true,
      width: "25%",
      align: "right" as const,
      render: (_: any, { calculatedBalance }: any) => (
        <Tooltip title={calculatedBalance}>
          {calculatedBalance
            ? calculatedBalance.toLocaleString("en-us", {
                minimumFractionNumber: 2,
                maximumFractionNumber: 2,
              })
            : "0.00"}
        </Tooltip>
      ),
    },
    {
      title: <Tooltip title="DBT Balance">DBT Balance</Tooltip>,
      dataIndex: "balanceFromDBT",
      ellipsis: true,
      width: "25%",
      align: "right" as const,
      render: (_: any, { balanceFromDBT }: any) => (
        <Tooltip title={balanceFromDBT}>
          {balanceFromDBT
            ? balanceFromDBT.toLocaleString("en-us", {
                minimumFractionNumber: 2,
                maximumFractionNumber: 2,
              })
            : "0.00"}
        </Tooltip>
      ),
    },
    {
      title: <Tooltip title="# Transactions"># Transactions</Tooltip>,
      ellipsis: true,
      width: "25%",
      dataIndex: "txnCount",
      align: "right" as const,
      render: (_: any, { txnCount }: any) => (txnCount ? txnCount : 0),
    },
  ];
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  return (
    <Content id="view-ebanking-balance">
      <Row>
        {balanceData.map((row) => {
          return (
            <Col xxl={12} xl={12} lg={12} md={24} key={row.key}>
              <Card
                title={<b>{row.key}</b>}
                key={row.key}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
                styles={{
                  body: {
                    minHeight: "300px",
                  },
                }}
                extra={
                  <Tooltip
                    className="card-extra-tooltip"
                    overlayInnerStyle={{
                      width: "340px",
                    }}
                    placement={"topLeft"}
                    title={
                      <React.Fragment>
                        <Row>
                          Last Upadated Date: &nbsp;
                          {lastUpadate
                            ? lastUpadate.toLocaleString("en-us", {
                                timeZone: displayTimezone,
                                timeZoneName: "short",
                              })
                            : ""}
                        </Row>
                        <Row>
                          Initial Balance Date: &nbsp;
                          {initalBalanceDate && initalBalanceDate[row.key]
                            ? dayjs(initalBalanceDate[row.key])
                                .tz("America/New_York", true)
                                .toDate()
                                .toLocaleString("en-us", {
                                  timeZone: displayTimezone,
                                  timeZoneName: "short",
                                })
                            : dayjs()
                                .tz("America/New_York", true)
                                .startOf("year")
                                .toDate()
                                .toLocaleString("en-us", {
                                  timeZone: displayTimezone,
                                  timeZoneName: "short",
                                })}
                        </Row>
                      </React.Fragment>
                    }
                  >
                    {lastUpadate
                      ? lastUpadate.toLocaleString("en-us", {
                          timeZone: displayTimezone,
                          timeZoneName: "short",
                        })
                      : ""}
                  </Tooltip>
                }
              >
                <Table
                  columns={balanceColumns}
                  dataSource={row.value}
                  rowKey="assetTicker"
                  rowClassName={(record, _) => {
                    return record.isValueMatch
                      ? "ebanking-table-value-match"
                      : "ebanking-table-value-not-match";
                  }}
                  scroll={{ x: 600 }}
                  pagination={{ pageSize: 10, hideOnSinglePage: true }}
                />
              </Card>
            </Col>
          );
        })}
      </Row>
    </Content>
  );
}
