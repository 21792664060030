import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function ViewTechLogs() {
  const [filterForm] = Form.useForm();
  const navigate = useNavigate();
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [logsData, setLogsData] = useState<any[]>();
  const [logActionOptions, setLogActionOptions] = useState<any[]>();
  const [currentPage, setCurrentPage] = useState(0);
  const [currPageSize, setCurrPageSize] = useState(10);
  const [logsCount, setLogsCount] = useState<number>();

  const fetchData = useCallback(
    (params = { offset: 0, pageSize: 10 }) => {
      setOnFinishLoad(false);
      axios({
        method: "GET",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/logs",
        params: {
          offset: params.offset,
          pageSize: params.pageSize,
          targetUserId: filterForm
            ? filterForm.getFieldValue("targetUserId")
            : undefined,
          targetUserFirstName: filterForm
            ? filterForm.getFieldValue("targetUserFirstName")
            : undefined,
          targetUserLastName: filterForm
            ? filterForm.getFieldValue("targetUserLastName")
            : undefined,
          actions: filterForm
            ? filterForm.getFieldValue("logAction")
            : undefined,
        },
        withCredentials: true,
      })
        .then((res) => {
          if (params.offset === 0) {
            setLogsCount(res.data.totalLogs);
            setLogActionOptions(res.data.actions);
          }
          setLogsData(res.data.logs);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
    },
    [navigate, filterForm],
  );

  const filterLogs = (form: any) => {
    setOnFinishLoad(false);
    fetchData({ offset: 0, pageSize: currPageSize });
    setCurrentPage(0);
  };

  const onPaginationChange = (current: any, pageSize: any) => {
    setOnFinishLoad(false);
    const offset =
      current === 1 || current === 0 ? 0 : (current - 1) * pageSize;
    setCurrentPage(current);
    setCurrPageSize(pageSize);
    fetchData({ offset: offset, pageSize: pageSize });
  };

  const onReset = () => {
    filterForm.resetFields();
    fetchData();
    setCurrentPage(0);
  };

  useEffect(() => {
    fetchData({ offset: 0, pageSize: 10 });
    setCurrentPage(0);
  }, [fetchData]);

  const initialColumns = [
    {
      title: "Id",
      dataIndex: "id",
      hidden: false,
      sorter: false,
      editable: false,
      width: 40,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      hidden: false,
      sorter: false,
      editable: false,
      width: 150,
    },
    {
      title: "User Id",
      dataIndex: "userId",
      hidden: false,
      sorter: false,
      editable: false,
      width: 40,
    },
    {
      title: "Target User Id",
      dataIndex: "targetUserId",
      hidden: false,
      sorter: false,
      editable: false,
      width: 60,
    },

    {
      title: "Target User First Name",
      dataIndex: "targetUserFirstName",
      hidden: false,
      sorter: false,
      editable: false,
      width: 100,
    },
    {
      title: "Target User Last Name",
      dataIndex: "targetUserLastName",
      hidden: false,
      sorter: false,
      editable: false,
      width: 100,
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      hidden: false,
      sorter: false,
      editable: false,
      width: 120,
    },
    {
      title: "Action",
      dataIndex: "logAction",
      hidden: false,
      sorter: false,
      editable: false,
      width: 200,
    },
    {
      title: "JSON",
      dataIndex: "jsonText",
      hidden: false,
      sorter: false,
      editable: false,
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (jsonText: string) => (
        <Tooltip placement="topLeft" title={jsonText}>
          {jsonText}
        </Tooltip>
      ),
    },
  ];
  return onFinshiLoad ? (
    <Content>
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Space>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
              Filters
              {showFilter ? (
                <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
              ) : (
                <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
              )}
            </Button>
          </Space>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={filterLogs}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
            labelWrap
          >
            <Row>
              <Form.Item name="targetUserId" className="dcl-filter-item">
                <Input placeholder="Target User Id" />
              </Form.Item>
              <Form.Item name="targetUserFirstName" className="dcl-filter-item">
                <Input placeholder="Target User First Name" />
              </Form.Item>
              <Form.Item name="targetUserLastName" className="dcl-filter-item">
                <Input placeholder="Target User Last Name" />
              </Form.Item>
              <Form.Item name="logAction" className="dcl-filter-item">
                <Select
                  className="dcl-log-action-select"
                  placeholder="Log Action"
                  mode="multiple"
                  allowClear
                  options={logActionOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      ?.toLowerCase()
                      ?.includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                <Button htmlType="submit" className="ant-btn-primary">
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>
      <Table
        columns={initialColumns}
        sticky
        scroll={{ x: 1600 }}
        dataSource={logsData}
        pagination={{ pageSize: currPageSize, hideOnSinglePage: true }}
      />
      <Row justify="end" className="pagination-row">
        <Pagination
          showSizeChanger
          onChange={onPaginationChange}
          current={currentPage}
          pageSize={currPageSize}
          pageSizeOptions={[10, 50, 100]}
          total={logsCount}
        />
      </Row>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
export default ViewTechLogs;
