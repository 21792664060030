import React, { forwardRef, HTMLAttributes, useState } from "react";
import classNames from "classnames";

import { Action } from "./Action";
import { Handle } from "./Handle";
import styles from "./TreeItem.module.css";
import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";

export interface Props extends HTMLAttributes<HTMLLIElement> {
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  value: string;
  onCollapse?(): void;
  onRemove?(): void;
  wrapperRef?(node: HTMLLIElement): void;
  updateMenuFields?: any;
  label: string;
  pageTitle?: string;
  description?: string;
  iconName?: string;
  capablityList?: any;
  capabilityId?: number;
  isActive?: boolean;
  link?: string;
  helper?: string;
  openDeleteConfirmModal?: any;
}
export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      style,
      value,
      label,
      wrapperRef,
      updateMenuFields,
      capablityList,
      openDeleteConfirmModal,
      pageTitle,
      description,
      iconName,
      capabilityId,
      isActive,
      link,
      helper,
      ...props
    },
    ref,
  ) => {
    const [showDetail, setShowDetail] = useState<Boolean>(false);
    const propsListLeft = [
      { key: "pageTitle", title: "Title", value: pageTitle },
      { key: "description", title: "Description", value: description },
      { key: "iconName", title: "Icon Name", value: iconName },
      { key: "link", title: "Link", value: link },
    ];
    return (
      <li
        className={classNames(
          styles.Wrapper,
          clone && styles.clone,
          ghost && styles.ghost,
          indicator && styles.indicator,
          disableSelection && styles.disableSelection,
          disableInteraction && styles.disableInteraction,
        )}
        ref={wrapperRef}
        style={
          {
            "--spacing": `${indentationWidth * depth}px`,
          } as React.CSSProperties
        }
        {...props}
      >
        <Row className={styles.TreeItem} ref={ref} style={style}>
          <Handle {...handleProps} />
          {onCollapse && (
            <Action
              onClick={onCollapse}
              className={classNames(
                styles.Collapse,
                collapsed && styles.collapsed,
              )}
            >
              {collapsed ? <ArrowRightOutlined /> : <ArrowDownOutlined />}
            </Action>
          )}

          {clone ? (
            <span className={styles.Text}>{label}</span>
          ) : (
            <span className={styles.Text}>
              <Input
                style={{ width: "50%" }}
                defaultValue={label}
                onChange={(e: any) => {
                  updateMenuFields(value, "label", e.target.value);
                }}
              />

              <Button
                style={{
                  marginLeft: "1em",
                  border: "none",
                  background: "none",
                  boxShadow: "none",
                }}
                size="small"
                onClick={() => {
                  setShowDetail(!showDetail);
                }}
              >
                {showDetail ? <MinusOutlined /> : <PlusOutlined />}
              </Button>

              <Checkbox
                style={{ marginLeft: "2em" }}
                defaultChecked={isActive}
                onChange={(e: any) => {
                  updateMenuFields(value, "isActive", e.target.checked);
                }}
              >
                Displayed in menu
              </Checkbox>
            </span>
          )}

          {!clone ? (
            <Button
              onClick={() => {
                openDeleteConfirmModal(value);
              }}
              style={{ border: "none", background: "none", boxShadow: "none" }}
            >
              <CloseOutlined />
            </Button>
          ) : (
            <></>
          )}
          {clone && childCount && childCount > 1 ? (
            <span className={styles.Count}>{childCount}</span>
          ) : null}
        </Row>
        {showDetail ? (
          <Row justify={"space-evenly"} className={styles.Detail}>
            <Col span={10}>
              {propsListLeft.map((row) => (
                <Row className="expanded-menu-item-row" key={row.key}>
                  <b>{row.title}: </b>
                  <Input
                    placeholder={row.title}
                    defaultValue={row.value}
                    onChange={(e: any) => {
                      updateMenuFields(value, row.key, e.target.value);
                    }}
                  />
                </Row>
              ))}
            </Col>
            <Col span={10}>
              <Row className="expanded-menu-item-row">
                <b>Capability: </b>
                <Select
                  style={{ width: "100%" }}
                  dropdownStyle={{ zIndex: 9999 }}
                  options={capablityList}
                  defaultValue={capabilityId}
                  showSearch
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  popupMatchSelectWidth={false}
                  onChange={(e: any) => {
                    updateMenuFields(value, "capabilityId", e);
                  }}
                />
              </Row>
              <Row className="expanded-menu-item-row">
                <b>Helper: </b>
                <TextArea
                  defaultValue={helper}
                  onChange={(e: any) => {
                    updateMenuFields(value, "helper", e.target.value);
                  }}
                />
              </Row>
            </Col>
          </Row>
        ) : (
          <></>
        )}
      </li>
    );
  },
);
