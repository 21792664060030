import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Divider,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { Buffer } from "buffer";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";
import ViewOperationDetail from "./ViewOperationDetail";

const { RangePicker } = DatePicker;

export default function OperationReport() {
  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [selectedOperationId, setSelectedOperationId] = useState<number>();
  const [transactionWayList, setTransactionWayList] = useState<any[]>([]);
  const [reportExist, setReportExist] = useState<boolean>(false);
  const [isSavingReport, setIsSavingReport] = useState<boolean>(false);
  const [showOverwritePopover, setShowOverwritePopover] =
    useState<boolean>(false);
  const getTransactionWayList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/transaction-way",
      params: { type: "trade" }, // Buy and Sell Specific
      withCredentials: true,
    }).then((res) => {
      setTransactionWayList(res.data);
    });
  }, []);
  useEffect(() => {
    getTransactionWayList();
  }, [getTransactionWayList]);
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  const operationDetailColumns = [
    {
      title: "Date",
      dataIndex: "referenceTimestamp",
      key: "referenceTimestamp",
      width: "110px",
      render: (_: any, { referenceTimestamp }: any) =>
        dayjs(referenceTimestamp).format("DD MMM YYYY"),
    },
    {
      title: "CCY",
      dataIndex: "ticker",
      key: "ticker",
      width: "80px",
    },
    {
      title: "In",
      dataIndex: "referenceAmount",
      key: "in",
      align: "right" as const,
      render: (_: any, { referenceAmount }: any, index: number) =>
        referenceAmount > 0
          ? `${
              referenceAmount
                ? referenceAmount.toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  })
                : index === 0
                ? ""
                : "0.00"
            }`
          : "",
      width: "150px",
    },
    {
      title: "Out",
      dataIndex: "referenceAmount",
      key: "out",
      align: "right" as const,
      render: (_: any, { referenceAmount }: any, index: number) =>
        referenceAmount < 0
          ? `${
              referenceAmount
                ? Math.abs(referenceAmount).toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 8,
                  })
                : index === 0
                ? ""
                : "0.00"
            }`
          : "",
      width: "150px",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      align: "right" as const,
      render: (_: any, { balance, ticker }: any, index: number) =>
        Number(index) > 0
          ? `${balance.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}`
          : (initialAmount?.[ticker] || 0)?.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            }),
      width: "150px",
    },
    {
      title: "Type",
      dataIndex: "operationItemType",
      key: "typeLabel",
      width: "200px",
    },
    {
      title: "Remarks",
      dataIndex: "opDescription",
      key: "opDescription",
      width: "300px",
      render: (_: any, { opDescription }: any, index: number) =>
        index === 0 ? <b>{opDescription}</b> : opDescription,
    },
    {
      title: "Txid",
      dataIndex: "referenceRealId",
      key: "referenceRealId",
      width: "200px",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: "200px",
    },
    {
      title: "Trade ID",
      dataIndex: "opLabel",
      key: "opLabel",
      width: "90px",
      render: (_: any, { opLabel, opId }: any) =>
        opId ? (
          <Row align={"middle"} key={opId}>
            {opLabel}
            <Tooltip title="Detail">
              <Button
                onClick={() => {
                  setShowDetailModal(true);
                  setSelectedOperationId(opId);
                }}
                style={{
                  border: "none",
                  background: "none",
                  boxShadow: "none",
                  padding: "5px",
                }}
              >
                <FontAwesomeIcon icon={"fa-regular fa-eye" as IconProp} />
              </Button>
            </Tooltip>
          </Row>
        ) : undefined,
    },
  ];
  const tradeActivitiesColumns = [
    {
      title: "Trade Date",
      dataIndex: "tradeDate",
      key: "tradeDate",
      width: "110px",
      render: (_: any, { tradeDate }: any) =>
        dayjs(tradeDate).format("MMM/DD/YYYY"),
    },
    {
      title: "Trade ID",
      dataIndex: "tradeId",
      key: "tradeId",
      width: "100px",
    },
    {
      title: "Client DBT #",
      dataIndex: "clientDBT",
      key: "clientDBT",
      width: "100px",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      width: "150px",
    },
    {
      title: "Trade Symbol",
      dataIndex: "symbol",
      key: "symbol",
      width: "100px",
    },
    {
      title: "Base Asset",
      dataIndex: "baseAsset",
      key: "baseAsset",
      width: "100px",
    },
    {
      title: "Quote Asset",
      dataIndex: "quoteAsset",
      key: "quoteAsset",
      width: "100px",
    },
    {
      title: "Base Amount",
      dataIndex: "baseAmount",
      key: "baseAmount",
      width: "150px",
      align: "right" as const,
      render: (_: any, { baseAmount }: any) =>
        baseAmount ? (
          <Row
            style={{
              color: baseAmount > 0 ? "black" : "red",
            }}
            justify={"end"}
          >
            {baseAmount > 0
              ? baseAmount.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                })
              : `(${Math.abs(baseAmount).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                })})`}
          </Row>
        ) : (
          <Row justify={"end"}>0.00</Row>
        ),
    },
    {
      title: "Quote Amount",
      dataIndex: "quoteAmount",
      key: "quoteAmount",
      width: "150px",
      align: "right" as const,
      render: (_: any, { quoteAmount }: any) =>
        quoteAmount ? (
          <Row
            style={{
              color: quoteAmount > 0 ? "black" : "red",
            }}
            justify={"end"}
          >
            {quoteAmount > 0
              ? quoteAmount.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                })
              : `(${Math.abs(quoteAmount).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 8,
                })})`}
          </Row>
        ) : (
          <Row justify={"end"}>0.00</Row>
        ),
    },
    {
      title: "Trade Price",
      dataIndex: "tradePrice",
      key: "tradePrice",
      width: "100px",
      render: (_: any, { tradePrice }: any) =>
        tradePrice?.toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }),
    },
  ];
  const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
    // Can not select days after today and today
    return current > dayjs().endOf("d");
  };

  const [selectedClient, setSelectClient] = useState<any>();
  const [confirmedClient, setConfirmedClient] = useState<any>();
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const [clientOptions, setClientOptions] = useState<any[]>();
  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);
  useEffect(() => {
    getClientOptions();
  }, [getClientOptions]);
  const [isPrepopulatingReport, setIsPrepopulatingReport] =
    useState<boolean>(false);
  const [datePeriod, setDatePeriod] = useState<any>();
  const [confirmedDatePeriod, setConfirmedSelectedDatePeriod] = useState<any>();

  const [initialAmount, setInitialAmount] = useState<any>();
  const [finalAmount, setFinalAmount] = useState<any>();
  const [inflowAmounts, setInflowAmounts] = useState<any>();
  const [outflowAmounts, setOutflowAmounts] = useState<any>();
  const [tradeAcitvities, setTradeActivities] = useState<any>();
  const [walletAddressMap, setWalletAddressMap] = useState<any>();
  const [assetTabs, setAssetTabs] = useState<any>([]);
  const [clientName, setClientName] = useState<string>("");
  const [operationItemDetails, setOperationItemDetails] = useState<any[]>([]);
  const [reportParam, setReportParam] = useState<{
    startDate: string;
    endDate: string;
    clientId: string;
  }>({
    startDate: "",
    endDate: "",
    clientId: "",
  });
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false);
  const generateReport = useCallback(() => {
    setIsGeneratingReport(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/report/pdf-report-generation",
      withCredentials: true,
      data: {
        startDate: reportParam.startDate,
        endDate: reportParam.endDate,
        clientId: reportParam.clientId,
      },
    })
      .then((res) => {
        const buffer = Buffer.from(res.data);
        const blob = new Blob([buffer]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${clientName}_${reportParam.startDate}_${reportParam.endDate}_client_trading_report.pdf`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsGeneratingReport(false);
      });
  }, [navigate, clientName, reportParam]);
  const saveReportOnS3 = useCallback(() => {
    setIsSavingReport(true);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/report/s3",
      withCredentials: true,
      data: {
        startDate: reportParam.startDate,
        endDate: reportParam.endDate,
        clientId: reportParam.clientId,
        overwrite: true,
      },
    })
      .then((res) => {
        toast.success("Report stored successfully");
        setReportExist(true);
      })
      .catch((err) => {
        toast.error(
          "Faile to save report, ensure client has a delchain account",
        );
      })
      .finally(() => {
        setIsSavingReport(false);
      });
  }, [reportParam.clientId, reportParam.endDate, reportParam.startDate]);

  const [checkingExist, setCheckingExist] = useState<boolean>(false);
  const [reportCreationDate, setReportCreationDate] = useState<
    Date | undefined
  >(undefined);
  const checkReportExist = useCallback(() => {
    setReportExist(false);
    if (!datePeriod || !selectedClient) return;
    setCheckingExist(true);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/report/s3",
      withCredentials: true,
      params: {
        startDate: dayjs
          .utc(datePeriod[0].format("YYYY-MM-DD"))
          .startOf("d")
          .format("YYYY-MM-DD"),
        endDate: dayjs
          .utc(datePeriod[1].format("YYYY-MM-DD"))
          .endOf("d")
          .format("YYYY-MM-DD"),
        clientId: selectedClient,
      },
    })
      .then((res) => {
        setReportExist(res.data.exist);
        setReportCreationDate(new Date(res.data.reportDateCreationDate));
      })
      .catch((err) => {
        setReportExist(false);
        setReportCreationDate(undefined);
      })
      .finally(() => {
        setCheckingExist(false);
      });
  }, [datePeriod, selectedClient]);
  useEffect(() => {
    checkReportExist();
  }, [checkReportExist]);

  const prepopulateReport = useCallback(
    (overwrite: boolean) => {
      setIsPrepopulatingReport(true);
      setDataLoaded(false);
      setInitialAmount(undefined);
      setFinalAmount(undefined);
      setInflowAmounts(undefined);
      setOutflowAmounts(undefined);
      setWalletAddressMap(undefined);
      setConfirmedClient(undefined);
      setTradeActivities(undefined);
      setConfirmedSelectedDatePeriod(undefined);

      setConfirmedSelectedDatePeriod(datePeriod);
      setConfirmedClient(selectedClient);
      setReportParam({
        startDate: dayjs
          .utc(datePeriod[0].format("YYYY-MM-DD"))
          .startOf("d")
          .format("YYYY-MM-DD"),
        endDate: dayjs
          .utc(datePeriod[1].format("YYYY-MM-DD"))
          .endOf("d")
          .format("YYYY-MM-DD"),
        clientId: selectedClient,
      });

      axios({
        method: "Get",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/operations/report/pre-populate",
        withCredentials: true,
        params: {
          startDate: dayjs
            .utc(datePeriod[0].format("YYYY-MM-DD"))
            .startOf("d")
            .toDate(),
          endDate: dayjs
            .utc(datePeriod[1].format("YYYYMMDD"))
            .endOf("d")
            .toDate(),
          clientId: selectedClient,
          overwrite: overwrite,
        },
      })
        .then((res) => {
          setInitialAmount(res.data.initialAmount);
          setFinalAmount(res.data.finalAmount);
          setAssetTabs(Object.keys(res.data.assetMap));
          setOperationItemDetails(res.data.assetMap);
          setInflowAmounts(res.data.inflowMap);
          setOutflowAmounts(res.data.outflowMap);
          setWalletAddressMap(res.data.walletAddressMap);
          setClientName(res.data.clientName);
          setTradeActivities(res.data.tradeActivities);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setIsPrepopulatingReport(false);
          setDataLoaded(true);
        });
    },
    [navigate, datePeriod, selectedClient],
  );

  const resetSelection = useCallback(() => {
    setSelectClient(undefined);
    setDatePeriod(undefined);
    setReportExist(false);
    setReportParam({
      startDate: "",
      endDate: "",
      clientId: "",
    });
  }, []);

  return (
    <Content id="operation-report">
      <Row
        style={{
          margin: "1em 1em",
        }}
      >
        <Col
          xl={{ span: 5 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <RangePicker
            className="dcl-daterange-select"
            value={datePeriod}
            disabledDate={disabledDate}
            onChange={(e) => {
              setDatePeriod(e);
            }}
          />
        </Col>
        <Col
          xl={{ span: 4, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Select
            placeholder={"Client"}
            options={clientOptions}
            onSelect={(e) => {
              setSelectClient(e);
            }}
            value={selectedClient}
            showSearch
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
            filterOption={(input, option: any) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Col>
      </Row>
      {reportExist ? (
        <Row
          justify={"end"}
          align={"middle"}
          style={{
            marginRight: "1em",
          }}
        >
          <b>Existing report saved on: </b> &nbsp;
          {reportCreationDate?.toLocaleString("en-US", {
            timeZone: displayTimezone,
            timeZoneName: "short",
          })}
        </Row>
      ) : undefined}
      <Row justify={"end"} align={"middle"}>
        <Button loading={isPrepopulatingReport} onClick={resetSelection}>
          Reset
        </Button>
        {checkingExist ? (
          <Spin />
        ) : (
          <Button
            type="primary"
            onClick={() => prepopulateReport(false)}
            disabled={!selectedClient || !datePeriod}
            loading={isPrepopulatingReport}
          >
            {reportExist ? "Display existing report" : "Generate report"}
          </Button>
        )}
        {reportExist ? (
          <Button
            type="primary"
            onClick={() => setShowOverwritePopover(true)}
            disabled={isGeneratingReport}
          >
            Re-Generate Report
          </Button>
        ) : undefined}
      </Row>
      )
      {isPrepopulatingReport ? (
        <Row justify={"center"}>
          <Spin size="large" />
        </Row>
      ) : dataLoaded ? (
        <Row
          style={{
            margin: "1em 1em",
          }}
        >
          <Divider orientation="center">
            <h3 style={{ color: "black" }}>
              {
                clientOptions?.find((row) => row.value === confirmedClient)
                  ?.label
              }
            </h3>
          </Divider>

          <Col span={24}>
            <Row justify={"end"}>
              {!reportExist ? (
                <Button
                  onClick={() => saveReportOnS3()}
                  type="primary"
                  loading={isSavingReport}
                >
                  <FontAwesomeIcon
                    icon={"fa-solid fa-floppy-disk" as IconProp}
                    style={{ marginRight: "10px" }}
                  />
                  Save Report
                </Button>
              ) : undefined}
              {reportExist ? (
                <React.Fragment>
                  <Button
                    onClick={() => generateReport()}
                    type="primary"
                    loading={isGeneratingReport}
                  >
                    <FontAwesomeIcon
                      icon={"fa-solid fa-file-pdf" as IconProp}
                      style={{ marginRight: "10px" }}
                    />
                    Download Report
                  </Button>
                </React.Fragment>
              ) : undefined}
            </Row>
            <Tabs
              items={[
                {
                  label: "Trade Activity",
                  key: "trade-activity",
                  children: (
                    <React.Fragment>
                      <Table
                        sticky
                        scroll={{
                          x: "max-content",
                        }}
                        dataSource={tradeAcitvities}
                        columns={tradeActivitiesColumns}
                      />
                    </React.Fragment>
                  ),
                },
                {
                  label: "Currency Movements",
                  key: "currency-movement",
                  children: (
                    <React.Fragment>
                      <Tabs
                        items={[
                          ...assetTabs.map((row: any) => {
                            return {
                              label: row,
                              key: `currency-mvmt-${row}`,
                              children: (
                                <Table
                                  sticky
                                  scroll={{
                                    x: "max-content",
                                  }}
                                  dataSource={[
                                    {
                                      key: "initialAmount",
                                      referenceAmount: 0,
                                      referenceRealId: "",
                                      referenceTimestamp:
                                        confirmedDatePeriod?.[0].format(),
                                      ticker: row,
                                      opDescription: "Opening Balance",
                                    },
                                    ...operationItemDetails?.[row],
                                  ]}
                                  columns={operationDetailColumns}
                                  summary={() => {
                                    return (
                                      <Table.Summary key={`${row}-summary`}>
                                        <Table.Summary.Row>
                                          <Table.Summary.Cell
                                            index={3}
                                            colSpan={1}
                                          >
                                            {dayjs(
                                              confirmedDatePeriod?.[1].format(),
                                            )?.format("DD MMM YYYY")}
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell
                                            index={2}
                                            colSpan={1}
                                          >
                                            {row}
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell
                                            index={3}
                                            colSpan={1}
                                          >
                                            {/* {dayjs(
                                              confirmedDatePeriod?.[1].format(),
                                            )?.format("DD MMM YYYY")} */}
                                          </Table.Summary.Cell>

                                          <Table.Summary.Cell
                                            index={4}
                                            colSpan={1}
                                          />
                                          <Table.Summary.Cell
                                            index={5}
                                            align="right"
                                          >
                                            {finalAmount?.[row]
                                              ? finalAmount?.[
                                                  row
                                                ].toLocaleString("en-us", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 8,
                                                })
                                              : "0.00"}
                                          </Table.Summary.Cell>
                                          <Table.Summary.Cell
                                            index={6}
                                            colSpan={1}
                                          />
                                          <Table.Summary.Cell
                                            index={7}
                                            colSpan={1}
                                          >
                                            <b>Ending Balance</b>
                                          </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                      </Table.Summary>
                                    );
                                  }}
                                />
                              ),
                            };
                          }),
                        ]}
                      />
                    </React.Fragment>
                  ),
                },
                {
                  label: "Currency Balance Summary",
                  key: "currency-balance-summary",
                  children: (
                    <React.Fragment>
                      <Tabs
                        items={[
                          ...assetTabs.map((row: any) => {
                            return {
                              label: row,
                              key: `currency-balance-summary-${row}`,
                              children: (
                                <Table
                                  key={`currency-balance-summary-table-${row}`}
                                  columns={[
                                    {
                                      title: "Title",
                                      dataIndex: "operationItemType",
                                      key: "typeLabel",
                                      width: "200px",
                                    },
                                    {
                                      title: "Amount",
                                      dataIndex: "amount",
                                      key: "amount",
                                      align: "right",
                                      width: "200px",
                                      render: (_: any, { amount }: any) =>
                                        amount ? (
                                          <b
                                            style={{
                                              color:
                                                amount > 0 ? "black" : "red",
                                            }}
                                          >
                                            {amount > 0
                                              ? amount.toLocaleString("en-us", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 8,
                                                })
                                              : `(${Math.abs(
                                                  amount,
                                                ).toLocaleString("en-us", {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 8,
                                                })})`}
                                          </b>
                                        ) : (
                                          <b>0.00</b>
                                        ),
                                    },
                                  ]}
                                  dataSource={[
                                    {
                                      key: "initialAmount",
                                      operationItemType:
                                        "Balance as of 1st date of report",
                                      amount: initialAmount?.[row],
                                      ticker: row,
                                    },
                                    {
                                      key: "inflow",
                                      operationItemType: `Add: ${row} Inflow (Deposits and Purchases)`,
                                      amount: inflowAmounts?.[row],
                                      ticker: row,
                                    },
                                    {
                                      key: "outflow",
                                      operationItemType: `Less: ${row} Outflow (Withdrawals and Sales)`,
                                      amount: outflowAmounts?.[row],
                                      ticker: row,
                                    },
                                    {
                                      key: "walletBalance",
                                      operationItemType: `Balance in custody wallet`,
                                      amount: walletAddressMap?.[row]?.length
                                        ? walletAddressMap?.[row]?.reduce(
                                            (
                                              accumulator: number,
                                              currentValue: any,
                                            ) =>
                                              accumulator + currentValue.amount,
                                            0,
                                          )
                                        : 0,
                                      ticker: row,
                                    },
                                    {
                                      key: "final",
                                      operationItemType: `Balance as of end date of report`,
                                      amount:
                                        finalAmount?.[row] +
                                        (walletAddressMap?.[row]?.length
                                          ? walletAddressMap?.[row]?.reduce(
                                              (
                                                accumulator: number,
                                                currentValue: any,
                                              ) =>
                                                accumulator +
                                                currentValue.amount,
                                              0,
                                            )
                                          : 0),
                                      ticker: row,
                                    },
                                  ]}
                                  summary={() => {
                                    return walletAddressMap?.[row]?.length ? (
                                      <Table.Summary>
                                        <Table.Summary.Row>
                                          <Table.Summary.Cell
                                            index={1}
                                            colSpan={1}
                                          >
                                            <Row>
                                              <Col span={10}>
                                                Custody wallet address:
                                              </Col>
                                              <Col span={14}>
                                                {walletAddressMap?.[row]?.map(
                                                  (t: any, idx: number) => (
                                                    <Row
                                                      key={`${t.address}-${idx}`}
                                                    >
                                                      {t.address}
                                                    </Row>
                                                  ),
                                                )}
                                              </Col>
                                            </Row>
                                          </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                      </Table.Summary>
                                    ) : (
                                      <></>
                                    );
                                  }}
                                />
                              ),
                            };
                          }),
                        ]}
                      />
                    </React.Fragment>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Modal
        style={{
          minWidth: "70%",
        }}
        open={showDetailModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setSelectedOperationId(undefined);
          setShowDetailModal(false);
        }}
      >
        <ViewOperationDetail
          operationId={selectedOperationId}
          transactionWayList={transactionWayList}
        />
      </Modal>
      <Modal
        style={{
          minWidth: "70%",
        }}
        open={showOverwritePopover}
        closable={true}
        onCancel={() => {
          setShowOverwritePopover(false);
        }}
        onClose={() => {
          setShowOverwritePopover(false);
        }}
        onOk={() => {
          prepopulateReport(true);
          setReportExist(false);
          setShowOverwritePopover(false);
        }}
      >
        <Alert
          type="warning"
          message={
            <React.Fragment>
              <p style={{ color: "black" }}>
                This will overwrite the existing report with current data.
              </p>
              <p style={{ color: "black" }}>
                <b>Are you sure?</b>
              </p>
            </React.Fragment>
          }
        />
      </Modal>
    </Content>
  );
}
