import { Button, ConfigProvider, Form, Input, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import dayjs from "dayjs";
import { useCallback, useState } from "react";

export default function CreateXTradeModal({
  setShowCreateXTradeModal,
  fetchXTradeList,
}: any) {
  const [newXTradeId, setNewXTradeId] = useState<string>(
    `${dayjs().format("YYYY")}-M`,
  );
  const [newXTradeLabel, setNewXTradeLabel] = useState<string>("");
  const [newXTradeDescription, setNewXTradeDescription] = useState<string>("");

  const [xTradeCreationLoading, setXTradeCreationLoading] =
    useState<boolean>(false);
  const resetNewXTradeFields = useCallback(() => {
    setNewXTradeId(`${dayjs().format("YYYY")}-M`);
    setNewXTradeLabel("");
    setNewXTradeDescription("");
  }, []);

  const createXTrade = useCallback(() => {
    setXTradeCreationLoading(true);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/x-trade",
      withCredentials: true,
      data: {
        label: newXTradeLabel,
        description: newXTradeDescription,
        xTradeId: newXTradeId,
      },
    })
      .then(() => {
        setXTradeCreationLoading(false);
        resetNewXTradeFields();
        setShowCreateXTradeModal(false);
      })
      .finally(() => {
        fetchXTradeList();
      });
  }, [
    fetchXTradeList,
    newXTradeLabel,
    newXTradeId,
    setShowCreateXTradeModal,
    newXTradeDescription,
    resetNewXTradeFields,
  ]);
  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            labelColor: "white",
            labelFontSize: 16,
          },
        },
      }}
    >
      <Row justify={"center"}>
        <h2 style={{ color: "white" }}>Cross-Trade</h2>
      </Row>
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{
          marginTop: "40px",
        }}
      >
        <Form.Item label={<b>X-Trade Id</b>} required>
          <Input
            value={newXTradeId}
            onChange={(e) => {
              setNewXTradeId(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label={<b>Label</b>} required>
          <Input
            value={newXTradeLabel}
            onChange={(e) => {
              setNewXTradeLabel(e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label={<b>Description</b>}>
          <TextArea
            rows={3}
            value={newXTradeDescription}
            onChange={(e) => {
              setNewXTradeDescription(e.target.value);
            }}
          />
        </Form.Item>
        <Row justify={"center"}>
          <Button
            onClick={() => {
              setShowCreateXTradeModal(false);
              resetNewXTradeFields();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              createXTrade();
            }}
            disabled={!newXTradeLabel || !newXTradeId}
            loading={xTradeCreationLoading}
          >
            Create
          </Button>
        </Row>
      </Form>
    </ConfigProvider>
  );
}
