import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Content } from "antd/lib/layout/layout";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";
import axios from "axios";
import { DisplayContext } from "../../../Context/displayContext";
const { RangePicker } = DatePicker;

export default function ViewVenueHistoricalMarketBasis() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const [selectedDateRange, setSelectedDateRange] = useState<any>();
  const [fetchedDateRange, setFetchedDateRange] = useState<any>();
  const [fetchingData, setFetchingData] = useState<boolean>(false);
  const [dataCount, setDataCount] = useState<number>();
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [downloadingCSV, setDownloadingCSV] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>();
  const [canExport, setCanExport] = useState<boolean>(false);

  const singleCollateralColumns = useMemo(
    () => [
      {
        title: (
          <Row justify={"center"}>
            <h3 style={{ color: "black" }}>
              Future - Month (Single Collateral)
            </h3>
          </Row>
        ),
        children: [
          {
            title: "Symbol",
            dataIndex: "monthSymbol",
          },
          {
            title: "Future - Month Mid Price",
            dataIndex: "monthMidPrice",
            align: "right" as const,
            render: (_: any, { monthMidPrice }: any) =>
              monthMidPrice ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(monthMidPrice)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(monthMidPrice)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
          {
            title: "Future - Month Long Basis",
            dataIndex: "monthLongBasis",
            align: "right" as const,
            render: (_: any, { monthLongBasis }: any) =>
              monthLongBasis ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(monthLongBasis)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(monthLongBasis)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
          {
            title: "Future - Month Short Basis",
            dataIndex: "monthShortBasis",
            align: "right" as const,
            render: (_: any, { monthShortBasis }: any) =>
              monthShortBasis ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(monthShortBasis)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(monthShortBasis)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
          {
            title: "Future - Month Spread",
            dataIndex: "monthSpread",
            align: "right" as const,
            render: (_: any, { monthSpread }: any) =>
              monthSpread ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(monthSpread)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(monthSpread)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
        ],
      },
      {
        title: (
          <Row justify={"center"}>
            <h3 style={{ color: "black" }}>
              Future - Quarter (Single Collateral)
            </h3>
          </Row>
        ),
        children: [
          {
            title: "Symbol",
            dataIndex: "quarterSymbol",
          },
          {
            title: "Future - Quarter Mid Price",
            dataIndex: "quarterMidPrice",
            align: "right" as const,
            render: (_: any, { quarterMidPrice }: any) =>
              quarterMidPrice ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(quarterMidPrice)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(quarterMidPrice)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
          {
            title: "Future - Quarter Long Basis",
            dataIndex: "quarterLongBasis",
            align: "right" as const,
            render: (_: any, { monthLongBasis: quarterLongBasis }: any) =>
              quarterLongBasis ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(quarterLongBasis)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(quarterLongBasis)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
          {
            title: "Future - Quarter Short Basis",
            dataIndex: "quarterShortBasis",
            align: "right" as const,
            render: (_: any, { quarterShortBasis }: any) =>
              quarterShortBasis ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(quarterShortBasis)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(quarterShortBasis)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
          {
            title: "Future - Quarter Spread",
            dataIndex: "quarterSpread",
            align: "right" as const,
            render: (_: any, { quarterSpread }: any) =>
              quarterSpread ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(quarterSpread)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(quarterSpread)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
        ],
      },
      {
        title: (
          <Row justify={"center"}>
            <h3 style={{ color: "black" }}>
              Future - Semi Annual (Single Collateral)
            </h3>
          </Row>
        ),
        children: [
          {
            title: "Symbol",
            dataIndex: "semiAnnualSymbol",
          },
          {
            title: "Future - Semi Annual Mid Price",
            dataIndex: "semiAnnualMidPrice",
            align: "right" as const,
            render: (_: any, { semiAnnualMidPrice }: any) =>
              semiAnnualMidPrice ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(semiAnnualMidPrice)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(semiAnnualMidPrice)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
          {
            title: "Future - Semi Annual Long Basis",
            dataIndex: "semiAnnualLongBasis",
            align: "right" as const,
            render: (_: any, { semiAnnualLongBasis }: any) =>
              semiAnnualLongBasis ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(semiAnnualLongBasis)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(semiAnnualLongBasis)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
          {
            title: "Future - Semi Annual Short Basis",
            dataIndex: "semiAnnualShortBasis",
            align: "right" as const,
            render: (_: any, { semiAnnualShortBasis }: any) =>
              semiAnnualShortBasis ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(semiAnnualShortBasis)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(semiAnnualShortBasis)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
          {
            title: "Future - Semi Annual Spread",
            dataIndex: "semiAnnualSpread",
            align: "right" as const,
            render: (_: any, { semiAnnualSpread }: any) =>
              semiAnnualSpread ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(semiAnnualSpread)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(semiAnnualSpread)}
                </Tooltip>
              ) : (
                <></>
              ),
          },
        ],
      },
    ],
    [],
  );

  const multiCollateralColumns = useMemo(
    () => [
      {
        title: (
          <Row justify={"center"}>
            <h3 style={{ color: "black" }}>
              Future - Month (Multi Collateral)
            </h3>
          </Row>
        ),
        children: [
          {
            title: "Symbol",
            dataIndex: "monthMCSymbol",
          },
          {
            title: "Future - Month Mid Price",
            dataIndex: "monthMCMidPrice",
            align: "right" as const,
            render: (_: any, { monthMCMidPrice }: any) =>
              monthMCMidPrice ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(monthMCMidPrice)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(monthMCMidPrice)}
                </Tooltip>
              ) : (
                <>N/A</>
              ),
          },
          {
            title: "Future - Month Long Basis",
            dataIndex: "monthMCLongBasis",
            align: "right" as const,
            render: (_: any, { monthMCLongBasis }: any) =>
              monthMCLongBasis ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(monthMCLongBasis)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(monthMCLongBasis)}
                </Tooltip>
              ) : (
                <>N/A</>
              ),
          },
          {
            title: "Future - Month Short Basis",
            dataIndex: "monthMCShortBasis",
            align: "right" as const,
            render: (_: any, { monthMCShortBasis }: any) =>
              monthMCShortBasis ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(monthMCShortBasis)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(monthMCShortBasis)}
                </Tooltip>
              ) : (
                <>N/A</>
              ),
          },
          {
            title: "Future - Month Spread",
            dataIndex: "monthMCSpread",
            align: "right" as const,
            render: (_: any, { monthMCSpread }: any) =>
              monthMCSpread ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(monthMCSpread)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(monthMCSpread)}
                </Tooltip>
              ) : (
                <>N/A</>
              ),
          },
        ],
      },
      {
        title: (
          <Row justify={"center"}>
            <h3 style={{ color: "black" }}>
              Future - Quarter (Multi Collateral)
            </h3>
          </Row>
        ),
        children: [
          {
            title: "Symbol",
            dataIndex: "quarterMCSymbol",
          },
          {
            title: "Future - Quarter Mid Price",
            dataIndex: "quarterMCMidPrice",
            align: "right" as const,
            render: (_: any, { quarterMCMidPrice }: any) =>
              quarterMCMidPrice ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(quarterMCMidPrice)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(quarterMCMidPrice)}
                </Tooltip>
              ) : (
                <>N/A</>
              ),
          },
          {
            title: "Future - Quarter Long Basis",
            dataIndex: "quarterMCLongBasis",
            align: "right" as const,
            render: (_: any, { quarterMCLongBasis }: any) =>
            quarterMCLongBasis ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(quarterMCLongBasis)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(quarterMCLongBasis)}
                </Tooltip>
              ) : (
                <>N/A</>
              ),
          },
          {
            title: "Future - Quarter Short Basis",
            dataIndex: "quarterMCShortBasis",
            align: "right" as const,
            render: (_: any, { quarterMCShortBasis }: any) =>
              quarterMCShortBasis ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(quarterMCShortBasis)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(quarterMCShortBasis)}
                </Tooltip>
              ) : (
                <>N/A</>
              ),
          },
          {
            title: "Future - Quarter Spread",
            dataIndex: "quarterMCSpread",
            align: "right" as const,
            render: (_: any, { quarterMCSpread }: any) =>
              quarterMCSpread ? (
                <Tooltip
                  title={`$ ${Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 14,
                    minimumFractionDigits: 2,
                  }).format(quarterMCSpread)}`}
                >
                  $
                  {Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(quarterMCSpread)}
                </Tooltip>
              ) : (
                <>N/A</>
              ),
          },
        ],
      },
    ],
    [],
  );

  const initialColumns = useMemo(
    () => [
      {
        title: "Timestamp",
        dataIndex: "timestamp",
        fixed: "left" as const,
        render: (_: any, { timestamp }: any) =>
          timestamp ? (
            <Space>
              {new Date(timestamp)
                .toLocaleString("en-US", {
                  timeZone: displayTimezone,
                  timeZoneName: "short",
                })
                .split(", ")
                .join(" ")}
            </Space>
          ) : (
            <></>
          ),
      },
      {
        title: "Symbol",
        dataIndex: "id",
        render: (_: any, { assetBase, assetQuote }: any) =>
          `${assetBase}/${assetQuote}`,
      },
      {
        title: "Spot - Mid Price",
        dataIndex: "midPrice",
        align: "right" as const,
        render: (_: any, { midPrice }: any) =>
          midPrice ? (
            <Tooltip
              title={`$ ${Intl.NumberFormat("en-US", {
                maximumFractionDigits: 14,
                minimumFractionDigits: 2,
              }).format(midPrice)}`}
            >
              $
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(midPrice)}
            </Tooltip>
          ) : (
            <></>
          ),
      },
    ],
    [displayTimezone],
  );

  const [displayColumns, setDisplayColumns] = useState<any[]>(initialColumns);
  const [singleCollateralChecked, setSingleCollateralChecked] =
    useState<boolean>(true);
  const [multiCollateralChecked, setMultiCollateralChecked] =
    useState<boolean>(true);

  const fetchData = useCallback(() => {
    setFetchingData(true);
    setDataFetched(false);
    setFetchedDateRange(undefined);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/historical-market-price",
      withCredentials: true,
      params: {
        startDate: selectedDateRange[0].format("YYYY-MM-DD"),
        endDate: selectedDateRange[1].format("YYYY-MM-DD"),
      },
    })
      .then((res) => {
        setCanExport(res.data.canExport);
        setTableData(res.data.res);
        setDataCount(res.data.res.length);
        setFetchedDateRange(selectedDateRange);
        setDataFetched(true);
      })
      .finally(() => {
        setFetchingData(false);
      });
  }, [selectedDateRange]);

  const exportCSV = useCallback(() => {
    setDownloadingCSV(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/historical-market-price/export-csv",
      withCredentials: true,
      data: {
        startDate: fetchedDateRange[0].format("YYYY-MM-DD"),
        endDate: fetchedDateRange[1].format("YYYY-MM-DD"),
        includeSingleCollateral: singleCollateralChecked,
        includeMultiCollateral: multiCollateralChecked,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${fetchedDateRange[0].format(
            "YYYY-MM-DD",
          )}-${fetchedDateRange[1].format(
            "YYYY-MM-DD",
          )}_venue_market_basis.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setDownloadingCSV(false);
      });
  }, [fetchedDateRange, multiCollateralChecked, singleCollateralChecked]);

  useEffect(() => {
    const res = [...initialColumns];
    if (singleCollateralChecked) res.push(...(singleCollateralColumns as any));
    if (multiCollateralChecked) res.push(...(multiCollateralColumns as any));
    setDisplayColumns(res as any);
  }, [
    initialColumns,
    singleCollateralColumns,
    singleCollateralChecked,
    multiCollateralChecked,
    multiCollateralColumns,
  ]);

  return (
    <Content id="view-venue-historical-market-basis">
      <Row>
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Form className="dcl-toggled-content dcl-toggled-content-filter">
            <Row>
              <Form.Item className="dcl-filter-item">
                <RangePicker
                  className="dcl-daterange-select dcl-daterange-select"
                  onChange={(e) => {
                    setSelectedDateRange(e);
                  }}
                />
              </Form.Item>
              <Form.Item className="dcl-filter-item">
                <Checkbox
                  checked={singleCollateralChecked}
                  onChange={(e) => {
                    setSingleCollateralChecked(e.target.checked);
                  }}
                >
                  Single Collateral
                </Checkbox>
                <Checkbox
                  checked={multiCollateralChecked}
                  onChange={(e) => {
                    setMultiCollateralChecked(e.target.checked);
                  }}
                >
                  Multi-Collateral
                </Checkbox>
              </Form.Item>
            </Row>

            <Row justify={"end"}>
              <Space>{dataFetched ? <b>{dataCount} Results</b> : <></>}</Space>
              <Space>
                <Button
                  type={"primary"}
                  loading={fetchingData}
                  disabled={!selectedDateRange}
                  className="ant-btn-primary"
                  onClick={fetchData}
                >
                  Apply
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify={"end"}>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              disabled={!fetchedDateRange || !canExport}
              loading={downloadingCSV}
              onClick={exportCSV}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>
      {dataFetched ? (
        <Table
          columns={displayColumns}
          dataSource={tableData}
          scroll={{
            x:
              singleCollateralChecked && multiCollateralChecked
                ? 5000
                : singleCollateralChecked
                ? 3000
                : multiCollateralChecked
                ? 2000
                : 1000,
          }}
          sticky
        />
      ) : (
        <></>
      )}
    </Content>
  );
}
