import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ConfigProvider, Drawer, Menu, Select, Space } from "antd";
import { Header } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/DLC_Logo_RVB-white-400.png";
import "../../assets/scss/navbar.scss";
import { DisplayContext } from "../Context/displayContext";
import { UserContext } from "../Context/userContext";

function NavBar({ items, topItems, handleMenuClick, collapsed}: any) {
  const context = useContext(UserContext);
  const navigate = useNavigate();

  const displayContext = useContext(DisplayContext);
  const [displayTimezoneOptions, setDisplayTimezoneOption] = useState<any[]>();

  //const username = context?.user.username;

  const getDisplayTimezone = useCallback((params = {}) => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/displayTimezone",
      withCredentials: true,
    }).then((res) => {
      setDisplayTimezoneOption(res.data);
    });
  }, []);

  useEffect(() => {
    getDisplayTimezone();
  }, [getDisplayTimezone]);

  const fullname = context?.user.fullname;
  const logOut = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/authentication/log-out/",
      withCredentials: true,
    })
      .then(() => {
        context?.setUser({
          id: 0,
          email: "",
          fullname: "",
          roleId: 0,
          capabilities: [],
          selectedClient: undefined,
          roleName: "",
        });
        navigate("/login");
      })
      .catch(() => {
        context?.setUser({
          id: 0,
          email: "",
          fullname: "",
          roleId: 0,
          capabilities: [],
          selectedClient: undefined,
          roleName: "",
        });
        navigate("/login");
      });
  };
  const [visible, setVisible] = useState(false);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [cornerItems, setCornerItems] = useState<any[]>([]);
  useEffect(() => {
    const clientLength = context?.user.clients?.length;

    if (
      context?.user.roleName &&
      context?.user.roleName.startsWith("delchain")
    ) {
      setCornerItems([
        {
          key: "timezone",
          label: (
            <Space className="dcl-filter-row-space">
              <b>Display Timezone:</b>
              <Select
                className="dcl-select-dropdown dcl-timezone-selector"
                defaultValue={displayContext?.displayContext.timezone}
                onChange={(value) => {
                  displayContext?.setDisplayContext({
                    timezone: value,
                  });
                }}
                popupMatchSelectWidth={false}
                options={displayTimezoneOptions}
              />
            </Space>
          ),
        },
        ...topItems,
        {
          key: "clientInfo",
          label: <span>Delchain</span>,
          icon: <FontAwesomeIcon icon={"fa-solid fa-briefcase" as IconProp} />,
        },
        {
          key: "userInfo",
          label: (
            <Link
              to={`/bo/user/info/${encodeURIComponent(context.user.id)}`}
              state={{
                from: window.location.pathname,
              }}
            >
              <span>{fullname}</span>
            </Link>
          ),
          icon: <FontAwesomeIcon icon={"fa-regular fa-user" as IconProp} />,
        },
        {
          key: "logOut",
          label: null,
          icon: <FontAwesomeIcon icon={"fa-solid fa-power-off" as IconProp} />,
          onClick: () => {
            logOut();
          },
        },
      ]);
      setMenuItems(
        [
          {
            key: "timezone",
            label: (
              <Space className="dcl-filter-row-space">
                <b>Display Timezone:</b>
                <Select
                  className="dcl-select-dropdown dcl-timezone-selector"
                  defaultValue={displayContext?.displayContext.timezone}
                  onChange={(value) => {
                    displayContext?.setDisplayContext({
                      timezone: value,
                    });
                  }}
                  popupMatchSelectWidth={false}
                  options={displayTimezoneOptions}
                />
              </Space>
            ),
          },
          { type: "divider" },
          {
            key: "logOut",
            label: null,
            icon: (
              <FontAwesomeIcon icon={"fa-solid fa-power-off" as IconProp} />
            ),
            onClick: () => {
              logOut();
            },
          },
          ...topItems,
          {
            key: "clientInfo",
            label: <span>Delchain</span>,
            icon: (
              <FontAwesomeIcon icon={"fa-solid fa-briefcase" as IconProp} />
            ),
          },
          {
            key: "userInfo",
            label: (
              <Link
                to={`/bo/user/info/${encodeURIComponent(context.user.id)}`}
                state={{
                  from: window.location.pathname,
                }}
              >
                <span>{fullname}</span>
              </Link>
            ),
            icon: <FontAwesomeIcon icon={"fa-regular fa-user" as IconProp} />,
          },

          { type: "divider" },
        ].concat(items),
      );
    } else if (
      context?.user.clients &&
      (!context?.user.roleId ||
        context?.user.roleId === 4 ||
        context?.user.roleId === 5)
    ) {
      if (context?.user.clients && clientLength === 1) {
        setCornerItems([
          {
            key: "clientInfo",
            label: <span>{context?.user.clients[0].clientName}</span>,
            icon: (
              <FontAwesomeIcon icon={"fa-solid fa-briefcase" as IconProp} />
            ),
            children: [
              {
                key: "timezone",
                label: (
                  <Space className="dcl-filter-row-space">
                    <b>Display Timezone:</b>
                    <Select
                      className="dcl-select-dropdown dcl-timezone-selector"
                      defaultValue={displayContext?.displayContext.timezone}
                      onChange={(value) => {
                        displayContext?.setDisplayContext({
                          timezone: value,
                        });
                      }}
                      popupMatchSelectWidth={false}
                      options={displayTimezoneOptions}
                    />
                  </Space>
                ),
              },
              {
                key: "myCompanyProfile",
                label: (
                  <Link
                    to={
                      context.user.selectedClient
                        ? `/client/my_company_profile#${encodeURIComponent(
                            context.user.selectedClient.clientId,
                          )}`
                        : ``
                    }
                    state={{
                      from: window.location.pathname,
                    }}
                  >
                    <span>My Company Profile</span>
                  </Link>
                ),
              },
            ],
          },
          {
            key: "userInfo",
            label: fullname,
            icon: <FontAwesomeIcon icon={"fa-regular fa-user" as IconProp} />,
            children: [
              {
                key: "myDetails",
                label: (
                  <Link
                    to={`/client/my_profile#${encodeURIComponent(
                      context.user.id,
                    )}`}
                    state={{
                      from: window.location.pathname,
                    }}
                  >
                    <span>My Profile</span>
                  </Link>
                ),
              },
            ],
          },
          {
            key: "logOut",
            label: null,
            icon: (
              <FontAwesomeIcon icon={"fa-solid fa-power-off" as IconProp} />
            ),
            onClick: () => {
              logOut();
            },
          },
        ]);
        setMenuItems(
          [
            {
              key: "timezone",
              label: (
                <Space className="dcl-filter-row-space">
                  <b>Display Timezone:</b>
                  <Select
                    className="dcl-select-dropdown dcl-timezone-selector"
                    defaultValue={displayContext?.displayContext.timezone}
                    onChange={(value) => {
                      displayContext?.setDisplayContext({
                        timezone: value,
                      });
                    }}
                    popupMatchSelectWidth={false}
                    options={displayTimezoneOptions}
                  />
                </Space>
              ),
            },
            {
              key: "clientInfo",
              label: <span>{context?.user.clients[0].clientName}</span>,
              icon: (
                <FontAwesomeIcon icon={"fa-solid fa-briefcase" as IconProp} />
              ),
              children: [
                {
                  key: "myCompanyProfile",
                  label: (
                    <Link
                      to={
                        context.user.selectedClient
                          ? `/client/my_company_profile#${encodeURIComponent(
                              context.user.selectedClient.clientId,
                            )}`
                          : ``
                      }
                      state={{
                        from: window.location.pathname,
                      }}
                    >
                      <span>My Company Profile</span>
                    </Link>
                  ),
                },
              ],
            },
            {
              key: "userInfo",
              label: fullname,
              icon: <FontAwesomeIcon icon={"fa-regular fa-user" as IconProp} />,
              children: [
                {
                  key: "myDetails",
                  label: (
                    <Link
                      to={
                        context.user.selectedClient
                          ? `/client/my_company_profile#${encodeURIComponent(
                              context.user.selectedClient.clientId,
                            )}`
                          : ``
                      }
                      state={{
                        from: window.location.pathname,
                      }}
                    >
                      <span>My Profile</span>
                    </Link>
                  ),
                },
              ],
            },
            {
              key: "logOut",
              label: null,
              icon: (
                <FontAwesomeIcon icon={"fa-solid fa-power-off" as IconProp} />
              ),
              onClick: () => {
                logOut();
              },
            },
          ].concat(items),
        );
      } else if (context?.user.clients && clientLength && clientLength > 1) {
        setCornerItems([
          {
            key: "timezone",
            label: (
              <Space className="dcl-filter-row-space">
                <b>Display Timezone:</b>
                <Select
                  className="dcl-select-dropdown dcl-timezone-selector"
                  defaultValue={displayContext?.displayContext.timezone}
                  onChange={(value) => {
                    displayContext?.setDisplayContext({
                      timezone: value,
                    });
                  }}
                  popupMatchSelectWidth={false}
                  options={displayTimezoneOptions}
                />
              </Space>
            ),
          },
          {
            key: "clientInfo",
            label: (
              <Select
                showSearch
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                defaultValue={context.user.clients[0].clientId}
                onChange={(value) => {
                  const client = context.user.clients?.find(
                    (e) => e.clientId === value,
                  );
                  context?.setUser({
                    id: context.user.id,
                    email: context.user.email,
                    roleId: client?.roleId ? client.roleId : 0,
                    capabilities: context.user.capabilities,
                    fullname: context.user.fullname,
                    clients: context.user.clients,
                    selectedClient: client,
                    roleName: context.user.roleName,
                  });
                }}
              >
                {context.user.clients.map((client) => (
                  <Select.Option
                    value={client.clientId}
                    key={client.clientId}
                    id={client.clientId}
                  >
                    {client.clientName}
                  </Select.Option>
                ))}
              </Select>
            ),
            children: [
              {
                key: "myCompanyProfile",
                label: (
                  <span
                    onClick={() => {
                      navigate(
                        context.user.selectedClient
                          ? `/client/my_company_profile#${encodeURIComponent(
                              context.user.selectedClient.clientId,
                            )}`
                          : "",
                        {
                          state: {
                            from: window.location.pathname,
                          },
                        },
                      );
                    }}
                  >
                    My Company Profile
                  </span>
                ),
              },
            ],
            icon: (
              <FontAwesomeIcon icon={"fa-solid fa-briefcase" as IconProp} />
            ),
          },
          {
            key: "userInfo",
            label: fullname,
            icon: <FontAwesomeIcon icon={"fa-regular fa-user" as IconProp} />,
            children: [
              {
                key: "myDetails",
                label: (
                  <Link
                    to={`/client/my_profile#${encodeURIComponent(
                      context.user.id,
                    )}`}
                    state={{
                      from: window.location.pathname,
                    }}
                  >
                    <span>My Profile</span>
                  </Link>
                ),
              },
            ],
          },
          {
            key: "logOut",
            label: null,
            icon: (
              <FontAwesomeIcon icon={"fa-solid fa-power-off" as IconProp} />
            ),
            onClick: () => {
              logOut();
            },
          },
        ]);
        setMenuItems(
          [
            {
              key: "timezone",
              label: (
                <Space className="dcl-filter-row-space">
                  <b>Display Timezone:</b>
                  <Select
                    className="dcl-select-dropdown dcl-timezone-selector"
                    defaultValue={displayContext?.displayContext.timezone}
                    onChange={(value) => {
                      displayContext?.setDisplayContext({
                        timezone: value,
                      });
                    }}
                    popupMatchSelectWidth={false}
                    options={displayTimezoneOptions}
                  />
                </Space>
              ),
            },
            {
              key: "clientInfo2",
              label: (
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  defaultValue={context.user.clients[0].clientId}
                  onChange={(value) => {
                    const client = context.user.clients?.find(
                      (e) => e.clientId === value,
                    );
                    context?.setUser({
                      id: context.user.id,
                      email: context.user.email,
                      roleId: client?.roleId ? client.roleId : 0,
                      capabilities: context.user.capabilities,
                      fullname: context.user.fullname,
                      clients: context.user.clients,
                      selectedClient: client,
                      roleName: context.user.roleName,
                    });
                  }}
                >
                  {context.user.clients.map((client) => (
                    <Select.Option
                      value={client.clientId}
                      key={client.clientId}
                      id={client.clientId}
                    >
                      {client.clientName}
                    </Select.Option>
                  ))}
                </Select>
              ),

              children: [
                {
                  key: "myCompanyProfile",
                  label: (
                    <span
                      onClick={() => {
                        navigate(
                          context.user.selectedClient
                            ? `/client/my_company_profile#${encodeURIComponent(
                                context.user.selectedClient.clientId,
                              )}`
                            : "",
                          {
                            state: {
                              from: window.location.pathname,
                            },
                          },
                        );
                      }}
                    >
                      My Company Profile
                    </span>
                  ),
                },
              ],
              icon: (
                <FontAwesomeIcon icon={"fa-solid fa-briefcase" as IconProp} />
              ),
            },
            {
              key: "userInfo",
              label: fullname,
              icon: <FontAwesomeIcon icon={"fa-regular fa-user" as IconProp} />,
              children: [
                {
                  key: "myDetails",
                  label: (
                    <Link
                      to={`/client/my_profile#${encodeURIComponent(
                        context.user.id,
                      )}`}
                      state={{
                        from: window.location.pathname,
                      }}
                    >
                      <span>My Profile</span>
                    </Link>
                  ),
                },
              ],
            },
            {
              key: "logOut",
              label: null,
              icon: (
                <FontAwesomeIcon icon={"fa-solid fa-power-off" as IconProp} />
              ),
              onClick: () => {
                logOut();
              },
            },
          ].concat(items),
        );
      }
    }
    // eslint-disable-next-line
  }, [context, items]);
  return (
    <React.Fragment>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              darkItemSelectedBg: "#19bdc2",
            },
          },
        }}
      >
        <Header
          className="header"
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
          }}
        >
         {collapsed && <Button
            className="menu"
            type="primary"
            icon={<MenuOutlined id="navbar-menu" />}
            onClick={() => setVisible(true)}
          />}
          <Drawer
            placement="left"
            onClose={() => setVisible(false)}
            closeIcon={<CloseOutlined />}
            open={visible}
            styles={{
              body: { padding: 0 },
              content: {
                backgroundColor: "#001529",
              },
            }}
          >
            <Menu
              defaultSelectedKeys={["1"]}
              theme="dark"
              mode="inline"
              items={menuItems}
              onClick={(info) => {
                handleMenuClick(info);
                setVisible(false);
              }}
            />
          </Drawer>
          <Link to="/client/dashboard">
            <img
              src={logo}
              className="logo"
              alt="Delchain Logo"
              style={{ maxWidth: "120px" }}
            />
          </Link>

          <Menu
            selectable={false}
            id="right-corner-menu"
            theme="dark"
            mode="horizontal"
            style={{ justifyContent: "flex-end" }}
            items={cornerItems}
          ></Menu>
        </Header>
      </ConfigProvider>
    </React.Fragment>
  );
}
export default NavBar;
