import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Input,
  Row,
  Space,
  Table,
  Form,
  Spin,
  Modal,
  Layout,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function BusinessActivitySettings() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<any>();
  const [businessActivitySettings, setBusinessActivitySettings] = useState<
    any[]
  >([]);
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [deletedRows, setDeletedRows] = useState<any[]>([]);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const openDeleteConfirmModal = (id: any) => {
    setIsModalOpen(true);
    setSelectedRowId(id);
  };

  const deleteRow = (id: any) => {
    setOnFinishLoad(false);
    const newBusinessActivitySettings = [...businessActivitySettings];
    const childMenuItems = newBusinessActivitySettings.filter(
      (row) => row.parentId === id,
    );

    const newDeltedRows = [...deletedRows];

    const rowToDelete = newBusinessActivitySettings.find(
      (setting) => setting.id === id,
    );
    if (!rowToDelete.newRow) {
      newDeltedRows.push(rowToDelete);
    }
    for (const childRow of childMenuItems) {
      if (!childRow.newRow) {
        newDeltedRows.push(childRow);
      }
      newBusinessActivitySettings.splice(
        newBusinessActivitySettings.indexOf(childRow),
        1,
      );
    }
    newBusinessActivitySettings.splice(
      newBusinessActivitySettings.indexOf(rowToDelete),
      1,
    );
    console.log(newDeltedRows.sort((a, b) => b.parentId - a.parentId));
    setDeletedRows(newDeltedRows);
    setBusinessActivitySettings(newBusinessActivitySettings);
    setOnFinishLoad(true);
  };

  const updateBusinesssActivityField = (
    id: number,
    fieldName: string,
    fieldValue: string,
  ) => {
    const newBusinessActivitySettings = [...businessActivitySettings];
    const row = newBusinessActivitySettings.filter((obj) => obj.id === id)[0];
    row[fieldName] = fieldValue;
    row.edited = true;
    setBusinessActivitySettings(businessActivitySettings);
  };

  const submit = () => {
    const editedRows = businessActivitySettings.filter(
      (e) => e.edited === true && !e.newRow,
    );
    const newRows = businessActivitySettings.filter((e) => e.newRow);

    setOnFinishLoad(false);
    axios({
      method: "Put",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/dsp-terms/business-activity",
      withCredentials: true,
      data: {
        editedRows: editedRows,
        newRows: newRows,
        deletedRows: deletedRows,
      },
    })
      .then(() => {
        toast.success("Business activity settings updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
        fetchData();
      });
  };

  const insertNewRow = () => {
    setOnFinishLoad(false);
    const newEmailSettings = [...businessActivitySettings];
    const newRow = {
      id: `temp_${newEmailSettings.filter((row) => row.newRow).length + 1}`,
      name: "",
      newRow: true,
    };
    newEmailSettings.unshift(newRow);
    setBusinessActivitySettings(newEmailSettings);
    setOnFinishLoad(true);
  };

  const fetchData = () => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/dsp-terms/business-activity",
      withCredentials: true,
    })
      .then((res) => {
        res.data.businessActivities.forEach((e: any) => {
          e.key = e.id;
          e.edited = false;
        });
        setCanEdit(res.data.canEdit);
        setBusinessActivitySettings(res.data.businessActivities);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      width: "5%",
      editable: false,
      render: (text: any) => <strong>{text}</strong>,
    },
    {
      title: "Business Activity Name",
      dataIndex: "value",
      width: "10%",
      editable: false,
      render: (text: any, { id }: any) => (
        <Input
          defaultValue={text}
          onChange={(e: any) => {
            updateBusinesssActivityField(id, "value", e.target.value);
          }}
        />
      ),
    },
    {
      title: "sort",
      dataIndex: "sort",
      width: "10%",
      editable: false,
      render: (text: any, { id }: any) => (
        <Input
          defaultValue={text}
          onChange={(e: any) => {
            updateBusinesssActivityField(id, "sort", e.target.value);
          }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      width: "3%",
      editable: false,
      render: (_: any, { id }: any) => (
        <Button
          disabled={!canEdit}
          onClick={() => {
            openDeleteConfirmModal(id);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);
  return onFinshiLoad ? (
    <Content>
      <Row justify={"end"} style={{ marginRight: "10px" }}>
        <Button
          type="primary"
          onClick={() => {
            insertNewRow();
          }}
        >
          <FontAwesomeIcon
            icon={"fa-solid fa-plus" as IconProp}
            className="white-plus"
          />
          &nbsp; New Business Activity
        </Button>
      </Row>
      <Form component={false}>
        <Table
          columns={columns}
          //rowKey={(record) => record.id}
          scroll={{ x: 1000 }}
          dataSource={businessActivitySettings}
          sticky
        />
      </Form>
      <Row justify="center">
        <Space>
          <Button
            onClick={() => {
              fetchData();
            }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => {
              submit();
            }}
          >
            Save
          </Button>
        </Space>
      </Row>
      <Modal
        getContainer={false}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onOk={() => {
          deleteRow(selectedRowId);
          setIsModalOpen(false);
        }}
      >
        <p>
          Are you sure you want to delete the business activity's row{" "}
          {selectedRowId}?
        </p>
        <p>
          Click the Ok button and then click the Save button at the end of the
          page to save the updates.
        </p>
      </Modal>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
export default BusinessActivitySettings;
