import { Col, Row, Select, Table } from "antd";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { gunzip, strFromU8 } from "fflate";
import { Client } from "@stomp/stompjs";
import { useNavigate } from "react-router-dom";

export default function ViewRealTimePrice() {
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [venueOptions, setVenueOptions] = useState<any[]>();
  const [selectedAsset, setSelectedAsset] = useState<any>();
  const [counterCurrencyOptions, setCounterCurrencyOptions] = useState<any[]>();
  const [selectedCounterCurrency, setSelectedCounterCurrency] =
    useState<number>();
  const navigate = useNavigate();
  const getAssetOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      params: { withVenuePairs: true },
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
            venuePairs: asset.venuePairs,
          });
        }
        setAssetOptions(options);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  const getVenueList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueOptions(res.data.list);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  const getKucoinPublicToken = useCallback(() => {
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/get-kucoin-public-token",
      withCredentials: true,
    })
      .then((res) => {
        setKucoinPublicToken(res.data);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  const [selectedVenuePairs, setSelectedVenuePairs] = useState<any>();
  const updateSelectedAsset = useCallback(
    (option: any) => {
      setSelectedCounterCurrency(undefined);

      if (!option) {
        setSelectedAsset(undefined);
        setCounterCurrencyOptions(undefined);
        return;
      }
      setSelectedAsset(option);
      const counterCurrencyIds = option.venuePairs?.map(
        (t: any) => t.counterCurrencyId,
      );
      if (counterCurrencyIds) {
        const counterAssetOptionsTemp = assetOptions?.filter((t) =>
          counterCurrencyIds.includes(t.value),
        );
        setCounterCurrencyOptions(counterAssetOptionsTemp);
      } else {
        setCounterCurrencyOptions([]);
      }
    },
    [assetOptions],
  );

  const [isFetchBitfinex, setIsFetchBitfinex] = useState<boolean>(false);
  const [isFetchBinance, setIsFetchBinance] = useState<boolean>(false);
  const [isFetchKraken, setIsFetchKraken] = useState<boolean>(false);
  const [isFetchBittrex, setIsFetchBittrex] = useState<boolean>(false);
  const [isFetchHuobi, setIsFetchHuobi] = useState<boolean>(false);
  const [isFetchKucoin, setIsFetchKucoin] = useState<boolean>(false);
  const [isFetchGateIO, setIsFetchGateIO] = useState<boolean>(false);
  const [isFetchLAToken, setIsFetchLAToken] = useState<boolean>(false);
  const [isFetchOkx, setIsFetchOkx] = useState<boolean>(false);
  const [isFetchMexc, setIsFetchMexc] = useState<boolean>(false);

  const [bitfinexPrice, setBitfinexPrice] = useState<number>();
  const [binancePrice, setBinancePrice] = useState<number>();
  const [huobiPrice, setHuobiPrice] = useState<number>();
  const [krakenPrice, setKrakenPrice] = useState<number>();
  const [bittrexPrice, setBittrexPrice] = useState<number>();
  const [kucoinPrice, setKucoinPrice] = useState<number>();
  const [gateIOPrice, setGateIOPrice] = useState<number>();
  const [laTokenPrice, setLATokenPrice] = useState<number>();
  const [okxPrice, setOkxPrice] = useState<number>();
  const [mexcPrice, setMexcPrice] = useState(null);

  const [venuePrices, setVenuePrices] = useState<any>([]);
  const [lastUpdated, setLastUpdated] = useState<number>();

  const [kucoinPublicToken, setKucoinPublicToken] = useState<string>();

  // Helper function to render price cells
  const renderPriceCell = useCallback(
    (value: number, record: any) => {
      const filteredValues = Object.entries(record)
        .filter(([key, val]) => key !== "key" && typeof val === "number")
        .map(([, val]) => val) as number[];

      if (filteredValues.length === 0) return null;

      const max = Math.max(...filteredValues);
      const min = Math.min(...filteredValues);
      const color = value === max ? "#00d900" : value === min ? "red" : "black";
      const className = value === lastUpdated ? "fade-in" : "";

      return (
        <b className={className} style={{ color }}>
          {value
            ? Number(value).toLocaleString("en-us", {
                maximumFractionDigits: 6,
                minimumFractionDigits: 2,
              })
            : ""}
        </b>
      );
    },
    [lastUpdated],
  );

  const columns = useMemo(
    () => [
      {
        title: "Kraken",
        dataIndex: "kraken",
        key: "kraken",
        align: "right" as const,
        width: "10%",
        render: (_: any, record: any) => renderPriceCell(record.kraken, record),
      },
      {
        title: "Binance",
        dataIndex: "binance",
        key: "binance",
        align: "right" as const,
        width: "10%",
        render: (_: any, record: any) =>
          renderPriceCell(record.binance, record),
      },
      {
        title: "Bitfinex",
        dataIndex: "bitfinex",
        key: "bitfinex",
        align: "right" as const,
        width: "10%",
        render: (_: any, record: any) =>
          renderPriceCell(record.bitfinex, record),
      },
      {
        title: "Bittrex",
        dataIndex: "bittrex",
        key: "bittrex",
        align: "right" as const,
        width: "10%",
        render: (_: any, record: any) =>
          renderPriceCell(record.bittrex, record),
      },
      {
        title: "LAToken",
        dataIndex: "latoken",
        key: "latoken",
        align: "right" as const,
        width: "10%",
        render: (_: any, record: any) =>
          renderPriceCell(record.latoken, record),
      },
      {
        title: "Kucoin",
        dataIndex: "kucoin",
        key: "kucoin",
        align: "right" as const,
        width: "10%",
        render: (_: any, record: any) => renderPriceCell(record.kucoin, record),
      },
      {
        title: "Gate.IO",
        dataIndex: "gateio",
        key: "gateio",
        align: "right" as const,
        width: "10%",
        render: (_: any, record: any) => renderPriceCell(record.gateio, record),
      },
      {
        title: "OKX",
        dataIndex: "okx",
        key: "okx",
        align: "right" as const,
        width: "10%",
        render: (_: any, record: any) => renderPriceCell(record.okx, record),
      },
      {
        title: "MEXC",
        dataIndex: "mexc",
        key: "mexc",
        align: "right" as const,
        width: "10%",
        render: (_: any, record: any) => renderPriceCell(record.mexc, record),
      },
      {
        title: "Huobi",
        dataIndex: "huobi",
        key: "huobi",
        align: "right" as const,
        width: "10%",
        render: (_: any, record: any) => renderPriceCell(record.huobi, record),
      },
    ],
    [renderPriceCell],
  );

  const updateSelectedCounterCurrency = useCallback(
    (e: number) => {
      setSelectedCounterCurrency(e);
      if (!venueOptions) {
        toast.error("Failed to fetch venue list");
        return;
      }
      const venuePairs = selectedAsset.venuePairs.filter(
        (t: any) => t.counterCurrencyId === e,
      );
      venuePairs.forEach((pair: any) => {
        pair.venueName = venueOptions.find(
          (t) => t.value === pair.venueId,
        ).label;
      });
      setSelectedVenuePairs(venuePairs);
    },
    [selectedAsset, venueOptions],
  );
  const [latokenClient, setLATokenClient] = useState<any>(null);

  useEffect(() => {
    getAssetOptions();
    getVenueList();
    getKucoinPublicToken();
  }, [getAssetOptions, getVenueList, getKucoinPublicToken]);

  useEffect(() => {
    if (!selectedVenuePairs) {
      setIsFetchBitfinex(false);
      return;
    }
    const bitfinexFound = selectedVenuePairs.some(
      (t: any) => t.venueName.toLowerCase() === "bitfinex",
    );
    const binanceFound = selectedVenuePairs.some(
      (t: any) => t.venueName.toLowerCase() === "binance",
    );
    const bittrexFound = selectedVenuePairs.some(
      (t: any) => t.venueName.toLowerCase() === "bittrex",
    );
    const gateIOFound = selectedVenuePairs.some(
      (t: any) => t.venueName.toLowerCase() === "gate.io",
    );
    const isHuobiFound = selectedVenuePairs.some(
      (t: any) => t.venueName.toLowerCase() === "huobi",
    );
    const isKucoinFound = selectedVenuePairs.some(
      (t: any) => t.venueName.toLowerCase() === "kucoin",
    );
    const krakenFound = selectedVenuePairs.some(
      (t: any) => t.venueName.toLowerCase() === "kraken",
    );
    const latokenFound = selectedVenuePairs.some(
      (t: any) => t.venueName.toLowerCase() === "latoken",
    )
      ? true
      : false;
    const okxFound = selectedVenuePairs.some(
      (t: any) => t.venueName.toLowerCase() === "okx",
    )
      ? true
      : false;

    const mexcFound = selectedVenuePairs.some(
      (t: any) => t.venueName.toLowerCase() === "mexc",
    )
      ? true
      : false;

    const bitfinexshouldBeOpen = bitfinexFound ? true : false;
    const binaceShouldBeOpen = binanceFound ? true : false;
    const bittrexShouldBeOpen = bittrexFound ? true : false;
    const gateIOShouldBeOpen = gateIOFound ? true : false;
    const huobiShouldBeOpen = isHuobiFound ? true : false;
    const kucoinShouldBeOpen = isKucoinFound ? true : false;
    const krakenShouldBeOpen = krakenFound ? true : false;

    if (bitfinexshouldBeOpen !== isFetchBitfinex)
      setIsFetchBitfinex(!isFetchBitfinex);
    if (binaceShouldBeOpen !== isFetchBinance)
      setIsFetchBinance(!isFetchBinance);
    if (bittrexShouldBeOpen !== isFetchBittrex)
      setIsFetchBittrex(!isFetchBittrex);
    if (gateIOShouldBeOpen !== isFetchGateIO) setIsFetchGateIO(!isFetchGateIO);
    if (huobiShouldBeOpen !== isFetchHuobi) setIsFetchHuobi(!isFetchHuobi);
    if (kucoinShouldBeOpen !== isFetchKucoin) setIsFetchKucoin(!isFetchKucoin);
    if (krakenShouldBeOpen !== isFetchKraken) setIsFetchKraken(!isFetchKraken);
    if (latokenFound !== isFetchLAToken) setIsFetchLAToken(!isFetchLAToken);
    if (okxFound !== isFetchOkx) setIsFetchOkx(!isFetchOkx);
    if (mexcFound !== isFetchMexc) setIsFetchMexc(!isFetchMexc);
  }, [
    isFetchBinance,
    isFetchBitfinex,
    isFetchBittrex,
    isFetchGateIO,
    isFetchHuobi,
    isFetchKucoin,
    isFetchKraken,
    isFetchLAToken,
    isFetchMexc,
    isFetchOkx,
    selectedVenuePairs,
  ]);

  // Bitfinex
  useEffect(() => {
    if (!selectedVenuePairs) {
      return;
    }
    const pair = selectedVenuePairs.find(
      (t: any) => t.venueName.toLowerCase() === "bitfinex",
    )
      ? selectedVenuePairs.find(
          (t: any) => t.venueName.toLowerCase() === "bitfinex",
        ).pair
      : undefined;
    if (isFetchBitfinex && pair) {
      const socket = new WebSocket("wss://api-pub.bitfinex.com/ws/2");
      let msg = JSON.stringify({
        event: "subscribe",
        channel: "ticker",
        symbol: pair,
      });

      // Connection opened
      socket.addEventListener("open", (event) => {
        socket.send(msg);
      });

      // Listen for messages from the server
      socket.addEventListener("message", (event) => {
        // Process and update your React component's state with the data
        const data = JSON.parse(event.data);
        const bitfinexPriceData = data[1] === "hb" ? undefined : data[1];
        if (bitfinexPriceData) {
          setBitfinexPrice(Number(bitfinexPriceData[6]));
        }
      });

      // Handle errors
      socket.addEventListener("error", (event) => {
        console.error("Bitfinex WebSocket error:", event);
      });

      socket.addEventListener("close", (event) => {
        setVenuePrices([]);
        setBitfinexPrice(undefined);
      });

      // Clean up the WebSocket connection when the component unmounts
      return () => {
        socket.close();
      };
    }
  }, [isFetchBitfinex, selectedVenuePairs]);

  // Binance
  useEffect(() => {
    if (!selectedVenuePairs) {
      return;
    }

    let socket: any;
    let intervalId: any;

    const pair = selectedVenuePairs.find(
      (t: any) => t.venueName.toLowerCase() === "binance",
    )?.pair;

    if (!pair || !isFetchBinance) return;

    const connectWebSocket = () => {
      if (socket) return;
      socket = new WebSocket("wss://ws-api.binance.com:443/ws-api/v3");

      // Function to send the message
      const sendMessage = () => {
        const msg = JSON.stringify({
          id: null,
          method: "ticker.price",
          params: {
            symbol: pair,
          },
        });
        if (socket.OPEN === socket.readyState) socket.send(msg);
      };

      // Connection opened
      socket.addEventListener("open", () => {
        sendMessage();
        intervalId = setInterval(sendMessage, 15000);
      });

      // Listen for messages from the server
      socket.addEventListener("message", (event: any) => {
        const data = JSON.parse(event.data);
        const binancePriceData = data.result.price;
        setBinancePrice(Number(binancePriceData));
      });

      // Handle errors
      socket.addEventListener("error", (event: any) => {
        console.error("Binance WebSocket error:", event);
      });

      socket.addEventListener("close", () => {
        clearInterval(intervalId);
        setVenuePrices([]);
        setBinancePrice(undefined);
        if (isFetchBinance) {
          setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
        }
      });
    };

    if (!socket) connectWebSocket();

    return () => {
      clearInterval(intervalId);
      if (socket) {
        socket.close();
      }
    };
  }, [isFetchBinance, selectedVenuePairs]);

  // Bittrex
  useEffect(() => {
    if (!selectedVenuePairs) {
      return;
    }
    const pair = selectedVenuePairs.find(
      (t: any) => t.venueName.toLowerCase() === "bittrex",
    )
      ? selectedVenuePairs.find(
          (t: any) => t.venueName.toLowerCase() === "bittrex",
        ).pair
      : undefined;
    if (!pair || !isFetchBittrex) return;
    const backendWSURL =
      process.env.REACT_APP_AWS_BACKEND_URL?.replace("https", "wss").replace(
        "http",
        "ws",
      ) + "/ws";
    const socket = new WebSocket(backendWSURL as string);
    socket.addEventListener("open", function (event) {
      socket.send(pair);
      // You might need to handle identity differently depending on your backend implementation
    });
    socket.addEventListener("message", (event) => {
      setBittrexPrice(Number(JSON.parse(event.data).lastTradeRate));
    });
    socket.addEventListener("close", function (event) {
      setBittrexPrice(undefined);
      setVenuePrices([]);
    });

    socket.addEventListener("error", function (event: any) {
      console.log("WebSocket error: ", event);
    });

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [isFetchBittrex, selectedVenuePairs]);

  // Gate IO
  useEffect(() => {
    if (!selectedVenuePairs) {
      return;
    }

    let socket: any;

    const pair = selectedVenuePairs.find(
      (t: any) => t.venueName.toLowerCase() === "gate.io",
    )?.pair;

    if (!pair || !isFetchGateIO) return;

    const connectWebSocket = () => {
      if (socket) return;
      socket = new WebSocket("wss://api.gateio.ws/ws/v4/");

      const msg = JSON.stringify({
        event: "subscribe",
        time: new Date().valueOf(),
        channel: "spot.tickers",
        payload: [pair],
      });

      socket.addEventListener("open", () => {
        socket.send(msg);
      });

      socket.addEventListener("message", (event: any) => {
        // Process and update your React component's state with the data
        const data = JSON.parse(event.data);
        if (data.event && data.event.toLowerCase() === "update") {
          const gateIOPriceData = data.result;
          setGateIOPrice(Number(gateIOPriceData.last));
        }
      });

      socket.addEventListener("error", (event: any) => {
        console.error("GateIO WebSocket error:", event);
      });

      socket.addEventListener("close", () => {
        setVenuePrices([]);
        setGateIOPrice(undefined);
        if (isFetchGateIO) {
          setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
        }
      });
    };

    if (!socket) connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [isFetchGateIO, selectedVenuePairs]);

  // Huobi
  useEffect(() => {
    if (!selectedVenuePairs) {
      return;
    }

    let socket: any;
    const pair = selectedVenuePairs.find(
      (t: any) => t.venueName.toLowerCase() === "huobi",
    )?.pair;

    if (!pair || !isFetchHuobi) return;

    const connectWebSocket = () => {
      if (socket) return;
      socket = new WebSocket("wss://api-aws.huobi.pro/ws");
      const sub = `market.${pair}.ticker`;
      const msg = JSON.stringify({ sub: sub });

      socket.addEventListener("open", () => {
        socket.send(msg);
      });

      socket.addEventListener("message", async (event: any) => {
        const fr = new FileReader();
        fr.onload = function () {
          gunzip(new Uint8Array(fr.result as any), function (err, raw) {
            if (err) {
              console.error(err);
              return;
            }
            const data = JSON.parse(strFromU8(raw));
            if (typeof data?.ping === "number") {
              socket.send(
                JSON.stringify({
                  pong: data.ping,
                }),
              );
            } else if (data?.ch === sub) {
              setHuobiPrice(data?.tick?.lastPrice);
            }
          });
        };
        fr.readAsArrayBuffer(event.data);
      });

      socket.addEventListener("error", (event: any) => {
        console.error("Huobi WebSocket error:", event);
      });

      socket.addEventListener("close", () => {
        setVenuePrices([]);
        setHuobiPrice(undefined);
        if (isFetchHuobi) {
          setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
        }
      });
    };

    if (!socket) connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [isFetchHuobi, selectedVenuePairs]);

  // Kucoin
  useEffect(() => {
    if (!selectedVenuePairs || !kucoinPublicToken) {
      return;
    }

    let socket: any;
    let intervalId: any;

    // Function to send the ping message
    const ping = () => {
      const msg = JSON.stringify({
        id: new Date().valueOf(),
        type: "ping",
      });
      if (socket.OPEN === socket.readyState) socket.send(msg);
    };

    // Function to establish WebSocket connection and set up event listeners
    const connectWebSocket = () => {
      if (socket) return;
      socket = new WebSocket(
        "wss://ws-api-spot.kucoin.com/?token=" + kucoinPublicToken,
      );

      socket.addEventListener("open", () => {
        // Subscribe to the desired topic
        const pair = getPair();
        if (pair) {
          const msg = JSON.stringify({
            id: new Date().valueOf(),
            type: "subscribe",
            topic: `/market/ticker:${pair}`,
            privateChannel: false,
            response: true,
          });
          socket.send(msg);
          intervalId = setInterval(ping, 15000);
        }
      });

      socket.addEventListener("message", (event: any) => {
        const data = JSON.parse(event.data);
        if (data.type === "message") {
          setKucoinPrice(Number(data.data.price));
        }
      });

      socket.addEventListener("error", (event: any) => {
        console.error("Kucoin WebSocket error:", event);
      });

      socket.addEventListener("close", () => {
        clearInterval(intervalId);
        setVenuePrices([]);
        setKucoinPrice(undefined);
        // Reconnect if isFetchKucoin is still true
        if (isFetchKucoin) {
          setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
        }
      });
    };

    // Function to get the desired pair
    const getPair = () => {
      return selectedVenuePairs.find(
        (t: any) => t.venueName.toLowerCase() === "kucoin",
      )?.pair;
    };

    if (!socket) connectWebSocket();

    return () => {
      if (socket && socket.OPEN === socket.readyState) {
        socket.close();
      }
    };
  }, [isFetchKucoin, selectedVenuePairs, kucoinPublicToken]);

  // Kraken
  useEffect(() => {
    if (!selectedVenuePairs) {
      return;
    }

    let socket: any;

    const pair = selectedVenuePairs.find(
      (t: any) => t.venueName.toLowerCase() === "kraken",
    )?.pair;

    if (!isFetchKraken || !pair) return;

    const connectWebSocket = () => {
      if (socket) return;
      socket = new WebSocket("wss://ws.kraken.com/");

      let msg = JSON.stringify({
        event: "subscribe",
        pair: [pair],
        subscription: {
          name: "ticker",
        },
      });

      socket.addEventListener("open", () => {
        socket.send(msg);
      });

      socket.addEventListener("message", (event: any) => {
        // Process and update your React component's state with the data
        const data = JSON.parse(event.data);
        if (data && data[1] && data[2] === "ticker") {
          const krakenPriceData = data[1].c;
          setKrakenPrice(Number(krakenPriceData[0]));
        }
      });

      socket.addEventListener("error", (event: any) => {
        console.error("Kraken WebSocket error:", event);
      });

      socket.addEventListener("close", () => {
        setVenuePrices([]);
        setKrakenPrice(undefined);
        if (isFetchKraken) {
          setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
        }
      });
    };

    if (!socket) connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [isFetchKraken, selectedVenuePairs]);

  // LAToken
  useEffect(() => {
    if (!selectedVenuePairs) {
      return () => {
        if (latokenClient) {
          latokenClient.deactivate();
        }
      };
    }
    const pair = selectedVenuePairs.find(
      (t: any) => t.venueName.toLowerCase() === "latoken",
    )
      ? selectedVenuePairs.find(
          (t: any) => t.venueName.toLowerCase() === "latoken",
        ).pair
      : undefined;
    if (!pair || !isFetchLAToken)
      return () => {
        if (latokenClient) {
          latokenClient.deactivate();
        }
      };
    const stompClient = new Client({
      brokerURL: "wss://api.latoken.com/stomp", // Replace with the actual WebSocket URL of LAToken
      connectHeaders: {
        // Any required headers
      },
      reconnectDelay: 5000,
      heartbeatIncoming: 15000,
      heartbeatOutgoing: 15000,
    });

    stompClient.onConnect = () => {
      stompClient.subscribe(`/v1/ticker/${pair}`, (message) => {
        if (message.body) {
          const data = JSON.parse(message.body);
          setLATokenPrice(Number(data.payload.lastPrice)); // Assuming the data has a 'rate' field
        }
      });
    };

    stompClient.onStompError = (frame) => {
      console.error("Broker reported error: " + frame.headers["message"]);
      console.error("Additional details: " + frame.body);
    };

    stompClient.activate();
    setLATokenClient(stompClient);

    return () => {
      setVenuePrices([]);
      setLATokenPrice(undefined);
      if (latokenClient) {
        latokenClient.deactivate();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchLAToken, selectedVenuePairs]);

  // OKX
  useEffect(() => {
    if (!selectedVenuePairs) {
      return;
    }

    let socket: any;

    const pair = selectedVenuePairs.find(
      (t: any) => t.venueName.toLowerCase() === "okx",
    )?.pair;

    if (!pair || !isFetchOkx) return;

    const connectWebSocket = () => {
      if (socket) return;
      socket = new WebSocket("wss://ws.okx.com:8443/ws/v5/public");

      let msg = JSON.stringify({
        op: "subscribe",
        args: [{ channel: "tickers", instId: pair }],
      });

      socket.addEventListener("open", () => {
        socket.send(msg);
      });

      socket.addEventListener("message", (event: any) => {
        const data = JSON.parse(event.data).data;
        if (data && data.length) {
          setOkxPrice(data[0].last);
        }
      });

      socket.addEventListener("error", (event: any) => {
        console.error("OKX WebSocket error:", event);
      });

      socket.addEventListener("close", () => {
        setVenuePrices([]);
        setOkxPrice(undefined);
        if (isFetchOkx) {
          setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
        }
      });
    };

    if (!socket) connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [isFetchOkx, selectedVenuePairs]);

  // MEXC
  useEffect(() => {
    if (!selectedVenuePairs) {
      return;
    }

    let socket: any;

    const pair = selectedVenuePairs.find(
      (t: any) => t.venueName.toLowerCase() === "mexc",
    )?.pair;

    if (!pair || !isFetchMexc) return;

    const connectWebSocket = () => {
      if (socket) return;
      socket = new WebSocket("wss://wbs.mexc.com/ws");

      let msg = JSON.stringify({
        method: "SUBSCRIPTION",
        params: [`spot@public.deals.v3.api@${pair}`],
      });

      socket.addEventListener("open", () => {
        socket.send(msg);
      });

      socket.addEventListener("message", (event: any) => {
        const data = JSON.parse(event.data);
        if (data && data.d) {
          setMexcPrice(data.d.deals[0].p);
        }
      });

      socket.addEventListener("error", (event: any) => {
        console.error("MEXC WebSocket error:", event);
      });

      socket.addEventListener("close", () => {
        setVenuePrices([]);
        setMexcPrice(null);
        if (isFetchMexc) {
          setTimeout(connectWebSocket, 5000); // Reconnect after 5 seconds
        }
      });
    };

    if (!socket) connectWebSocket();

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [isFetchMexc, selectedVenuePairs]);

  // Handle Table Data
  useEffect(() => {
    const createVenueWithPrices = () =>
      ({
        key: new Date().valueOf(),
        binance: isFetchBinance ? binancePrice : null,
        bitfinex: isFetchBitfinex ? bitfinexPrice : null,
        bittrex: isFetchBittrex ? bittrexPrice : null,
        kraken: isFetchKraken ? krakenPrice : null,
        gateio: isFetchGateIO ? gateIOPrice : null,
        huobi: isFetchHuobi ? huobiPrice : null,
        kucoin: isFetchKucoin ? kucoinPrice : null,
        latoken: isFetchLAToken ? laTokenPrice : null,
        mexc: isFetchMexc ? mexcPrice : null,
        okx: isFetchOkx ? okxPrice : null,
      } as any);
    const findChangedValue = (prev: any, current: any) => {
      for (const key in current) {
        if (current[key] !== prev[key]) {
          setLastUpdated(current[key]);
          // You can also perform other actions here if needed
        }
      }
    };

    const isFetchRequired =
      isFetchBinance ||
      isFetchBitfinex ||
      isFetchBittrex ||
      isFetchGateIO ||
      isFetchHuobi ||
      isFetchKucoin ||
      isFetchKraken ||
      isFetchLAToken ||
      isFetchMexc ||
      isFetchOkx;
    if (!venuePrices.length && isFetchRequired) {
      // If venuePrices is empty and fetching is required
      setVenuePrices([createVenueWithPrices()]);
    } else if (venuePrices.length > 0) {
      // Extract the first venue without its key
      const { key, ...firstVenue } = venuePrices[0];
      // Create an object for comparison
      const comparisonVenue = createVenueWithPrices();
      delete comparisonVenue.key; // Remove key for comparison

      if (JSON.stringify(firstVenue) !== JSON.stringify(comparisonVenue)) {
        // If current data is different, prepend new data and keep the first five
        findChangedValue(venuePrices[0], comparisonVenue);

        setVenuePrices([createVenueWithPrices(), ...venuePrices.slice(0, 4)]);
      }
    }
  }, [
    binancePrice,
    bitfinexPrice,
    bittrexPrice,
    gateIOPrice,
    huobiPrice,
    kucoinPrice,
    krakenPrice,
    laTokenPrice,
    mexcPrice,
    okxPrice,
    isFetchBinance,
    isFetchBitfinex,
    isFetchBittrex,
    isFetchGateIO,
    isFetchHuobi,
    isFetchKucoin,
    isFetchKraken,
    isFetchLAToken,
    isFetchMexc,
    isFetchOkx,
    venuePrices,
  ]);

  useEffect(() => {
    if (!selectedCounterCurrency) {
      setSelectedVenuePairs([]);
    }
  }, [selectedCounterCurrency]);

  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          <Row justify={"space-between"}>
            <Col
              lg={{ span: 6 }}
              md={{ span: 6 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Select
                className="dcl-asset-select"
                style={{ width: "100%", minWidth: "200px" }}
                onChange={(e, option) => {
                  updateSelectedAsset(option);
                }}
                allowClear
                placeholder="Asset Tickers"
                options={assetOptions?.filter(
                  (t) => t.venuePairs && t.venuePairs.length,
                )}
                popupMatchSelectWidth={false}
                showSearch
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            </Col>
            <Col
              lg={{ span: 6 }}
              md={{ span: 6 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Select
                className="dcl-asset-select"
                style={{ width: "100%", minWidth: "200px" }}
                disabled={!selectedAsset}
                value={selectedCounterCurrency}
                onChange={(e) => {
                  updateSelectedCounterCurrency(e);
                }}
                allowClear
                placeholder="Asset Tickers"
                options={counterCurrencyOptions}
                popupMatchSelectWidth={false}
                showSearch
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {selectedCounterCurrency ? (
        <Row justify={"space-around"}>
          <Col span={24}>
            <Table
              id="real-time-venue-price-table"
              sticky
              scroll={{ x: 1000 }}
              columns={columns}
              dataSource={venuePrices}
              pagination={false}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}
