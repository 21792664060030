import { Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useNavigate, useParams } from "react-router-dom";
import ViewSubWallets from "./ViewSubWallets";
import ViewWallets from "./ViewWallets";

export default function ViewWalletsContainer() {
  const { tabName = "wallets" } = useParams();
  const navigate = useNavigate();

  return (
    <Content id="delchain-view-wallets-container">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/wallets/list/" + key);
        }}
        items={[
          {
            label: "Wallets",
            key: "wallets",
            children: <ViewWallets />,
          },
          {
            label: "SubWallets",
            key: "sub-wallets",
            children: <ViewSubWallets />,
          },
        ]}
      />
    </Content>
  );
}
