import { createContext } from "react";

export interface ITitleContext {
  title: string;
  helperText: string;
}
export interface TitleContextType {
  titleContext: ITitleContext;
  setTitleContext: (title: ITitleContext) => void;
}

export const TitleContext = createContext<TitleContextType | null>(null);
