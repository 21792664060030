import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import CreateXTradeModal from "./CreateXTradeModal";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const LOAN_TYPE = 2;
export default function CreateOperation({
  clientList,
  fetchOperations,
  operationTypes,
  setShowCreateModal,
  transactionWayList,
}: any) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<number>();
  const [label, setLabel] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isXTrade, setIsXTrade] = useState<boolean>(false);
  const [isPropTrade, setIsPropTrade] = useState<boolean>(false);
  const [showCreateXTradeModal, setShowCreateXTradeModal] =
    useState<boolean>(false);
  const [transactionWayId, setTrasactionWayId] = useState<number>();

  const [xTradeOptions, setXTradeOptions] = useState<any[]>();
  const [opDate, setOpDate] = useState<any>();

  const resetFields = useCallback(() => {
    setDescription("");
    setLabel("");
    setSelectedClient(undefined);
    setIsXTrade(false);
    setIsPropTrade(false);
    setXTradesSelected([]);
    setTrasactionWayId(undefined);
    setXTradesSelectedDetails([]);
  }, []);

  const [xTradesSelected, setXTradesSelected] = useState<number[]>([]);
  const [xTradesSelectedDetails, setXTradesSelectedDetails] = useState<any[]>(
    [],
  );

  useEffect(() => {
    resetFields();
  }, [clientList, resetFields]);

  const fetchXTradeList = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/x-trade/list",
      withCredentials: true,
    }).then((res) => {
      setXTradeOptions(res.data.xTradeList);
    });
  }, []);

  const [selectedOperationType, setSelectedOperationType] = useState<number>(1);
  const createOperation = useCallback(() => {
    setIsLoading(true);
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations",
      withCredentials: true,
      data: {
        clientId: selectedClient,
        label,
        description,
        operationTypeId: selectedOperationType,
        isXTrade,
        isPropTrade,
        xTradeIds: xTradesSelected,
        transactionWayId,
        opDate: opDate
          ? opDate.tz("America/New_York", true).toDate()
          : undefined,
      },
    })
      .then(() => {
        setShowCreateModal(false);
        resetFields();
        fetchOperations();
      })
      .catch((err) => {
        if (err.response.status === 409) {
          toast.error(
            "Duplicate operation label. Please check the data you entered.",
          );
        } else if (err.response.status === 403) {
          navigate("/login");
        } else
          toast.error("Failed to create. Please check the data you entered.");
      })
      .finally(() => {
        setIsLoading(false);
        fetchXTradeList();
      });
  }, [
    label,
    isXTrade,
    isPropTrade,
    selectedClient,
    selectedOperationType,
    description,
    transactionWayId,
    opDate,
    xTradesSelected,
    fetchOperations,
    setShowCreateModal,
    fetchXTradeList,
    resetFields,
    navigate,
  ]);

  useEffect(() => {
    fetchXTradeList();
  }, [fetchXTradeList]);

  return (
    <Content id={"crerate-opeartion"}>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "white",
            },
          },
        }}
      >
        <Row
          justify={"center"}
          style={{
            marginTop: "20px",
          }}
        >
          <h2 style={{ color: "white" }}>Operation</h2>
        </Row>
        <Form
          labelCol={{ span: 7 }}
          labelWrap
          wrapperCol={{ span: 17 }}
          style={{
            marginTop: "40px",
          }}
        >
          <Form.Item label={<b>Type</b>} required>
            <Select
              options={operationTypes}
              value={selectedOperationType}
              onChange={(e) => {
                setSelectedOperationType(e);
                setIsXTrade(false);
                setIsPropTrade(false);
                setXTradesSelected([]);
                setXTradesSelectedDetails([]);
              }}
              showSearch
              filterOption={(input, option: any) =>
                option.label
                  ?.toLocaleLowerCase()
                  .includes(input.toLocaleLowerCase())
              }
            />
          </Form.Item>
          <Form.Item label={<b>Client</b>} required>
            <Select
              options={clientList}
              value={selectedClient}
              onChange={(e) => {
                setSelectedClient(e);
              }}
              showSearch
              filterOption={(input, option: any) =>
                option.label
                  ?.toLocaleLowerCase()
                  .includes(input.toLocaleLowerCase())
              }
            />
          </Form.Item>
          <Form.Item label={<b>Label</b>} required>
            <Input
              value={label}
              onChange={(e) => {
                setLabel(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            label={<b>Operation Way</b>}
            name="transactionWayId"
            required
          >
            <Radio.Group
              onChange={(e) => {
                setTrasactionWayId(e.target.value);
              }}
              buttonStyle="solid"
            >
              {transactionWayList?.map((transanctionWay: any) => (
                <Radio.Button
                  key={transanctionWay.value}
                  value={transanctionWay.value}
                  className={`dcl-${transanctionWay.label?.toLowerCase()}`}
                >
                  {transanctionWay.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label={<b>Operation Date</b>} name="opDate">
            <DatePicker
              disabledDate={(current) => current > dayjs().endOf("d")}
              onChange={(e) => {
                setOpDate(e);
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Description</b>}>
            <TextArea
              rows={3}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Form.Item>
          {selectedOperationType === LOAN_TYPE ? (
            <></>
          ) : (
            <Form.Item label={<b>Is it a X-trade?</b>} colon={false}>
              <Switch
                checked={isXTrade}
                onChange={(e) => {
                  setIsXTrade(e);
                  if (!e) {
                    setXTradesSelected([]);
                  }
                }}
              />
            </Form.Item>
          )}

          {isXTrade ? (
            <React.Fragment>
              <Form.Item label={<b>X-Trades</b>}>
                <Row align={"middle"} key={"x-trade-select"}>
                  <Col span={20}>
                    <Select
                      mode="multiple"
                      options={xTradeOptions}
                      value={xTradesSelected}
                      onChange={(e, options) => {
                        setXTradesSelected(e);
                        setXTradesSelectedDetails(options);
                      }}
                      allowClear
                      showSearch
                      filterOption={(input, option: any) =>
                        option.label
                          ?.toLocaleLowerCase()
                          .includes(input.toLocaleLowerCase())
                      }
                    />
                  </Col>
                  <Col span={3} offset={1}>
                    <Tooltip title="Create New Cross Trade">
                      <Button
                        style={{
                          border: "none",
                          background: "none",
                          boxShadow: "none",
                        }}
                        onClick={() => {
                          setShowCreateXTradeModal(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={"fa-regular fa-square-plus" as IconProp}
                          style={{ fontSize: "1.2em" }}
                        />
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </Form.Item>
              <Row>
                {xTradesSelectedDetails.map((row) => (
                  <Col span={24} key={row.id}>
                    <Card
                      title={row.label}
                      style={{ margin: "20px" }}
                      key={row.key}
                    >
                      {row.link?.map((l: any) => (
                        <Row key={l.id}>
                          <b>{l.operation?.label}</b> &nbsp;(
                          {l.operation?.description} )
                        </Row>
                      ))}
                    </Card>
                  </Col>
                ))}
              </Row>
            </React.Fragment>
          ) : (
            <></>
          )}
          {selectedOperationType === LOAN_TYPE ? (
            <></>
          ) : (
            <Form.Item label={<b>Is it a Prop Trade?</b>} colon={false}>
              <Switch
                checked={isPropTrade}
                onChange={(e) => {
                  setIsPropTrade(e);
                }}
              />
            </Form.Item>
          )}
        </Form>
        <Row justify={"center"}>
          <Button
            onClick={() => {
              setShowCreateModal(false);
              resetFields();
            }}
            loading={isLoading}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              createOperation();
            }}
            disabled={!selectedClient || !label || !transactionWayId}
            loading={isLoading}
          >
            Create
          </Button>
        </Row>
        <Modal
          open={showCreateXTradeModal}
          onCancel={() => {
            setShowCreateXTradeModal(false);
          }}
          footer={null}
        >
          <CreateXTradeModal
            fetchXTradeList={fetchXTradeList}
            setShowCreateXTradeModal={setShowCreateXTradeModal}
          />
        </Modal>
      </ConfigProvider>
    </Content>
  );
}
