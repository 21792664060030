import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, DatePicker, Form, Row, Space, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "../../../Context/displayContext";

export default function ViewFinalDataFrame() {
  const displayContext = useContext(DisplayContext);
  const [filterForm] = Form.useForm();
  const [assetFilters, setAssetFilters] = useState<any[]>();
  const [onFinishLoad, setOnFinishLoad] = useState<boolean>(false);
  const [dataFrames, setDataFrames] = useState<any[]>();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [fetchedDate, setFetchedDate] = useState<any>();

  const [isRunning, setIsRunning] = useState<boolean>(false);
  // const [canRefresh, setCanRefresh] = useState<boolean>();
  const initialColumns = [
    {
      title: <Space>Asset</Space>,
      dataIndex: "asset",
      sorter: (a: any, b: any) => a.asset.localeCompare(b.asset),
      width: 60,
      hidden: false,
      filters: assetFilters,
      filterSearch: true,
      onFilter: (value: any, record: any) =>
        record.asset.toLowerCase().indexOf(value.toLowerCase()) === 0,
      render: (_: any, { asset }: any) => (
        <Space>
          <b>{asset}</b>
        </Space>
      ),
    },
    {
      title: <Space>Kraken</Space>,
      dataIndex: "Kraken",
      // sorter: (a: any, b: any) => a.Kraken - b.Kraken,
      width: 100,
      hidden: false,
      align: "right" as const,
      render: (_: any, { Kraken }: any) =>
        Kraken ? (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 8,
              minimumFractionDigits: 4,
            }).format(Kraken)}
          </Space>
        ) : (
          <Space>-</Space>
        ),
    },
    {
      title: <Space>Binance</Space>,
      dataIndex: "Binance",
      // sorter: (a: any, b: any) => a.Binance - b.Binance,
      width: 100,
      hidden: false,
      align: "right" as const,
      render: (_: any, { Binance }: any) =>
        Binance ? (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 6,
              minimumFractionDigits: 6,
            }).format(Binance)}
          </Space>
        ) : (
          <Space>-</Space>
        ),
    },
    {
      title: <Space>Bitfinex</Space>,
      dataIndex: "Bitfinex",
      // sorter: (a: any, b: any) => a.Bitfinex - b.Bitfinex,
      width: 100,
      hidden: false,
      align: "right" as const,
      render: (_: any, { Bitfinex }: any) =>
        Bitfinex ? (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 6,
              minimumFractionDigits: 6,
            }).format(Bitfinex)}
          </Space>
        ) : (
          <Space>-</Space>
        ),
    },
    {
      title: <Space>Bittrex</Space>,
      dataIndex: "Bittrex",
      // sorter: (a: any, b: any) => a.Bittrex - b.Bittrex,
      width: 100,
      hidden: false,
      align: "right" as const,
      render: (_: any, { Bittrex }: any) =>
        Bittrex ? (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 6,
              minimumFractionDigits: 6,
            }).format(Bittrex)}
          </Space>
        ) : (
          <Space>-</Space>
        ),
    },
    {
      title: <Space>LAToken</Space>,
      dataIndex: "LAToken",
      // sorter: (a: any, b: any) => a.Bittrex - b.Bittrex,
      width: 100,
      hidden: false,
      align: "right" as const,
      render: (_: any, { LAToken }: any) =>
        LAToken ? (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 6,
              minimumFractionDigits: 6,
            }).format(LAToken)}
          </Space>
        ) : (
          <Space>-</Space>
        ),
    },
    {
      title: <Space>Bitmart</Space>,
      dataIndex: "Bitmart",
      // sorter: (a: any, b: any) => a.Bittrex - b.Bittrex,
      width: 100,
      hidden: false,
      align: "right" as const,
      render: (_: any, { Bitmart }: any) =>
        Bitmart ? (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 6,
              minimumFractionDigits: 6,
            }).format(Bitmart)}
          </Space>
        ) : (
          <Space>-</Space>
        ),
    },
    {
      title: <Space>OKX</Space>,
      dataIndex: "OKX",
      // sorter: (a: any, b: any) => a.Bittrex - b.Bittrex,
      width: 100,
      hidden: false,
      align: "right" as const,
      render: (_: any, { OKX }: any) =>
        OKX ? (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 6,
              minimumFractionDigits: 6,
            }).format(OKX)}
          </Space>
        ) : (
          <Space>-</Space>
        ),
    },
    {
      title: <Space>Mexc</Space>,
      dataIndex: "Mexc",
      // sorter: (a: any, b: any) => a.Bittrex - b.Bittrex,
      width: 100,
      hidden: false,
      align: "right" as const,
      render: (_: any, { Mexc }: any) =>
        Mexc ? (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 6,
              minimumFractionDigits: 6,
            }).format(Mexc)}
          </Space>
        ) : (
          <Space>-</Space>
        ),
    },
    {
      title: <Space>Total</Space>,
      dataIndex: "Total",
      sorter: (a: any, b: any) => a.Total - b.Total,
      width: 100,
      hidden: false,
      align: "right" as const,
      render: (_: any, { Total }: any) => (
        <Space>
          {Intl.NumberFormat("en-US", {
            maximumFractionDigits: 6,
            minimumFractionDigits: 6,
          }).format(Total)}
        </Space>
      ),
    },
    {
      title: <Space>Total USD</Space>,
      dataIndex: "totalUsd",
      sorter: (a: any, b: any) => a.totalUsd - b.totalUsd,
      width: 100,
      hidden: false,
      align: "right" as const,
      render: (_: any, { totalUsd }: any) => (
        <Space>
          {Intl.NumberFormat("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(totalUsd)}
        </Space>
      ),
    },
  ];

  const onReset = () => {
    filterForm.resetFields();
    fetchData({ isInstant: false, startDate: undefined, endDate: undefined });
  };

  const exportCSV = () => {
    setOnFinishLoad(false);
    if (dataFrames)
      dataFrames.forEach((e) => (e.totalUsd = e.totalUsd.toFixed(2)));
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/final-data-frame/export-csv",
      withCredentials: true,
      data: {
        data: dataFrames,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_final_data_frame.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };

  const fetchData = useCallback(
    (
      params = { isInstant: false, startDate: undefined, endDate: undefined },
    ) => {
      setOnFinishLoad(false);
      axios({
        method: "GET",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/final-data-frame",
        withCredentials: true,
        params: {
          instant: params.isInstant,
          startDate: params.startDate,
          endDate: params.endDate,
        },
      })
        .then((res) => {
          const assetOptions = res.data.data.map((t: any) => ({
            text: t.asset,
            value: t.asset,
          }));
          setIsRunning(res.data.isRunning);
          setAssetFilters(assetOptions);
          setDataFrames(res.data.data);
          setFetchedDate(res.data.fetchDate);
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
    },
    [],
  );

  const filterData = (form: any) => {
    const startDate = form.date
      .tz("America/New_York", true)
      .startOf("day")
      .toISOString();
    const endDate = form.date
      .tz("America/New_York", true)
      .endOf("day")
      .toISOString();
    fetchData({
      isInstant: false,
      startDate: startDate,
      endDate: endDate,
    });
  };

  const fetchInstant = () => {
    fetchData({
      isInstant: true,
      startDate: undefined,
      endDate: undefined,
    });
  };

  const getIsRunning = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/venue-balance/is-running",
      withCredentials: true,
    }).then((res: any) => {
      setIsRunning(res.data.isRunning);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning) {
        getIsRunning();
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [isRunning, getIsRunning]);

  useEffect(() => {
    fetchData({
      isInstant: false,
      startDate: undefined,
      endDate: undefined,
    });
  }, [fetchData]);

  return (
    <Content>
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Row className="dcl-filter-row" align="middle">
            <Space>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
                Filters
                {showFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
            </Space>
            <Space>
              <Button
                disabled={!onFinishLoad}
                onClick={fetchInstant}
                className="ant-btn-primary"
              >
                Instant Fetch
              </Button>
            </Space>
            <Space className="dcl-filter-row-space">
              <b>Last updated: </b>
              {fetchedDate
                ? new Date(fetchedDate).toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })
                : "Pending..."}
            </Space>
          </Row>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={filterData}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
            labelWrap
          >
            <Form.Item name="date" className="dcl-filter-item">
              <DatePicker className="dcl-date-select" />
            </Form.Item>
            <Row justify="end">
              <Space>
                <Button htmlType="submit" className="ant-btn-primary">
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify="end">
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              disabled={!onFinishLoad}
              onClick={exportCSV}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>
      <Table
        columns={initialColumns}
        dataSource={dataFrames}
        sticky
        loading={!onFinishLoad}
        pagination={{ showSizeChanger: true }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={4}>
                Total USD Value (Crypto)
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} colSpan={6}></Table.Summary.Cell>
              <Table.Summary.Cell index={0} colSpan={6} align="right">
                {dataFrames
                  ? Number(
                      dataFrames.reduce(
                        (prev: number, curr: any) =>
                          curr && curr.totalUsd
                            ? prev + Number(curr.totalUsd)
                            : Number(prev),
                        0,
                      ),
                    ).toLocaleString("en-us", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })
                  : "$0.00"}
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        scroll={{
          x: "max-content",
        }}
      />
    </Content>
  );
}
