import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { tailFormItemLayout } from "../UserPages/CreateUser";

export default function CreateFeePolicy() {
  const [creationForm] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const [feeTypeOptions, setFeeTypeOptions] = useState<any[]>([
    { key: 1, value: 1, label: "Fix Fee" },
    { key: 2, value: 2, label: "Fee Range" },
  ]);

  const [feePolicyDataType, setFeePolicyTypeOptions] = useState<any[]>([
    { key: 1, value: 1, label: "Custody" },
    { key: 2, value: 2, label: "Staking" },
    { key: 3, value: 3, label: "Trading" },
  ]);

  const [policyName, setPolicyName] = useState<string>("");
  const [policyType, setPolicyType] = useState<number>();
  const [onFinishLoaded, setOnFinishLoaded] = useState<boolean>(false);

  const onCreateFeePolicy = (form: any) => {
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy/create",
      data: form,
      withCredentials: true,
    })
      .then((res) => {
        toast.success("Updated Successfully");
        navigate("/bo/confirmation", {
          state: {
            pageType: "Fee Policy",
            createOrUpdate: "Create",
            displayName: policyName,
            createUrl: "/bo/fee/create-policy/",
            editUrl: "/bo/fee/update-policy/" + encodeURIComponent(res.data.id),
          },
        });
      })
      .catch((err) => {
        toast.error("Failed to create");
      });
  };
  const fetchFeePolicyTypeOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/fee-policy-types",
      withCredentials: true,
    }).then((res) => {
      setFeePolicyTypeOptions(res.data.feePolicyTypes);
    });
  }, []);

  const fetchFeeTypeOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/fee-types",
      withCredentials: true,
    }).then((res) => {
      setFeeTypeOptions(res.data.feeTypes);
    });
  }, []);

  useEffect(() => {
    fetchFeePolicyTypeOptions();
    fetchFeeTypeOptions();
    setOnFinishLoaded(true);
  }, [fetchFeeTypeOptions, fetchFeePolicyTypeOptions]);
  return onFinishLoaded ? (
    <Content id="create-fee-policy">
      <Row>
        <Button
          onClick={() => {
            navigate(
              location.state ? location.state.from : "/bo/fee/policy-list",
            );
          }}
        >
          <FontAwesomeIcon
            icon={"fa-solid fa-circle-chevron-left" as IconProp}
          />
        </Button>
      </Row>
      <Row justify={"center"}>
        <h2>Fee Policy - {policyName}</h2>
      </Row>
      <Form
        form={creationForm}
        autoComplete="off"
        onFinish={onCreateFeePolicy}
        style={{
          padding: "1em",
          margin: "auto",
        }}
      >
        <Row>
          <Col span={6} offset={2}>
            <Form.Item
              name={"feePolicyName"}
              label="Fee Policy Name"
              rules={[
                {
                  required: true,
                  message: "Please input fee policy name!",
                },
              ]}
            >
              <Input
                value={policyName}
                onChange={(e) => setPolicyName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item
              name={"feePolicyType"}
              label="Fee Policy Type"
              initialValue={1}
              rules={[
                {
                  required: true,
                  message: "Please input fee policy name!",
                },
              ]}
            >
              <Select options={feePolicyDataType} />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">
          <b>Account Opening Fee</b>
        </Divider>
        <Row>
          <Col span={6} offset={2}>
            <Form.Item
              name="accountOpeningVenueFee"
              label="Venue Fees"
              initialValue={0}
            >
              <InputNumber addonBefore={"$"} controls={false} />
            </Form.Item>
          </Col>
          <Col span={6} offset={2}>
            <Form.Item
              name="accountOpeningDCLFee"
              label="DCL Fees"
              initialValue={0}
            >
              <InputNumber addonBefore={"$"} controls={false} />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">
          <b>Fee Policy Detail</b>
        </Divider>
        <Row>
          <Col span={3} offset={2}>
            <Form.Item
              name={"feeTypeId"}
              label="Fee Type"
              rules={[
                {
                  required: true,
                  message: "Please input fee policy type!",
                },
              ]}
            >
              <Select
                options={feeTypeOptions}
                popupMatchSelectWidth={false}
                onChange={(e) => setPolicyType(e)}
              />
            </Form.Item>
          </Col>
          {policyType === 1 ? (
            <Col span={18} offset={1}>
              <Row>
                <Col span={4}>
                  <Form.Item
                    name={"venueFeeType"}
                    label="Venue Fee Type"
                    initialValue={"bps"}
                  >
                    <Select
                      popupMatchSelectWidth={false}
                      options={[
                        { key: "1", value: "dollar", label: "$" },
                        {
                          key: "2",
                          value: "bps",
                          label: "BPS",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item
                    name="venueFee"
                    label="Venue Fees"
                    initialValue={0}
                  >
                    <InputNumber controls={false} />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item
                    name={"dclFeeType"}
                    label="DCL Fee Type"
                    initialValue={"bps"}
                  >
                    <Select
                      options={[
                        { key: "1", value: "dollar", label: "$" },
                        {
                          key: "2",
                          value: "bps",
                          label: "BPS",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={5} offset={1}>
                  <Form.Item name="dclFees" label="DCL Fees" initialValue={0}>
                    <InputNumber controls={false} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          ) : (
            <></>
          )}
          {policyType === 2 ? (
            <Col span={18} offset={1}>
              <Form.List name="rangeFees">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <React.Fragment key={key}>
                        <Row>
                          <Col span={5}>
                            <Form.Item
                              name={[name, "from"]}
                              label="From"
                              initialValue={0}
                            >
                              <InputNumber addonBefore={"$"} controls={false} />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              name={[name, "to"]}
                              label="To"
                              initialValue={0}
                            >
                              <InputNumber addonBefore={"$"} controls={false} />
                            </Form.Item>
                          </Col>
                          <Col span={5} offset={1}>
                            <Form.Item
                              name={[name, "venueFee"]}
                              label="Venue Fees"
                              initialValue={0}
                            >
                              <InputNumber
                                addonBefore={
                                  <Select
                                    defaultValue={"bps"}
                                    options={[
                                      { key: "1", value: "dollar", label: "$" },
                                      {
                                        key: "2",
                                        value: "bps",
                                        label: "BPS",
                                      },
                                    ]}
                                    onChange={(e) => {
                                      if (
                                        creationForm.getFieldValue("rangeFees")[
                                          key
                                        ]
                                      )
                                        creationForm.getFieldValue("rangeFees")[
                                          key
                                        ].venueFeeType = e;
                                    }}
                                  />
                                }
                                controls={false}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={5} offset={1}>
                            <Form.Item
                              name={[name, "dclFees"]}
                              label="DCL Fees"
                              initialValue={0}
                            >
                              <InputNumber
                                addonBefore={
                                  <Select
                                    defaultValue={"bps"}
                                    options={[
                                      { key: "1", value: "dollar", label: "$" },
                                      {
                                        key: "2",
                                        value: "bps",
                                        label: "BPS",
                                      },
                                    ]}
                                    onChange={(e) => {
                                      if (
                                        creationForm.getFieldValue("rangeFees")[
                                          key
                                        ]
                                      )
                                        creationForm.getFieldValue("rangeFees")[
                                          key
                                        ].dclFeesType = e;
                                    }}
                                  />
                                }
                                controls={false}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={1}>
                            <Popconfirm
                              title="Are you sure to delete this range?"
                              onConfirm={() => {
                                remove(name);
                              }}
                              onCancel={() => {}}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button size="small">
                                <MinusCircleOutlined />
                                Delete
                              </Button>
                            </Popconfirm>
                          </Col>
                        </Row>
                      </React.Fragment>
                    ))}
                    <Form.Item
                      wrapperCol={{
                        sm: {
                          span: 12,
                          offset: 6,
                        },
                      }}
                    >
                      <Button
                        type="dashed"
                        onClick={() => {
                          add({ newlyAdded: true, edited: false });
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add New Range
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          ) : (
            <></>
          )}
        </Row>

        <Divider orientation="left">
          <b>Minimum Fee</b>
        </Divider>
        <Row>
          <Col span={4} offset={2}>
            <Form.Item name="feesBelow" label="Fees Below" initialValue={0}>
              <InputNumber addonBefore={"$"} controls={false} />
            </Form.Item>
          </Col>
          <Col span={4} offset={2}>
            <Form.Item
              name="feesBelowVenueFee"
              label="Venue Fees"
              initialValue={0}
            >
              <InputNumber addonBefore={"$"} controls={false} />
            </Form.Item>
          </Col>
          <Col span={4} offset={2}>
            <Form.Item name="feesBelowDclFee" label="DCL Fees" initialValue={0}>
              <InputNumber addonBefore={"$"} controls={false} />
            </Form.Item>
          </Col>
          <Col span={4} offset={2}>
            <Form.Item
              name="feesBelowIsGlboal"
              label="Is Minimum Fee Global"
              valuePropName="checked"
              initialValue={true}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item {...tailFormItemLayout}>
          <Button htmlType="submit" className="submit-button primary-button">
            Create Fee Policy
          </Button>
        </Form.Item>
      </Form>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large"  />
      </Layout>
    </Content>
  );
}
