import { Button, Checkbox, Form, Layout, Row, Spin, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import "../../../App.scss";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface ColumnInterface {
  title: string;
  dataIndex: string;
  width: string;
  editable: boolean;
  render?: any;
  fixed?: any;
}

function EditRolePrivileges() {
  let [columns, setColumns] = useState<ColumnInterface[]>([
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
      fixed: "left" as const,
      editable: false,
    },
    {
      title: "Capability Name",
      dataIndex: "name",
      width: "20%",
      editable: false,
      fixed: "left" as const,
      render: (text: any) => <strong>{text}</strong>,
    },
  ]);
  let [tableData, setTableData] = useState<any[]>([]);
  const navigate = useNavigate();
  //const [form] = Form.useForm();
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);

  const handleCheckbox = useCallback(
    (rowIndex: any, columnKey: any) => (event: any) => {
      setOnFinishLoad(false);
      const newtableData = [...tableData];
      const row = newtableData.filter((obj) => obj.key === rowIndex.key)[0];
      row[columnKey] = { value: event.target.checked, edited: true };
      row.edited = true;
      //console.log(newtableData);
      setTableData(newtableData);
      setOnFinishLoad(true);
    },
    [tableData],
  );
  const fetchData = useCallback((params = {}) => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/role-capability",
      withCredentials: true,
    }).then((res) => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (tableData.length > 0) tableData = []; // Rest table Data
      // Calculate the Column Width
      const colWidth = Number(75 / res.data.role.length).toFixed(0) + "%";
      for (const column of res.data.role) {
        if (columns.length < res.data.role.length + 2) {
          columns.push({
            title: column.roleName,
            dataIndex: column.id,
            width: colWidth,
            editable: true,
            render: (capability: any, rowIndex: any) => (
              <Checkbox
                type={"checkbox"}
                defaultChecked={capability.value}
                onChange={handleCheckbox(rowIndex, column.id)}
              ></Checkbox>
            ),
          });
        }
      }
      for (const capability of res.data.capabilityList) {
        const capabilityData: any = {
          id: capability.id,
          key: capability.id,
          name: capability.name,
        };
        for (const role of res.data.role) {
          const roleId = role.id;
          let capValue = { value: false, edited: false };
          role.roleCapabilities.forEach((roleCap: any) => {
            if (roleCap.capabilityId === capability.id) {
              capValue.value = roleCap.value;
            }
          });
          capabilityData[roleId] = capValue;
          capabilityData.edited = false; // If row is edited
        }
        if (tableData.length < res.data.capabilityList.length)
          tableData.push(capabilityData);
      }
      setTableData(tableData);
      setColumns(columns);
      setOnFinishLoad(true);
    });
  }, []);
  const saveUpdates = useCallback(() => {
    const editedRows = tableData
      .filter((row) => row.edited === true)
      .map(({ name, edited, ...attributes }) => attributes);
    if (!editedRows.length) {
      toast.success("Role Capability Updated Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/role-capability",
      withCredentials: true,
      data: {
        editedRows: editedRows,
      },
    })
      .then(() => {
        toast.success("Role Capability Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      <Content>
        {onFinshiLoad ? (
          <React.Fragment>
            <Form component={false}>
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={{
                  defaultPageSize: 50,
                  showSizeChanger: true,
                  pageSizeOptions: ["30", "50", "100"],
                }}
                scroll={{
                  x: 1600,
                  y: 800,
                }}
                sticky
              />
              <Row justify="center">
                <Button
                  onClick={() => {
                    fetchData();
                  }}
                >
                  Reset
                </Button>
                <Button onClick={saveUpdates} type="primary">
                  Save
                </Button>
              </Row>
            </Form>
          </React.Fragment>
        ) : (
          <Layout
            style={{
              paddingTop: "30vh",
              minHeight: "100vh",
            }}
          >
            <Spin size="large" />
          </Layout>
        )}
      </Content>
    </React.Fragment>
  );
}
export default EditRolePrivileges;
