import { Col, Row, Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TelegramBackup from "./TelegramBackup";
import TelegramBackupDownload from "./TelegramBackupDownload";

export default function TelegramBackupContainer() {
  const { tabName = "additional-groups" } = useParams();
  const navigate = useNavigate();
  const [canDownload, setCanDownload] = useState<boolean>(false);
  const fetchCanGet = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/can-download-telegram-backup",
      withCredentials: true,
    })
      .then((res) => {
        setCanDownload(res.data.canDownload)
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);
  useEffect(() => {
    fetchCanGet();
  }, [fetchCanGet]);
  return (
    <Content id="telegram-backup-container">
      <Tabs
        type="card"
        defaultActiveKey={tabName}
        onChange={(key) => {
          navigate("/bo/settings/telegram-backup/" + key);
        }}
        items={[
          {
            label: "Additional Groups",
            key: "additional-groups",
            children: (
              <Row justify="center">
                <Col xxl={{ span: 12 }} lg={{ span: 18 }} md={{ span: 24 }}>
                  <TelegramBackup />
                </Col>
              </Row>
            ),
          },
          {
            label: "Historical Data",
            key: "historical-data",
            disabled: !canDownload,
            children: (
              <Row justify="center">
                <Col span={24}>
                  <Row justify={"center"}>
                    <h2>Historical Data</h2>
                  </Row>
                </Col>

                <Col xxl={{ span: 12 }} lg={{ span: 18 }} md={{ span: 24 }}>
                  <TelegramBackupDownload />
                </Col>
              </Row>
            ),
          },
        ]}
      />
    </Content>
  );
}
