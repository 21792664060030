import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Switch,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import "react-phone-number-input/style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../assets/scss/create-user.scss";
import { UserContext } from "../../Context/userContext";
import { formItemLayout, tailFormItemLayout } from "./CreateUser";

function UpdateUser() {
  const location = useLocation();
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const { encodedId } = useParams();
  const [form] = Form.useForm();
  const [user, setUser] = useState("");
  const [roleList, setRoleList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [mobileNum, setMobileNum] = useState<string>("");
  const [officeNum, setOfficeNum] = useState<string>("");
  const [isDelchianAdmin, setIsDelchainAdmin] = useState<boolean>(false);
  const [isSMSVerified, SetIsSmsVerified] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRole, setSelectedRole] = useState<any>();
  const [clientList, setClientList] = useState<any[]>();
  const [clientMultiSelect, setClientMultiSelect] = useState<any>();
  const [langOptions, setLangOptions] = useState<any[]>();
  const [is2FaResetModalOpen, setIs2FaMoalOpen] = useState<boolean>(false);
  const [roleGroup, setRoleGroup] = useState<string>("");
  const [serviceList, setServiceList] = useState<any[]>();
  const [toDeleteUserClient] = useState<any[]>([]);
  const [toDeleteUsername] = useState<any[]>([]);
  const [selectedClients, setSelectedClients] = useState<any[]>([]);
  // const [usernameMultiSelect, setUsernameMultiSelect] = useState<any>();

  const show2FaResetModal = () => {
    setUser(form.getFieldValue("email"));
    setIs2FaMoalOpen(true);
  };

  const checkCanDelete = (type: string, id: number) => {
    switch (type) {
      case "client":
        return axios({
          method: "PUT",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL + "/user/client-delete-check",
          withCredentials: true,
          data: {
            userClientId: id,
          },
        });
      case "username":
        return axios({
          method: "PUT",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL +
            "/user/username-delete-check",
          withCredentials: true,
          data: {
            usernameId: id,
          },
        });
    }
  };

  const handle2FaResetModalOk = () => {
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/reset2FA",
      withCredentials: true,
      data: { email: form.getFieldValue("email") },
    })
      .then((res) => {
        toast.success("Reset user's 2FA successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
    setIs2FaMoalOpen(false);
  };

  const handle2FaResetModalCancel = () => {
    setIs2FaMoalOpen(false);
  };
  // const [serviceOptions, setServiceOptions] = useState<any[]>();
  // const [serviceSelect, setServiceSelect] = useState<any>();
  const [initialClients, setInitialClients] = useState<any[]>();
  const resendTmpPwd = () => {
    //console.log(email);
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/resetPwd",
      withCredentials: true,
      data: {
        email: form.getFieldValue("email"),
      },
    })
      .then((res) => {
        toast.success(
          "Send temporary password to user's email address successfully",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  };
  useEffect(() => {
    setIsDelchainAdmin(
      context ? context.user.roleId === 1 || context.user.roleId === 2 : false,
    );
  }, [context]);
  useEffect(() => {
    // Get the user info
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/user/" +
        encodeURIComponent(encodedId ? encodedId : ""),
      withCredentials: true,
    })
      .then((res) => {
        const user = res.data.user;
        let usernames: any[] = [];
        user.clients.forEach((client: any) => {
          usernames = usernames.concat(client.usernames);
        });
        //console.log(user.isSMSVerified);
        if (!clientList) {
          setSelectedRole(user.role);
        }
        SetIsSmsVerified(user.isSMSVerified);
        if (clientList) {
          user.clients.map((userClient: any) => {
            const client = clientList.find(
              (element) => element.clientName === userClient.client.clientName,
            );
            userClient.clientName = client.clientName;
            userClient.userClientId = userClient.id;
            userClient.id = client.id;
            userClient.label = client.label;
            userClient.value = client.value;
            userClient.key = client.id;
            delete userClient.client;
            delete userClient.clientId;
            delete userClient.userId;
            return userClient;
          });
          setInitialClients(user.clients);
        }
        usernames.forEach((username) => {
          username.clientId = username.userClient.clientId;
          username.clientName = user.clients.find(
            (e: any) => e.id === username.clientId,
          )?.clientName;
          username.username = username.value;
          delete username.userClient;
          delete username.value;
        });
        setSelectedClients(user.clients);
        setRoleGroup(
          user.role &&
            user.role.roleName.startsWith("delchain") &&
            (!form.getFieldValue("userRoleGroup") ||
              form.getFieldValue("userRoleGroup").startsWith("delchain"))
            ? "delchain"
            : "client",
        );
        form.setFieldsValue({
          email: user.email,
          address_id: user.address_id,
          countryId: user.countryId,
          firstName: user.firstName,
          lastName: user.lastName,
          isActive: user.isActive,
          mobileNum: user.mobileNum,
          role:
            user.role && user.role.roleName.startsWith("delchain")
              ? user.roleId
              : undefined,
          addressLine1: user.address ? user.address.addressLine1 : "",
          addressLine2: user.address ? user.address.addressLine2 : "",
          city: user.address ? user.address.city : "",
          state: user.address ? user.address.state : "",
          postcode: user.address ? user.address.postcode : "",
          userRoleGroup:
            user.role &&
            user.role.roleName.startsWith("delchain") &&
            (!form.getFieldValue("userRoleGroup") ||
              form.getFieldValue("userRoleGroup").startsWith("delchain"))
              ? "delchain"
              : "client",
          country: user.countryId,
          clients: user.clients,
          mainLang: user.langId ? user.langId : undefined,
          telegramAcc: user.telegramUsername ? user.telegramUsername : "",
          officeNum: user.officeNum ? user.officeNum : "",
          serviceUsername: usernames ? usernames : undefined,
        });
        setTimeout(() => {
          form.setFieldValue(
            "serviceUsername",
            usernames ? usernames : undefined,
          );
        }, 1000);
        // setTimeout(() => {
        //   form.setFieldValue("serviceUsername", usernames ? usernames : undefined);
        // })
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/bo/users");
      });
    // Fetch the roles from DB
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/role",
      withCredentials: true,
    })
      .then((res) => {
        // console.log(res.data);
        setRoleList(res.data);
      })
      .catch(() => {
        console.error("Unable to fetch the role list");
      });

    // Fetch all the Countries
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/country",
      withCredentials: true,
    })
      .then((res) => {
        setCountryList(res.data);
      })
      .catch(() => {
        console.error("Unable to fetch the country list");
      });

    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/service",
      withCredentials: true,
    })
      .then((res) => {
        setServiceList(res.data.services);
      })
      .catch(() => {
        console.error("Unable to fetch the service list");
      });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/langs",
      withCredentials: true,
    }).then((res) => {
      setLangOptions(res.data.langs);
    });
  }, [form, encodedId, clientList, navigate]);

  useEffect(() => {
    if (roleGroup === "client" && !clientList) {
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/client",
        withCredentials: true,
        params: {
          sortField: "clientName",
          sortOrder: "asc",
        },
      }).then((res) => {
        for (const client of res.data.clients) {
          client.label = client.clientName;
          client.value = client.id;
        }
        setClientList(res.data.clients);
      });
    }
  }, [roleGroup, clientList]);
  useEffect(() => {
    if (
      clientList &&
      !clientMultiSelect &&
      // form.getFieldValue("clients") &&
      initialClients &&
      roleGroup &&
      roleGroup === "client"
    ) {
      setClientMultiSelect(
        <React.Fragment>
          <Form.List name="clients">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <React.Fragment key={key}>
                    <Row>
                      <Col sm={{ span: 8, offset: 1 }}>
                        <Form.Item
                          wrapperCol={{
                            sm: {
                              span: 24,
                              offset: 0,
                            },
                          }}
                          {...restField}
                          name={[name, "id"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing client",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Please select client"
                            filterOption={(input, option: any) => {
                              return option.children
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }}
                            onChange={(clientId) => {
                              if (form.getFieldValue("clients")[key]) {
                                form.getFieldValue("clients")[key].edited =
                                  true;
                                form.getFieldValue("clients")[key].clientName =
                                  clientList.find(
                                    (client) => client.id === clientId,
                                  ).clientName;
                              }
                            }}
                          >
                            {clientList?.map((client) => (
                              <Select.Option
                                key={client.clientName}
                                value={client.id}
                              >
                                {client.clientName}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={{ span: 8, offset: 1 }}>
                        <Form.Item
                          wrapperCol={{
                            sm: {
                              span: 24,
                              offset: 0,
                            },
                          }}
                          {...restField}
                          name={[name, "roleId"]}
                          rules={[{ required: true, message: "Missing Role" }]}
                        >
                          <Select
                            // disabled={!isDelchianAdmin}
                            placeholder="Please select role for this client"
                            onChange={() => {
                              if (form.getFieldValue("clients")[key]) {
                                form.getFieldValue("clients")[key].edited =
                                  true;
                              }
                            }}
                          >
                            {roleList.map((role: any) =>
                              role.roleName.startsWith(roleGroup) ? (
                                <Select.Option
                                  value={role["id"]}
                                  key={role["id"]}
                                >
                                  {role["roleName"]}
                                </Select.Option>
                              ) : undefined,
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={{ offset: 1, span: 2 }}>
                        <Popconfirm
                          title="Are you sure to delete this client?"
                          onConfirm={() => {
                            if (
                              form.getFieldValue("clients")[key].userClientId
                            ) {
                              checkCanDelete(
                                "client",
                                form.getFieldValue("clients")[key].userClientId,
                              )
                                ?.then((res) => {
                                  toDeleteUserClient.push(
                                    form.getFieldValue("clients")[key],
                                  );
                                  remove(name);
                                })
                                .catch((err) => {
                                  toast.error("Cannot delete this client.", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                });
                            } else {
                              remove(name);
                            }
                          }}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <MinusCircleOutlined onClick={() => {}} />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}
                <Form.Item
                  wrapperCol={{
                    sm: {
                      span: 12,
                      offset: 6,
                    },
                  }}
                >
                  <Button
                    type="dashed"
                    onClick={() => {
                      add({ newlyAdded: true, edited: false });
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add New Client
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Form.List name="serviceUsername">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <React.Fragment key={key}>
                    <Row>
                      <Col sm={{ span: 6, offset: 1 }}>
                        <Form.Item
                          wrapperCol={{
                            sm: {
                              span: 24,
                              offset: 0,
                            },
                          }}
                          {...restField}
                          name={[name, "clientId"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing client",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Client"
                            popupMatchSelectWidth={false}
                            filterOption={(input, option: any) => {
                              return option.children
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }}
                            onChange={() => {
                              const serviceUsername =
                                form.getFieldValue("serviceUsername");
                              if (serviceUsername[key]) {
                                serviceUsername[key].edited = true;
                              }
                              form.setFieldValue("serviceUsername", [
                                ...serviceUsername,
                              ]);
                            }}
                          >
                            {selectedClients?.map((client: any) => {
                              return client ? (
                                <Select.Option
                                  key={client.clientName}
                                  value={client.id}
                                >
                                  {client.clientName}
                                </Select.Option>
                              ) : undefined;
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={{ span: 6, offset: 1 }}>
                        <Form.Item
                          wrapperCol={{
                            sm: {
                              span: 24,
                              offset: 0,
                            },
                          }}
                          {...restField}
                          name={[name, "serviceId"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing service",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Service"
                            onChange={() => {
                              const serviceUsername =
                                form.getFieldValue("serviceUsername");
                              if (serviceUsername[key]) {
                                serviceUsername[key].edited = true;
                              }
                              form.setFieldValue("serviceUsername", [
                                ...serviceUsername,
                              ]);
                            }}
                          >
                            {serviceList?.map((service: any) =>
                              service ? (
                                <Select.Option
                                  value={service["id"]}
                                  key={service["id"]}
                                >
                                  {service["name"]}
                                </Select.Option>
                              ) : undefined,
                            )}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col sm={{ offset: 1, span: 6 }}>
                        <Form.Item
                          wrapperCol={{
                            sm: {
                              span: 24,
                              offset: 0,
                            },
                          }}
                          {...restField}
                          name={[name, "username"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing username",
                            },
                          ]}
                        >
                          <Input
                            placeholder="username"
                            onChange={() => {
                              const serviceUsername =
                                form.getFieldValue("serviceUsername");
                              if (serviceUsername[key]) {
                                serviceUsername[key].edited = true;
                              }
                              form.setFieldValue("serviceUsername", [
                                ...serviceUsername,
                              ]);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={{ offset: 1, span: 2 }}>
                        <Popconfirm
                          title={
                            <React.Fragment>
                              <p className="pop-confirm-p">
                                Are you sure to delete this username?
                              </p>
                              <p className="pop-confirm-p">
                                Please click "Update User" button before
                                removing related client.
                              </p>
                            </React.Fragment>
                          }
                          onConfirm={() => {
                            if (form.getFieldValue("serviceUsername")[key]) {
                              checkCanDelete(
                                "username",
                                form.getFieldValue("serviceUsername")[key].id,
                              )
                                ?.then(() => {
                                  toDeleteUsername.push(
                                    form.getFieldValue("serviceUsername")[key],
                                  );
                                  remove(name);
                                })
                                .catch((err) => {
                                  console.log(err);
                                  toast.error("Cannot delete this username.", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                });
                            } else {
                              remove(name);
                            }
                            // toDeleteUsername.push(
                            //   form.getFieldValue("serviceUsername")[key]
                            // );
                          }}
                          onCancel={() => {}}
                          okText="Yes"
                          cancelText="No"
                        >
                          <MinusCircleOutlined onClick={() => {}} />
                        </Popconfirm>
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}
                <Form.Item
                  wrapperCol={{
                    sm: {
                      span: 12,
                      offset: 6,
                    },
                  }}
                >
                  <Button
                    type="dashed"
                    onClick={() => {
                      add({ newlyAdded: true, edited: false });
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add New Username
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </React.Fragment>,
      );
    } else if (clientList && clientMultiSelect && roleGroup !== "client") {
      setClientMultiSelect(undefined);
    }
  }, [
    selectedClients,
    serviceList,
    clientList,
    clientMultiSelect,
    form,
    initialClients,
    roleGroup,
    isDelchianAdmin,
    roleList,
    toDeleteUserClient,
    toDeleteUsername,
  ]);

  const onFinish = (form: any) => {
    // console.log("Clients: ", toDeleteUserClient);
    // console.log("Username: ", toDeleteUsername);

    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/update",
      withCredentials: true,
      data: {
        encodedUserId: encodedId,
        username: form.username,
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        roleId: form.userRoleGroup === "delchain" ? form.role : undefined,
        countryId: form.country,
        mobileNum: form.mobileNum,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        postcode: form.postcode,
        isActive: form.isActive,
        officeNum: form.officeNum,
        langId: form.mainLang,
        telegramUsername: form.telegramAcc,
        clients:
          form.clients && form.userRoleGroup === "client"
            ? form.clients
            : undefined,
        toDeleteClients:
          toDeleteUserClient && form.userRoleGroup === "client"
            ? toDeleteUserClient.filter(
                (client: any) => client && !client.newlyAdded,
              )
            : undefined,
        addedServiceUsernames:
          form.serviceUsername && form.userRoleGroup === "client"
            ? form.serviceUsername.filter(
                (username: any) => username.newlyAdded,
              )
            : undefined,
        editedServiceUsernames:
          form.serviceUsername && form.userRoleGroup === "client"
            ? form.serviceUsername.filter(
                (username: any) => username.edited && !username.newlyAdded,
              )
            : undefined,
        toDeleteUsernames:
          toDeleteUsername && form.userRoleGroup === "client"
            ? toDeleteUsername.filter(
                (username: any) => username && !username.newlyAdded,
              )
            : undefined,
      },
    })
      .then(() => {
        navigate("/bo/confirmation", {
          state: {
            pageType: "User",
            createOrUpdate: "Update",
            displayName: form.email,
            editUrl:
              "/bo/user/update/" +
              encodeURIComponent(encodedId ? encodedId : ""),
          },
        });
        toast.success("User Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        toast.error("Unable to update the user", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    toast.error("Please fill required fields ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log("Failed:", errorInfo);
  };
  return (
    <React.Fragment>
      <Content>
        <Row>
          <Button
            onClick={() => {
              if (location.state) {
                navigate(location.state.from);
              } else {
                navigate("/bo/users");
              }
            }}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-chevron-left" as IconProp}
            />
          </Button>
        </Row>

        <Form
          form={form}
          {...formItemLayout}
          labelWrap
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="form-body"
          labelCol={{
            sm: {
              span: 7,
              offset: 1,
            },
          }}
          wrapperCol={{
            sm: {
              span: 14,
              offset: 1,
            },
          }}
          style={{
            padding: "1em",
            margin: "auto",
          }}
        >
          {/* <Row justify="center" align="middle">
            <h2>UPDATE USER</h2>
          </Row> */}
          <Row>
            <Col sm={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input user's email",
                  },
                ]}
              >
                <Input disabled={!isDelchianAdmin} />
              </Form.Item>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input user's first name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input user's last name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Address Line 1"
                name="addressLine1"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please input user's address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Address Line 2"
                name="addressLine2"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Postcode"
                name="postcode"
                rules={[{ required: false }]}
              >
                <Input placeholder="postcode" />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please input user's city!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: false,
                    message: "Please input user's state!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please select user's Country",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countryList.map((country) => (
                    <Select.Option
                      value={country["id"]}
                      key={country["id"]}
                      id={country["id"]}
                    >
                      {country["nicename"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name="mobileNum"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please enter user's Phone number",
                  },
                ]}
              >
                <PhoneInput
                  flags={flags}
                  placeholder="Enter phone number"
                  value={mobileNum}
                  onChange={(e: any) => setMobileNum(e)}
                />
              </Form.Item>
              <Form.Item label="Office Number" name="officeNum">
                <PhoneInput
                  flags={flags}
                  placeholder="Enter Office phone number"
                  value={officeNum}
                  onChange={(e: any) => setOfficeNum(e)}
                />
              </Form.Item>
              <Form.Item label="Main Language" name="mainLang">
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Please select main language"
                >
                  {langOptions?.map((lang) => (
                    <Select.Option
                      value={lang["id"]}
                      key={lang["name"]}
                      id={lang["id"]}
                    >
                      {lang["name"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Telegram Account(e.g. @username)"
                name="telegramAcc"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
              <Form.Item
                label="User Role Group"
                name="userRoleGroup"
                rules={[
                  {
                    required: true,
                    message: "Missing Role Group",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setRoleGroup(e.target.value);
                  }}
                >
                  <Radio value="delchain">Delchain</Radio>
                  <Radio value="client">Client</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Role"
                name="role"
                hidden={roleGroup !== "delchain"}
                rules={[
                  {
                    required: roleGroup === "delchain",
                    message: "Please select user's role!",
                  },
                ]}
              >
                <Select
                  // disabled={!isDelchianAdmin}
                  onChange={(roleId) => {
                    const role = roleList.filter((obj) => {
                      return obj["id"] === roleId;
                    });
                    setSelectedRole(role[0]);
                  }}
                >
                  {roleList.map((role: any) =>
                    role.roleName.startsWith(roleGroup) ? (
                      <Select.Option value={role["id"]} key={role["id"]}>
                        {role["roleName"]}
                      </Select.Option>
                    ) : undefined,
                  )}
                </Select>
              </Form.Item>

              {clientMultiSelect}
              <Form.Item
                className="form-control-row-2"
                label="Is Active?"
                name="isActive"
                rules={[{ required: true }]}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Row className="form-control-row-2">
                <Col sm={{ span: 12, offset: 6 }}>
                  <Button
                    disabled={isSMSVerified}
                    onClick={resendTmpPwd}
                    className="primary-button"
                  >
                    Resend Temporary Password
                  </Button>
                </Col>
                <Col
                  className="form-control-row-2"
                  sm={{ span: 12, offset: 6 }}
                >
                  <Button
                    onClick={show2FaResetModal}
                    className="primary-button"
                  >
                    Reset 2FA
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col sm={{ span: 12, order: 2 }} xs={{ span: 24, order: 3 }}>
              <Form.Item
                className="form-control-col-3"
                label="Is Active?"
                name="isActive"
                rules={[{ required: true }]}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Button
                disabled={isSMSVerified}
                onClick={resendTmpPwd}
                className="form-control-col-3 primary-button"
              >
                Resend Temporary Password
              </Button>
              <Button
                onClick={show2FaResetModal}
                className="form-control-col-3 primary-button"
              >
                Reset 2FA
              </Button>
            </Col>
          </Row>
          <Form.Item {...tailFormItemLayout}>
            <Row justify="center">
              <Col>
                <Button
                  htmlType="submit"
                  className="submit-button primary-button"
                >
                  Update User
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Modal
            getContainer={false}
            open={is2FaResetModalOpen}
            onOk={handle2FaResetModalOk}
            onCancel={handle2FaResetModalCancel}
          >
            <p>Are you sure you want to reset the 2FA for user {user}?</p>
          </Modal>
        </Form>
      </Content>
    </React.Fragment>
  );
}
export default UpdateUser;
