import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  Row,
  Space,
  Table,
  Form,
  Spin,
  Modal,
  Layout,
  Select,
  Col,
  Divider,
  InputRef,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function MetadataSettings() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<any>();
  const [dspTermMetadatas, setDspTermMetadatas] = useState<any[]>([]);
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [deletedRows, setDeletedRows] = useState<any[]>([]);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [selectedDspTermKey, setSelectedDspTermKey] = useState<any>();
  const [selectedDspTermValue, setSelectedDspTermValue] = useState<any>();
  const openDeleteConfirmModal = (id: any) => {
    setIsModalOpen(true);
    setSelectedRowId(id);
  };
  const [dspTermKeyOptions, setDspTermKeyOptions] = useState<any[]>([]);
  const [dspTermValueOptions, setDspTermsValueOptions] = useState<any[]>([]);
  const [metadataKeyOptions, setMetaDataKeyOptions] = useState<any[]>([]);
  const fetchDspTerms = useCallback(() => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/dsp-terms/",
      withCredentials: true,
    })
      .then((res) => {
        const options: any[] = [];
        const dspTermKeys = [
          ...new Set(res.data.dspTerms.map((t: any) => t.key)),
        ];
        const keyOptions = dspTermKeys.map((t: any, index: number) => {
          return { key: index, label: t, value: t };
        });
        if (selectedDspTermKey) {
          res.data.dspTerms
            .filter((t: any) => t.key === selectedDspTermKey)
            .forEach((e: any) => {
              options.push({
                key: e.id,
                label: `${e.value}`,
                value: e.id,
              });
            });
        } else {
          res.data.dspTerms.forEach((e: any) => {
            options.push({
              key: e.id,
              label: `${e.value}`,
              value: e.id,
            });
          });
        }

        setDspTermKeyOptions(keyOptions);
        setDspTermsValueOptions(options);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, [selectedDspTermKey]);

  const [keyName, setKeyName] = useState("");
  const onKeyNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyName(event.target.value);
  };
  const inputRef = useRef<InputRef>(null);
  const addItem = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    e.preventDefault();
    setMetaDataKeyOptions([
      ...metadataKeyOptions,
      { key: keyName, value: keyName, label: keyName },
    ]);
    setKeyName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  useEffect(() => {
    fetchDspTerms();
  }, [fetchDspTerms]);

  const deleteRow = (id: any) => {
    setOnFinishLoad(false);
    const newDspTermMetadata = [...dspTermMetadatas];
    const childMenuItems = newDspTermMetadata.filter(
      (row) => row.parentId === id,
    );

    const newDeltedRows = [...deletedRows];

    const rowToDelete = newDspTermMetadata.find((setting) => setting.id === id);
    if (!rowToDelete.newRow) {
      newDeltedRows.push(rowToDelete);
    }
    for (const childRow of childMenuItems) {
      if (!childRow.newRow) {
        newDeltedRows.push(childRow);
      }
      newDspTermMetadata.splice(newDspTermMetadata.indexOf(childRow), 1);
    }
    newDspTermMetadata.splice(newDspTermMetadata.indexOf(rowToDelete), 1);
    setDeletedRows(newDeltedRows);
    setDspTermMetadatas(newDspTermMetadata);
    setOnFinishLoad(true);
  };

  const updateDspTermMetadataField = (
    id: number,
    fieldName: string,
    fieldValue: string,
  ) => {
    const newDspTermMetadata = [...dspTermMetadatas];
    const row = newDspTermMetadata.filter((obj) => obj.id === id)[0];
    row[fieldName] = fieldValue;
    row.edited = true;
    setDspTermMetadatas(dspTermMetadatas);
  };

  const submit = () => {
    const editedRows = dspTermMetadatas.filter(
      (e) => e.edited === true && !e.newRow,
    );
    const newRows = dspTermMetadatas.filter((e) => e.newRow);

    setOnFinishLoad(false);
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/dsp-terms/metadata",
      withCredentials: true,
      data: {
        dspTermId: selectedDspTermValue,
        editedRows: editedRows,
        newRows: newRows,
        deletedRows: deletedRows,
      },
    })
      .then(() => {
        toast.success("Business activity settings updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
        fetchData();
      });
  };

  const insertNewRow = () => {
    setOnFinishLoad(false);
    const newDspTermMetadata = [...dspTermMetadatas];
    const newRow = {
      id: `temp_${newDspTermMetadata.filter((row) => row.newRow).length + 1}`,
      name: "",
      newRow: true,
    };
    newDspTermMetadata.unshift(newRow);
    setDspTermMetadatas(newDspTermMetadata);
    setOnFinishLoad(true);
  };

  const fetchData = useCallback(() => {
    if (!selectedDspTermValue) {
      return;
    }
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/dsp-terms/metadata",
      params: {
        dspTermId: selectedDspTermValue,
      },
      withCredentials: true,
    })
      .then((res) => {
        const metadataRows = res.data.metadata
          .filter((t: any) => t.dspTermId === selectedDspTermValue)
          .map((t: any) => ({ ...t, edited: false }));
        setCanEdit(res.data.canEdit);
        setMetaDataKeyOptions(res.data.metadataKeys);
        setDspTermMetadatas(metadataRows);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, [selectedDspTermValue]);
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      width: "5%",
      editable: false,
      render: (text: any) => <strong>{text}</strong>,
    },
    {
      title: "Metadata Key",
      dataIndex: "key",
      width: "10%",
      editable: false,
      render: (_: any, { id, key }: any) => (
        <Select
          options={metadataKeyOptions}
          defaultValue={key}
          style={{ minWidth: "150px" }}
          popupMatchSelectWidth={false}
          onChange={(e: any) => {
            updateDspTermMetadataField(id, "key", e);
          }}
          placeholder="Metadata Key"
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: "8px 0" }} />
              <Space style={{ padding: "0 8px 4px" }}>
                <Input
                  placeholder="DSP Term Metadata Key"
                  ref={inputRef}
                  value={keyName}
                  onChange={onKeyNameChange}
                  onKeyDown={(e) => e.stopPropagation()}
                />
                <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                  Add Key
                </Button>
              </Space>
            </>
          )}
        />
      ),
    },
    {
      title: "Metadata Value",
      dataIndex: "value",
      width: "10%",
      editable: false,
      render: (text: any, { id }: any) => (
        <Input
          defaultValue={text}
          onChange={(e: any) => {
            updateDspTermMetadataField(id, "value", e.target.value);
          }}
        />
      ),
    },
    {
      title: "sort",
      dataIndex: "sort",
      width: "10%",
      editable: false,
      render: (text: any, { id }: any) => (
        <Input
          defaultValue={text}
          onChange={(e: any) => {
            updateDspTermMetadataField(id, "sort", e.target.value);
          }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      width: "3%",
      editable: false,
      render: (_: any, { id }: any) => (
        <Button
          disabled={!canEdit}
          onClick={() => {
            openDeleteConfirmModal(id);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return onFinshiLoad ? (
    <Content>
      <Form component={false}>
        <Row>
          <Col span={12}>
            {" "}
            <Row className="dcl-toggled-content dcl-toggled-content-filter">
              <Col>
                <Select
                  placeholder="DSP Term Key"
                  options={dspTermKeyOptions}
                  defaultValue={selectedDspTermKey}
                  popupMatchSelectWidth={false}
                  style={{ minWidth: "150px" }}
                  onChange={(e) => {
                    setSelectedDspTermValue(undefined);
                    setSelectedDspTermKey(e);
                  }}
                />
              </Col>
              <Col offset={1}>
                <Select
                  disabled={!selectedDspTermKey}
                  placeholder="DSP Term Value"
                  options={dspTermValueOptions}
                  defaultValue={selectedDspTermValue}
                  popupMatchSelectWidth={false}
                  style={{ minWidth: "150px" }}
                  onChange={(e) => {
                    setSelectedDspTermValue(e);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row
              justify={"end"}
              style={{ marginTop: "10px", marginRight: "10px" }}
            >
              <Button
                type="primary"
                onClick={() => {
                  insertNewRow();
                }}
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-plus" as IconProp}
                  className="white-plus"
                />
                &nbsp; New Metadata
              </Button>
            </Row>
          </Col>
        </Row>

        <Table
          scroll={{ x: 1000 }}
          columns={columns}
          rowKey={"id"}
          dataSource={dspTermMetadatas}
          sticky
        />
      </Form>
      <Row justify="center">
        <Space>
          <Button
            disabled={!selectedDspTermValue}
            onClick={() => {
              fetchData();
            }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            disabled={!selectedDspTermValue}
            onClick={() => {
              submit();
            }}
          >
            Save
          </Button>
        </Space>
      </Row>
      <Modal
        getContainer={false}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onOk={() => {
          deleteRow(selectedRowId);
          setIsModalOpen(false);
        }}
      >
        <p>
          Are you sure you want to delete the DSP Term Metadata's row{" "}
          {selectedRowId}?
        </p>
        <p>
          Click the Ok button and then click the Save button at the end of the
          page to save the updates.
        </p>
      </Modal>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
