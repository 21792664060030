// import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Slider,
  Spin,
  Switch,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { formItemLayout, tailFormItemLayout } from "../UserPages/CreateUser";
import ClientInitialBalanceList from "./ClientInitialBalanceList";

function UpdateClient() {
  const navigate = useNavigate();
  const location = useLocation();
  const { clientId } = useParams();
  const [form] = Form.useForm();
  const [countryList, setCountryList] = useState([]);
  const [clientTypeList, setClientTypeList] = useState([]);
  const [businessActivityList, setBusinessActivityList] = useState([]);
  const [deltecRMList, setDeltecRMList] = useState([]);
  const [custodyFeeTypeId, setCustodyFeeTypeId] = useState<number>();
  const [stakingFeeTypeId, setStakingFeeTypeId] = useState<number>();
  const [tradingFeeTypeId, setTradingFeeTypeId] = useState<number>();
  const [custodyeePolicyList, setCustodyFeePolicyList] = useState<any[]>();
  const [stakingFeePolicyList, setStakingFeePolicyList] = useState<any[]>();
  const [tradingFeePolicyList, setTradingFeePolicyList] = useState<any[]>();

  const [initialBalanceFetched, setInitialBalancFetched] =
    useState<boolean>(false);
  const [canAssignInitialBalance, setCanAssignInitialBalance] =
    useState<boolean>(false);
  const [initialBalances, setInititalBalance] = useState<any[]>([]);

  const fetchFeePolicyList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "custody" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setCustodyFeePolicyList(res.data.feePolicyList);
      setCustodyFeeTypeId(res.data.feePolicyTypeId);
    });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "staking" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setStakingFeePolicyList(res.data.feePolicyList);
      setStakingFeeTypeId(res.data.feePolicyTypeId);
    });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "trading" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setTradingFeePolicyList(res.data.feePolicyList);
      setTradingFeeTypeId(res.data.feePolicyTypeId);
    });
    // .finally(() => {
    //   setOnFinishLoad(true);
    // });
  }, []);

  const fetchPolicyAssigned = useCallback(() => {
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy-assgined",
      data: {
        id: clientId,
        type: "client",
      },
      withCredentials: true,
    }).then((res) => {
      const custodyPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType && t.feePolicyType.name.toLowerCase() === "custody",
      );
      if (custodyPolicy) {
        form.setFieldValue(
          "custodyFeePolicyId",
          Number(custodyPolicy.feePolicyId),
        );
        form.setFieldValue(
          "custodyFeePolicyStartDate",
          dayjs(custodyPolicy.startDate),
        );
      }

      const stakingPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType && t.feePolicyType.name.toLowerCase() === "staking",
      );
      if (stakingPolicy) {
        form.setFieldValue(
          "stakingFeePolicyId",
          Number(stakingPolicy.feePolicyId),
        );
        form.setFieldValue(
          "stakingFeePolicyStartDate",
          dayjs(custodyPolicy.startDate),
        );
      }
      const tradingPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType && t.feePolicyType.name.toLowerCase() === "trading",
      );
      if (tradingPolicy) {
        form.setFieldValue(
          "tradingFeePolicyId",
          Number(tradingPolicy.feePolicyId),
        );
        form.setFieldValue(
          "tradingFeePolicyStartDate",
          dayjs(tradingPolicy.startDate),
        );
      }
    });
  }, [clientId, form]);

  const fetchCanAssignInitialBalace = useCallback((clientId: string) => {
    setInitialBalancFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/initial-balance",
      params: {
        encodedClientId: clientId,
      },
      withCredentials: true,
    })
      .then((res) => {
        setCanAssignInitialBalance(res.data.canAssignInitialBalance);
        setInititalBalance(res.data.clientInitialBalances);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setInitialBalancFetched(true);
      });
  }, []);
  useEffect(() => {
    if (clientId) fetchCanAssignInitialBalace(clientId);
  }, [clientId, fetchCanAssignInitialBalace]);

  useEffect(() => {
    fetchFeePolicyList();
    fetchPolicyAssigned();
  }, [fetchFeePolicyList, fetchPolicyAssigned]);

  const onFinish = (form: any) => {
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/update",
      withCredentials: true,
      data: {
        clientId: clientId,
        clientName: form.clientName,
        email: form.email,
        countryId: form.country,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        postcode: form.postcode,
        clientTypeId: form.clientType,
        businessActivityDspTermId: form.businessActivityDspTermId,
        rmDspTermId: form.rmDspTermId,
        isActive: form.isActive,
        telegramGroup: form.telegramGroup,
        clientRanking: form.clientRanking,
        clientComment: form.clientComment,
        deltecAccount: form.deltecAccount,
        delchainAccount: form.delchainAccount,
        isBot: form.isBot,
        toBackup: form.toBackup,
        custodyFeePolicyId: form.custodyFeePolicyId,
        custodyFeePolicyTypeId: custodyFeeTypeId,
        custodyFeePolicyStartDate: form.custodyFeePolicyStartDate,
        stakingFeePolicyTypeId: stakingFeeTypeId,
        stakingFeePolicyId: form.stakingFeePolicyId,
        stakingFeePolicyStartDate: form.stakingFeePolicyStartDate,
        tradingFeePolicyTypeId: tradingFeeTypeId,
        tradingFeePolicyId: form.tradingFeePolicyId,
        tradingFeePolicyStartDate: form.tradingFeePolicyStartDate,
      },
    })
      .then(() => {
        navigate("/bo/confirmation", {
          state: {
            pageType: "Client",
            createOrUpdate: "Update",
            displayName: form.clientName,
            editUrl:
              "/bo/client/update/" +
              encodeURIComponent(clientId ? clientId : ""),
          },
        });
        toast.success("Client Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        toast.error(err.response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    toast.error("Please fill required fields ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client",
      params: {
        encodedClientId: clientId ? clientId : "",
      },
      withCredentials: true,
    })
      .then((res) => {
        const client = res.data.client;
        // console.log(client);
        form.setFieldsValue({
          clientId: clientId,
          clientName: client.clientName,
          email: client.email,
          isActive: client.isActive,
          addressLine1: client.address ? client.address.addressLine1 : "",
          addressLine2: client.address ? client.address.addressLine2 : "",
          city: client.address ? client.address.city : "",
          state: client.address ? client.address.state : "",
          postcode: client.address ? client.address.postcode : "",
          country: client.address ? client.address.countryId : "",
          clientType: client.clientTypeId,
          businessActivityDspTermId: client.businessActivityDspTermId,
          rmDspTermId: client.rmDspTermId,
          telegramGroup: client.telegramGroup,
          clientRanking: client.ranking,
          clientComment: client.comment,
          deltecAccount: client.deltecAccount,
          delchainAccount: client.delchainAccount,
          isBot: client.isBot,
          toBackup: client.toBackup,
        });
      })
      .catch(() => {
        toast.error("This record does not exist");
        navigate("/bo/clients");
      });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/country",
      withCredentials: true,
    })
      .then((res) => {
        setCountryList(res.data);
      })
      .catch(() => {
        console.error("Unable to fetch the country list");
      });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-type",
      withCredentials: true,
    })
      .then((res) => {
        setClientTypeList(res.data);
      })
      .catch(() => {
        console.error("Unable to fetch the Client Type list");
      });
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/dsp-terms/business-activity",
      withCredentials: true,
    })
      .then((res) => {
        res.data.businessActivities.forEach((t: any) => (t.name = t.value));
        setBusinessActivityList(res.data.businessActivities);
      })
      .catch(() => {
        console.error("Unable to fetch the Business Activities list");
      });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/dsp-terms/deltec-rms",
      withCredentials: true,
    })
      .then((res) => {
        res.data.deltecRMs.forEach((t: any) => (t.name = t.value));
        setDeltecRMList(res.data.deltecRMs);
      })
      .catch(() => {
        toast.error("Unable to fetch the Deltec RMs list", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, [clientId, form, navigate]);

  return (
    <React.Fragment>
      <Content>
        <Row>
          <Button
            onClick={() => {
              navigate(location.state ? location.state.from : "/bo/clients");
            }}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-chevron-left" as IconProp}
            />
          </Button>
        </Row>
        <Form
          form={form}
          {...formItemLayout}
          labelWrap
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="form-body"
          labelCol={{
            sm: {
              span: 4,
              offset: 4,
            },
          }}
          wrapperCol={{
            sm: {
              span: 10,
              offset: 1,
            },
          }}
          style={{
            padding: "1em",
            margin: "auto",
          }}
        >
          <Row>
            <Col sm={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }}>
              <Form.Item
                label="Client Name"
                name="clientName"
                rules={[
                  {
                    required: true,
                    message: "Please input Client's Name!",
                  },
                ]}
              >
                <Input placeholder="Client Name" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: false, // Set to false for launching
                    message: "Please input Client's email",
                  },
                ]}
              >
                <Input placeholder="Client's Email" />
              </Form.Item>

              <Form.Item
                label="Address Line 1"
                name="addressLine1"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please input client's address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Address Line 2"
                name="addressLine2"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Postcode"
                name="postcode"
                rules={[{ required: false }]}
              >
                <Input placeholder="postcode" />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please input client's city!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: false,
                    message: "Please input client's state!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please select user's Country",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countryList.map((country) => (
                    <Select.Option
                      value={country["id"]}
                      key={country["id"]}
                      id={country["id"]}
                    >
                      {country["nicename"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Telegram Group (e.g. -123456789)"
                name="telegramGroup"
                rules={[
                  {
                    required: false,
                    message: "Please select user's Country",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="To Backup"
                name="toBackup"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Bot is in Group"
                name="isBot"
                valuePropName="checked"
              >
                <Switch
                  style={{
                    display: "inline-block",
                  }}
                />
              </Form.Item>

              <Form.Item
                label="Client Type"
                name="clientType"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please select client's type",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {clientTypeList.map((clientType) => (
                    <Select.Option
                      value={clientType["id"]}
                      key={clientType["id"]}
                      id={clientType["id"]}
                    >
                      {clientType["name"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Business Activity"
                name="businessActivityDspTermId"
                rules={[
                  {
                    required: false,
                    message: "Please select client's business activity",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {businessActivityList.map((businessActivity) => (
                    <Select.Option
                      value={businessActivity["id"]}
                      key={businessActivity["id"]}
                      id={businessActivity["id"]}
                    >
                      {businessActivity["name"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Client Ranking" name="clientRanking">
                <Slider
                  min={0}
                  max={10}
                  marks={{
                    0: 0,
                    2: 2,
                    4: 4,
                    6: 6,
                    8: 8,
                    10: 10,
                  }}
                />
              </Form.Item>
              <Form.Item label="Comment" name="clientComment">
                <TextArea rows={3} />
              </Form.Item>
              <Form.Item
                label="Is Active?"
                name="isActive"
                rules={[{ required: true }]}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col sm={{ span: 12, order: 2 }} xs={{ span: 24, order: 2 }}>
              <Form.Item label="Deltec Account" name="deltecAccount">
                <Input placeholder="Deltec Account" />
              </Form.Item>
              <Form.Item
                label="Delchain Account"
                name="delchainAccount"
                rules={[
                  {
                    required: false,
                    message: "Please input client's Delchain account",
                  },
                ]}
              >
                <Input placeholder="Delchain Account" />
              </Form.Item>

              <Form.Item label="Deltec RM" name="rmDspTermId">
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  allowClear
                  placeholder={"Deltec RM"}
                >
                  {deltecRMList.map((deltecRM) => (
                    <Select.Option
                      value={deltecRM["id"]}
                      key={deltecRM["id"]}
                      id={deltecRM["id"]}
                    >
                      {deltecRM["name"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Initial Balances</Divider>
          <Row>
            <Col lg={24} md={24} sm={24} xs={24}>
              {initialBalanceFetched ? (
                <ClientInitialBalanceList
                  clientId={clientId}
                  initialBalances={initialBalances}
                  canEdit={canAssignInitialBalance}
                  fetchCanAssignInitialBalace={fetchCanAssignInitialBalace}
                />
              ) : (
                <Row justify={"center"}>
                  <Spin />
                </Row>
              )}
            </Col>
          </Row>
          <Divider orientation="left">Fee Policy</Divider>
          <Row>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Card title="Custody">
                <Form.Item label="Custody Fee Policy" name="custodyFeePolicyId">
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={custodyeePolicyList}
                    placeholder="Custody Fee Policy"
                  />
                </Form.Item>
                <Form.Item
                  label="Custody Fee Policy Start Date"
                  name="custodyFeePolicyStartDate"
                >
                  <DatePicker />
                </Form.Item>
              </Card>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Card title="Staking">
                <Form.Item label="Staking Fee Policy" name="stakingFeePolicyId">
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={stakingFeePolicyList}
                    placeholder="Staking Fee Policy"
                  />
                </Form.Item>
                <Form.Item
                  label="Staking Fee Policy Start Date"
                  name="stakingFeePolicyStartDate"
                >
                  <DatePicker />
                </Form.Item>
              </Card>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Card title="Trading">
                <Form.Item label="Trading Fee Policy" name="tradingFeePolicyId">
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={tradingFeePolicyList}
                    placeholder="Ttaking Fee Policy"
                  />
                </Form.Item>
                <Form.Item
                  label="Ttaking Fee Policy Start Date"
                  name="tradingFeePolicyStartDate"
                >
                  <DatePicker />
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <Form.Item {...tailFormItemLayout}>
            <Button htmlType="submit" className="submit-button primary-button">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </React.Fragment>
  );
}
export default UpdateClient;
