import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
interface AcitveClientChartProps {
  data: any;
}
const ActiveClientBarChart: React.FC<AcitveClientChartProps> = ({
  data,
}: any) => {
  const [containerWidth, setContainerWidth] = useState<number>(445);
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!data || data.length === 0) return;
    if (!svgRef.current) return;
    const container = svgRef.current?.parentElement;

    const margin = { top: 60, right: 15, bottom: 60, left: 40 };
    const width =
      container?.clientWidth && container?.clientWidth < containerWidth
        ? container?.clientWidth - margin.left - margin.right
        : containerWidth - margin.left - margin.right;
    const height = 445 - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const months = data.map((d: any) => d.month);

    const color = d3
      .scaleOrdinal()
      .domain(["sumBefore", "count"])
      .range(["#19bdc2", "orange"]);

    const stack = d3.stack().keys(["sumBefore", "count"]);

    // Calculate the 'sumBefore' values correctly
    for (let i = 0; i < data.length; i++) {
      if (i > 0) {
        data[i].sumBefore = data[i - 1].sumBefore + data[i - 1].count;
      }
    }

    const stackedData = stack(data);

    const x = d3.scaleBand().domain(months).range([0, width]).padding(0.1);
    const y = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(stackedData, (d) => d3.max(d, (entry) => entry[1])) as number,
      ])
      .nice()
      .range([height, 0]);

    const groups = svg
      .selectAll(".group")
      .data(stackedData)
      .enter()
      .append("g")
      .attr("class", "group")
      .style("fill", (d) => color(d.key) as any);

    groups
      .selectAll(".bar")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.data.month as any) || 0)
      .attr("y", (d) => y(d[1]) || 0)
      .attr("height", (d) => y(d[0]) - y(d[1]) || 0)
      .attr("width", x.bandwidth());

    svg
      .selectAll(".stack-label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "stack-label")
      .attr(
        "x",
        (d: any) => (x(d.month as any) as number) + x.bandwidth() / 2 || 0,
      )
      .attr("y", (d: any) => y(d.sumBefore + d.count) || 0)
      .attr("dy", "0em")
      .attr("text-anchor", "middle")
      .style("fill", "black")
      .text((d: any) => `${d.count + d.sumBefore}`);

    // Add "count" label only on top of the orange bars
    svg
      .selectAll(".count-label")
      .data(data.filter((d: any) => d.count > 0))
      .enter()
      .append("text")
      .attr("class", "count-label")
      .attr(
        "x",
        (d: any) => (x(d.month as any) as number) + x.bandwidth() / 2 || 0,
      )
      .attr("y", (d: any) => y(d.sumBefore + d.count) || 0)
      .attr("dy", "1em")
      .attr("text-anchor", "middle")
      .style("fill", "black")
      .text((d: any) => `${d.count > 0 ? d.count : ""}`);

    // Add a legend for "Active Clients" at the top-left
    svg
      .append("text")
      .attr("x", -25)
      .attr("y", -20)
      .attr("text-anchor", "start")
      .style("font-weight", "bold")
      .text("Active Clients");

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text") // Select all x-axis labels
      .attr("transform", "rotate(-45)") // Rotate the labels by -45 degrees
      .style("text-anchor", "end"); // Adjust the text anchor for proper alignment

    svg
      .append("g")
      .attr("class", "y-axis")
      .call(d3.axisLeft(y).tickFormat((d) => d.toString()));

    // Add a legend for color interpretation
    const legend = svg
      .append("g")
      .attr("class", "legend")
      .attr("transform", `translate(${width + 20}, 0)`);

    const legendColors = ["#19bdc2", "orange"];
    const legendLabels = ["Total active clients", "Newly activated clients"];

    legend
      .selectAll(".legend-item")
      .data(legendLabels)
      .enter()
      .append("g")
      .attr("class", "legend-item")
      .attr("transform", (d, i) => `translate(0, ${i * 20})`);

    legend
      .selectAll(".legend-item")
      .append("rect")
      .attr("x", -185)
      .attr("y", -50)
      .attr("width", 10)
      .attr("height", 10)
      .style("fill", (d, i) => legendColors[i]);

    legend
      .selectAll(".legend-item")
      .append("text")
      .attr("x", -170)
      .attr("y", -40)
      .text((d: any) => d);
    return () => {
      svg.selectAll("*").remove();
    };
  }, [data, containerWidth]);

  // Handle window resize to update container width
  useEffect(() => {
    let resizeTimer: any;
    const margin = { top: 5, right: 15, bottom: 5, left: 5 };

    const handleResize = () => {
      // Clear the previous timer (if any)
      clearTimeout(resizeTimer);

      // Set a new timer to delay the resizing logic
      resizeTimer = setTimeout(() => {
        const container = svgRef.current?.parentElement;
        if (container) {
          setContainerWidth(container.clientWidth - margin.left - margin.right);
        }
      }, 130); // Adjust the delay time (in milliseconds) as needed
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <svg ref={svgRef} width={containerWidth}></svg>;
};

export default ActiveClientBarChart;
