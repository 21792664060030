import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Row } from "antd";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../assets/DLC_Logo_RVB-white-400.png";
import "../../assets/scss/login.scss";
import { UserContext } from "../Context/userContext";
import InputComponent from "../Input";
import OTPInput from "../OTPInput/OTPInput";
// import SmsVerification from "../SMSVerification/SmsVerification";
import TwofaActivationContainer from "../TwoFA/TwoFA-container";
import ChangePassword from "./ChangePwd";

function Login() {
  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const context = useContext(UserContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [captcha, setCaptcha] = useState<string>("");
  const [is2FaModalVisible, setIs2FaModalVisible] = useState<boolean>(false);
  const [twoFA, setTwoFA] = useState<string>("");
  const loadDate = new Date();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  // const [access_token, setAccessToken] = useState<string>("");
  const [isTempPwd, setIsTempPwd] = useState<boolean>(true);
  const [isTwoFaEnabled, setIsTwoFaEnabled] = useState<boolean>(false);
  const [isSmsVerified, setIsSmsVerified] = useState<boolean>(false);
  const [isPwdModalVisible, setIsPwdModalVisible] = useState<boolean>(false);
  const [isTwoFaSet, setIsTwoFaSet] = useState<boolean>(true);
  const [initialFrom, setInitialFrom] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    if (location && location.state && location.state.initialPath) {
      setInitialFrom(location.state.initialPath);
    }
  }, [location]);
  // User Login Handler
  const handleSubmit = () => {
    if (!isValidEmail(email)) {
      toast.error("Invalid Email Format", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!password) {
      toast.error("Empty Password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const currentDate = new Date();
    //console.log(currentDate.getTime() - loadDate.getTime());
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL
        ? process.env.REACT_APP_AWS_BACKEND_URL + "/authentication/log-in"
        : "/authentication/log-in", // Replace with ECS Address after BE deployed to AWS
      data: {
        email: email.trim(),
        password: password.trim(),
        captcha: captcha,
        loadDate: loadDate.getTime(),
        clickDate: currentDate.getTime(),
      },
      withCredentials: true,
    })
      .then((response) => {
        setIsTempPwd(response.data["isTempPwd"]);
        setIsTwoFaEnabled(response.data["is2FaEnabled"]);
        setIsSmsVerified(true); // Disabled SMS Function for now
        setIsPwdModalVisible(
          response.data["isTempPwd"] || !response.data["is2FaEnabled"],

          // || !response.data["isSmsVerified"]
        );
        setIs2FaModalVisible(
          !response.data["isTempPwd"] && response.data["is2FaEnabled"],
          // &&
          // response.data["isSmsVerified"]
        );
        // if (!response.data["isSmsVerified"]) {
        //   axios({
        //     method: "POST",
        //     url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/sendSmsOtp",
        //     data: { email: email },
        //   }).then((res) => console.log(res));
        // }
      })
      .catch((error) => {
        toast.error("Failed to login", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        //alert(error.response.data.error);
      });
  };

  // 2FA Authentication handler
  const handle2FaModalOk = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/2fa/authenticate",
      data: {
        email: email.trim(),
        twoFactorAuthenticationCode: twoFA,
        isLogin: isTwoFaSet,
      },
      withCredentials: true,
    })
      .then((response) => {
        //console.log("Successs");
        const userJSON = response.data;
        if (
          userJSON["isActive"] &&
          !userJSON["isTempPwd"] &&
          userJSON["is2FaEnabled"]
        ) {
          //sessionStorage.setItem("user", userJSON);
          context?.setUser({
            id: userJSON["id"],
            email: userJSON["email"],
            roleId: userJSON["roleId"],
            capabilities: userJSON["userCapabilities"],
            fullname: userJSON["fullname"],
            clients: userJSON["clients"],
            selectedClient: userJSON["clients"][0],
            roleName: userJSON["roleName"],
          });
          //console.log("Is active and Is not Temp PWD and 2FA is enabled");
          setIsUserLoggedIn(true);
          setIs2FaModalVisible(false);
        }
      })
      .catch((error) => {
        toast.error("2FA Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handle2FaModalCancel = () => {
    // axios({
    //   method: "Post",
    //   url: process.env.REACT_APP_AWS_BACKEND_URL + "/authentication/log-out",
    //   withCredentials: true,
    // });
    setIs2FaModalVisible(false);
  };

  const handlePwdModalCancel = () => {
    setIsPwdModalVisible(false);
  };

  let pwdModal;
  if (isPwdModalVisible) {
    if (isTempPwd) {
      pwdModal = (
        <Modal
          open={isPwdModalVisible}
          onCancel={handlePwdModalCancel}
          footer={null}
          styles={{
            body: {
              backgroundColor: "#0e3654",
              minHeight: "200px",
            },
          }}
        >
          <ChangePassword
            email={email}
            oldPassword={password}
            changePwdStatus={(isTemp: boolean) => {
              setIsTempPwd(isTemp);
              if (!isTemp && isTwoFaEnabled && isSmsVerified) {
                setIs2FaModalVisible(true);
              }
            }}
          />
        </Modal>
      );
    } else if (!isTwoFaEnabled) {
      pwdModal = (
        <Modal
          open={isPwdModalVisible}
          onCancel={handlePwdModalCancel}
          footer={null}
          styles={{
            body: {
              backgroundColor: "#0e3654",
              minHeight: "200px",
            },
          }}
        >
          <TwofaActivationContainer
            email={email}
            changeIs2FaActivated={(is2FaEnabled: boolean) => {
              setIsTwoFaEnabled(is2FaEnabled);
              setIs2FaModalVisible(is2FaEnabled);
              setIsTwoFaSet(false);
            }}
          />
        </Modal>
      );
    }
  }

  if (is2FaModalVisible) {
    pwdModal = (
      <Modal
        open={is2FaModalVisible}
        onOk={handle2FaModalOk}
        onCancel={handle2FaModalCancel}
        styles={{
          body: {
            backgroundColor: "#0e3654",
            minHeight: "200px",
          },
        }}
      >
        <h2
          style={{ textAlign: "center", color: "white", paddingBottom: "20px" }}
        >
          Please enter the 2FA code
        </h2>
        <OTPInput
          autoFocus
          length={6}
          isNumberInput={true}
          className="otpContainer"
          inputClassName="otpInput"
          onEnterKeyDown={() => {
            handle2FaModalOk();
          }}
          onChangeOTP={(otp) => {
            setTwoFA(otp);
          }}
        />
      </Modal>
    );
  }

  if (isUserLoggedIn)
    return (
      <Navigate
        to={initialFrom ? initialFrom : "/"}
        replace
        state={{ from: location }}
      />
    );
  return (
    <React.Fragment>
      <div className="login-component">
        <Row justify="center" align="middle">
          <Col span={24} xs={22} lg={12} sm={12} xl={6}>
            <div className="back-error-container">
              <Form
                onFinish={handleSubmit}
                className="login-form my-login-form"
              >
                <Row justify="center">
                  <img
                    src={logo}
                    alt="Delchain Logo"
                    style={{ maxWidth: "250px" }}
                  />
                </Row>
                <h3>Sign in</h3>
                <Form.Item>
                  <InputComponent
                    prefix={<MailOutlined className="whiteBackground" />}
                    type="email"
                    placeholder="Email Address"
                    onChange={(e: any) => {
                      if (e.nativeEvent.inputType === "insertFromPaste") {
                        setEmail(e.target.value.trim());
                      } else {
                        setEmail(e.target.value);
                      }
                    }}
                    className="primary-input whiteBackground"
                    autoFocus
                  />
                </Form.Item>
                <Form.Item>
                  <InputComponent
                    prefix={<LockOutlined className="whiteBackground" />}
                    type="password"
                    placeholder="Password"
                    onChange={(e: any) => {
                      if (e.nativeEvent.inputType === "insertFromPaste") {
                        setPassword(e.target.value.trim());
                      } else {
                        setPassword(e.target.value);
                      }
                    }}
                    // onPaste={(e: any) => {
                    //   const text = e.clipboardData.getData('Text');
                    //   console.log(text, 321);
                    //   setPassword(text.split(' ').join(''))
                    // }}
                    value={password}
                    className="primary-input whiteBackground"
                  />
                </Form.Item>
                <Form.Item className="captcha">
                  <label htmlFor="captcha">3 + 4</label>
                  <input
                    id="captcha"
                    onChange={(e: any) => setCaptcha(e.target.value)}
                  />
                </Form.Item>
                <Form.Item>
                  <div>
                    <Button htmlType="submit" className="primary-button">
                      LOGIN
                    </Button>
                    <Link to={"issues"} className="login-form-forgot">
                      Need help with Sign in?
                    </Link>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      {pwdModal}
    </React.Fragment>
  );
}

export default Login;
