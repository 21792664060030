import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, DatePicker, Row, Table } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useCallback, useEffect, useState } from "react";

dayjs.extend(timezone);

const { RangePicker } = DatePicker;

export default function ViewVenueTradingReportVenueBreakdown() {
  const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
    // Can not select days after today and today
    return current > dayjs().endOf("d");
  };
  const [columns, setColumns] = useState<any>();
  const [datePeriod, setDatePeriod] = useState<any>([
    dayjs().subtract(7, "d"),
    dayjs().subtract(1, "d"),
  ]);
  const [dataPrepopulated, setDataPrepopulated] = useState<boolean>(false);
  const [breakdownData, setBreakdownData] = useState<any[]>();
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [initialFetch, setInitialFetch] = useState<boolean>(true);
  const [selectedDatePeriod, setSeletedDatePeriod] = useState<any[]>([
    undefined,
    undefined,
  ]);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const fetchListToFetch = useCallback(() => {
    const breakdownColumns = [
      {
        title: "Name",
        dataIndex: "name",
        render: (name: any) => <strong>{name}</strong>,
        width: "200px",
      } as any,
    ];
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/list-to-fetch",
      withCredentials: true,
    }).then((res) => {
      for (const venue of res.data.listToFetch) {
        breakdownColumns.push({
          title: <Row justify={"center"}>{venue.name}</Row>,
          dataIndex: venue.name,
          render: (value: any) => {
            return <Row justify={"end"}>{value}</Row>;
          },
          width: "200px",
        } as any);
      }
      breakdownColumns.push({
        title: <Row justify={"center"}>Total</Row>,
        dataIndex: "total",
        fixed: "right" as const,
        render: (total: any) => (
          <Row justify={"end"}>
            <strong>{total}</strong>
          </Row>
        ),
        width: "200px",
      });
      setColumns(breakdownColumns);
    });
  }, []);

  useEffect(() => {
    fetchListToFetch();
  }, [fetchListToFetch]);

  const prePopulateReport = useCallback(() => {
    const startDate = datePeriod[0]
      .tz("America/New_York", true)
      .startOf("d")
      .toISOString();
    const endDate = datePeriod[1]
      .tz("America/New_York", true)
      .endOf("d")
      .toISOString();
    setSeletedDatePeriod([startDate, endDate]);
    setIsFetchingData(true);
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/trading-report",
      data: {
        startDate,
        endDate,
      },
      withCredentials: true,
    })
      .then((res) => {
        setBreakdownData(res.data);
      })
      .finally(() => {
        setIsFetchingData(false);
        setDataPrepopulated(true);
      });
  }, [datePeriod]);

  const exportCSV = useCallback(() => {
    const startDate = selectedDatePeriod[0];
    const endDate = selectedDatePeriod[1];
    setIsDownloading(true);
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/trading-report/export-csv",
      data: {
        startDate,
        endDate,
      },
      withCredentials: true,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(
            2,
            "0",
          )}_trading_report_venue_breakdown.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }, [selectedDatePeriod]);

  useEffect(() => {
    if (columns && columns.length > 0 && initialFetch) {
      prePopulateReport();
      setInitialFetch(false);
    }
  }, [columns, initialFetch, prePopulateReport]);
  return (
    <Content id="venue-trading-report-breakdown">
      <Row className="dcl-filter-row" align={"middle"}>
        <Col
          className="dcl-filter-item"
          xl={{ span: 5 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <RangePicker
            className="dcl-daterange-select"
            value={datePeriod}
            disabledDate={disabledDate}
            onChange={(e) => {
              setDatePeriod(e);
            }}
          />
        </Col>
        <Col
          xl={{ span: 5 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Button
            onClick={prePopulateReport}
            disabled={!datePeriod}
            type="primary"
            loading={isFetchingData}
          >
            Apply
          </Button>
        </Col>
        {dataPrepopulated ? (
          <Col
            xl={{ span: 14 }}
            lg={{ span: 12 }}
            md={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <Row justify="end" className="pagination-row">
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                disabled={isDownloading}
                loading={isDownloading}
                onClick={exportCSV}
              >
                <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
                CSV Export
              </Button>
            </Row>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      {dataPrepopulated ? (
        <Table
          rowKey={"name"}
          id="venue-trading-report-table"
          columns={columns}
          loading={isFetchingData}
          dataSource={breakdownData}
          scroll={{
            x: 1800,
          }}
        />
      ) : (
        <></>
      )}
    </Content>
  );
}
