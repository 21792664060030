import React from "react";
import "../../assets/scss/qrcode.scss";
import logo from "../../assets/DLC_Logo_RVB-white-400.png";
import { Row } from "antd";

function QRCode(props: any) {
  return (
    <React.Fragment>
      <div className="enable-twofa-container">
        <Row justify="center">
          <img
            src={logo}
            alt="Delchain Logo"
            style={{
              width: "70%",
              marginBottom: "2em",
            }}
          />
        </Row>
        <h3>Follow these steps to set up 2 factor authentication.</h3>
        <div className="twofa-steps-text">
          <p>Step 1.</p>
          <p>
            If you already have Google Authenticator or Microsoft Authenticator
            on your device, you can skip to step 2. Otherwise, please install
            Google Authenticator or Microsoft Authenticator on your mobile
            device
          </p>
        </div>
        <div className="twofa-steps-text">
          <p>Step 2.</p>
          <p>
            Pair the app with your Delchain by scanning the QRCode or tap it in
            mobile browser and then press 'Next'
          </p>
        </div>
        <div className="qrcode" style={{
          textAlign: 'center',
        }}>
          <img src={props.qrCode} alt="qrcode" />
          <p style={{ marginTop: "10px" }}>keyUri: {props.keyUriSecret}</p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default QRCode;
