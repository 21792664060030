import {
  Button,
  ConfigProvider,
  DatePicker,
  Form,
  InputNumber,
  Row,
  Select,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function CreateVirtualLedger({
  assetList,
  venueList,
  setShowCreateVirtualLedger,
  fetchData,
  form,
}: any) {
  const [fromVenue, setFromVenue] = useState<any>();
  const [toVenue, setToVenue] = useState<any>();

  const [portfolioOptions, setPortfolioOptions] =
    useState<{ label: string; value: number }[]>();
  const fetchDelchainPortfolios = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/delchain-portfolio",
      params: {
        key: "filter_venue",
      },
      withCredentials: true,
    }).then((res) => {
      setPortfolioOptions(res.data);
    });
  }, []);
  useEffect(() => {
    fetchDelchainPortfolios();
  }, [fetchDelchainPortfolios]);
  const [venueAccountList, setVenueAccountList] = useState<any>([]);
  const [venueAccountFromDropdownList, setVenueAccountFromDropdownList] =
    useState<any>([]);
  useEffect(() => {
    if (venueAccountList && venueAccountList.length && fromVenue) {
      const filteredAccount = venueAccountList.filter(
        (row: any) => row.venueId === fromVenue,
      );
      setVenueAccountFromDropdownList(filteredAccount);
    } else if (venueAccountList && venueAccountList.length) {
      setVenueAccountFromDropdownList(venueAccountList);
    }
  }, [venueAccountList, fromVenue]);
  const [venueAccountToDropdownList, setVenueAccountToDropdownList] =
    useState<any>([]);
  useEffect(() => {
    if (venueAccountList && venueAccountList.length && toVenue) {
      const filteredAccount = venueAccountList.filter(
        (row: any) => row.venueId === toVenue,
      );
      setVenueAccountToDropdownList(filteredAccount);
    } else if (venueAccountList && venueAccountList.length) {
      setVenueAccountToDropdownList(venueAccountList);
    }
  }, [venueAccountList, toVenue]);

  const getVenueAccountList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-accounts",
      withCredentials: true,
    }).then((res) => {
      const data = res.data.venueAccountList.map((row: any) => ({
        key: row.id,
        venueId: row.venueId,
        label: `${row.label}${
          row.accountNumber ? ` (${row.accountNumber})` : ""
        }`,
        value: row.id,
      }));
      setVenueAccountList(data);
    });
  }, []);
  useEffect(() => {
    getVenueAccountList();
  }, [getVenueAccountList]);
  const [venueOptions, setVenueOptions] = useState<any[]>([]);
  useEffect(() => {
    setVenueOptions([
      { label: "Deltec", key: "deltec", value: 0 },
      ...venueList,
    ]);
  }, [venueList]);
  const [inputForm] = Form.useForm();
  const createVirtualLedger = useCallback(
    (values: any) => {
      setIsCreating(true);
      axios({
        method: "Post",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/operations/virtual-ledger",
        data: { ...values, date: values.date?.format("YYYY-MM-DD HH:mm:ss") },
        withCredentials: true,
      })
        .then((res) => {
          toast.success("Virtual Ledger Inserted");
        })
        .finally(() => {
          setIsCreating(false);
          setShowCreateVirtualLedger(false);
          fetchData(form);
        });
    },
    [fetchData, form, setShowCreateVirtualLedger],
  );
  const [isCreating, setIsCreating] = useState<boolean>(false);
  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            labelColor: "white",
          },
          InputNumber: {
            /* here is your component tokens */
            addonBg: "lightgrey",
          },
        },
        token: {
          colorBgContainerDisabled: "lightgrey",
          colorSplit: "white",
        },
      }}
    >
      <Content id={"create-virtual-ledger"}>
        <Row
          justify={"center"}
          style={{
            marginTop: "20px",
          }}
        >
          <h2 style={{ color: "white" }}>Create Virtual Ledger</h2>
        </Row>
        <Form
          form={inputForm}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
          labelWrap
          onFinish={createVirtualLedger}
        >
          <Form.Item
            required
            label={<b>Date</b>}
            name="date"
            rules={[{ required: true }]}
          >
            <DatePicker
              showNow={false}
              style={{ width: "100%" }}
              renderExtraFooter={() => (
                <Row justify={"end"}>
                  <b>America/New York</b>
                </Row>
              )}
              showTime={{ format: "HH:mm:ss" }}
            />
          </Form.Item>
          <Form.Item
            required
            label={<b>From Venue</b>}
            name="fromVenue"
            rules={[{ required: true }]}
          >
            <Select
              options={venueOptions}
              allowClear
              showSearch
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
              onChange={(e) => {
                setFromVenue(e);
                inputForm.setFieldValue("fromAccount", undefined);
              }}
            />
          </Form.Item>
          <Form.Item
            required
            label={<b>From Account</b>}
            name="fromAccount"
            rules={[{ required: true }]}
          >
            {fromVenue === 0 ? (
              <Select
                options={portfolioOptions}
                allowClear
                showSearch
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            ) : (
              <Select
                options={venueAccountFromDropdownList}
                allowClear
                showSearch
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            )}
          </Form.Item>
          <Form.Item
            required
            label={<b>To Venue</b>}
            name="toVenue"
            rules={[{ required: true }]}
          >
            <Select
              options={venueOptions}
              allowClear
              showSearch
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
              onChange={(e) => {
                setToVenue(e);
                inputForm.setFieldValue("toAccount", undefined);
              }}
            />
          </Form.Item>
          <Form.Item
            required
            label={<b>to Account</b>}
            name="toAccount"
            rules={[{ required: true }]}
          >
            {toVenue === 0 ? (
              <Select
                options={portfolioOptions}
                allowClear
                showSearch
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            ) : (
              <Select
                options={venueAccountToDropdownList}
                allowClear
                showSearch
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              />
            )}
          </Form.Item>

          <Form.Item
            required
            label={<b>Amount</b>}
            name="amount"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: "100%" }}
              controls={false}
              formatter={(value) => {
                const parts = `${value}`.split(".");
                // Apply the original regex only to the first part (integer part) to add commas.
                const integerPartWithCommas = parts[0].replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ",",
                );
                // Reassemble the parts, including the decimal part if it was present.
                return parts.length > 1
                  ? `${integerPartWithCommas}.${parts[1]}`
                  : integerPartWithCommas;
              }}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
            />
          </Form.Item>
          <Form.Item
            required
            label={<b>Asset</b>}
            name="asset"
            rules={[{ required: true }]}
          >
            <Select
              options={assetList}
              allowClear
              showSearch
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
          <Row justify="center">
            <Button
              onClick={() => {
                setShowCreateVirtualLedger(false);
              }}
              loading={isCreating}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className="ant-btn-primary"
              loading={isCreating}
            >
              Apply
            </Button>
          </Row>
        </Form>
      </Content>
    </ConfigProvider>
  );
}
