import { Card, Col, InputNumber, Row, Form, Button } from "antd";
import { useForm } from "antd/es/form/Form";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function VenueExposureLimits() {
  const navigate = useNavigate();
  const [form] = useForm();
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const fetchExposureLimit = useCallback(() => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-exposure-limit",
      withCredentials: true,
    })
      .then((res) => {
        for (const [key, value] of Object.entries(res.data)) {
          form.setFieldValue(key, value);
        }
        setData(res.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setDataFetched(true);
      });
  }, [form, navigate]);
  const onFinish = useCallback(
    (form: any) => {
      axios({
        method: "Put",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-exposure-limit",
        data: { data: { ...form } },
        withCredentials: true,
      })
        .then(() => {
          toast.success("Venue exposure limit updated successfully");
          fetchExposureLimit();
        })
        .catch((err) => {
          toast.error("Failed to update venue exposure limit");
        });
    },
    [fetchExposureLimit],
  );
  useEffect(() => {
    fetchExposureLimit();
  }, [fetchExposureLimit]);
  return (
    <Card
      title={
        <Row>
          <Col span={8}>Venue Exposure Limit</Col>
        </Row>
      }
      bordered={false}
      loading={!dataFetched}
      style={{
        margin: "10px",
        borderRadius: "10px",
      }}
      id="venue-exposure-limit"
    >
      <Form form={form} onFinish={onFinish}>
        {data && Object.entries(data).length ? (
          Object.keys(data).map((key) => (
            <Form.Item label={`${key}`} key={key} name={key}>
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          ))
        ) : (
          <></>
        )}
        <Form.Item>
          <Button htmlType="submit" className="submit-button primary-button">
            Update Venue Exposure Limit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
