import React, { useState, useEffect } from "react";
import axios from "axios";
import QRCode from "./QrCode";

function TwofaActivationContainer(props: any) {
  const { email, changeIs2FaActivated } = props;
  const [qrCode, setQRCode] = useState<any>("");
  const [keyUriSecret, setKeyUriSecret] = useState<string | null>("");
  const [isNext, setIsNext] = useState<boolean>(false);

  // Boolean Controller for QR Code Confirmation Page
  const changePage = () => {
    setIsNext(!isNext);
  };

  // React Hook to generate QRCode
  useEffect(() => {
    // console.log(process.env.REACT_APP_AWS_BACKEND_URL + "/2fa/generate/");
    // console.log(email);
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/2fa/generate/",
      data: {
        email: email,
      },
    })
      .then((res) => {
        const keyUri = res.data.otpauthUrl;
        // console.log("28", keyUri);
        setKeyUriSecret(keyUri.split("=")[1].split("&")[0]);
        setQRCode(res.data.dataurl);
      })
      .catch((err) => console.log(err));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <React.Fragment>
      {!isNext ? (
        <React.Fragment>
          <QRCode qrCode={qrCode} keyUriSecret={keyUriSecret} />
          <button onClick={changePage} className="primary-button">
            Next
          </button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h2 style={{ color: "white" }}>
            Please confirm that you have scan the QR Code
          </h2>
          <button
            className="primary-button"
            style={{
              width: "40%",
              marginRight: "3em",
            }}
            onClick={changePage}
          >
            Back
          </button>
          <button
            className="primary-button"
            style={{ width: "40%" }}
            onClick={() => {
              changeIs2FaActivated(true);
              // axios({
              //   method: "PUT",
              //   url: process.env.REACT_APP_AWS_BACKEND_URL + "/2fa/activate",
              //   data: {
              //     email: email,
              //     is2FaEnabled: true,
              //   },
              // })
              //   .then(() => {
              //     changeIs2FaActivated(true);
              //   })
              //   .catch((e) => console.log(e));
            }}
          >
            Next
          </button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default TwofaActivationContainer;
