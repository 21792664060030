import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import Input from "rc-input";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CreateNewVenueMapping from "./CreateNewVenueMapping";

export default function ViewAssetVenueMap() {
  const navigate = useNavigate();

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [selectedAssetIds, setSelectedAssetIds] = useState<number[]>([]);
  const [selectedNetworkIds, setSelectedNetworkIds] = useState<number[]>([]);
  const [selectedVenueIds, setSelectedVenueIds] = useState<number[]>([]);
  const [assetVenueMap, setAssetVenueMap] = useState<any[]>([]);
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [assetNetworkOptions, setAssetNetworkOptions] = useState<any[]>([]);
  const [venueOptions, setVenueOptions] = useState<any[]>([]);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [selectedAVM, setSelectedAVM] = useState<any>();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const getAssetVenueList = useCallback(
    (
      selectAssetIds: number[] = [],
      selectedNetworkIds: number[] = [],
      selectedVenueIds: number[] = [],
    ) => {
      setIsFetching(true);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/venue-map",
        params: {
          assetIds: JSON.stringify(selectAssetIds),
          networkIds: JSON.stringify(selectedNetworkIds),
          venueIds: JSON.stringify(selectedVenueIds),
        },
        withCredentials: true,
      })
        .then((res) => {
          setTotal(res.data.aanmvnList?.length);
          setAssetVenueMap(res.data.aanmvnList);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
          toast.error("Failed to get asset venue map");
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    [navigate],
  );
  useEffect(() => {
    getAssetVenueList();
  }, [getAssetVenueList]);

  const getAssetList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    }).then((res) => {
      const options = [];
      for (const asset of res.data.assetList) {
        options.push({
          label: `${asset.name} (${asset.ticker})`,
          value: asset.id,
        });
      }
      options.sort((a, b) => a.label.localeCompare(b.label));
      setAssetOptions(options);
    });
  }, []);
  const getAssetNetworks = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/networks",
      withCredentials: true,
    }).then((res) => {
      setAssetNetworkOptions(res.data.networks);
    });
  }, []);
  const getVenues = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueOptions(res.data.list);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);
  useEffect(() => {
    getAssetList();
    getAssetNetworks();
    getVenues();
  }, [getAssetList, getAssetNetworks, getVenues]);

  const applyFilter = useCallback(() => {
    getAssetVenueList(selectedAssetIds, selectedNetworkIds, selectedVenueIds);
  }, [
    getAssetVenueList,
    selectedAssetIds,
    selectedNetworkIds,
    selectedVenueIds,
  ]);

  const updateSelectedAVM = useCallback(() => {
    setIsUpdating(true);
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/venue-map",
      data: selectedAVM,
      withCredentials: true,
    })
      .then(() => {
        toast.success("Update Successfully");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        toast.error("Failed to update");
      })
      .finally(() => {
        setIsUpdating(false);
        setOpenEditModal(false);
        applyFilter();
      });
  }, [applyFilter, selectedAVM, navigate]);

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [openCreateModal, setOpenCreateModal] = useState<boolean>(false);
  const [newAVM, setNewAVM] = useState<any>({
    assetId: undefined,
    assetNetworkId: undefined,
    isActive: true,
    venueId: undefined,
    value: "",
  });
  const resetNewAVM = useCallback(() => {
    setNewAVM({
      assetId: undefined,
      assetNetworkId: undefined,
      isActive: true,
      venueId: undefined,
      value: "",
    });
  }, []);

  const createNewAVM = useCallback(() => {
    setIsCreating(true);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/venue-map",
      data: newAVM,
      withCredentials: true,
    })
      .then(() => {
        toast.success("Created Successfully");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        toast.error("Failed to create");
      })
      .finally(() => {
        setIsCreating(false);
        setOpenCreateModal(false);
        applyFilter();
        resetNewAVM();
      });
    setIsCreating(false);
  }, [applyFilter, navigate, newAVM, resetNewAVM]);

  return (
    <Content id="view-asset-venue-map">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Space>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
              Filters
              {showFilter ? (
                <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
              ) : (
                <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
              )}
            </Button>
          </Space>
          <Form
            title="Filter"
            hidden={!showFilter}
            className="dcl-toggled-content dcl-toggled-content-filter"
          >
            <Row>
              <Col className="dcl-filter-item">
                <Select
                  mode="multiple"
                  allowClear
                  className="dcl-asset-select"
                  placeholder="Assets"
                  onChange={(e) => {
                    setSelectedAssetIds(e);
                  }}
                  value={selectedAssetIds}
                  options={assetOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Col>
              <Col className="dcl-filter-item">
                <Select
                  mode="multiple"
                  allowClear
                  className="dcl-asset-select"
                  placeholder="Asset Networks"
                  onChange={(e) => {
                    setSelectedNetworkIds(e);
                  }}
                  value={selectedNetworkIds}
                  options={assetNetworkOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Col>
              <Col className="dcl-filter-item">
                <Select
                  mode="multiple"
                  allowClear
                  className="dcl-venue-select"
                  placeholder="Venues"
                  onChange={(e) => {
                    setSelectedVenueIds(e);
                  }}
                  value={selectedVenueIds}
                  options={venueOptions}
                  popupMatchSelectWidth={false}
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Col>
            </Row>
            <Row justify="end">
              <Space>
                {total}
                <b>Results</b>
              </Space>
              <Space>
                <Button
                  onClick={() => {
                    applyFilter();
                  }}
                  loading={isFetching}
                  className="ant-btn-primary"
                >
                  Apply
                </Button>
                <Button
                  onClick={() => {
                    setSelectedAssetIds([]);
                    setSelectedNetworkIds([]);
                    setSelectedVenueIds([]);
                    getAssetVenueList();
                  }}
                >
                  Reset
                </Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8 }} sm={{ span: 8 }}>
          <Row justify={"end"}>
            <Button
              type="primary"
              onClick={() => {
                setOpenCreateModal(true);
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-plus" as IconProp}
                className="white-plus"
              />
              &nbsp; New Venue Mapping
            </Button>
          </Row>
        </Col>
      </Row>
      {isFetching ? (
        <Row justify={"center"}>
          <Spin size="large" />
        </Row>
      ) : (
        <Table
          sticky
          pagination={{
            defaultPageSize: 100,
          }}
          loading={isFetching}
          rowKey={"id"}
          columns={[
            {
              title: "Asset",
              dataIndex: "assetAssetNetworkMap",
              key: "assetAssetNetworkMap",
              sorter: (a: any, b: any) =>
                a.assetAssetNetworkMap?.asset?.ticker?.localeCompare(
                  b.assetAssetNetworkMap?.asset?.ticker,
                ),
              render: (_: any, { assetAssetNetworkMap }: any) =>
                assetAssetNetworkMap?.asset?.ticker,
            },
            {
              title: "Venue",
              dataIndex: "Venue",
              key: "venueId",
              sorter: (a: any, b: any) =>
                a.venue?.name?.localeCompare(b.venue?.name),

              render: (_: any, { venue }: any) => (
                <Space className={`dcl-venue-${venue?.ccxtKey}`}>
                  {venue?.name}
                </Space>
              ),
            },
            {
              title: "Asset Network",
              dataIndex: "assetAssetNetworkMap",
              key: "assetAssetNetworkMap",
              render: (_: any, { assetAssetNetworkMap }: any) =>
                assetAssetNetworkMap?.assetNetwork?.name,
            },

            {
              title: "Network value",
              dataIndex: "value",
              key: "value",
            },
            {
              title: "Is Active",
              dataIndex: "isActive",
              key: "isActive",
              render: (_: any, { isActive }: any) => (
                <Switch defaultChecked={isActive} disabled />
              ),
            },
            {
              title: "Actions",
              dataIndex: "id",
              render: (_: any, row: any, index: number) => (
                <Row justify={"space-between"}>
                  <Col span={12}>
                    <Tooltip title="Edit">
                      <Button
                        style={{
                          border: "none",
                          background: "none",
                          boxShadow: "none",
                          fontSize: "18px",
                        }}
                        onClick={() => {
                          setOpenEditModal(true);
                          setSelectedAVM(row);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={"fa-regular fa-pen-to-square" as IconProp}
                        />
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              ),
            },
          ]}
          dataSource={assetVenueMap}
        />
      )}

      <Modal
        open={openCreateModal}
        onCancel={() => {
          setOpenCreateModal(false);
          resetNewAVM();
        }}
        onOk={() => {
          createNewAVM();
        }}
        confirmLoading={isCreating}
      >
        <CreateNewVenueMapping
          assetOptions={assetOptions}
          networkOptions={assetNetworkOptions}
          venueOptions={venueOptions}
          newAVM={newAVM}
          setNewAVM={setNewAVM}
        />
      </Modal>
      <Modal
        open={openEditModal}
        onCancel={() => {
          setOpenEditModal(false);
          setSelectedAVM(undefined);
        }}
        onOk={() => {
          updateSelectedAVM();
        }}
        confirmLoading={isUpdating}
      >
        <ConfigProvider
          theme={{
            components: {
              Form: {
                labelColor: "white",
              },
            },
          }}
        >
          <Form.Item label={<b>Asset</b>}>
            <b style={{ color: "white" }}>
              {selectedAVM?.assetAssetNetworkMap?.asset?.name} (
              {selectedAVM?.assetAssetNetworkMap?.asset?.ticker})
            </b>
          </Form.Item>
          <Form.Item label={<b>Venue</b>}>
            <b style={{ color: "white" }}>{selectedAVM?.venue?.name}</b>
          </Form.Item>
          <Form.Item label={<b>Asset Network</b>}>
            <b style={{ color: "white" }}>
              {selectedAVM?.assetAssetNetworkMap?.assetNetwork?.name}
            </b>
          </Form.Item>
          <Form.Item label={<b>Network value</b>}>
            <Input
              value={selectedAVM?.value}
              onChange={(e) => {
                selectedAVM.value = e.target.value;
                setSelectedAVM({ ...selectedAVM });
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Is Active</b>}>
            <Switch
              checked={selectedAVM?.isActive}
              onChange={(e) => {
                selectedAVM.isActive = e;
                setSelectedAVM({ ...selectedAVM });
              }}
            />
          </Form.Item>
        </ConfigProvider>
      </Modal>
    </Content>
  );
}
