import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

interface PieChartData {
  venueName: string;
  count: number;
  countPercentage: number;
  usdAmountPercentage: number;
  usdAmount: number;
}

interface PieChartProps {
  data: PieChartData[];
  nameKey: string;
  numberKey: string;
}

function calculatePoint(i: number, intervalSize: number, colorRangeInfo: any) {
  var { colorStart, colorEnd, useEndAsStart } = colorRangeInfo;
  return useEndAsStart
    ? colorEnd - i * intervalSize
    : colorStart + i * intervalSize;
}

function interpolateColors(
  dataLength: number,
  colorScale: any,
  colorRangeInfo: any,
) {
  var { colorStart, colorEnd } = colorRangeInfo;
  var colorRange = colorEnd - colorStart;
  var intervalSize = colorRange / dataLength;
  var i, colorPoint;
  var colorArray = [];

  for (i = 0; i < dataLength; i++) {
    colorPoint = calculatePoint(i, intervalSize, colorRangeInfo);
    colorArray.push(colorScale(colorPoint));
  }

  return colorArray;
}

const TradingPieChart: React.FC<PieChartProps> = ({
  data,
  nameKey,
  numberKey,
}) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const width = 250; // Width of the SVG element
    const height = 250; // Height of the SVG element
    const radius = Math.min(width, height) / 2; // Radius of the pie chart
    const colorScale = d3.interpolateCool;
    const colorRangeInfo = {
      colorStart: 0,
      colorEnd: 0.65,
      useEndAsStart: true,
    };
    var COLORS = interpolateColors(data.length, colorScale, colorRangeInfo);
    const pie = d3.pie<PieChartData>().value((d: any) => d[numberKey]);
    const pieData = pie(data);

    const arc = d3.arc<PieChartData>().innerRadius(0).outerRadius(radius);

    svg.selectAll("*").remove();

    const sliceGroup = svg
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);
    sliceGroup
      .selectAll("path")
      .data(pieData)
      .enter()
      .append("path")
      .attr("d", arc as any)
      .attr("fill", (d: any) => COLORS[d.index])
      .attr("stroke", "white")
      .style("stroke-width", "2px")
      .on("mouseover", function (e, d: any) {
        // d3.select(this).attr("style", "fill: orange");

        const percentage =
          numberKey === "count"
            ? d.data.countPercentage.toFixed(2)
            : d.data.usdAmountPercentage.toFixed(2);
        d3.select("#tooltip-pie-chart")
          .html(
            `<b>${d.data[nameKey]} - ${percentage}%</b><br/><b>${
              numberKey === "count" ? "# of orders" : "USD Notional"
            }: ${d.data[numberKey].toLocaleString("en-us", {
              minimumFractionDigits: numberKey === "count" ? 0 : 2,
              maximumFractionDigits: numberKey === "count" ? 0 : 2,
            })}</b>`,
          )
          .style("visibility", "visible");
      })
      .on("mousemove", (event) => {
        // Update tooltip position
        d3.select("#tooltip-pie-chart")
          .style("top", event.pageY - 230 + "px")
          .style("left", event.pageX - 200 + "px");
      })
      .on("mouseout", function () {
        d3.select(this).attr("style", (d: any) => `fill: ${COLORS[d.index]}`);
        d3.select("#tooltip-pie-chart").style("visibility", "hidden");
      });
    // Add text labels for venueName to each slice
    sliceGroup
      .selectAll("text")
      .data(pieData)
      .enter()
      .append("text")
      .attr("transform", (d: any) => `translate(${arc.centroid(d)})`)
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .text((d: any) => {
        if (d.endAngle - d.startAngle < 0.1) {
          return ""; // Hide labels for small slices
        }
        return `${d.data.venueName}`;
      });

    return () => {
      svg.selectAll("*").remove();
    };
  }, [data, nameKey, numberKey]);

  return (
    <div className="trade-chart">
      <svg ref={svgRef} width={250} height={250}></svg>
      <div
        className="tooltip-container"
        id="tooltip-pie-chart"
        style={{ position: "absolute", visibility: "hidden", zIndex: 2 }}
      ></div>
    </div>
  );
};

export default TradingPieChart;
