import React, { useState } from "react";
import InputComponent from "../Input";
import validator from "validator";
import "../../assets/scss/change-pwd.scss";
import { Button } from "antd";
import axios from "axios";
//import { useParams } from "react-router-dom";

function ChangePassword(props: any) {
  const email = props.email;
  const oldPassword = props.oldPassword;
  //const [user, setUser] = useState<any>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
  const [isPasswordMatching, setIsPasswordMatching] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  // const [isTempPwd, setIsTempPwd] = useState<boolean>(true);
  // const [isTwoFaEnabled, setIsTwoFaEnabled] = useState<boolean>(false);

  // Only run this once
  // Fetch the user Info before the page is rendered
  //useEffect(() => {
  //axios.get(`http://127.0.0.1:3000/user/${username}`).then((response) => {
  //setUser(response.data);
  // setIsTempPwd(response.data["isTempPwd"]);
  // setIsTwoFaEnabled(response.data["is2FaEnabled"]);
  //});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  //}, []);
  const { changePwdStatus } = props;
  const validate = (value: any) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
        returnScore: false,
      })
    ) {
      setErrorMessage("Congrats! This is a strong password");
    } else {
      setErrorMessage("Unfortunately, This is not a strong password.");
    }
  };

  const comparePasswordMatching = (passwowrd1: string, password2: string) => {
    if (passwowrd1 === password2) {
      setIsPasswordMatching(true);
    } else {
      setIsPasswordMatching(false);
    }
  };

  const onSubmit = () => {
    //console.log(user);
    // console.log(email)
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/update-pwd",
      data: {
        email: email,
        password: password,
        oldPassword: oldPassword,
      },
    })
      .then(() => {
        changePwdStatus(false);
        // console.log(response);
      })
      .catch((e) => console.log(e));
  };

  let button;
  if (
    isPasswordMatching &&
    errorMessage === "Congrats! This is a strong password"
  ) {
    button = (
      <Button className="primary-button" onClick={onSubmit}>
        {" "}
        Change Password{" "}
      </Button>
    );
  } else {
    button = (
      <Button className="primary-button" disabled={true}>
        {" "}
        Change Password{" "}
      </Button>
    );
  }

  // if (!isTempPwd && isTwoFaEnabled) {
  //   return <Navigate to="/login" replace state={{}} />;
  // }

  return (
    <React.Fragment>
      <h2 className="instruction">Please change your password</h2>
      <p className="instruction">
        At least 8 characters with 1 upper letter, 1 lower letter, 1 number and
        1 symbol or special character
      </p>
      <InputComponent
        type="password"
        placeholder="Please enter your password"
        onChange={(e: any) => {
          if (e.nativeEvent.inputType === "insertFromPaste") {
            setPassword(e.target.value.trim());
          } else {
            setPassword(e.target.value);
          }
          validate(e.target.value);
          if (passwordConfirmation) {
            comparePasswordMatching(e.target.value, passwordConfirmation);
          }
        }}
        className="primary-input"
      />
      <InputComponent
        type="password"
        placeholder="Please confirm your password"
        onChange={(e: any) => {
          if (e.nativeEvent.inputType === "insertFromPaste") {
            setPasswordConfirmation(e.target.value.trim());
          } else {
            setPasswordConfirmation(e.target.value);
          }
          validate(e.target.value);
          comparePasswordMatching(e.target.value, password);
        }}
        className="primary-input"
      />
      {passwordConfirmation && !isPasswordMatching && (
        <React.Fragment>
          <span
            style={{
              fontWeight: "bold",
              color: "Orange",
            }}
          >
            Passwords not matching
          </span>
          <br />
        </React.Fragment>
      )}
      {errorMessage === "Congrats! This is a strong password" && (
        <span
          style={{
            fontWeight: "bold",
            color: "Green",
          }}
        >
          {errorMessage}
        </span>
      )}
      {errorMessage === "Unfortunately, This is not a strong password." && (
        <span
          style={{
            fontWeight: "bold",
            color: "red",
          }}
        >
          {errorMessage}
        </span>
      )}
      <br />
      {button}
    </React.Fragment>
  );
}
export default ChangePassword;
