import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import useCopyToClipboard from "../../../../hooks/useCopyToClipboard";
import { toast } from "react-toastify";
import DclQuoteConfirmationModal from "./DclQuoteConfirmModal";

const BPS_FEE = 0.0001;

function EditDclQuote() {
  const feeCalculationTypeOptions = [
    { label: "BPS", value: "bps" },
    { label: "Quote", value: "quote" },
  ];

  let index = 0;
  const [form] = Form.useForm();
  const [, copy] = useCopyToClipboard();

  const location = useLocation();
  const navigate = useNavigate();
  const { dclOrderId } = useParams();

  const [dclOrderDetail, setDclOrderDetail] = useState<any>();
  const [orderDetailFetched, setOrderDetailFetched] = useState<boolean>(false);
  const [dclFeeCalculationType, setDclFeeCalculationType] =
    useState<string>("bps");
  const fetchDclOrderDetail = useCallback(
    (orderId: string) => {
      setOrderDetailFetched(false);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/dcl-order-data",
        params: {
          orderId: String(orderId),
        },
        withCredentials: true,
      })
        .then((res) => {
          setDclOrderDetail(res.data);
          form.setFieldsValue({
            ...res.data,
            timestamp: dayjs(res.data?.timestamp),
          });
          setIncludeDelchainFee(res.data.dclFee ? true : false);
          setIncludeVenueFee(res.data.fee ? true : false);
          setDclOrderModified(true);
        })
        .finally(() => {
          setOrderDetailFetched(true);
        });
    },
    [form],
  );

  useEffect(() => {
    if (dclOrderId) fetchDclOrderDetail(dclOrderId);
  }, [dclOrderId, fetchDclOrderDetail]);

  const [clientList, setClientList] = useState<any[]>([]);
  const getClientList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    }).then((res) => {
      for (const c of res.data.clients) {
        c.label = `${c.label} (${c.deltecAccount})`;
      }
      setClientList(res.data.clients);
    });
  }, []);

  const [userList, setUserList] = useState<any[]>([]);
  const getUserList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/user-list",
      withCredentials: true,
    }).then((res) => {
      for (const user of res.data.users) {
        user.value = user.key;
      }
      setUserList(res.data.users);
    });
  }, []);

  const [venueList, setVenueList] = useState<any[]>([]);
  const getVenueList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
      withCredentials: true,
    })
      .then((res) => {
        setVenueList(res.data.list);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  const [orderId, setOrderId] = useState("");
  const onNameChange = (event: any) => {
    setOrderId(event.target.value);
  };
  const inputRef = useRef(null);
  const [openOrderList, setOpenOrderList] = useState<any[]>([]);
  const addItem = (e: any) => {
    e.preventDefault();
    setOpenOrderList([...openOrderList, orderId || `New item ${index++}`]);
    setOrderId("");
    // setTimeout(() => {
    //   inputRef?.current?.focus();
    // }, 0);
  };
  const [transactionWayList, setTransactionWayList] = useState<any[]>([]);
  const getTransactionWayList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/transaction-way",
      params: { type: "trade" }, // Buy and Sell Specific
      withCredentials: true,
    }).then((res) => {
      setTransactionWayList(res.data);
    });
  }, []);

  const [dclOrderTypeList, setdclOrderTypeList] = useState<any[]>([]);
  const getdclOrderTypeList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/dcl-order-type",
      withCredentials: true,
    }).then((res) => {
      setdclOrderTypeList(res.data.types);
    });
  }, []);
  const [assetList, setAssetList] = useState<any[]>([]);
  const getAssetList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    }).then((res) => {
      const options = [];
      for (const asset of res.data.assetList) {
        options.push({
          label: `${asset.name} (${asset.ticker})`,
          value: asset.id,
          key: asset.id,
          ticker: asset.ticker,
          isStablecoin: asset.isStablecoin,
          assetType: asset.assetType,
        });
      }
      setAssetList(options);
    });
  }, []);
  const [quoteQty, setQuoteQty] = useState(0);
  // const [minNetFee, setMinNetFee] = useState<number>(0);
  // const [minDclNetFee, setMinDclNetFee] = useState<number>(0);
  const [dclOrderModified, setDclOrderModified] = useState<boolean>();
  const [defaultFeeObj, setDefaultFeeObj] = useState<any>();

  const copyMessageToClipboard = () => {
    copy(messageToCopy);
    toast.success("Propsition message copied to clipboard", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const copySummaryToClipboard = () => {
    copy(summary);
    toast.success("Trade summary copied to clipboard", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const getDefaultFees = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/order-builder-default-fees",
      withCredentials: true,
    }).then((res) => {
      setDefaultFeeObj(res.data);
    });
  }, []);

  useEffect(() => {
    getUserList();
    getVenueList();
    getClientList();
    getTransactionWayList();
    getdclOrderTypeList();
    getAssetList();
    getDefaultFees();
  }, [
    getUserList,
    getVenueList,
    getClientList,
    getTransactionWayList,
    getdclOrderTypeList,
    getAssetList,
    getDefaultFees,
  ]);

  const [symbol, setSymbol] = useState<string>("");
  const [netPrice, setNetPrice] = useState<string>("");
  const [netFees, setnetFees] = useState<number>(0);
  const [netFeeQuote, setNetFeeQuote] = useState<number>(0);
  const [isBaseStablecoin, setIsBaseStablecoin] = useState<boolean>(false);
  const [isQuoteFiat, setIsQuoteFiat] = useState<boolean>(true);
  const [includeVenueFee, setIncludeVenueFee] = useState<boolean>(true);
  const [includeDelchainFee, setIncludeDelchainFee] = useState<boolean>(true);
  const [netVenueFeeQuote, setNetVenueFeeQuote] = useState<number>(0);
  const [netDclFeeQuote, setNetDclFeeQuote] = useState<number>(0);
  const [metMinimumFeeRequirement, setMetMinimumFeeRequirement] =
    useState<boolean>(true);

  const [metMinimumDclFeeRequirement, setMetMinimumDclFeeRequirement] =
    useState<boolean>(true);
  const [quoteAmountApplied, setQuoteAmountApplied] = useState<number>(0);

  const [messageToCopy, setMessageToCopy] = useState("");
  const [summary, setSummary] = useState<string>("");

  const onFieldChange = useCallback(
    (fieldName: string, value: any) => {
      dclOrderDetail[fieldName] = value;
      if (dclFeeCalculationType === "quote") {
        if (!netDclFeeQuote) return;
        const qty = dclOrderDetail?.qty || 0;
        const price = dclOrderDetail?.price || 0;
        const dclFee = netDclFeeQuote / (qty * price * BPS_FEE);
        dclOrderDetail.dclFee = dclFee;
      }
      setDclOrderModified(true);
    },
    [dclOrderDetail, netDclFeeQuote, dclFeeCalculationType],
  );
  const setBaseAsset = useCallback(
    (assetTicker: string) => {
      const baseAsset = assetList?.find(
        (row: any) => row.ticker === assetTicker,
      );
      form.setFieldValue("firstAssetId", baseAsset.value);
      onFieldChange("firstAssetId", baseAsset.value);
    },
    [assetList, form, onFieldChange],
  );

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [saveType, setSaveType] = useState<string>("");

  const onDclNetFeeQuoteChange = useCallback(
    (e: number | null) => {
      if (!e) return;
      const qty = dclOrderDetail?.qty || 0;
      const price = dclOrderDetail?.price || 0;
      const baseVolume = qty * price * BPS_FEE;
      const dclFeeBps = Number((e / baseVolume).toFixed(6));
      dclOrderDetail.dclFee = dclFeeBps;
      dclOrderDetail.netFee = dclFeeBps + dclOrderDetail.venueFee;
      setNetDclFeeQuote(e);
      setNetFeeQuote(netVenueFeeQuote + e);
      setDclOrderModified(true);
    },
    [dclOrderDetail, netVenueFeeQuote],
  );

  const submitData = useCallback(
    (type: string) => {
      form
        .validateFields()
        .then(() => {
          setOrderDetailFetched(false);
          axios({
            method: "Put",
            url:
              process.env.REACT_APP_AWS_BACKEND_URL + "/venue/dcl-order-data",
            data: {
              ...dclOrderDetail,
              timestamp: dayjs(dclOrderDetail.timestamp)
                .tz("America/New_York", true)
                .format(),
              fee: includeVenueFee ? dclOrderDetail.fee : 0,
              dclFee: includeDelchainFee ? dclOrderDetail.dclFee : 0,
              isCanceled: type === "canceled" ? true : false,
              isExecuted: type === "executed" ? true : false,
            },
            withCredentials: true,
          })
            .then(() => {
              toast.success("Order updated successfully", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            })
            .catch((err) => {
              toast.error(err.response.data.message);
            })
            .finally(() => {
              setOrderDetailFetched(true);
              fetchDclOrderDetail(String(dclOrderId));
            });
        })
        .catch(() => {
          toast.error("Please check the fields");
        })
        .finally(() => {
          setShowConfirmModal(false);
          setSaveType("");
        });
    },
    [
      dclOrderDetail,
      fetchDclOrderDetail,
      form,
      dclOrderId,
      includeVenueFee,
      includeDelchainFee,
    ],
  );

  const [minimumAmountComponent, setMinimumAmountComponent] = useState<any>(
    <></>,
  );
  const renderMinimumAmount = useCallback(() => {
    if (!defaultFeeObj || !dclOrderDetail?.venueId || !assetList) return;
    if (metMinimumDclFeeRequirement && metMinimumFeeRequirement) {
      setMinimumAmountComponent(<></>);
      return;
    }

    const transactionWayName = transactionWayList
      .find((row) => row.value === dclOrderDetail?.transactionWayId)
      ?.label?.toLocaleLowerCase();

    const venueBps = includeVenueFee ? dclOrderDetail?.fee : 0;
    const dclBps = includeDelchainFee ? dclOrderDetail?.dclFee : 0;
    const minDclFee = defaultFeeObj[dclOrderDetail?.venueId]?.min_dcl_net_fee;
    const minNetFee = defaultFeeObj[dclOrderDetail.venueId]?.min_net_fee;
    let dclFee = includeVenueFee
      ? Math.max(
          dclOrderDetail?.qty * dclOrderDetail?.price * dclBps * BPS_FEE,
          minDclFee,
        )
      : 0;
    const venueFee =
      (dclOrderDetail?.qty || 0) *
      (dclOrderDetail?.price || 0) *
      venueBps *
      BPS_FEE;
    let netFee = 0;
    if (includeDelchainFee && includeVenueFee) {
      netFee = Math.max(dclFee + venueFee, minNetFee);
    } else if (includeDelchainFee) {
      netFee = Math.max(dclFee, minNetFee);
    } else if (includeVenueFee) {
      netFee = Math.max(venueFee, minNetFee);
    }
    const isBase = dclOrderDetail?.isBase;
    let quoteAmount = 0;
    if (isBase) {
      quoteAmount =
        dclOrderDetail?.qty * dclOrderDetail?.price +
        netFee * (transactionWayName === "buy" ? 1 : -1);
    } else {
      const netFeeQuote = dclFee * Number(dclOrderDetail.qty) * BPS_FEE;
      if (
        netFeeQuote < defaultFeeObj[dclOrderDetail.venueId]?.min_dcl_net_fee
      ) {
        const qty =
          Number(dclOrderDetail.qty) +
          defaultFeeObj[dclOrderDetail.venueId]?.min_dcl_net_fee *
            (transactionWayName === "buy" ? -1 : 1);
        const nPrice = Number(
          (
            (1 +
              (includeVenueFee
                ? Number(dclOrderDetail.fee) *
                  BPS_FEE *
                  (transactionWayName === "sell" ? -1 : 1)
                : 0)) *
            dclOrderDetail.price
          ).toFixed(isBaseStablecoin ? 4 : 6),
        );
        quoteAmount = Number(qty) / Number(nPrice);
      } else {
        quoteAmount = Number(quoteQty);
      }
    }
    setQuoteAmountApplied(quoteAmount);

    setMinimumAmountComponent(
      <React.Fragment>
        <Divider />
        <Form.Item label={<b>Min Fee Applied</b>}>
          <Space className="calculated">
            {(isQuoteFiat ? Number(netFee.toFixed(2)) : netFee).toLocaleString(
              "en-us",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: isQuoteFiat ? 2 : 6,
              },
            )}
            {
              assetList.find(
                (row) => row.value === dclOrderDetail?.secondAssetId,
              )?.ticker
            }
          </Space>
        </Form.Item>
        <Form.Item label={<b>Min Fee Implied Price</b>}>
          <Row className="calculated">
            {isBase
              ? (quoteAmount / dclOrderDetail?.qty).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 4,
                })
              : (dclOrderDetail?.qty / quoteAmount).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: isBaseStablecoin ? 4 : 6,
                })}
          </Row>
        </Form.Item>
        <Form.Item label={<b>Quote amount Applied</b>}>
          <Space className="calculated">
            {isBase
              ? (isQuoteFiat
                  ? Number(quoteAmount.toFixed(2))
                  : quoteAmount
                ).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: isQuoteFiat
                    ? 2
                    : isBaseStablecoin
                    ? 4
                    : 6,
                })
              : dclOrderDetail?.qty.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: isQuoteFiat
                    ? 2
                    : isBaseStablecoin
                    ? 4
                    : 6,
                })}
            {
              assetList.find(
                (row) => row.value === dclOrderDetail?.secondAssetId,
              )?.ticker
            }
          </Space>
        </Form.Item>
      </React.Fragment>,
    );
  }, [
    defaultFeeObj,
    dclOrderDetail?.venueId,
    dclOrderDetail?.fee,
    dclOrderDetail?.dclFee,
    dclOrderDetail?.qty,
    dclOrderDetail?.price,
    dclOrderDetail?.isBase,
    dclOrderDetail?.transactionWayId,
    dclOrderDetail?.secondAssetId,
    assetList,
    metMinimumDclFeeRequirement,
    metMinimumFeeRequirement,
    transactionWayList,
    includeVenueFee,
    includeDelchainFee,
    isQuoteFiat,
    isBaseStablecoin,
    quoteQty,
  ]);
  useEffect(() => {
    setDclOrderModified(true);
  }, [quoteAmountApplied]);

  useEffect(() => {
    renderMinimumAmount();
  }, [renderMinimumAmount]);

  useEffect(() => {
    if (
      !assetList?.length ||
      !clientList?.length ||
      !transactionWayList ||
      !defaultFeeObj
    )
      return;
    if (dclOrderModified) {
      const base = assetList?.find(
        (e) => e.value === dclOrderDetail.firstAssetId,
      );
      const quote = assetList?.find(
        (e) => e.value === dclOrderDetail.secondAssetId,
      );
      const baseCurrency = base?.ticker;
      const isBaseStablecoin = base?.isStablecoin;
      setIsBaseStablecoin(isBaseStablecoin);
      const quoteCurrency = quote?.ticker;
      const isQuoteFiat = quote?.assetType?.toLocaleLowerCase() === "fiat";
      setIsQuoteFiat(isQuoteFiat);

      const symbol = `${baseCurrency}/${quoteCurrency}`;
      setSymbol(symbol);
      const transactionWayName = transactionWayList
        .find((row) => row.value === dclOrderDetail.transactionWayId)
        ?.label?.toLocaleLowerCase();
      const netFeeBps =
        (includeVenueFee ? dclOrderDetail.fee : 0) +
        (includeDelchainFee ? dclOrderDetail.dclFee : 0);
      let nPrice =
        (1 + (transactionWayName === "sell" ? -1 : 1) * netFeeBps * BPS_FEE) *
        dclOrderDetail.price;
      nPrice = Number(nPrice.toFixed(isBaseStablecoin ? 4 : 6));
      let quoteQtyCalc;
      if (dclOrderDetail.isBase) {
        quoteQtyCalc = Number(dclOrderDetail.qty) * Number(nPrice);
      } else {
        const vF =
          dclOrderDetail.fee *
          BPS_FEE *
          Number(dclOrderDetail.qty) *
          Number(dclOrderDetail.price);

        const dF =
          dclFeeCalculationType === "bps"
            ? Number(
                (
                  dclOrderDetail.dclFee *
                  BPS_FEE *
                  Number(dclOrderDetail.qty) *
                  Number(dclOrderDetail.price)
                ).toFixed(isBaseStablecoin ? 4 : 6),
              )
            : netDclFeeQuote;

        const nF =
          dclFeeCalculationType === "bps"
            ? netFeeBps *
              BPS_FEE *
              Number(dclOrderDetail.qty) *
              Number(dclOrderDetail.price)
            : dF + vF;

        const qty =
          nF < defaultFeeObj[dclOrderDetail.venueId]?.min_dcl_net_fee
            ? Number(dclOrderDetail.qty) +
              defaultFeeObj[dclOrderDetail.venueId]?.min_dcl_net_fee *
                (transactionWayName === "buy" ? -1 : 1)
            : Number(dclOrderDetail.qty);
        quoteQtyCalc = Number(qty) / Number(nPrice);
      }
      // const venueFeeQuote = dclOrderDetail.fee * BPS_FEE * quoteQty;
      const venueFeeQuote =
        dclOrderDetail.fee *
        BPS_FEE *
        Number(dclOrderDetail.qty) *
        Number(dclOrderDetail.price);

      const dclFeeQuote =
        dclFeeCalculationType === "bps"
          ? Number(
              (
                dclOrderDetail.dclFee *
                BPS_FEE *
                Number(dclOrderDetail.qty) *
                Number(dclOrderDetail.price)
              ).toFixed(isBaseStablecoin ? 4 : 6),
            )
          : netDclFeeQuote;

      const netFeeQuote =
        dclFeeCalculationType === "bps"
          ? netFeeBps *
            BPS_FEE *
            Number(dclOrderDetail.qty) *
            Number(dclOrderDetail.price)
          : dclFeeQuote + venueFeeQuote;

      const metMinFeeRequirement =
        netFeeQuote >= defaultFeeObj[dclOrderDetail.venueId]?.min_net_fee;
      const metMinDclFeeRequirement =
        dclFeeQuote >= defaultFeeObj[dclOrderDetail.venueId]?.min_dcl_net_fee;
      setMetMinimumFeeRequirement(metMinFeeRequirement);
      setMetMinimumDclFeeRequirement(metMinDclFeeRequirement);

      const client = clientList.find(
        (row) => row.value === dclOrderDetail.clientId,
      );
      let msg = "",
        summary = "";
      if (dclOrderDetail.isBase) {
        if (!metMinDclFeeRequirement && includeDelchainFee) {
          msg = `${
            transactionWayName === "buy" ? "Buying" : "Selling"
          } ${dclOrderDetail.qty?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} ${baseCurrency} will ${
            transactionWayName === "buy" ? "require" : "give"
          } you ${quoteAmountApplied.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isQuoteFiat ? 2 : 6,
          })} ${quoteCurrency}`;
          summary = `#TRADESUMMARY ${client?.deltecAccount} ${
            client?.clientName
          } ${
            transactionWayName === "buy" ? "Bought" : "Sold"
          } ${dclOrderDetail.qty?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} ${baseCurrency}. ${
            transactionWayName === "buy" ? "Costs" : "Proceeds"
          }: ${quoteAmountApplied.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isQuoteFiat ? 2 : isBaseStablecoin ? 4 : 6,
          })} ${quoteCurrency}`;
        } else {
          msg = `Indicative quote to ${
            transactionWayName === "buy" ? "buy" : "sell"
          } ${dclOrderDetail.qty?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 6,
          })} ${baseCurrency} is ${
            isBaseStablecoin
              ? Number(nPrice.toFixed(4)).toLocaleString("en-us", {
                  minimumFractionDigits: 4,
                  maximumFractionDigits: 4,
                })
              : nPrice.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: isBaseStablecoin ? 4 : 6,
                })
          } which would ${
            transactionWayName === "buy" ? "require" : "give you"
          } ${
            isQuoteFiat
              ? Number(quoteQtyCalc.toFixed(2)).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : quoteQtyCalc.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: isBaseStablecoin ? 4 : 6,
                })
          } ${quoteCurrency}`;
          summary = `#TRADESUMMARY ${client?.deltecAccount} ${
            client?.clientName
          } ${
            transactionWayName === "buy" ? "Bought" : "Sold"
          } ${dclOrderDetail.qty?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} ${baseCurrency} for a net price ${
            isBaseStablecoin
              ? Number(nPrice.toFixed(4)).toLocaleString("en-us", {
                  minimumFractionDigits: 4,
                  maximumFractionDigits: 4,
                })
              : nPrice.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 6,
                })
          }. ${transactionWayName === "buy" ? "Costs" : "Proceeds"}: ${
            isQuoteFiat
              ? Number(quoteQtyCalc.toFixed(2)).toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : quoteQtyCalc.toLocaleString("en-us", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: isBaseStablecoin ? 4 : 6,
                })
          } ${quoteCurrency}`;
        }
      } else {
        if (!metMinDclFeeRequirement && includeDelchainFee) {
          msg = `Indicatively, to ${
            transactionWayName === "buy" ? "buy" : "sell"
          } ${baseCurrency} ${
            transactionWayName === "buy" ? "with" : "to get you"
          } ${dclOrderDetail.qty?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} will give you ${quoteQtyCalc.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} ${baseCurrency}`;
          summary = `#TRADESUMMARY ${client?.deltecAccount} ${
            client.clientName
          } ${
            transactionWayName === "buy" ? "Bought" : "Sold"
          } ${quoteQtyCalc.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} ${baseCurrency}. ${
            transactionWayName === "buy" ? "Costs" : "Proceeds"
          }: ${dclOrderDetail.qty?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} ${quoteCurrency}`;
        } else {
          msg = `Indicative quote to ${
            transactionWayName === "buy" ? "buy" : "sell"
          } ${baseCurrency} ${
            transactionWayName === "buy" ? "with" : "to get you"
          } ${dclOrderDetail.qty?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} ${quoteCurrency} is ${nPrice} which would ${
            transactionWayName === "buy" ? "give you" : "require"
          } ${quoteQtyCalc.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} ${baseCurrency}`;
          summary = `#TRADESUMMARY ${client?.deltecAccount} ${
            client.clientName
          } ${
            transactionWayName === "buy" ? "Bought" : "Sold"
          } ${quoteQtyCalc.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} ${baseCurrency} for a net price ${nPrice.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 6,
          })}. ${
            transactionWayName === "buy" ? "Costs" : "Proceeds"
          }: ${dclOrderDetail.qty?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: isBaseStablecoin ? 4 : 6,
          })} ${quoteCurrency}`;
        }
      }
      setMessageToCopy(msg);
      setSummary(summary);
      setNetPrice(String(nPrice));
      setQuoteQty(quoteQtyCalc);
      setnetFees(netFeeBps);
      setNetFeeQuote(netFeeQuote);
      setNetDclFeeQuote(dclFeeQuote);
      setNetVenueFeeQuote(venueFeeQuote);
      setDclOrderModified(false);
    }
  }, [
    assetList,
    clientList,
    dclFeeCalculationType,
    dclOrderDetail,
    dclOrderModified,
    defaultFeeObj,
    includeDelchainFee,
    includeVenueFee,
    netDclFeeQuote,
    quoteAmountApplied,
    transactionWayList,
  ]);
  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            itemMarginBottom: 10,
          },
        },
      }}
    >
      <Content id={"edit-order-builer"}>
        <Row>
          <Col md={{ span: 16 }} sm={{ span: 16 }}>
            <Button
              onClick={() => {
                navigate(
                  location.pathname.substring(
                    0,
                    location.pathname.indexOf("edit"),
                  ),
                );
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-circle-chevron-left" as IconProp}
              />
            </Button>
          </Col>
        </Row>
        {orderDetailFetched ? (
          <Form form={form} style={{ margin: "2em" }}>
            <Row justify={"space-between"}>
              <Col xxl={{ span: 5 }} lg={{ span: 5 }} md={{ span: 10 }}>
                <Form.Item
                  label={<b>Date</b>}
                  name="timestamp"
                  rules={[
                    {
                      required: true,
                      message: "Please select date!",
                    },
                  ]}
                  initialValue={dayjs(dclOrderDetail?.timestamp)}
                >
                  <DatePicker
                    onChange={(e) => {
                      onFieldChange("timestamp", e.toISOString());
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>dcl User</b>}
                  name="dclUserId"
                  rules={[
                    {
                      required: true,
                      message: "Please select user!",
                    },
                  ]}
                  initialValue={dclOrderDetail.dclUserId}
                >
                  <Select
                    showSearch
                    style={{ maxWidth: "180px" }}
                    popupMatchSelectWidth={false}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLocaleLowerCase()
                        .includes(input.toLocaleLowerCase());
                    }}
                    onChange={(e) => {
                      onFieldChange("dclUserId", e);
                    }}
                    options={userList}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Order Type</b>}
                  name="dclOrderTypeId"
                  rules={[
                    {
                      required: true,
                      message: "Please select order type!",
                    },
                  ]}
                  initialValue={dclOrderDetail.dlcOrderTypeId}
                >
                  <Select
                    showSearch
                    style={{ maxWidth: "180px" }}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLocaleLowerCase()
                        .includes(input.toLocaleLowerCase());
                    }}
                    options={dclOrderTypeList}
                    onChange={(value) => {
                      onFieldChange("dclOrderTypeId", value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Client</b>}
                  name="clientId"
                  rules={[
                    {
                      required: true,
                      message: "Please select client!",
                    },
                  ]}
                  initialValue={dclOrderDetail.clientId}
                >
                  <Select
                    style={{ maxWidth: "180px" }}
                    showSearch
                    popupMatchSelectWidth={false}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLocaleLowerCase()
                        .includes(input.toLocaleLowerCase());
                    }}
                    options={clientList}
                    onChange={(value) => {
                      onFieldChange("clientId", value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Venue</b>}
                  name="venueId"
                  initialValue={dclOrderDetail.venueId}
                >
                  <Select
                    showSearch
                    allowClear
                    style={{ maxWidth: "180px" }}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLocaleLowerCase()
                        .includes(input.toLocaleLowerCase());
                    }}
                    options={venueList}
                    onChange={(value) => {
                      onFieldChange("venueId", value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Venue Order Id</b>}
                  name="venueOrderId"
                  initialValue={dclOrderDetail.venueOrderId}
                >
                  <Select
                    style={{ maxWidth: "300px" }}
                    popupMatchSelectWidth={false}
                    placeholder="Open orders"
                    showSearch
                    mode="multiple"
                    onChange={(value: any) => {
                      onFieldChange("venueOrderId", value);
                    }}
                    filterOption={(input, option) => {
                      return (option?.label ?? "")
                        .toLowerCase()
                        .startsWith(input.toLowerCase());
                    }}
                    dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider
                          style={{
                            margin: "8px 0",
                          }}
                        />
                        <Row
                          style={{
                            padding: "0 8px 4px",
                          }}
                        >
                          <Input
                            placeholder="Please enter the order id"
                            ref={inputRef}
                            value={orderId}
                            onChange={onNameChange}
                          />
                        </Row>
                        <Row justify="end">
                          <Button type="primary" onClick={addItem}>
                            Add Order ID
                          </Button>
                        </Row>
                      </>
                    )}
                    options={openOrderList?.map((item) => ({
                      label: item,
                      value: item,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Is Canceled</b>}
                  name="isCanceled"
                  initialValue={dclOrderDetail.isCanceled}
                >
                  <Switch disabled />
                </Form.Item>
              </Col>
              <Col
                xxl={{ span: 5 }}
                xl={{ span: 6 }}
                lg={{ span: 5 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Is Executed</b>}
                  name="isExecuted"
                  initialValue={dclOrderDetail.isExecuted}
                >
                  <Switch disabled />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row align="top" justify={"space-between"}>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Base Currency</b>}
                  name="firstAssetId"
                  rules={[
                    {
                      required: true,
                      message: "Please select currency!",
                    },
                  ]}
                  style={{ marginBottom: "0px" }}
                  initialValue={dclOrderDetail.firstAssetId}
                >
                  <Select
                    showSearch
                    style={{ maxWidth: "180px" }}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLocaleLowerCase()
                        .includes(input.toLocaleLowerCase());
                    }}
                    popupMatchSelectWidth={false}
                    onChange={(e) => {
                      onFieldChange("firstAssetId", e);
                    }}
                    options={assetList}
                  />
                </Form.Item>
                <Row justify={"center"}>
                  <Col xxl={24} xl={24} lg={24} md={24} sm={24}>
                    <Row justify={"start"}>
                      <Button
                        className="dsp-button-usdt"
                        onClick={() => {
                          setBaseAsset("USDT");
                        }}
                      >
                        USDT
                      </Button>
                      <Button
                        className="dsp-button-usdc"
                        onClick={() => {
                          setBaseAsset("USDC");
                        }}
                      >
                        USDC
                      </Button>
                      <Button
                        className="dsp-button-btc"
                        onClick={() => {
                          setBaseAsset("BTC");
                        }}
                      >
                        BTC
                      </Button>

                      <Button
                        className="dsp-button-eth"
                        onClick={() => {
                          setBaseAsset("ETH");
                        }}
                      >
                        ETH
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Quote Currency</b>}
                  name="secondAssetId"
                  rules={[
                    {
                      required: true,
                      message: "Please select venue!",
                    },
                  ]}
                  initialValue={dclOrderDetail.secondAssetId}
                >
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    filterOption={(input, option) => {
                      return (String(option?.label) ?? "")
                        .toLocaleLowerCase()
                        .includes(input.toLocaleLowerCase());
                    }}
                    popupMatchSelectWidth={false}
                    onChange={(e) => {
                      onFieldChange("secondAssetId", e);
                    }}
                    options={assetList}
                  />
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item label={<b>Symbol</b>}>{symbol}</Form.Item>
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: "20px" }}>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={<b>Transaction Way</b>}
                  name="transactionWayId"
                  rules={[
                    {
                      required: true,
                      message: "Please select venue!",
                    },
                  ]}
                  initialValue={dclOrderDetail.transactionWayId}
                >
                  <Radio.Group
                    buttonStyle="solid"
                    onChange={(e) => {
                      onFieldChange("transactionWayId", e.target.value);
                    }}
                  >
                    {transactionWayList.map((transanctionWay: any) => (
                      <Radio.Button
                        key={transanctionWay.value}
                        value={transanctionWay.value}
                        className={`dcl-${transanctionWay.label?.toLocaleLowerCase()}`}
                      >
                        {transanctionWay.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label={<b>Base/Quote</b>}
                  name="isBase"
                  initialValue={dclOrderDetail.isBase}
                  rules={[
                    {
                      required: true,
                      message: "Please select base or quote!",
                    },
                  ]}
                >
                  <Radio.Group
                    onChange={(e) => {
                      onFieldChange("isBase", e.target.value);
                    }}
                  >
                    <Radio value={true}>Base</Radio>
                    <Radio value={false}>Quote</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                <Form.Item
                  label={
                    <b>
                      {dclOrderDetail.isBase ? "Base" : "Quote"} Amount (
                      {
                        assetList.find((row) =>
                          dclOrderDetail.isBase
                            ? row.value === dclOrderDetail.firstAssetId
                            : row.value === dclOrderDetail.secondAssetId,
                        )?.ticker
                      }
                      )
                    </b>
                  }
                  name="qty"
                  rules={[
                    {
                      type: "number",
                      message: "The input is not valid quantity!",
                    },
                    {
                      required: true,
                      message: "Please input correct quantity!",
                    },
                  ]}
                  initialValue={dclOrderDetail.qty}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    controls={false}
                    formatter={(value) => `${value?.toLocaleString("en-us")}`}
                    onChange={(e) => {
                      onFieldChange("qty", e);
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label={<b>Venue Price</b>}
                  name="price"
                  rules={[
                    {
                      type: "number",
                      message: "The input is not valid price!",
                    },
                    {
                      required: true,
                      message: "Please input correct price!",
                    },
                  ]}
                  initialValue={dclOrderDetail.price}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    controls={false}
                    formatter={(value) => `${value?.toLocaleString("en-us")}`}
                    onChange={(e) => {
                      onFieldChange("price", e);
                    }}
                  />
                </Form.Item>
                <Row justify={"space-between"}>
                  <Form.Item
                    label={<b>Venue Fee (BPS)</b>}
                    name="fee"
                    style={{ width: "90%" }}
                    rules={[
                      {
                        type: "number",
                        message: "The input is not valid fee!",
                      },
                      // {
                      //   required: true,
                      //   message: "Please input correct fee!",
                      // },
                    ]}
                    initialValue={dclOrderDetail.fee}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      controls={false}
                      formatter={(value) => `${value?.toLocaleString("en-us")}`}
                      onChange={(e) => {
                        onFieldChange("fee", e);
                      }}
                    />
                  </Form.Item>
                  <Tooltip title="Include Venue Fees">
                    <Form.Item
                      name="includeFees"
                      valuePropName="checked"
                      initialValue={dclOrderDetail.fee ? true : false}
                    >
                      <Checkbox
                        onChange={(e) => {
                          setDclOrderModified(true);
                          setIncludeVenueFee(e.target.checked);
                        }}
                      />
                    </Form.Item>
                  </Tooltip>
                </Row>
                <Row justify={"space-between"}>
                  {dclFeeCalculationType === "bps" ? (
                    <Form.Item
                      label={<b>Delchain Fee (BPS)</b>}
                      name="dclFee"
                      rules={[
                        {
                          type: "number",
                          message: "The input is not valid fee!",
                        },
                      ]}
                      style={{ width: "90%" }}
                      initialValue={dclOrderDetail.dclFee}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        formatter={(value) =>
                          `${value?.toLocaleString("en-us")}`
                        }
                        onChange={(e) => {
                          onFieldChange("dclFee", e);
                        }}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item label={<b>Delchain Fee (BPS)</b>}>
                      {dclOrderDetail.dclFee}
                    </Form.Item>
                  )}
                  <Tooltip title="Include Delchain Fees">
                    <Form.Item
                      name="includeDclFees"
                      valuePropName="checked"
                      initialValue={dclOrderDetail.fee ? true : false}
                    >
                      <Checkbox
                        onChange={(e) => {
                          setDclOrderModified(true);
                          setIncludeDelchainFee(e.target.checked);
                        }}
                      />
                    </Form.Item>
                  </Tooltip>
                </Row>
                <Row justify={"space-between"}>
                  <Form.Item
                    label={<b>Delchain fees calculation</b>}
                    name="dclFeeCalculationType"
                    style={{ width: "90%" }}
                    initialValue={"bps"}
                  >
                    <Radio.Group
                      options={feeCalculationTypeOptions}
                      onChange={(e) => {
                        setDclFeeCalculationType(e.target.value);
                      }}
                      value={dclFeeCalculationType}
                    />
                  </Form.Item>
                </Row>
              </Col>

              <Col
                xl={{ span: 6 }}
                lg={{ span: 8 }}
                md={{ span: 10 }}
                xs={{ span: 23 }}
                sm={{ span: 23 }}
              >
                {includeDelchainFee || includeVenueFee ? (
                  <React.Fragment>
                    <Form.Item label={<b>Total Fee (BPS)</b>}>
                      <Space className="calculated">
                        {netFees.toLocaleString("en-us")}
                      </Space>
                    </Form.Item>
                    <Form.Item
                      label={
                        <b>
                          Total Fee (
                          {
                            assetList.find(
                              (row) =>
                                row.value === dclOrderDetail.secondAssetId,
                            )?.ticker
                          }
                          )
                        </b>
                      }
                      style={
                        metMinimumFeeRequirement
                          ? {
                              color: "black",
                            }
                          : {
                              color: "red",
                            }
                      }
                    >
                      <Space className="calculated">
                        {isQuoteFiat
                          ? Number(
                              Number(netFeeQuote).toFixed(2),
                            ).toLocaleString("en-us", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : netFeeQuote.toLocaleString("en-us")}
                      </Space>
                    </Form.Item>
                    {includeVenueFee ? (
                      <Form.Item
                        label={
                          <b>
                            Venue Fee (
                            {
                              assetList.find(
                                (row) =>
                                  row.value === dclOrderDetail.secondAssetId,
                              )?.ticker
                            }
                            )
                          </b>
                        }
                      >
                        <Space className="calculated">
                          {isQuoteFiat
                            ? Number(
                                Number(netVenueFeeQuote).toFixed(2),
                              ).toLocaleString("en-us", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : netVenueFeeQuote.toLocaleString("en-us")}
                        </Space>
                      </Form.Item>
                    ) : undefined}
                    {includeDelchainFee ? (
                      <Form.Item
                        label={
                          <b>
                            Net Delchain Fee (
                            {
                              assetList.find(
                                (row) =>
                                  row.value === dclOrderDetail.secondAssetId,
                              )?.ticker
                            }
                            )
                          </b>
                        }
                        style={
                          metMinimumDclFeeRequirement
                            ? {
                                color: "black",
                              }
                            : {
                                color: "red",
                              }
                        }
                      >
                        {dclFeeCalculationType === "bps" ? (
                          <Space className="calculated">
                            {isQuoteFiat
                              ? Number(
                                  Number(netDclFeeQuote).toFixed(2),
                                ).toLocaleString("en-us", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : netDclFeeQuote.toLocaleString("en-us")}
                          </Space>
                        ) : (
                          <InputNumber
                            style={{ width: "100%" }}
                            formatter={(value) =>
                              `${value?.toLocaleString("en-us")}`
                            }
                            value={netDclFeeQuote}
                            onChange={onDclNetFeeQuoteChange}
                          />
                        )}
                      </Form.Item>
                    ) : undefined}
                  </React.Fragment>
                ) : (
                  <></>
                )}
                <Form.Item label={<b>Indicative Price</b>}>
                  <Space className="calculated">
                    {isBaseStablecoin
                      ? Number(
                          Number(netPrice.replaceAll(",", "")).toFixed(4),
                        ).toLocaleString("en-us", {
                          minimumFractionDigits: 4,
                          maximumFractionDigits: 4,
                        })
                      : netPrice}
                  </Space>
                </Form.Item>
                <Form.Item
                  label={
                    <b>
                      {dclOrderDetail.isBase ? "Quote" : "Base"} Amount (
                      {
                        assetList.find(
                          (row) => row.value === dclOrderDetail.secondAssetId,
                        )?.ticker
                      }
                      )
                    </b>
                  }
                >
                  <Space className="calculated">
                    {isQuoteFiat && dclOrderDetail.isBase
                      ? Number(quoteQty.toFixed(2)).toLocaleString("en-us", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : Number(
                          quoteQty.toFixed(isBaseStablecoin ? 4 : 6),
                        ).toLocaleString("en-us", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: isBaseStablecoin ? 4 : 6,
                        })}
                  </Space>
                </Form.Item>
                {minimumAmountComponent}
              </Col>
            </Row>
            <Divider />
            <Row justify={"space-between"}>
              <Col xl={{ span: 13 }} xs={{ span: 23 }} sm={{ span: 23 }}>
                <Form.Item
                  label={<b>Note</b>}
                  name="note"
                  initialValue={dclOrderDetail?.note}
                >
                  <TextArea
                    onChange={(e) => {
                      onFieldChange("note", e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xl={{ span: 13 }} xs={{ span: 23 }} sm={{ span: 23 }}>
                <Form.Item label={<b>Message</b>}>
                  <Space>
                    {messageToCopy}
                    <Button onClick={copyMessageToClipboard}>
                      <FontAwesomeIcon
                        icon={"fa-regular fa-copy" as IconProp}
                      />
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col xl={{ span: 13 }} xs={{ span: 23 }} sm={{ span: 23 }}>
                <Form.Item label={<b>Summary</b>}>
                  <Space>
                    {summary}
                    <Button onClick={copySummaryToClipboard}>
                      <FontAwesomeIcon
                        icon={"fa-regular fa-copy" as IconProp}
                      />
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Form.Item>
                <Button
                  danger
                  onClick={() => {
                    if (dclOrderId) fetchDclOrderDetail(dclOrderId);
                  }}
                >
                  Reset
                </Button>

                <Button
                  type="primary"
                  className="button-save-as-draft"
                  onClick={() => {
                    setShowConfirmModal(true);
                    setSaveType("draft");
                  }}
                >
                  Update as Draft
                </Button>
                <Button
                  type="primary"
                  className="button-save-as-canceled"
                  onClick={() => {
                    setShowConfirmModal(true);
                    setSaveType("canceled");
                  }}
                >
                  Update as Canceled
                </Button>
                <Button
                  type="primary"
                  className="button-save-as-executed"
                  onClick={() => {
                    setShowConfirmModal(true);
                    setSaveType("executed");
                  }}
                  style={{
                    marginRight: "30px",
                  }}
                >
                  Update as Executed
                </Button>
              </Form.Item>
            </Row>
          </Form>
        ) : (
          <Row justify={"center"}>
            <Spin size={"large"} />
          </Row>
        )}
      </Content>
      <Modal
        width={"80%"}
        open={showConfirmModal}
        okText={
          saveType
            ? `Save as ${saveType.charAt(0).toUpperCase() + saveType.slice(1)}`
            : "Confirm"
        }
        cancelButtonProps={{
          danger: true,
          loading: !orderDetailFetched,
        }}
        okButtonProps={{
          loading: !orderDetailFetched,
        }}
        onOk={() => {
          submitData(saveType);
        }}
        onCancel={() => {
          setSaveType("");
          setShowConfirmModal(false);
        }}
      >
        <DclQuoteConfirmationModal
          date={dayjs(form.getFieldValue("timestamp"))
            .tz("America/New_York", true)
            .format()}
          isBase={dclOrderDetail?.isBase}
          symbol={symbol}
          venue={
            venueList.find(
              (row: any) => row.value === form.getFieldValue("venueId"),
            )?.label
          }
          orderType={
            dclOrderTypeList.find(
              (row: any) => row.value === form.getFieldValue("dclOrderTypeId"),
            )?.label
          }
          client={
            clientList.find((row) => row.value === dclOrderDetail?.clientId)
              ?.clientName
          }
          transactionWay={
            transactionWayList.find(
              (row) => row.value === dclOrderDetail?.transactionWayId,
            )?.label
          }
          qty={form.getFieldValue("qty")}
          price={form.getFieldValue("price")}
          venueFee={form.getFieldValue("fee") ? form.getFieldValue("fee") : 0}
          dclFee={
            dclFeeCalculationType === "bps"
              ? form.getFieldValue("dclFee")
                ? form.getFieldValue("dclFee")
                : 0
              : dclOrderDetail.dclFee
          }
          note={form.getFieldValue("note")}
          msg={messageToCopy}
          summary={summary}
        />
      </Modal>
    </ConfigProvider>
  );
}

export default EditDclQuote;
