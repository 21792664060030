import { Input } from "antd";
import React from "react";
const InputComponent: React.FunctionComponent<any> = ({
  type,
  onChange,
  className,
  disabled,
  prefix,
  placeholder,
  autoFocus,
  onPaste,
  value,
}) => {
  if (type === "password") {
    return (
      <Input.Password
        value={value}
        prefix={prefix}
        placeholder={placeholder}
        onChange={onChange}
        className={className}
        disabled={disabled}
        autoFocus={autoFocus}
        onPaste={onPaste}
        autoComplete="on"
      />
    );
  }
  return (
    <Input
      prefix={prefix}
      placeholder={placeholder}
      onChange={onChange}
      className={className}
      disabled={disabled}
      autoFocus={autoFocus}
      autoComplete="on"
    />
  );
};

export default InputComponent;
