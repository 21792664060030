import { Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewOperations from "./ViewOperations";
import ViewXTrades from "./ViewXTrades";

export default function OperationContainer() {
  const { tabName = "operations" } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!["operations", "x-trades"].includes(tabName)) {
      navigate("/bo/operation/view");
    }
  }, [navigate, tabName]);

  return (
    <Content id="view-operation-container">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/operation/view/" + key);
        }}
        activeKey={tabName}
        items={[
          {
            label: "Operations",
            key: "operations",
            children: (
              <React.Fragment>
                <ViewOperations />
              </React.Fragment>
            ),
          },
          {
            label: "Cross Trades",
            key: "x-trades",
            children: (
              <React.Fragment>
                <ViewXTrades />
              </React.Fragment>
            ),
          },
        ]}
      />
    </Content>
  );
}
