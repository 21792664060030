import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Input, Radio, Row, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../../../assets/scss/create-user.scss";
import { UserContext } from "../../Context/userContext";

export const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 18,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};

function CreateUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(UserContext);
  const [form] = Form.useForm();
  const [roleList, setRoleList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [mobileNum, setMobileNum] = useState<string>("");
  const [officeNum, setOfficeNum] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRole, setSelectedRole] = useState<any>();
  const [clientList, setClientList] = useState<any[]>();
  const [clientMultiSelect, setClientMultiSelect] = useState<any>();
  const [langOptions, setLangOptions] = useState<any[]>();
  const [roleGroup, setRoleGroup] = useState<string>("");

  //const [serviceOptions, setServiceOptions] = useState<any[]>();
  // const [serviceSelect, setServiceSelect] = useState<any>();

  useEffect(() => {
    // Fetch the roles from DB
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/role",
      withCredentials: true,
    })
      .then((res) => {
        setRoleList(res.data);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
        // toast.error(, {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        // });
      });

    // Fetch all the Countries
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/country",
      withCredentials: true,
    })
      .then((res) => {
        setCountryList(res.data);
      })
      .catch(() => {
        console.log("Unable to fetch the country list");
      });

    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/langs",
      withCredentials: true,
    })
      .then((res) => {
        setLangOptions(res.data.langs);
      })
      .catch(() => {
        console.log("Unable to fetch the langs");
      });
  }, [context]);
  useEffect(() => {
    if (roleGroup === "client" && !clientList) {
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/client",
        withCredentials: true,
      }).then((res) => {
        for (const client of res.data.clients) {
          client.label = client.clientName;
          client.value = client.id;
        }
        setClientList(res.data.clients);
      });
    }
  }, [roleGroup, clientList]);

  useEffect(() => {
    if (
      clientList &&
      !clientMultiSelect &&
      roleGroup &&
      roleGroup === "client"
    ) {
      setClientMultiSelect(
        <React.Fragment>
          <Form.List name="clients">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <React.Fragment key={key}>
                    <Row>
                      <Col sm={{ span: 8, offset: 1 }}>
                        <Form.Item
                          wrapperCol={{
                            sm: {
                              span: 24,
                              offset: 0,
                            },
                          }}
                          {...restField}
                          name={[name, "id"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing Client",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Please select clients"
                            filterOption={(input, option: any) => {
                              return option.children
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }}
                          >
                            {clientList?.map((client) => (
                              <Select.Option
                                key={client.clientName}
                                value={client.id}
                              >
                                {client.clientName}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={{ span: 8, offset: 1 }}>
                        <Form.Item
                          wrapperCol={{
                            sm: {
                              span: 24,
                              offset: 0,
                            },
                          }}
                          {...restField}
                          name={[name, "roleId"]}
                          rules={[{ required: true, message: "Missing Role" }]}
                        >
                          <Select placeholder="Please select role for this client">
                            {roleList.map((role: any) =>
                              role.roleName.startsWith(roleGroup) ? (
                                <Select.Option
                                  value={role["id"]}
                                  key={role["id"]}
                                >
                                  {role["roleName"]}
                                </Select.Option>
                              ) : undefined,
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={{ offset: 1, span: 2 }}>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Col>
                    </Row>
                  </React.Fragment>
                ))}
                <Form.Item
                  wrapperCol={{
                    sm: {
                      span: 12,
                      offset: 6,
                    },
                  }}
                >
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add New Client
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </React.Fragment>,
      );
    } else if (clientList && clientMultiSelect && roleGroup !== "client") {
      setClientMultiSelect(undefined);
    }
  }, [clientList, roleGroup, clientMultiSelect, form, roleList]);

  // useEffect(() => {
  //   if (serviceOptions) {
  //     setServiceSelect(
  //       <React.Fragment>
  //         <Form.Item label="Service" name="service">
  //           <Select
  //             placeholder="Please select service"
  //             filterOption={(input, option: any) => {
  //               return option.children
  //                 .toLowerCase()
  //                 .includes(input.toLowerCase());
  //             }}
  //           >
  //             {serviceOptions.map((service) => (
  //               <Select.Option key={service.name} value={service.id}>
  //                 {service.name}
  //               </Select.Option>
  //             ))}
  //           </Select>
  //         </Form.Item>
  //       </React.Fragment>
  //     );
  //   }
  // }, [serviceOptions]);
  const onFinish = (form: any) => {
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/create",
      withCredentials: true,
      data: {
        username: form.username,
        email: form.email,
        firstName: form.firstName,
        lastName: form.lastName,
        roleId: form.role,
        countryId: form.country,
        mobileNum: form.mobileNum,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        postcode: form.postcode,
        state: form.state,
        langId: form.mainLang,
        telegramUsername: form.telegramAcc,
        officeNum: form.officeNum,
        serviceId: form.service,
        clients:
          form.clients && form.userRoleGroup === "client"
            ? form.clients
            : undefined,
      },
    })
      .then((res) => {
        navigate("/bo/confirmation", {
          state: {
            pageType: "User",
            createOrUpdate: "Create",
            displayName: form.email,
            createUrl: "/bo/user/create",
            editUrl:
              "/bo/user/update/" +
              encodeURIComponent(res.data.userId ? res.data.userId : ""),
          },
        });
        toast.success("User created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        toast.error("Unable to create the user", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    toast.error("Please fill required fields ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log("Failed:", errorInfo);
  };

  return (
    <React.Fragment>
      <Content>
        <Row>
          <Col md={{ span: 16 }} sm={{ span: 16 }}>
            <Button
              onClick={() => {
                if (location.state) {
                  navigate(location.state.from);
                } else {
                  navigate("/bo/users");
                }
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-circle-chevron-left" as IconProp}
              />
            </Button>
          </Col>
        </Row>
        <Form
          {...formItemLayout}
          form={form}
          labelWrap
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="form-body"
          labelCol={{
            sm: {
              span: 7,
              offset: 1,
            },
          }}
          wrapperCol={{
            sm: {
              span: 14,
              offset: 1,
            },
          }}
          style={{
            padding: "1em",
            margin: "auto",
          }}
        >
          {/* <Row justify="center" align="middle">
            <h2>CREATE USER</h2>
          </Row> */}
          <Row>
            <Col sm={{ span: 12, order: 1 }} xs={{ span: 24, order: 2 }}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input user's email",
                  },
                ]}
              >
                <Input placeholder="Please enter email" />
              </Form.Item>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please input user's first name!",
                  },
                ]}
              >
                <Input placeholder="Please enter first name" />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please input user's last name!",
                  },
                ]}
              >
                <Input placeholder="Please enter last name" />
              </Form.Item>
              <Form.Item
                label="Address Line 1"
                name="addressLine1"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please input user's address!",
                  },
                ]}
              >
                <Input placeholder="Please enter address" />
              </Form.Item>
              <Form.Item
                label="Address Line 2"
                name="addressLine2"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Postcode"
                name="postcode"
                rules={[{ required: false }]}
              >
                <Input placeholder="Please enter postcode" />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please enter user's city!",
                  },
                ]}
              >
                <Input placeholder="Please enter city" />
              </Form.Item>

              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: false,
                    message: "Please input user's state!",
                  },
                ]}
              >
                <Input placeholder="Please enter state" />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please select user's Country",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Please select country"
                >
                  {countryList.map((country) => (
                    <Select.Option
                      value={country["id"]}
                      key={country["id"]}
                      id={country["id"]}
                    >
                      {country["nicename"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                name="mobileNum"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please enter user's Phone number",
                  },
                ]}
              >
                <PhoneInput
                  flags={flags}
                  placeholder="Please enter phone number"
                  value={mobileNum}
                  onChange={(e: any) => setMobileNum(e)}
                />
              </Form.Item>
              <Form.Item label="Office Number" name="officeNum">
                <PhoneInput
                  flags={flags}
                  placeholder="Please enter office phone number"
                  value={officeNum}
                  onChange={(e: any) => setOfficeNum(e)}
                />
              </Form.Item>
              <Form.Item label="Main Language" name="mainLang">
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Please select main language"
                >
                  {langOptions?.map((lang) => (
                    <Select.Option
                      value={lang["id"]}
                      key={lang["name"]}
                      id={lang["id"]}
                    >
                      {lang["name"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Telegram Account(e.g. @username)"
                name="telegramAcc"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={{ span: 12, order: 2 }} xs={{ span: 24, order: 1 }}>
              <Form.Item
                label="User Role Group"
                name="userRoleGroup"
                rules={[
                  {
                    required: true,
                    message: "Missing Role Group",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setRoleGroup(e.target.value);
                  }}
                >
                  <Radio value="delchain">Delchain</Radio>
                  <Radio value="client">Client</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Role"
                name="role"
                hidden={roleGroup !== "delchain"}
                rules={[
                  {
                    required: roleGroup === "delchain",
                    message: "Please select user's role!",
                  },
                ]}
              >
                <Select
                  onChange={(roleId) => {
                    const role = roleList.filter((obj) => {
                      return obj["id"] === roleId;
                    });
                    setSelectedRole(role[0]);
                  }}
                >
                  {roleList.map((role: any) =>
                    role.roleName.startsWith(roleGroup) ? (
                      <Select.Option value={role["id"]} key={role["id"]}>
                        {role["roleName"]}
                      </Select.Option>
                    ) : undefined,
                  )}
                </Select>
              </Form.Item>
              {clientMultiSelect}
              {/* {serviceSelect}
              <Form.Item
                label="Service Username"
                name="username"
                rules={[
                  {
                    required: false,
                    message: "Please input user's username!",
                  },
                ]}
              >
                <Input placeholder="Enter username" />
              </Form.Item> */}
            </Col>
          </Row>

          <Form.Item {...tailFormItemLayout}>
            <Button htmlType="submit" className="submit-button primary-button">
              Create User
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </React.Fragment>
  );
}
export default CreateUser;
