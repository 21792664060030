import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Input, Row, Select, Spin, Switch } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { tailFormItemLayout } from "../UserPages/CreateUser";

function EditVenue() {
  const { venueId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [venueTypeList, setVenueTypeList] = useState<any[]>([]);
  const [initialValues, setInitialValues] = useState<any>();
  const [form] = Form.useForm();

  const fetchVenueInfo = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue",
      params: {
        venueId: venueId,
      },
      withCredentials: true,
    })
      .then((res) => {
        setInitialValues(res.data.info);
      })
      .catch(() => {
        toast.error("Failed to get venue info");
        navigate("/bo/settings/venues");
      });
  }, [venueId, navigate]);

  const fetchVenueTypeList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/types",
      withCredentials: true,
    })
      .then((res) => {
        res.data.venueTypeList.forEach((t: any) => {
          t.label = t.name;
          t.value = t.id;
        });
        setVenueTypeList(res.data.venueTypeList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onFinish = (values: any) => {
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/update",
      data: { id: venueId, ...values },
      withCredentials: true,
    })
      .then(() => {
        toast.success(`Venue ${values.name} updated successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        navigate("/bo/settings/venues/");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        console.error(err);
        toast.error("Failed to update venue");
      });
  };

  useEffect(() => {
    fetchVenueInfo();
    fetchVenueTypeList();
  }, [fetchVenueInfo, fetchVenueTypeList]);

  return (
    <Content id="dcl-edit-venue-page">
      <Row>
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Button
            onClick={() => {
              if (location.state) {
                navigate(location.state.from, { state: location.state });
              } else {
                navigate("/bo/settings/venues");
              }
            }}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-chevron-left" as IconProp}
            />
          </Button>
        </Col>
      </Row>
      <Row justify={"center"}>
        <Col md={20} sm={24} lg={18}>
          {initialValues && initialValues.id ? (
            <Form
              form={form}
              onFinish={onFinish}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              layout="horizontal"
              initialValues={initialValues}
              size="large"
            >
              <Form.Item
                label="Venue Name"
                name="name"
                rules={[
                  { required: true, message: "Please input the venue name!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Venue Type"
                name="typeId"
                rules={[
                  { required: true, message: "Please input the type ID!" },
                ]}
              >
                <Select options={venueTypeList} />
              </Form.Item>
              <Form.Item
                label="CCXT Key"
                name="ccxtKey"
                rules={[
                  { required: true, message: "Please input the CCXT key!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Venue Active Status"
                name="isActive"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Display Venue in Tech Settings"
                name="toFetch"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Included in Trading Reports"
                name="inLists"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Fetch Deposit Address"
                name="depositAddressToFetch"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button primary-button"
                >
                  Update Venue
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Row justify={"center"}>
              <Spin size="large" />
            </Row>
          )}
        </Col>
      </Row>
    </Content>
  );
}
export default EditVenue;
