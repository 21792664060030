import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Input,
  Layout,
  Modal,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function ViewCapabilityList() {
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [capList, setCapList] = useState<any[]>([]);
  const navigate = useNavigate();
  const [isModalOpen, setModalIsOpen] = useState<boolean>(false);
  const [newCapabilityName, setNewCapabilityName] = useState<string>("");
  const [newCapabilityKey, setNewCapabilityKey] = useState<string>("");
  const [newSort, setNewSort] = useState<string>("");
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  const handleCancel = () => {
    setNewCapabilityName("");
    setNewSort("");
    setNewCapabilityKey("");
    setModalIsOpen(false);
  };

  const handleOk = () => {
    if (!Number(newSort) && newSort !== "0") {
      toast.warning("Please input a number in sort field", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setModalLoading(true);
    submitNewCapability()
      .then(() => {
        toast.success("Successfully created new capability.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        toast.error("Failed to create capability", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setModalLoading(false);
        setModalIsOpen(false);
        setNewSort("");
        setNewCapabilityName("");
        setNewCapabilityKey("");
        fetchData();
      });
  };

  const submitNewCapability = async () => {
    return axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/create-capability",
      withCredentials: true,
      data: {
        capabilityName: newCapabilityName,
        key: newCapabilityKey,
        sort: newSort,
      },
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: "5%",
      editable: false,
    },
    {
      title: "Capability Nice Name",
      dataIndex: "name",
      width: "20%",
      editable: false,
      render: (text: any) => <strong>{text}</strong>,
    },
    {
      title: "Capability Key",
      dataIndex: "key",
      width: "20%",
      editable: false,
      render: (text: any) => <strong>{text}</strong>,
    },
    {
      title: "Capability Sort",
      dataIndex: "sort",
      width: "20%",
      editable: false,
      render: (text: any) => <strong>{text}</strong>,
    },
  ];

  const fetchData = useCallback(() => {
    setOnFinishLoad(false);
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/capability",
      withCredentials: true,
    })
      .then((res) => {
        setCapList(res.data);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return onFinshiLoad ? (
    <React.Fragment>
      <Content>
        <Row justify="end" style={{ marginRight: "10px" }}>
          <Button
            type="primary"
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-plus" as IconProp}
              className="white-plus"
            />
            &nbsp; New Capability
          </Button>
        </Row>
        <Table
          rowKey={"id"}
          sticky
          scroll={{ y: 1000 }}
          columns={columns}
          dataSource={capList}
          pagination={{ defaultPageSize: 100 }}
        />
      </Content>

      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={modalLoading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <Row align={"middle"}>
          <Col sm={{ span: 10 }}>
            <Space style={{ color: "white" }}>
              <h3>Capability nice name:</h3>
            </Space>
          </Col>
          <Col sm={{ span: 12, offset: 1 }}>
            <Input
              value={newCapabilityName}
              onChange={(e) => {
                setNewCapabilityName(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row align={"middle"}>
          <Col sm={{ span: 10 }}>
            <Space style={{ color: "white" }}>
              <h3>Capability key:</h3>
            </Space>
          </Col>
          <Col sm={{ span: 12, offset: 1 }}>
            <Input
              value={newCapabilityKey}
              onChange={(e) => {
                setNewCapabilityKey(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row align={"middle"}>
          <Col sm={{ span: 10 }}>
            <Space style={{ color: "white" }}>
              <h3>Sort:</h3>
            </Space>
          </Col>
          <Col sm={{ span: 12, offset: 1 }}>
            <Input
              value={newSort}
              onChange={(e) => {
                setNewSort(e.target.value);
              }}
            />
          </Col>
        </Row>
      </Modal>
    </React.Fragment>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
export default ViewCapabilityList;
