import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";
import DclQuoteConfirmationModal from "../MasterLedgerPage/OrderBuilderComponents/DclQuoteConfirmModal";
import CreateXTradeModal from "./CreateXTradeModal";
import CreateXTradeOrder from "./CreateXTradeOrder";
import ViewOperationSummary from "./ViewOperationSummary";

const DragHandle = ({ attributes, listeners, isDragging }: any) => (
  <span
    {...attributes}
    {...listeners}
    style={{ cursor: isDragging ? "grabbing" : "grab", marginRight: 8 }}
  >
    <FontAwesomeIcon icon={"fa-solid fa-grip-vertical" as IconProp} />
  </span>
);

const DraggableRow = ({
  id,
  index,
  moveRow,
  style,
  children,
  ...restProps
}: any) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id?.toString() });

  const enhancedStyle = {
    ...style,
    transform: CSS.Transform.toString(transform),
    transition: transition || "transform 250ms ease",
  };

  // Clone the first child (cell) and append the DragHandle to it
  const modifiedChildren = React.Children.map(children, (child, i) => {
    if (i === 0) {
      // Assuming the first column is where you want the handle
      return React.cloneElement(child, {
        ...child.props,
        children: [
          <DragHandle
            key="drag-handle"
            attributes={attributes}
            listeners={listeners}
            isDragging={isDragging}
          />,
          child.props.children,
        ],
      });
    }
    return child;
  });

  return (
    <tr ref={setNodeRef} style={enhancedStyle} {...restProps}>
      {modifiedChildren}
    </tr>
  );
};

export default function EditOperationDetails() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const [canEditTerminated, setCanEditTerminated] = useState<boolean>(false);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const columns = [
    {
      title: "",
      dataIndex: "step",
      width: "30px",
      className: "drag-visible",
      render: () => <></>, // Render nothing here, since the handle is part of DraggableRow
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_: any, { typeLabel }: any) => <b>{typeLabel}</b>,
      width: "245px",
    },
    {
      title: "ID",
      dataIndex: "referenceRealId",
      key: "referenceRealId",
      width: "200px",
    },
    {
      title: "Timestamp",
      dataIndex: "referenceTimestamp",
      key: "referenceTimestamp",
      width: "200px",
      render: (_: any, { referenceTimestamp }: any) =>
        new Date(referenceTimestamp)?.toLocaleString("en-us", {
          timeZone: displayTimezone,
          timeZoneName: "short",
        }),
    },
    {
      title: "Venue",
      dataIndex: "referenceVenue",
      key: "referenceVenue",
      width: "90px",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "200px",
      render: (_: any, { operationItem }: any) => (
        <p style={{ whiteSpace: "pre-line", color: "black" }}>
          {operationItem?.description}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "referenceAmount",
      key: "referenceAmount",
      align: "right" as const,
      render: (_: any, { referenceAmount, typeLabel }: any) => {
        const formatAmount = (amount: number, isNegative = false) =>
          `${isNegative ? "-" : ""}${amount.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}`;
        const lowerTypeLabel = typeLabel?.toLocaleLowerCase() || "";
        const isEbanking = lowerTypeLabel.includes("ebanking");
        const isFee = lowerTypeLabel.includes("fee");
        const isWithdrawal = lowerTypeLabel.includes("withdrawal");
        const isDeposit = lowerTypeLabel.includes("deposit");

        if (isFee) {
          return formatAmount(referenceAmount);
        }

        if (isWithdrawal && !isEbanking) {
          return formatAmount(referenceAmount, true);
        }

        if (isDeposit) {
          return formatAmount(referenceAmount);
        }

        return formatAmount(referenceAmount);
      },
      width: "150px",
    },
    {
      title: "Currency",
      dataIndex: "referenceCurrency",
      key: "referenceCurrency",
      width: "100px",
    },
    {
      title: "Is Client Transfer",
      dataIndex: "isClientTransfer",
      key: "isClientTransfer",
      width: "120px",
      render: (_: any, { operationItem, operationItemSubtype }: any) => (
        <Row>
          {!operationItemSubtype.addOnLabel &&
          operationItem.isClientTransfer ? (
            // is Client Transfer
            operationItem.isVenueWalletRelated ? (
              // Kept on venue
              operationItem.operationItemType?.key?.includes("deposit") ? (
                <Tooltip title="Take asset from venue wallet">
                  <FontAwesomeIcon
                    icon={"fa-solid fa-circle-arrow-down" as IconProp}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Keep asset on venue wallet">
                  <FontAwesomeIcon
                    icon={"fa-solid fa-circle-arrow-up" as IconProp}
                  />
                </Tooltip>
              )
            ) : // took out
            operationItem.operationItemType?.key?.includes("deposit") ? (
              <Tooltip title="Client Deposit">
                <FontAwesomeIcon
                  icon={"fa-solid fa-arrow-right-to-bracket" as IconProp}
                />
              </Tooltip>
            ) : (
              <Tooltip title="Withdrawal to Client">
                <FontAwesomeIcon
                  icon={"fa-solid fa-arrow-right-from-bracket" as IconProp}
                />
              </Tooltip>
            )
          ) : undefined}
        </Row>
      ),
    },
    // Add other columns as needed
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const { operationId } = useParams();

  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false);

  const [operationLabel, setOperationLabel] = useState<string>("");
  const [operationDescription, setOperationDescription] = useState<string>("");
  const [operationCategoryDetail, setOperationCategoryDetail] = useState<any[]>(
    [],
  );
  const [dclQuotes, setDclQuotes] = useState<any[]>([]);
  const [operationItemDetails, setOperationItemDetails] = useState<any[]>([]);
  const [operationStatus, setOperationStatus] = useState<string>();
  const [operationStatusList, setOperationStatusList] = useState<any[]>([]);
  const [selectedOperationStatusId, setSelectedOperationStatusId] =
    useState<any>();
  const [clientName, setClientName] = useState<string>("");
  const [clientId, setClientId] = useState<string>("");
  const [showTerminateModal, setShowTerminateModal] = useState<boolean>(false);

  const [isXTrade, setIsXTrade] = useState<boolean>(false);
  const [isPropTrade, setIsPropTrade] = useState<boolean>(false);
  const [showCreateXTradeModal, setShowCreateXTradeModal] =
    useState<boolean>(false);
  const [xTradesSelected, setXTradesSelected] = useState<number[]>([]);
  const [xTradesSelectedDetails, setXTradesSelectedDetails] = useState<any[]>(
    [],
  );
  const [xTradeOptions, setXTradeOptions] = useState<any[]>();
  const [deleteReference, setDeleteReference] = useState<any>(undefined);
  const [operationLabels, setOperationLabels] = useState<string[]>([]);

  const [operationWay, setOperationWay] = useState<string>("");
  const [dclFeeEqual, setDclFeeEqual] = useState<boolean>(true);
  const [calculatedDclFee, setCalculatedDclFee] = useState<number>(0);
  const [canUnterminate, setCanUnterminate] = useState<boolean>(false);
  const [showUnterminateModal, setShowUnterminateModal] =
    useState<boolean>(false);

  const [showCreateXTradeOrderModal, setShowCreateXTradeOrderModal] =
    useState<boolean>(false);

  const [selectedOperationType, setSelectedOperationType] = useState<number>();
  const [operationTypes, setOperationTypes] = useState<any>();

  const [xTradeSelectedOptions, setXTradeSelectedOptions] = useState<any[]>([]);
  const [colorMap, setColorMap] = useState<any>({});

  const [transactionWayId, setTrasactionWayId] = useState<number>();
  const [transactionWayList, setTransactionWayList] = useState<any[]>([]);
  const [opDate, setOpDate] = useState<any>();

  const [orderSymbolMap, setOrderSymbolMap] = useState<any>();
  const getTransactionWayList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/transaction-way",
      params: { type: "trade" }, // Buy and Sell Specific
      withCredentials: true,
    }).then((res) => {
      setTransactionWayList(res.data);
    });
  }, []);
  useEffect(() => {
    getTransactionWayList();
  }, [getTransactionWayList]);
  useEffect(() => {
    const opIds = [
      ...new Set(operationItemDetails.map((row) => row.operationItemId)),
    ];
    let count = 0;
    const map = new Map();
    for (const opId of opIds) {
      map.set(opId, count % 2 ? "operation-odd" : "operation-even");
      count += 1;
    }
    setColorMap(map);
  }, [operationItemDetails]);
  const getRowClassName = useCallback(
    (opId: number) => {
      const className = colorMap?.get(opId);
      return className;
    },
    [colorMap],
  );
  const fetchOperationTypes = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/types",
      withCredentials: true,
    }).then((res) => {
      setOperationTypes(res.data);
    });
  }, []);
  useEffect(() => {
    fetchOperationTypes();
  }, [fetchOperationTypes]);

  const fetchXTradeList = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/x-trade/list",
      withCredentials: true,
    }).then((res) => {
      setXTradeOptions(res.data.xTradeList);
    });
  }, []);

  useEffect(() => {
    fetchXTradeList();
  }, [fetchXTradeList]);

  useEffect(() => {
    if (xTradeOptions && xTradeOptions.length) {
      setXTradeSelectedOptions(
        xTradeOptions.filter((row) => xTradesSelected.includes(row.value)),
      );
    }
  }, [xTradesSelected, xTradeOptions]);

  const fetchOperationStatusList = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/status",
      withCredentials: true,
    }).then((res) => {
      setOperationStatusList(res.data);
    });
  }, []);

  useEffect(() => {
    fetchOperationStatusList();
  }, [fetchOperationStatusList]);

  const fetchOperationItems = useCallback(() => {
    setOnFinishLoad(false);
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/item",
      withCredentials: true,
      params: {
        operationId,
      },
    })
      .then((res) => {
        setIsPropTrade(res.data.operation?.isPropTrade);
        setIsXTrade(res.data.operation?.hasXTrade);
        if (res.data.operation?.hasXTrade) {
          const xTradesIds = [
            ...new Set(
              res.data.operation?.links?.map((row: any) =>
                Number(row.opertionXTradeId),
              ),
            ),
          ] as number[];
          setXTradesSelected(xTradesIds);
        }
        setOperationItemDetails(res.data.operationItems);
        setOperationCategoryDetail(res.data.operationCategoriesMap);
        setOperationWay(res.data.operationWay);
        setDclFeeEqual(res.data.dclFeeEqual);
        setCalculatedDclFee(res.data.calculatedDclFee);
        setOperationLabel(res.data.operation?.label);
        if (res.data.operation?.opDate) {
          setOpDate(dayjs(res.data.operation?.opDate));
        }
        setSelectedOperationType(res.data.operation?.operationTypeId);
        setOperationStatus(res.data.operation?.operationStatus?.name);
        setOperationDescription(res.data.operation?.description);
        setDclQuotes(res.data.operation?.dclQuotes);
        setClientName(res.data.operation?.client?.clientName);
        setClientId(res.data.operation?.clientId);
        setCanUnterminate(res.data.canUnterminate);
        setCanEditTerminated(res.data.canEditTerminated);
        setTrasactionWayId(res.data.operation?.transactionWayId);
        setOrderSymbolMap(res.data.orderSymbolMap);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        toast.error("Failed to get operation items");
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, [navigate, operationId]);

  const fetchOperationItemsWithReference = useCallback(() => {
    if (
      !deleteReference ||
      !deleteReference.reference ||
      !deleteReference.referenceId
    )
      return;
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/item/reference",
      withCredentials: true,
      params: {
        referenceId: deleteReference.referenceId,
        reference: deleteReference.reference,
      },
    }).then((res) => {
      const operationLabels = res.data.operationItems.map(
        (row: any) => row.operation?.label,
      );
      setOperationLabels(operationLabels);
    });
  }, [deleteReference]);

  useEffect(() => {
    fetchOperationItemsWithReference();
  }, [fetchOperationItemsWithReference]);

  const deleteReferenceFromDB = useCallback(() => {
    if (
      !deleteReference ||
      !deleteReference.reference ||
      !deleteReference.referenceId
    )
      return;
    axios({
      method: "Delete",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/item/reference",
      withCredentials: true,
      data: {
        referenceId: deleteReference.referenceId,
        reference: deleteReference.reference,
      },
    })
      .then(() => {
        toast.success("Deleted Successfully.");
      })
      .catch(() => {
        toast.error("Failed to delete");
      })
      .finally(() => {
        setOperationLabels([]);
        fetchOperationItems();
      });
  }, [deleteReference, fetchOperationItems]);

  const fetchXTradeDetails = useCallback(() => {
    if (xTradesSelected && xTradesSelected.length) {
      axios({
        method: "Get",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/operations/item/x-trade",
        params: {
          xTradeIds: JSON.stringify(xTradesSelected),
          operationId: operationId,
        },
        withCredentials: true,
      }).then((res) => {
        setXTradesSelectedDetails(res.data);
      });
    } else {
      return;
    }
  }, [operationId, xTradesSelected]);

  useEffect(() => {
    fetchXTradeDetails();
  }, [fetchXTradeDetails]);

  const updateOperationSteps = useCallback(
    (isTerminate = false) => {
      let index = 1;
      setIsUpdating(true);
      const data = [];
      for (const row of operationItemDetails) {
        data.push({
          id: row.id,
          step: index,
          operationXTradeId: row.operationXTradeId
            ? row.operationXTradeId
            : null,
        });
        index += 1;
      }
      let startDate, endDate;
      if (operationItemDetails?.length) {
        startDate = new Date(
          Math.min.apply(
            null,
            operationItemDetails.map((row) =>
              new Date(row.referenceTimestamp).valueOf(),
            ),
          ),
        );
        endDate = new Date(
          Math.max.apply(
            null,
            operationItemDetails.map((row) =>
              new Date(row.referenceTimestamp).valueOf(),
            ),
          ),
        );
      }
      axios({
        method: "Put",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/item",
        withCredentials: true,
        data: {
          isTerminate: isTerminate,
          operationId: operationId,
          operationStatusId: selectedOperationStatusId,
          operationItems: data,
          startDate,
          endDate,
        },
      })
        .then(() => {
          setIsUpdating(false);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
          toast.error("Failed to update operation items");
        })
        .finally(() => {
          if (isTerminate && !canEditTerminated) {
            navigate("/bo/operation/view");
          } else {
            fetchOperationItems();
          }
        });
    },
    [
      // location,
      canEditTerminated,
      navigate,
      operationId,
      operationItemDetails,
      selectedOperationStatusId,
      fetchOperationItems,
    ],
  );

  const updateOperation = useCallback(() => {
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations",
      withCredentials: true,
      data: {
        operationId,
        label: operationLabel,
        description: operationDescription,
        isXTrade,
        isPropTrade,
        operationTypeId: selectedOperationType,
        xTradeIds: xTradesSelected,
        transactionWayId,
        opDate: opDate,
      },
    })
      .then(() => {
        toast.success("Operation Updated");
      })
      .catch((err) => {
        if (err.response.status === 409) {
          toast.error(
            "Duplicate operation label. Please check the data you entered.",
          );
        } else if (err.response.status === 403) {
          navigate("/login");
        } else
          toast.error("Failed to update. Please check the data you entered.");
      })
      .finally(() => {
        setSelectedOperationStatusId(undefined);
      });
  }, [
    navigate,
    isXTrade,
    isPropTrade,
    xTradesSelected,
    operationId,
    operationLabel,
    operationDescription,
    selectedOperationType,
    transactionWayId,
    opDate,
  ]);
  const [showDeleteVirtualOrderModal, setShowDeleteVirtualOrderModal] =
    useState<boolean>(false);
  const [deleteVirtualOrderId, setDeleteVirtualOrderId] = useState<any>();
  const [isDeletingVirtualOrder, setIsDeletingVirtualOrder] =
    useState<boolean>(false);

  const deleteVirtualOrder = useCallback(() => {
    setIsDeletingVirtualOrder(true);
    axios({
      method: "Delete",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/virtual-order",
      data: { virtualOrderId: deleteVirtualOrderId },
      withCredentials: true,
    })
      .then(() => {
        toast.success("Deleted Successfully");
        fetchOperationItems();
      })
      .finally(() => {
        setIsDeletingVirtualOrder(false);
        setShowDeleteVirtualOrderModal(false);
        setDeleteVirtualOrderId(undefined);
      });
  }, [fetchOperationItems, deleteVirtualOrderId]);

  useEffect(() => {
    fetchOperationItems();
  }, [fetchOperationItems]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [referenceId, setReferenceId] = useState<number>();
  const [referenceType, setReferenceType] = useState<string>();
  const [showOperationDetailModal, setShowOperationDetailModal] =
    useState<boolean>(false);

  const [clientOptions, setClientOptions] = useState<any[]>();

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      params: {
        withOperationOpen: JSON.stringify(true),
      },
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);
  useEffect(() => {
    getClientOptions();
  }, [getClientOptions]);

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const movingItemDetail = operationItemDetails[dragIndex];

    // Find all items with the same operationItemId and sort them
    const sameItemDetails = operationItemDetails
      .filter((row) => row.operationItemId === movingItemDetail.operationItemId)
      .sort((a, b) => a.operationItemSubtypeId - b.operationItemSubtypeId);

    // Remove these items from the original array
    let updatedDetails = operationItemDetails.filter(
      (row) => row.operationItemId !== movingItemDetail.operationItemId,
    );

    // Calculate the insertion index
    let insertIndex = hoverIndex;
    if (hoverIndex > dragIndex) {
      insertIndex -= sameItemDetails.length - 1;
    }
    if (insertIndex < 0) insertIndex = 0;
    // Insert the items at the new index
    updatedDetails.splice(insertIndex, 0, ...sameItemDetails);

    // Update the state with the new order of items
    setOperationItemDetails(updatedDetails);
  };

  return (
    <Content id="view-operation-details">
      <Row justify={"start"}>
        <Button
          onClick={() => {
            if (location.state) {
              navigate(location.state.from, { state: location.state });
            } else {
              navigate("/bo/operation");
            }
          }}
        >
          <FontAwesomeIcon
            icon={"fa-solid fa-circle-chevron-left" as IconProp}
          />
        </Button>
      </Row>
      <Row justify={"center"}>
        <Divider>
          <Row style={{ position: "relative" }}>
            <h2>{operationLabel}</h2>
            {operationStatus && operationStatus !== "Open" ? (
              <b className="terminated-label">TERMINATED</b>
            ) : (
              ""
            )}
          </Row>
        </Divider>
      </Row>
      <Row justify={"space-between"} align={"top"} style={{ margin: "20px" }}>
        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
          <ConfigProvider
            theme={{
              components: {
                Form: {
                  itemMarginBottom: 5,
                  /* here is your component tokens */
                },
              },
            }}
          >
            <Form
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
              labelAlign="left"
            >
              <Form.Item label={<b>Type</b>} required>
                <Select
                  options={operationTypes}
                  value={selectedOperationType}
                  onChange={(e) => {
                    setSelectedOperationType(e);
                  }}
                  showSearch
                  filterOption={(input, option: any) =>
                    option.label
                      ?.toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  style={{ width: "300px" }}
                />
              </Form.Item>
              <Form.Item label={<b>Client</b>}>
                <Link
                  to={"/bo/client/info/" + encodeURIComponent(clientId)}
                  state={{ from: window.location.pathname }}
                >
                  {clientName}
                </Link>
              </Form.Item>
              <Form.Item label={<b>Operation Label</b>}>
                <Input
                  disabled={!canEditTerminated && operationStatus !== "Open"}
                  value={operationLabel}
                  onChange={(e) => {
                    setOperationLabel(e.target.value);
                  }}
                  style={{ width: "300px" }}
                />
              </Form.Item>
              {onFinshiLoad ? (
                <Form.Item
                  label={<b>Operation Way</b>}
                  name="transactionWayId"
                  initialValue={transactionWayId}
                >
                  <Radio.Group
                    disabled={!canEditTerminated && operationStatus !== "Open"}
                    onChange={(e) => {
                      setTrasactionWayId(e.target.value);
                    }}
                    value={transactionWayId}
                    buttonStyle="solid"
                  >
                    {transactionWayList?.map((transanctionWay: any) => (
                      <Radio.Button
                        checked={transanctionWay.value === transactionWayId}
                        key={transanctionWay.value}
                        value={transanctionWay.value}
                        className={`dcl-${transanctionWay.label?.toLowerCase()}`}
                      >
                        {transanctionWay.label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              ) : undefined}
              {onFinshiLoad ? (
                <Form.Item label={<b>Operation Date</b>} name="opDate">
                  <DatePicker
                    style={{ width: "300px" }}
                    placeholder="Operation Date"
                    value={opDate}
                    disabledDate={(current) => current > dayjs().endOf("d")}
                    onChange={(e) => {
                      console.log(e);
                      setOpDate(e);
                    }}
                  />
                </Form.Item>
              ) : undefined}
              <Form.Item label={<b>Description</b>}>
                {onFinshiLoad ? (
                  <TextArea
                    disabled={!canEditTerminated && operationStatus !== "Open"}
                    value={operationDescription}
                    onChange={(e) => {
                      setOperationDescription(e.target.value);
                    }}
                    rows={2}
                    style={{ width: "300px" }}
                  />
                ) : (
                  <Spin />
                )}
              </Form.Item>
              <Form.Item label={<b>Dates</b>}>
                <span>
                  {operationItemDetails?.length
                    ? `${new Date(
                        Math.min.apply(
                          null,
                          operationItemDetails.map((row) =>
                            new Date(row.referenceTimestamp).valueOf(),
                          ),
                        ),
                      )?.toLocaleString("en-US", {
                        timeZone: displayTimezone,
                        timeZoneName: "short",
                      })} - ${new Date(
                        Math.max.apply(
                          null,
                          operationItemDetails.map((row) =>
                            new Date(row.referenceTimestamp).valueOf(),
                          ),
                        ),
                      )?.toLocaleString("en-US", {
                        timeZone: displayTimezone,
                        timeZoneName: "short",
                      })}`
                    : "N/A"}
                </span>
              </Form.Item>
              <Form.Item label={<b>Way</b>}>{operationWay}</Form.Item>
              <Form.Item label={<b>Is it a X-trade?</b>} colon={false}>
                {onFinshiLoad ? (
                  <Switch
                    disabled={!canEditTerminated && operationStatus !== "Open"}
                    checked={isXTrade}
                    onChange={(e) => {
                      setIsXTrade(e);
                      if (!e) {
                        setXTradesSelected([]);
                      }
                    }}
                  />
                ) : (
                  <Spin />
                )}
              </Form.Item>

              {onFinshiLoad ? (
                isXTrade ? (
                  <React.Fragment>
                    <Form.Item label={<b>X-trades</b>}>
                      <Row align={"middle"} key={"x-trade-select"}>
                        <Col span={20}>
                          <Select
                            mode="multiple"
                            options={xTradeOptions}
                            value={xTradesSelected}
                            onChange={(e) => {
                              setXTradesSelected(e);
                              // setXTradesSelectedDetails(options);
                            }}
                            disabled={
                              !canEditTerminated && operationStatus !== "Open"
                            }
                            allowClear
                            showSearch
                            filterOption={(input, option: any) =>
                              option.label
                                ?.toLocaleLowerCase()
                                .includes(input.toLocaleLowerCase())
                            }
                          />
                        </Col>
                        <Col span={3} offset={1}>
                          <Tooltip title="Create New Cross Trade">
                            <Button
                              style={{
                                border: "none",
                                background: "none",
                                boxShadow: "none",
                              }}
                              onClick={() => {
                                setShowCreateXTradeModal(true);
                              }}
                            >
                              <FontAwesomeIcon
                                icon={"fa-regular fa-square-plus" as IconProp}
                                style={{ fontSize: "1.2em" }}
                              />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Form.Item>
                  </React.Fragment>
                ) : undefined
              ) : undefined}
              <Form.Item label={<b>Is it a Prop Trade?</b>} colon={false}>
                {onFinshiLoad ? (
                  <Switch
                    disabled={!canEditTerminated && operationStatus !== "Open"}
                    checked={isPropTrade}
                    onChange={(e) => {
                      setIsPropTrade(e);
                    }}
                  />
                ) : (
                  <Spin />
                )}
              </Form.Item>
            </Form>
          </ConfigProvider>
          <Divider />
          <Card
            title={<h3 style={{ color: "black" }}>Operation Summary</h3>}
            style={{ marginBottom: "1em" }}
          >
            <Row align={"stretch"}>
              {operationCategoryDetail.map((row) => (
                <Col
                  key={row.name}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{ marginBottom: "10px" }}
                >
                  <Form.Item
                    label={<b>{row.name}</b>}
                    style={{ marginBottom: "0px" }}
                  >
                    {row.name === "Transaction Fees" ? (
                      <React.Fragment>
                        <b style={{ marginLeft: "1em" }}>
                          Borne by the client:
                        </b>
                        {Object.entries(row.values).length ? (
                          Object.entries(row.values).map(
                            ([ticker, amount], index) => (
                              <Row
                                key={`${ticker}_${index}`}
                                style={{ marginLeft: "2em" }}
                              >
                                <b>
                                  {Number(amount)?.toLocaleString("en-us", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 8,
                                  })}
                                  &nbsp; {ticker}
                                </b>
                              </Row>
                            ),
                          )
                        ) : (
                          <Row style={{ marginLeft: "2em" }}>
                            <b>N/A</b>
                          </Row>
                        )}
                        <b style={{ marginLeft: "1em" }}>
                          Borne by the Delchain:
                        </b>
                        {Object.entries(row.delchainValues).length ? (
                          Object.entries(row.delchainValues).map(
                            ([ticker, amount], index) => (
                              <Row key={index} style={{ marginLeft: "2em" }}>
                                <b>
                                  {Number(amount)?.toLocaleString("en-us", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 8,
                                  })}
                                  &nbsp; {ticker}
                                </b>
                              </Row>
                            ),
                          )
                        ) : (
                          <Row>
                            <b style={{ marginLeft: "2em" }}>N/A</b>
                          </Row>
                        )}
                      </React.Fragment>
                    ) : Object.entries(row.values).length ? (
                      Object.entries(row.values).map(
                        ([ticker, amount]: any, index) => (
                          <Row key={index}>
                            <b>
                              {Number(
                                row.name === "Client Proceeds"
                                  ? Math.abs(amount)
                                  : amount,
                              )?.toLocaleString("en-us", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 8,
                              })}
                              &nbsp; {ticker}
                            </b>
                            {row.name === "Delchain Fees" ? (
                              <Tooltip
                                title={calculatedDclFee?.toLocaleString(
                                  "en-us",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  },
                                )}
                              >
                                <i
                                  style={{ marginLeft: "0.5em" }}
                                  className={`dcl_state ${
                                    dclFeeEqual ? "state_green" : "state_red"
                                  }`}
                                />
                              </Tooltip>
                            ) : undefined}
                          </Row>
                        ),
                      )
                    ) : (
                      <Row>
                        <b>N/A</b>
                        {row.name === "Delchain Fees" ? (
                          <Tooltip
                            title={calculatedDclFee?.toLocaleString("en-us", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          >
                            <i
                              style={{ marginLeft: "0.5em" }}
                              className={`dcl_state ${
                                dclFeeEqual ? "state_green" : "state_red"
                              }`}
                            />
                          </Tooltip>
                        ) : undefined}
                      </Row>
                    )}
                    {row.name === "Order Quote" ? (
                      <React.Fragment>
                        {orderSymbolMap?.map(
                          ({ txnWay, base, quote }: any, idx: number) => {
                            if (txnWay === "Buy") {
                              return (
                                <Row key={`${txnWay}_${idx}`}>
                                  <b>
                                    {`${quote.value?.toLocaleString("en-us", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 8,
                                    })} ${
                                      quote.ticker
                                    } sold to buy ${base.value?.toLocaleString(
                                      "en-us",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 8,
                                      },
                                    )} ${base.ticker}`}
                                  </b>
                                </Row>
                              );
                            } else {
                              return (
                                <Row key={`${txnWay}_idx`}>
                                  <b>
                                    {`${base.value?.toLocaleString("en-us", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 8,
                                    })} ${
                                      base.ticker
                                    } sold to buy ${quote.value?.toLocaleString(
                                      "en-us",
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 8,
                                      },
                                    )} ${quote.ticker}`}
                                  </b>
                                </Row>
                              );
                            }
                          },
                        )}
                      </React.Fragment>
                    ) : undefined}
                  </Form.Item>
                  {row.name === "Delchain Fees" ? (
                    <Form.Item
                      label={
                        <b style={{ color: "#19bdc2" }}>
                          Calculated Delchain Fee:
                        </b>
                      }
                      colon={false}
                    >
                      <b style={{ color: "#19bdc2" }}>
                        {calculatedDclFee?.toLocaleString("en-us", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        USD
                      </b>
                    </Form.Item>
                  ) : undefined}
                </Col>
              ))}
            </Row>
          </Card>
        </Col>
        <Col xxl={11} xl={11} lg={11} md={24} sm={24} xs={24}>
          <Row>
            {xTradesSelectedDetails.map((row) => {
              return (
                <Col span={24} key={row.xTradeId}>
                  <Card
                    title={`${row.xTradeLabel} - Counterparties`}
                    style={{ marginBottom: "1em", width: "100%" }}
                    key={row.xTradeId}
                  >
                    {row.counterOperationItemDetails?.map(
                      (operation: any, opIndex: number) => {
                        return (
                          <React.Fragment key={opIndex}>
                            <Row>
                              <b style={{ fontSize: "1.1em" }}>
                                {operation?.operationLabel}
                              </b>
                              &nbsp;
                              {operation?.operationDescription
                                ? `(${operation.operationDescription})`
                                : ""}
                            </Row>
                            <Row style={{ marginTop: "0.5em" }}>
                              {operation.details?.map(
                                (opItem: any, opItemIndex: number) => (
                                  <Col span={18} key={opItemIndex}>
                                    {opItem.opItemSubtypeFullName}:{" "}
                                    {opItem.amount} {opItem.assetTicker}
                                  </Col>
                                ),
                              )}
                            </Row>
                          </React.Fragment>
                        );
                      },
                    )}
                  </Card>
                </Col>
              );
            })}
          </Row>
          {dclQuotes?.length ? (
            <Row>
              <ConfigProvider
                theme={{
                  components: {
                    Collapse: {
                      headerBg: "white",
                    },
                  },
                }}
              >
                <Collapse
                  style={{ marginBottom: "1em", width: "100%" }}
                  items={dclQuotes?.map((dclQuote: any, index: number) => ({
                    key: index,
                    label: <b>Quote {index + 1}</b>,
                    children: (
                      <DclQuoteConfirmationModal
                        isBase={dclQuote.isBase}
                        date={dayjs(dclQuote.timestamp)
                          .tz("America/New_York", true)
                          .startOf("day")
                          .format()}
                        orderType={dclQuote.dlcOrderType?.name}
                        venue={dclQuote.venue?.name}
                        symbol={`${dclQuote.firstAsset?.ticker}/${dclQuote.secondAsset?.ticker}`}
                        transactionWay={dclQuote.transactionWay?.name}
                        qty={dclQuote.qty}
                        price={dclQuote.price}
                        venueFee={dclQuote.fee}
                        dclFee={dclQuote.dclFee}
                        note={dclQuote.note}
                        msg={dclQuote.propositionMessage}
                        summary={dclQuote.tradeSummary}
                        inCard={true}
                        status={
                          dclQuote.isExecuted ? (
                            <b>Executed</b>
                          ) : dclQuote.isCanceled ? (
                            "Canceled"
                          ) : (
                            <b className="dcl-order-draft">Draft</b>
                          )
                        }
                      />
                    ),
                  }))}
                />
              </ConfigProvider>
            </Row>
          ) : undefined}
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Button
          type="primary"
          className="dcl-btn-toggle"
          onClick={() => {
            setShowCreateXTradeOrderModal(true);
          }}
        >
          <FontAwesomeIcon
            icon={"fa-solid fa-plus" as IconProp}
            className="white-plus"
          />
          Virtual Order
        </Button>
      </Row>

      {onFinshiLoad ? (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={(event) => {
            const { active, over } = event;
            if (active.id !== over?.id) {
              const oldIndex = operationItemDetails.findIndex(
                (item) => item.id.toString() === active.id,
              );
              const newIndex = operationItemDetails.findIndex(
                (item) => item.id.toString() === over?.id,
              );
              moveRow(oldIndex, newIndex);
            }
          }}
        >
          <SortableContext
            items={operationItemDetails.map((item) => item.id.toString())}
            strategy={verticalListSortingStrategy}
          >
            <Table
              loading={isUpdating}
              columns={[
                ...columns,
                isXTrade
                  ? {
                      title: "X Trade",
                      dataIndex: "operationXTradeId",
                      key: "operationXTradeId",
                      width: "150px",
                      align: "center",
                      render: (_: any, operationItemDetail, index: number) => (
                        <Select
                          style={{ width: "100%", textAlign: "left" }}
                          options={xTradeSelectedOptions}
                          value={operationItemDetail.operationXTradeId}
                          onChange={(e) => {
                            operationItemDetail.operationXTradeId = e;
                            operationItemDetails[index] = operationItemDetail;
                            setOperationItemDetails([...operationItemDetails]);
                          }}
                          allowClear
                          showSearch
                          filterOption={(input, option: any) =>
                            option.label
                              ?.toLocaleLowerCase()
                              .includes(input.toLocaleLowerCase())
                          }
                        />
                      ),
                    }
                  : {
                      width: "150px",
                    },
                {
                  title: "Actions",
                  dataIndex: "operationItem",
                  key: "operationItem",
                  width: "150px",
                  align: "center",
                  render: (_: any, { operationItem, isVirtual }) => (
                    <Row>
                      <Col span={8}>
                        <Button
                          onClick={() => {
                            setReferenceId(operationItem?.referenceId);
                            setReferenceType(operationItem?.referenceTableName);
                            setShowOperationDetailModal(true);
                          }}
                          style={{
                            border: "none",
                            background: "none",
                            boxShadow: "none",
                            padding: "1px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={"fa-regular fa-eye" as IconProp}
                          />
                        </Button>
                      </Col>
                      <Col span={8}>
                        {isVirtual ? (
                          <Tooltip title="Delete this virtual order">
                            <Button
                              style={{
                                border: "none",
                                background: "none",
                                boxShadow: "none",
                              }}
                              disabled={
                                !canEditTerminated && operationStatus !== "Open"
                              }
                              onClick={() => {
                                setShowDeleteVirtualOrderModal(true);
                                setDeleteVirtualOrderId(
                                  operationItem.referenceId,
                                );
                              }}
                            >
                              <FontAwesomeIcon
                                className="delete-icon"
                                icon={"fa-regular fa-trash-can" as IconProp}
                              />
                            </Button>
                          </Tooltip>
                        ) : undefined}
                      </Col>
                      <Col span={8}>
                        <Tooltip title="Remove from this operation">
                          <Button
                            style={{
                              border: "none",
                              background: "none",
                              boxShadow: "none",
                            }}
                            disabled={
                              !canEditTerminated && operationStatus !== "Open"
                            }
                            onClick={() => {
                              setDeleteReference({
                                referenceId: operationItem?.referenceId,
                                reference: operationItem?.referenceTableName,
                              });
                              setShowDeleteConfirmModal(true);
                            }}
                          >
                            <FontAwesomeIcon
                              className="delete-icon"
                              icon={"fa-regular fa-square-minus" as IconProp}
                            />
                          </Button>
                        </Tooltip>
                      </Col>
                    </Row>
                  ),
                },
              ]}
              sticky
              scroll={{ x: 1000 }}
              dataSource={operationItemDetails}
              onRow={(record, index) => ({
                id: record.id,
                index,
                moveRow,
              })}
              rowClassName={(record) => {
                const className = getRowClassName(record.operationItemId);
                return className;
              }}
              components={{
                body: {
                  row: DraggableRow,
                },
              }}
              pagination={{
                hideOnSinglePage: true,
                pageSize: 100,
              }}
              rowKey="id"
            />
          </SortableContext>
        </DndContext>
      ) : (
        <Spin size="large" />
      )}
      <Row style={{ marginTop: "20px" }} justify="center">
        <Button
          type="primary"
          loading={!onFinshiLoad || isUpdating}
          disabled={!canEditTerminated && operationStatus !== "Open"}
          onClick={() => {
            updateOperation();
            updateOperationSteps();
          }}
        >
          Save
        </Button>
        {operationStatus !== "Open" ? (
          <Button
            onClick={() => {
              setShowUnterminateModal(true);
              setSelectedOperationStatusId(1);
            }}
            loading={!onFinshiLoad}
            disabled={!canUnterminate}
          >
            Unterminate
          </Button>
        ) : (
          <Button
            danger
            onClick={() => {
              setShowTerminateModal(true);
            }}
            loading={!onFinshiLoad}
            disabled={operationStatus !== "Open"}
          >
            Terminate
          </Button>
        )}
      </Row>

      <Modal
        open={showTerminateModal}
        footer={null}
        onCancel={() => {
          setShowTerminateModal(false);
          selectedOperationStatusId(undefined);
        }}
      >
        <Alert
          type="warning"
          style={{ marginTop: "30px" }}
          message={"Are you sure you want to terminate this operation"}
        />
        <Select
          style={{ minWidth: "300px", marginTop: "20px" }}
          options={operationStatusList}
          value={selectedOperationStatusId}
          onChange={(e) => {
            setSelectedOperationStatusId(e);
          }}
          placeholder={"Closed/Cancel"}
        />
        <Row justify={"end"}>
          <Button
            onClick={() => {
              setShowTerminateModal(false);
              setSelectedOperationStatusId(undefined);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateOperationSteps(true);
              setShowTerminateModal(false);
            }}
            type={"primary"}
            disabled={!selectedOperationStatusId}
          >
            Confirm
          </Button>
        </Row>
      </Modal>

      <Modal
        open={showUnterminateModal}
        footer={null}
        onCancel={() => {
          setShowUnterminateModal(false);
          setSelectedOperationStatusId(undefined);
        }}
      >
        <Alert
          type="warning"
          style={{ marginTop: "30px" }}
          message={"Are you sure you want to un-terminate this operation"}
        />
        <Row justify={"end"}>
          <Button
            onClick={() => {
              setShowUnterminateModal(false);
              setSelectedOperationStatusId(undefined);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateOperationSteps(true);
              setShowUnterminateModal(false);
            }}
            type={"primary"}
            disabled={!selectedOperationStatusId}
          >
            Confirm
          </Button>
        </Row>
      </Modal>

      <Modal
        open={showCreateXTradeModal}
        onCancel={() => {
          setShowCreateXTradeModal(false);
        }}
        footer={null}
      >
        <CreateXTradeModal
          fetchXTradeList={fetchXTradeList}
          setShowCreateXTradeModal={setShowCreateXTradeModal}
        />
      </Modal>

      <Modal
        open={showDeleteConfirmModal}
        onCancel={() => {
          setShowDeleteConfirmModal(false);
          setDeleteReference(undefined);
        }}
        onOk={() => {
          setShowDeleteConfirmModal(false);
          deleteReferenceFromDB();
        }}
      >
        <Alert
          type="warning"
          style={{
            marginTop: "30px",
            paddingTop: "0px",
          }}
          showIcon
          message={
            <React.Fragment>
              <p style={{ color: "black" }}>
                Deleting selected record would affect all the record with same
                reference.
              </p>
              <p style={{ color: "black" }}>
                The following operations would be affected.
              </p>
              {operationLabels.map((row, index) => (
                <Row key={index}>
                  <li>
                    {index + 1}. {row}
                  </li>
                </Row>
              ))}
              <p style={{ color: "black" }}>
                <b>Are you sure you want to delete?</b>
              </p>
            </React.Fragment>
          }
        />
      </Modal>

      <Modal
        open={showCreateXTradeOrderModal}
        onCancel={() => {
          setShowCreateXTradeOrderModal(false);
        }}
        footer={[]}
      >
        <CreateXTradeOrder
          operationId={operationId}
          setShowCreateXTradeOrderModal={setShowCreateXTradeOrderModal}
          fetchOperationItems={fetchOperationItems}
        />
      </Modal>
      <Modal
        open={showDeleteVirtualOrderModal}
        onCancel={() => {
          setShowDeleteVirtualOrderModal(false);
          setDeleteVirtualOrderId(undefined);
        }}
        onOk={() => {
          deleteVirtualOrder();
        }}
        cancelButtonProps={{ loading: isDeletingVirtualOrder }}
        okButtonProps={{ loading: isDeletingVirtualOrder }}
      >
        <Alert
          style={{
            marginTop: "30px",
          }}
          message={"Are you sure you want to delete this virtual order?"}
          type="warning"
          showIcon
        />
      </Modal>
      <Modal
        open={showOperationDetailModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setReferenceType(undefined);
          setReferenceId(undefined);
          setShowOperationDetailModal(false);
        }}
      >
        <ViewOperationSummary
          clientOptions={clientOptions}
          referenceId={referenceId}
          reference={referenceType}
          setShowModal={setShowOperationDetailModal}
          fetchData={(form: any) => {
            return;
          }}
          form={undefined}
          editable={false}
        />
      </Modal>
    </Content>
  );
}
