import { MenuOutlined } from '@ant-design/icons';
import React from 'react';

import {Action, ActionProps} from '../Action';

export function Handle(props: ActionProps) {
  return (
    <Action cursor="grab" data-cypress="draggable-handle" {...props}>
      <MenuOutlined />
    </Action>
  );
}
