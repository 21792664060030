import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, Row } from "antd";
import axios from "axios";
import React from "react";

export default function FileUploader(props: any) {
  const downloadTemplate = () => {
    axios({
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/venue/download-csv-template",
      method: "POST",
      withCredentials: true,
      data: {
        fileType: props.fileType,
      },
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${props.fileType}_template.csv`);
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <Card
      title={props.title}
      extra={
        <React.Fragment>
          <Row justify={"end"} style={{ paddingTop: "5px" }} align="middle">
            <Button size="small" onClick={downloadTemplate}>
              <DownloadOutlined
                style={{
                  fontSize: "12px",
                }}
              />
              Template
            </Button>
            <UploadOutlined
              style={{
                fontSize: "20px",
              }}
            />
          </Row>
        </React.Fragment>
      }
      bordered={false}
      style={{
        margin: "10px",
        borderRadius: "10px",
      }}
      id={props.cardId}
    >
      <input
        className="input-file-choose"
        type="file"
        onChange={(e: any) => props.handleFileChange(e, props.fileType)}
        ref={props.inputRef}
      />
      <Row justify="center">
        <Button
          type="primary"
          onClick={() => {
            props.handleUploadClick();
          }}
          disabled={props.disabled}
        >
          Upload
        </Button>
      </Row>
    </Card>
  );
}
