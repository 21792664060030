import {
  Button,
  DatePicker,
  InputNumber,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import axios from "axios";
import { toast } from "react-toastify";

export default function ClientInitialBalanceList({
  canEdit,
  clientId,
  initialBalances,
  fetchCanAssignInitialBalace,
}: any) {
  const [data, setData] = useState<any[]>(initialBalances);
  const [changes, setChanges] = useState<any>({
    inserted: [],
    updated: [],
    deleted: [],
  });
  const [venueList, setVenueList] = useState<any>();
  const [assetList, setAssetList] = useState<any[]>();
  const [canSave, setCanSave] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const saveData = useCallback(() => {
    setIsSaving(true);
    const insertedKeys = changes.inserted.map((row: any) => row.key);
    const deltededIds = changes.deleted.map((row: any) => row.id);
    const updatedKeys = changes.updated.map((row: any) => row.key);
    const insertedData = data.filter((row) => insertedKeys.includes(row.key));
    const updatedData = data.filter((row) => updatedKeys.includes(row.key));
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/initial-balance",
      data: {
        encodedClientId: clientId,
        insertedData,
        deltededIds,
        updatedData,
      },
      withCredentials: true,
    })
      .then(() => {
        toast.success("Initial balance data is saved");
      })
      .catch(() => {
        toast.error("Failed to save initial balance.");
      })
      .finally(() => {
        setIsSaving(false);
        fetchCanAssignInitialBalace(clientId);
        setChanges({
          inserted: [],
          updated: [],
          deleted: [],
        });
      });
  }, [fetchCanAssignInitialBalace, data, clientId, changes]);

  // Util
  const fetchVenueList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue",
      params: {
        includeBank: true,
      },
      withCredentials: true,
    }).then((res) => {
      setVenueList(res.data.list);
    });
  }, []);

  useEffect(() => {
    fetchVenueList();
  }, [fetchVenueList]);

  // Util
  const fetchAssetList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    }).then((res) => {
      const options = [];
      for (const asset of res.data.assetList) {
        options.push({
          label: `${asset.name} (${asset.ticker})`,
          value: asset.id,
        });
      }
      setAssetList(options);
    });
  }, []);

  useEffect(() => {
    fetchAssetList();
  }, [fetchAssetList]);

  const handleAdd = () => {
    const newRow = {
      key: Date.now(),
      timestamp: null,
      amount: null,
      venueId: null,
      assetId: null,
    };
    setData([...data, newRow]);
    setChanges({ ...changes, inserted: [...changes.inserted, newRow] });
  };

  const handleChange = (key: any, field: any, value: any) => {
    const newData = data.map((item) => {
      if (item.key === key) {
        const updatedRow = { ...item, [field]: value };
        if (!changes.inserted.some((row: any) => row.key === key)) {
          setChanges({
            ...changes,
            updated: [
              ...changes.updated.filter((row: any) => row.key !== key),
              updatedRow,
            ],
          });
        }
        return updatedRow;
      }
      return item;
    });
    setData(newData);
  };

  const handleDelete = (key: any) => {
    const newData = data.filter((item: any) => item.key !== key);
    const isInserted = changes.inserted.some((item: any) => item.key === key);
    if (isInserted) {
      const newInserted = changes.inserted.filter(
        (item: any) => item.key !== key,
      );
      setChanges({
        ...changes,
        inserted: newInserted,
      });
    } else {
      const deletedRow = data.find((item) => item.key === key);
      setChanges({
        ...changes,
        deleted: [...changes.deleted, deletedRow],
        updated: changes.updated.filter((item: any) => item.key !== key),
      });
    }
    setData(newData);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "timestamp",
      width: "22%",
      render: (_: any, record: any) => (
        <DatePicker
          disabled={!canEdit}
          value={record.timestamp ? dayjs(record.timestamp) : null}
          onChange={(date) =>
            handleChange(
              record.key,
              "timestamp",
              date ? date.format("YYYY-MM-DD") : null,
            )
          }
        />
      ),
    },
    {
      title: "Venue",
      dataIndex: "venueId",
      width: "22%",
      render: (_: any, record: any) => (
        <Select
          style={{
            width: "100%",
          }}
          showSearch
          filterOption={(input, option: any) => {
            return option.label.toLowerCase().includes(input.toLowerCase());
          }}
          placeholder={"Venue"}
          value={record.venueId}
          options={venueList}
          onChange={(value) => handleChange(record.key, "venueId", value)}
        />
      ),
    },
    {
      title: "Asset",
      dataIndex: "assetId",
      width: "22%",
      render: (_: any, record: any) => (
        <Select
          style={{
            width: "100%",
          }}
          showSearch
          filterOption={(input, option: any) => {
            return option.label.toLowerCase().includes(input.toLowerCase());
          }}
          placeholder={"Asset"}
          value={record.assetId}
          options={assetList}
          onChange={(value) => handleChange(record.key, "assetId", value)}
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      width: "22%",
      render: (_: any, record: any) => (
        <InputNumber
          style={{
            width: "100%",
          }}
          controls={false}
          formatter={(value) => {
            const parts = `${value}`.split(".");
            // Apply the original regex only to the first part (integer part) to add commas.
            const integerPartWithCommas = parts[0].replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ",",
            );
            // Reassemble the parts, including the decimal part if it was present.
            return parts.length > 1
              ? `${integerPartWithCommas}.${parts[1]}`
              : integerPartWithCommas;
          }}
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          value={record.amount}
          disabled={!canEdit}
          onChange={(value) => handleChange(record.key, "amount", value)}
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <Tooltip title="Delete">
          <Button
            style={{
              border: "none",
              background: "none",
              boxShadow: "none",
            }}
            disabled={!canEdit}
            onClick={() => handleDelete(record.key)}
          >
            <FontAwesomeIcon
              className="delete-icon"
              icon={"fa-regular fa-trash-can" as IconProp}
            />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const checkCanSave = useCallback(() => {
    const allFilled = data.every(
      (row) =>
        row.timestamp && row.amount !== null && row.venueId && row.assetId,
    );
    setCanSave(allFilled);
  }, [data]);

  useEffect(() => {
    checkCanSave();
  }, [checkCanSave]);

  return (
    <Content id="client-initial-balance-list">
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowKey="key"
        bordered
        sticky
        loading={isSaving}
        scroll={{ x: "800px" }}
        rowClassName={(record) => (record.addButton ? "add-button-row" : "")}
        footer={() => (
          <Row justify={"center"}>
            {canEdit ? (
              <Button
                type="dashed"
                onClick={handleAdd}
                style={{ width: "100%" }}
                loading={isSaving}
                disabled={!canEdit}
              >
                Add a row
              </Button>
            ) : (
              <></>
            )}
          </Row>
        )}
      />
      <Row justify={"end"}>
        {canEdit ? (
          <Button
            type="primary"
            disabled={!canSave}
            loading={isSaving}
            onClick={() => {
              saveData();
            }}
          >
            Save Initial Balances
          </Button>
        ) : (
          <></>
        )}
      </Row>
    </Content>
  );
}
