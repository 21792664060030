import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  Button,
  Input,
  Row,
  Space,
  Spin,
  Table,
  Form,
  Modal,
  Layout,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const { TextArea } = Input;

function DSPSettings() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<any>();
  const [dspSettings, setDspSettings] = useState<any[]>([]);
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [deletedRows, setDeletedRows] = useState<any[]>([]);

  const openDeleteConfirmModal = (id: any) => {
    setIsModalOpen(true);
    setSelectedRowId(id);
  };

  const deleteRow = (id: any) => {
    setOnFinishLoad(false);
    const newDspSettings = [...dspSettings];
    const newDeltedRows = [...deletedRows];
    const rowToDelete = newDspSettings.find((setting) => setting.id === id);
    if (!rowToDelete.newRow) {
      newDeltedRows.push(rowToDelete);
    }
    setDeletedRows(newDeltedRows);
    newDspSettings.splice(newDspSettings.indexOf(rowToDelete), 1);
    setDspSettings(newDspSettings);
    setOnFinishLoad(true);
  };

  const updateDspSettingFields = (
    id: number,
    fieldName: string,
    fieldValue: string,
  ) => {
    const newDspSettings = [...dspSettings];
    const row = newDspSettings.filter((obj) => obj.id === id)[0];
    row[fieldName] = fieldValue;
    row.edited = true;
    setDspSettings(dspSettings);
  };

  const submit = () => {
    const editedRows = dspSettings.filter(
      (e) => e.edited === true && !e.newRow,
    );
    const newRows = dspSettings.filter((e) => e.newRow);

    setOnFinishLoad(false);
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/dsp-settings",
      withCredentials: true,
      data: {
        editedRows: editedRows,
        newRows: newRows,
        deletedRows: deletedRows,
      },
    })
      .then(() => {
        toast.success("DSP settings updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
        fetchData();
      });
  };

  const insertNewRow = () => {
    setOnFinishLoad(false);
    const newDspSettings = [...dspSettings];
    const newRow = {
      id: `temp_${newDspSettings.length + 1}`,
      key: `New key ${newDspSettings.length + 1}`,
      value: "",
      newRow: true,
      canBeDeleted: true,
    };
    newDspSettings.unshift(newRow);
    setDspSettings(newDspSettings);
    setOnFinishLoad(true);
  };

  const fetchData = () => {
    setOnFinishLoad(false);
    setDeletedRows([]);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/dsp-settings",
      withCredentials: true,
    })
      .then((res) => {
        res.data.forEach((e: any) => {
          e.edited = false;
        });
        setDspSettings(res.data);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };
  const columns = [
    {
      title: "id",
      dataIndex: "id",
      editable: false,
      render: (text: any) => <strong>{text}</strong>,
      responsive: ["lg"] as any,
    },
    {
      title: "Key",
      dataIndex: "key",
      editable: false,
      render: (_: any, { id, key }: any) => (
        <Input
          defaultValue={key}
          onChange={(e: any) => {
            updateDspSettingFields(id, "key", e.target.value);
          }}
        />
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      editable: false,
      render: (_: any, { id, value }: any) => (
        <TextArea
          defaultValue={value}
          rows={4}
          onChange={(e: any) => {
            updateDspSettingFields(id, "value", e.target.value);
          }}
        />
      ),
    },
    {
      title: "Delete",
      dataIndex: "canBeDeleted",
      editable: false,
      render: (_: any, { id, canBeDeleted }: any) => (
        <Button
          disabled={!canBeDeleted}
          onClick={() => {
            openDeleteConfirmModal(id);
          }}
        >
          Delete
        </Button>
      ),
      responsive: ["lg"] as any,
    },
  ];
  useEffect(() => {
    fetchData();
  }, []);

  return onFinshiLoad ? (
    <Content>
      <Row justify={"end"} style={{ marginRight: "10px" }}>
        <Button
          type="primary"
          onClick={() => {
            insertNewRow();
          }}
        >
          <FontAwesomeIcon
            icon={"fa-solid fa-plus" as IconProp}
            className="white-plus"
          />
          &nbsp; New DSP Setting
        </Button>
      </Row>
      <Form component={false}>
        <Table
          columns={columns}
          dataSource={dspSettings}
          sticky
          pagination={{ defaultPageSize: 100 }}
        />
      </Form>
      <Row justify="center">
        <Space>
          <Button
            onClick={() => {
              fetchData();
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              submit();
            }}
          >
            Save
          </Button>
        </Space>
      </Row>
      <Modal
        getContainer={false}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onOk={() => {
          deleteRow(selectedRowId);
          setIsModalOpen(false);
        }}
      >
        <p>
          Are you sure you want to delete the dsp setting's row {selectedRowId}?
        </p>
        <p>
          Click the Ok button and then click the Save button at the end of the
          page to save the updates.
        </p>
      </Modal>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
export default DSPSettings;
