import { Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewKrakenFees from "./ViewVenueMarketTabs/ViewKrakenFees";
import ViewRealTimePrice from "./ViewVenueMarketTabs/ViewRealTimePrice";
import ViewVenueHistoricalMarketBasis from "./ViewVenueMarketTabs/ViewVenueHistoricalMarketBasis";
import ViewVenueMarketBasis from "./ViewVenueMarketTabs/ViewVenueMarketBasis";
import ViewVenueMarketPrices from "./ViewVenueMarketTabs/ViewVenueMarketPrices";
import ViewVenueMarketTrades from "./ViewVenueMarketTabs/ViewVenueMarketTrades";

export default function ViewVenueMarket() {
  const { tabName = "trades" } = useParams();
  const navigate = useNavigate();

  return (
    <Content id="ViewMarketData">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/venues/market/" + key);
        }}
        items={[
          {
            label: "Trades",
            key: "trades",
            children: (
              <React.Fragment>
                <ViewVenueMarketTrades />
              </React.Fragment>
            ),
          },
          {
            label: "Prices",
            key: "prices",
            children: (
              <React.Fragment>
                <ViewVenueMarketPrices />
              </React.Fragment>
            ),
          },
          {
            label: "Market Price Basis",
            key: "basis",
            children: (
              <React.Fragment>
                <ViewVenueMarketBasis />
              </React.Fragment>
            ),
          },
          {
            label: "Historical Market Price Basis",
            key: "historical-market-price-basis",
            children: (
              <React.Fragment>
                <ViewVenueHistoricalMarketBasis />
              </React.Fragment>
            ),
          },
          {
            label: "Kraken Fees",
            key: "kraken-fees",
            children: (
              <React.Fragment>
                <ViewKrakenFees />
              </React.Fragment>
            ),
          },
          {
            label: "Real Time Prices",
            key: "real-time-prices",
            children: (
              <React.Fragment>
                <ViewRealTimePrice />
              </React.Fragment>
            ),
          },
        ]}
      />
    </Content>
  );
}
