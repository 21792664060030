import {
  Alert,
  Button,
  ConfigProvider,
  Divider,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function ViewOperationSummary({
  clientOptions,
  referenceId,
  reference,
  setShowModal,
  fetchData,
  form,
  editable = true,
}: any) {
  const navigate = useNavigate();
  const [operationItemTypeList, setOperationItemTypeList] = useState<any[]>([]);
  const [loadingOpertionItemType, setLoadingOperationItemType] =
    useState<boolean>(true);
  const [selectedOperationItemType, setSelectedOperationItemType] =
    useState<any>();
  const [operationItemDescription, setOperationItemDescription] =
    useState<string>("");
  const [operationItemWalletAddress, setOperationItemWalletAddress] =
    useState<string>("");
  const [operationItemTxHash, setOperationItemTxHash] = useState<string>("");
  const [operationItems, setOperationItems] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] =
    useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [operationLabels, setOperationLabels] = useState<string[]>([]);

  const fetchOperationItemType = useCallback(() => {
    if (!reference) return;
    setLoadingOperationItemType(true);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/item-types",
      params: { reference: reference },
      withCredentials: true,
    })
      .then((res) => {
        setOperationItemTypeList(res.data.operationItemTypes);
      })
      .finally(() => {
        setLoadingOperationItemType(false);
      });
  }, [reference]);
  const deleteOperationItem = useCallback(() => {
    setIsDeleting(true);
    axios({
      method: "Delete",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/item/reference",
      withCredentials: true,
      data: {
        referenceId,
        reference,
      },
    })
      .then(() => {
        toast.success("Deleted Successfully.");
      })
      .catch(() => {
        toast.error("Failed to delete");
      })
      .finally(() => {
        setOperationLabels([]);
        setIsDeleting(false);
        setShowModal(false);
        fetchData(form);
      });
  }, [fetchData, form, reference, referenceId, setShowModal]);
  useEffect(() => {
    fetchOperationItemType();
  }, [fetchOperationItemType]);

  const getOperationSummary = useCallback(() => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/operations/item/reference",
      withCredentials: true,
      params: {
        referenceId,
        reference,
      },
    }).then((res) => {
      if (res.data.operationItems?.length) {
        setSelectedOperationItemType(
          res.data.operationItems[0].operationItemTypeId,
        );
        const operationLabels = res.data.operationItems.map(
          (row: any) => row.operation?.label,
        );
        setOperationLabels(operationLabels);
        setOperationItemDescription(res.data.operationItems[0].description);
        setOperationItemWalletAddress(
          res.data.operationItems[0].jsonData?.walletAddress,
        );
        setOperationItemTxHash(res.data.operationItems[0].jsonData?.txHash);
      } else {
        setSelectedOperationItemType(undefined);
        setOperationItemDescription("");
      }
      setOperationItems(res.data.operationItems);
    });
  }, [reference, referenceId]);
  useEffect(() => {
    getOperationSummary();
  }, [getOperationSummary]);
  const updateStatus = useCallback(() => {
    axios({
      method: "Put",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/item/status",
      data: {
        operationItems: operationItems,
        jsonData: {
          txHash: operationItemTxHash,
          walletAddress: operationItemWalletAddress,
        },
      },
      withCredentials: true,
    })
      .then(() => {
        toast.success("Updated Successfully");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOperationLabels([]);
        setIsDeleting(false);
        setShowModal(false);
        fetchData(form);
      });
  }, [
    fetchData,
    form,
    setShowModal,
    navigate,
    operationItems,
    operationItemTxHash,
    operationItemWalletAddress,
  ]);
  return (
    <Content id={"opeartion-summary"}>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "white",
            },
            InputNumber: {
              /* here is your component tokens */
              addonBg: "lightgrey",
            },
          },
          token: {
            colorBgContainerDisabled: "white",
            colorSplit: "white",
            colorTextDisabled: "#19bdc2",
          },
        }}
      >
        <Row
          justify={"center"}
          style={{
            marginTop: "20px",
          }}
        >
          <h2 style={{ color: "white" }}>Operation</h2>
        </Row>
        <Form labelCol={{ span: 7 }} wrapperCol={{ span: 17 }} labelWrap>
          <Form.Item label={<b>Type</b>} required>
            {loadingOpertionItemType ? (
              <Spin />
            ) : (
              <Select
                disabled
                options={operationItemTypeList}
                value={selectedOperationItemType}
              />
            )}
          </Form.Item>
          <Form.Item label={<b>Description</b>}>
            <TextArea rows={3} disabled value={operationItemDescription} />
          </Form.Item>
          <Form.Item label={<b>Address</b>}>
            <TextArea
              rows={3}
              value={operationItemWalletAddress}
              onChange={(e) => {
                setOperationItemWalletAddress(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Txid</b>}>
            <TextArea
              rows={3}
              value={operationItemTxHash}
              onChange={(e) => {
                setOperationItemTxHash(e.target.value);
              }}
            />
          </Form.Item>
          <Divider />
          <React.Fragment>
            {operationItems?.map((opGroup: any, index: number) => (
              <React.Fragment key={index}>
                <Form.Item label={<b>Client</b>}>
                  <Select
                    options={clientOptions}
                    value={opGroup.operation?.clientId}
                    disabled
                  />
                </Form.Item>
                <Form.Item label={<b>Operation</b>}>
                  <b style={{ color: "#19bdc2" }}>{opGroup.operation?.label}</b>
                </Form.Item>
                {opGroup.operationItemDetails?.map(
                  (itemDetail: any, detailIndex: number) => (
                    <React.Fragment key={detailIndex}>
                      <Form.Item
                        label={
                          <b>
                            {itemDetail.operationItemSubtype?.addOnLabel
                              ? itemDetail.operationItemSubtype?.addOnLabel
                              : "Amount"}
                          </b>
                        }
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          value={itemDetail.amount}
                          disabled
                          controls={false}
                          formatter={(value) => {
                            const parts = `${value}`.split(".");
                            // Apply the original regex only to the first part (integer part) to add commas.
                            const integerPartWithCommas = parts[0].replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ",",
                            );
                            // Reassemble the parts, including the decimal part if it was present.
                            return parts.length > 1
                              ? `${integerPartWithCommas}.${parts[1]}`
                              : integerPartWithCommas;
                          }}
                          addonAfter={itemDetail.asset?.ticker}
                        />
                      </Form.Item>
                    </React.Fragment>
                  ),
                )}
                <Form.Item label={<b>Fees borne by Delchain</b>}>
                  <Switch
                    checked={opGroup.isFeeAssignedToDelchain}
                    onChange={(e) => {
                      operationItems[index].isFeeAssignedToDelchain = e;
                      setOperationItems([...operationItems]);
                    }}
                    disabled={!editable}
                  />
                </Form.Item>
                <Form.Item label={<b>Is Client Transfer?</b>}>
                  <Switch
                    checked={opGroup.isClientTransfer}
                    onChange={(e) => {
                      operationItems[index].isClientTransfer = e;
                      setOperationItems([...operationItems]);
                    }}
                    disabled={!editable}
                  />
                </Form.Item>
                {operationItems[index].isClientTransfer ? (
                  <Form.Item
                    label={
                      <b>
                        {operationItemTypeList
                          .find(
                            (row) =>
                              row.value ===
                              operationItems[index].operationItemTypeId,
                          )
                          ?.label?.toLocaleLowerCase()
                          ?.includes("withdrawal")
                          ? "To"
                          : "From"}{" "}
                        Venue Wallet?
                      </b>
                    }
                  >
                    <Switch
                      checked={opGroup.isVenueWalletRelated}
                      onChange={(e) => {
                        operationItems[index].isVenueWalletRelated = e;
                        setOperationItems([...operationItems]);
                      }}
                      disabled={!editable}
                    />
                  </Form.Item>
                ) : (
                  <></>
                )}
                <Divider />
              </React.Fragment>
            ))}
          </React.Fragment>
        </Form>
        {editable ? (
          <Row justify={"center"}>
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainerDisabled: "white",
                  colorSplit: "white",
                  colorTextDisabled: "gray",
                },
              }}
            >
              <Button
                loading={isDeleting}
                onClick={() => {
                  setShowDeleteConfirmModal(true);
                }}
                danger
              >
                Remove from operations
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  updateStatus();
                }}
                disabled={!editable}
              >
                Update
              </Button>
            </ConfigProvider>
          </Row>
        ) : (
          <></>
        )}

        <Modal
          open={showDeleteConfirmModal}
          onCancel={() => setShowDeleteConfirmModal(false)}
          onOk={() => {
            setShowDeleteConfirmModal(false);
            deleteOperationItem();
          }}
        >
          <Alert
            type="warning"
            message={
              <React.Fragment>
                <p style={{ color: "black" }}>
                  Deleting selected record would affect all the record with same
                  reference.
                </p>
                <p style={{ color: "black" }}>
                  The following operations would be affected.
                </p>
                {operationLabels.map((row, index) => (
                  <Row key={index}>
                    <li>
                      {index + 1}. {row}
                    </li>
                  </Row>
                ))}
                <p style={{ color: "black" }}>
                  <b>Are you sure you want to delete?</b>
                </p>
              </React.Fragment>
            }
            style={{
              marginTop: "30px",
            }}
            showIcon
          />
        </Modal>
      </ConfigProvider>
    </Content>
  );
}
