import { Button, Col, Form, Pagination, Row, Select, Space, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "../../Context/displayContext";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

export default function ViewEbankingBalanceDetails() {
  const navigate = useNavigate();
  // Display Context
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  // Pagination Related
  const [recordCount, setRecordCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [currPageSize, setCurrPageSize] = useState(50);

  // Filter Form
  const [filterForm] = Form.useForm();
  const [showFilter, setShowFilter] = useState<boolean>(true);
  const [delchainPortfolioOptions, setDelchainPortfolioOptions] =
    useState<any[]>();
  const fetchDelchainPortfolios = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/delchain-portfolio",
      params: {
        key: 'filter_balance',
      },
      withCredentials: true,
    }).then((res) => {
      setDelchainPortfolioOptions(res.data);
    });
  }, []);
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const fetchAssetOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      params: {
        onlyFiat: "true",
      },
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  useEffect(() => {
    fetchDelchainPortfolios();
    fetchAssetOptions();
  }, [fetchDelchainPortfolios, fetchAssetOptions]);

  const [tableData, setTableData] = useState<any>();
  const initialColumns = [
    {
      title: "Delchain Portfolio",
      dataIndex: "delchainPortfolio",
      render: (_: any, { delchainPortfolio }: any) => <>{delchainPortfolio}</>,
    },
    {
      title: "Asset",
      dataIndex: "asset",
      render: (_: any, { asset }: any) => <>{asset}</>,
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      render: (_: any, { timestamp }: any) => (
        <>
          {new Date(timestamp)
            .toLocaleString("en-US", {
              timeZone: displayTimezone,
              timeZoneName: "short",
            })
            .split(", ")
            .join(" ")}
        </>
      ),
    },
    {
      title: "Calculated Balance",
      dataIndex: "calculatedValue",
      render: (_: any, { calculatedValue }: any) => <>{calculatedValue}</>,
    },
    {
      title: "Fetched Balance",
      dataIndex: "fetchedValue",
      render: (_: any, { fetchedValue }: any) => <>{fetchedValue}</>,
    },
    {
      title: "Is Accurate",
      dataIndex: "isAccurate",
      render: (_: any, { isAccurate, timestamp, delchainPortfolioId }: any) =>
        isAccurate ? (
          <Space className="dcl-N">True</Space>
        ) : (
          <Space
            className="dcl-Y"
            onClick={() => {
              navigate("/bo/ebanking/ledger", {
                state: {
                  from: window.location.pathname,
                  timestamp: timestamp,
                  delchainPortfolioId: delchainPortfolioId,
                },
              });
            }}
          >
            False
          </Space>
        ),
    },
  ];

  const onPaginationChange = useCallback((current: any, pageSize: any) => {
    setCurrPageSize(pageSize);
    setCurrentPage(current);
  }, []);
  const fetchData = useCallback(
    (isReset = false, isFilter = false) => {
      if (isReset) {
        setCurrentPage(0);
        axios({
          method: "Get",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking/daily-balances",
          withCredentials: true,
          params: {
            pageSize: currPageSize,
            currentPage: 0,
          },
        }).then((res) => {
          setRecordCount(res.data.totalCount);
          setTableData(res.data.result);
        });
      } else {
        const form = filterForm.getFieldsValue();
        axios({
          method: "Get",
          url:
            process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking/daily-balances",
          withCredentials: true,
          params: {
            delchainPortfolios:
              form.delchainPortfolios && form.delchainPortfolios.length
                ? JSON.stringify(form.delchainPortfolios)
                : undefined,
            assetTickers:
              form.assetTickers && form.assetTickers.length
                ? JSON.stringify(form.assetTickers)
                : undefined,
            isAccurate:
              form.isAccurate !== undefined
                ? JSON.stringify(form.isAccurate)
                : undefined,
            pageSize: currPageSize,
            currentPage: isFilter ? 0 : currentPage,
          },
        }).then((res) => {
          if (isFilter) {
            setCurrentPage(0);
            setRecordCount(res.data.totalCount);
          }
          if (currentPage === 0) {
            setRecordCount(res.data.totalCount);
          }
          setTableData(res.data.result);
        });
      }
    },
    [filterForm, currPageSize, currentPage],
  );
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const onReset = () => {
    fetchData(true);
    filterForm.resetFields();
  };
  return (
    <Content id="view-ebanking-balance-details">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Space>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
              Filters
              {showFilter ? (
                <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
              ) : (
                <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
              )}
            </Button>
          </Space>
          <Form
            title="Filter"
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
            form={filterForm}
            onFinish={() => fetchData(false, true)}
            labelWrap
          >
            <Row justify={"start"}>
              <Form.Item name="delchainPortfolios" className="dcl-filter-item">
                <Select
                  allowClear
                  
                  placeholder="Delchain Portfolios"
                  options={delchainPortfolioOptions}
                  className="dcl-portfolio-select"
                  mode="multiple"
                  popupMatchSelectWidth={false}
                />
              </Form.Item>
              <Form.Item name="assetTickers" className="dcl-filter-item">
                <Select
                  allowClear
                  mode="multiple"
                  placeholder="Assets"
                  options={assetOptions}
                  className="dcl-asset-select"
                  popupMatchSelectWidth={false}
                />
              </Form.Item>
              <Form.Item name="isAccurate" className="dcl-filter-item">
                <Select
                  allowClear
                  
                  placeholder="Is Accurate"
                  options={[
                    { label: "All", value: "all" },
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  className="dcl-status-select"
                  popupMatchSelectWidth={false}
                />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                {recordCount}
                <b>Results</b>
              </Space>
              <Space>
                <Button htmlType="submit" className="ant-btn-primary">
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
      </Row>
      <Table
        dataSource={tableData}
        columns={initialColumns}
        sticky
        scroll={{x: 1000}}
        pagination={false}
      />

      <Row justify="end" className="pagination-row">
        <Pagination
          showSizeChanger
          showQuickJumper
          onChange={onPaginationChange}
          pageSizeOptions={[50, 100, 200]}
          current={currentPage}
          pageSize={currPageSize}
          total={recordCount}
        />
      </Row>
    </Content>
  );
}
