import { Button, ConfigProvider, Form, Input, InputNumber, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";

export default function EditLoan({
  loanDetail,
  editLoan,
  setOpenEditModal,
}: any) {
  const [displayLoan, setDisplayLoan] = useState<any>({
    ...loanDetail,
    marginRatioThreshold: loanDetail?.marginRatioThreshold
      ? (loanDetail.marginRatioThreshold * 100).toFixed(2)
      : 0,
    initialMarginRatio: loanDetail?.initialMarginRatio
      ? (loanDetail.initialMarginRatio * 100).toFixed(2)
      : 0,
  });
  useEffect(() => {
    setDisplayLoan({
      ...loanDetail,
      marginRatioThreshold: loanDetail?.marginRatioThreshold
        ? (loanDetail.marginRatioThreshold * 100).toFixed(2)
        : 0,
      initialMarginRatio: loanDetail?.initialMarginRatio
        ? (loanDetail.initialMarginRatio * 100).toFixed(2)
        : 0,
    });
  }, [loanDetail]);
  return (
    <Content id={"edit-loan"}>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "white",
            },
          },
        }}
      >
        <Row
          justify={"center"}
          style={{
            marginTop: "20px",
          }}
        >
          <h2 style={{ color: "white" }}>Loan</h2>
        </Row>
        <Form
          labelCol={{ span: 6 }}
          labelWrap
          wrapperCol={{ span: 18 }}
          style={{
            marginTop: "40px",
          }}
        >
          <Form.Item label={<b>Telegram Group</b>}>
            <Input
              placeholder={"Telegram Group"}
              onChange={(e) => {
                displayLoan.telegramGroup = e.target.value;
                setDisplayLoan({ ...displayLoan });
              }}
              value={displayLoan?.telegramGroup}
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Initial Margion Ratio</b>} required>
            <InputNumber
              value={displayLoan.initialMarginRatio}
              placeholder={"Initial Margion Ratio"}
              style={{
                width: "50%",
                maxWidth: "100%",
              }}
              addonAfter={<b style={{ color: "white" }}>%</b>}
              onChange={(e) => {
                displayLoan.initialMarginRatio = e;
                setDisplayLoan({ ...displayLoan });
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Margin Ratio Threshold</b>} required>
            <InputNumber
              value={displayLoan.marginRatioThreshold}
              placeholder={"Margin Ratio Threshold"}
              style={{
                width: "50%",
                maxWidth: "100%",
              }}
              addonAfter={<b style={{ color: "white" }}>%</b>}
              onChange={(e) => {
                displayLoan.marginRatioThreshold = e;
                setDisplayLoan({ ...displayLoan });
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Email to</b>} required>
            <TextArea
              placeholder={"Email to"}
              defaultValue={JSON.stringify(displayLoan.emailTo)}
              onChange={(e) => {
                displayLoan.emailTo = e.target.value;
                setDisplayLoan({ ...displayLoan });
              }}
              style={{
                width: "100%",
                maxWidth: "100%",
              }}
            />
          </Form.Item>
          <Form.Item label={<b>Display Asset Price Url</b>} required>
            <Input
              placeholder={"Asset Price Url"}
              style={{
                width: "80%",
                maxWidth: "100%",
              }}
              defaultValue={displayLoan.assetPriceUrl}
              onChange={(e) => {
                displayLoan.assetPriceUrl = e.target.value;
                setDisplayLoan({ ...displayLoan });
              }}
            />
          </Form.Item>
        </Form>
        <Row justify={"center"}>
          <Button
            onClick={() => {
              setOpenEditModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={() => {
              editLoan(displayLoan);
            }}
          >
            Update
          </Button>
        </Row>
      </ConfigProvider>
    </Content>
  );
}
