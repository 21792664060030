import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Layout,
  Row,
  Select,
  Tooltip,
} from "antd";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "../../../Context/displayContext";
import CountdownTimer from "./CountdownTimer";
import { toast } from "react-toastify";

export default function ViewVenueMarketBasis() {
  const displayContext = useContext(DisplayContext);
  const [assetBaseList, setAssetBaseList] = useState<any[]>([]);
  const [assetIdBase, setAssetIdBase] = useState<number>(12);
  const [assetIdQuote, setAssetIdQuote] = useState<number>(98);
  const [assetQuoteList, setAssetQuoteList] = useState<any[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [expDateMonth, setExpDateMonth] = useState<Date>(new Date());
  const [expDateQuarter, setExpDateQuarter] = useState<Date>(new Date());
  const [expDateSemiAnnual, setExpDateSemiAnnual] = useState<Date>(new Date());
  const [selectedDate, setSelectedDate] = useState<any>();
  const [symbol, setSymbol] = useState<string>("");
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  const getAssetList = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/market-price-asset-list",
      withCredentials: true,
    }).then((res) => {
      const baseOptions = [],
        quoteOptions = [];
      for (const asset of res.data.baseAssetList) {
        baseOptions.push({
          label: `${asset.name} (${asset.ticker})`,
          value: asset.id,
        });
      }
      for (const asset of res.data.quoteAssetList) {
        quoteOptions.push({
          label: `${asset.name} (${asset.ticker})`,
          value: asset.id,
        });
      }
      setAssetBaseList(baseOptions);
      setAssetQuoteList(quoteOptions);
    });
  }, []);

  useEffect(() => {
    getAssetList();
  }, [getAssetList]);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayContext, displayTimezone]);

  const fetchData = useCallback(
    (baseAsset: number, quoteAsset: number, date: any) => {
      setDataLoaded(false);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/market-price",
        params: {
          assetIdBase: baseAsset,
          assetIdQuote: quoteAsset,
          selectedDate: date ? date.toISOString() : undefined,
        },
        withCredentials: true,
      })
        .then((res) => {
          setSymbol(
            res.data.res.assetBase.ticker +
              "/" +
              res.data.res.assetQuote.ticker,
          );
          setData(res.data.res);
          setExpDateMonth(new Date(res.data.res.expDateMonth));
          setExpDateQuarter(new Date(res.data.res.expDateQuarter));
          setExpDateSemiAnnual(new Date(res.data.res.expDateSemiAnnual));
        })
        .catch((e) => {
          toast.error(e.response.data.message);
        })
        .finally(() => {
          setDataLoaded(true);
        });
    },
    [],
  );

  useEffect(() => {
    if (assetIdBase && assetIdQuote) {
      fetchData(assetIdBase, assetIdQuote, selectedDate);
    }
  }, [fetchData, assetIdBase, assetIdQuote, selectedDate]);

  const gridStyleLeft: React.CSSProperties = {
    width: "50%",
    textAlign: "left",
  };
  const calculatedGridStyleLeft: React.CSSProperties = {
    ...gridStyleLeft,
    backgroundColor: "lightblue",
  };
  const gridStyleRight: React.CSSProperties = {
    width: "50%",
    textAlign: "right",
  };
  const calculatedGridStyleRight: React.CSSProperties = {
    ...gridStyleRight,
    backgroundColor: "lightblue",
  };

  return (
    <Layout id="view-venue-market-basis">
      <Row className="dcl-filter-row" align={"bottom"}>
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Row
            style={{ marginBottom: "1em", marginTop: "1em" }}
          >
            <Col className="dcl-filter-item">
              <DatePicker
              style={{minWidth: '200px'}}
                className="dcl-date-select"
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e);
                }}
              />
            </Col>
          </Row>
          <Row className="dcl-filter-item">
            <Select
              className="dcl-asset-select"
              showSearch
              filterOption={(input, option) => {
                return (String(option?.label) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              allowClear
              placeholder="Base Asset"
              loading={!assetBaseList}
              value={assetIdBase}
              popupMatchSelectWidth={false}
              onChange={(e) => {
                setAssetIdBase(e);
              }}
              options={assetBaseList}
            />

            <Select
              className="dcl-asset-select"
              style={{ marginLeft: "10px" }}
              showSearch
              filterOption={(input, option) => {
                return (String(option?.label) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              allowClear
              placeholder="Quote Asset"
              loading={!assetQuoteList}
              value={assetIdQuote}
              popupMatchSelectWidth={false}
              onChange={(e) => {
                setAssetIdQuote(e);
              }}
              options={assetQuoteList}
            />
          </Row>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify={"end"} className="dcl-filter-item">
            <Col>
              <Button
                disabled={!assetIdBase || !assetIdQuote}
                onClick={() => {
                  if (assetIdBase && assetIdQuote) {
                    fetchData(assetIdBase, assetIdQuote, selectedDate);
                  }
                }}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Divider orientation="left">{symbol}</Divider>
        <Col lg={6} md={24}>
          <Card
            title={<b>Spot</b>}
            bordered={false}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
            extra={
              data ? (
                <Tooltip
                  title={new Date(data.timestamp).toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })}
                >
                  {new Date(data.timestamp).toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })}
                </Tooltip>
              ) : (
                ""
              )
            }
            loading={!dataLoaded}
          >
            <Card.Grid style={gridStyleLeft}>
              <b>Bid Price</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {data
                ? Number(data.marketPriceJSON.spot.bidPrice).toLocaleString(
                    "en-us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  )
                : "$0.00"}
            </Card.Grid>
            <Card.Grid style={gridStyleLeft}>
              <b>Ask Price</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {data
                ? Number(data.marketPriceJSON.spot.askPrice).toLocaleString(
                    "en-us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  )
                : "$0.00"}
            </Card.Grid>
            <Card.Grid style={calculatedGridStyleLeft}>
              <b>Mid Price</b>
            </Card.Grid>
            <Card.Grid style={calculatedGridStyleRight}>
              {data
                ? Number(data.marketPriceJSON.spot.midPrice).toLocaleString(
                    "en-us",
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  )
                : "$0.00"}
            </Card.Grid>
          </Card>
        </Col>
        <Col lg={18} md={24}>
          <Row>
            <Divider orientation="center">Single Collateral</Divider>
          </Row>
          <Row>
            <Col lg={8} md={24}>
              <Card
                title="Month"
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
                extra={
                  <div>
                    {data ? data.marketPriceJSON.future.month.symbol : ""}
                  </div>
                }
                loading={!dataLoaded}
              >
                <Card.Grid style={gridStyleLeft}>
                  <b>Bid Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.month.bid,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Ask Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.month.ask,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Mid Price</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.month.midPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b className="long-basis">Long Basis</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.month.bidBasis,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b className="short-basis">Short Basis</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.month.askBasis,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Mark Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.month.markPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Mark Price Difference</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.month.markPriceDiff,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Index Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.month.indexPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Index Price Difference</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.month.indexPriceDiff,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Expire Date</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {expDateMonth.toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Days to Expire</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  <CountdownTimer targetDate={expDateMonth} />
                </Card.Grid>
              </Card>
            </Col>
            <Col lg={8} md={24}>
              <Card
                title="Quarter"
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
                extra={
                  <div>
                    {data ? data.marketPriceJSON.future.quarter.symbol : ""}
                  </div>
                }
                loading={!dataLoaded}
              >
                <Card.Grid style={gridStyleLeft}>
                  <b>Bid Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.quarter.bid,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Ask Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.quarter.ask,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Mid Price</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.quarter.midPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b className="long-basis">Long Basis</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.quarter.bidBasis,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b className="short-basis">Short Basis</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.quarter.askBasis,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Mark Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.quarter.markPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Mark Price Difference</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.quarter.markPriceDiff,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Index Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.quarter.indexPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Index Price Difference</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.quarter.indexPriceDiff,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Expire Date</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {expDateQuarter.toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Days to Expire</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  <CountdownTimer targetDate={expDateQuarter} />
                </Card.Grid>
              </Card>
            </Col>
            <Col lg={8} md={24}>
              <Card
                title={<b>Semi Annual</b>}
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
                extra={
                  <div>
                    {data ? data.marketPriceJSON.future.semiAnnual.symbol : ""}
                  </div>
                }
                loading={!dataLoaded}
              >
                <Card.Grid style={gridStyleLeft}>
                  <b>Bid Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.semiAnnual.bid,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Ask Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.semiAnnual.ask,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Mid Price</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.semiAnnual.midPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b className="long-basis">Long Basis</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.semiAnnual.bidBasis,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b className="short-basis">Short Basis</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.semiAnnual.askBasis,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Mark Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.semiAnnual.markPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Mark Price Difference</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.semiAnnual.markPriceDiff,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Index Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.semiAnnual.indexPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Index Price Difference</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data
                    ? Number(
                        data.marketPriceJSON.future.semiAnnual.indexPriceDiff,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Expire Date</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {expDateSemiAnnual.toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Days to Expire</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  <CountdownTimer targetDate={expDateSemiAnnual} />
                </Card.Grid>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 18, offset: 6 }} md={24}>
          <Row>
            <Divider orientation="center">Multi Collateral</Divider>
          </Row>
          <Row>
            <Col lg={8} md={24}>
              <Card
                title="Month"
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
                extra={
                  <div>
                    {data && data.marketPriceJSON.future.monthMC
                      ? data.marketPriceJSON.future.monthMC.symbol
                      : ""}
                  </div>
                }
                loading={!dataLoaded}
              >
                <Card.Grid style={gridStyleLeft}>
                  <b>Bid Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data && data.marketPriceJSON.future.monthMC
                    ? Number(
                        data.marketPriceJSON.future.monthMC.bid,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Ask Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data && data.marketPriceJSON.future.monthMC
                    ? Number(
                        data.marketPriceJSON.future.monthMC.ask,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Mid Price</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data && data.marketPriceJSON.future.monthMC
                    ? Number(
                        data.marketPriceJSON.future.monthMC.midPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b className="long-basis">Long Basis</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data && data.marketPriceJSON.future.monthMC
                    ? Number(
                        data.marketPriceJSON.future.monthMC.bidBasis,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b className="short-basis">Short Basis</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data && data.marketPriceJSON.future.monthMC
                    ? Number(
                        data.marketPriceJSON.future.monthMC.askBasis,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Mark Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data && data.marketPriceJSON.future.monthMC
                    ? Number(
                        data.marketPriceJSON.future.monthMC.markPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Mark Price Difference</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data && data.marketPriceJSON.future.monthMC
                    ? Number(
                        data.marketPriceJSON.future.monthMC.markPriceDiff,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Index Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data && data.marketPriceJSON.future.monthMC
                    ? Number(
                        data.marketPriceJSON.future.monthMC.indexPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Index Price Difference</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data && data.marketPriceJSON.future.monthMC
                    ? Number(
                        data.marketPriceJSON.future.monthMC.indexPriceDiff,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Expire Date</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {expDateMonth.toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Days to Expire</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  <CountdownTimer targetDate={expDateMonth} />
                </Card.Grid>
              </Card>
            </Col>
            <Col lg={8} md={24}>
              <Card
                title="Quarter"
                bordered={false}
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
                extra={
                  <div>
                    {data && data.marketPriceJSON.future.quarterMC
                      ? data.marketPriceJSON.future.quarterMC.symbol
                      : ""}
                  </div>
                }
                loading={!dataLoaded}
              >
                <Card.Grid style={gridStyleLeft}>
                  <b>Bid Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data && data.marketPriceJSON.future.quarterMC
                    ? Number(
                        data.marketPriceJSON.future.quarterMC.bid,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Ask Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data && data.marketPriceJSON.future.quarterMC
                    ? Number(
                        data.marketPriceJSON.future.quarterMC.ask,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Mid Price</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data && data.marketPriceJSON.future.quarterMC
                    ? Number(
                        data.marketPriceJSON.future.quarterMC.midPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b className="long-basis">Long Basis</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data && data.marketPriceJSON.future.quarterMC
                    ? Number(
                        data.marketPriceJSON.future.quarterMC.bidBasis,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b className="short-basis">Short Basis</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data && data.marketPriceJSON.future.quarterMC
                    ? Number(
                        data.marketPriceJSON.future.quarterMC.askBasis,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Mark Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data && data.marketPriceJSON.future.quarterMC
                    ? Number(
                        data.marketPriceJSON.future.quarterMC.markPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Mark Price Difference</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data && data.marketPriceJSON.future.quarterMC
                    ? Number(
                        data.marketPriceJSON.future.quarterMC.markPriceDiff,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Index Price</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {data && data.marketPriceJSON.future.quarterMC
                    ? Number(
                        data.marketPriceJSON.future.quarterMC.indexPrice,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Index Price Difference</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  {data && data.marketPriceJSON.future.quarterMC
                    ? Number(
                        data.marketPriceJSON.future.quarterMC.indexPriceDiff,
                      ).toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "$0.00"}
                </Card.Grid>
                <Card.Grid style={gridStyleLeft}>
                  <b>Expire Date</b>
                </Card.Grid>
                <Card.Grid style={gridStyleRight}>
                  {expDateQuarter.toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })}
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleLeft}>
                  <b>Days to Expire</b>
                </Card.Grid>
                <Card.Grid style={calculatedGridStyleRight}>
                  <CountdownTimer targetDate={expDateQuarter} />
                </Card.Grid>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
}
