import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DisplayContext } from "../../../Context/displayContext";
import ViewFinalDataFrame from "./ViewFinalDataFrame";
import ViewInstantBalance from "./ViewInstantBalance";
import ViewVenueBalanceExposures from "./ViewVenueBalanceExposures";
dayjs.extend(utc);

const { RangePicker } = DatePicker;

export default function ViewDelchainBalances() {
  const { tabName = "daily" } = useParams();
  const displayContext = useContext(DisplayContext);

  const navigate = useNavigate();
  const [assetOptions, setAssetOptions] = useState<any[]>();
  const [venueOptions, setVenueOptions] = useState<any[]>();
  const [onFinishLoad, setOnFinishLoad] = useState<boolean>(false);
  const [venueBalanceList, setVenueBalanceList] = useState<any[]>();
  const [balanceCount, setBalanceCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState(0);
  const [currPageSize, setCurrPageSize] = useState(10);
  const [filterForm] = Form.useForm();
  const [csvForm] = Form.useForm();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const [showDisplayFilter, setShowDisplayFilter] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [displayColumns, setDisplayColumns] = useState<any[]>([]);
  const [tableSorter, setSorter] = useState<any>();
  const [venueAccountList, setVenueAccountList] = useState<any>();
  const [selectedVenues, setSelectedVenues] = useState<any>([]);
  const [venueAccountDropdownList, setVenueAccountDropdownList] = useState<any>(
    [],
  );
  const [totalUsdValue, setTotalUsdValue] = useState<number | undefined>();
  const [displayVenueExposureData, setDisplayVenueExposureData] =
    useState<boolean>(false);
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today and today
    return current > dayjs().endOf("day");
  };

  const fetchWidgetDisplay = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/dashboard-widget-display",
      withCredentials: true,
    }).then((res) => {
      if (res.data.widgetVenueExposureData) {
        setDisplayVenueExposureData(res.data.widgetLatestVenuesData);
      }
    });
  }, []);

  const filterData = (form: any) => {
    let startDate, endDate;
    setOnFinishLoad(false);
    if (form.dateRange) {
      startDate = form.dateRange[0]
        .tz("America/New_York", true)
        .startOf("day")
        .toISOString();
      endDate = form.dateRange[1]
        .tz("America/New_York", true)
        .endOf("day")
        .toISOString();
    }
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/balance",
      params: {
        offset: 0,
        pageSize: currPageSize,
        startDate: form.dateRange ? startDate : undefined,
        endDate: form.dateRange ? endDate : undefined,
        venues: form.venue ? JSON.stringify(form.venue) : undefined,
        assetNames: form.assetNames
          ? JSON.stringify(form.assetNames)
          : undefined,
        venueAccounts: form.venueAccountIds
          ? JSON.stringify(form.venueAccountIds)
          : undefined,
        sortField: tableSorter ? tableSorter.field : "timestamp",
        sortOrder: tableSorter ? tableSorter.order : "descend",
      },
      withCredentials: true,
    })
      .then((res) => {
        setBalanceCount(res.data.count);
        setVenueBalanceList(res.data.balanceList);
        setTotalUsdValue(res.data.totalUsd);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };

  const handleTableChange = (_: any, __: any, sorter: any) => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/balance",
      params: {
        offset:
          currentPage === 1 || currentPage === 0
            ? 0
            : (currentPage - 1) * currPageSize,
        pageSize: currPageSize,
        startDate: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[0]
              .tz("America/New_York", true)
              .startOf("day")
              .toISOString()
          : undefined,
        endDate: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[1]
              .tz("America/New_York", true)
              .endOf("day")
              .toISOString()
          : undefined,
        assetNames: filterForm.getFieldValue("assetNames")
          ? JSON.stringify(filterForm.getFieldValue("assetNames"))
          : "",
        venues: filterForm.getFieldValue("venue")
          ? JSON.stringify(filterForm.getFieldValue("venue"))
          : "",
        sortField: sorter.field,
        sortOrder: sorter.order,
      },
      withCredentials: true,
    })
      .then((res) => {
        setVenueBalanceList(res.data.balanceList);
      })
      .catch(() => {
        console.error("Unable to fetch user list");
      });
    setSorter({ ...sorter });
  };

  const changeColumnDisplay = (dataIndex: string) => {
    columns.find((e) => e.dataIndex === dataIndex).hidden = !columns.find(
      (e) => e.dataIndex === dataIndex,
    ).hidden;
    setDisplayColumns(columns.filter((item) => !item.hidden));
  };

  const initialColumns = [
    {
      title: <Space>Timestamp</Space>,
      dataIndex: "timestamp",
      sorter: true,
      width: "150px",
      hidden: false,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: displayContext?.displayContext.timezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
    {
      title: <Space>Venue</Space>,
      dataIndex: "venue",
      sorter: true,
      width: "90px",
      hidden: false,
      render: (_: any, { venue }: any) => <Space>{venue.name}</Space>,
    },
    {
      title: <Space>Venue Account</Space>,
      dataIndex: "vanueAccountLabel",
      sorter: false,
      width: "90px",
      hidden: false,
      render: (_: any, { vanueAccountLabel }: any) => (
        <Space>{vanueAccountLabel}</Space>
      ),
    },
    {
      title: <Space>Asset Name</Space>,
      dataIndex: "assetName",
      width: "150px",
      hidden: false,
      render: (_: any, { asset }: any) => <Space>{asset.name}</Space>,
    },

    {
      title: <Space>Asset Ticker</Space>,
      dataIndex: "assetTicker",
      width: "90px",
      sorter: true,
      render: (_: any, { asset }: any) => <Space>{asset.ticker}</Space>,
    },
    {
      title: <Space>Free Amount</Space>,
      dataIndex: "freeAmount",
      width: "120px",
      hidden: false,
      align: "right" as const,
      render: (_: any, { freeAmount }: any) =>
        !isNaN(freeAmount) ? (
          <Space>
            {freeAmount.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: <Space>Hold Amount</Space>,
      dataIndex: "holdAmount",
      width: "120px",
      hidden: false,

      align: "right" as const,
      render: (_: any, { holdAmount }: any) =>
        !isNaN(holdAmount) ? (
          <Space>
            {holdAmount.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: <Space>Total Amount</Space>,
      dataIndex: "totalAmount",
      width: "120px",
      hidden: false,
      sorter: true,
      align: "right" as const,
      render: (_: any, { totalAmount }: any) =>
        !isNaN(totalAmount) ? (
          <Space>
            {totalAmount.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: <Space>Total USD Value</Space>,
      dataIndex: "totalUsd",
      width: "120px",
      hidden: false,
      sorter: true,
      align: "right" as const,
      render: (_: any, { totalUsd }: any) =>
        !isNaN(totalUsd) ? (
          <Space>
            <Tooltip
              title={`$ ${Intl.NumberFormat("en-US", {
                minimumFractionDigits: 2,
                currency: "USD",
              }).format(totalUsd)}`}
            >
              $
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: "USD",
              }).format(totalUsd)}
            </Tooltip>
          </Space>
        ) : (
          <Space>N/A</Space>
        ),
    },
    {
      title: <Space>Mid Price</Space>,
      dataIndex: "midUsdPrice",
      width: "120px",
      hidden: false,
      align: "right" as const,
      render: (_: any, { midUsdPrice }: any) =>
        !isNaN(midUsdPrice) ? (
          <Space>
            <Tooltip
              title={`$ ${Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
              }).format(midUsdPrice)}`}
            >
              $
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: "USD",
              }).format(midUsdPrice)}
            </Tooltip>
          </Space>
        ) : (
          <Space>N/A</Space>
        ),
    },
    {
      title: <Space>Ask Price</Space>,
      dataIndex: "askUsdPrice",
      width: "120px",
      hidden: false,
      align: "right" as const,
      render: (_: any, { askUsdPrice }: any) =>
        !isNaN(askUsdPrice) ? (
          <Space>
            <Tooltip
              title={`$ ${Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
              }).format(askUsdPrice)}`}
            >
              $
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: "USD",
              }).format(askUsdPrice)}
            </Tooltip>
          </Space>
        ) : (
          <Space>N/A</Space>
        ),
    },
    {
      title: <Space>Bid Price</Space>,
      dataIndex: "bidUsdPrice",
      width: "120px",
      hidden: false,
      // fixed: "right",
      align: "right" as const,
      render: (_: any, { bidUsdPrice }: any) =>
        !isNaN(bidUsdPrice) ? (
          <Space>
            <Tooltip
              title={`$ ${Intl.NumberFormat("en-US", {
                currency: "USD",
                minimumFractionDigits: 2,
              }).format(bidUsdPrice)}`}
            >
              $
              {Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                currency: "USD",
              }).format(bidUsdPrice)}
            </Tooltip>
          </Space>
        ) : (
          <Space>N/A</Space>
        ),
    },
  ];

  const onReset = () => {
    filterForm.resetFields();
    fetchData();
  };

  const downloadCSV = () => {
    const hidden = Object.entries(csvForm.getFieldsValue()).filter(
      (e) => e[1] === false,
    );

    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/balance/export-csv",
      data: {
        startDate: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[0]
              .tz("America/New_York", true)
              .startOf("day")
              .toISOString()
          : undefined,
        endDate: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[1]
              .tz("America/New_York", true)
              .endOf("day")
              .toISOString()
          : undefined,
        assetNameIds: filterForm.getFieldValue("assetNames")
          ? filterForm.getFieldValue("assetNames")
          : undefined,
        venueIds: filterForm.getFieldValue("venue")
          ? filterForm.getFieldValue("venue")
          : undefined,
        venueAccounts: filterForm.getFieldValue("venueAccountIds")
          ? filterForm.getFieldValue("venueAccountIds")
          : undefined,
        hiddenColumns: hidden.length ? hidden : undefined,
      },
      withCredentials: true,
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_delchain_balance.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  };

  const fetchData = useCallback(
    (
      params = {
        offset: 0,
        pageSize: 10,
        assetNames: "",
        venue: "",
        venueAccounts: "",
        startDate: undefined,
        endDate: undefined,
        sortField: undefined,
        sortOrder: undefined,
      },
    ) => {
      setOnFinishLoad(false);
      axios({
        method: "GET",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/balance",
        params: {
          offset: params.offset,
          pageSize: params.pageSize,
          startDate: params.startDate,
          endDate: params.endDate,
          venues: params.venue ? params.venue : undefined,
          venueAccounts: params.venueAccounts
            ? params.venueAccounts
            : undefined,
          assetNames: params.assetNames ? params.assetNames : undefined,
          sortField: params.sortField ? params.sortField : "timestamp",
          sortOrder: params.sortOrder ? params.sortOrder : "descend",
        },
        withCredentials: true,
      })
        .then((res) => {
          setCurrPageSize(params.pageSize);
          if (params.offset === 0) {
            setBalanceCount(res.data.count);
            setTotalUsdValue(res.data.totalUsd);
          }
          setVenueBalanceList(res.data.balanceList);
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
    },
    [],
  );

  const onPaginationChange = (current: any, pageSize: any) => {
    setOnFinishLoad(false);
    const offset =
      current === 1 || current === 0 ? 0 : (current - 1) * pageSize;
    setCurrentPage(current);
    setCurrPageSize(pageSize);
    fetchData({
      offset: offset,
      pageSize: pageSize,
      startDate: filterForm.getFieldValue("dateRange")
        ? filterForm
            .getFieldValue("dateRange")[0]
            .tz("America/New_York", true)
            .startOf("day")
            .toISOString()
        : undefined,
      endDate: filterForm.getFieldValue("dateRange")
        ? filterForm
            .getFieldValue("dateRange")[1]
            .tz("America/New_York", true)
            .endOf("day")
            .toISOString()
        : undefined,
      assetNames: filterForm.getFieldValue("assetNames")
        ? JSON.stringify(filterForm.getFieldValue("assetNames"))
        : "",
      venue: filterForm.getFieldValue("venue")
        ? JSON.stringify(filterForm.getFieldValue("venue"))
        : "",
      venueAccounts: filterForm.getFieldValue("venueAccountIds")
        ? JSON.stringify(filterForm.getFieldValue("venueAccountIds"))
        : "",
      sortField: tableSorter ? tableSorter.field : undefined,
      sortOrder: tableSorter ? tableSorter.order : undefined,
    });
  };

  const getAssetList = useCallback((params = {}) => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, []);

  const getVenueList = useCallback(
    (params = {}) => {
      setOnFinishLoad(false);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
        withCredentials: true,
      })
        .then((res) => {
          setVenueOptions(res.data.list);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
    },
    [navigate],
  );

  const getVenueAccountList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/venue-accounts",
      withCredentials: true,
    })
      .then((res) => {
        const data = res.data.venueAccountList.map((row: any) => ({
          key: row.id,
          venueId: row.venueId,
          label: `${row.label}${
            row.accountNumber ? ` (${row.accountNumber})` : ""
          }`,
          value: row.id,
        }));
        setVenueAccountList(data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
          return;
        }
      });
  }, [navigate]);

  useEffect(() => {
    getVenueAccountList();
  }, [getVenueAccountList]);

  useEffect(() => {
    if (
      venueAccountList &&
      venueAccountList.length &&
      selectedVenues &&
      selectedVenues.length
    ) {
      const filteredAccount = venueAccountList.filter((row: any) =>
        selectedVenues.includes(row.venueId),
      );
      setVenueAccountDropdownList(filteredAccount);
    } else if (venueAccountList && venueAccountList.length) {
      setVenueAccountDropdownList(venueAccountList);
    } else if (
      venueAccountList &&
      venueAccountList.length &&
      !selectedVenues.length
    ) {
      setVenueAccountDropdownList(venueAccountList);
    }
  }, [venueAccountList, selectedVenues]);

  useEffect(() => {
    if (filterForm) {
      fetchData({
        offset: 0,
        pageSize: currPageSize,
        startDate: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[0]
              .tz("America/New_York", true)
              .startOf("day")
              .toISOString()
          : undefined,
        endDate: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[1]
              .tz("America/New_York", true)
              .endOf("day")
              .toISOString()
          : undefined,
        assetNames: filterForm.getFieldValue("assetNames")
          ? JSON.stringify(filterForm.getFieldValue("assetNames"))
          : "",
        venue: filterForm.getFieldValue("venue")
          ? JSON.stringify(filterForm.getFieldValue("venue"))
          : "",
        venueAccounts: filterForm.getFieldValue("venueAccountIds")
          ? JSON.stringify(filterForm.getFieldValue("venueAccountIds"))
          : "",
        sortField: tableSorter ? tableSorter.field : undefined,
        sortOrder: tableSorter ? tableSorter.order : undefined,
      });
    } else {
      fetchData();
    }
    fetchWidgetDisplay();
    getAssetList();
    getVenueList();
    setColumns(initialColumns);
    setDisplayColumns(initialColumns.filter((item) => !item.hidden));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchData,
    getAssetList,
    getVenueList,
    displayContext,
    currPageSize,
    fetchWidgetDisplay,
  ]);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayContext, displayTimezone]);

  return onFinishLoad ? (
    <Content id="dcl-balances">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/venues/delchain_balances/" + key);
        }}
        items={[
          {
            label: "Daily Balances",
            key: "daily",
            children: (
              <React.Fragment>
                <Row className="dcl-filter-row">
                  <Col md={{ span: 16 }} sm={{ span: 16 }} xs={{ span: 16 }}>
                    <Row className="dcl-filter-row" align="middle">
                      <Space>
                        <Button
                          className="dcl-btn-toggle"
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setShowFilter(!showFilter);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={"fa-solid fa-filter" as IconProp}
                          />
                          Filters
                          {showFilter ? (
                            <FontAwesomeIcon
                              icon={"fa-solid fa-caret-up" as IconProp}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={"fa-solid fa-caret-down" as IconProp}
                            />
                          )}
                        </Button>
                      </Space>
                      <Space>
                        <Button
                          className="dcl-btn-toggle"
                          style={{ marginRight: "10px" }}
                          onClick={() => {
                            setShowFilter(false);
                            setShowDisplayFilter(!showDisplayFilter);
                          }}
                        >
                          Display
                          {showDisplayFilter ? (
                            <FontAwesomeIcon
                              icon={"fa-solid fa-caret-up" as IconProp}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={"fa-solid fa-caret-down" as IconProp}
                            />
                          )}
                        </Button>
                      </Space>
                    </Row>
                    <Form
                      title="Filter"
                      form={filterForm}
                      onFinish={filterData}
                      className="dcl-toggled-content dcl-toggled-content-filter"
                      hidden={!showFilter}
                      labelWrap
                    >
                      <Row>
                        <Form.Item name="dateRange" className="dcl-filter-item">
                          <RangePicker
                            disabledDate={disabledDate}
                            className="dcl-daterange-select"
                          />
                        </Form.Item>
                        <Form.Item name="venue" className="dcl-filter-item">
                          <Select
                            className="dcl-venue-select"
                            mode="multiple"
                            allowClear
                            placeholder="Venues"
                            options={venueOptions}
                            onChange={(e) => {
                              setSelectedVenues([...e]);
                            }}
                            popupMatchSelectWidth={false}
                            filterOption={(input, option: any) => {
                              return option.label
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="venueAccountIds"
                          className="dcl-filter-item"
                        >
                          <Select
                            className="dcl-order-type-select"
                            mode="multiple"
                            allowClear
                            placeholder="Venue Accounts"
                            options={venueAccountDropdownList}
                            popupMatchSelectWidth={false}
                            filterOption={(input, option: any) => {
                              return option.label
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="assetNames"
                          className="dcl-filter-item"
                        >
                          <Select
                            className="dcl-asset-select"
                            mode="multiple"
                            allowClear
                            placeholder="Asset Ticker"
                            options={assetOptions}
                            popupMatchSelectWidth={false}
                            filterOption={(input, option: any) => {
                              return option.label
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }}
                          />
                        </Form.Item>
                      </Row>
                      <Row justify="end">
                        <Space>
                          <Button htmlType="submit" className="ant-btn-primary">
                            Apply
                          </Button>
                          <Button onClick={onReset}>Reset</Button>
                        </Space>
                      </Row>
                    </Form>
                    <Form
                      className="dcl-toggled-content dcl-toggled-content-csv"
                      form={csvForm}
                      hidden={!showDisplayFilter}
                    >
                      <Row>
                        <Form.Item
                          name="timestamp"
                          label="Timestamp"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("timestamp");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="venue"
                          label="Venue"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("venue");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="assetName"
                          label="Asset Name"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("assetName");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="assetTicker"
                          label="Asset Ticker"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("assetTicker");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="freeAmount"
                          label="Free Amount"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("freeAmount");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="holdAmount"
                          label="Hold Amount"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("holdAmount");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="totalAmount"
                          label="Total Amount"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("totalAmount");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="totalUsd"
                          label="Total USD Value"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("totalUsd");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="midUsdPrice"
                          label="Mid Price"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("midUsdPrice");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="askUsdPrice"
                          label="Ask Price"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("askUsdPrice");
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="bidUsdPrice"
                          label="Bid Price"
                          className="dcl-filter-item"
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={true}
                            onClick={() => {
                              changeColumnDisplay("bidUsdPrice");
                            }}
                          />
                        </Form.Item>
                      </Row>
                    </Form>
                  </Col>
                  <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
                    <Row justify="end">
                      <Button
                        className="dcl-btn-toggle"
                        style={{ marginRight: "10px" }}
                        // disabled={true}
                        onClick={downloadCSV}
                      >
                        <FontAwesomeIcon
                          icon={"fa-solid fa-file-csv" as IconProp}
                        />
                        CSV Export
                      </Button>
                    </Row>
                  </Col>
                </Row>
                <Table
                  dataSource={venueBalanceList}
                  sticky
                  showSorterTooltip={false}
                  columns={displayColumns}
                  pagination={{
                    pageSize: currPageSize,
                    hideOnSinglePage: true,
                  }}
                  onChange={handleTableChange}
                  scroll={{
                    x: "max-content",
                  }}
                  summary={() =>
                    totalUsdValue ? (
                      <Table.Summary>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={1} colSpan={2}>
                            <b>Total USD</b>
                          </Table.Summary.Cell>
                          <Table.Summary.Cell
                            index={1}
                            colSpan={6}
                          ></Table.Summary.Cell>
                          <Table.Summary.Cell index={2} align="right">
                            {`$${totalUsdValue?.toLocaleString("en-us", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </Table.Summary>
                    ) : (
                      <></>
                    )
                  }
                />
                <Row justify="end" className="pagination-row">
                  <Pagination
                    showSizeChanger
                    onChange={onPaginationChange}
                    current={currentPage}
                    pageSize={currPageSize}
                    // pageSizeOptions={[2, 4, 10]}
                    total={balanceCount}
                  />
                </Row>
              </React.Fragment>
            ),
          },
          {
            label: "Instant Balances",
            key: "instant",
            children: <ViewInstantBalance />,
          },
          {
            label: "Consolidated Balances",
            key: "consolidatedBalances",
            children: <ViewFinalDataFrame />,
          },
          {
            label: "Venue Exposures",
            key: "venueExposures",
            disabled: !displayVenueExposureData,
            children: <ViewVenueBalanceExposures />,
          },
        ]}
      />
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
