import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Collapse,
  ConfigProvider,
  Divider,
  InputNumber,
  Layout,
  Progress,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import axios from "axios";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DisplayContext } from "../../../Context/displayContext";

export default function ViewKrakenFees() {
  // Time Related
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [minus30Days, setMinus30Days] = useState<Date>(
    dayjs().subtract(30, "d").toDate(),
  );
  const [currentTier, setCurrentTier] = useState<any>();
  const [nextSpotTier, setNextSpotTier] = useState<any>();

  const [currentPercent, setCurrentPrecent] = useState<number>();
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
      setMinus30Days(dayjs().subtract(30, "d").toDate());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayContext, displayTimezone]);

  const feeScheduleColumns = [
    {
      title: "Tier",
      dataIndex: "key",
      align: "right" as const,
      width: "5%",
    },
    {
      title: "30-Day Volume (USD)",
      dataIndex: "range",
      width: "39%",
    },
    {
      title: "Lower",
      dataIndex: "lower",
      align: "right" as const,
      width: "18%",
      render: (_: any, { lower }: any) => (
        <>
          $
          {lower
            ? lower.toLocaleString("en-us", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : 0}
        </>
      ),
    },
    {
      title: "Upper",
      dataIndex: "upper",
      align: "right" as const,
      width: "18%",
      render: (_: any, { upper }: any) => (
        <>
          $
          {upper
            ? upper.toLocaleString("en-us", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : 0}
        </>
      ),
    },
    {
      title: "Maker",
      dataIndex: "makerFee",
      align: "right" as const,
      width: "10%",
      render: (_: any, { makerFee }: any) => (
        <>
          {makerFee
            ? makerFee.toLocaleString("en-us", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : 0}
          %
        </>
      ),
    },
    {
      title: "Taker",
      dataIndex: "takerFee",
      align: "right" as const,
      width: "10%",
      render: (_: any, { takerFee }: any) => (
        <>
          {takerFee
            ? takerFee.toLocaleString("en-us", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : 0}
          %
        </>
      ),
    },
  ];

  const volumeTableColumns = [
    {
      title: "Date",
      dataIndex: "key",
      width: "10%",
      render: (_: any, { key }: any) => (
        <>{dayjs(key, "YYYYMMDD").format("MM/DD/YYYY")}</>
      ),
    },
    {
      title: "USD Volume",
      dataIndex: "withoutStableValue",
      width: "20%",
      align: "right" as const,
      render: (_: any, { withoutStableValue }: any, dataIndex: number) => (
        <>
          {dataIndex < 30 ? (
            `$
        ${withoutStableValue.toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
          ) : (
            <InputNumber
              addonBefore={"$"}
              controls={false}
              defaultValue={withoutStableValue}
              formatter={(value) => {
                const parts = `${value}`.split(".");
                // Apply the original regex only to the first part (integer part) to add commas.
                const integerPartWithCommas = parts[0].replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ",",
                );
                // Reassemble the parts, including the decimal part if it was present.
                return parts.length > 1
                  ? `${integerPartWithCommas}.${parts[1]}`
                  : integerPartWithCommas;
              }}
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              onChange={(e) => handleVolumeInput(e, dataIndex)}
            />
          )}
        </>
      ),
    },
    {
      title: "Rolling 30D Volume",
      dataIndex: "rolling30DVolume",
      align: "right" as const,
      render: (_: any, { rollingSum }: any, dataIndex: number) => (
        <>
          $
          {rollingSum
            ? rollingSum.toLocaleString("en-us", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : "0.00"}
        </>
      ),
    },
    {
      title: "Stablecoin Fees",
      items: [
        {
          title: "Fee Tier",
          dataIndex: "stableTier",
          align: "right" as const,
          render: (_: any, { rollingSum }: any, i: number) =>
            `${findStableTierLevel(rollingSum, i).key}`,
        },
        {
          title: "Maker Fee",
          dataIndex: "stableMakerFee",
          align: "right" as const,
          render: (_: any, { rollingSum }: any, i: number) =>
            `${findStableTierLevel(rollingSum, i).makerFee}%`,
        },
        {
          title: "Taker Fee",
          dataIndex: "stableTakerFee",
          align: "right" as const,
          render: (_: any, { rollingSum }: any, i: number) =>
            `${findStableTierLevel(rollingSum, i).takerFee}%`,
        },
      ],
    },
    {
      title: "Spot Fees",
      items: [
        {
          title: "Fee Tier",
          dataIndex: "spotTier",
          align: "right" as const,
          render: (_: any, { rollingSum }: any, i: number) =>
            `${findSpotTierLevel(rollingSum, i).key}`,
        },
        {
          title: "Maker Fee",
          dataIndex: "spotMakerFee",
          align: "right" as const,
          render: (_: any, { rollingSum }: any, i: number) =>
            `${findSpotTierLevel(rollingSum, i).makerFee}%`,
        },
        {
          title: "Taker Fee",
          dataIndex: "spotTakerFee",
          align: "right" as const,
          render: (_: any, { rollingSum }: any, i: number) =>
            `${findSpotTierLevel(rollingSum, i).takerFee}%`,
        },
      ],
    },
  ];

  const [stableCoinFeeStructure, setStableFeeStructure] = useState<any[]>();
  const [spotCoinFeeStructure, setSpotFeeStructure] = useState<any[]>();
  const [volumeFetchedFromKraken, setVolumeFetchedFromKraken] =
    useState<number>(0);
  const [volumeTotalWithStableCoin, setVolumeWithStableCoin] =
    useState<number>(0);
  const [volumeTotalWithoutStableCoin, setVolumeWithoutStableCoin] =
    useState<number>(0);
  const [volumeDataFromDSP, setVolumeDataFromDSP] = useState<any[]>();
  const [krakenVolumeTier, setKrakenVolumeTier] = useState<any>();

  const [datafetched, setDataFetched] = useState<boolean>(false);

  const calculateSum = useCallback(
    (index: number) => {
      let result = 0;
      if (!volumeDataFromDSP || !volumeDataFromDSP.length) {
        return 0;
      }
      if (index < 30) {
        // If the index is one of the first 30 records, sum withoutStableValue until that index
        result = volumeDataFromDSP
          .slice(0, index)
          .reduce((sum, item) => sum + item.withoutStableValue, 0);
      } else {
        // If the index is not in the first 30 records, sum withoutStableValue for the last 30 records
        result = volumeDataFromDSP
          .slice(index - 29, index)
          .reduce((sum, item) => sum + item.withoutStableValue, 0);
      }
      return result;
    },
    [volumeDataFromDSP],
  );

  const fetchKrakenFees = useCallback(() => {
    axios({
      method: "get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/volume-breakdown",
      withCredentials: true,
    })
      .then((res) => {
        setStableFeeStructure(res.data.stableFeeStructure);
        setSpotFeeStructure(res.data.spotFeeStructure);
        setVolumeFetchedFromKraken(res.data.volumeTotalFromKraken);
        setVolumeWithStableCoin(res.data.volumeTotalWithStableCoin);
        setVolumeWithoutStableCoin(res.data.volumeTotalWithoutStableCoin);
        const currentTier = res.data.spotFeeStructure.find(
          (row: any) =>
            row.lower <= res.data.volumeTotalFromKraken &&
            res.data.volumeTotalFromKraken <= row.upper,
        );
        const nextTierKey = currentTier.key + 1;
        const nextSpotTier = res.data.spotFeeStructure.find(
          (row: any) => row.key === nextTierKey,
        );
        if (nextSpotTier) setNextSpotTier(nextSpotTier);
        setCurrentTier(currentTier);

        setCurrentPrecent(
          Number(
            (
              (res.data.volumeTotalFromKraken / currentTier.upper) *
              100
            ).toFixed(2),
          ),
        );
        setKrakenVolumeTier(res.data.krakenVolumeTier);
        if (
          !res.data.volumeDataSummary ||
          res.data.volumeDataSummary.length < 30
        ) {
          throw Error("Failed to fetch Volumes");
        }
        for (const key in res.data.volumeDataSummary) {
          const index = Number(key);
          const row = res.data.volumeDataSummary[key];
          row.rollingSum =
            Number(index) < 30
              ? res.data.volumeDataSummary
                  .slice(0, index + 1)
                  .reduce(
                    (sum: number, item: any) => sum + item.withoutStableValue,
                    0,
                  )
              : res.data.volumeDataSummary
                  .slice(index - 29, index + 1)
                  .reduce(
                    (sum: number, item: any) => sum + item.withoutStableValue,
                    0,
                  );
        }
        setVolumeDataFromDSP(res.data.volumeDataSummary.slice(30));
        setDataFetched(true);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => {});
  }, []);

  useEffect(() => {
    fetchKrakenFees();
  }, [fetchKrakenFees]);

  const handleVolumeInput = useCallback(
    (e: number, dataIndex: number) => {
      if (!volumeDataFromDSP) return;

      for (let index = dataIndex; index < volumeDataFromDSP.length; index++) {
        const followingRow = {
          ...volumeDataFromDSP[index],
          withoutStableValue:
            index === dataIndex
              ? e
              : volumeDataFromDSP[index].withoutStableValue,
          rollingSum:
            index === dataIndex
              ? calculateSum(index) + e
              : calculateSum(index) +
                volumeDataFromDSP[index].withoutStableValue,
        };
        volumeDataFromDSP[index] = followingRow;
      }
      setVolumeDataFromDSP([...volumeDataFromDSP]);
    },
    [volumeDataFromDSP, calculateSum],
  );

  const findSpotTierLevel = useCallback(
    (inputNumber: number, index: number) => {
      if (!spotCoinFeeStructure || !volumeDataFromDSP) return -1;

      let left = 0;
      let right = spotCoinFeeStructure.length - 1;

      while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const tier = spotCoinFeeStructure[mid];

        if (inputNumber >= tier.lower && inputNumber < tier.upper) {
          volumeDataFromDSP[index].spotTier = tier;
          return tier;
        } else if (inputNumber < tier.lower) {
          right = mid - 1;
        } else {
          left = mid + 1;
        }
      }
      volumeDataFromDSP[index].spotTier =
        spotCoinFeeStructure[spotCoinFeeStructure.length - 1];
      return spotCoinFeeStructure[spotCoinFeeStructure.length - 1];
    },
    [volumeDataFromDSP, spotCoinFeeStructure],
  );

  const findStableTierLevel = useCallback(
    (inputNumber: number, index: number) => {
      if (!stableCoinFeeStructure || !volumeDataFromDSP) return -1;

      let left = 0;
      let right = stableCoinFeeStructure.length - 1;

      while (left <= right) {
        const mid = Math.floor((left + right) / 2);
        const tier = stableCoinFeeStructure[mid];

        if (inputNumber >= tier.lower && inputNumber < tier.upper) {
          volumeDataFromDSP[index].stableTier = tier;
          return tier;
        } else if (inputNumber < tier.lower) {
          right = mid - 1;
        } else {
          left = mid + 1;
        }
      }
      volumeDataFromDSP[index].stableTier =
        stableCoinFeeStructure[stableCoinFeeStructure.length - 1];
      return stableCoinFeeStructure[stableCoinFeeStructure.length - 1];
    },
    [volumeDataFromDSP, stableCoinFeeStructure],
  );

  const csvExport = useCallback(() => {
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/volume-breakdown/export-csv",
      withCredentials: true,
      data: {
        volumeData: volumeDataFromDSP,
      },
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      let date = new Date();

      link.setAttribute(
        "download",
        `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
          2,
          "0",
        )}${String(date.getDate()).padStart(2, "0")}_kraken_fees.csv`,
      );
      document.body.appendChild(link);
      link.click();
    });

    console.log(volumeDataFromDSP);
  }, [volumeDataFromDSP]);

  return (
    <Layout id="view-kraken-fees">
      {datafetched ? (
        <React.Fragment>
          <Divider />
          <Row justify={"space-evenly"}>
            <Col
              xxl={{ span: 7 }}
              lg={{ span: 7 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <b>Now: </b>
              {currentTime.toLocaleString("en-US", {
                timeZone: displayContext?.displayContext.timezone,
                timeZoneName: "short",
              })}
            </Col>
            <Col
              xxl={{ span: 7 }}
              lg={{ span: 7 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <b>Now - 30 days: </b>
              {minus30Days.toLocaleString("en-US", {
                timeZone: displayContext?.displayContext.timezone,
                timeZoneName: "short",
              })}
            </Col>
          </Row>
          <Divider orientation="left" orientationMargin={50}>
            Kraken Fetched Volume (fetched at 2am UTC)
          </Divider>
          <Row justify={"space-evenly"}>
            <Col
              xxl={{ span: 9 }}
              lg={{ span: 9 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Card
                title="Kraken 30D Trade Volume (without stable coin and FX)"
                size="small"
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={{ width: "100%" }}>
                  <Row justify={"end"}>
                    $
                    {volumeFetchedFromKraken.toLocaleString("en-us", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Row>
                </Card.Grid>
              </Card>
            </Col>
            <Col
              xxl={{ span: 9 }}
              lg={{ span: 9 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Card
                title="Kraken fees"
                size="small"
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={{ width: "50%" }}>
                  <b>Stablecoins:</b>
                </Card.Grid>
                <Card.Grid style={{ width: "50%" }}>
                  <b>Non Stable Coins:</b>
                </Card.Grid>
                <Card.Grid style={{ width: "50%" }}>
                  <b style={{ marginLeft: "2em" }}>Maker: </b>
                  {krakenVolumeTier.stableMaker}%
                </Card.Grid>
                <Card.Grid style={{ width: "50%" }}>
                  <b style={{ marginLeft: "2em" }}>Maker: </b>
                  {krakenVolumeTier.spotMaker}%
                </Card.Grid>
                <Card.Grid style={{ width: "50%" }}>
                  <b style={{ marginLeft: "2em" }}>Taker: </b>
                  {krakenVolumeTier.stableTaker}%
                </Card.Grid>
                <Card.Grid style={{ width: "50%" }}>
                  <b style={{ marginLeft: "2em" }}>Taker: </b>
                  {krakenVolumeTier.spotTaker}%
                </Card.Grid>
              </Card>
            </Col>
          </Row>
          <Divider orientation="left" orientationMargin={50}>
            Kraken Calculated Volume (Instant)
          </Divider>

          <Row justify={"space-evenly"}>
            <Col
              xxl={{ span: 9 }}
              lg={{ span: 9 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Card
                title="Delchain 30D Calculated Volume(without stable coin and FX)"
                size="small"
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Row justify={"end"}>
                  $
                  {volumeTotalWithoutStableCoin?.toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Row>
              </Card>
            </Col>
            <Col
              xxl={{ span: 9 }}
              lg={{ span: 9 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Card
                title="Delchain 30D Calculated Volume"
                size="small"
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Row justify={"end"}>
                  $
                  {volumeTotalWithStableCoin.toLocaleString("en-us", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Row>
              </Card>
            </Col>
          </Row>
          <Divider orientation="left" orientationMargin={50}>
            Kraken Fee Schedule
          </Divider>
          <Row
            style={{
              marginBottom: "15px",
            }}
            justify={"space-evenly"}
          >
            <Col span={20}>
              <Card
                title={<b>Current fees based on 30-Volume fetch)</b>}
                size="small"
                style={{
                  margin: "10px",
                  borderRadius: "10px",
                }}
              >
                <Card.Grid style={{ width: "50%" }}>
                  <Row>
                    <Col span={24}>
                      <Row style={{ fontSize: "12px", margin: "0px" }}>
                        <b>{`$${volumeFetchedFromKraken?.toLocaleString(
                          "en-us",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )} USD / $${currentTier?.upper?.toLocaleString(
                          "en-us",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )} USD (${currentPercent}%)`}</b>
                      </Row>
                      <Tooltip
                        title={`$${volumeFetchedFromKraken?.toLocaleString(
                          "en-us",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          },
                        )} / $${currentTier?.upper?.toLocaleString("en-us", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })} (${currentPercent}%)`}
                      >
                        <ConfigProvider
                          theme={{
                            components: {
                              Progress: {
                                defaultColor: "#19bdc2",
                              },
                            },
                          }}
                        >
                          <Progress
                            percent={currentPercent}
                            format={() => ``}
                          />
                        </ConfigProvider>
                      </Tooltip>
                    </Col>
                  </Row>
                </Card.Grid>
                <Card.Grid style={{ width: "50%" }}>
                  <Row>
                    <Col span={18}>Current fee (BTC/USD):</Col>
                    <Col span={6}>
                      <Row justify={"end"}>
                        {currentTier.makerFee}% / {currentTier.takerFee}%
                      </Row>
                    </Col>
                  </Row>
                  <Divider style={{ margin: "0px" }} />
                  <Row>
                    <Col span={18}> Next fee (BTC/USD):</Col>
                    <Col span={6}>
                      <Row justify={"end"}>
                        {nextSpotTier.makerFee}% / {nextSpotTier.takerFee}%
                      </Row>
                    </Col>
                  </Row>
                </Card.Grid>
              </Card>
            </Col>
          </Row>

          <Divider orientation="left" orientationMargin={50}>
            Daily Volume Summary
          </Divider>
          <Row justify={"center"} id={"row-volume-data-table"}>
            <Col
              xxl={{ span: 20 }}
              lg={{ span: 20 }}
              md={{ span: 20 }}
              sm={{ span: 20 }}
            >
              <Row justify={"end"}>
                <Button
                  className="dcl-btn-toggle"
                  style={{ marginRight: "10px" }}
                  onClick={csvExport}
                >
                  <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
                  CSV Export
                </Button>
              </Row>
              <Table
                size="small"
                sticky
                scroll={{ x: 1000 }}
                pagination={{
                  pageSize: 50,
                  showQuickJumper: false,
                  hideOnSinglePage: true,
                }}
                columns={volumeTableColumns}
                dataSource={volumeDataFromDSP}
              />
            </Col>
          </Row>

          <Divider />
          <Row justify={"center"}>
            <Col
              xxl={{ span: 24 }}
              lg={{ span: 24 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
            >
              <Collapse style={{ margin: "5px 10px" }}>
                <CollapsePanel
                  header="Kraken Fee Schedule"
                  key="kraken-fee-schedule"
                >
                  <Row>
                    <Col
                      xxl={{ span: 12 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Card
                        title="Kraken Stablecoin Fee Schedule"
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Table
                          sticky
                          scroll={{ x: 800 }}
                          size="small"
                          pagination={false}
                          columns={feeScheduleColumns}
                          dataSource={stableCoinFeeStructure}
                        />
                      </Card>
                    </Col>
                    <Col
                      xxl={{ span: 12 }}
                      lg={{ span: 24 }}
                      md={{ span: 24 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                    >
                      <Card
                        title="Kraken Spot Fee Schedule"
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        <Table
                          size="small"
                          sticky
                          scroll={{ x: 800 }}
                          pagination={false}
                          columns={feeScheduleColumns}
                          dataSource={spotCoinFeeStructure}
                        />
                      </Card>
                    </Col>
                  </Row>
                </CollapsePanel>
              </Collapse>
            </Col>
          </Row>
        </React.Fragment>
      ) : (
        <Layout
          style={{
            paddingTop: "30vh",
            minHeight: "100vh",
          }}
        >
          <Spin size="large" />
        </Layout>
      )}
    </Layout>
  );
}
