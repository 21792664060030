import { createContext } from "react";

interface UserCapability {
  capability: number;
  value: boolean;
}

interface Client {
  clientId: number;
  clientName: string;
  role: string;
  roleId: number;
}

export interface IUser {
  id: number;
  roleId: number;
  roleName: string;
  email: string;
  fullname: string;
  capabilities: UserCapability[];
  clients?: Client[];
  selectedClient?: Client;
}

export interface UserContextType {
  user: IUser;
  setUser: (user: IUser) => void;
}
export const UserContext = createContext<UserContextType | null>(null);
