import { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
export default function DashboardTreeChart({ data }: any) {
  const [containerWidth, setContainerWidth] = useState<number>(445);

  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!data || data.length === 0) return;
    if (!svgRef.current) return;

    const container = svgRef.current?.parentElement;

    const margin = { top: 5, right: 15, bottom: 5, left: 5 },
      width =
        container?.clientWidth && container?.clientWidth < containerWidth
          ? container?.clientWidth - margin.left - margin.right
          : containerWidth - margin.left - margin.right,
      height = 445 - margin.top - margin.bottom;

    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    var root: any = d3.hierarchy(data).sum(function (d) {
      return d.value;
    }); // Here the size of each leave is given in the 'value' field in input data
    d3.treemap().size([width, height]).padding(0)(root);
    const blocks = svg
      .selectAll("rect")
      .data(root.leaves())
      .enter()
      .append("g")
      .attr("class", "block-group");

    blocks
      .append("rect")
      .attr("x", (d: any) => d.x0)
      .attr("y", (d: any) => d.y0)
      .attr("width", (d: any) => d.x1 - d.x0)
      .attr("height", (d: any) => d.y1 - d.y0)
      .style("stroke", "white")
      .style("fill", "#19bdc2");

    // Add text labels for name
    blocks
      .append("text")
      .attr("x", (d: any) => d.x0 + 5)
      .attr("y", (d: any) => d.y0 + 20)
      .text((d: any) => d.data.name)
      .attr("font-size", "15px")
      .attr("font-weight", "bold")
      .attr("fill", "white");
    // Add text labels for percentage below the name
    blocks
      .append("text")
      .attr("x", (d: any) => d.x0 + 5)
      .attr("y", (d: any) => d.y0 + 40) // Adjust the vertical position
      .text((d: any) => `${((d.data.value / root.value) * 100).toFixed(2)}%`)
      .attr("font-size", "12px")
      .attr("fill", "white");

    // Add text labels for value below the percentage
    blocks
      .append("text")
      .attr("x", (d: any) => d.x0 + 5)
      .attr("y", (d: any) => d.y0 + 60) // Adjust the vertical position
      .text(
        (d: any) =>
          `$${d.data?.value?.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
      )
      .attr("font-size", "12px")
      .attr("fill", "white");

    return () => {
      svg.selectAll("*").remove();
    };
  }, [data, containerWidth]);

  // Handle window resize to update container width
  useEffect(() => {
    let resizeTimer: any;
    const margin = { top: 5, right: 15, bottom: 5, left: 5 };

    const handleResize = () => {
      // Clear the previous timer (if any)
      clearTimeout(resizeTimer);

      // Set a new timer to delay the resizing logic
      resizeTimer = setTimeout(() => {
        const container = svgRef.current?.parentElement;
        if (container) {
          setContainerWidth(container.clientWidth - margin.left - margin.right);
        }
      }, 130); // Adjust the delay time (in milliseconds) as needed
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <svg
      ref={svgRef}
      width={containerWidth}
      className="dashboard-tree-svg"
    ></svg>
  );
}
