import { Button, Card, Col, Row } from "antd";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";

export default function PrcessReset() {
  const displayContext = useContext(DisplayContext);

  const [instantBalanceLastFetched, setInstantBalanceLastFetched] =
    useState<Date>();
  const [fetchInstantBalanceState, setFetchInstantBalanceState] =
    useState<string>("");

  const [instantPriceLastFetched, setInstantPriceLastFetched] =
    useState<Date>();
  const [fetchInstantPriceState, setFetchInstantPriceState] =
    useState<string>("");

  const [transactionLastFetched, setTansactionLastFetched] = useState<Date>();
  const [fetchTransactionState, setFetchTransactionState] =
    useState<string>("");

  const [ebankingLastFetched, setEbankingLastFetched] = useState<Date>();
  const [fetchEbankingState, setFetchEbankingtate] = useState<string>("");

  const [priceCalculationFetched, setPriceCalculationFetched] =
    useState<Date>();
  const [priceCalculationState, setPriceCalculationState] =
    useState<string>("");
  const [lastCallFetched, setLastCallFetched] = useState<boolean>(false);

  const [assetDataLastFetched, setAssetDataLastFetched] = useState<Date>();
  const [assetState, setAssetState] = useState<string>();

  const [telegramDate, setTelegramDate] = useState<Date>();
  const [telegramRunningState, setTelegramRunningState] = useState<string>("");

  const getAssetInfo = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/dashboard",
      withCredentials: true,
    }).then((res) => {
      setAssetDataLastFetched(new Date(res.data.lastDate));
      setAssetState(res.data.assetState);
    });
  }, []);

  const getTelegramBackupInfo = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/telegram-backup-result",
      withCredentials: true,
    }).then((res) => {
      setTelegramDate(new Date(res.data.createdAt));
      setTelegramRunningState(res.data.runningState);
    });
  }, []);

  const gridStyleLeft: React.CSSProperties = {
    width: "30%",
    textAlign: "left",
  };
  const gridStyleRight: React.CSSProperties = {
    width: "40%",
    textAlign: "left",
  };

  const processReset = (processName: string) => {
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/process-reset",
      withCredentials: true,
      data: { processName: processName },
    })
      .then(() => {
        toast.success("Process Reset Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        fetchLastCallData();
      });
  };

  const fetchLastCallData = useCallback(() => {
    setLastCallFetched(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/lastCalls",
      withCredentials: true,
    })
      .then((res) => {
        setInstantBalanceLastFetched(new Date(res.data.instantBalance));
        setInstantPriceLastFetched(new Date(res.data.instantPrice));
        setTansactionLastFetched(new Date(res.data.transaction));
        setEbankingLastFetched(new Date(res.data.ebanking));
        setPriceCalculationFetched(new Date(res.data.priceCalculation));
        setFetchInstantBalanceState(res.data.instantBalanceState);
        setFetchInstantPriceState(res.data.instantPriceState);
        setFetchEbankingtate(res.data.ebankingState);
        setFetchTransactionState(res.data.transactionState);
        setPriceCalculationState(res.data.priceCalculationState);
      })
      .finally(() => {
        setLastCallFetched(true);
      });
  }, []);

  useEffect(() => {
    fetchLastCallData();
    getAssetInfo();
    getTelegramBackupInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      title={
        <Row>
          <Col span={8}>Process Reset</Col>
        </Row>
      }
      bordered={false}
      loading={!lastCallFetched}
      style={{
        margin: "10px",
        borderRadius: "10px",
      }}
      id="process-reset"
    >
      <Card.Grid style={gridStyleLeft}>
        {fetchEbankingState ? (
          <i className={`dcl_state ${fetchEbankingState}`}></i>
        ) : (
          <></>
        )}
        <b>Ebanking Ledger:</b>
      </Card.Grid>
      <Card.Grid style={gridStyleRight}>
        {ebankingLastFetched
          ? ebankingLastFetched.toLocaleString("en-US", {
              timeZone: displayContext?.displayContext.timezone,
              timeZoneName: "short",
            })
          : "Pending..."}
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        <Button
          disabled={fetchEbankingState !== "state_red"}
          onClick={() => processReset("ebanking-ledger")}
        >
          Reset
        </Button>
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        {fetchInstantBalanceState ? (
          <i className={`dcl_state ${fetchInstantBalanceState}`}></i>
        ) : (
          <></>
        )}
        <b>Venue Instant Balance:</b>
      </Card.Grid>
      <Card.Grid style={gridStyleRight}>
        {instantBalanceLastFetched
          ? instantBalanceLastFetched.toLocaleString("en-US", {
              timeZone: displayContext?.displayContext.timezone,
              timeZoneName: "short",
            })
          : "Pending..."}
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        <Button
          disabled={fetchInstantBalanceState !== "state_red"}
          onClick={() => processReset("venue-balance")}
        >
          Reset
        </Button>
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        {fetchInstantPriceState ? (
          <i className={`dcl_state ${fetchInstantPriceState}`}></i>
        ) : (
          <></>
        )}
        <b>Venue Instant Price:</b>
      </Card.Grid>
      <Card.Grid style={gridStyleRight}>
        {instantPriceLastFetched
          ? instantPriceLastFetched.toLocaleString("en-US", {
              timeZone: displayContext?.displayContext.timezone,
              timeZoneName: "short",
            })
          : "Pending..."}
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        <Button
          disabled={fetchInstantPriceState !== "state_red"}
          onClick={() => processReset("venue-price")}
        >
          Reset
        </Button>
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        {fetchTransactionState ? (
          <i className={`dcl_state ${fetchTransactionState}`}></i>
        ) : (
          <></>
        )}
        <b>Venue Transaction:</b>
      </Card.Grid>
      <Card.Grid style={gridStyleRight}>
        {transactionLastFetched
          ? transactionLastFetched.toLocaleString("en-US", {
              timeZone: displayContext?.displayContext.timezone,
              timeZoneName: "short",
            })
          : "Pending..."}
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        <Button
          disabled={fetchTransactionState !== "state_red"}
          onClick={() => processReset("venue-transaction")}
        >
          Reset
        </Button>
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        {assetState ? <i className={`dcl_state ${assetState}`}></i> : <></>}
        <b>Asset Prices:</b>
      </Card.Grid>
      <Card.Grid style={gridStyleRight}>
        {assetDataLastFetched
          ? assetDataLastFetched.toLocaleString("en-US", {
              timeZone: displayContext?.displayContext.timezone,
              timeZoneName: "short",
            })
          : "Pending..."}
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        {/* <Button disabled={assetState !== 'state_red'}>Reset</Button> */}
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        {telegramRunningState ? (
          <i className={`dcl_state ${telegramRunningState}`}></i>
        ) : (
          <></>
        )}
        <b>Telegram Groups:</b>
      </Card.Grid>
      <Card.Grid style={gridStyleRight}>
        {telegramDate
          ? telegramDate.toLocaleString("en-US", {
              timeZone: displayContext?.displayContext.timezone,
              timeZoneName: "short",
            })
          : "Pending..."}
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        {/* <Button disabled={telegramRunningState !== 'state_red'}>Reset</Button> */}
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        {priceCalculationState ? (
          <i className={`dcl_state ${priceCalculationState}`}></i>
        ) : (
          <></>
        )}
        <b>Market Trade Price Calc:</b>
      </Card.Grid>
      <Card.Grid style={gridStyleRight}>
        {priceCalculationFetched
          ? priceCalculationFetched.toLocaleString("en-US", {
              timeZone: displayContext?.displayContext.timezone,
              timeZoneName: "short",
            })
          : "Pending..."}
      </Card.Grid>
      <Card.Grid style={gridStyleLeft}>
        {/* <Button disabled={fetchEbankingState !== 'state_red'}>Reset</Button> */}
      </Card.Grid>
    </Card>
  );
}
