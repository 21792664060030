import { Alert, Button, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback } from "react";
import { toast } from "react-toastify";

export default function DeleteOperationModal({
  operationId,
  fetchOperations,
  operationItemCount,
  setSelectedOperationId,
  setShowDeleteModal,
  setOperationItemCount,
}: any) {
  const confirmDelete = useCallback(() => {
    axios({
      method: "Delete",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/operations/",
      withCredentials: true,
      data: {
        operationId,
        operationItemCount,
      },
    })
      .then(() => {
        toast.success("Successfully Deleted!");
      })
      .catch(() => {
        toast.error("Failed to Delete");
      })
      .finally(() => {
        setShowDeleteModal(false);
        setOperationItemCount(0);
        setSelectedOperationId(undefined);
        fetchOperations();
      });
  }, [
    fetchOperations,
    operationId,
    setShowDeleteModal,
    operationItemCount,
    setSelectedOperationId,
    setOperationItemCount,
  ]);
  return (
    <Content id="delete-operation-confirm">
      <Alert
        style={{
          marginTop: "30px",
        }}
        message={
          <React.Fragment>
            <p style={{ color: "black" }}>
              Are you sure you want to delete operation?
            </p>
            <p style={{ color: "black" }}>
              Deleting this operation will delete all items linked to it.
            </p>
          </React.Fragment>
        }
        type="warning"
        showIcon
      />
      <Row justify="end">
        <Button
          onClick={() => {
            setShowDeleteModal(false);
            setSelectedOperationId(undefined);
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => {
            confirmDelete();
          }}
        >
          Confirm
        </Button>
      </Row>
    </Content>
  );
}
