import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row } from "antd";
import axios from "axios";
import React, { useContext, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../../assets/DLC_Logo_RVB-white-400.png";
import "../../../assets/scss/login-trouble.scss";
import InputComponent from "../../Input";
import OTPInput from "../../OTPInput/OTPInput";
// import SmsVerification from "../../SMSVerification/SmsVerification";
import TwofaActivationContainer from "../../TwoFA/TwoFA-container";
import { UserContext } from "../../Context/userContext";

function LoginTroubleContainer() {
  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const context = useContext(UserContext);

  const loadDate = new Date();
  const location = useLocation();
  const navigate = useNavigate();

  const [is2FaModalVisible, setIs2FaModalVisible] = useState<boolean>(false);
  const [isPwdModalVisible, setIsPwdModalVisible] = useState<boolean>(false);
  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);
  // const [isForgotUsername, setIsForgotUsername] = useState<boolean>(false);
  // const [isSmsVerifyVisible, setIsSmsVerifyVisible] = useState<boolean>(false);
  const [is2FaQRCodeVisible, setIs2FaQRCodeVisible] = useState<boolean>(false);
  const [isReset2FA, setIsReset2FA] = useState<boolean>(false);
  const [isResetPwdValidEmail, setResetPwdValidEmail] =
    useState<boolean>(false);
  const [twoFA, setTwoFA] = useState<string>("");
  // const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [captcha, setCaptcha] = useState<string>("");
  // const [clientAccountNumber, setClientAccountNumber] = useState<string>("");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [openUserNotSetUpModal, setOpenUserNotSetUpModal] =
    useState<boolean>(false);
  const [isAsk2FaResetModalOpen, setIsAsk2FaResetModalOpen] =
    useState<boolean>(false);

  const onClickResetPassword = () => {
    setIsPwdModalVisible(true);
    setIsResetPassword(true);
  };
  // const onClickForgotUsername = () => {
  //   setIsPwdModalVisible(true);
  //   setIsForgotUsername(true);
  //   console.log("Forgot Username");
  // };

  // // Removed 2FA set
  // const onClickReset2FA = () => {
  //   setIsPwdModalVisible(true);
  //   setIsReset2FA(true);
  // };
  const handle2FaResetModalCancel = () => {
    setIsAsk2FaResetModalOpen(false);
  };
  const handle2FaResetModalOk = () => {
    setIsAsk2FaResetModalOpen(false);
  };
  const show2FaResetModal = () => {
    setIsAsk2FaResetModalOpen(true);
  };

  const handleReset2FaModalOk = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/2fa/authenticate",
      data: {
        email: email,
        twoFactorAuthenticationCode: twoFA,
        isLogin: false,
      },
    })
      .then((response) => {
        //console.log("Successs");
        const userJSON = response.data;
        if (
          userJSON["isActive"] &&
          !userJSON["isTempPwd"] &&
          userJSON["is2FaEnabled"]
        ) {
          context?.setUser({
            id: userJSON["id"],
            email: userJSON["email"],
            roleId: userJSON["roleId"],
            capabilities: userJSON["userCapabilities"],
            fullname: userJSON["fullname"],
            selectedClient: userJSON["clients"][0],
            roleName: userJSON["roleName"],
          });
          setIsUserLoggedIn(true);
          setIs2FaModalVisible(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("2FA Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handle2FaModalResetPasswordOk = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/2fa/reset",
      data: {
        email: email,
        twoFactorAuthenticationCode: twoFA,
        isLogin: false,
      },
    })
      .then((res) => {
        toast.success("Tempory password has been sent to you email", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setIs2FaModalVisible(false);
      })
      .catch((error) => {
        toast.error("2FA Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const handle2FaModalCancel = () => {
    setIs2FaModalVisible(false);
  };

  let pwdModal;
  // This Modal rednered when user have trouble with login.
  if (isPwdModalVisible) {
    if (isResetPassword) {
      /** The Modal that allow user to reset password
       * 1. We would ask for the email address
       * 2. Then, ask user to pass the 2FA from his/her assoiciated device
       * 3. Password reset
       * */
      pwdModal = (
        <Modal
          open={isPwdModalVisible}
          onOk={() => {
            axios({
              method: "Get",
              url: process.env.REACT_APP_AWS_BACKEND_URL + "/user/sms2FaStatus",
              params: {
                email: email,
              },
            })
              .then((res) => {
                if (res.data === true) {
                  setIsPwdModalVisible(false);
                  setIsResetPassword(false);
                  setIs2FaModalVisible(true);
                  setResetPwdValidEmail(false);
                } else {
                  setIsPwdModalVisible(false);
                  setResetPwdValidEmail(false);
                  setIsResetPassword(false);
                  setOpenUserNotSetUpModal(true);
                }
              })
              .catch(() => {
                toast.error("Failed to find user with this email", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }}
          onCancel={() => {
            setIsPwdModalVisible(false);
            setIsResetPassword(false);
            setResetPwdValidEmail(false);
          }}
          okButtonProps={{ disabled: !isResetPwdValidEmail }}
        >
          {/* <Row>
            <InputComponent
              prefix={<UserOutlined style={{ color: "#155082" }} />}
              placeholder="Username"
              onChange={(e: any) => setUsername(e.target.value)}
              className="primary-input"
              autoFocus
            />
          </Row> */}
          <Row>
            <InputComponent
              prefix={<MailOutlined style={{ color: "#155082" }} />}
              type="email"
              placeholder="Email"
              onChange={(e: any) => {
                if (e.nativeEvent.inputType === "insertFromPaste") {
                  setEmail(e.target.value.trim());
                } else {
                  setEmail(e.target.value);
                }
                setResetPwdValidEmail(isValidEmail(e.target.value));
              }}
              className="primary-input"
            />
          </Row>
        </Modal>
      );
    } // else if (isForgotUsername) {
    //   /** The Modal that allow user to find his/her username back
    //    * 1. We would Ask for email and client account number
    //    * 2. Then, ask user to pass the 2FA from his/her assoiciated device
    //    * 3. Send username through email
    //    * */
    //   pwdModal = (
    //     <Modal
    //       visible={isPwdModalVisible}
    //       onCancel={() => {
    //         setIsPwdModalVisible(false);
    //         setIsForgotUsername(false);
    //       }}
    //       onOk={() => {
    //         axios({
    //           method: "PUT",
    //           url:
    //             process.env.REACT_APP_AWS_BACKEND_URL + "/user/forgotUsername", // Replace with ECS Address after BE deployed to AWS
    //           data: {
    //             email: email,
    //             clientAccountNumber: clientAccountNumber,
    //           },
    //         })
    //           .then((res) => {
    //             setUsername(res.data.username);
    //             setIsPwdModalVisible(false);
    //             setIs2FaModalVisible(true);
    //           })
    //           .catch(() => {
    //             toast.error("User not found", {
    //               position: "top-right",
    //               autoClose: 5000,
    //               hideProgressBar: true,
    //               closeOnClick: true,
    //               pauseOnHover: true,
    //               draggable: true,
    //               progress: undefined,
    //             });
    //           });
    //       }}
    //     >
    //       <Row>
    //         <InputComponent
    //           prefix={<MailOutlined style={{ color: "#155082" }} />}
    //           type="email"
    //           placeholder="Email"
    //           onChange={(e: any) => setEmail(e.target.value)}
    //           className="primary-input"
    //         />
    //       </Row>
    //       <Row>
    //         <InputComponent
    //           prefix={<UserOutlined style={{ color: "#155082" }} />}
    //           placeholder="Client Account Number"
    //           onChange={(e: any) => setClientAccountNumber(e.target.value)}
    //           className="primary-input"
    //           autoFocus
    //         />
    //       </Row>
    //     </Modal>
    //   );
    // }
    else if (isReset2FA) {
      /** The Modal that allow user to reset 2FA if the user changed his/her device
       * 1. We would ask for email and password
       * 2. Send a SMS OTP to user's reserved number
       * 3. Reset 2FA
       * */
      pwdModal = (
        <Modal
          open={isPwdModalVisible}
          onOk={() => {
            const currentDate = new Date();
            axios({
              method: "POST",
              url:
                process.env.REACT_APP_AWS_BACKEND_URL +
                "/authentication/log-in",
              data: {
                email: email,
                password: password,
                captcha: captcha,
                loadDate: loadDate.getTime(),
                clickDate: currentDate.getTime(),
              },
              withCredentials: true,
            })
              .then((res) => {
                setIsReset2FA(false);
                setIsPwdModalVisible(false);
                // setIsSmsVerifyVisible(true);
                // axios({
                //   method: "POST",
                //   url:
                //     process.env.REACT_APP_AWS_BACKEND_URL + "/user/sendSmsOtp",
                //   data: { email: email },
                // }).catch((e) => console.log(e));
              })
              .catch((e) => {
                toast.error("Failed to login", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              });
          }}
          onCancel={() => {
            setIsPwdModalVisible(false);
            setIsReset2FA(false);
            setResetPwdValidEmail(false);
          }}
          okButtonProps={{ disabled: !isResetPwdValidEmail }}
        >
          <Row>
            <InputComponent
              prefix={<MailOutlined style={{ color: "#155082" }} />}
              placeholder="email"
              onChange={(e: any) => {
                if (e.nativeEvent.inputType === "insertFromPaste") {
                  setEmail(e.target.value.trim());
                } else {
                  setEmail(e.target.value);
                }
                setResetPwdValidEmail(isValidEmail(e.target.value));
              }}
              className="primary-input"
              autoFocus
            />
          </Row>
          <Row>
            <InputComponent
              prefix={<LockOutlined style={{ color: "#155082" }} />}
              type="password"
              placeholder="Password"
              onChange={(e: any) => {
                if (e.nativeEvent.inputType === "insertFromPaste") {
                  setPassword(e.target.value.trim());
                } else {
                  setPassword(e.target.value);
                }
              }}
              className="primary-input"
            />
          </Row>
          <Row className="captcha">
            <label htmlFor="captcha">3 + 4</label>
            <input
              id="captcha"
              onChange={(e: any) => setCaptcha(e.target.value)}
            />
          </Row>
        </Modal>
      );
    }
    // else if (isSmsVerifyVisible) {
    //   // Modal that allow user to enter the SMS OTP and finish the SMS OTP authorization
    //   pwdModal = (
    //     <Modal
    //       open={isPwdModalVisible}
    //       onCancel={() => {
    //         setIsSmsVerifyVisible(false);
    //         setIsPwdModalVisible(false);
    //       }}
    //       footer={null}
    //     >
    //       <SmsVerification
    //         email={email}
    //         changeSmsVerificationStatus={(isSmsVerified: boolean) => {
    //           setIs2FaQRCodeVisible(true);
    //           setIsSmsVerifyVisible(false);
    //         }}
    //       />
    //     </Modal>
    //   );
    // }
    else if (is2FaQRCodeVisible) {
      // Modal that allow user to reactiveate 2FA
      pwdModal = (
        <Modal
          open={isPwdModalVisible}
          onCancel={() => {
            setIs2FaQRCodeVisible(false);
            setIsPwdModalVisible(false);
          }}
          footer={null}
          styles={{
            body: { backgroundColor: "#0e3654", minHeight: "200px" },
          }}
        >
          <TwofaActivationContainer
            email={email}
            changeIs2FaActivated={(is2FaEnabled: boolean) => {
              setIs2FaQRCodeVisible(false);
              setIs2FaModalVisible(is2FaEnabled);
              setIsReset2FA(true);
              setIsPwdModalVisible(false);
            }}
          />
        </Modal>
      );
    }
  }

  // The Modal used to do the 2FA authentication
  if (is2FaModalVisible) {
    if (isReset2FA) {
      pwdModal = (
        <Modal
          open={is2FaModalVisible}
          onOk={handleReset2FaModalOk}
          onCancel={handle2FaModalCancel}
          styles={{ body: { backgroundColor: "#0e3654", minHeight: "200px" } }}
        >
          <h2
            style={{
              textAlign: "center",
              color: "white",
              paddingBottom: "20px",
            }}
          >
            Please enter the 2FA code
          </h2>
          <OTPInput
            autoFocus
            length={6}
            isNumberInput
            className="otpContainer"
            inputClassName="otpInput"
            onEnterKeyDown={() => {
              handleReset2FaModalOk();
            }}
            onChangeOTP={(otp) => {
              setTwoFA(otp);
              // console.log(twoFA);
              // console.log("String OTP: ", otp);
            }}
          />
        </Modal>
      );
      // }  else if (isForgotUsername) {
      //   pwdModal = (
      //     <Modal
      //       visible={is2FaModalVisible}
      //       onOk={() => {
      //         axios({
      //           method: "POST",
      //           url: process.env.REACT_APP_AWS_BACKEND_URL + "/2fa/authenticate",
      //           data: {
      //             username: username,
      //             twoFactorAuthenticationCode: twoFA,
      //           },
      //         })
      //           .then(() => {
      //             axios({
      //               method: "POST",
      //               url:
      //                 process.env.REACT_APP_AWS_BACKEND_URL +
      //                 "/user/sendUsername",
      //               data: {
      //                 username: username,
      //                 email: email,
      //               },
      //             });
      //             toast.success("Username has been sent to your email", {
      //               position: "top-right",
      //               autoClose: 5000,
      //               hideProgressBar: true,
      //               closeOnClick: true,
      //               pauseOnHover: true,
      //               draggable: true,
      //               progress: undefined,
      //             });
      //             setIs2FaModalVisible(false);
      //             setIsForgotUsername(false);
      //             navigate("/login");
      //           })
      //           .catch(() => {
      //             toast.error("2FA Failed", {
      //               position: "top-right",
      //               autoClose: 5000,
      //               hideProgressBar: true,
      //               closeOnClick: true,
      //               pauseOnHover: true,
      //               draggable: true,
      //               progress: undefined,
      //             });
      //           });
      //       }}
      //       onCancel={handle2FaModalCancel}
      //     >
      //       <h2
      //         style={{
      //           textAlign: "center",
      //           color: "white",
      //           paddingBottom: "20px",
      //         }}
      //       >
      //         Please enter the 2FA code
      //       </h2>
      //       <OTPInput
      //         autoFocus
      //         length={6}
      //         className="otpContainer"
      //         inputClassName="otpInput"
      //         onChangeOTP={(otp) => {
      //           setTwoFA(otp);
      //         }}
      //       />
      //     </Modal>
      //   );
    } else {
      pwdModal = (
        <Modal
          open={is2FaModalVisible}
          onOk={handle2FaModalResetPasswordOk}
          onCancel={handle2FaModalCancel}
          styles={{ body: { backgroundColor: "#0e3654", minHeight: "200px" } }}
        >
          <h2
            style={{
              textAlign: "center",
              color: "white",
              paddingBottom: "20px",
            }}
          >
            Please enter the 2FA code
          </h2>
          <OTPInput
            autoFocus
            length={6}
            className="otpContainer"
            inputClassName="otpInput"
            onEnterKeyDown={() => {
              handle2FaModalResetPasswordOk();
            }}
            onChangeOTP={(otp) => {
              setTwoFA(otp);
            }}
          />
        </Modal>
      );
    }
  }

  if (isUserLoggedIn)
    return (
      <Navigate to="/client/dashboard" replace state={{ from: location }} />
    );

  return (
    <React.Fragment>
      <div className="login-trouble-component">
        <Row justify="center" align="middle">
          <Col span={24} xs={22} lg={12} sm={12} xl={6}>
            <div className="login-trouble-container">
              <Row justify="center">
                <img
                  src={logo}
                  alt="Delchain Logo"
                  style={{ maxWidth: "250px" }}
                />
              </Row>
              <Row justify="center">
                <h3>Need Help with Sign in?</h3>
              </Row>
              <Row justify="center">
                <Button
                  className="primary-button"
                  style={{ width: "10em" }}
                  onClick={onClickResetPassword}
                >
                  Reset Password
                </Button>
              </Row>
              {/* <Row justify="center">
                <button
                  style={{ width: "10em" }}
                  onClick={onClickForgotUsername}
                >
                  Forgot Username
                </button>
              </Row> */}
              <Row justify="center">
                <Button
                  className="primary-button"
                  style={{ width: "10em" }}
                  onClick={show2FaResetModal}
                >
                  Ask for 2FA Reset
                </Button>
              </Row>
              <Row justify="center">
                <Button
                  className="primary-button"
                  style={{ width: "10em" }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  {" "}
                  Back to Login{" "}
                </Button>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        open={isAsk2FaResetModalOpen}
        onOk={handle2FaResetModalOk}
        onCancel={handle2FaResetModalCancel}
      >
        <p>
          Please contact your account manager either by email or on your
          dedicated Telegram channel for a 2FA reset
        </p>
      </Modal>
      <Modal
        open={openUserNotSetUpModal}
        onCancel={() => {
          setOpenUserNotSetUpModal(false);
        }}
        onOk={() => {
          setOpenUserNotSetUpModal(false);
        }}
      >
        <p>Your account has not been set up yet:</p>
        <p>
          If you have not received or have lost your temporary password, please
          contact your account manager either by email or on your dedicated
          Telegram channel in order for them you resend you a new temporary
          password.
        </p>
      </Modal>
      {pwdModal}
    </React.Fragment>
  );
}

export default LoginTroubleContainer;
