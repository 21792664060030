import {
  Button,
  Col,
  Form,
  Layout,
  Modal,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SortableTree } from "./SortableTree/SortableTree";

function MenuSettings() {
  const navigate = useNavigate();
  const [filterForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<any>();
  const [menuSettings, setMenuSettings] = useState<any[]>([]);
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(true);
  const [deletedRows, setDeletedRows] = useState<any[]>([]);
  const [menuOptions, setMenuOptions] = useState<any>();
  const [capOptions, setCapOptions] = useState<any>();
  const [selectedMenu, setSelectedMenu] = useState<any>();

  const openDeleteConfirmModal = (id: any) => {
    setIsModalOpen(true);
    setSelectedRowId(id);
  };

  const findObjectById = (data: any[], id: string | number) => {
    // First, search in the parent array
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        return data[i]; // Found in parent
      }

      // If not found, recursively search in children
      if (data[i].children && data[i].children.length > 0) {
        const childResult: any = findObjectById(data[i].children, id);
        if (childResult) {
          return childResult; // Found in children
        }
      }
    }

    // Object not found
    return null;
  };

  const deleteRow = (id: any) => {
    setOnFinishLoad(false);
    const newMenuSettings = [...menuSettings];
    const childMenuItems = newMenuSettings.filter((row) => row.parentId === id);
    const newDeltedRows = [...deletedRows];

    const rowToDelete = findObjectById(newMenuSettings, id);
    if (!rowToDelete.newRow) {
      newDeltedRows.push(rowToDelete);
    }
    for (const childRow of childMenuItems) {
      if (!childRow.newRow) {
        newDeltedRows.push(childRow);
      }
      newMenuSettings.splice(newMenuSettings.indexOf(childRow), 1);
    }
    if (rowToDelete.parentId) {
      const parentItem = findObjectById(newMenuSettings, rowToDelete.parentId);
      parentItem.children.splice(parentItem.children.indexOf(rowToDelete), 1);
      // console.log(newMenuSettings.indexOf(parentItem));
    } else {
      newMenuSettings.splice(newMenuSettings.indexOf(rowToDelete), 1);
    }
    setDeletedRows(newDeltedRows);
    setMenuSettings(newMenuSettings);
    setOnFinishLoad(true);
  };

  const updatePropertyById = (
    data: any[],
    id: number | string,
    propertyKey: string,
    propertyValue: any,
  ) => {
    // Clone the data to avoid modifying the original data
    const updatedData = JSON.parse(JSON.stringify(data));

    // Define a recursive function to update the property
    function recursiveUpdate(menu: any[]): any[] {
      return menu.map((menuItem) => {
        if (menuItem.id === id) {
          menuItem.edited = true;
          menuItem[propertyKey] = propertyValue;
        }
        if (menuItem.children.length > 0) {
          menuItem.children = recursiveUpdate(menuItem.children);
        }
        return menuItem;
      });
    }

    // Call the recursive function to update the property
    return recursiveUpdate(updatedData);
  };

  const updateMenuFields = (
    id: number | string,
    fieldName: string,
    fieldValue: string,
  ) => {
    const updatedData = updatePropertyById(
      menuSettings,
      id,
      fieldName,
      fieldValue,
    );
    setMenuSettings(updatedData);
  };

  const flattenMenu = useCallback((menuItems: any[], parentId = null) => {
    const flattenedMenu: any[] = [];

    menuItems.forEach((item) => {
      const flattenedItem = { ...item, parentId };

      if (item.children && item.children.length > 0) {
        const children = flattenMenu(item.children, item.id);
        flattenedMenu.push(flattenedItem, ...children);
      } else {
        flattenedMenu.push(flattenedItem);
      }
    });

    return flattenedMenu;
  }, []);

  const submit = useCallback(() => {
    let index = 1;
    const flattenedMenuItems = flattenMenu(menuSettings);
    flattenedMenuItems.forEach((t) => {
      if (t.sort !== index) {
        t.sort = index;
        t.edited = true;
      }
      index += 1;
    });

    const editedRows = flattenedMenuItems.filter(
      (e) => e.edited === true && !e.newRow,
    );
    const newRows = flattenedMenuItems.filter((e) => e.newRow);
    setOnFinishLoad(false);
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/menu-settings",
      withCredentials: true,
      data: {
        editedRows: editedRows,
        newRows: newRows,
        deletedRows: deletedRows,
      },
    })
      .then(() => {
        window.sessionStorage.setItem("afterSubmitSuccess", "true");
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        toast.warning(
          "Failed to submit data. Please check the fields modified.",
        );
        fetchData(selectedMenu);
      });
  }, [menuSettings, deletedRows, flattenMenu, navigate, selectedMenu]);

  const insertNewRow = () => {
    setOnFinishLoad(false);
    const newEmailSettings = [...menuSettings];
    const newRow = {
      id: `temp_${newEmailSettings.filter((row) => row.newRow).length + 1}`,
      key: `New key ${newEmailSettings.length + 1}`,
      menuId: selectedMenu,
      label: "",
      parentId: undefined,
      link: "",
      iconName: "",
      sort: "",
      capabilityId: "",
      pageTitle: "",
      helper: "",
      isActive: false,
      newRow: true,
      showDetail: true,
      children: [],
    };
    newEmailSettings.push(newRow);
    setMenuSettings(newEmailSettings);
    setOnFinishLoad(true);
  };

  const fetchMenuOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/menus",
      withCredentials: true,
    }).then((res) => {
      setMenuOptions(res.data.result);
    });
  }, []);

  const fetchCapabilityOptions = useCallback(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/capability",
      withCredentials: true,
    }).then((res) => {
      const capList = res.data.map((e: any) => ({
        label: e.name,
        key: e.id,
        value: e.id,
      }));
      setCapOptions(capList);
    });
  }, []);

  const fetchData = (menuId: number) => {
    setOnFinishLoad(false);
    setDeletedRows([]);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/menu-settings",
      params: {
        menuId: menuId,
      },
      withCredentials: true,
    })
      .then((res) => {
        const itemMap = new Map();

        res.data.forEach((e: any) => {
          e.key = e.id;
          e.edited = false;
          e.children = [];
          itemMap.set(e.id, e);
        });
        const result: any[] = [];
        res.data.forEach((item: any) => {
          if (item.parentId) {
            const parentItem = itemMap.get(item.parentId);
            if (parentItem) {
              parentItem.children.push(item);
            }
          } else {
            result.push(item);
          }
        });
        setMenuSettings(result);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };

  const onMenuSelected = useCallback((e: number) => {
    setSelectedMenu(e);
    fetchData(e);
  }, []);
  useEffect(() => {
    fetchMenuOptions();
    fetchCapabilityOptions();
  }, [fetchMenuOptions, fetchCapabilityOptions]);

  useEffect(() => {
    const sessionMenu = Number(window.sessionStorage.getItem("selectedMenu"));
    if (sessionMenu) onMenuSelected(sessionMenu);
  }, [onMenuSelected]);

  useEffect(() => {
    if (selectedMenu)
      window.sessionStorage.setItem("selectedMenu", selectedMenu);
  }, [selectedMenu]);
  useEffect(() => {
    const afterSubmitSuccess = JSON.parse(
      window.sessionStorage.getItem("afterSubmitSuccess") as string,
    );
    if (afterSubmitSuccess === true) {
      toast.success("Menu settings updated successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.sessionStorage.setItem("afterSubmitSuccess", "false");
    }
  }, []);
  return onFinshiLoad ? (
    <Content id="menu-settings">
      <Row>
        <Col md={12} sm={24} lg={12}>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={(e) => {
              console.log(e);
            }}
            className="dcl-toggled-content dcl-toggled-content-filter"
          >
            <Row className="dcl-filter-item">
              <Select
                placeholder="Select the menu"
                className="dcl-venue-select"
                options={menuOptions}
                // defaultValue={selectedMenu}
                showSearch
                value={selectedMenu}
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                onChange={(e) => {
                  onMenuSelected(e);
                }}
              />
            </Row>
          </Form>
        </Col>
      </Row>
      {onFinshiLoad ? (
        <SortableTree
          collapsible
          indicator
          removable
          defaultItems={menuSettings}
          updateMenuFields={updateMenuFields}
          capablityList={capOptions}
          setMenuSettings={setMenuSettings}
          openDeleteConfirmModal={openDeleteConfirmModal}
        />
      ) : (
        <></>
      )}

      {selectedMenu ? (
        <Row justify="center">
          <Space>
            <Button
              type="primary"
              onClick={() => {
                insertNewRow();
              }}
            >
              New
            </Button>
            <Button
              onClick={() => {
                fetchData(selectedMenu);
              }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              onClick={() => {
                submit();
              }}
            >
              Save
            </Button>
          </Space>
        </Row>
      ) : (
        <></>
      )}

      <Modal
        getContainer={false}
        open={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onOk={() => {
          deleteRow(selectedRowId);
          setIsModalOpen(false);
        }}
      >
        <p>
          Are you sure you want to delete the row {selectedRowId}? The
          associated rows would be delted as well.
        </p>
        <p>
          Click the Ok button and then click the Save button at the end of the
          page to save the updates.
        </p>
      </Modal>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}

export default MenuSettings;
