import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Layout, Row, Spin, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function ViewFeePolicyList() {
  const navigate = useNavigate();
  const [feePolicyList, setFeePolicyList] = useState<any[]>([]);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [canAssignDefaultFeePolicy, setCanAssignDefaultFeePolicy] =
    useState<boolean>(false);
  const [onFinishLoaded, setOnFinishLoaded] = useState<boolean>(false);
  const feePolicyColumns = [
    { title: "Name", dataIndex: "name" },
    { title: "Fee Type", dataIndex: "feePolicyTypeName" },
    {
      title: "Edit",
      dataIndex: "edit",
      render: (_: any, { id }: any) => (
        <Link
          to={`/bo/fee/update-policy/${id}`}
          state={{ from: window.location.pathname }}
        >
          <Button disabled={!canEdit}>Edit</Button>
        </Link>
      ),
    },
  ];
  const fetchFeePolicyList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      withCredentials: true,
    })
      .then((res) => {
        setFeePolicyList(res.data.feePolicyList);
        setCanEdit(res.data.canEdit);
        setCanAssignDefaultFeePolicy(res.data.canAssignDefaultFeePolicy);
      })
      .finally(() => {
        setOnFinishLoaded(true);
      });
  }, []);

  useEffect(() => {
    fetchFeePolicyList();
  }, [fetchFeePolicyList]);

  return onFinishLoaded ? (
    <Content id="view-fee-policy-list">
      <Row justify={"end"}>
        <Col md={{ span: 8 }} sm={{ span: 8 }}>
          <Row justify="end">
            <Button
              className="dcl-btn-toggle"
              disabled={!canAssignDefaultFeePolicy}
              onClick={() => {
                navigate("/bo/fee/default-policies", {
                  state: { from: window.location.pathname },
                });
              }}
            >
              Default Fee Policies
            </Button>
            <Button
              className="dcl-btn-toggle"
              disabled={!canEdit}
              onClick={() => {
                navigate("/bo/fee/create-policy", {
                  state: { from: window.location.pathname },
                });
              }}
              type="primary"
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-plus" as IconProp}
                className="white-plus"
              />
              New Fee Policy
            </Button>
          </Row>
        </Col>
      </Row>
      <Table dataSource={feePolicyList} columns={feePolicyColumns} />
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
