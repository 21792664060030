import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../Context/userContext";

function ViewClients({
  filter,
  onFilterChange,
  clientDisplay,
  onDisplayChange,
}: any) {
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const [filterForm] = Form.useForm();
  const [csvForm] = Form.useForm();
  const [countryList, setCountryList] = useState([]);
  const [currPageSize, setCurrPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [clientList, setClientList] = useState<any[]>([]);
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [clientsLength, setClientsLength] = useState(0);
  const [editDisabled, setEditDisabled] = useState<boolean>(true);

  const [showFilter, setShowFilter] = useState<boolean>(filter ? true : false);
  const [showDisplayFilter, setShowDisplayFilter] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [displayColumns, setDisplayColumns] = useState<any[]>([]);
  const [tableSorter, setSorter] = useState<any>();

  const initialColumns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      sorter: true,
      width: "15%",
      hidden: false,
      editable: false,
      render: (text: any, record: any) => (
        <Link
          to={"/bo/client/info/" + encodeURIComponent(record.id)}
          state={{ from: window.location.pathname }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Deltec Account",
      dataIndex: "deltecAccount",
      sorter: true,
      width: "10%",
      hidden: false,
      editable: false,
      render: (_: any, { deltecAccount }: any) => (
        <Space>{deltecAccount}</Space>
      ),
    },
    {
      title: "Delchain Account",
      dataIndex: "delchainAccount",
      sorter: true,
      width: "10%",
      hidden: false,
      editable: false,
      render: (_: any, { delchainAccount }: any) => (
        <Space>{delchainAccount}</Space>
      ),
    },
    {
      title: "Users",
      dataIndex: "users",
      hidden: false,
      width: "10%",
      editable: false,
      render: (_: any, { users }: any) => (
        <React.Fragment>
          {users.map((user: any) => {
            const link = "/bo/user/info/" + encodeURIComponent(user.userId);
            return user ? (
              <Row key={user.userId} style={{ overflow: "hidden" }}>
                <Link to={link} state={{ from: window.location.pathname }}>
                  <Tooltip
                    title={`${user.user.lastName.trim()},${user.user.firstName.trim()}`}
                  >
                    {user.user.lastName.trim()},{user.user.firstName.trim()}
                  </Tooltip>
                </Link>
              </Row>
            ) : (
              <></>
            );
          })}
        </React.Fragment>
      ),
    },
    {
      title: "Deltec RM",
      dataIndex: "rmDspTerm",
      sorter: true,
      hidden: false,
      width: "7%",
      editable: false,
      render: (_: any, { rmDspTerm }: any) =>
        rmDspTerm ? <Space>{rmDspTerm.value}</Space> : <></>,
    },
    {
      title: "Telegram Group",
      dataIndex: "telegramGroup",
      sorter: true,
      width: "10%",
      hidden: false,
      editable: false,
      render: (_: any, { telegramGroup, telegramGroupLink }: any) => (
        <Link
          to=""
          onClick={() => {
            window.open(telegramGroupLink);
          }}
        >
          {telegramGroup}
        </Link>
      ),
    },

    {
      title: "Country",
      dataIndex: "address",
      sorter: true,
      width: "7%",
      hidden: false,
      editable: false,
      render: (_: any, { address }: any) =>
        address ? <Space>{address.country.nicename}</Space> : <></>,
    },
    {
      title: "Client Type",
      dataIndex: "clientType",
      sorter: true,
      hidden: false,
      editable: false,
      width: "7%",
      render: (_: any, { clientType }: any) =>
        clientType ? <Space>{clientType.name}</Space> : <></>,
    },
    {
      title: "Business Activity",
      dataIndex: "businessActivityDspTerm",
      sorter: true,
      hidden: false,
      editable: false,
      width: "7%",
      render: (_: any, { businessActivityDspTerm }: any) =>
        businessActivityDspTerm ? (
          <Space>{businessActivityDspTerm.value}</Space>
        ) : (
          <></>
        ),
    },

    {
      title: "To Backup",
      dataIndex: "toBackup",
      hidden: false,
      editable: false,
      width: "7%",
      render: (_: any, { id, toBackup }: any) => (
        <Space size="small" id={id}>
          <Switch defaultChecked={toBackup} disabled />
        </Space>
      ),
    },
    {
      title: "Bot is in Group",
      dataIndex: "isBot",
      hidden: false,
      editable: false,
      width: "7%",
      render: (_: any, { id, isBot }: any) => (
        <Space size="small" id={id}>
          <Switch defaultChecked={isBot} disabled />
        </Space>
      ),
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      // sorter: true,
      hidden: false,
      width: "7%",
      editable: false,
      render: (_: any, { id, isActive }: any) => (
        <Space size="small" id={id}>
          <Switch defaultChecked={isActive} disabled />
        </Space>
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      hidden: false,
      width: "7%",
      fixed: "right",
      editable: false,
      render: (text: any, record: any) => (
        <Button
          disabled={editDisabled}
          onClick={() => {
            // console.log(record);
            const url = "/bo/client/update/" + encodeURIComponent(record.id);
            navigate(url, {
              state: { from: window.location.pathname },
            });
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const changeColumnDisplay = (dataIndex: string) => {
    columns.find((e) => e.dataIndex === dataIndex).hidden = !columns.find(
      (e) => e.dataIndex === dataIndex,
    ).hidden;
    //setColumns(columns);
    onDisplayChange(columns);
    setDisplayColumns(columns.filter((item) => !item.hidden));
  };

  const handleTableChange = (_: any, __: any, sorter: any) => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client",
      params: {
        offset:
          currentPage === 1 || currentPage === 0
            ? 0
            : (currentPage - 1) * currPageSize,
        pageSize: currPageSize,
        roleId: userContext?.user.roleId,
        clientName: filterForm.getFieldValue("clientName"),
        isActive: filterForm.getFieldValue("isActive"),
        countryId: filterForm.getFieldValue("countryId"),
        deltecAccount: filterForm.getFieldValue("deltecAccount"),
        delchainAccount: filterForm.getFieldValue("delchainAccount"),
        sortField: sorter.field,
        sortOrder: sorter.order,
      },
      withCredentials: true,
    })
      .then((res) => {
        res.data.clients?.forEach((client: any) => {
          client.key = client.id;
        });
        setClientList(res.data.clients);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
    setSorter({ ...sorter });
  };

  const fetchData = useCallback(
    (params = {}) => {
      setOnFinishLoad(false);
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/client",
        params: {
          offset: 0,
          pageSize: 10,
          roleId: userContext?.user.roleId,
          clientName: filterForm.getFieldValue("clientName"),
          isActive: filterForm.getFieldValue("isActive"),
          countryId: filterForm.getFieldValue("countryId"),
          deltecAccount: filterForm.getFieldValue("deltecAccount"),
          delchainAccount: filterForm.getFieldValue("delchainAccount"),
        },
        withCredentials: true,
      })
        .then((res) => {
          //const data = res.data;
          if (res.data.totalClients) {
            setClientsLength(res.data.totalClients);
          }
          const data = res.data.clients;
          for (const row of data) {
            //console.log(row);
            row.key = row.id;
          }
          setEditDisabled(!res.data.canEditClient);
          setClientList(data);

          //console.log(res);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        })
        .finally(() => {
          setOnFinishLoad(true);
        });
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/country",
        withCredentials: true,
      }).then((res) => {
        setCountryList(res.data);
      });

      // axios({
      //   method: "Get",
      //   url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/clientAccount-type",
      //   withCredentials: true,
      // }).then((res) => {
      //   setAccountTypeList(res.data);
      // });
    },
    [userContext?.user.roleId, filterForm, navigate],
  );
  const filterClients = (form: any) => {
    setOnFinishLoad(false);
    onFilterChange(form);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/",
      withCredentials: true,
      params: {
        offset: 0,
        pageSize: currPageSize,
        roleId: userContext?.user.roleId,
        clientName: form.clientName,
        deltecAccount: form.deltecAccount,
        delchainAccount: form.delchainAccount,
        isActive: form.isActive,
        countryId: form.countryId,
      },
    })
      .then((res) => {
        setCurrentPage(0);
        setClientsLength(res.data.totalClients);
        for (const row of res.data.clients) {
          row.key = row.id;
        }
        setClientList(res.data.clients);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };
  const onReset = () => {
    fetchData();
    filterForm.resetFields();
    onFilterChange(undefined);
  };

  const downloadCSV = () => {
    const displayOptions = {
      clientName: csvForm.getFieldValue("clientName"),
      deltecAccount: csvForm.getFieldValue("deltecAccount"),
      delchainAccount: csvForm.getFieldValue("delchainAccount"),
      email: csvForm.getFieldValue("users"),
      telegramGroup: csvForm.getFieldValue("telegramGroup"),
      country: csvForm.getFieldValue("country"),
      clientType: csvForm.getFieldValue("clientType"),
      businessActivity: csvForm.getFieldValue("businessActivity"),
      isActive: csvForm.getFieldValue("isActive"),
    };
    const filterOptions = {
      clientName: filterForm.getFieldValue("clientName"),
      deltecAccount: filterForm.getFieldValue("deltecAccount"),
      delchainAccount: filterForm.getFieldValue("delchainAccount"),
      country: filterForm.getFieldValue("countryId"),
      status: filterForm.getFieldValue("isActive"),
    };

    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/export-csv",
      withCredentials: true,
      data: {
        filter: filterOptions,
        displayOptions: displayOptions,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_clients.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  };

  const onPaginationChange = (current: any, pageSize: any) => {
    //console.log(current, pageSize);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client",
      params: {
        offset: current === 1 || current === 0 ? 0 : (current - 1) * pageSize,
        pageSize: pageSize,
        roleId: userContext?.user.roleId,
        clientName: filterForm.getFieldValue("clientName"),
        isActive: filterForm.getFieldValue("isActive"),
        countryId: filterForm.getFieldValue("countryId"),
        deltecAccount: filterForm.getFieldValue("deltecAccount"),
        delchainAccount: filterForm.getFieldValue("delchainAccount"),
        sortField: tableSorter ? tableSorter.field : undefined,
        sortOrder: tableSorter ? tableSorter.order : undefined,
      },
      withCredentials: true,
    })
      .then((res) => {
        setCurrentPage(current);
        setCurrPageSize(pageSize);
        //console.log(res.data.clients);
        res.data.clients?.forEach((client: any) => {
          client.key = client.id;
        });
        setClientList(res.data.clients);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  };
  useEffect(() => {
    if (filterForm && filter) {
      filterForm.setFieldsValue(filter);
    }
    fetchData();
    if (clientDisplay) {
      setColumns(clientDisplay);
      setDisplayColumns(clientDisplay.filter((item: any) => !item.hidden));
    } else {
      setColumns(initialColumns);
      setDisplayColumns(initialColumns.filter((item) => !item.hidden));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDisabled, filter, filterForm]);
  return onFinshiLoad ? (
    <React.Fragment>
      <Content id="view-clients">
        <Row>
          <Col md={{ span: 16, order: 1 }} sm={{ span: 16, order: 1 }}>
            <Space>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowDisplayFilter(false);
                  setShowFilter(!showFilter);
                }}
              >
                <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
                Filters
                {showFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowFilter(false);
                  setShowDisplayFilter(!showDisplayFilter);
                }}
              >
                Display
                {showDisplayFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
            </Space>
            <Form
              title="Filter"
              form={filterForm}
              onFinish={filterClients}
              hidden={!showFilter}
              className="dcl-toggled-content dcl-toggled-content-filter"
            >
              <Row>
                <Form.Item name="clientName" className="dcl-filter-item">
                  <Input placeholder="Client Name" />
                </Form.Item>
                <Form.Item
                  name="deltecAccount"
                  className="dcl-filter-item"
                  normalize={(value) => value.trim()}
                >
                  <Input placeholder="Deltec Account" />
                </Form.Item>
                <Form.Item
                  name="delchainAccount"
                  className="dcl-filter-item"
                  normalize={(value) => value.trim()}
                >
                  <Input placeholder="Delchain Account" />
                </Form.Item>
                <Form.Item name="isActive" className="dcl-filter-item">
                  <Select
                    placeholder="Status"
                    popupMatchSelectWidth={false}
                    className="dcl-status-select"
                  >
                    <Select.Option key={"true"} value={true}>
                      Active
                    </Select.Option>
                    <Select.Option key={"false"} value={false}>
                      Inactive
                    </Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item name="countryId" className="dcl-filter-item">
                  <Select
                    className="dcl-country-select"
                    placeholder="Country"
                    popupMatchSelectWidth={false}
                    showSearch
                    filterOption={(input, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {countryList?.map((country) => (
                      <Select.Option
                        value={country["id"]}
                        key={country["id"]}
                        id={country["id"]}
                      >
                        {country["nicename"]}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Row>
              <Row justify="end">
                <Space>
                  {clientsLength}
                  <b>Results</b>
                </Space>
                <Space>
                  <Button htmlType="submit" className="ant-btn-primary">
                    Apply
                  </Button>
                  <Button onClick={onReset}>Reset</Button>
                </Space>
              </Row>
            </Form>
            <Form
              className="dcl-toggled-content dcl-toggled-content-csv"
              form={csvForm}
              hidden={!showDisplayFilter}
            >
              <Row>
                <Form.Item
                  name="clientName"
                  label="Client Name"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) => e.dataIndex === "clientName",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("clientName");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="deltecAccount"
                  label="Deltec Account"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) => e.dataIndex === "deltecAccount",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("deltecAccount");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="delchainAccount"
                  label="Delchain Account"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) => e.dataIndex === "delchainAccount",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("delchainAccount");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="users"
                  label="Users"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) => e.dataIndex === "users",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("users");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="telegramGroup"
                  label="Telegram Group"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) => e.dataIndex === "telegramGroup",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("telegramGroup");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="toBackup"
                  label="To Backup"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) => e.dataIndex === "toBackup",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("toBackup");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="isBot"
                  label="Bot in the group"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) => e.dataIndex === "isBot",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("isBot");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="country"
                  label="Country"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) => e.dataIndex === "address",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("address");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="clientType"
                  label="Client Type"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) => e.dataIndex === "clientType",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("clientType");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="businessActivityDspTerm"
                  label="Business Activity"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) =>
                              e.dataIndex === "businessActivityDspTerm",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("businessActivityDspTerm");
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="isActive"
                  label="Is Active"
                  className="dcl-filter-item"
                  valuePropName="checked"
                >
                  <Switch
                    defaultChecked={
                      clientDisplay
                        ? !clientDisplay.find(
                            (e: any) => e.dataIndex === "isActive",
                          ).hidden
                        : true
                    }
                    onClick={() => {
                      changeColumnDisplay("isActive");
                    }}
                  />
                </Form.Item>
              </Row>
              {/* <Row justify="end">
                  <Space>
                    <Button
                      htmlType="submit"
                      className="ant-btn-primary"
                      onClick={() => {
                        console.log(csvForm.getFieldValue("accountNumber"));
                      }}
                    >
                      Save
                    </Button>
                  </Space>
                </Row> */}
            </Form>
          </Col>
          <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
            <Row justify="end">
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={downloadCSV}
              >
                <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
                CSV Export
              </Button>
              <Button
                type="primary"
                className="dcl-btn-toggle"
                disabled={editDisabled}
                onClick={() => {
                  navigate("/bo/client/create");
                }}
              >
                <FontAwesomeIcon
                  icon={"fa-solid fa-plus" as IconProp}
                  className="white-plus"
                />
                New Client
              </Button>
            </Row>
          </Col>
        </Row>
        <Table
          columns={displayColumns}
          dataSource={clientList}
          showSorterTooltip={false}
          onChange={handleTableChange}
          sticky
          pagination={{ pageSize: currPageSize, hideOnSinglePage: true }}
          scroll={{
            x: "1500px",
          }}
        />
        <Row justify="end" className="pagination-row">
          <Pagination
            showSizeChanger
            onChange={onPaginationChange}
            current={currentPage}
            pageSize={currPageSize}
            total={clientsLength}
          />
        </Row>
      </Content>
    </React.Fragment>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}

export default ViewClients;
