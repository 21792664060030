import { Button, Form, Row } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function TelegramBackup() {
  const navigate = useNavigate();
  const [groupComplement, setGroupComplement] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const fetchComplement = useCallback(() => {
    setLoading(true);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/telegram-backup-complement",
      withCredentials: true,
    })
      .then((res) => {
        setLoading(false);
        setGroupComplement(res.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  const updateComplement = useCallback(() => {
    setLoading(true);
    axios({
      method: "Put",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/telegram-backup-complement",
      data: {
        complement: groupComplement,
      },
      withCredentials: true,
    })
      .then(() => {
        setLoading(false);
        toast.success("Updated Successfully!");
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [groupComplement, navigate]);

  useEffect(() => {
    fetchComplement();
  }, [fetchComplement]);
  return (
    <Content id="telegram-backup">
      <Row justify={"center"}>
        <h2 style={{ color: "black" }}>
          Additional Telegram groups to back up
        </h2>
      </Row>
      <Form>
        <Form.Item label={<b>TELEGRAM_BACKUP_GROUP_COMPLEMENT</b>}>
          <TextArea
            disabled={loading}
            value={groupComplement}
            rows={5}
            onChange={(e) => {
              setGroupComplement(e.target.value);
            }}
          />
        </Form.Item>
        <Row justify={"center"}>
          <Button
            loading={loading}
            onClick={() => {
              fetchComplement();
            }}
          >
            Reset
          </Button>
          <Button
            type="primary"
            onClick={() => updateComplement()}
            loading={loading}
          >
            Save
          </Button>
        </Row>
      </Form>
    </Content>
  );
}
