import { Alert, Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function ComplianceWhitelist() {
  const navigate = useNavigate();
  const [selectedClient, setSelectClient] = useState<any>();
  const [walletLabel, setWalletLabel] = useState<string>();
  const [addressInput, setAddressInput] = useState<string>("");
  const [assetNetworkList, setAssetNetworkList] = useState<any>();
  const [selectAssetNetwork, setSelectedAssetNetwork] = useState<any>();
  const [clientOptions, setClientOptions] = useState<any[]>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        if (res.data.clients) {
          res.data.clients.forEach(
            (client: any) =>
              (client.label = `${client.label} (${client.deltecAccount})`),
          );
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  const fetchAssetApiUrlUtils = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/asset-url-utils",
      withCredentials: true,
    }).then((res) => {
      setAssetNetworkList(res.data.networks);
    });
  }, []);

  const fetchSelectOptions = useCallback(() => {
    getClientOptions();
    fetchAssetApiUrlUtils();
  }, [getClientOptions, fetchAssetApiUrlUtils]);

  useEffect(() => {
    fetchSelectOptions();
  }, [fetchSelectOptions]);

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const confirmCreation = useCallback(() => {
    setIsCreating(true);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/risk/wallet",
      data: {
        walletAddress: addressInput,
        clientId: selectedClient,
        assetNetworkId: selectAssetNetwork,
        label: walletLabel,
      },
      withCredentials: true,
    })
      .then((res) => {
        toast.success("Wallet Created and added to client whitelist");
        navigate("/bo/confirmation", {
          state: {
            pageType: "Wallet",
            createOrUpdate: "Create",
            displayName: walletLabel,
            createUrl: "/bo/wallet/create",
            editUrl: "/bo/wallet/update/" + encodeURIComponent(res.data.id),
          },
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsCreating(false);
        setShowConfirmModal(false);
      });
  }, [addressInput, navigate, selectAssetNetwork, selectedClient, walletLabel]);

  return (
    <Content id="compliance-whitelist">
      <Row justify={"center"}>
        <Col span={22}>
          <Alert
            style={{
              marginBottom: "1em",
            }}
            message={
              <React.Fragment>
                <p style={{ color: "black" }}>
                  <b>A new wallet will be created and linked to the client.</b>
                </p>
              </React.Fragment>
            }
            type="warning"
            showIcon
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "2em" }} justify={"space-around"}>
        <Col lg={18} md={22} sm={22} xs={22} xl={8} xxl={8}>
          <Form.Item
            label={<b>Client</b>}
            name="clientId"
            rules={[
              {
                required: true,
                message: "Please select client",
              },
            ]}
          >
            <Select
              options={clientOptions}
              onSelect={(e) => {
                setSelectClient(e);
              }}
              allowClear
              showSearch
              placeholder={"Client"}
              filterOption={(input, option: any) => {
                return option.label.toLowerCase().includes(input.toLowerCase());
              }}
            />
          </Form.Item>
          <Form.Item
            label={<b>Label</b>}
            name="label"
            rules={[
              {
                required: true,
                message: "Please input label!",
              },
            ]}
          >
            <Input
              placeholder="Wallet label"
              onChange={(e) => setWalletLabel(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={<b>Address</b>}
            name="address"
            rules={[
              {
                required: true,
                message: "Address required",
              },
            ]}
          >
            <Input
              placeholder="Wallet Address"
              value={addressInput}
              onChange={(e) => setAddressInput(e.target.value)}
              style={{ padding: "8px", borderRadius: "4px" }}
            />
          </Form.Item>
          <Form.Item label={<b>Network</b>} name="assetNetworkId">
            <Select
              showSearch
              allowClear
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              onChange={(e) => setSelectedAssetNetwork(e)}
              options={assetNetworkList}
              placeholder="Network"
            />
          </Form.Item>
        </Col>
        <Col lg={0} md={0} sm={0} xs={0} xl={8} xxl={8}></Col>
      </Row>
      <Row justify={"center"}>
        <Button
          type="primary"
          onClick={() => {
            setShowConfirmModal(true);
          }}
          disabled={!walletLabel || !addressInput || !selectedClient}
        >
          Add to whitelist
        </Button>
      </Row>

      {showConfirmModal ? (
        <Modal
          width="80%"
          footer={
            <Row justify={"end"}>
              <Button
                onClick={() => {
                  setShowConfirmModal(false);
                }}
                loading={isCreating}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  confirmCreation();
                }}
                loading={isCreating}
                disabled={!walletLabel || !addressInput || !selectedClient}
              >
                Confirm
              </Button>
            </Row>
          }
          open={showConfirmModal}
          onCancel={() => {
            setShowConfirmModal(false);
          }}
          onClose={() => {
            setShowConfirmModal(false);
          }}
        >
          <p>
            <b>Client: </b>
            {clientOptions?.find((e) => e.value === selectedClient)?.label}
          </p>
          <p>
            <b>Label: </b> {walletLabel}
          </p>
          <p>
            <b>Wallet Address: </b> {addressInput}
          </p>
          {selectAssetNetwork ? (
            <p>
              <b>Network: </b>
              {
                assetNetworkList?.find(
                  (e: any) => e.value === selectAssetNetwork,
                )?.label
              }
            </p>
          ) : undefined}
        </Modal>
      ) : undefined}
    </Content>
  );
}
