import { DataType } from "@antv/l7-core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Layout,
  Row,
  Space,
  Spin,
  Table,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { Content } from "antd/lib/layout/layout";
import { ColumnsType } from "antd/lib/table";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../../hooks/useCopyToClipboard";
import { DisplayContext } from "../../Context/displayContext";

dayjs.extend(utc);

const { RangePicker } = DatePicker;
export default function ViewMasterLedgerEBankingLedger() {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const displayContext = useContext(DisplayContext);
  const navigate = useNavigate();
  const [filterForm] = Form.useForm();
  const [, copy] = useCopyToClipboard();

  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [data, setData] = useState<any[]>();
  const [fetchDate, setFetchDate] = useState<any>();
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  const [isRunning, setIsRunning] = useState<boolean>();

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today and today
    return current > dayjs().endOf("day");
  };

  const fetchData = useCallback(
    (
      params = {
        startDate: undefined,
        endDate: undefined,
        instantFetch: false,
      },
    ) => {
      setOnFinishLoad(false);
      axios({
        method: "Post",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking",
        withCredentials: true,
        data: {
          copyToClipBoard: true,
          startDate: params.startDate,
          endDate: params.endDate,
          instantFetch: params.instantFetch,
        },
      })
        .then((res) => {
          setFetchDate(new Date(res.data.lastUpdated));
          setData(res.data.transactions);
          setIsRunning(res.data.isRunning);
        })
        .finally(() => setOnFinishLoad(true));
    },
    [],
  );

  const instantFetch = useCallback(() => {
    let startDate, endDate;
    const form = filterForm.getFieldsValue();
    if (form.dateRange) {
      startDate = form.dateRange[0]
        .tz("America/New_York", true)
        .startOf("day")
        .toISOString();
      endDate = form.dateRange[1]
        .tz("America/New_York", true)
        .endOf("day")
        .toISOString();
    }
    fetchData({
      startDate: startDate,
      endDate: endDate,
      instantFetch: true,
    });
  }, [filterForm, fetchData]);

  const onReset = () => {
    filterForm.resetFields();
    fetchData();
  };

  const filterEbankingledger = (form: any) => {
    let startDate, endDate;
    setOnFinishLoad(false);
    if (form.dateRange) {
      startDate = form.dateRange[0]
        .tz("America/New_York", true)
        .startOf("day")
        .toISOString();
      endDate = form.dateRange[1]
        .tz("America/New_York", true)
        .endOf("day")
        .toISOString();
    }
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking",
      withCredentials: true,
      data: {
        startDate: startDate,
        endDate: endDate,
        delchainPortfolioIds: [1],
      },
    })
      .then((res) => {
        setData(res.data.transactions);
      })
      .finally(() => setOnFinishLoad(true));
  };

  const copyCSV = () => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking/export-csv",
      withCredentials: true,
      data: {
        startDate: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[0]
              .tz("America/New_York", true)
              .startOf("day")
              .toISOString()
          : undefined,
        endDate: filterForm.getFieldValue("dateRange")
          ? filterForm
              .getFieldValue("dateRange")[1]
              .tz("America/New_York", true)
              .endOf("day")
              .toISOString()
          : undefined,
        copyToClipBoard: true,
        delchainPortfolioIds: [1],
      },
    })
      .then((res) => {
        copy(res.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        toast.success("Data copied to clipboard", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };
  const initialColumns: ColumnsType<DataType> = [
    {
      title: <Space>Timestamp</Space>,
      dataIndex: "timestamp",
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
      width: 150,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {new Date(timestamp).toLocaleString("en-US", {
            timeZone: displayTimezone,
            timeZoneName: "short",
          })}
        </Space>
      ),
    },
    {
      title: "Ledger ID",
      dataIndex: "ledger_id",
      sorter: (a: any, b: any) => a.ledger_id.localeCompare(b.ledger_id),
      width: 200,
      render: (_: any, { ledger_id }: any) => (
        <React.Fragment>{ledger_id}</React.Fragment>
      ),
    },
    {
      title: "Portfolio No",
      dataIndex: "portfolio_no",
      sorter: (a: any, b: any) => a.portfolio_no - b.portfolio_no,
      width: 150,
      render: (_: any, { portfolio_no }: any) => <Space>{portfolio_no}</Space>,
    },
    {
      title: "Client Portfolio No",
      dataIndex: "client_portfolio_no",
      sorter: (a: any, b: any) => a.client_portfolio_no - b.client_portfolio_no,
      width: 200,
      render: (_: any, { client_portfolio_no }: any) => (
        <Space>{client_portfolio_no}</Space>
      ),
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      sorter: (a: any, b: any) => a.client_name.localeCompare(b.client_name),
      width: 200,
      render: (_: any, { client_name }: any) => <Space>{client_name}</Space>,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
      width: 200,
      render: (_: any, { description }: any) => <Space>{description}</Space>,
    },
    {
      title: "Accounting Text",
      dataIndex: "accounting_text",
      sorter: (a: any, b: any) =>
        a.accounting_text.localeCompare(b.accounting_text),
      width: 150,
      render: (_: any, { accounting_text }: any) => (
        <Space>{accounting_text}</Space>
      ),
    },
    {
      title: "Dir",
      dataIndex: "dir",
      sorter: (a: any, b: any) => a.dir.localeCompare(b.dir),
      width: 100,
      render: (_: any, { dir }: any) => (
        <Space className={`dcl-${dir}`}>
          {dir === "Virtual Deposit"
            ? "Credit"
            : dir === "Virtual Withdrawal"
            ? "Debit"
            : dir}
        </Space>
      ),
    },
    {
      title: "Currency",
      dataIndex: "currency",
      sorter: (a: any, b: any) => a.currency.localeCompare(b.currency),
      width: 100,
      render: (_: any, { currency }: any) => <Space>{currency}</Space>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a: any, b: any) => a.amount - b.amount,
      width: 150,
      align: "right",
      render: (_: any, { amount, currency }: any) =>
        currency === "USD" || currency === "EUR" ? (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              style: "currency",
              currency: currency,
            }).format(amount)}
          </Space>
        ) : (
          <Space>
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(amount)}
          </Space>
        ),
    },
    {
      title: "Amount (USD)",
      dataIndex: "amount_usd",
      sorter: (a: any, b: any) => a.amount_usd - b.amount_usd,
      width: 150,
      align: "right",
      render: (_: any, { amount_usd }: any) => (
        <Space>
          {Intl.NumberFormat("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
            style: "currency",
            currency: "USD",
          }).format(amount_usd)}
        </Space>
      ),
    },
    {
      title: "Reversed",
      dataIndex: "reversed",
      sorter: (a: any, b: any) => a.reversed.localeCompare(b.reversed),
      width: 80,
      render: (_: any, { reversed }: any) => (
        <Space className={`dcl-${reversed}`}>{reversed}</Space>
      ),
    },
    {
      title: "Client Portfolio Label",
      dataIndex: "client_portfolio_label",
      sorter: (a: any, b: any) =>
        a.client_portfolio_label.localeCompare(b.client_portfolio_label),
      width: 200,
      render: (_: any, { client_portfolio_label }: any) => (
        <Space>{client_portfolio_label}</Space>
      ),
    },
    {
      title: <Space>Accounting Date</Space>,
      dataIndex: "accounting_date",
      sorter: (a: any, b: any) => a.accounting_date - b.accounting_date,
      width: 200,
      render: (_: any, { accounting_date }: any) => (
        <Space>
          {dayjs(String(accounting_date), "YYYYMMDD").format("MM/DD/YYYY")}
        </Space>
      ),
    },
    {
      title: "Value Date",
      dataIndex: "value_date",
      sorter: (a: any, b: any) => a.value_date - b.value_date,
      width: 150,
      // fixed: "right",
      render: (_: any, { value_date }: any) => (
        <Space>
          {dayjs(String(value_date), "YYYYMMDD").format("MM/DD/YYYY")}
        </Space>
      ),
    },
  ];

  const fetchIsRunning = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/ebanking/is-running",
      withCredentials: true,
    }).then((res) => {
      setIsRunning(res.data.isRunning);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning) {
        fetchIsRunning();
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [isRunning, fetchIsRunning]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  return onFinshiLoad ? (
    <Content id="master-ledger-ebanking">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Row className="dcl-filter-row" align="middle">
            <Space>
              <Button
                className="dcl-btn-toggle"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
                Filters
                {showFilter ? (
                  <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
                ) : (
                  <FontAwesomeIcon
                    icon={"fa-solid fa-caret-down" as IconProp}
                  />
                )}
              </Button>
            </Space>
            <Space>
              <Button className="ant-btn-primary" onClick={instantFetch}>
                Instant Fetch
              </Button>
            </Space>
            <Space className="dcl-filter-row-space">
              <b>Last updated: </b>
              {fetchDate
                ? fetchDate.toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })
                : "Pending..."}
              {isRunning ? <Spin /> : <></>}
            </Space>
          </Row>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={filterEbankingledger}
            className="dcl-toggled-content dcl-toggled-content-filter"
            hidden={!showFilter}
            labelWrap
          >
            <Row>
              <Form.Item
                name="dateRange"
                className="dcl-filter-item"
                initialValue={[
                  dayjs().tz("America/New_York", true).startOf("year"),
                  dayjs(),
                ]}
              >
                <RangePicker disabledDate={disabledDate} />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                {data?.length}
                <b>Results</b>
              </Space>
              <Space>
                <Button htmlType="submit" className="ant-btn-primary">
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify="end">
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={copyCSV}
            >
              <FontAwesomeIcon icon={"fa-regular fa-copy" as IconProp} />
              Copy to Clipboard
            </Button>
          </Row>
        </Col>
      </Row>

      <Table
        dataSource={data}
        sticky
        showSorterTooltip={false}
        columns={initialColumns as any}
        scroll={{
          x: "max-header",
        }}
        rowClassName={(_, index) => (index % 2 ? "odd" : "even")}
      />
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
