import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function ViewVenueList() {
  const navigate = useNavigate();
  const [venueTypeList, setVenueTypeList] = useState<any[]>([]);
  const [venueList, setVenueList] = useState<any>();
  const [canEdit, setCanEdit] = useState<false>();

  const fetchVenueList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue",
      params: {
        includeAll: true,
        showDetail: true,
      },
      withCredentials: true,
    })
      .then((res) => {
        setVenueList(res.data.list);
        setCanEdit(res.data.canEdit);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/");
        } else {
          toast.error("Failed to get Venue List");
        }
      });
  }, [navigate]);
  const fetchVenueTypeList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/types",
      withCredentials: true,
    })
      .then((res) => {
        setVenueTypeList(res.data.venueTypeList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onCreateClick = useCallback(() => {
    navigate(`create`, {
      state: {
        from: window.location.pathname,
      },
    });
  }, [navigate]);
  const onEditClick = useCallback(
    (id: number) => {
      navigate(`edit/${id}`, {
        state: {
          from: window.location.pathname,
        },
      });
    },
    [navigate],
  );

  useEffect(() => {
    fetchVenueList();
    fetchVenueTypeList();
  }, [fetchVenueList, fetchVenueTypeList]);

  return (
    <Content id="view-venue-list">
      <Row justify={"end"}>
        <Button type="primary" onClick={onCreateClick}>
          <FontAwesomeIcon
            icon={"fa-solid fa-plus" as IconProp}
            className="white-plus"
          />
          &nbsp; New Venue
        </Button>
      </Row>
      <Table
        dataSource={venueList}
        sticky
        pagination={{ defaultPageSize: 100 }}
        scroll={{ x: 800 }}
        columns={[
          {
            title: "Venue Name",
            dataIndex: "name",
            sorter: (a, b) => a.name.localeCompare(b.name),
          },
          {
            title: "Venue Type",
            dataIndex: "typeId",
            render: (_: any, { typeId }: any) => (
              <React.Fragment>
                {venueTypeList.find((t) => t.id === typeId)
                  ? venueTypeList.find((t) => t.id === typeId).name
                  : ""}
              </React.Fragment>
            ),
            sorter: (a, b) =>
              venueTypeList
                .find((t) => t.id === a.typeId)
                .name.localeCompare(
                  venueTypeList.find((t) => t.id === b.typeId).name,
                ),
          },
          {
            title: "CCXT Key",
            dataIndex: "ccxtKey",
          },
          {
            title: "Venue Active Status",
            dataIndex: "isActive",
            render: (_: any, { isActive }: any) => (
              <Checkbox disabled checked={isActive} />
            ),
          },
          {
            title: "Display Venue in Tech Settings",
            dataIndex: "toFetch",
            render: (_: any, { toFetch }: any) => (
              <Checkbox disabled checked={toFetch} />
            ),
          },
          {
            title: "Included in Trading Reports",
            dataIndex: "inLists",
            render: (_: any, { inLists }: any) => (
              <Checkbox disabled checked={inLists} />
            ),
          },
          {
            title: "Fething Deposit Address",
            dataIndex: "depositAddressToFetch",
            render: (_: any, { depositAddressToFetch }: any) => (
              <Checkbox disabled checked={depositAddressToFetch} />
            ),
          },

          {
            title: "Edit",
            dataIndex: "edit",
            render: (_: any, { id }: any) => (
              <Button disabled={!canEdit} onClick={() => onEditClick(id)}>
                Edit
              </Button>
            ),
          },
        ]}
      />
    </Content>
  );
}
export default ViewVenueList;
