import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Layout,
  Modal,
  Row,
  Space,
  Spin,
  Tabs,
} from "antd";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "./Context/displayContext";
import TextArea from "antd/lib/input/TextArea";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import "../assets/scss/changelog.scss";
import HTMLReactParser from "html-react-parser";

function ViewChangelog() {
  const displayContext = useContext(DisplayContext);
  const [onFinishLoaded, setOnFinishLoaded] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [changeLogs, setChangeLogs] = useState<any[]>([]);
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  const [deleteRowId, setDeleteRowId] = useState<number>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const [isModalOpen, setModalIsOpen] = useState<boolean>(false);
  const [newVersion, setNewVersion] = useState<string>("");
  const [newDate, setNewDate] = useState<Date>(new Date());
  const [newText, setNewText] = useState<string>("");
  const [modalLoading, setModalLoading] = useState<boolean>(false);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const fetchData = useCallback(() => {
    setOnFinishLoaded(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/changelog",
      withCredentials: true,
    })
      .then((res) => {
        setCanEdit(res.data.canEdit);
        setChangeLogs(res.data.result);
      })
      .finally(() => {
        setOnFinishLoaded(true);
      });
  }, []);

  const deleteRow = useCallback(() => {
    axios({
      method: "Delete",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/changelog",
      withCredentials: true,
      data: {
        changelogId: deleteRowId,
      },
    })
      .then(() => {
        toast.success("Changelogs Deleted Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        fetchData();
      });
  }, [deleteRowId, fetchData]);

  const openDeleteConfirmModal = (id: any) => {
    setIsDeleteModalOpen(true);
    setDeleteRowId(id);
  };

  const saveNewChangelog = useCallback(() => {
    setModalLoading(true);
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/changelog",
      withCredentials: true,
      data: {
        changelog: {
          date: newDate.toISOString(),
          version: newVersion,
          value: newText,
        },
      },
    })
      .then(() => {
        toast.success("Changelogs Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(() => {
        toast.success("Failed to Create Changelog", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setNewDate(new Date());
        setNewVersion("");
        setNewText("");
        setModalLoading(false);
        fetchData();
      });
  }, [newDate, newVersion, newText, fetchData]);
  const handleCancel = () => {
    setNewDate(new Date());
    setNewVersion("");
    setNewText("");
    setModalIsOpen(false);
  };

  const handleOk = () => {
    saveNewChangelog();
    setModalIsOpen(false);
  };

  const saveUpdates = useCallback(() => {
    setOnFinishLoaded(false);
    axios({
      method: "Put",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/changelog",
      withCredentials: true,
      data: {
        changeLogs,
      },
    })
      .then(() => {
        toast.success("Changelogs Updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((e) => {
        toast.error("Changelogs Failed to Update", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        fetchData();
      });
  }, [changeLogs, fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return onFinishLoaded ? (
    <Layout id={canEdit ? "dcl-changelog-edit" : "dcl-changelog"}>
      {canEdit ? (
        <Row justify="end">
          <Button
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            Create new change log
          </Button>
        </Row>
      ) : (
        <></>
      )}
      {canEdit ? (
        <Tabs
          defaultActiveKey={"edit"}
          type="card"
          items={[
            {
              label: "Edit",
              key: "edit",
              children: (
                <React.Fragment>
                  {changeLogs.map((changelog, i) => (
                    <React.Fragment key={changelog.id}>
                      <Tabs defaultActiveKey={"edit"} type="card" items={[]} />
                      <Row align="middle">
                        <Col xs={{ span: 24 }} xl={{ span: 6, offset: 1 }}>
                          Version:
                          <Input
                            defaultValue={changelog.version}
                            onChange={(e) => {
                              changeLogs[i].version = e.target.value;
                            }}
                          />
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 4, offset: 1 }}>
                          Date:
                          <DatePicker
                            style={{ width: "100%" }}
                            defaultValue={dayjs(changelog.date)}
                            onChange={(e) => {
                              changeLogs[i].date = e?.toDate().toISOString();
                            }}
                          />
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 6, offset: 1 }}>
                          Text:
                          <TextArea
                            defaultValue={changelog.value}
                            onChange={(e) => {
                              changeLogs[i].value = e.target.value;
                            }}
                          />
                        </Col>
                        <Col xs={{ span: 24 }} xl={{ span: 4, offset: 1 }}>
                          <Space>
                            <Button
                              onClick={() => {
                                openDeleteConfirmModal(changelog.id);
                              }}
                            >
                              Delete
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                      <Divider />
                    </React.Fragment>
                  ))}
                  <React.Fragment>
                    <Row justify="center">
                      <Button onClick={fetchData}>Reset</Button>
                      <Button type="primary" onClick={saveUpdates}>
                        Save
                      </Button>
                    </Row>
                    <Modal
                      width={"80%"}
                      open={isModalOpen}
                      onCancel={handleCancel}
                      footer={[
                        <Button key="back" onClick={handleCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleOk}
                          loading={modalLoading}
                        >
                          Submit
                        </Button>,
                      ]}
                    >
                      <Row align={"middle"}>
                        <Col span={24}>
                          <Space style={{ color: "white" }}>
                            <h3>Date:</h3>
                          </Space>
                        </Col>
                        <Col span={24}>
                          <DatePicker
                            defaultValue={dayjs(newDate)}
                            onChange={(e) => {
                              const date = e ? e.toDate() : new Date();
                              setNewDate(date);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row align={"middle"}>
                        <Col span={24}>
                          <Space style={{ color: "white" }}>
                            <h3>Version:</h3>
                          </Space>
                        </Col>
                        <Col span={24}>
                          <Input
                            value={newVersion}
                            onChange={(e) => {
                              setNewVersion(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row align={"middle"}>
                        <Col span={24}>
                          <Space style={{ color: "white" }}>
                            <h3>Text:</h3>
                          </Space>
                        </Col>
                        <Col span={24}>
                          <TextArea
                            value={newText}
                            rows={8}
                            onChange={(e) => {
                              setNewText(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </Modal>

                    <Modal
                      getContainer={false}
                      open={isDeleteModalOpen}
                      onCancel={() => {
                        setIsDeleteModalOpen(false);
                      }}
                      onOk={() => {
                        deleteRow();
                        setIsDeleteModalOpen(false);
                      }}
                    >
                      <p>
                        Are you sure you want to delete the change logs' row{" "}
                        {deleteRowId}?
                      </p>
                      <p>Click the Ok button to delete this change log</p>
                    </Modal>
                  </React.Fragment>
                </React.Fragment>
              ),
            },
            {
              label: "Preview",
              key: "preview",
              children: (
                <React.Fragment>
                  <Layout id="dcl-changelog">
                    {changeLogs.map((changelog, i) => (
                      <React.Fragment key={changelog.id}>
                        <Card>
                          <Row>
                            <h2 className="dcl-changelog">
                              {changelog.version}
                            </h2>
                          </Row>
                          <Row>
                            {new Date(changelog.date).toLocaleString("en-US", {
                              timeZone: displayTimezone,
                              timeZoneName: "short",
                            })}
                          </Row>
                          <Row>{HTMLReactParser(changelog.value)}</Row>
                        </Card>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </Layout>
                </React.Fragment>
              ),
            },
          ]}
        />
      ) : (
        changeLogs.map((changelog, i) => (
          <React.Fragment key={changelog.id}>
            <Card>
              <Row>
                <h2 className="dcl-changelog">{changelog.version}</h2>
              </Row>
              <Row>
                {new Date(changelog.date).toLocaleString("en-US", {
                  timeZone: displayTimezone,
                  timeZoneName: "short",
                })}
              </Row>
              <Row>{HTMLReactParser(changelog.value)}</Row>
            </Card>
            <Divider />
          </React.Fragment>
        ))
      )}
    </Layout>
  ) : (
    <Layout
      id="dcl-changelog"
      style={{
        paddingTop: "30vh",
        minHeight: "100vh",
      }}
    >
      <Spin size="large" />
    </Layout>
  );
}
export default ViewChangelog;
