import { Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import ViewTradingReportCurrencyBreakdown from "./ViewTradingReportCurrencyBreakdown";
import ViewTradingReportDashboard from "./ViewTradingReportDashboard";
import ViewVenueTradingReportVenueBreakdown from "./ViewTradingReportVenueBreakdown";
import ViewVenueTradingReportOrders from "./ViewVenueTradingReportOrderSummary";

export default function ViewVenueTradingReportContainer() {
  const { tabName = "dashboard" } = useParams();

  const navigate = useNavigate();

  return (
    <Content id="venue-trading-report-container">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/venues/trading_report/" + key);
        }}
        items={[
          {
            label: "Dashboard",
            key: "dashboard",
            children: (
              <React.Fragment>
                <ViewTradingReportDashboard />
              </React.Fragment>
            ),
          },
          {
            label: "Orders",
            key: "orders",
            children: (
              <React.Fragment>
                <ViewVenueTradingReportOrders />
              </React.Fragment>
            ),
          },
          {
            label: "Venue Breakdown",
            key: "venue_breakdown",
            children: (
              <React.Fragment>
                <ViewVenueTradingReportVenueBreakdown />
              </React.Fragment>
            ),
          },
          {
            label: "Currency Breakdown",
            key: "currency_breakdown",
            children: (
              <React.Fragment>
                <ViewTradingReportCurrencyBreakdown />
              </React.Fragment>
            ),
          },
        ]}
      />
    </Content>
  );
}
