import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Divider,
  Layout,
  Pagination,
  Row,
  Space,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";

const { Text } = Typography;

function WalletInfo() {
  const { walletId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const displayContext = useContext(DisplayContext);
  const [locationState, setLocationtate] = useState<any>();
  const [onFinshiLoad, setOnFinishLoad] = useState<boolean>(false);
  const [wallet, setWallet] = useState<any>();
  const [custodyWallets, setCustodyWallets] = useState<any>();
  const [stakingWallets, setStakingWallets] = useState<any>();

  const [editDisabled, setEditDisabled] = useState<boolean>(true);

  const [balanceDataLoaded, setBalanceDataLoaded] = useState<boolean>(false);
  const [balanceData, setBalanceData] = useState<any[]>([]);
  const [displayBalanceData, setDisplayBalanceData] = useState<boolean>(false);

  const [txnDataLoaded, setTxnDataLoaded] = useState<boolean>(false);
  const [displayTxnData, setDisplayTxnData] = useState<boolean>(false);
  const [txnData, setTxnData] = useState<any[]>([]);
  const [walletSwitches, setWalletSwitches] = useState<any[]>();
  const [assetPrices, setAssetPrices] = useState<any>();

  const [subWalletLabel, setSubWalletLabel] = useState<string>("");
  const [selectedSubWalletId, setSelectedSubWalletId] = useState<number>();
  const [feePolicyAssignedCSDY, setFeePolicyAssignedCSDY] =
    useState<string>("");
  const [feePolicyAssignedSTKG, setFeePolicyAssignedSTKG] =
    useState<string>("");
  const [feePolicyAssignedTRDG, setFeePolicyAssignedTRDG] =
    useState<string>("");
  const [balanceLoading, setBalanceLoading] = useState<boolean>(false);
  const [txnLoading, setTxnLoading] = useState<boolean>(false);

  const [balancePageTotal, setBalancePageTotal] = useState<number>(0);
  const [txnPageTotal, setTxnPageTotal] = useState<number>(0);
  const [fetchingTxns, setFetchingTxns] = useState<boolean>(false);
  const [canFetchWalletHistoricalTxns, setCanFetchWalletHistoricalTxns] =
    useState<boolean>(false);

  useEffect(() => {
    const backendWSURL =
      process.env.REACT_APP_AWS_BACKEND_URL?.replace("https", "wss").replace(
        "http",
        "ws",
      ) + "/ws/dsp-setting";
    const socket = new WebSocket(backendWSURL as string);
    // socket.addEventListener("open", function (event) {
    //    console.log(event);
    // });
    socket.addEventListener("message", (event) => {
      try {
        // console.log(JSON.parse(event.data)?.value);
        setFetchingTxns(JSON.parse(event.data)?.value);
      } catch (err) {
        console.error(err);
      }
    });
    // socket.addEventListener("close", function (event) {
    //   console.log(event);
    // });
    socket.addEventListener("error", function (event: any) {
      console.error("WebSocket error: ", event);
    });

    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  const balanceColumns = [
    {
      title: "Date",
      dataIndex: "timestamp",
      width: 50,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {timestamp
            ? new Date(timestamp).toLocaleString("en-US", {
                timeZone: displayContext?.displayContext.timezone,
                timeZoneName: "short",
              })
            : ""}
        </Space>
      ),
    },
    {
      title: "Amount",
      dataIndex: "value",
      width: 50,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Tooltip title={value}>
          {value.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}
        </Tooltip>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 50,
      align: "right" as const,
      render: (_: any, { price }: any) => (
        <Tooltip title={price}>
          {price.toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}
        </Tooltip>
      ),
    },
    {
      title: "USD Value",
      dataIndex: "usdPrice",
      width: 50,
      align: "right" as const,
      render: (_: any, { usdPrice }: any) => (
        <Tooltip
          title={usdPrice === "N/A" ? "Asset price not found" : usdPrice}
        >
          {usdPrice === "N/A"
            ? "-"
            : usdPrice?.toLocaleString("en-us", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              })}
        </Tooltip>
      ),
    },
  ];

  const txnColums = [
    {
      title: "Date",
      dataIndex: "timestamp",
      width: 50,
      render: (_: any, { timestamp }: any) => (
        <Space>
          {timestamp
            ? new Date(timestamp).toLocaleString("en-US", {
                timeZone: displayContext?.displayContext.timezone,
                timeZoneName: "short",
              })
            : ""}
        </Space>
      ),
    },
    {
      title: "Transaction Hash",
      dataIndex: "hash",
      width: 50,
      render: (_: any, { hash }: any) => (
        <Text style={{ width: "100%" }} ellipsis={{ tooltip: hash }}>
          {hash}
        </Text>
      ),
    },
    {
      title: "Block Number",
      dataIndex: "blockNumber",
      width: 50,
      render: (_: any, { blockNumber }: any) => (
        <Text style={{ width: "100%" }} ellipsis={{ tooltip: blockNumber }}>
          {blockNumber}
        </Text>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "value",
      width: 50,
      align: "right" as const,
      render: (_: any, { value }: any) => (
        <Tooltip title={Number(value)}>
          {Number(value).toLocaleString("en-us", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          })}
        </Tooltip>
      ),
    },
    {
      title: "Asset",
      dataIndex: "subWallet",
      width: 50,
      render: (_: any, { subWallet }: any) => (
        <Space>
          {subWallet && subWallet.asset ? subWallet.asset.ticker : ""}
        </Space>
      ),
    },
    {
      title: "Fee",
      dataIndex: "fee",
      width: 50,
      align: "right" as const,
      render: (_: any, { fee }: any) => (
        <Space>
          {fee
            ? fee.toLocaleString("en-us", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              })
            : ""}
        </Space>
      ),
    },
    {
      title: "Fee Currency",
      dataIndex: "assetFeeCurrency",
      width: 50,
      render: (_: any, { assetFeeCurrency }: any) => (
        <Space>{assetFeeCurrency ? assetFeeCurrency.ticker : ""}</Space>
      ),
    },
  ];

  const subWalletColumns = [
    {
      title: "Label",
      dataIndex: "label",
      width: 50,
    },
    {
      title: "Asset",
      dataIndex: "asset",
      width: 50,
      render: (_: any, { asset }: any) => (
        <Space>{asset && asset.name ? asset.name : ""}</Space>
      ),
    },
    {
      title: "Type",
      dataIndex: "walletType",
      width: 50,
      render: (_: any, { walletType }: any) => (
        <Space>{walletType && walletType.name ? walletType.name : ""}</Space>
      ),
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      width: 50,
      render: (_: any, { isActive, switches }: any) => (
        <Tooltip
          title={
            switches && switches.length
              ? switches.map((t: any, idx: number) => (
                  <Row key={`switch_isactive_${idx}`}>
                    {t.value ? (
                      <b className="dcl-label-activated">
                        Activated Date:&nbsp;
                      </b>
                    ) : (
                      <b className="dcl-label-archived">Archived Date:&nbsp;</b>
                    )}
                    {new Date(t.date).toLocaleString("en-US", {
                      timeZone: displayContext?.displayContext.timezone,
                      timeZoneName: "short",
                    })}
                  </Row>
                ))
              : ""
          }
        >
          <Switch disabled checked={isActive} />
        </Tooltip>
      ),
    },
    {
      title: "In Report",
      dataIndex: "isReport",
      width: 50,
      render: (_: any, { isReport }: any) => (
        <Space>
          <Switch disabled checked={isReport} />
        </Space>
      ),
    },
    {
      title: "Is Placeholder",
      dataIndex: "isPlaceholder",
      width: 50,
      render: (_: any, { isPlaceholder }: any) => (
        <Space>
          <Switch disabled checked={isPlaceholder} />
        </Space>
      ),
    },
    {
      title: "Fee Policy Assigned",
      dataIndex: "FeePolicyAssigned",
      width: 200,
      render: (_: any, { feePolicyAssignments }: any) => (
        <React.Fragment>
          <Row>
            {feePolicyAssignments && feePolicyAssignments.length > 0 ? (
              feePolicyAssignments.find(
                (t: any) =>
                  t.feePolicyType &&
                  t.feePolicyType.name.toLowerCase() === "custody",
              ) ? (
                <React.Fragment>
                  <b>{"Custody: "}</b>
                  {
                    feePolicyAssignments.find(
                      (t: any) =>
                        t.feePolicyType &&
                        t.feePolicyType.name.toLowerCase() === "custody",
                    ).feePolicy.name
                  }
                </React.Fragment>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Row>
          <Row>
            {feePolicyAssignments && feePolicyAssignments.length > 0 ? (
              feePolicyAssignments.find(
                (t: any) =>
                  t.feePolicyType &&
                  t.feePolicyType.name.toLowerCase() === "staking",
              ) ? (
                <React.Fragment>
                  <b>{"Staking: "}</b>
                  {
                    feePolicyAssignments.find(
                      (t: any) =>
                        t.feePolicyType &&
                        t.feePolicyType.name.toLowerCase() === "staking",
                    ).feePolicy.name
                  }
                </React.Fragment>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Row>
          <Row>
            {feePolicyAssignments && feePolicyAssignments.length > 0 ? (
              feePolicyAssignments.find(
                (t: any) =>
                  t.feePolicyType &&
                  t.feePolicyType.name.toLowerCase() === "trading",
              ) ? (
                <React.Fragment>
                  <b>{"Trading: "}</b>
                  {
                    feePolicyAssignments.find(
                      (t: any) =>
                        t.feePolicyType &&
                        t.feePolicyType.name.toLowerCase() === "trading",
                    ).feePolicy.name
                  }
                </React.Fragment>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </Row>
        </React.Fragment>
      ),
    },

    {
      title: "Quantity",
      dataIndex: "displayBalance",
      width: 50,
      align: "right" as const,
      render: (_: any, { displayBalance }: any) => (
        <Space>
          <Tooltip title={displayBalance}>
            {displayBalance.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 50,
      align: "right" as const,
      render: (_: any, { assetId }: any) => (
        <Space>
          {assetPrices && assetPrices[assetId] ? (
            <Tooltip title={assetPrices[assetId]}>
              {assetPrices[assetId].toLocaleString("en-us", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              })}
            </Tooltip>
          ) : (
            "0.00"
          )}
        </Space>
      ),
    },
    {
      title: "USD Value($)",
      dataIndex: "usdValue",
      width: 50,
      align: "right" as const,
      render: (_: any, { assetId, displayBalance }: any) => (
        <Space>
          {assetPrices && assetPrices[assetId] ? (
            <Tooltip title={assetPrices[assetId] * displayBalance}>
              {(assetPrices[assetId] * displayBalance).toLocaleString("en-us", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 8,
              })}
            </Tooltip>
          ) : (
            "$0.00"
          )}
        </Space>
      ),
    },
    {
      title: "Balances",
      dataIndex: "balances",
      width: 50,
      render: (_: any, { id, label }: any) => (
        <Space>
          <Link
            to="#"
            onClick={() => {
              if (selectedSubWalletId !== id) {
                fetchWalletBalances(id, 0, 5);
                fetchWalletTxns(id, 0, 5);
                setSubWalletLabel(label);
                setSelectedSubWalletId(id);
              }
              setDisplayBalanceData(true);
              setDisplayTxnData(false);
            }}
          >
            Balances
          </Link>
        </Space>
      ),
    },
    {
      title: "Transactions",
      dataIndex: "transactions",
      width: 50,
      render: (_: any, { id, label }: any) => (
        <Space>
          <Link
            to="#"
            onClick={() => {
              if (selectedSubWalletId !== id) {
                fetchWalletTxns(id, 0, 5);
                fetchWalletBalances(id, 0, 5);
                setSubWalletLabel(label);
                setSelectedSubWalletId(id);
              }
              setDisplayBalanceData(false);
              setDisplayTxnData(true);
            }}
          >
            Transactions
          </Link>
        </Space>
      ),
    },
  ];

  const gridStyleLeft: React.CSSProperties = {
    width: "35%",
    textAlign: "left",
  };
  const gridStyleRight: React.CSSProperties = {
    width: "65%",
    textAlign: "left",
  };

  const fetchPolicyAssignedWallet = useCallback(() => {
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy-assgined",
      data: {
        id: walletId,
        type: "wallet",
      },
      withCredentials: true,
    }).then((res) => {
      const custodyPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType && t.feePolicyType.name.toLowerCase() === "custody",
      );
      if (custodyPolicy) {
        setFeePolicyAssignedCSDY(custodyPolicy.feePolicy.name);
      }
      const stakingPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType && t.feePolicyType.name.toLowerCase() === "staking",
      );
      if (stakingPolicy) {
        setFeePolicyAssignedSTKG(stakingPolicy.feePolicy.name);
      }
      const tradingPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType && t.feePolicyType.name.toLowerCase() === "trading",
      );
      if (tradingPolicy) {
        setFeePolicyAssignedTRDG(tradingPolicy.feePolicy.name);
      }
    });
  }, [walletId]);

  const fetchWalletWithId = useCallback(() => {
    setOnFinishLoad(false);
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallet",
      withCredentials: true,
      params: {
        walletId: walletId,
      },
    })
      .then((res) => {
        const cWallets = res.data.wallet.subWallets.filter(
          (t: any) =>
            t.walletType && t.walletType.name.toLowerCase() !== "staking",
        );
        const sWallets = res.data.wallet.subWallets.filter(
          (t: any) =>
            t.walletType && t.walletType.name.toLowerCase() === "staking",
        );
        setCustodyWallets(cWallets);
        setStakingWallets(sWallets);
        delete res.data.wallet.subWallets;
        setWallet(res.data.wallet);
        setWalletSwitches(res.data.wallet.switches);
        setAssetPrices(res.data.assetPrices);
        setCanFetchWalletHistoricalTxns(res.data.canFetchWalletHistoricalTxns);
        if (res.data.canEdit) {
          setEditDisabled(false);
        }
      })
      .catch(() => {
        toast.error("Failed to fetch wallet");
        navigate("/bo/wallets/list");
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, [walletId, navigate]);

  const fetchWalletBalances = useCallback(
    (subWalletId: any, pageNum: number, pageSize: number) => {
      setBalanceLoading(true);
      axios({
        method: "Get",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL + "/util/sub-wallet-balances",
        withCredentials: true,
        params: {
          subWalletId: subWalletId,
          pageNum: pageNum,
          pageSize: pageSize,
        },
      })
        .then((res) => {
          setBalanceData(res.data.balances);
          if (pageNum === 0) {
            setBalancePageTotal(res.data.balanceCount);
          }
        })
        .finally(() => {
          setBalanceLoading(false);
          setBalanceDataLoaded(true);
        });
    },
    [],
  );

  useEffect(() => {
    fetchWalletWithId();
    fetchPolicyAssignedWallet();
    // fetchAssetApiUrlUtils();
  }, [fetchWalletWithId, fetchPolicyAssignedWallet]);

  const fetchWalletTxns = useCallback(
    (subWalletId: any, pageNum: number, pageSize: number) => {
      setTxnLoading(true);
      axios({
        method: "Get",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/sub-wallet-transactions",
        withCredentials: true,
        params: {
          subWalletId: subWalletId,
          pageNum: pageNum,
          pageSize: pageSize,
        },
      })
        .then((res) => {
          setTxnData(res.data.transactions);
          if (pageNum === 0) {
            setTxnPageTotal(res.data.txnCount);
          }
        })
        .finally(() => {
          setTxnLoading(false);
          setTxnDataLoaded(true);
        });
    },
    [],
  );

  useEffect(() => {
    if (displayBalanceData) {
      const element = document.getElementById("wallet-info-bottom");
      element?.scrollIntoView({ behavior: "smooth" });
    }
  }, [displayBalanceData, location]);

  useEffect(() => {
    setLocationtate(location.state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchWalletHistoricalTransactions = useCallback(() => {
    setFetchingTxns(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/wallet/fetch-wallet-historical-transactions",
      withCredentials: true,
      data: {
        walletId: walletId,
      },
    })
      .then(() => {
        toast.success("Transaction Fetched!");
      })
      .finally(() => {
        setSelectedSubWalletId(undefined);
        setDisplayTxnData(false);
        setDisplayBalanceData(false);
        setFetchingTxns(false);
      });
  }, [walletId]);

  return onFinshiLoad ? (
    <Content id="wallet-info">
      <Row>
        <Col md={{ span: 20 }} sm={{ span: 20 }}>
          <Button
            onClick={() => {
              if (locationState?.from === window.location.pathname) {
                navigate("/bo/wallets/list", { state: locationState });
              } else if (locationState) {
                navigate(locationState?.from, { state: locationState });
              } else {
                navigate("/bo/wallets/list", { state: locationState });
              }
            }}
          >
            <FontAwesomeIcon
              icon={"fa-solid fa-circle-chevron-left" as IconProp}
            />
          </Button>
        </Col>
        <Col span={3} style={{ float: "right", textAlign: "right" }}>
          <Button
            disabled={editDisabled}
            onClick={
              walletId
                ? () => {
                    const url =
                      "/bo/wallet/update/" + encodeURIComponent(walletId);
                    navigate(url, {
                      state: {
                        ...locationState,
                        from: window.location.pathname,
                      },
                    });
                  }
                : () => {
                    console.log("Empty");
                  }
            }
          >
            Edit
          </Button>
        </Col>
      </Row>
      <Row justify="center">
        <Col xxl={{ span: 11 }} lg={{ span: 11 }} md={{ span: 24 }}>
          <Card
            title={
              <Row>
                <Col span={8}>Client</Col>
              </Row>
            }
            bordered={false}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
          >
            <Card.Grid style={gridStyleLeft}>
              <b>Client Name</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {wallet && wallet.client ? (
                <Link
                  to={
                    "/bo/client/info/" +
                    encodeURIComponent(wallet.encodedClientId)
                  }
                  state={{ from: window.location.pathname }}
                >
                  {wallet.client.clientName}
                </Link>
              ) : (
                ""
              )}
            </Card.Grid>
            <Card.Grid style={gridStyleLeft}>
              <b>Email</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {wallet && wallet.client ? wallet.client.email : ""}
            </Card.Grid>

            <Card.Grid style={gridStyleLeft}>
              <b>Deltec Account</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {wallet && wallet.client ? wallet.client.deltecAccount : ""}
            </Card.Grid>

            <Card.Grid style={gridStyleLeft}>
              <b>Deltec SubAccount</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {wallet && wallet.clientAccount
                ? wallet.clientAccount.accountNumber
                : ""}
            </Card.Grid>

            <Card.Grid style={gridStyleLeft}>
              <b>Delchain Account</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {wallet && wallet.client ? wallet.client.delchainAccount : ""}
            </Card.Grid>
          </Card>
        </Col>
        <Col xxl={{ span: 11 }} lg={{ span: 11 }} md={{ span: 24 }}>
          <Card
            title={
              <Row>
                <Col span={8}>Wallet information</Col>
              </Row>
            }
            extra={
              canFetchWalletHistoricalTxns ? (
                <Button
                  type="primary"
                  style={{ height: "auto" }}
                  onClick={() => {
                    fetchWalletHistoricalTransactions();
                  }}
                  loading={fetchingTxns}
                >
                  Fetch Historical Transactions
                </Button>
              ) : (
                <></>
              )
            }
            bordered={false}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
          >
            <Card.Grid style={gridStyleLeft}>
              <b>Wallet Address</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              <Text
                style={{ width: "100%" }}
                ellipsis={{ tooltip: wallet.walletAddress }}
              >
                {wallet.walletAddress}
              </Text>
            </Card.Grid>

            <Card.Grid style={gridStyleLeft}>
              <b>Asset Network</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              <Text
                style={{ width: "100%" }}
                ellipsis={{
                  tooltip:
                    wallet && wallet.assetNetwork
                      ? wallet.assetNetwork.name
                      : "",
                }}
              >
                {wallet && wallet.assetNetwork ? wallet.assetNetwork.name : ""}
              </Text>
            </Card.Grid>

            <Card.Grid style={gridStyleLeft}>
              <b>Activities</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {walletSwitches?.map((t: any, i: any) => (
                <Row className="dcl-switch" key={i} style={{ margin: "1px" }}>
                  {t.value ? (
                    <b className="dcl-label-activated">Activated Date:&nbsp;</b>
                  ) : (
                    <b className="dcl-label-archived">Archived Date:&nbsp;</b>
                  )}
                  {new Date(t.date).toLocaleString("en-US", {
                    timeZone: displayContext?.displayContext.timezone,
                    timeZoneName: "short",
                  })}
                </Row>
              ))}
            </Card.Grid>
          </Card>
          <Card
            title={
              <Row>
                <Col span={8}>Fee Policies</Col>
              </Row>
            }
            bordered={false}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
          >
            <Card.Grid style={gridStyleLeft}>
              <b>Custody:</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {feePolicyAssignedCSDY}
            </Card.Grid>

            <Card.Grid style={gridStyleLeft}>
              <b>Staking:</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {feePolicyAssignedSTKG}
            </Card.Grid>
            <Card.Grid style={gridStyleLeft}>
              <b>Trading:</b>
            </Card.Grid>
            <Card.Grid style={gridStyleRight}>
              {feePolicyAssignedTRDG}
            </Card.Grid>
          </Card>
        </Col>

        <Col xxl={{ span: 22 }} lg={{ span: 22 }} md={{ span: 24 }}>
          <Card
            title={
              <Row>
                <Col span={8}>
                  Custody Subwallets (
                  {custodyWallets ? custodyWallets.length : 0})
                </Col>
              </Row>
            }
            extra={
              <Row>
                <b>AUM</b>
                <Tooltip
                  title={custodyWallets
                    .reduce((accumulator: any, element: any) => {
                      if (!element.isActive || !element.isReport)
                        return accumulator;
                      return assetPrices[element.assetId] &&
                        element.displayBalance
                        ? accumulator +
                            element.displayBalance *
                              assetPrices[element.assetId]
                        : accumulator;
                    }, 0)
                    .toLocaleString("en-us", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 8,
                    })}
                >
                  {`:  ${
                    custodyWallets && custodyWallets.length
                      ? custodyWallets
                          .reduce((accumulator: any, element: any) => {
                            if (!element.isActive || !element.isReport)
                              return accumulator;
                            return assetPrices[element.assetId] &&
                              element.displayBalance
                              ? accumulator +
                                  element.displayBalance *
                                    assetPrices[element.assetId]
                              : accumulator;
                          }, 0)
                          .toLocaleString("en-us", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                      : "$0.00"
                  }`}
                </Tooltip>
              </Row>
            }
            bordered={false}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
          >
            {custodyWallets && custodyWallets.length ? (
              <Table
                columns={subWalletColumns}
                scroll={{ x: "1000px" }}
                dataSource={custodyWallets}
                pagination={{ pageSize: 100, hideOnSinglePage: true }}
                rowKey={"id"}
              />
            ) : (
              <b>No Custody Subwallets</b>
            )}
          </Card>
        </Col>
        <Col xxl={{ span: 22 }} lg={{ span: 22 }} md={{ span: 24 }}>
          <Card
            title={
              <Row>
                <Col span={8}>
                  Staking Subwallets (
                  {stakingWallets ? stakingWallets.length : 0})
                </Col>
              </Row>
            }
            extra={
              <Row>
                <b>AUS</b>
                <Tooltip
                  title={`${
                    stakingWallets && stakingWallets.length
                      ? stakingWallets
                          .reduce((accumulator: any, element: any) => {
                            if (!element.isActive || !element.isReport)
                              return accumulator;
                            return assetPrices[element.assetId]
                              ? accumulator +
                                  element.displayBalance *
                                    assetPrices[element.assetId]
                              : accumulator;
                          }, 0)
                          .toLocaleString("en-us", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 8,
                          })
                      : "$0.00"
                  }`}
                >
                  {`:  ${
                    stakingWallets && stakingWallets.length
                      ? stakingWallets
                          .reduce((accumulator: any, element: any) => {
                            if (!element.isActive || !element.isReport)
                              return accumulator;
                            return assetPrices[element.assetId]
                              ? accumulator +
                                  element.displayBalance *
                                    assetPrices[element.assetId]
                              : accumulator;
                          }, 0)
                          .toLocaleString("en-us", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                      : "$0.00"
                  }`}
                </Tooltip>
              </Row>
            }
            bordered={false}
            style={{
              margin: "10px",
              borderRadius: "10px",
            }}
          >
            {stakingWallets && stakingWallets.length ? (
              <Table
                columns={subWalletColumns}
                scroll={{ x: "1000px" }}
                dataSource={stakingWallets}
                pagination={{ pageSize: 100, hideOnSinglePage: true }}
                rowKey={"id"}
              />
            ) : (
              <b>No Staking Subwallets</b>
            )}
          </Card>
        </Col>
      </Row>
      <Divider />
      <Row justify="center" id="wallet-info-bottom">
        {displayBalanceData ? (
          <Col xxl={{ span: 22 }} lg={{ span: 22 }} md={{ span: 24 }}>
            <Card
              title={<b>Balance - {subWalletLabel ? subWalletLabel : ""}</b>}
              bordered={false}
              style={{
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Table
                scroll={{ y: "1000px" }}
                loading={!balanceDataLoaded || balanceLoading}
                dataSource={balanceData}
                columns={balanceColumns}
                pagination={{ hideOnSinglePage: true, defaultPageSize: 100 }}
                rowKey={"id"}
              />
              <Row justify={"end"}>
                <Pagination
                  defaultPageSize={5}
                  showSizeChanger
                  pageSizeOptions={[5, 10, 50]}
                  onChange={(page, pageSize) => {
                    fetchWalletBalances(selectedSubWalletId, page, pageSize);
                  }}
                  total={balancePageTotal}
                />
              </Row>
            </Card>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row justify="center" id="wallet-info-bottom">
        {displayTxnData ? (
          <Col xxl={{ span: 22 }} lg={{ span: 22 }} md={{ span: 24 }}>
            <Card
              title={
                <b>Transactions - {subWalletLabel ? subWalletLabel : ""}</b>
              }
              bordered={false}
              style={{
                margin: "10px",
                borderRadius: "10px",
              }}
            >
              <Table
                scroll={{ y: "1000px" }}
                loading={!txnDataLoaded || txnLoading}
                dataSource={txnData}
                columns={txnColums}
                pagination={{ hideOnSinglePage: true, defaultPageSize: 100 }}
                rowKey={"id"}
              />
              <Row justify={"end"}>
                <Pagination
                  defaultPageSize={5}
                  showSizeChanger
                  pageSizeOptions={[5, 10, 50]}
                  onChange={(page, pageSize) => {
                    fetchWalletTxns(selectedSubWalletId, page, pageSize);
                  }}
                  total={txnPageTotal}
                />
              </Row>
            </Card>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
export default WalletInfo;
