import { Tabs } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustodyTransaction from "../CustodyPages/CustodyTransaction";
import ViewEBankingLedger from "../EbankingPages/ViewEBankingLedger";
import SubaccountTransferList from "../VenuePages/SubAccountTransferPages/SubaccountTransferList";
import ViewVenueLedgers from "../VenuePages/ViewVenueTransactionTabs/ViewVenueLedgers";
import ViewVenueOrders from "../VenuePages/ViewVenueTransactionTabs/ViewVenueOrders";

export default function OperationBuilder() {
  const { tabName = "orders" } = useParams();
  const [canCreateSubaccountTransfer, setCanCreateSubaccountTransfer] =
    useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      ![
        "account-transfer",
        "custody",
        "ebanking-ledgers",
        "ledgers",
        "orders",
      ].includes(tabName)
    ) {
      navigate("/bo/operation/builder");
    }
  }, [navigate, tabName]);
  return (
    <Content id="operation-builer">
      <Tabs
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/operation/builder/" + key);
        }}
        activeKey={tabName}
        items={[
          {
            label: "Venue Order",
            key: "orders",
            children: (
              <React.Fragment>
                <ViewVenueOrders tabName={tabName} />
              </React.Fragment>
            ),
          },
          {
            label: "Venue Ledger",
            key: "ledgers",
            children: (
              <React.Fragment>
                <ViewVenueLedgers tabName={tabName} />
              </React.Fragment>
            ),
          },
          {
            label: "Ebanking Ledger",
            key: "ebanking-ledgers",
            children: (
              <React.Fragment>
                <ViewEBankingLedger />
              </React.Fragment>
            ),
          },
          {
            label: "Venue Account Transfer",
            key: "account-transfer",
            children: (
              <React.Fragment>
                <SubaccountTransferList
                  canCreateSubaccountTransfer={canCreateSubaccountTransfer}
                  setCanCreateSubaccountTransfer={
                    setCanCreateSubaccountTransfer
                  }
                />
              </React.Fragment>
            ),
          },
          {
            label: "Custody",
            key: "custody",
            children: (
              <React.Fragment>
                <CustodyTransaction />
              </React.Fragment>
            ),
          },
        ]}
      />
    </Content>
  );
}
