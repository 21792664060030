// import { MinusCircleFilled, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Slider,
  Switch,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formItemLayout, tailFormItemLayout } from "../UserPages/CreateUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CreateClient() {
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [countryList, setCountryList] = useState([]);
  const [clientTypeList, setClientTypeList] = useState([]);
  const [businessActivityList, setBusinessActivityList] = useState([]);
  const [deltecRMList, setDeltecRMList] = useState([]);

  const [custodyFeeTypeId, setCustodyFeeTypeId] = useState<number>();
  const [stakingFeeTypeId, setStakingFeeTypeId] = useState<number>();
  const [tradingFeeTypeId, setTradingFeeTypeId] = useState<number>();
  const [custodyeePolicyList, setCustodyFeePolicyList] = useState<any[]>();
  const [stakingFeePolicyList, setStakingFeePolicyList] = useState<any[]>();
  const [tradingFeePolicyList, setTradingFeePolicyList] = useState<any[]>();

  const fetchFeePolicyList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "custody" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setCustodyFeePolicyList(res.data.feePolicyList);
      setCustodyFeeTypeId(res.data.feePolicyTypeId);
    });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "staking" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setStakingFeePolicyList(res.data.feePolicyList);
      setStakingFeeTypeId(res.data.feePolicyTypeId);
    });
    // .finally(() => {
    //   setOnFinishLoad(true);
    // });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "trading" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setTradingFeePolicyList(res.data.feePolicyList);
      setTradingFeeTypeId(res.data.feePolicyTypeId);
    });
    // .finally(() => {
    //   setOnFinishLoad(true);
    // });
  }, []);

  useEffect(() => {
    fetchFeePolicyList();
  }, [fetchFeePolicyList]);

  const onFinish = (form: any) => {
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/client/create",
      withCredentials: true,
      data: {
        clientName: form.clientName,
        email: form.email,
        countryId: form.country,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        city: form.city,
        state: form.state,
        postcode: form.postcode,
        clientTypeId: form.clientType,
        businessActivityDspTermId: form.businessActivityDspTermId,
        telegramGroup: form.telegramGroup,
        clientRanking: form.clientRanking,
        clientComment: form.clientComment,
        delchainAccount: form.delchainAccount,
        deltecAccount: form.deltecAccount,
        isBot: form.isBot,
        toBackup: form.toBackup,
        custodyFeePolicyId: form.custodyFeePolicyId,
        custodyFeePolicyTypeId: custodyFeeTypeId,
        custodyFeePolicyStartDate: form.custodyFeePolicyStartDate,
        rmDspTermId: form.rmDspTermId,
        stakingFeePolicyTypeId: stakingFeeTypeId,
        stakingFeePolicyId: form.stakingFeePolicyId,
        stakingFeePolicyStartDate: form.stakingFeePolicyStartDate,
        tradingFeePolicyTypeId: tradingFeeTypeId,
        tradingFeePolicyId: form.tradingFeePolicyId,
        tradingFeePolicyStartDate: form.tradingFeePolicyStartDate,
      },
    })
      .then((res) => {
        navigate("/bo/confirmation", {
          state: {
            pageType: "Client",
            createOrUpdate: "Create",
            displayName: form.clientName,
            createUrl: "/bo/client/create",
            editUrl:
              "/bo/client/update/" + encodeURIComponent(res.data.client.id),
          },
        });
        toast.success("Client Created Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
        toast.error(err.response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    toast.error("Please fill required fields ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/country",
      withCredentials: true,
    })
      .then((res) => {
        setCountryList(res.data);
      })
      .catch(() => {
        toast.error("Unable to fetch the country list", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-type",
      withCredentials: true,
    })
      .then((res) => {
        setClientTypeList(res.data);
      })
      .catch(() => {
        console.error("Unable to fetch the Client Type list");
      });

    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/dsp-terms/business-activity",
      withCredentials: true,
    })
      .then((res) => {
        res.data.businessActivities.forEach((t: any) => (t.name = t.value));
        setBusinessActivityList(res.data.businessActivities);
      })
      .catch(() => {
        toast.error("Unable to fetch the Business Activities list", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/dsp-terms/deltec-rms",
      withCredentials: true,
    })
      .then((res) => {
        res.data.deltecRMs.forEach((t: any) => (t.name = t.value));
        setDeltecRMList(res.data.deltecRMs);
      })
      .catch(() => {
        toast.error("Unable to fetch the Deltec RMs list", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }, []);

  return (
    <React.Fragment>
      <Content>
        <Row>
          <Col md={{ span: 16 }} sm={{ span: 16 }}>
            <Button
              onClick={() => {
                if (location.state) {
                  navigate(location.state.from);
                } else {
                  navigate("/bo/clients");
                }
              }}
            >
              <FontAwesomeIcon
                icon={"fa-solid fa-circle-chevron-left" as IconProp}
              />
            </Button>
          </Col>
        </Row>
        <Form
          form={form}
          {...formItemLayout}
          labelWrap
          autoComplete="off"
          onFinish={onFinish}
          labelCol={{
            sm: {
              span: 6,
              offset: 2,
            },
          }}
          wrapperCol={{
            sm: {
              span: 12,
              offset: 1,
            },
          }}
          onFinishFailed={onFinishFailed}
          className="form-body"
          style={{
            padding: "1em",
            margin: "auto",
          }}
        >
          <Row>
            <Col sm={{ span: 12, order: 1 }} xs={{ span: 24, order: 1 }}>
              <Form.Item
                label="Client Name"
                name="clientName"
                rules={[
                  {
                    required: true,
                    message: "Please input Client's Name!",
                  },
                ]}
              >
                <Input placeholder="Please enter client's name" />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: false, // Set to false for launching
                    message: "Please input Client's email",
                  },
                ]}
              >
                <Input placeholder="Please enter client's email" />
              </Form.Item>

              <Form.Item
                label="Address Line 1"
                name="addressLine1"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please input client's address!",
                  },
                ]}
              >
                <Input placeholder="Please enter client's address" />
              </Form.Item>
              <Form.Item
                label="Address Line 2"
                name="addressLine2"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Postcode"
                name="postcode"
                rules={[{ required: false }]}
              >
                <Input placeholder="Please enter postcode" />
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please input client's city!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: false,
                    message: "Please input client's state!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Country"
                name="country"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please select user's Country",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {countryList.map((country) => (
                    <Select.Option
                      value={country["id"]}
                      key={country["id"]}
                      id={country["id"]}
                    >
                      {country["nicename"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Telegram Group (e.g. -123456789)"
                name="telegramGroup"
                rules={[
                  {
                    required: false,
                    message: "Please select user's Country",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="To Backup"
                name="toBackup"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Bot is in Group"
                name="isBot"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Client Type"
                name="clientType"
                rules={[
                  {
                    required: false, // Set to false for launching
                    message: "Please select client's type",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {clientTypeList.map((clientType) => (
                    <Select.Option
                      value={clientType["id"]}
                      key={clientType["id"]}
                      id={clientType["id"]}
                    >
                      {clientType["name"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Business Activity"
                name="businessActivityDspTermId"
                rules={[
                  {
                    required: false,
                    message: "Please select client's business activity",
                  },
                ]}
              >
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder={"Business activity"}
                >
                  {businessActivityList.map((businessActivity) => (
                    <Select.Option
                      value={businessActivity["id"]}
                      key={businessActivity["id"]}
                      id={businessActivity["id"]}
                    >
                      {businessActivity["name"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Client Ranking" name="clientRanking">
                <Slider
                  min={0}
                  max={10}
                  marks={{
                    0: 0,
                    2: 2,
                    4: 4,
                    6: 6,
                    8: 8,
                    10: 10,
                  }}
                />
              </Form.Item>
              <Form.Item label="Comment" name="clientComment">
                <TextArea rows={3} />
              </Form.Item>
            </Col>
            <Col sm={{ span: 12, order: 2 }} xs={{ span: 24, order: 2 }}>
              <Form.Item label="Deltec Account" name="deltecAccount">
                <Input placeholder="Deltec Account" />
              </Form.Item>
              <Form.Item
                label="Delchain Account"
                name="delchainAccount"
                rules={[
                  {
                    required: false,
                    message: "Please input client's Delchain account",
                  },
                ]}
              >
                <Input placeholder="Delchain Account" />
              </Form.Item>
              <Form.Item label="Deltec RM" name="rmDspTermId">
                <Select
                  showSearch
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  allowClear
                  placeholder={"Deltec RM"}
                >
                  {deltecRMList.map((deltecRM) => (
                    <Select.Option
                      value={deltecRM["id"]}
                      key={deltecRM["id"]}
                      id={deltecRM["id"]}
                    >
                      {deltecRM["name"]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left">Fee Policy</Divider>
          <Row>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Card title="Custody">
                <Form.Item label="Custody Fee Policy" name="custodyFeePolicyId">
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={custodyeePolicyList}
                    placeholder="Custody Fee Policy"
                  />
                </Form.Item>
                <Form.Item
                  label="Custody Fee Policy Start Date"
                  name="custodyFeePolicyStartDate"
                >
                  <DatePicker />
                </Form.Item>
              </Card>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Card title="Staking">
                <Form.Item label="Staking Fee Policy" name="stakingFeePolicyId">
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={stakingFeePolicyList}
                    placeholder="Staking Fee Policy"
                  />
                </Form.Item>
                <Form.Item
                  label="Staking Fee Policy Start Date"
                  name="stakingFeePolicyStartDate"
                >
                  <DatePicker />
                </Form.Item>
              </Card>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <Card title="Trading">
                <Form.Item label="Trading Fee Policy" name="tradingFeePolicyId">
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    options={tradingFeePolicyList}
                    placeholder="Ttaking Fee Policy"
                  />
                </Form.Item>
                <Form.Item
                  label="Ttaking Fee Policy Start Date"
                  name="tradingFeePolicyStartDate"
                >
                  <DatePicker />
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <Form.Item {...tailFormItemLayout}>
            <Button htmlType="submit" className="submit-button primary-button">
              Create Client
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </React.Fragment>
  );
}
export default CreateClient;
