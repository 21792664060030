import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import dayjs from "dayjs";
import "../../../../assets/scss/trading-chart.scss";

interface TradeData {
  name: string;
  tradeCount: number;
  tradeVolume: number;
}

interface TradeChartProps {
  data: TradeData[];
  yearNum: string;
  viewType: string;
}

const TradeReportChart: React.FC<TradeChartProps> = ({
  data,
  yearNum,
  viewType,
}) => {
  const [containerWidth, setContainerWidth] = useState<number>(800);
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (!svgRef.current) return;
    const minSvgWidth = 800;

    const svg = d3.select(svgRef.current);
    const container = svgRef.current?.parentElement;
    // Define chart dimensions and margins
    const margin = {
      top: 60,
      right: 30,
      bottom: viewType === "Weekly View" ? 60 : 80,
      left: 40,
    };
    let width =
      container?.clientWidth && container?.clientWidth < containerWidth
        ? container?.clientWidth - margin.left - margin.right
        : containerWidth - margin.left - margin.right;
    width = Math.max(minSvgWidth, width);
    setContainerWidth(width + margin.left + margin.right);
    const height = 800 - margin.top - margin.bottom;

    // Create scales for X and Y axes
    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.name))
      .range([0, width])
      .padding(0.1);

    const maxTradeCount = d3.max(data, (d) => d.tradeCount) || 0;

    const y = d3
      .scaleLinear()
      .domain([0, maxTradeCount === 0 ? 5 : maxTradeCount]) // Ensure Y-axis starts at 0
      .nice()
      .range([height, 0]);

    // Create the chart
    const chart = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    chart.select(".y-axis").call(d3.axisLeft(y) as any);

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", 30)
      .attr("text-anchor", "middle")
      .style("font-size", "32px")
      .text(`# of Orders (${viewType})`);

    // Add X-axis
    chart
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("class", "x-axis-label"); // Apply the CSS class

    // Add Y-axis
    chart.append("g").attr("class", "y-axis").call(d3.axisLeft(y).ticks(5));
    // Create and style bars with hover effect and Y-axis values
    const bars = chart
      .selectAll(".bar")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "bar-group");

    bars
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.name) || 0)
      .attr("y", (d) => y(d.tradeCount) || 0)
      .attr("width", x.bandwidth())
      .attr("height", (d) => height - (y(d.tradeCount) || 0))
      .attr("class", "bar")
      .attr("style", "fill: #19bdc2")
      .on("mouseover", function (e, d) {
        d3.select(this).attr("style", "fill: orange");
        let exactDate, startDate, endDate;
        if (viewType === "Weekly View") {
          exactDate = dayjs()
            .set("y", Number(yearNum))
            .startOf("year")
            .add(Number(d.name.split(" ")[1]) - 1, "weeks")
            .format("YYYY-MM-DD");
          startDate = dayjs(exactDate).startOf("w").format("MMM Do, YYYY");
          endDate = dayjs(exactDate).endOf("w").format("MMM Do, YYYY");
        } else {
          exactDate = dayjs(
            `01-${d.name.split(" ")[1]}-${yearNum}`,
            "DD-MMMM-YYYY",
          );
          startDate = dayjs(exactDate).startOf("M").format("MMM Do, YYYY");
          endDate = dayjs(exactDate).endOf("M").format("MMM Do, YYYY");
        }
        d3.select("#tooltip")
          .html(
            `<b>${viewType === "Weekly View" ? "Week" : ""} ${
              d.name.split(" ")[1]
            }<br />Exact Date: ${startDate} - ${endDate}<br />Order Count: ${
              d.tradeCount
            }<br/> USD Notional: $${d.tradeVolume.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}</b>`,
          )
          .style("visibility", "visible");
      })
      .on("mousemove", (event) => {
        // Update tooltip position
        d3.select("#tooltip")
          .style("top", event.pageY - 200 + "px")
          .style("left", event.pageX - 180 + "px");
      })
      .on("mouseout", function () {
        d3.select(this).attr("style", "fill: #19bdc2");
        d3.select("#tooltip").style("visibility", "hidden");
      }); // Apply the CSS class

    bars
      .append("text")
      .attr("class", "bar-label")
      .attr("x", (d) => (x(d.name) as number) + x.bandwidth() / 2)
      .attr("y", (d) => y(d.tradeCount) - 5) // Adjust the y position to display above the bar
      .attr("text-anchor", "middle")
      .text((d) => d.tradeCount);

    if (width < 600) {
      svg
        .selectAll("text:not(.chart-title)") // Selects all text elements except the title
        .style("font-size", "9px"); // Sets the font size to 8px
    }
    // Cleanup on unmount
    return () => {
      svg.selectAll("*").remove();
    };
  }, [viewType, yearNum, data, containerWidth]);

  // Handle window resize to update container width
  useEffect(() => {
    const handleResize = () => {
      const container = svgRef.current?.parentElement;
      if (container) {
        setContainerWidth(container.clientWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="trade-chart" style={{ overflowX: "auto" }}>
      <svg ref={svgRef} width={containerWidth} height={"800"}></svg>
      <div
        className="tooltip-container"
        id="tooltip"
        style={{ position: "absolute", visibility: "hidden", height: "100px" }}
      ></div>
    </div>
  );
};

export default TradeReportChart;
