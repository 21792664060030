import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function ViewSubWallets({ filter }: any) {
  const navigate = useNavigate();
  const location = useLocation();
  //   const displayContext = useContext(DisplayContext);
  const [tableData, setTableData] = useState<any[]>();
  const [onFinishLoaded, setOnFinishLoaded] = useState<boolean>(false);
  const [editDisabled, setEditDisabled] = useState<boolean>(true);
  const [tableLength, setTableLength] = useState<number>(0);
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
  });

  const initialColumns = [
    {
      title: "Client Name",
      dataIndex: "clientName",
      width: 100,
      render: (_: any, { clientId, clientName }: any) =>
        clientId ? (
          <Link
            to={"/bo/client/info/" + encodeURIComponent(clientId)}
            state={{
              from: window.location.pathname,
              clientIds: filterForm.getFieldValue("clientIds"),
              walletAddress: filterForm.getFieldValue("address"),
              assetIds: filterForm.getFieldValue("assetIds"),
              walletTypeIds: filterForm.getFieldValue("walletTypeIds"),
              isActive: filterForm.getFieldValue("isActive"),
              pagination: pagination,
            }}
          >
            {clientName}
          </Link>
        ) : (
          clientName
        ),
    },
    { title: "Subaccount", dataIndex: "clientAccount", width: 80 },
    {
      title: "Asset",
      dataIndex: "assetName",
      width: 100,
    },
    { title: "Label", dataIndex: "label", width: 80 },
    {
      title: "Wallet Address",
      dataIndex: "walletAddress",
      width: 150,
      render: (_: any, { walletAddress, walletId }: any) => (
        <Link
          to={"/bo/wallet/view/" + encodeURIComponent(walletId)}
          state={{
            from: window.location.pathname,
            clientIds: filterForm.getFieldValue("clientIds"),
            walletAddress: filterForm.getFieldValue("address"),
            assetIds: filterForm.getFieldValue("assetIds"),
            walletTypeIds: filterForm.getFieldValue("walletTypeIds"),
            isActive: filterForm.getFieldValue("isActive"),
            isPlaceholder: filterForm.getFieldValue("isPlaceholder"),
            isReport: filterForm.getFieldValue("isReport"),
            pagination: pagination,
          }}
        >
          {walletAddress}
        </Link>
      ),
    },
    { title: "Wallet Type", dataIndex: "walletTypeName", width: 80 },
    {
      title: "Quantity",
      dataIndex: "balance",
      width: 100,
      align: "right" as const,
      render: (_: any, { balance }: any) => (
        <Space className="dcl-active">
          <Tooltip
            title={`${balance.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}`}
          >
            {balance.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      width: 100,
      align: "right" as const,
      render: (_: any, { price }: any) => (
        <Space className="dcl-active">
          <Tooltip
            title={`${price.toLocaleString("en-us", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}`}
          >
            {price.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "USD Value",
      dataIndex: "usdValue",
      width: 100,
      align: "right" as const,
      render: (_: any, { usdValue }: any) => (
        <Space className="dcl-active">
          <Tooltip
            title={`${usdValue.toLocaleString("en-us", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}`}
          >
            {usdValue.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      width: 70,
      render: (_: any, { isActive }: any) => (
        <Space className="dcl-active">
          <Switch defaultChecked={isActive} disabled />
        </Space>
      ),
    },

    {
      title: "In Report",
      dataIndex: "isReport",
      width: 70,
      render: (_: any, { isReport }: any) => (
        <Space className="dcl-active">
          <Switch defaultChecked={isReport} disabled />
        </Space>
      ),
    },

    {
      title: "Is Placeholder",
      dataIndex: "isPlaceholder",
      width: 70,
      render: (_: any, { isPlaceholder }: any) => (
        <Space className="dcl-active">
          <Switch defaultChecked={isPlaceholder} disabled />
        </Space>
      ),
    },

    {
      title: "Edit",
      dataIndex: "edit",
      width: 50,
      fixed: "right" as const,
      render: (_: any, { walletId }: any) => (
        <Button
          disabled={editDisabled}
          onClick={() => {
            const url = "/bo/wallet/update/" + encodeURIComponent(walletId);
            navigate(url, {
              state: {
                from: window.location.pathname,
                clientIds: filterForm.getFieldValue("clientIds"),
                walletAddress: filterForm.getFieldValue("address"),
                assetIds: filterForm.getFieldValue("assetIds"),
                walletTypeIds: filterForm.getFieldValue("walletTypeIds"),
                isActive: filterForm.getFieldValue("isActive"),
                pagination: pagination,
              },
            });
          }}
        >
          Edit
        </Button>
      ),
    },
  ];
  const [filterForm] = Form.useForm();
  const [showFilter, setShowFilter] = useState<boolean>(filter ? true : false);
  const [clientOptions, setClientOptions] = useState<any[]>();
  const [assetOptions, setAssetOptions] = useState<any[]>();
  //   const [venueOptions, setVenueOptions] = useState<any[]>();
  const [walletTypeOptions, setWalletTypeOptions] = useState<any[]>();
  const [clientAccountOptions, setClientAccountOptions] = useState<any[]>();

  const [isExportingCSV, setIsExportingCSV] = useState<boolean>(false);

  const onReset = () => {
    fetchData();
    filterForm.resetFields();
  };

  const filterWallets = useCallback((form: any, pagination: any) => {
    // console.log(form);
    setPagination(pagination);
    setOnFinishLoaded(false);
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/sub-wallets",
      withCredentials: true,
      data: {
        ...form,
        pagination: pagination,
      },
    })
      .then((res) => {
        setTableData(res.data.subWallets);
        setTableLength(res.data.count);
        if (res.data.canEdit) {
          setEditDisabled(false);
        }
      })
      .finally(() => {
        setOnFinishLoaded(true);
      });
  }, []);

  const getClientAccountsList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-accounts",
      withCredentials: true,
    })
      .then((res) => {
        setClientAccountOptions(res.data.clientAccounts);
      })
      .catch(() => {
        console.log("Unable to fetch the wallet type list");
      });
  }, []);

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        res.data.clients.forEach(
          (c: any) => (c.label = `${c.label} (${c.deltecAccount})`),
        );
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  const getAssetOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    })
      .then((res) => {
        const options = [];
        for (const asset of res.data.assetList) {
          options.push({
            label: `${asset.name} (${asset.ticker})`,
            value: asset.id,
          });
        }
        setAssetOptions(options);
      })
      .catch(() => {
        console.log("Unable to fetch the asset list");
      });
  }, []);

  const getWalletTypes = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/wallet-type",
      withCredentials: true,
    })
      .then((res) => {
        setWalletTypeOptions(res.data.walletTypes);
      })
      .catch(() => {
        console.log("Unable to fetch the wallet type list");
      });
  }, []);

  const fetchSelectOptions = useCallback(() => {
    getClientOptions();
    getAssetOptions();
    getClientAccountsList();
    // getVenueList();
    getWalletTypes();
  }, [
    getAssetOptions,
    getWalletTypes,
    getClientOptions,
    getClientAccountsList,
  ]);

  const exportCSV = useCallback(() => {
    setIsExportingCSV(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/sub-wallets/export-csv",
      withCredentials: true,
      data: {
        wallets: tableData,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_sub_wallets.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsExportingCSV(false);
      });
  }, [tableData]);

  useEffect(() => {
    fetchSelectOptions();
  }, [fetchSelectOptions]);

  const fetchData = useCallback(
    (paginationParams = { current: 1, pageSize: 10 }) => {
      setOnFinishLoaded(false);
      axios({
        method: "Post",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/sub-wallets",
        data: {
          pagination: paginationParams,
        },
        withCredentials: true,
      })
        .then((res) => {
          setTableData(res.data.subWallets);
          setTableLength(res.data.count);
          if (res.data.canEdit) {
            setEditDisabled(false);
          }
        })
        .finally(() => {
          setOnFinishLoaded(true);
        });
    },
    [],
  );
  useEffect(() => {
    if (tableData) return;
    if (location.state) {
      filterForm.setFieldsValue(location.state);
      setShowFilter(true);
      setOnFinishLoaded(false);
      axios({
        method: "Post",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/sub-wallets",
        withCredentials: true,
        data: {
          ...location.state,
        },
      })
        .then((res) => {
          setTableData(res.data.subWallets);
          setTableLength(res.data.count);
          if (res.data.canEdit) {
            setEditDisabled(false);
          }
        })
        .finally(() => {
          if (location.state.pagination)
            setPagination(location.state.pagination);
          setOnFinishLoaded(true);
        });
    } else {
      fetchData();
    }
  }, [tableData, location, filterForm, fetchData]);
  return (
    <Content id="view-wallets">
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Space>
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <FontAwesomeIcon icon={"fa-solid fa-filter" as IconProp} />
              Filters
              {showFilter ? (
                <FontAwesomeIcon icon={"fa-solid fa-caret-up" as IconProp} />
              ) : (
                <FontAwesomeIcon icon={"fa-solid fa-caret-down" as IconProp} />
              )}
            </Button>
          </Space>
          <Form
            title="Filter"
            form={filterForm}
            onFinish={() => {
              filterWallets(filterForm.getFieldsValue(), {
                ...pagination,
                current: 1,
              });
            }}
            hidden={!showFilter}
            className="dcl-toggled-content dcl-toggled-content-filter"
          >
            <Row>
              <Form.Item name="clientIds" className="dcl-filter-item">
                <Select
                  className="dcl-client-select"
                  placeholder="Client"
                  options={clientOptions}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="clientAccounts" className="dcl-filter-item">
                <Select
                  placeholder="Subaccounts"
                  className="dcl-client-account-select"
                  options={clientAccountOptions}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>

              <Form.Item name="address" className="dcl-filter-item">
                <Input placeholder="Wallet Address" />
              </Form.Item>
              <Form.Item name="assetIds" className="dcl-filter-item">
                <Select
                  className="dcl-asset-select"
                  placeholder="Asset Ticker"
                  options={assetOptions}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="walletTypeIds" className="dcl-filter-item">
                <Select
                  className="dcl-wallet-type-select"
                  placeholder="Wallet Type"
                  options={walletTypeOptions}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option: any) => {
                    return option.label
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                />
              </Form.Item>
              <Form.Item name="isReport" className="dcl-filter-item">
                <Select
                  placeholder="In Report"
                  options={[
                    {
                      label: "True",
                      value: "true",
                    },
                    { label: "False", value: "false" },
                    {
                      label: "All",
                      value: "all",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="isActive" className="dcl-filter-item">
                <Select
                  className="dcl-status-select"
                  placeholder="Status"
                  options={[
                    {
                      label: "Active",
                      value: "active",
                    },
                    { label: "Inactive", value: "inactive" },
                    {
                      label: "All",
                      value: "all",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="isPlaceholder" className="dcl-filter-item">
                <Select
                  placeholder="Is Placeholder"
                  options={[
                    {
                      label: "True",
                      value: "true",
                    },
                    { label: "False", value: "false" },
                    {
                      label: "All",
                      value: "all",
                    },
                  ]}
                />
              </Form.Item>
            </Row>
            <Row justify="end">
              <Space>
                <b>{tableLength} Subwallets</b>
                <Button htmlType="submit" className="ant-btn-primary">
                  Apply
                </Button>
                <Button onClick={onReset}>Reset</Button>
              </Space>
            </Row>
          </Form>
        </Col>
        <Col md={{ span: 8 }} sm={{ span: 8 }}>
          <Row justify="end">
            <Button
              className="dcl-btn-toggle"
              disabled={!onFinishLoaded}
              onClick={exportCSV}
              loading={isExportingCSV}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>

      <Table
        pagination={{
          ...pagination,
          total: tableLength,
          pageSizeOptions: [10, 50, 100],
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            filterWallets(filterForm.getFieldsValue(), {
              current: page,
              pageSize,
            });
          },
        }}
        sticky
        rowKey="id"
        loading={!onFinishLoaded}
        columns={initialColumns}
        scroll={{ x: 1500 }}
        showSorterTooltip={false}
        dataSource={tableData}
      />
    </Content>
  );
}
