import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  Button,
  Checkbox,
  ConfigProvider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CreateTelegramBroadcastGroup() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editOrCreate, setEditOrCreate] = useState<string>("");
  const [clientOptions, setClientOptions] = useState<any[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [telegramBroadcastGroups, setTelegramBroadcastGroups] = useState<any>();
  const [clientGroupSelected, setClientGroupSelected] = useState<any>();
  const [fileName, setFileName] = useState<string>("");
  const [clientList, setClientList] = useState<any>([]);
  const [groupDescription, setGroupDescription] = useState<string>("");

  const getTelegramBroadcastGroups = useCallback(() => {
    setIsLoading(true);
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/telegram-broadcast-group-list",
      withCredentials: true,
    })
      .then((res) => {
        setTelegramBroadcastGroups(res.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [navigate]);

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      params: {
        onlyWithTelegram: JSON.stringify(true),
      },
      withCredentials: true,
    })
      .then((res) => {
        res.data.clients.forEach(
          (c: any) => (c.label = `${c.label} (${c.deltecAccount})`)
        );
        setClientOptions(res.data.clients);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          navigate("/login");
        }
      });
  }, [navigate]);

  const handleFileNameChange = (e: any) => {
    const { value: newValue } = e.target;
    // Regular expression to match only alphanumeric characters and hyphen
    const regex = /^[A-Za-z0-9-]*$/;

    if (regex.test(newValue)) {
      setFileName(newValue);
    }
  };

  const resetInputFields = useCallback(() => {
    setShowModal(false);
    setEditOrCreate("");
    setFileName("");
    setClientList([]);
    setClientGroupSelected(undefined);
    setGroupDescription("");
  }, []);
  const submitData = useCallback(() => {
    if (!editOrCreate) return;
    if (editOrCreate === "edit") {
      axios({
        method: "Put",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/telegram-broadcast-group-list",
        withCredentials: true,
        data: {
          clientGroupId: clientGroupSelected,
          fileName: fileName.trim(),
          clientIdList: clientList,
          description: groupDescription,
        },
      })
        .then(() => {
          toast.success("Updated!");
          getTelegramBroadcastGroups();
          resetInputFields();
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          } else {
            console.error(err);
            toast.error("Failed to update client group");
          }
        });
    } else if (editOrCreate === "create") {
      axios({
        method: "Post",
        url:
          process.env.REACT_APP_AWS_BACKEND_URL +
          "/util/telegram-broadcast-group-list",
        withCredentials: true,
        data: {
          fileName: fileName.trim(),
          clientIdList: clientList,
          description: groupDescription,
        },
      })
        .then(() => {
          toast.success("Created!");
          getTelegramBroadcastGroups();
          resetInputFields();
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          } else {
            console.error(err);
            toast.error("Failed to update client group");
          }
        });
    }
  }, [
    clientGroupSelected,
    clientList,
    editOrCreate,
    fileName,
    groupDescription,
    getTelegramBroadcastGroups,
    navigate,
    resetInputFields,
  ]);

  const columns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      width: "200px",
      render: (_: any, { fileName }: any) => (
        <React.Fragment>{fileName}</React.Fragment>
      ),
    },
    {
      title: "Client List",
      dataIndex: "clientIdList",
      width: "140px",
      render: (_: any, { clientIdList }: any) => (
        <Tooltip
          title={clientIdList.map((id: any, index: number) => (
            <li key={index}>
              <b>
                {index + 1}.{" "}
                {clientOptions?.find((row) => row.value === id)?.label}
              </b>
            </li>
          ))}
        >
          <i className="dcl-info">i</i>
          {clientIdList && clientIdList.length ? clientIdList.length : 0}
          &nbsp;client(s)
        </Tooltip>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "300px",
    },
    {
      title: "Last Updated By",
      dataIndex: "lastUpdatedBy",
    },
    {
      title: "Waiting to be Created",
      dataIndex: "toCreate",
      width: "100px",
      render: (_: any, { toCreate }: any) => (
        <Checkbox checked={toCreate} disabled />
      ),
    },
    {
      title: "Created on EC2",
      dataIndex: "isCreated",
      width: "100px",
      render: (_: any, { isCreated }: any) => (
        <Checkbox checked={isCreated} disabled />
      ),
    },
    {
      title: "Edit",
      dataIndex: "id",
      width: "150px",
      render: (_: any, { id, fileName, description, clientIdList }: any) => (
        <Button
          onClick={() => {
            setClientGroupSelected(id);
            setShowModal(true);
            setClientList(clientIdList);
            setFileName(fileName);
            setGroupDescription(description);
            setEditOrCreate("edit");
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getClientOptions();
    getTelegramBroadcastGroups();
  }, [getClientOptions, getTelegramBroadcastGroups]);

  return (
    <Content id="create-telegram-broadcast-group">
      <Row justify={"center"}>
        <h2 style={{ color: "black" }}>Telegram Broadcast Group</h2>
      </Row>
      <Row justify={"end"} style={{ margin: "20px" }}>
        <Button onClick={getTelegramBroadcastGroups} loading={isLoading}>
          Refresh
        </Button>
        <Button
          type="primary"
          onClick={() => {
            setShowModal(true);
            setEditOrCreate("create");
          }}
          disabled={isLoading}
        >
           <FontAwesomeIcon
            icon={"fa-solid fa-plus" as IconProp}
            className="white-plus"
          />
          &nbsp; New Group
        </Button>
      </Row>
      <Table
        columns={columns}
        dataSource={telegramBroadcastGroups}
        sticky
        scroll={{ x: 1100 }}
        rowKey="id"
        loading={isLoading}
      />
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelColor: "white",
            },
          },
        }}
      >
        <Modal
          open={showModal}
          closeIcon={false}
          footer={
            <Row justify={"end"}>
              <Button onClick={resetInputFields}>Cancel</Button>
              <Button onClick={submitData} type="primary">
                {editOrCreate === "edit" ? "Save" : "Create"}
              </Button>
            </Row>
          }
        >
          <Row justify={"center"}>
            <h2 style={{ color: "white" }}>
              {editOrCreate === "edit" ? "Edit" : "Create"}
            </h2>
          </Row>
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Form.Item label={<b>File Name</b>}>
              <Input
                value={fileName}
                onChange={(e) => handleFileNameChange(e)}
              />
            </Form.Item>
            <Form.Item label={<b>Clients</b>}>
              <Select
                mode="multiple"
                value={clientList}
                options={clientOptions}
                showSearch
                onChange={(e) => setClientList(e)}
                filterOption={(input, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                allowClear
                style={{ maxWidth: "400px" }}
              />
            </Form.Item>
            <Form.Item label={<b>Description</b>}>
              <TextArea
                rows={2}
                style={{ width: "300px" }}
                value={groupDescription}
                onChange={(e) => setGroupDescription(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
      </ConfigProvider>
    </Content>
  );
}
