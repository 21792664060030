import { LoadingOutlined } from "@ant-design/icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Row, Space, Spin, Table } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DisplayContext } from "../../../Context/displayContext";

export default function ViewInstantBalance() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [onFinishLoad, setOnFinishLoad] = useState<boolean>(false);
  const [instantBalanceData, setInstantBalanceData] = useState<any[]>([]);
  const [fetchDate, setFetchTime] = useState<any>(undefined);
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const [canRefresh, setCanRefresh] = useState<boolean>();
  const [assetFilters, setAssetFilters] = useState<any[]>();
  const [venueFilters, setVenueFilters] = useState<any[]>();
  const navigate = useNavigate();
  const [isRunning, setIsRunning] = useState<boolean>(false);

  const initialColumns = [
    {
      title: <Space>Venue</Space>,
      dataIndex: "exchange",
      sorter: (a: any, b: any) => a.exchange.localeCompare(b.exchange),
      width: 80,
      hidden: false,
      filters: venueFilters,
      // filterSearch: true,
      onFilter: (value: any, record: any) =>
        record.exchange.toLowerCase().indexOf(value.toLowerCase()) === 0,
      render: (_: any, { exchange }: any) => <Space>{exchange}</Space>,
    },
    {
      title: <Space>Asset</Space>,
      dataIndex: "asset",
      sorter: (a: any, b: any) => a.asset.localeCompare(b.asset),
      width: 80,
      hidden: false,
      filters: assetFilters,
      filterSearch: true,
      onFilter: (value: any, record: any) =>
        record.asset.toLowerCase().indexOf(value.toLowerCase()) === 0,
      render: (_: any, { asset }: any) => <Space>{asset}</Space>,
    },
    {
      title: <Space>Free Amount</Space>,
      dataIndex: "free",
      width: 120,
      hidden: false,
      align: "right" as const,
      render: (_: any, { free }: any) =>
        !isNaN(free) ? (
          <Space>
            {free.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: <Space>Hold Amount</Space>,
      dataIndex: "holdAmount",
      width: 120,
      hidden: false,

      align: "right" as const,
      render: (_: any, { hold }: any) =>
        !isNaN(hold) ? (
          <Space>
            {hold.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: <Space>Total Amount</Space>,
      dataIndex: "totalAmount",
      width: 120,
      hidden: false,
      sorter: (a: any, b: any) => a.total - b.total,
      align: "right" as const,
      render: (_: any, { total }: any) =>
        !isNaN(total) ? (
          <Space>
            {total.toLocaleString("en-us", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            })}
          </Space>
        ) : (
          <Space></Space>
        ),
    },
    {
      title: <Space>Total USD Value</Space>,
      dataIndex: "totalUsd",
      width: 120,
      hidden: false,
      sorter: (a: any, b: any) => a.totalUsd - b.totalUsd,
      align: "right" as const,
      render: (_: any, { totalUsd }: any) =>
        !isNaN(totalUsd) ? (
          <Space>
            $
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              currency: "USD",
            }).format(totalUsd)}
          </Space>
        ) : canRefresh ? (
          <Space>N/A</Space>
        ) : (
          <Spin indicator={antIcon} />
        ),
    },
    {
      title: <Space>Mid Price</Space>,
      dataIndex: "midUsdPrice",
      width: 120,
      hidden: false,
      align: "right" as const,
      render: (_: any, { midUsdPrice }: any) =>
        !isNaN(midUsdPrice) ? (
          <Space>
            $
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              currency: "USD",
            }).format(midUsdPrice)}
          </Space>
        ) : canRefresh ? (
          <Space>N/A</Space>
        ) : (
          <Spin indicator={antIcon} />
        ),
    },
    {
      title: <Space>Ask Price</Space>,
      dataIndex: "askUsdPrice",
      width: 120,
      hidden: false,
      align: "right" as const,
      render: (_: any, { askUsdPrice }: any) =>
        !isNaN(askUsdPrice) ? (
          <Space>
            $
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              currency: "USD",
            }).format(askUsdPrice)}
          </Space>
        ) : canRefresh ? (
          <Space>N/A</Space>
        ) : (
          <Spin indicator={antIcon} />
        ),
    },
    {
      title: <Space>Bid Price</Space>,
      dataIndex: "bidUsdPrice",
      width: 120,
      hidden: false,
      // fixed: "right" as const,
      align: "right" as const,
      render: (_: any, { bidUsdPrice }: any) =>
        !isNaN(bidUsdPrice) ? (
          <Space>
            $
            {Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
              currency: "USD",
            }).format(bidUsdPrice)}
          </Space>
        ) : canRefresh ? (
          <Space>N/A</Space>
        ) : (
          <Spin indicator={antIcon} />
        ),
    },
  ];

  const exportCSV = () => {
    setOnFinishLoad(false);
    instantBalanceData?.map((t) => (t.timestamp = fetchDate));
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/instant-balance/export-csv",
      withCredentials: true,
      data: {
        data: instantBalanceData,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let date = new Date();

        link.setAttribute(
          "download",
          `${date.getFullYear()}${String(date.getMonth() + 1).padStart(
            2,
            "0",
          )}${String(date.getDate()).padStart(2, "0")}_instant_balance.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  };

  const fetchPriceData = useCallback(() => {
    setIsRunning(true);
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/venue/instant-balance-price",
      withCredentials: true,
    })
      .then((res) => {
        setFetchTime(new Date());
        setOnFinishLoad(false);
        setInstantBalanceData([...res.data.data]);
        setFetchTime(res.data.lastDate);
      })
      .finally(() => {
        setOnFinishLoad(true);
        setCanRefresh(true);
        setIsRunning(false);
      });
  }, []);

  const fetchData = useCallback(() => {
    setOnFinishLoad(false);
    setCanRefresh(false);
    setInstantBalanceData([]);
    axios({
      method: "GET",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/instant-balance",
      withCredentials: true,
    })
      .then((res) => {
        setInstantBalanceData([...res.data.data]);
        if (!res.data.withPriceData) {
          fetchPriceData();
        } else {
          setFetchTime(res.data.lastDate);
          setCanRefresh(true);
          setIsRunning(false);
        }
      })
      .finally(() => {
        setOnFinishLoad(true);
      });
  }, [fetchPriceData]);

  const initialFetch = useCallback(() => {
    setOnFinishLoad(false);
    setCanRefresh(false);
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/instant-balance-existing",
      withCredentials: true,
    }).then((res) => {
      setInstantBalanceData([...res.data.data]);
      setFetchTime(res.data.lastDate);
      setIsRunning(res.data.isRunnning);
      if (!res.data.isRunnning) {
        setCanRefresh(true);
        setOnFinishLoad(true);
      }
    });
  }, []);

  const getAssetList = useCallback((params = {}) => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/asset/select-list",
      withCredentials: true,
    }).then((res) => {
      const options = [];
      for (const asset of res.data.assetList) {
        options.push({
          text: asset.ticker,
          value: asset.ticker,
        });
      }
      options.sort((a, b) => a.text.localeCompare(b.text));
      setAssetFilters(options);
    });
  }, []);

  const getVenueList = useCallback(
    (params = {}) => {
      axios({
        method: "Get",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/",
        withCredentials: true,
      })
        .then((res) => {
          const options = [];
          for (const venue of res.data.list) {
            options.push({
              text: venue.label,
              value: venue.label,
            });
          }
          setVenueFilters(options);
        })
        .catch((err) => {
          if (err.response.status === 403) {
            navigate("/login");
          }
        });
    },
    [navigate],
  );

  const getIsRunning = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/balance/is-running",
      withCredentials: true,
    }).then((res: any) => {
      setIsRunning(res.data.balanceIsRunning || res.data.priceIsRunning);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isRunning) {
        getIsRunning();
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [isRunning, getIsRunning]);

  useEffect(() => {
    if (!isRunning) initialFetch();
  }, [isRunning, initialFetch]);

  useEffect(() => {
    initialFetch();
    getAssetList();
    getVenueList();
  }, [initialFetch, getAssetList, getVenueList]);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);
  return (
    <Content>
      <Row className="dcl-filter-row">
        <Col md={{ span: 16 }} sm={{ span: 16 }}>
          <Space>
            <Button
              className="ant-btn-primary"
              onClick={fetchData}
              disabled={!canRefresh}
            >
              Instant Fetch
            </Button>
          </Space>
          <Space className="dcl-filter-row-space">
            <b>Last updated: </b>
            {fetchDate
              ? new Date(fetchDate).toLocaleString("en-US", {
                  timeZone: displayTimezone,
                  timeZoneName: "short",
                })
              : "Pending..."}
            {isRunning ? <Spin /> : <></>}
          </Space>
        </Col>
        <Col md={{ span: 8, order: 1 }} sm={{ span: 8, order: 1 }}>
          <Row justify="end">
            <Button
              className="dcl-btn-toggle"
              style={{ marginRight: "10px" }}
              disabled={!canRefresh}
              onClick={exportCSV}
            >
              <FontAwesomeIcon icon={"fa-solid fa-file-csv" as IconProp} />
              CSV Export
            </Button>
          </Row>
        </Col>
      </Row>
      <Table
        columns={initialColumns}
        dataSource={instantBalanceData}
        sticky
        loading={!onFinishLoad}
        showSorterTooltip={false}
        pagination={{
          showSizeChanger: true,
          pageSizeOptions: [50, 100, 200],
          defaultPageSize: 100,
        }}
        scroll={{
          x: "max-content",
        }}
        summary={(pageData) => {
          let totalUSD = pageData?.reduce(
            (accumulator: number, current: any) => {
              return accumulator + current.totalUsd;
            },
            0,
          );

          return totalUSD ? (
            <Table.Summary>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1} colSpan={2}>
                  <b>Total USD</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={3}></Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="right">
                  {totalUSD
                    ? `$${totalUSD?.toLocaleString("en-us", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : `$0.00`}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          ) : (
            <></>
          );
        }}
      />
    </Content>
  );
}
