import { Card, Col, Divider, Row, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";

export default function ViweOperationXTradeModal({ xtradeDetails }: any) {
  return (
    <Content id="show-xtrade-modal">
      <Row justify={"center"}>
        <h2 style={{ color: "white" }}>X-Trades</h2>
      </Row>
      <Row>
        {xtradeDetails ? (
          <></>
        ) : (
          <Row justify={"center"}>
            <Spin size="large" />
          </Row>
        )}
        {xtradeDetails?.map((row: any) => {
          return (
            <Col span={24} key={row.xTradeId}>
              <Card
                title={`${row.xTradeLabel} - Counterparties`}
                style={{ width: '100%' }}
                key={row.xTradeId}
              >
                {row.counterOperationItemDetails?.map(
                  (operation: any, opIndex: number) => {
                    return (
                      <React.Fragment key={opIndex}>
                        <Row>
                          <b style={{ fontSize: "1.1em" }}>
                            {operation?.operationLabel}
                          </b>
                          &nbsp;
                          {operation?.operationDescription
                            ? `(${operation.operationDescription})`
                            : ""}
                        </Row>
                        <Row>
                          {operation.details?.map(
                            (opItem: any, opItemIndex: number) => (
                              <Col span={18} key={opItemIndex} offset={1}>
                                {opItem.opItemSubtypeFullName}: {opItem.amount}{" "}
                                {opItem.assetTicker}
                              </Col>
                            ),
                          )}
                        </Row>
                        {opIndex ===
                        row.counterOperationItemDetails?.length - 1 ? (
                          <></>
                        ) : (
                          <Divider />
                        )}
                      </React.Fragment>
                    );
                  },
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
    </Content>
  );
}
