import { Button, Card, Form, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import axios from "axios";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
const typeOptions = [
  { label: "Order", key: "order", value: "order" },
  { label: "Trade", key: "trade", value: "trade" },
];

export default function LBankUpload() {
  const [uploading, setUploading] = useState<boolean>(false);
  const [data, setData] = useState<string>("");
  const [type, setType] = useState<string>("order");
  const uploadLbankData = useCallback(() => {
    setUploading(true);
    try {
      JSON.parse(data);
    } catch (err) {
      setUploading(false);
      console.error(err);
      toast.error("Failed to parse input json. Please check the format.");
      return;
    }
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/venue/lbank-maunal",
      withCredentials: true,
      data: {
        jsonText: data,
        type: type,
      },
    })
      .then((res) => {
        toast.success(`${res.data?.inserted} Inserted`);
      })
      .catch((err) => {
        toast.error(`Failed to insert`);
      })
      .finally(() => {
        setUploading(false);
      });
  }, [data, type]);
  return (
    <Content id="lbank-upload">
      <Card>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          labelAlign="left"
        >
          <Form.Item label={<b>Type</b>}>
            <Select
              options={typeOptions}
              value={type}
              onChange={(e) => {
                setType(e);
              }}
            />
          </Form.Item>
          <Form.Item label={<b>JSON Text</b>}>
            <TextArea
              value={data}
              onChange={(e) => {
                setData(e.target.value);
              }}
              rows={5}
            />
          </Form.Item>
        </Form>
        <Row justify={"center"}>
          <Button
            type="primary"
            onClick={() => {
              uploadLbankData();
            }}
            loading={uploading}
          >
            Upload
          </Button>
        </Row>
      </Card>
    </Content>
  );
}
