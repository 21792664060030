import { Tabs } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BusinessActivitySettings from "./BusinessActivitySettings";
import DeltecRmsSettings from "./DeltecRMsSettings";
import MetadataSettings from "./MetadataSettings";

export default function ViewDspTermsPageContainer() {
  const tabNames = useRef(["business_activities", "deltec_rms", "metadata"]);
  const { tabName = "overview" } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (tabNames.current.indexOf(tabName) === -1) {
      navigate("/bo/settings/dsp_terms/overview");
    }
  }, [navigate, tabNames, tabName]);

  return (
    <Content id="view-dsp-terms-container">
      <Tabs
        destroyInactiveTabPane={true}
        defaultActiveKey={tabName}
        type="card"
        onChange={(key) => {
          navigate("/bo/settings/dsp_terms/" + key);
        }}
        items={[
          {
            label: "Business Activities",
            key: "business_activities",
            children: <BusinessActivitySettings />,
          },
          {
            label: "Deltec RMs",
            key: "deltec_rms",
            children: <DeltecRmsSettings />,
          },
          {
            label: "Metadata",
            key: "metadata",
            children: <MetadataSettings />,
          },
        ]}
      />
    </Content>
  );
}
