import React, { useCallback, useContext, useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Row, Space } from "antd";
import axios from "axios";
import { UserContext } from "./Context/userContext";

function ConfirmationPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [content, setContent] = useState<any>();
  const getPageTypeRoute = useCallback(() => {
    const pageType = location.state?.pageType.toLowerCase();
    switch (pageType) {
      case "asset":
        return "/bo/settings/assets";
      case "venue":
        return "/bo/settings/venues/";
      case "fee policy":
        return "/bo/fee/policy-list";
      default:
        return `/bo/${pageType}s/list`;
    }
  }, [location]);

  useEffect(() => {
    if (location.state === null) {
      axios({
        method: "Post",
        url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/emails",
        data: {
          emailName: "email_error",
          message: `User ${userContext?.user.fullname}(${userContext?.user.email}) trying to access ${location.pathname} in unusual context: direct get`,
        },
        withCredentials: true,
      }).finally(() => {
        navigate("/");
      });
    }
    // console.log(location.state);
    if (location.state?.createOrUpdate === "Update") {
      setContent(
        <React.Fragment>
          <Space size={"middle"} wrap>
            <Link to={location.state?.editUrl}>
              <Button className="primary-button">
                Edit {location.state?.displayName}
              </Button>
            </Link>

            <Link
              to={
                location.state?.pageType.toLowerCase() === "asset"
                  ? "/bo/settings/assets"
                  : location.state?.pageType.toLowerCase() === "fee policy"
                  ? "/bo/fee/policy-list"
                  : `/bo/${location.state?.pageType.toLowerCase()}s`
              }
            >
              <Button className="primary-button">
                Go to {location.state?.pageType.toLowerCase()} list
              </Button>
            </Link>
          </Space>
        </React.Fragment>,
      );
    } else if (location.state?.createOrUpdate === "Create") {
      setContent(
        <React.Fragment>
          <Space size={"middle"} wrap>
            <Link to={location.state?.createUrl}>
              <Button className="primary-button">
                Create Another {location.state?.pageType}
              </Button>
            </Link>
            <Link to={location.state?.editUrl}>
              <Button className="primary-button">
                Edit {location.state?.displayName}
              </Button>
            </Link>
            <Link to={getPageTypeRoute()}>
              <Button className="primary-button">
                Go to {location.state?.pageType.toLowerCase()} list
              </Button>
            </Link>
          </Space>
        </React.Fragment>,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, navigate]);
  return (
    <React.Fragment>
      <Content>
        <Row justify="center">
          <h3 style={{ color: "black" }}>
            {location.state?.pageType} has been successfully{" "}
            {location.state?.createOrUpdate}d. What would you like to do next?
          </h3>
        </Row>
        <Row justify="center">{content}</Row>
      </Content>
    </React.Fragment>
  );
}

export default ConfirmationPage;
