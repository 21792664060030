import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, DatePicker, Form, Row, Select } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { formItemLayout, tailFormItemLayout } from "../UserPages/CreateUser";

export default function AssignDefaultFeePolicy() {
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm();

  const [custodyFeeTypeId, setCustodyFeeTypeId] = useState<number>();
  const [stakingFeeTypeId, setStakingFeeTypeId] = useState<number>();
  const [tradingFeeTypeId, setTradingFeeTypeId] = useState<number>();
  const [custodyeePolicyList, setCustodyFeePolicyList] = useState<any[]>();
  const [stakingFeePolicyList, setStakingFeePolicyList] = useState<any[]>();
  const [tradingFeePolicyList, setTradingFeePolicyList] = useState<any[]>();
  const fetchFeePolicyList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "custody" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setCustodyFeePolicyList(res.data.feePolicyList);
      setCustodyFeeTypeId(res.data.feePolicyTypeId);
    });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "staking" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setStakingFeePolicyList(res.data.feePolicyList);
      setStakingFeeTypeId(res.data.feePolicyTypeId);
    });
    // .finally(() => {
    //   setOnFinishLoad(true);
    // });
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "trading" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setTradingFeePolicyList(res.data.feePolicyList);
      setTradingFeeTypeId(res.data.feePolicyTypeId);
    });
    // .finally(() => {
    //   setOnFinishLoad(true);
    // });
  }, []);

  const fetchPolicyAssigned = useCallback(() => {
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy-assgined",
      data: {
        type: "default",
      },
      withCredentials: true,
    }).then((res) => {
      const custodyPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType &&
          t.feePolicyType.name.toLowerCase() === "custody",
      );
      console.log(custodyPolicy);
      form.setFieldValue(
        "custodyFeePolicyId",
        custodyPolicy ? Number(custodyPolicy.feePolicyId) : undefined,
      );
      form.setFieldValue(
        "custodyFeePolicyStartDate",
        custodyPolicy ? dayjs(custodyPolicy.startDate) : undefined,
      );

      const stakingPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType &&
          t.feePolicyType.name.toLowerCase() === "staking",
      );
      form.setFieldValue(
        "stakingFeePolicyId",
        stakingPolicy ? Number(stakingPolicy.feePolicyId) : undefined,
      );
      form.setFieldValue(
        "stakingFeePolicyStartDate",
        stakingPolicy ? dayjs(custodyPolicy.startDate) : undefined,
      );

      const tradingPolicy = res.data.find(
        (t: any) =>
          t.feePolicyType &&
          t.feePolicyType.name.toLowerCase() === "trading",
      );
      form.setFieldValue(
        "tradingFeePolicyId",
        tradingPolicy ? Number(tradingPolicy.feePolicyId) : undefined,
      );
      form.setFieldValue(
        "tradingFeePolicyStartDate",
        tradingPolicy ? dayjs(tradingPolicy.startDate) : undefined,
      );
    });
  }, [form]);

  const onFinish = (form: any) => {
    axios({
      method: "Post",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/assign-default",
      withCredentials: true,
      data: {
        custodyFeePolicyId: form.custodyFeePolicyId,
        custodyFeePolicyTypeId: custodyFeeTypeId,
        custodyFeePolicyStartDate: form.custodyFeePolicyStartDate,
        stakingFeePolicyTypeId: stakingFeeTypeId,
        stakingFeePolicyId: form.stakingFeePolicyId,
        stakingFeePolicyStartDate: form.stakingFeePolicyStartDate,
        tradingFeePolicyTypeId: tradingFeeTypeId,
        tradingFeePolicyId: form.tradingFeePolicyId,
        tradingFeePolicyStartDate: form.tradingFeePolicyStartDate,
      },
    })
      .then(() => {
        navigate("/bo/confirmation", {
          state: {
            pageType: "Fee Policy",
            createOrUpdate: "Update",
            displayName: "Default Fee Policy",
            editUrl: "/bo/fee/default-policies",
          },
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    toast.error("Please fill required fields ", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    fetchFeePolicyList();
    fetchPolicyAssigned();
  }, [fetchFeePolicyList, fetchPolicyAssigned]);
  return (
    <Content id="default-fee-policy-assginment">
      <Row>
        <Button
          onClick={() => {
            navigate(location.state ? location.state.from : "/bo/clients");
          }}
        >
          <FontAwesomeIcon
            icon={"fa-solid fa-circle-chevron-left" as IconProp}
          />
        </Button>{" "}
      </Row>
      <Form
        form={form}
        {...formItemLayout}
        labelWrap
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        className="form-body"
        labelCol={{
          sm: {
            span: 8,
            offset: 0,
          },
        }}
        wrapperCol={{
          sm: {
            span: 10,
            offset: 1,
          },
        }}
        style={{
          padding: "1em",
          margin: "auto",
        }}
      >
        <Row>
          <Col span={8}>
            <Card title="Custody">
              <Form.Item label="Custody Fee Policy" name="custodyFeePolicyId">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={custodyeePolicyList}
                  placeholder="Custody Fee Policy"
                />
              </Form.Item>
              <Form.Item
                label="Custody Fee Policy Start Date"
                name="custodyFeePolicyStartDate"
              >
                <DatePicker />
              </Form.Item>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Staking">
              <Form.Item label="Staking Fee Policy" name="stakingFeePolicyId">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={stakingFeePolicyList}
                  placeholder="Staking Fee Policy"
                />
              </Form.Item>
              <Form.Item
                label="Staking Fee Policy Start Date"
                name="stakingFeePolicyStartDate"
              >
                <DatePicker />
              </Form.Item>
            </Card>
          </Col>
          <Col span={8}>
            <Card title="Trading">
              <Form.Item label="Trading Fee Policy" name="tradingFeePolicyId">
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option: any) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  options={tradingFeePolicyList}
                  placeholder="Ttaking Fee Policy"
                />
              </Form.Item>
              <Form.Item
                label="Ttaking Fee Policy Start Date"
                name="tradingFeePolicyStartDate"
              >
                <DatePicker />
              </Form.Item>
            </Card>
          </Col>
        </Row>
        <Form.Item {...tailFormItemLayout}>
          <Button htmlType="submit" className="submit-button primary-button">
            Save
          </Button>
        </Form.Item>
      </Form>
    </Content>
  );
}
