import { Card, Col, Layout, Row, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { DisplayContext } from "../../../Context/displayContext";
import TradingPieChart from "./TradingPieChart";

export default function ViewTradingReportDashboard() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();

  const [currentWeekData, setCurrentWeekData] = useState<any>();
  const [prevWeekData, setPrevWeekData] = useState<any>();
  const [prevMonthData, setPrevMonthData] = useState<any>();
  const [currWeekDates, setCurrWeekDates] = useState<any[]>([
    undefined,
    undefined,
  ]);
  const [prevWeekDates, setPrevWeekDates] = useState<any[]>([
    undefined,
    undefined,
  ]);
  const [prevMonthDates, setPrevmonthDates] = useState<any[]>([
    undefined,
    undefined,
  ]);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  const [initialFetched, setInitialFetched] = useState<boolean>(false);
  const fetchData = useCallback(() => {
    axios({
      method: "Get",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/venue/trading-report/dashboard",
      withCredentials: true,
    })
      .then((res) => {
        setCurrentWeekData(res.data.currWeekVenueStatsArray);
        setPrevWeekData(res.data.prevWeekVenueStatsArray);
        setPrevMonthData(res.data.prevMonthVenueStatsArray);
        setCurrWeekDates(res.data.currWeek);
        setPrevWeekDates(res.data.prevWeek);
        setPrevmonthDates(res.data.prevMonth);
      })
      .finally(() => {
        setInitialFetched(true);
      });
  }, []);
  useEffect(() => {
    if (!initialFetched) {
      fetchData();
    }
  }, [fetchData, initialFetched]);
  return initialFetched ? (
    <Content id="venue-trading-report-dashboard">
      <Card title="Order Count">
        <Row style={{ textAlign: "center" }}>
          <Col lg={8} md={8} sm={24} xs={24}>
            <h4>Current week</h4>
            <h4>
              {currWeekDates[0]
                ? new Date(currWeekDates[0]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}{" "}
              -{" "}
              {currWeekDates[1]
                ? new Date(currWeekDates[1]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}
            </h4>
            <TradingPieChart
              data={currentWeekData}
              nameKey="venueName"
              numberKey="count"
            />
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <h4>Previous week</h4>
            <h4>
              {prevWeekDates[0]
                ? new Date(prevWeekDates[0]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}{" "}
              -{" "}
              {prevWeekDates[1]
                ? new Date(prevWeekDates[1]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}
            </h4>
            <TradingPieChart
              data={prevWeekData}
              nameKey="venueName"
              numberKey="count"
            />
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <h4>Previous month</h4>
            <h4>
              {prevMonthDates[0]
                ? new Date(prevMonthDates[0]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}{" "}
              -{" "}
              {prevMonthDates[1]
                ? new Date(prevMonthDates[1]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}
            </h4>
            <TradingPieChart
              data={prevMonthData}
              nameKey="venueName"
              numberKey="count"
            />
          </Col>
        </Row>
      </Card>
      <Card title="Order Amount">
        <Row style={{ textAlign: "center" }}>
          <Col lg={8} md={8} sm={24} xs={24}>
            <h4>Current week</h4>
            <h4>
              {currWeekDates[0]
                ? new Date(currWeekDates[0]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}{" "}
              -{" "}
              {currWeekDates[1]
                ? new Date(currWeekDates[1]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}
            </h4>
            <TradingPieChart
              data={currentWeekData}
              nameKey="venueName"
              numberKey="usdAmount"
            />
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <h4>Previous week</h4>
            <h4>
              {prevWeekDates[0]
                ? new Date(prevWeekDates[0]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}{" "}
              -{" "}
              {prevWeekDates[1]
                ? new Date(prevWeekDates[1]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}
            </h4>
            <TradingPieChart
              data={prevWeekData}
              nameKey="venueName"
              numberKey="usdAmount"
            />
          </Col>
          <Col lg={8} md={8} sm={24} xs={24}>
            <h4>Previous month</h4>
            <h4>
              {prevMonthDates[0]
                ? new Date(prevMonthDates[0]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}{" "}
              -{" "}
              {prevMonthDates[1]
                ? new Date(prevMonthDates[1]).toLocaleString("en-US", {
                    timeZone: displayTimezone,
                    // timeZoneName: "short",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                : ""}
            </h4>
            <TradingPieChart
              data={prevMonthData}
              nameKey="venueName"
              numberKey="usdAmount"
            />
          </Col>
        </Row>
      </Card>
    </Content>
  ) : (
    <Content>
      <Layout
        style={{
          paddingTop: "30vh",
          minHeight: "100vh",
        }}
      >
        <Spin size="large" />
      </Layout>
    </Content>
  );
}
