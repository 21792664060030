import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Select,
  Table,
} from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { Buffer } from "buffer";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DisplayContext } from "../../Context/displayContext";

const { RangePicker } = DatePicker;

export default function StakingReport() {
  const displayContext = useContext(DisplayContext);
  const [displayTimezone, setDisplayTimezone] = useState<string>();
  const [clientOptions, setClientOptions] = useState<any[]>();
  const [clientAccountOptions, setClientAccountOptions] = useState<any[]>();
  const [selectedClient, setSelectedClient] = useState<any>();
  const [selectedClientAccount, setSelectedClientAccount] = useState<any>();
  const [stakingFeePolicyList, setStakingFeePolicyList] = useState<any[]>();
  const [reportDataDatePeriod, setReportDataDatePeriod] = useState<any>();
  const [reportData, setReportData] = useState<any>();

  const [encodedClientId, setEncodedClientId] = useState<string>("");

  const [isReportPrePopulated, setIsReportPrePopulated] =
    useState<boolean>(false);

  // const [isHidingFeeInReport, setIsHidingFeeInReport] = useState<boolean>(true);
  const [isGeneratingReport, setIsGeneratingReport] = useState<boolean>(false);
  const [isGeneratingCSV, setIsGeneratingCSV] = useState<boolean>(false);
  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false);

  const [isNoStakingSubWallets, setIsNostakingSubWallets] =
    useState<boolean>(false);
  const [datePeriod, setDatePeriod] = useState<any>();

  const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
    // Can not select days after today and today
    return current > dayjs().endOf("d");
  };

  const handleFeePolicySelection = useCallback(
    (index: number, feePolicy: any) => {
      const newReportData = [...reportData];
      newReportData[index].feePolicyJSON = feePolicy.feePolicyJSON;
      newReportData[index].feePolicyId = feePolicy.id;
      setReportData(newReportData);
    },
    [reportData],
  );

  const calculateFeeAmount = useCallback(
    (index: number, number: any, key: string) => {
      let dclFeeAmount = 0;
      const newReportData = [...reportData];
      const feePolicyJSON = newReportData[index].feePolicyJSON;
      const periodFeeData = {
        ...newReportData[index].periodFeeData,
        [key]: number,
      };
      const rewardAmount = periodFeeData.grossReward
        ? periodFeeData.grossReward
        : 0;
      periodFeeData[key] = Number(number);

      if (feePolicyJSON.rangeFees) {
        let amountLeft = rewardAmount;
        for (const range of feePolicyJSON.rangeFees) {
          const threshold = range.to - range.from;
          if (amountLeft >= threshold) {
            if (range.dclFeeType === "dollar") dclFeeAmount += range.dclFees;
            else dclFeeAmount += threshold * range.dclFees * 0.0001;
          } else {
            if (range.dclFeeType === "dollar") dclFeeAmount += range.dclFees;
            else dclFeeAmount += amountLeft * range.dclFees * 0.0001;
          }
          amountLeft -= threshold;
          if (amountLeft <= 0) break;
        }
      } else {
        // Get Direct Dollar Amount or BPS for DCL Fees
        if (feePolicyJSON.dclFeeType === "bps") {
          dclFeeAmount = feePolicyJSON.dclFees * rewardAmount * 0.0001;
        } else if (feePolicyJSON.dclFeeType === "dollar") {
          dclFeeAmount = feePolicyJSON.dclFees;
        }
        // Minimum Fee
        if (feePolicyJSON.feesBelow && dclFeeAmount < feePolicyJSON.feesBelow)
          dclFeeAmount = feePolicyJSON.feesBelowDclFee;
      }
      const additionalFees = periodFeeData.additionalFees
        ? Number(periodFeeData.additionalFees)
        : 0;
      periodFeeData.dclFeeAmount = dclFeeAmount;
      periodFeeData.netReward = isNaN(
        rewardAmount - dclFeeAmount - additionalFees,
      )
        ? 0
        : rewardAmount - dclFeeAmount - additionalFees;
      const startAmount = periodFeeData.startAmount
        ? Number(periodFeeData.startAmount)
        : 0;
      const depositAmount = periodFeeData.depositAmount
        ? Number(periodFeeData.depositAmount)
        : 0;
      const withdrawalAmount = periodFeeData.withdrawalAmount
        ? Number(periodFeeData.withdrawalAmount)
        : 0;

      periodFeeData.endAmount =
        startAmount +
        depositAmount -
        withdrawalAmount +
        periodFeeData.netReward;
      if (Number(startAmount) > 0)
        periodFeeData.monthlyEarningPercentage =
          (periodFeeData.netReward / startAmount) * 100;
      else periodFeeData.monthlyEarningPercentage = 0;

      newReportData[index].periodFeeData = periodFeeData;
      setReportData(newReportData);
    },
    [reportData],
  );

  const handleInputChange = useCallback(
    (index: number, value: any, key: string) => {
      const newReportData = [...reportData];
      const periodFeeData = { ...newReportData[index].periodFeeData };
      periodFeeData[key] = value;
      newReportData[index].periodFeeData = periodFeeData;
      setReportData(newReportData);
    },
    [reportData],
  );

  const displayColumns = [
    {
      title: "Label",
      dataIndex: "label",
      width: 120,
      // fixed: "left" as const,
      render: (_: any, { label }: any) => <b>{label}</b>,
    },
    {
      title: "Asset Name",
      dataIndex: "assetTicker",
      width: 100,
      render: (_: any, { assetTicker }: any) => <b>{assetTicker}</b>,
    },
    {
      title: "Start Amount",
      dataIndex: "startAmount",
      width: 180,
      align: "right" as const,
      render: (_: any, { periodFeeData }: any, index: number) => (
        <InputNumber
          style={{ width: "150px" }}
          defaultValue={periodFeeData ? periodFeeData.startAmount : 0}
          formatter={(value) => {
            const parts = `${value}`.split(".");
            // Apply the original regex only to the first part (integer part) to add commas.
            const integerPartWithCommas = parts[0].replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ",",
            );
            // Reassemble the parts, including the decimal part if it was present.
            return parts.length > 1
              ? `${integerPartWithCommas}.${parts[1]}`
              : integerPartWithCommas;
          }}
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          onChange={(e) => calculateFeeAmount(index, e, "startAmount")}
        />
      ),
    },
    {
      title: "Deposit Amount",
      dataIndex: "depositAmount",
      width: 180,
      align: "right" as const,
      render: (_: any, { periodFeeData }: any, index: number) => (
        <InputNumber
          style={{ width: "150px" }}
          defaultValue={periodFeeData ? periodFeeData.depositAmount : 0.0}
          formatter={(value) => {
                      const parts = `${value}`.split(".");
                      // Apply the original regex only to the first part (integer part) to add commas.
                      const integerPartWithCommas = parts[0].replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ",",
                      );
                      // Reassemble the parts, including the decimal part if it was present.
                      return parts.length > 1
                        ? `${integerPartWithCommas}.${parts[1]}`
                        : integerPartWithCommas;
                    }}
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          onChange={(e) => calculateFeeAmount(index, e, "depositAmount")}
        />
      ),
    },
    {
      title: "Withdrawal Amount",
      dataIndex: "withdrawalAmount",
      width: 180,
      align: "right" as const,
      render: (_: any, { periodFeeData }: any, index: number) => (
        <InputNumber
          style={{ width: "150px" }}
          defaultValue={periodFeeData ? periodFeeData.withdrawalAmount : 0.0}
          formatter={(value) => {
            const parts = `${value}`.split(".");
            // Apply the original regex only to the first part (integer part) to add commas.
            const integerPartWithCommas = parts[0].replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ",",
            );
            // Reassemble the parts, including the decimal part if it was present.
            return parts.length > 1
              ? `${integerPartWithCommas}.${parts[1]}`
              : integerPartWithCommas;
          }}
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          onChange={(e) => calculateFeeAmount(index, e, "withdrawalAmount")}
        />
      ),
    },
    {
      title: "Gross Reward",
      dataIndex: "grossReward",
      width: 180,
      align: "right" as const,
      render: (_: any, { periodFeeData }: any, index: number) => (
        <InputNumber
          style={{ width: "150px" }}
          defaultValue={periodFeeData ? periodFeeData.grossReward : 0}
          formatter={(value) => {
            const parts = `${value}`.split(".");
            // Apply the original regex only to the first part (integer part) to add commas.
            const integerPartWithCommas = parts[0].replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ",",
            );
            // Reassemble the parts, including the decimal part if it was present.
            return parts.length > 1
              ? `${integerPartWithCommas}.${parts[1]}`
              : integerPartWithCommas;
          }}
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          onChange={(e) => calculateFeeAmount(index, e, "grossReward")}
        />
      ),
    },
    {
      title: "Delchain Fee Policy",
      dataIndex: "feePolicyId",
      width: 250,
      render: (_: any, { feePolicyId }: any, index: number) => (
        <Select
          options={stakingFeePolicyList}
          defaultValue={feePolicyId}
          onSelect={(e) => {
            if (stakingFeePolicyList) {
              handleFeePolicySelection(
                index,
                stakingFeePolicyList.find((t: any) => t.id === e),
              );
              calculateFeeAmount(index, e, "feePolicyId");
            }
          }}
        />
      ),
    },
    {
      title: "Delchain Fee Percentage",
      dataIndex: "feePercentage",
      width: 180,
      align: "right" as const,
      render: (_: any, { feePolicyJSON }: any) =>
        `${feePolicyJSON.dclFees * 0.0001 * 100} %`,
    },
    {
      title: "Delchain Fee",
      dataIndex: "dclFeeAmount",
      width: 180,
      align: "right" as const,
      render: (_: any, { periodFeeData }: any) =>
        `${(periodFeeData && periodFeeData.dclFeeAmount
          ? periodFeeData.dclFeeAmount
          : 0
        ).toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 4,
        })}`,
    },
    {
      title: "Fee in USD",
      dataIndex: "feeUSDValue",
      width: 180,
      align: "right" as const,
      render: (_: any, { periodFeeData }: any, index: number) => (
        <InputNumber
          style={{ width: "150px" }}
          defaultValue={periodFeeData ? periodFeeData.feeUSDValue : 0}
          formatter={(value) => {
            const parts = `${value}`.split(".");
            // Apply the original regex only to the first part (integer part) to add commas.
            const integerPartWithCommas = parts[0].replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ",",
            );
            // Reassemble the parts, including the decimal part if it was present.
            return parts.length > 1
              ? `${integerPartWithCommas}.${parts[1]}`
              : integerPartWithCommas;
          }}
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          onChange={(e) => calculateFeeAmount(index, e, "feeUSDValue")}
        />
      ),
    },
    {
      title: "Additional Fees",
      dataIndex: "additionalFees",
      width: 180,
      align: "right" as const,
      render: (_: any, { periodFeeData }: any, index: number) => (
        <InputNumber
          style={{ width: "150px" }}
          defaultValue={periodFeeData ? periodFeeData.additionalFees : 0}
          formatter={(value) => {
            const parts = `${value}`.split(".");
            // Apply the original regex only to the first part (integer part) to add commas.
            const integerPartWithCommas = parts[0].replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ",",
            );
            // Reassemble the parts, including the decimal part if it was present.
            return parts.length > 1
              ? `${integerPartWithCommas}.${parts[1]}`
              : integerPartWithCommas;
          }}
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          onChange={(e) => calculateFeeAmount(index, e, "additionalFees")}
        />
      ),
    },
    {
      title: "Net Reward",
      dataIndex: "netReward",
      width: 150,
      align: "right" as const,
      render: (_: any, { periodFeeData }: any) =>
        (periodFeeData ? Number(periodFeeData.netReward) : 0).toLocaleString(
          "en-us",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 8,
          },
        ),
    },
    {
      title: "% Monthly Earning",
      dataIndex: "monthlyEarningPercentage",
      width: 100,
      align: "right" as const,
      render: (_: any, { periodFeeData }: any) =>
        `${
          periodFeeData && periodFeeData.monthlyEarningPercentage
            ? periodFeeData.monthlyEarningPercentage.toFixed(2)
            : 0
        } %`,
    },
    {
      title: "Net Reward in USD",
      dataIndex: "usdValue",
      width: 180,
      align: "right" as const,
      render: (_: any, { periodFeeData }: any, index: number) => (
        <InputNumber
          style={{ width: "150px" }}
          defaultValue={periodFeeData ? periodFeeData.usdValue : 0}
          formatter={(value) => {
            const parts = `${value}`.split(".");
            // Apply the original regex only to the first part (integer part) to add commas.
            const integerPartWithCommas = parts[0].replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ",",
            );
            // Reassemble the parts, including the decimal part if it was present.
            return parts.length > 1
              ? `${integerPartWithCommas}.${parts[1]}`
              : integerPartWithCommas;
          }}
          parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          onChange={(e) => calculateFeeAmount(index, e, "usdValue")}
        />
      ),
    },
    {
      title: "Memo",
      dataIndex: "memo",
      width: 200,
      render: (_: any, { periodFeeData }: any, index: number) => (
        <TextArea
          defaultValue={periodFeeData ? periodFeeData.memo : ""}
          onChange={(e) => handleInputChange(index, e.target.value, "memo")}
        />
      ),
    },

    {
      title: "End Amount",
      dataIndex: "endAmount",
      width: 200,
      align: "right" as const,
      // fixed: "right" as const,
      render: (_: any, { periodFeeData }: any) =>
        (periodFeeData && periodFeeData.endAmount
          ? Number(periodFeeData.endAmount)
          : 0
        ).toLocaleString("en-us", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 8,
        }),
    },
    {
      title: "Show in report",
      dataIndex: "includedInReport",
      width: 100,
      // fixed: "right" as const,
      render: (_: any, { periodFeeData }: any, index: number) => (
        <Checkbox
          defaultChecked={
            periodFeeData ? periodFeeData.includedInReport !== false : true
          }
          onChange={(e) =>
            handleInputChange(index, e.target.checked, "includedInReport")
          }
        />
      ),
    },
  ];

  const saveAdjustments = useCallback(() => {
    for (const row of reportData) {
      if (!row.periodFeeData) {
        row.periodFeeData = {
          includedInReport: true,
        };
      } else if (row.periodFeeData.includedInReport === undefined) {
        row.periodFeeData = {
          ...row.periodFeeData,
          includedInReport: true,
        };
      }
    }
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL + "/util/staking/save-period-fee",
      withCredentials: true,
      data: {
        startDate: dayjs
          .utc(reportDataDatePeriod[0].format("YYYYMMDD"))
          .startOf("d")
          .toDate(),
        endDate: dayjs
          .utc(reportDataDatePeriod[1].format("YYYYMMDD"))
          .endOf("d")
          .toDate(),
        periodFeeData: reportData,
      },
    })
      .then(() => {
        toast.success("Saved Successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reportData, reportDataDatePeriod]);

  const fetchFeePolicyList = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/fee/policy",
      params: { typeName: "staking" },
      withCredentials: true,
    }).then((res) => {
      res.data.feePolicyList.forEach((t: any) => {
        t.label = t.name;
        t.value = t.id;
      });
      setStakingFeePolicyList(res.data.feePolicyList);
    });
  }, []);

  const handleClientSelect = useCallback(
    (option: any, optionElement: any) => {
      setSelectedClient(option);
      const ca = clientOptions?.find((t) => t.value === option).clientAccounts;
      if (ca && ca.length === 1) setSelectedClientAccount(ca[0].value);
      else setSelectedClientAccount(undefined);
    },
    [clientOptions],
  );

  const getClientOptions = useCallback(() => {
    axios({
      method: "Get",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/client-list",
      withCredentials: true,
    })
      .then((res) => {
        for (const c of res.data.clients) {
          c.label = `${c.label} (${c.deltecAccount})`;
        }
        setClientOptions(res.data.clients);
      })
      .catch(() => {
        console.log("Unable to fetch the role list");
      });
  }, []);

  const prepolulateReport = useCallback(() => {
    setIsNostakingSubWallets(false);
    setIsReportPrePopulated(false);
    setIsSendingRequest(true);
    axios({
      method: "Post",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/staking/pre-populate-report",
      withCredentials: true,
      data: {
        startDate: dayjs
          .utc(datePeriod[0].format("YYYYMMDD"))
          .startOf("d")
          .toDate(),
        endDate: dayjs
          .utc(datePeriod[1].format("YYYYMMDD"))
          .endOf("d")
          .toDate(),
        clientId: selectedClient,
        clientAccountId: selectedClientAccount,
      },
    })
      .then((res) => {
        setIsNostakingSubWallets(res.data.data.length === 0);
        setReportData(res.data.data);
        setIsReportPrePopulated(true);
        setEncodedClientId(res.data.encodedClientId);
        setReportDataDatePeriod(datePeriod);
      })
      .finally(() => {
        setIsSendingRequest(false);
      });
  }, [selectedClient, selectedClientAccount, datePeriod]);

  const generateReport = useCallback(() => {
    setIsGeneratingReport(true);
    const CA = clientAccountOptions?.find(
      (t) => t.value === selectedClientAccount,
    );
    const reportDataSending = reportData.filter(
      (t: any) =>
        !t.periodFeeData ||
        (t.periodFeeData && t.periodFeeData.includedInReport !== false),
    );
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_AWS_BACKEND_URL +
        "/util/staking/pdf-report-generation",
      data: {
        clientAccountNumber: CA ? CA.accountNumber : "",
        clientAccountName:
          CA && CA.label.split("(") ? CA.label.split("(")[0].trim() : "",
        data: reportDataSending,
        startDate: reportDataDatePeriod[0].format("YYYY-MM-DD"),
        endDate: reportDataDatePeriod[1].format("YYYY-MM-DD"),
        // isHidingFeeInReport: isHidingFeeInReport,
      },
      withCredentials: true,
    })
      .then((res) => {
        const buffer = Buffer.from(res.data);
        const blob = new Blob([buffer]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${CA ? CA.label : ""}_${
            reportDataDatePeriod
              ? reportDataDatePeriod[0].format("YYYYMMDD")
              : ""
          }_${
            reportDataDatePeriod
              ? reportDataDatePeriod[1].format("YYYYMMDD")
              : ""
          }_staking_report.pdf`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsGeneratingReport(false);
      });
  }, [
    // isHidingFeeInReport,
    clientAccountOptions,
    reportData,
    reportDataDatePeriod,
    selectedClientAccount,
  ]);

  const exportCSV = useCallback(() => {
    setIsGeneratingCSV(true);
    const reportDataSending = reportData.filter(
      (t: any) =>
        !t.periodFeeData ||
        (t.periodFeeData && t.periodFeeData.includedInReport !== false),
    );
    axios({
      method: "POST",
      url: process.env.REACT_APP_AWS_BACKEND_URL + "/util/staking/export-csv",
      withCredentials: true,
      data: {
        data: reportDataSending,
      },
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${reportDataDatePeriod[0].format(
            "YYYY-MM-DD",
          )}_${reportDataDatePeriod[1].format(
            "YYYY-MM-DD",
          )}_staking_period_fee.csv`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsGeneratingCSV(false);
      });
  }, [reportData, reportDataDatePeriod]);

  useEffect(() => {
    getClientOptions();
    fetchFeePolicyList();
  }, [getClientOptions, fetchFeePolicyList]);

  useEffect(() => {
    if (selectedClient && clientOptions) {
      const c = clientOptions?.find((t) => t.id === selectedClient);
      setClientAccountOptions(c.clientAccounts ? c.clientAccounts : []);
    }
  }, [selectedClient, clientOptions]);

  useEffect(() => {
    if (
      displayContext?.displayContext &&
      displayTimezone !== displayContext.displayContext.timezone
    ) {
      setDisplayTimezone(displayContext?.displayContext.timezone);
    }
  }, [displayContext, displayTimezone]);

  return (
    <Content id="custody-fee-report">
      <Row>
        <Col
          xl={{ span: 4 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <RangePicker
            className="dcl-daterange-select"
            value={datePeriod}
            disabledDate={disabledDate}
            onChange={(e) => {
              setDatePeriod(e);
            }}
          />
        </Col>
        <Col
          xl={{ span: 4, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Select
            
            className="dcl-client-select"
            placeholder="Client"
            options={clientOptions}
            onSelect={handleClientSelect}
            value={selectedClient}
            showSearch
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
            filterOption={(input, option: any) => {
              return option.label.toLowerCase().includes(input.toLowerCase());
            }}
          />
        </Col>
        <Col
          xl={{ span: 4, offset: 1 }}
          lg={{ span: 12 }}
          md={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Select
            placeholder="Client Account"
            className="dcl-client-account-select"
            disabled={!selectedClient}
            options={clientAccountOptions}
            value={selectedClientAccount}
            onSelect={(option) => {
              setSelectedClientAccount(option);
            }}
            style={{
              width: "100%",
              maxWidth: "100%",
            }}
          />
        </Col>
      </Row>
      <Row justify="end" style={{ marginTop: "1em" }}>
        {/* <Checkbox
          defaultChecked={isHidingFeeInReport}
          onChange={(e) => setIsHidingFeeInReport(e.target.checked)}
        >
          Hide fees in report
        </Checkbox> */}
        <Button
          loading={isSendingRequest}
          onClick={prepolulateReport}
          disabled={!selectedClientAccount || !datePeriod}
          type="primary"
        >
          Prepopulate the report
        </Button>
      </Row>
      <Row>
        {isNoStakingSubWallets ? (
          <Col span={12}>
            <Alert
              message={"No staking wallets found with this client account"}
              type="warning"
              showIcon
            />
          </Col>
        ) : (
          <></>
        )}
      </Row>
      {isReportPrePopulated && !isNoStakingSubWallets ? (
        <Row style={{ marginTop: "2em" }}>
          <Col span={24}>
            <Card
              title={
                <Link
                  to={"/bo/client/info/" + encodeURIComponent(encodedClientId)}
                  state={{
                    from: window.location.pathname,
                    startDate: datePeriod ? datePeriod[0].format() : undefined,
                    endDate: datePeriod ? datePeriod[1].format() : undefined,
                    selectedClient: selectedClient,
                    selectClientAccount: selectedClientAccount,
                  }}
                >
                  {clientAccountOptions?.find(
                    (t) => t.value === selectedClientAccount,
                  )
                    ? clientAccountOptions?.find(
                        (t) => t.value === selectedClientAccount,
                      ).label
                    : ""}
                </Link>
              }
              extra={
                <React.Fragment>
                  <Row justify={"end"}>
                    <Button
                      type="primary"
                      loading={isGeneratingCSV}
                      onClick={exportCSV}
                    >
                      <FontAwesomeIcon
                        icon={"fa-solid fa-file-csv" as IconProp}
                        style={{ marginRight: "10px" }}
                      />
                      CSV Export
                    </Button>
                    <Button
                      onClick={() => generateReport()}
                      type="primary"
                      loading={isGeneratingReport}
                    >
                      <FontAwesomeIcon
                        icon={"fa-solid fa-file-pdf" as IconProp}
                        style={{ marginRight: "10px" }}
                      />
                      PDF Export
                    </Button>
                  </Row>
                </React.Fragment>
              }
            >
              <Table
                dataSource={reportData}
                columns={displayColumns}
                scroll={{ x: 2000 }}
                sticky
                rowKey={"id"}
                pagination={{
                  defaultPageSize: 30,
                  showTotal: (total) => (
                    <Row>
                      <b>Count</b>: {total}
                    </Row>
                  ),
                }}
              />
              <Row justify={"center"}>
                <Button type="primary" onClick={saveAdjustments}>
                  Save Adjustments
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </Content>
  );
}
